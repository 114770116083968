import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DvClientService } from '@dv/toolbar-msal';
import { APP_PATH } from 'app/app.types';
import { SiteService } from 'app/services/site.service';
import { lastValueFrom, map, take } from 'rxjs';

@Component({
  selector: 'dv-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css'],
})
export class ClientComponent implements OnInit {
  hideToolbar = false;
  loading = false;
  constructor(
    private siteService: SiteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dvClientService: DvClientService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      const urlId = parseInt(params['clientId']);

      const clientId = await lastValueFrom(
        this.dvClientService.client$.pipe(
          take(1),
          map((client) => client.id)
        )
      );

      if (clientId !== urlId) {
        window.location.href = `${APP_PATH.CLIENT}/${clientId}`;
      }

      this.siteService.hasCropMap().subscribe((hasCropMap) => {
        if (!hasCropMap) {
          this.router.navigateByUrl(`${APP_PATH.LOGIN}?status=401`);
        }
      });
    });
  }
}
