<div class="preview-header">
  <dvm-text
    h4
    [dvmTooltip]="
      '_map_settings_tooltip'
        | dvt
          : '_For each parameter with within-field variation, the user can select One fixed value/One or more data layers'
    "
  >
    {{ 'Map settings' | dvt }}
  </dvm-text>
</div>
<dvm-accordion>
  <dvm-accordion-item
    *ngFor="let data of geoData; let i = index"
    [title]="data.name"
    [subTitle]="
      data.errorMessage
        ? data.errorMessage
        : data.hasSelectedFile && !data.useFixedValue
        ? geoDataSelectedFileText[i]
        : ''
    "
    [mode]="
      data.errorMessage ? ACCORDION_ITEM_MODE.ERROR : ACCORDION_ITEM_MODE.NORMAL
    "
  >
    <dv-geo-data-content
      *ngIf="data.parameterType === PARAMETER_TYPES.GEO_DATA"
      [data]="data"
      (fileChange)="onFileChange($event, i)"
      (fixedValueChange)="onFixedValueChange(i)"
    ></dv-geo-data-content>
    <dv-nested-geo-data-content
      *ngIf="data.parameterType === PARAMETER_TYPES.NESTED"
      [data]="data"
      (fileChange)="onNestedFileChange($event, data, i)"
      (itemChange)="onNestedItemChange($event, data, i)"
      (fixedValueChange)="onFixedValueChange(i)"
    ></dv-nested-geo-data-content>
  </dvm-accordion-item>
</dvm-accordion>
