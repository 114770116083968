<div class="content">
  <button class="back-button" mat-button (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>
    {{ state === POI_STATES.CATEGORY ? ('Go back' | dvt) : ('Cancel' | dvt) }}
  </button>
  <dv-poi-toolbar
    [editFeature]="feature"
    (saveFeature)="saveFeature()"
    *ngIf="state !== POI_STATES.DETAIL"
    [state]="state"
  ></dv-poi-toolbar>
  <dv-poi-detail
    (delete)="featureDeleted($event)"
    [feature]="feature"
    *ngIf="feature && state === POI_STATES.DETAIL"
  ></dv-poi-detail>
  <dv-poi-add
    (cancel)="cancelDraw()"
    (save)="featureSaved($event)"
    [feature]="feature"
    *ngIf="
      feature &&
      (state === POI_STATES.ADD ||
        state === POI_STATES.EDIT ||
        state === POI_STATES.EDIT_SINGLE)
    "
  ></dv-poi-add>
  <dv-poi-categorys
    *ngIf="state === POI_STATES.CATEGORY"
    [categorys]="categorys"
  ></dv-poi-categorys>
  <div class="loading-area" *ngIf="loadingLayers">
    <mat-progress-spinner
      color="primary"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
</div>
