import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  LAYER_NAME_MARKERING,
  MapService,
} from 'app/components/map/map.service';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { PoiService } from '../poi.service';
import { POI_STATES } from '../poi.types';

@Component({
  selector: 'dv-poi-toolbar',
  templateUrl: './poi-toolbar.component.html',
  styleUrls: ['./poi-toolbar.component.scss'],
})
export class PoiToolbarComponent implements OnInit, OnDestroy {
  @Input() state: POI_STATES;
  @Input() editFeature: google.maps.Data.Feature;
  @Output() saveFeature = new EventEmitter();

  showToolbar = true;
  drawing = false;
  clickMapEvent: google.maps.MapsEventListener = null;
  clickPointEvent: google.maps.MapsEventListener = null;
  mouseUpEvent: google.maps.MapsEventListener = null;
  poiPolyline = new google.maps.Polyline();
  poiPolygon = new google.maps.Polygon();
  marker: google.maps.Marker;
  feature: google.maps.Data.Feature = null;
  drawingPoint = false;
  drawingLine = false;
  drawingPolygon = false;
  activeState: string;
  POI_STATES = POI_STATES;
  private _unsub$: Subject<void> = new Subject<void>();

  constructor(private mapService: MapService, private poiSrv: PoiService) {}

  ngOnInit(): void {
    this.poiSrv
      .getState()
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        this.activeState = res;
      });
    this.poiSrv
      .getPoiFeature()
      .pipe(takeUntil(this._unsub$))
      .subscribe((poi) => {
        if (!this.feature) {
          this.feature = poi;
        }
      });
    if (
      (this.state === POI_STATES.EDIT ||
        this.state === POI_STATES.EDIT_SINGLE) &&
      this.editFeature
    ) {
      this.mapService
        .mainMap()
        .pipe(first())
        .subscribe((res) => {
          res.removeLayer(LAYER_NAME_MARKERING);
        });
      this.editDraw(this.editFeature);
    }
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();

    if (this.poiPolygon) {
      this.poiPolygon.setMap(null);
    }
    if (this.poiPolyline) {
      this.poiPolyline.setMap(null);
    }
    if (this.marker) {
      this.marker.setMap(null);
    }

    this.setStartDraw(null, false);

    this.drawing = false;
    this.showToolbar = false;
    if (this.clickMapEvent) {
      this.clickMapEvent.remove();
    }
    if (this.clickPointEvent) {
      this.clickPointEvent.remove();
    }
    if (this.mouseUpEvent) {
      this.mouseUpEvent.remove();
    }
  }

  close(): void {
    if (this.poiPolygon) {
      this.poiPolygon.setMap(null);
    }
    if (this.poiPolyline) {
      this.poiPolyline.setMap(null);
    }
    if (this.marker) {
      this.marker.setMap(null);
    }

    this.drawing = false;
    this.showToolbar = false;
    if (this.clickMapEvent) {
      this.clickMapEvent.remove();
    }
  }

  editDraw(feature: google.maps.Data.Feature): void {
    this.feature = feature;
    const path = [];
    feature.getGeometry().forEachLatLng((latLng) => {
      path.push(latLng);
    });

    switch (feature.getGeometry().getType()) {
      case 'Point':
        this.drawPoint(true);
        break;
      case 'LineString':
        this.poiPolyline.setPath(path);
        this.drawLine(true);
        break;
      case 'Polygon':
        this.poiPolygon.setPath(path);
        this.drawPolygon(true);
        break;
    }
  }

  setStartDraw(type: string, edit): void {
    if (this.clickMapEvent) {
      this.clickMapEvent.remove();
    }
    if (this.clickPointEvent) {
      this.clickPointEvent.remove();
    }

    if (this.mouseUpEvent) {
      this.mouseUpEvent.remove();
    }
    if (this.marker) {
      this.marker.setMap(null);
    }

    this.drawingPoint = type === 'Point';
    this.drawingLine = type === 'LineString';
    this.drawingPolygon = type === 'Polygon';
    if (!edit) {
      this.poiPolygon.setPath([]);
      this.poiPolyline.setPath([]);
    }
  }

  drawPoint(edit = false): void {
    this.setStartDraw('Point', edit);

    this.mapService.mainMap().subscribe((mainMap) => {
      if (edit && this.feature) {
        this.feature.getGeometry().forEachLatLng((latlng) => {
          if (!this.marker) {
            this.marker = new google.maps.Marker({
              position: latlng,
              map: mainMap.map,
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: '#464650',
                fillOpacity: 1,
                strokeColor: '#fff',
                strokeOpacity: 1,
                strokeWeight: 1,
                scale: 5,
              },
            });
          }
        });
      }
      this.clickMapEvent = google.maps.event.addListener(
        mainMap.map,
        'click',
        (event: google.maps.MouseEvent) => {
          if (this.marker) {
            this.marker.setMap(null);
          }
          this.marker = new google.maps.Marker({
            position: event.latLng,
            map: mainMap.map,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: '#464650',
              fillOpacity: 1,
              strokeColor: '#fff',
              strokeOpacity: 1,
              strokeWeight: 1,
              scale: 5,
            },
          });

          this.updateGeometry(event.latLng);
        }
      );
    });
  }

  drawLine(edit = false): void {
    this.setStartDraw('LineString', edit);
    this.mapService.mainMap().subscribe((mainMap) => {
      this.poiPolyline.setMap(mainMap.map);
      this.poiPolyline.setEditable(true);
      if (!edit) {
        this.poiPolyline.setPath([]);
      } else {
        this.initLinePointEvents();
      }

      this.clickMapEvent = google.maps.event.addListener(
        mainMap.map,
        'click',
        (event: google.maps.MouseEvent) => {
          const path = this.poiPolyline.getPath();
          path.push(event.latLng);
          this.poiPolyline.setPath(path);
          if (
            this.poiPolyline.getPath() &&
            this.poiPolyline.getPath().getLength() > 1
          ) {
            const geometry = new google.maps.Data.LineString(
              this.poiPolyline
                .getPath()
                .getArray()
                .map((latLng) => latLng.toJSON())
            );

            this.initLinePointEvents();

            this.updateGeometry(geometry);
          }
        }
      );
    });
  }

  drawPolygon(edit = false): void {
    this.setStartDraw('Polygon', edit);

    this.mapService.mainMap().subscribe((mainMap) => {
      this.poiPolygon.setMap(mainMap.map);
      this.poiPolygon.setEditable(true);
      if (!edit) {
        this.poiPolygon.setPath([]);
      } else {
        this.initPolygonPointEvents();
      }

      this.clickMapEvent = google.maps.event.addListener(
        mainMap.map,
        'click',
        (event: google.maps.MouseEvent) => {
          const path = this.poiPolygon.getPath();
          path.push(event.latLng);
          this.poiPolygon.setPath(path);

          if (
            this.poiPolygon.getPath() &&
            this.poiPolygon.getPath().getLength() > 2
          ) {
            let geometry: google.maps.Data.Geometry = null;
            const arr = this.poiPolygon
              .getPath()
              .getArray()
              .map((latLng) => latLng.toJSON());
            //last point and first point must be same
            //leats do a check for that
            if (arr[0].lat !== arr[arr.length - 1].lat) {
              arr.push(arr[0]);
            }
            geometry = new google.maps.Data.Polygon([arr]);

            this.initPolygonPointEvents();

            this.updateGeometry(geometry);
          }
        }
      );
    });
  }

  cancelDraw(): void {
    if (this.clickMapEvent) {
      this.clickMapEvent.remove();
    }
    if (this.poiPolygon) {
      this.poiPolygon.setMap(null);
    }
    if (this.poiPolyline) {
      this.poiPolyline.setMap(null);
    }
    if (this.marker) {
      this.marker.setMap(null);
    }

    this.drawingPoint = false;
    this.drawingLine = false;
    this.drawingPolygon = false;
  }

  initLinePointEvents(): void {
    if (this.clickPointEvent) {
      this.clickPointEvent.remove();
    }

    this.clickPointEvent = this.poiPolyline.addListener('click', (e) => {
      const latlng = e.latLng;
      const arr = this.poiPolyline.getPath().getArray();

      const index = arr.findIndex(
        (ll) => ll.lng === latlng.lng && ll.lat === latlng.lat
      );
      if (index === -1) {
        return;
      }
      arr.splice(index, 1);

      this.poiPolyline.setPath(arr);
      this.updateGeometry(
        this.poiPolyline.getPath().getLength() > 1
          ? new google.maps.Data.LineString(
              this.poiPolyline
                .getPath()
                .getArray()
                .map((latLng) => latLng.toJSON())
            )
          : null
      );
    });

    if (this.mouseUpEvent) {
      this.mouseUpEvent.remove();
    }
    this.mouseUpEvent = this.poiPolyline.addListener('mouseup', () => {
      const geometry = new google.maps.Data.LineString(
        this.poiPolyline
          .getPath()
          .getArray()
          .map((latLng) => latLng.toJSON())
      );
      this.poiSrv.setPoiFeature(
        new google.maps.Data.Feature({
          ...this.feature,
          geometry: geometry,
        }),
        this.state ? this.state : POI_STATES.ADD
      );
    });
  }

  initPolygonPointEvents(): void {
    if (this.clickPointEvent) {
      this.clickPointEvent.remove();
    }

    this.clickPointEvent = this.poiPolygon.addListener('click', (e) => {
      const latlng = e.latLng;
      const arr = this.poiPolygon.getPath().getArray();

      const index = arr.findIndex(
        (ll) => ll.lng === latlng.lng && ll.lat === latlng.lat
      );
      if (index === -1) {
        return;
      }

      arr.splice(index, 1);

      this.poiPolygon.setPath(arr);

      this.updateGeometry(
        this.poiPolygon.getPath().getLength() > 2
          ? new google.maps.Data.Polygon([arr])
          : null
      );
    });

    this.poiPolygon.addListener('dblclick', () => {
      this.saveFeature.emit(true);
    });

    this.mouseUpEvent = this.poiPolygon.addListener('mouseup', () => {
      const geometry = new google.maps.Data.Polygon([
        this.poiPolygon
          .getPath()
          .getArray()
          .map((latLng) => latLng.toJSON()),
      ]);

      this.updateGeometry(geometry);
    });
  }

  updateGeometry(geometry): void {
    if (this.feature) {
      const feat = new google.maps.Data.Feature({ ...this.feature });
      this.feature.forEachProperty((prop, key) => {
        feat.setProperty(key, prop);
      });
      feat.setGeometry(geometry);
      setTimeout(() => {
        this.poiSrv.setPoiFeature(
          feat,
          this.state ? this.state : POI_STATES.ADD
        );
      });
    } else {
      this.poiSrv.setPoiFeature(
        new google.maps.Data.Feature({
          geometry: geometry,
        }),
        this.state ? this.state : POI_STATES.ADD
      );
    }
  }
}
