import { GeoDataMetaData } from './models';

export class ImportModel {
  _type: ImportTypes = 'unknown';
  manufacture = 'unknown';
  files: File[] = null;
  metadata: GeoDataMetaData = [
    {
      key: 'fileTypes',
      value: '',
    },
    {
      key: 'tag',
      value: '',
    },
  ];

  get type(): ImportTypes {
    return this._type;
  }

  set type(newType: ImportTypes) {
    this._type = newType;

    if (newType === 'prescription') {
      this.metadata = [
        ...this.metadata,
        {
          key: 'nutrition',
          value: '100',
        },
        {
          key: 'valueColumn',
          value: 'value',
        },
      ];
    }
  }

  addMetadata(key: string, value: string): void {
    const data = this.metadata.find((data) => data.key === key);

    data
      ? (data.value = value)
      : this.metadata.push({
          key,
          value,
        });
  }
}

export type ImportTypes =
  | 'nsensor'
  | 'prescription'
  | 'soilsampling'
  | 'veris'
  | 'yield'
  | 'unknown'
  | 'sensordata'
  | 'common'
  | 'fieldboundary'
  | 'asapplied';
