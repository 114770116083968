<div>
    <h2 mat-dialog-title>
        {{ 'Categorys' | dvt }}
    </h2>
    <div *ngFor="let cat of categorys; let i = index" class="cat-edit buttons">
        <mat-form-field appearance="outline">
            <input id="{{ 'cat' + i }}" [disabled]="!cat.edit" [(ngModel)]="cat.namn" matInput />
        </mat-form-field>
        <button mat-icon-button (click)="cat.edit = true; setFocus('cat' + i)" *ngIf="cat.edit !== true">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="cat.edit = false" color="accent" *ngIf="cat.edit === true">
            <mat-icon>save</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteCat(cat)" color="warn">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
    <div class="cat-edit add">
        <label>{{ 'New category' | dvt }}</label>
        <div class="row">
            <mat-form-field appearance="outline">
                <input
                    id="new-input"
                    placeholder="{{ 'New category...' | dvt }}"
                    [(ngModel)]="newCatName"
                    matInput
                />
            </mat-form-field>

        </div>
        <button
            mat-raised-button
            class="save-button"
            color="primary"
            mat-raised-button
            (click)="addNew(newCatName)"
            [disabled]="!newCatName || newCatName.length === 0"
        >
            {{ 'Save' | dvt }}
        </button>
    </div>
</div>
