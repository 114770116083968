import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ImportModel } from 'app/models/import.model';

@Component({
  selector: 'dv-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.css'],
})
export class CommonComponent {
  @Input() importModel: ImportModel = null;
  @Output() importChange = new EventEmitter<ImportModel>();

  files(ev: File[]): void {
    if (this.importModel) {
      this.importModel.files = ev;
      this.importChange.next(this.importModel);
    }
  }
}
