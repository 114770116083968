export function compare(a: number | string, b: number | string, isAsc: boolean) {
    if(a == null && b != null)
    return 1;
    else if(a != null && b == null)
    return -1;

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function applyFilter(event: Event, matData) {
    const filterValue = (event.target as HTMLInputElement).value;
    clearTimeout(this.filterTimeout);
    this.filterTimeout = setTimeout(
        () => {
            if (filterValue.length === 0) {
                this.currentSearchTerm = '';
                matData.filter = this.randomString;
            } else {
                matData.filter = filterValue.trim().toLowerCase();
            }
        },
        filterValue && filterValue.length > 0 ? 200 : 0
    );
}
export function getNameFromFile(filename: string){
    return filename.split('\\')[filename.split('\\').length-1];
}