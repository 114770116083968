<div class="parent" [class.hidden]="activeState === POI_STATES.CATEGORY">
  <p>{{ 'Draw marking' | dvt }}</p>
  <div class="content">
    <div class="start-draw">
      <span (click)="drawPoint()">
        <button [class.active]="drawingPoint" mat-icon-button>
          <mat-icon>gps_fixed</mat-icon>
        </button>
        {{ 'Punkt' | dvt }}
      </span>
      <span (click)="drawLine()">
        <button [class.active]="drawingLine" mat-icon-button>
          <mat-icon>timeline</mat-icon>
        </button>
        {{ 'Linje' | dvt }}
      </span>
      <span (click)="drawPolygon()">
        <button [class.active]="drawingPolygon" mat-icon-button>
          <mat-icon>crop_landscape</mat-icon>
        </button>
        {{ 'Yta' | dvt }}
      </span>
    </div>
  </div>
</div>
