import { Component, Input, OnInit, Output } from '@angular/core';
import { SkifteDetielsModel } from 'app/models/api.models';
import { EventEmitter } from '@angular/core';


@Component({
    selector: 'dv-table-expanded-row',
    templateUrl: './table-expanded-row.component.html',
    styleUrls: ['./table-expanded-row.component.scss'],
})
export class TableExpandedRowComponent implements OnInit {
    @Input() skifte: SkifteDetielsModel;
    @Input() loading: boolean;
    @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEditBorders: EventEmitter<any> = new EventEmitter<any>();
    constructor() {}

    ngOnInit(): void {}

    clear() {
        this.onClear.emit();
    }
    save() {
        this.onSave.emit();
    }
    delete() {
        this.onDelete.emit();
    }
    editBorders() {
        this.onEditBorders.emit();
    }
}
