import {
  Component,
  Input,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { PartOption } from '../PartOption';
import * as turf from '@turf/turf';
import { Subscription } from 'rxjs';
import { MapToolComponent } from '../map-tool';
import { SiteService } from 'app/services/site.service';
import {
  MapService,
  LAYER_NAME,
  LAYER_SELECTED,
  COLORS,
  LAYER_ZINDEX,
} from '../../map.service';
import { ClientService } from 'app/services/client.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-map-connect-field',
  templateUrl: 'map-connect-field.component.html',
  styleUrls: ['map-connect-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapConnectFieldComponent
  extends MapToolComponent
  implements OnDestroy
{
  tillgangligaAr: number[] = null;
  ar: number = null;
  currentAr: number;
  private onSelectedSub: Subscription;
  private oldMapMultiselect: boolean;

  @Input() multiSelect: boolean;
  @Input() newSkifte = false;
  constructor(
    siteService: SiteService,
    mapService: MapService,
    cd: ChangeDetectorRef,
    zone: NgZone,
    translateService: DvToolbarTranslateService,
    private clientService: ClientService
  ) {
    super(siteService, mapService, cd, zone, translateService);
  }

  protected onMapInit(): void {
    this.clientService.clientAr().subscribe((ar) => {
      this.currentAr = ar;
    });
    this.clientService.client().subscribe((klient) => {
      this.tillgangligaAr = klient.tillgangligaAr.sort((a, b) => b - a);
    });

    this.onSelectedSub = this.dvMap.onSelected.subscribe(
      (f: google.maps.Data.Feature) => {
        if (f.getProperty(LAYER_NAME) === 'connect') {
          this.dvMap.map.data.remove(f);
          const index = this.parts.findIndex((p) => {
            return p.feature.id === f.getId();
          });
          this.parts.splice(index, 1);
          this.parts = [].concat(this.parts);
          this.cd.detectChanges();
        } else if (this.isActive) {
          //if we have a skifte that we shall connect to we only work with one feature at time
          //so clear out the previus
          if (this.skifteId) {
            this.dvMap.removeLayer('connect');
            this.parts = [];
          }
          f.setProperty(LAYER_SELECTED, false);
          this.dvMap.getGeoJson([f]).subscribe((feature) => this.add(feature));
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.onSelectedSub) {
      this.onSelectedSub.unsubscribe();
    }

    this.dvMap.removeLayer('connect');
  }

  /**
   * Called when the tool sould be actived
   */
  onActive(): void {
    this.dvMap.disableSelect = false;
    this.oldMapMultiselect = this.dvMap.multiSelect;
    this.dvMap.multiSelect = this.multiSelect;
    this.activated.next();
  }

  /**
   * Called when the tool is deactivated ie not active
   */
  onDeActivated(): void {
    this.dvMap.disableSelect = true;
    this.dvMap.multiSelect = this.oldMapMultiselect;
    this.dvMap.removeLayer('connect');
    this.dvMap.removeLayer('skifte-ar');
    this.parts = [];
    this.cancel.emit();
  }

  onSave(parts: PartOption[]): void {
    this.save.emit(parts);
  }

  private add(
    featureCollection: GeoJSON.FeatureCollection<GeoJSON.GeometryObject>
  ): void {
    featureCollection.features[0].id = 1000000 + this.parts.length + '';

    const f = this.dvMap.addGeoJson(featureCollection, 'connect');
    f[0].setProperty('color', COLORS[this.parts.length % COLORS.length]);
    f[0].setProperty(LAYER_ZINDEX, this.dvMap.zIndex(f[0]) + 100000);

    f.forEach(() => {
      this.parts.unshift({
        areal:
          Math.round(
            this.siteService.calculateArea(
              turf.area(<any>featureCollection.features[0])
            ) * 100
          ) / 100,
        block: featureCollection.features[0].properties['blockNr']
          ? featureCollection.features[0].properties['blockNr']
          : 0,
        feature: featureCollection.features[0],
        action: this.skifteId && !this.newSkifte ? 'update' : 'new',
        featureText: null,
        color: COLORS[this.parts.length % COLORS.length],
      });
    });

    this.parts = [].concat(this.parts);
    this.cd.markForCheck();
  }

  arSelected(ar: number): void {
    this.ar = ar;

    this.clientService.getSkifteLayer().subscribe((fc) => {
      this.dvMap.removeLayer('skifte-ar');
      this.dvMap.fitFeature(this.dvMap.addGeoJson(fc, 'skifte-ar'));
    });
  }
}
