import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileImportStatusLogEntry } from 'app/models/filestatus.model';

@Component({
  selector: 'dv-file-status-log',
  templateUrl: './file-status-log.component.html',
  styleUrls: ['./file-status-log.component.scss'],
})
export class FileStatusLogComponent {
  @Input() log: FileImportStatusLogEntry[];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { log: FileImportStatusLogEntry[] }
  ) {
    this.log = data.log?.slice().filter((entry) => !!entry.message) ?? [];
  }
}
