<div class="deviations">
  <div
    class="deviation-container"
    *ngFor="let deviation of deviations$ | async; let i = index"
  >
    <button
      mat-icon-button
      class="close"
      (click)="deleteDeviation(deviation)"
      [class.selected]="i === currentIndex"
    >
      <mat-icon>close</mat-icon>
    </button>
    <div
      class="deviation value"
      [class.selected]="i === currentIndex"
      (click)="setActiveDeviation(i)"
    >
      <div [ngStyle]="{ 'background-color': deviation.color }">&nbsp;</div>
      <input
        [(ngModel)]="deviation.deviationRate"
        type="number"
        (click)="$event.stopPropagation()"
      />
    </div>
  </div>
  <button mat-icon-button class="deviation add" (click)="addDeviation()">
    <mat-icon>add</mat-icon>
  </button>
</div>
