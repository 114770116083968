import { Component, Input, OnDestroy } from '@angular/core';
import { CropBasisModel } from 'app/models/models';
import { MapStateService } from 'app/services/map-state.service';
import { PropertyInfoModel } from 'app/models/models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'dv-interpolation-basis',
  templateUrl: './interpolation-basis.component.html',
  styleUrls: ['./interpolation-basis.component.scss'],
})
export class InterpolationBasisComponent implements OnDestroy {
  @Input() interpolationType: string;
  @Input() interpolationProperty: PropertyInfoModel;

  cropTypes: string[];
  cropsBasis: CropBasisModel[] = [];
  private _unsub$: Subject<void> = new Subject<void>();

  constructor(private mapStateSrv: MapStateService) {
    this.mapStateSrv
      .getSelectedFeatures()
      .pipe(takeUntil(this._unsub$))
      .subscribe((features) => {
        if (!features || features.features?.length == 0) {
          this.cropTypes = [];
        } else {
          this.cropTypes = features.features
            .map((parcel) => parcel.properties.groda)
            .filter((x, i, a) => a.indexOf(x) === i);
        }

        if (this.cropTypes.length > 1) {
          this.cropTypes.forEach((crop) => {
            if (this.cropsBasis.findIndex((basis) => basis.crop === crop) < 0) {
              this.cropsBasis.push({
                crop,
                features: features.features.filter(
                  (parcel) => parcel.properties.groda === crop
                ),
                interpolation: null,
                color: features.features.filter(
                  (parcel) => parcel.properties.groda === crop
                )[0].properties.color,
                error: null,
              });
            }
          });
        }
      });
  }

  ngOnDestroy(): void {
    this._unsub$?.next();
    this._unsub$?.complete();
  }
}
