import { Routes } from '@angular/router';
import { APP_PATH } from 'app/app.types';
import { ClientComponent } from 'app/components/client/client.component';
import { MapViewComponent } from 'app/components/map-view/map-view.component';
import { DataPageComponent } from 'app/data-page/data-page.component';
import { GeodataDetailComponent } from 'app/data-page/geodata-detail/geodata-detail.component';
import { NoDataComponent } from 'app/data-page/no-data/no-data.component';
import { FieldListPageComponent } from 'app/field-list-page/field-list-page.component';
import { FieldPageComponent } from 'app/field-page/field-page.component';
import { AuthGuardService } from 'app/guards/auth/auth.guard';
import { LoginPageComponent } from 'app/login-page/login-page.component';
import { MapPageComponent } from 'app/map-page/map-page.component';
import { MapSplitFieldPickPageComponent } from 'app/map-split-page/map-split-field-pick/map-split-field-pick-page.component';
import { MapSplitPageComponent } from 'app/map-split-page/map-split-page.component';
import { PrescriptionPageComponent } from 'app/prescription-page/prescription-page.component';
import { SharePageComponent } from 'app/share-page/share-page.component';
import { PrescriptionWizardPageComponent } from 'app/views/prescription-wizard-page/prescription-wizard-page.component';
import { RedirectFromMarkkarteringComponent } from './views/redirect/redirectfrommarkkartering.component';

export const APP_ROUTES: Routes = [
  {
    path: `${APP_PATH.CLIENT}/:clientId`,
    component: ClientComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: APP_PATH.MAP,
        component: MapViewComponent,
        children: [
          { path: APP_PATH.MAPPING, component: MapPageComponent },
          { path: `${APP_PATH.MAPPING}/:year`, component: MapPageComponent },
          {
            path: `${APP_PATH.MAPPING}/:year/:fieldId`,
            component: MapPageComponent,
          },
          { path: APP_PATH.PRESCRIPTION, component: PrescriptionPageComponent },
          { path: '**', redirectTo: APP_PATH.MAPPING },
        ],
      },
      {
        path: APP_PATH.PRESCRIPTION_WIZARD,
        component: PrescriptionWizardPageComponent,
      },
      {
        path: APP_PATH.ANALYZE,
        canActivate: [AuthGuardService],
        children: [
          {
            path: APP_PATH.PICK,
            component: MapSplitFieldPickPageComponent,
            data: { hideFab: true, hidePoi: true, hideFieldfilter: true },
          },
          {
            path: APP_PATH.SPLIT,
            component: MapSplitPageComponent,
            data: {
              hideFab: true,
              hidePoi: true,
              showModeBtn: true,
              hideFieldfilter: true,
            },
          },
          { path: '**', redirectTo: APP_PATH.PICK },
        ],
      },
      {
        path: APP_PATH.FIELD,
        component: FieldPageComponent,
        data: { hideFab: true, hidePoi: true, hideFieldfilter: true },
      },
      {
        path: APP_PATH.FIELD_LIST,
        component: FieldListPageComponent,
        data: { hidePoi: true },
      },
      { path: `${APP_PATH.SHARE}/:key`, component: SharePageComponent },
      {
        path: APP_PATH.DATA,
        children: [
          {
            path: APP_PATH.DETAIL,
            component: MapViewComponent,
            children: [
              {
                path: `${APP_PATH.GEODATA}/:id`,
                component: GeodataDetailComponent,
              },
              { path: APP_PATH.NO_DATA, component: NoDataComponent },
            ],
          },
          { path: `${APP_PATH.FILESET}/:id`, component: DataPageComponent },
          { path: ':tab', component: DataPageComponent },
          { path: '**', component: DataPageComponent },
        ],
      },
      { path: '**', redirectTo: APP_PATH.MAP },
    ],
  },
  { path: `${APP_PATH.SHARE}/:key`, component: SharePageComponent },
  { path: APP_PATH.LOGIN, component: LoginPageComponent },
  {
    path: `${APP_PATH.REDIRECT}/orgnumber/:orgnumber`,
    component: RedirectFromMarkkarteringComponent,
  },
  { path: '**', redirectTo: '' },
];
