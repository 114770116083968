<dv-sliding-pane [showPane]="showManualDrawPane">
  <div class="content">
    <dvm-text class="title" h2>
      {{ '_prescription_adjustment_manual' | dvt : '_draw manually' }}
    </dvm-text>
    <dvm-text label>
      {{
        '_manual_draw_explanation'
          | dvt
            : '_Click on the grid in the map where you want to adjust the rate. When you save, the map and legend will be recalculated on save.'
      }}
    </dvm-text>
    <dv-adjustments-manual-draw [dvMap]="dvMap"></dv-adjustments-manual-draw>
  </div>
  <div class="buttons">
    <dvm-button
      class="close-button"
      size="large"
      secondary
      (click)="closePane()"
    >
      {{ 'Close' | dvt }}
    </dvm-button>
    <dvm-button
      class="close-button"
      size="large"
      primary
      (click)="closePane(true)"
    >
      {{ 'Save' | dvt }}
    </dvm-button>
  </div>
</dv-sliding-pane>
