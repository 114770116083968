import { Component } from '@angular/core';
import MarkerClusterer from '@google/markerclusterer';
import { MapPageBase } from 'app/components/map/map-page.base';
import { MapComponent } from 'app/components/map/map.component';
import {
  LAYER_NAME,
  LAYER_NAME_INTERPOLATION,
  LAYER_NAME_MARKERING,
  LAYER_NAME_SKIFTEN,
  LAYER_SELECTED,
  MapService,
} from 'app/components/map/map.service';
import { MappingWizardService } from 'app/components/mapping-view/mapping-wizard/mapping-wizard.service';
import { InterpolationModel } from 'app/models/interpolation.model';
import { ClientService } from 'app/services/client.service';
import { MapStateService } from 'app/services/map-state.service';
import { MapUtilService, ValuePointModel } from 'app/services/map-util.service';
import { combineLatest, Subject } from 'rxjs';
import { first, flatMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dv-map-page',
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.scss'],
})
export class MapPageComponent extends MapPageBase {
  mapName = 'map';
  private _unsub$: Subject<void> = new Subject<void>();
  private _skiften: GeoJSON.FeatureCollection;

  private _filterSkiften: GeoJSON.FeatureCollection;
  private _pointCluster: MarkerClusterer;
  selectedIds: number[] = [];
  mapBounds$: google.maps.MapsEventListener;
  mapBoundsSub = new Subject();
  validZoom = false;
  feature: google.maps.Data.Feature = null;
  loading = false;
  activeInterpolation: InterpolationModel;
  cropsatData: GeoJSON.FeatureCollection;
  pointOverlay: google.maps.GroundOverlay = null;
  clientId?: number;
  private activeIndex: number;
  private INDEX_FIELDS = 0;

  constructor(
    mapService: MapService,
    private mapStateSrv: MapStateService,
    private clientSrv: ClientService,
    private wizSrv: MappingWizardService,
    private mapUtilService: MapUtilService
  ) {
    super(mapService);
  }

  onMapCleanUp(): void {
    this.dvMap.clearMap([LAYER_NAME_MARKERING]);
    this.hideValuePoints();
    this._unsub$.next();
    this._unsub$.complete();
  }

  onMapInit(dvMap: MapComponent): void {
    dvMap.multiSelect = true;

    this.mapBounds$ = google.maps.event.addListener(
      dvMap.map,
      'bounds_changed',
      (ev) => {
        //we add the event to a sub so we can use a debounceTime
        this.mapBoundsSub.next(ev);
        this.validZoom = dvMap.map.getZoom() > 13;
      }
    );

    this.wizSrv
      .getActiveIndex()
      .pipe(takeUntil(this._unsub$))
      .subscribe((index) => {
        this.activeIndex = index;
      });

    this.clientSrv
      .clientAr()
      .pipe(
        flatMap(() => combineLatest([this.clientSrv.getSkifteLayer()])),
        takeUntil(this._unsub$)
      )
      .subscribe(([skiften]) => {
        this.mapStateSrv.resetInterpolationFeatures();
        this.hideInterpolation(dvMap);
        this._skiften = skiften;
        this._filterSkiften = skiften;
      });

    this.clientSrv
      .clientId()
      .pipe(takeUntil(this._unsub$))
      .subscribe((id) => (this.clientId = id));

    this.wizSrv
      .getReloadFields()
      .pipe(first())
      .subscribe((refresh) => {
        if (refresh && this.activeIndex === this.INDEX_FIELDS) {
          if (this.activeIndex === this.INDEX_FIELDS) {
            this.removeSkiften(dvMap);
            this.addSkiften(dvMap, this._skiften);
            this.wizSrv.setReloadFields(false);
          }
        }
      });

    this.mapStateSrv
      .getSelectedFeatures()
      .pipe(takeUntil(this._unsub$))
      .subscribe((feat) => {
        dvMap.getSelectedFeatures();
        if (!feat && dvMap.getSelectedFeatures()) {
          this.mapService.deselectAllFeatures(dvMap);
        } else if (feat) {
          feat.features.forEach((f) => {
            this.selectSkifte(f.id);
          });
        }
        this._filterSkiften = feat?.features.length ? feat : this._skiften;
      });

    this.mapStateSrv
      .getInterpolation()
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        this.activeInterpolation = res;
        this.hideInterpolation(dvMap);
        if (res) {
          if (res['img'] !== undefined) {
            this.showOverlay(dvMap, res);
          } else {
            this.showInterpolation(dvMap, res);
          }
        }
      });

    this.mapStateSrv
      .getValuePoints()
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        this.hideValuePoints();
        if (res) {
          this.drawValuePoints(res);
        }
      });

    dvMap.onSelected
      .pipe(takeUntil(this._unsub$))
      .subscribe((f: google.maps.Data.Feature) => {
        if (this.activeInterpolation || this.cropsatData) {
          //to stop interpolation from breaking
          return;
        }
        if (f.getProperty(LAYER_NAME) == LAYER_NAME_SKIFTEN) {
          this.mapStateSrv.setSelectedFeature(f);
          this.selectedIds = [...this.selectedIds, <number>f.getId()];
        } else if (f.getProperty(LAYER_NAME) === LAYER_NAME_MARKERING) {
          this.mapStateSrv.setSelectedMarking(f);
        }
      });

    dvMap.onDeSelected
      .pipe(takeUntil(this._unsub$))
      .subscribe((f: google.maps.Data.Feature) => {
        if (this.activeInterpolation || this.cropsatData) {
          //to stop interpolation from breaking
          return;
        }

        //check if something new is selected first
        if (f.getProperty(LAYER_NAME) == LAYER_NAME_SKIFTEN) {
          this.mapStateSrv.unsetSelectedFeature(f);
          this.hideInterpolation(dvMap);
          this.selectedIds = this.selectedIds.filter(
            (s: string | number) => s != f.getId()
          );
        } else if (f.getProperty(LAYER_NAME) == LAYER_NAME_MARKERING) {
          this.mapStateSrv.unsetSelectedMarking(f);
        }
      });
  }

  hideValuePoints(): void {
    if (this._pointCluster) {
      this._pointCluster.clearMarkers();
    }
  }

  drawValuePoints(pts: GeoJSON.FeatureCollection): void {
    if (
      !pts ||
      !this._filterSkiften ||
      this._filterSkiften.features.length === 0
    ) {
      return;
    }

    let points = this.mapUtilService.getValuePoints(pts);

    //filter all points outside interpolation (if not all skiften are chosen, then show all points)
    if (this._filterSkiften.features.length !== this._skiften.features.length) {
      points = this.mapUtilService.getValuePointsInsideInterpolation(
        points,
        this._filterSkiften.features
      );
    }

    this.drawMarker(points);
  }

  drawMarker(points: ValuePointModel[]): void {
    const datapoints = this.mapUtilService.getValuePointLabels(points);
    this._pointCluster = this.mapUtilService.drawValuePointLabelsOnMap(
      datapoints,
      this.dvMap.map
    );
  }

  removeSkiften(dvMap: MapComponent): void {
    dvMap.removeLayer(LAYER_NAME_SKIFTEN);
  }

  addSkiften(
    dvMap: MapComponent,
    skiften: GeoJSON.FeatureCollection,
    zoom = true
  ): void {
    const sk = dvMap.addGeoJson(skiften, LAYER_NAME_SKIFTEN);
    this.showSkiften();
    if (zoom) {
      dvMap.fitFeature(sk, true);
    }
  }

  hideSkiften(): void {
    const skiften = this.dvMap.getFeatures(LAYER_NAME_SKIFTEN);
    skiften.forEach((f) => {
      //set transparancy very transparent
      f.setProperty('strokeOpacity', 0.5);
      if (f.getProperty(LAYER_SELECTED)) {
        f.setProperty('fillOpacity', 0.00000001);
        f.setProperty('strokeOpacity', 0.8);
      } else {
        f.setProperty('fillOpacity', 0.3);
      }
    });
  }

  showSkiften(): void {
    const skiften = this.dvMap.getFeatures(LAYER_NAME_SKIFTEN);
    skiften.forEach((f) => {
      //set transparancy to opaque
      f.setProperty('strokeOpacity', 0.8);
      f.setProperty('fillOpacity', 0.8);
    });
  }

  selectSkifte(id: number | string): void {
    const skiften = this.dvMap.getFeatures(LAYER_NAME_SKIFTEN);
    skiften.forEach((f) => {
      //set transparancy to opaque
      if (f.getId() == id) {
        f.setProperty(LAYER_SELECTED, true);
      }
    });
  }

  hideInterpolation(dvMap: MapComponent): void {
    dvMap.removeLayer(LAYER_NAME_INTERPOLATION);

    if (this.pointOverlay) {
      this.pointOverlay.setMap(null);
    }
  }

  showInterpolation(
    dvMap: MapComponent,
    interpolation: GeoJSON.FeatureCollection
  ): void {
    dvMap.fitFeature(dvMap.addGeoJson(interpolation, LAYER_NAME_INTERPOLATION));
    this.hideSkiften();
  }

  showOverlay(dvMap, data): void {
    this.pointOverlay = new google.maps.GroundOverlay(
      data.img,
      new google.maps.LatLngBounds(
        new google.maps.LatLng(data.minY, data.minX),
        new google.maps.LatLng(data.maxY, data.maxX)
      ),
      {
        clickable: false,
      }
    );
    this.pointOverlay.setMap(dvMap.map);
    this.hideSkiften();
  }
}
