import { NgModule } from "@angular/core";
import { DialogService } from "./dialog.service";
import { DialogContent } from "./content/dialog.component";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DvMaterialModule } from "app/material.module";


@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        DvMaterialModule
    ],
    providers: [
        DialogService,
    ],
    declarations: [
        DialogContent
    ],
    exports: [
        DialogContent
    ]
})
export class DialogModule { }