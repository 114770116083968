<div #dragContainer class="drag-container" [class.drag-expanded]="maxWidth > 450" [ngStyle]="{'max-width': active ? maxWidth+'px' : 'inherit'}" >
  <div class="content" #contentWrapper>
    <ng-content></ng-content>
  </div>
  <div
    *ngIf="active"
    class="drag-button"
    (click)="toggleSize()"
    id="drag-button"
  >
  <mat-icon class="drag-icon" >arrow_forward</mat-icon>
  </div>
</div>