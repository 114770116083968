<div *ngIf="!hasSelectedType">
  <h3>{{ 'What type of file do you want to upload' | dvt }}?</h3>
  <div class="import-btns-wrapper">
    <div class="import-btns">
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('dat')"
      >
        <span class="import-btn-content">
          <img class="import-btn-icon" src="assets/images/icon-sensorDAT.png" />
          {{ 'Sensordata' | dvt }}
        </span>
      </button>
    </div>
  </div>
</div>

<div *ngIf="hasSelectedType" class="file-upload">
  <dv-fileupload class="bottom-margin" (files)="files($event)"></dv-fileupload>

  <div *ngIf="needAdditionalFiles" class="bottom-margin">
    <span *ngIf="datFiles.shp === null">{{
      '_markk_saknarpunkter'
        | dvt
          : '_GPS punkter saknas, ladda upp en .shp fil för att matcha punkter'
    }}</span>
    <span *ngIf="datFiles.shp !== null">{{
      '_markk_saknarpunkter_not_shp'
        | dvt: '_Analysis file is missing. Upload the remaining files'
    }}</span>
    <mat-chip-listbox>
      <mat-chip-option
        selected
        [disableRipple]="true"
        color="{{ datFiles.shp === null ? 'warn' : '' }}"
      >
        <mat-icon *ngIf="datFiles.shp !== null">done</mat-icon> *.shp
        {{ 'fil' | dvtl }}
      </mat-chip-option>
      <mat-chip-option
        selected
        [disableRipple]="true"
        color="{{ datFiles.dbf === null ? 'warn' : '' }}"
      >
        <mat-icon *ngIf="datFiles.dbf !== null">done</mat-icon> *.dbf
        {{ 'fil' | dvtl }}
      </mat-chip-option>
      <mat-chip-option
        selected
        [disableRipple]="true"
        color="{{ datFiles.shx === null ? 'warn' : '' }}"
      >
        <mat-icon *ngIf="datFiles.shx !== null">done</mat-icon> *.shx
        {{ 'fil' | dvtl }}
      </mat-chip-option>
      <mat-chip-option
        selected
        [disableRipple]="true"
        color="{{ datFiles.cpg === null ? 'warn' : '' }}"
      >
        <mat-icon *ngIf="datFiles.cpg !== null">done</mat-icon> *.cpg
        {{ 'fil' | dvtl }}
      </mat-chip-option>
      <mat-chip-option
        selected
        [disableRipple]="true"
        color="{{ datFiles.prj === null ? 'warn' : '' }}"
      >
        <mat-icon *ngIf="datFiles.prj !== null">done</mat-icon> *.prj
        {{ 'fil' | dvtl }}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</div>
