import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, merge } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import {
  Machine,
  MachineConnectionData,
  MachineProvider,
  PrescriptionRequest,
} from './machine-connection.types';
import { DvAuthService, DvToolbarTranslateService } from '@dv/toolbar-msal';

@Injectable()
export class MachineConnectionService {
  private readonly BASE_URL = environment.machineProviderUrl;
  private readonly DEFAULT_GRID_SIZE = 20;
  private readonly EPSG = 3006;

  constructor(
    private http: HttpClient,
    private translate: DvToolbarTranslateService,
    private dvAuthService: DvAuthService
  ) {}

  getProviders(): Observable<MachineProvider[]> {
    const url = `${this.BASE_URL}api/GetAllProviders`;
    return this.http.get<MachineProvider[]>(url);
  }

  setProviders(providers: MachineProvider[]): Observable<void> {
    const url = `${this.BASE_URL}api/SetProviders`;
    const body = providers
      .filter((provider) => provider.isConnected)
      .map((provider) => provider.providerCode);
    return this.http.post<void>(url, body);
  }

  revokeProviders(providers: MachineProvider[]): Observable<unknown> {
    const requests = providers.reduce((prev, curr) => {
      const url = `${this.BASE_URL}api/revoke/${curr.providerCode}`;
      prev.push(this.http.post<unknown>(url, {}));

      return prev;
    }, []);

    return merge(...requests);
  }

  signInToProviders(): Observable<void> {
    const token = this.dvAuthService.getAccessToken();
    const subject = new Subject<void>();

    if (!token) {
      subject.error(null);
      return subject.asObservable();
    }

    window['sendFileToProviderArgs'] = {
      authText: this.translate.t('Authenticating'),
      token: token,
      loginUrl: `${this.BASE_URL}api/StartAuth`,
      callback: (): void => {
        subject.next();
        subject.complete();

        window['sendFileToProviderArgs'] = undefined; // Clean up args used for communication with auth page
      },
    };

    window.open('assets/auth-page.html', '_blank');

    return subject.asObservable();
  }

  getMachines(): Observable<Machine[]> {
    const url = `${this.BASE_URL}api/GetMachines`;

    return this.http.get<Machine[]>(url);
  }

  sendToMachine(data: MachineConnectionData): Observable<string> {
    const url = `${this.BASE_URL}api/sendFile`;
    const body: PrescriptionRequest = {
      machineId: data.machineId,
      fileName: data.fileName,
      geoJson: btoa(JSON.stringify(data.prescription.fc)),
      sourceMetadata: {
        epsg: this.EPSG,
        gridSize: data.prescription.gridSize ?? this.DEFAULT_GRID_SIZE,
        unit: data.unit,
      },
    };

    return this.http.post(url, body, {
      responseType: 'text',
    });
  }
}
