<div class="drop-area" dvFiledrop (changed)="selectedFiles($event)">
    {{ singleFileOnly ? ('drop file here' | dvt) : ('drop files here' | dvt) }}
    <label for="file-input"> <mat-icon>cloud_upload</mat-icon> {{ 'select fil' | dvt }} </label>
</div>
<div *ngIf="!shpHelper.valid() && !disableShpHelper" style="margin-top: 15px">
    {{ '_missing_files_need_more' | dvt: '_more files are needed for this file type' }}
    <mat-chip-listbox>
        <mat-chip-option selected [disableRipple]="true" *ngFor="let hint of shpHelper.hints" [color]="hint.color">
            <mat-icon>done</mat-icon> {{ hint.ext }} {{ 'fil' | dvtl }}
        </mat-chip-option>
    </mat-chip-listbox>
</div>
<input
    type="file"
    [multiple]="!singleFileOnly"
    [accept]="fileTypes"
    id="file-input"
    style="display: none"
    (change)="filePicked($event)"
/>
<span class="error-text" color="warn" *ngIf="singleFileError">{{ 'You can only upload one file at a time' | dvt }}</span>
