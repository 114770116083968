import { Component } from '@angular/core';
import { ClientService } from 'app/services/client.service';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../dialog/dialog.service';
import { IBaseDialog } from '../dialog/content/DialogModels';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-new-year',
  templateUrl: './new-year.component.html',
  styleUrls: ['./new-year.component.scss'],
})
export class NewYearComponent implements IBaseDialog {
  isCreating = false;
  data: { from: number; to: number };
  title: string;

  constructor(
    private dialogService: DialogService,
    private clientService: ClientService,
    private dialogRef: MatDialogRef<NewYearComponent>,
    private translateService: DvToolbarTranslateService
  ) {
    this.title = this.translateService.t('New year');
  }

  copy(): void {
    this.isCreating = true;

    this.clientService
      .startNewYear(this.data.from, this.data.to)
      .subscribe((res) => {
        this.clientService
          .createNewYear(this.data.from, this.data.to, res)
          .subscribe(
            () => {
              this.dialogRef.close({ result: true });
              this.dialogService.message(
                this.translateService.t('New year created successfully')
              );
            },
            () => {
              this.dialogRef.close({ result: false });
              this.dialogService.message(
                this.translateService.t(
                  "Something went wrong. Can't create year."
                )
              );
            }
          );
      });
  }
}
