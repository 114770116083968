import { KeyValue } from '@angular/common';
import { FeatureCollection } from '@turf/turf';
import { SelectableFieldGridModel } from 'app/services/field-interpolation.service';
import { ImportTypes } from './import.model';
import { InterpolationModel } from './interpolation.model';
import { PrescriptionModel } from './models';
import { FilteredLayerModel } from './parcell-info.model';

export interface PrescriptionGridModel {
  fc: FeatureCollection | GeoJSON.FeatureCollection;
  geoDataFileIds: number[];
  type: string;
  property: string;
  prescription: PrescriptionModel;

  functionId: number;
  minRate: number;
  maxRate: number;
  targetYield: number;
  cutOffPoint: number;
}

export interface PrescriptionFileModel {
  fc: FeatureCollection | GeoJSON.FeatureCollection;
  average: number;
  total: number;
  bucketCount: number;
  gridSize: number;
  content: number;
  fileName: string;
  fileType: string;
  unit: string;
  settings: PrescriptionModel;
  adjustedWithContent: boolean;
}

export interface PrescriptionUntypedFileModel {
  featureCollection: FeatureCollection | GeoJSON.FeatureCollection;
  fileName: string;
  manufacturer: string;
  metadata?: KeyValue<string, string>[];
  type: ImportTypes;
}

export enum PrescriptionUnit {
  kg,
  liter,
  ton,
}

export enum PrescriptionType {
  fertilizer,
  seed,
  plantprotection,
  lime,
}
export interface PrescriptionFunctionModel {
  id: number;
  activeIngredient: string;
  cutoffPoint: boolean;
  functionName: string;
  functionType: string;
  maxRate: boolean;
  minRate: boolean;
  variables: PrescriptionFunctionVariableModel[];
  yield: boolean;
}
export interface PrescriptionFunctionVariableModel {
  id: number;
  layerType: string;
  layerField: string;
}
export interface PrescriptionBasisModel {
  interpolation: InterpolationModel;
  selectedParcels: GeoJSON.FeatureCollection;
  selectedData: SelectableFieldGridModel[];
  type: string;
  property: string;
}
