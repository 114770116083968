import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import {
  InterpolationCollection,
  InterpolationModel,
  MappingLegendModel,
} from 'app/models/interpolation.model';
import {
  FieldGridModel,
  GeoDataFileModel,
  PrescriptionModel,
  PropertyInfoModel,
} from 'app/models/models';
import { FieldInterpolationService } from 'app/services/field-interpolation.service';
import { MapStateService } from 'app/services/map-state.service';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AnalyzeService } from 'app/services/analyze.service';
import { AnalyzeFieldModel } from 'app/models/analyze-field.model';
import { DialogService } from 'app/components/dialog/dialog.service';
import { GeodataListShareDialogComponent } from 'app/components/data/geodata/geodata-list/geodata-list-share-dialog/geodata-list-share-dialog.component';
import { ShareDialogResult } from 'app/components/data/fileset/fileset-list/fileset-list-share-dialog/fileset-list-share-dialog-types';
import { DataSharePayload, ShareService } from 'app/services/share.service';
import { DataFileService } from 'app/services/data-file.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrescriptionService } from 'app/services/prescription.service';
import { FILE_TYPE } from 'app/models/file.model';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-field-interpolation',
  templateUrl: './field-interpolation.component.html',
  styleUrls: ['./field-interpolation.component.scss'],
})
export class FieldInterpolationComponent
  implements OnDestroy, AfterViewInit, OnInit
{
  type: string;
  properties: PropertyInfoModel[] = [];
  selectedProperty: PropertyInfoModel;
  error: string;
  presExport: boolean;
  legend: MappingLegendModel = null;
  interpolationLoading = false;
  currentInterpolation: InterpolationModel;
  exportOnly = true;
  private boundary: GeoJSON.FeatureCollection;
  private _unsub$ = new Subject<void>();

  constructor(
    public interpolationSrv: FieldInterpolationService,
    private mapStateSrv: MapStateService,
    private cd: ChangeDetectorRef,
    private translateSrv: DvToolbarTranslateService,
    private analyzeSrv: AnalyzeService,
    private dialogSrv: DialogService,
    private shareSrv: ShareService,
    private dataFileSrv: DataFileService,
    private snackBar: MatSnackBar,
    private prescriptionSrv: PrescriptionService
  ) {}

  ngOnInit(): void {
    this.mapStateSrv
      .getSelectedFeatures()
      .pipe(takeUntil(this._unsub$))
      .subscribe((boundary) => (this.boundary = boundary));
  }

  ngAfterViewInit(): void {
    this.trackGeodata();
    this.trackSelectedProperty();
    this.trackPropertyAndTypeAndMode();
    this.trackPointLabelState();
    this.presExport = this.selectedProperty.type === FILE_TYPE.Prescription;
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
    this.resetInterpolation();
  }

  private trackGeodata(): void {
    this.interpolationSrv
      .getInterpolatableGeodata()
      .pipe(takeUntil(this._unsub$))
      .subscribe((geodata: FieldGridModel[]) => {
        if (geodata) {
          this.type = geodata[0].fileType;
          this.init();
        } else {
          this.interpolationSrv.resetInterpolationType();
        }
      });
  }

  /** init interpolation when geodata selection,
   * property selection or interpolation mode changes  */
  private trackPropertyAndTypeAndMode(): void {
    combineLatest([
      this.interpolationSrv.getSelectedGeodata(),
      this.interpolationSrv.getInterpolationProperties(),
      this.interpolationSrv.getInterpolationProperty(),
      this.interpolationSrv.getInterpolationMode(),
      this.interpolationSrv.disabledFields$,
    ])
      .pipe(takeUntil(this._unsub$))
      .subscribe(([geodataids, props, property, mode, disabledFields]) => {
        this.properties = props;
        if (props?.length > 0) {
          if (!property || !props.some((p) => p == this.selectedProperty)) {
            this.interpolationSrv.setInterpolationProperty(props[0]);
          }
          this.interpolate(disabledFields);
        } else {
          this.printError(
            this.translateSrv.t(
              '_error_interpolation_nothing to interpolate',
              '_Nothing to interpolate. Make sure there are selected files under Settings and that they contains data for the selected parcels shown on the map'
            )
          );
          this.resetInterpolation();
        }
      });
  }

  private trackSelectedProperty(): void {
    this.interpolationSrv
      .getInterpolationProperty()
      .pipe(takeUntil(this._unsub$))
      .subscribe((property) => {
        this.selectedProperty = property;
      });
  }

  private trackPointLabelState(): void {
    /** hid/show data labels when mode changes */
    this.interpolationSrv
      .getShowPointLabels()
      .pipe(takeUntil(this._unsub$))
      .subscribe(() => this.getDataLabels());
  }

  private init(): void {
    if (
      this.type === FILE_TYPE.NSensor ||
      this.type === FILE_TYPE.Prescription ||
      this.type === FILE_TYPE.AsApplied
    ) {
      this.interpolationSrv.selectMostRecentLayer();
    } else {
      this.interpolationSrv.setGeodataFromYear();
    }
  }

  private interpolate(disabledFields: number[]): void {
    const boundaries = {
      features: this.boundary.features.filter(
        (boundary) => !disabledFields.includes(boundary.id as number)
      ),
      bbox: this.boundary.bbox,
      type: this.boundary.type,
    };

    if (
      !boundaries ||
      !boundaries.features ||
      boundaries.features.length === 0
    ) {
      return;
    }
    this.interpolationLoading = true;
    this.interpolationSrv
      .getInterpolation(boundaries)
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        (interpolation) => {
          if (
            !interpolation ||
            !interpolation.interpolations ||
            interpolation.interpolations.features?.length === 0
          ) {
            if (interpolation && interpolation['Message']) {
              this.parseInterpolationError(interpolation['Message']);
            } else {
              this.printError(
                this.translateSrv.t(
                  '_error_interpolation_nomatch',
                  [],
                  'No matching data found. Make sure the selected files under Advanced Settings contains data for the selected parcels shown on the map'
                )
              );
            }
            return;
          }
          this.currentInterpolation = interpolation.interpolations;
          this.drawInterpolation(interpolation);
          this.error = undefined;
        },
        () => this.handleInterpolationOtherError()
      );
  }

  private drawInterpolation(interpolation: InterpolationCollection): void {
    this.mapStateSrv.setInterpolation(interpolation.interpolations);
    this.legend = interpolation.interpolations.legend;
    this.interpolationLoading = false;
    this.cd.markForCheck();
  }
  private parseInterpolationError(message: string): void {
    const errCode = message.substring(0, message.indexOf('.'));
    const errMsg = message.substring(message.indexOf('.') + 2);
    this.printError(
      this.translateSrv.t('_error_interpolation_' + errCode, '_' + errMsg)
    );
  }
  private handleInterpolationOtherError(): void {
    this.printError(
      this.translateSrv.t(
        '_error_interpolation failed',
        [],
        'Interpolation failed. Try deselecting files under advanced settings to pinpoint corrupt files'
      )
    );
  }

  private printError(errorMsg: string): void {
    this.error = errorMsg;
  }

  private getDataLabels(): void {
    this.interpolationSrv
      .getPointLabels()
      .pipe(takeUntil(this._unsub$))
      .subscribe((labels) => this.drawDataLabels(labels));
  }

  private drawDataLabels(labels: GeoJSON.FeatureCollection): void {
    this.mapStateSrv.clearValuePoints();
    if (labels) {
      this.mapStateSrv.setValuePoints(labels);
    }
  }

  private resetInterpolation(): void {
    this.mapStateSrv.clearValuePoints();
    this.mapStateSrv.setInterpolation(null);
  }

  goBack(): void {
    this.interpolationSrv.resetInterpolationType();
  }

  setActiveProperty(event: MatSelectChange): void {
    this.interpolationSrv.setInterpolationProperty(event.value);
  }

  export(): void {
    this.prescriptionSrv.openExportDialog(
      <PrescriptionModel>{
        fc: <GeoJSON.FeatureCollection>this.currentInterpolation,
        average: this.currentInterpolation.legend.avg,
        total: this.currentInterpolation.legend.sum,
      },
      null,
      null,
      this.exportOnly
    );
  }

  createPrescription(): void {
    this.interpolationSrv
      .getSelectedGeodata()
      .pipe(take(1))
      .subscribe((geodataIds) => {
        this.prescriptionSrv.initGrid(
          this.boundary,
          geodataIds,
          this.type,
          this.selectedProperty.field
        );
        this.prescriptionSrv.gotoPrescription();
      });
  }

  analyseField(): void {
    this.interpolationSrv
      .getSelectedGeodata()
      .pipe(take(1))
      .subscribe((selectedGeodata: number[]) => {
        const analyseConf = new AnalyzeFieldModel();
        analyseConf.feature = this.boundary;
        analyseConf.group = this.type;
        analyseConf.type = this.selectedProperty.field;
        analyseConf.fileIds = selectedGeodata;
        this.analyzeSrv.analyzeField(analyseConf);
      });
  }

  shareInterpolation(): void {
    combineLatest([
      this.interpolationSrv.getSelectedGeodata(),
      this.dataFileSrv.getGeodata(),
    ])
      .pipe(take(1))
      .subscribe(([selectedIds, geodata]) => {
        const selectedGeodata = geodata.filter((file) =>
          selectedIds.includes(file.fileId)
        );
        this.openShareDialog(selectedGeodata);
      });
  }

  private openShareDialog(geoData: GeoDataFileModel[]): void {
    this.interpolationSrv
      .getInterpolation(this.boundary)
      .pipe(takeUntil(this._unsub$))
      .subscribe((interpolation: InterpolationCollection) => {
        const ref = this.dialogSrv.open(GeodataListShareDialogComponent, {
          selectedFiles: geoData,
          preview: interpolation.interpolations,
        });

        ref.afterClosed().subscribe((result: ShareDialogResult) => {
          if (result?.data) {
            const payload: DataSharePayload = {
              message: result.data.message,
              recipients: result.data.emails,
              urls: result.data.links,
            };

            this.shareSrv.shareGeoDataLinks(payload).subscribe(
              () =>
                this.snackBar.open(this.translateSrv.t('Success'), null, {
                  duration: 3500,
                }),
              () =>
                this.snackBar.open(
                  this.translateSrv.t(
                    'Something went wrong, please try again!'
                  ),
                  null,
                  {
                    duration: 3500,
                  }
                )
            );
          }
        });
      });
  }
}
