import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImportModel } from 'app/models/import.model';
import { TextFileRow } from '../soilsampling/soilsampling.types';
import { KeyValue } from '@angular/common';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-sensordata',
  templateUrl: './sensordata.component.html',
  styleUrls: ['./sensordata.component.scss', '../import-buttons.scss'],
})
export class SensordataComponent implements AfterViewInit {
  @Input() importModel: ImportModel = null;
  @Output() importChange = new EventEmitter<ImportModel>();

  rows: TextFileRow[] = null;
  prjFileMissing = false;
  shpFileMissing = false;
  dbfFileMissing = false;
  cpgFileMissing = false;
  shxFileMissing = false;
  needAdditionalFiles = false;
  datFiles = {
    shp: null,
    dbf: null,
    shx: null,
    cpg: null,
    prj: null,
  };
  gotFiles: boolean;
  hasSelectedType = false;
  fileTypesMetadata = {} as KeyValue<string, string>;

  constructor(
    private translateService: DvToolbarTranslateService,
    private snackBar: MatSnackBar
  ) {}

  ngAfterViewInit(): void {
    this.fileTypesMetadata = this.importModel.metadata.find(
      (data) => data.key === 'fileTypes'
    );
  }

  setManufacture(manufacture): void {
    this.importModel.manufacture = manufacture;
    switch (manufacture) {
      case 'dat':
        this.importModel.addMetadata('fileTypes', '.shp,.shx,.dbf,.prj,.cpg');
        this.hasSelectedType = true;
        break;
      default:
        this.importModel.addMetadata('fileTypes', '.shp,.shx,.dbf,.prj,.cpg');
        this.hasSelectedType = true;
        break;
    }
    this.hasSelectedType = true;
  }

  files(files: File[]): void {
    files.forEach((file) => {
      switch (file.name.toLowerCase().substring(file.name.lastIndexOf('.'))) {
        case '.dbf':
          this.datFiles.dbf = file;
          break;
        case '.shp':
          this.datFiles.shp = file;
          break;
        case '.shx':
          this.datFiles.shx = file;
          break;
        case '.cpg':
          this.datFiles.cpg = file;
          break;
        case '.prj':
          this.datFiles.prj = file;
          break;
        default:
          this.snackBar.open(
            this.translateService.t('Unknown filetype'),
            null,
            { duration: 3000 }
          );
      }
    });
    this.updateFileStatus();
    if (!this.needAdditionalFiles && this.gotFiles) {
      this.done();
    }
  }

  updateFileStatus(): void {
    this.gotFiles = true;
    this.shpFileMissing = this.datFiles.shp === null ? true : false;
    this.dbfFileMissing = this.datFiles.dbf === null ? true : false;
    this.prjFileMissing = this.datFiles.prj === null ? true : false;
    this.shxFileMissing = this.datFiles.shx === null ? true : false;
    this.needAdditionalFiles =
      !this.dbfFileMissing &&
      !this.shpFileMissing &&
      !this.prjFileMissing &&
      !this.shxFileMissing
        ? false
        : true;
  }

  done(): void {
    this.importModel.files = [
      this.datFiles.dbf,
      this.datFiles.shp,
      this.datFiles.shx,
      this.datFiles.cpg,
      this.datFiles.prj,
    ];
    this.importChange.next(this.importModel);
    this.reset();
  }

  reset(): void {
    this.gotFiles = false;
    this.datFiles = {
      shp: null,
      dbf: null,
      shx: null,
      cpg: null,
      prj: null,
    };
  }
}
