import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'trimSystemfolder', pure: false })
export class TrimSystemfolder implements PipeTransform {
  constructor() { }

  transform(path: string) {
    if (!path)
      return path;

    if (path.indexOf('_') === 0) {
      const paths = path.split('\\');
      paths.splice(0, 1);
      return paths.join('\\');
    }
    return path;
  }
}
