<div #legend id="chart_div" style="width: 100%; height: 100%;"></div>
<div *ngIf="emptyBucketPrescriptions" class="no-bucket-data">
  <div>
  {{'_prescription-enter-values'|dvt:'_Enter values below for desired rate distribution'}}
  </div>
</div>
<svg style="width:0;height:0;position:absolute;" aria-hidden="true" focusable="false">
  <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="0%">
    <stop *ngFor="let bucket of prescription.buckets; let i = index" [attr.offset]="((100 / (this.prescription.bucketCount - 1)) * i) + '%'"  [ngStyle]="{'stop-color': bucket.color}" style="stop-opacity:1" />
  </linearGradient>
</svg>
