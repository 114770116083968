import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { ClientService } from './services/client.service';
import { take } from 'rxjs/operators';
import { ClientRights, DvAuthService, DvClientService } from '@dv/toolbar-msal';
import { CLIENT_RIGHTS, REDIRECT_URL_KEY, APP_PATH } from 'app/app.types';

@Component({
  selector: 'dv-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  loading = true;

  constructor(
    private router: Router,
    private clientService: ClientService,
    private dvAuthService: DvAuthService,
    private dvClientService: DvClientService
  ) {}

  ngOnInit(): void {
    this.redirect();
  }

  private async redirect(): Promise<void> {
    const isLoggedIn = await lastValueFrom(
      this.dvAuthService.isLoggedIn$.pipe(take(1))
    );

    if (isLoggedIn) {
      this.loggedInRedirect();
    } else {
      this.loggedOutRedirect();
    }
  }

  async loggedInRedirect(): Promise<void> {
    this.hideElevioForAgrometius();

    const client = await lastValueFrom(
      this.dvClientService.client$.pipe(take(1))
    );

    window.setTimeout(() => {
      const redirectUrl = window.localStorage.getItem(REDIRECT_URL_KEY);
      const urlParts = this.router.url.split('/').filter((s) => s.length > 0);

      if (redirectUrl && redirectUrl.length > 0) {
        window.localStorage.removeItem(REDIRECT_URL_KEY);
        this.navigateToRedirectUrl(redirectUrl, client.id);
      } else if (this.canNavigateToStartPage(client.rattigheter)) {
        this.navigateToStartPage(client.id);
      } else if (
        this.router.url.includes(APP_PATH.SHARE) &&
        client.rattigheter[CLIENT_RIGHTS.CROP_MAP]
      ) {
        if (!this.router.url.includes(APP_PATH.CLIENT)) {
          this.router.navigate([APP_PATH.CLIENT, client.id, ...urlParts]);
        } else {
          this.router.navigate([...urlParts]);
        }
      } else if (client.rattigheter[CLIENT_RIGHTS.CROP_MAP] !== true) {
        this.navigateToLogin();
      }
    });

    this.loading = false;
  }

  private canNavigateToStartPage(clientRights: ClientRights): boolean {
    return (
      !this.router.url.includes(APP_PATH.CLIENT) &&
      !this.router.url.includes(APP_PATH.SHARE) &&
      clientRights[CLIENT_RIGHTS.CROP_MAP]
    );
  }

  private loggedOutRedirect(): void {
    const { href } = window.location;

    if (!href.includes(APP_PATH.SHARE)) {
      this.router.navigate([APP_PATH.LOGIN]);
    } else if (
      href.includes(APP_PATH.SHARE) &&
      href.includes(APP_PATH.CLIENT)
    ) {
      const paths = href.split('/').filter((s) => s.length > 0);
      const shareIndex = paths.indexOf(APP_PATH.SHARE);

      this.router.navigate([paths[shareIndex], paths[shareIndex + 1]]);
    }

    this.loading = false;
  }

  private navigateToStartPage(clientId: number): void {
    console.log('PATH:', APP_PATH.CLIENT);
    this.router.navigate([APP_PATH.CLIENT, clientId]);
  }

  private navigateToRedirectUrl(redirectUrl: string, clientId: number): void {
    this.router.navigate([
      APP_PATH.CLIENT,
      clientId,
      ...redirectUrl
        .split('/')
        .filter((s) => s.length > 0)
        .filter((s) => s !== APP_PATH.CLIENT),
    ]);
  }

  private navigateToLogin(): void {
    this.router.navigateByUrl(`${APP_PATH.LOGIN}?status=401`);
  }

  private hideElevioForAgrometius(): void {
    // temporary ugly fix to hide elevio from agrometius' users
    this.clientService
      .isAgrometsius()
      .pipe(take(1))
      .subscribe((isAgrometius) => {
        if (isAgrometius) {
          const bodyElement = document.getElementsByTagName('body')[0];
          bodyElement.classList.add('is-agro');
        }
      });
  }
}
