import { MapService } from "./map.service";
import { MapComponent } from "./map.component";
import { first } from "rxjs/operators";
import { OnDestroy, OnInit, Component } from "@angular/core";

@Component({
    template: ''
})
export abstract class MapPageBase implements OnDestroy, OnInit {


    protected dvMap: MapComponent = null;
    constructor(protected mapService: MapService) {

    }


    ngOnDestroy(): void {
        if (this.dvMap !== null)
            this.onMapCleanUp();
    }
    ngOnInit(): void {
        this.mapService.mainMap().pipe(first()).subscribe((dvMap) => {
            this.dvMap = dvMap;

            //Set default value
            this.dvMap.rcmShowCreateField = false;

            this.onMapInit(dvMap);
        });
    }

    abstract onMapInit(dvMap: MapComponent);
    abstract onMapCleanUp();
}
