import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'dv-map-split-marker-mode',
  templateUrl: 'map-split-marker-mode.component.html',
  styleUrls: ['map-split-marker-mode.component.scss'],
})
export class MapSplitMarkerModeComponent {
  @Output() mode = new EventEmitter<boolean>();
  placeMarker = false;

  markerToggle(value: boolean): void {
    this.placeMarker = value;
    this.mode.emit(this.placeMarker);
  }
}
