export enum CLIENT_RIGHTS {
  CROP_MAP = 'cropMap',
}

export const REDIRECT_URL_KEY = 'redirect_url';

export enum APP_PATH {
  ANALYZE = 'analyze',
  CLIENT = 'client',
  DATA = 'data',
  DETAIL = 'detail',
  FIELD = 'field',
  FIELD_LIST = 'fieldlist',
  FILESET = 'fileset',
  GEODATA = 'geodata',
  LOGIN = 'login',
  MAP = 'map',
  MAPPING = 'mapping',
  NO_DATA = 'no-data',
  PICK = 'pick',
  PRESCRIPTION = 'prescription',
  PRESCRIPTION_WIZARD = 'prescription-wizard',
  SHARE = 'share',
  SPLIT = 'split',
  REDIRECT = 'redirectfrommk',
}
