<div class="parameter-text-wrapper">
  <dvm-radio-button-group
    class="parameter-description"
    [value]="selectedParameterId"
    (change)="onInputSelection($event.value)"
  >
    <dvm-radio-button
      size="small"
      [value]="PARAMETER_IDS.ADJUST_PH"
      [checked]="selectedParameterId === PARAMETER_IDS.ADJUST_PH"
      [dvmTooltip]="adjustPhParameter.description"
    >
      {{ 'Adjustment of target pH' | dvt }}
    </dvm-radio-button>
    <dvm-radio-button
      size="small"
      [value]="PARAMETER_IDS.TARGET_PH"
      [checked]="selectedParameterId === PARAMETER_IDS.TARGET_PH"
      [dvmTooltip]="targetPhParameter.description"
    >
      {{ 'Fixed target pH' | dvt }}
    </dvm-radio-button>
  </dvm-radio-button-group>
</div>
<dvm-input
  class="parameter-input"
  [type]="'number'"
  [min]="selectedParameterLimits.minLimit"
  [max]="selectedParameterLimits.maxLimit"
  [placeholder]="0"
  [value]="value"
  [errorMessage]="errorMessage"
  (input)="onValueChange($event.target.value, $event.target.validity.badInput)"
>
  pH
</dvm-input>
