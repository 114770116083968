import { KeyValue } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'dv-grid-cell-detail-pane',
  templateUrl: 'grid-cell-detail-pane.component.html',
  styleUrls: ['grid-cell-detail-pane.component.scss'],
})
export class GridCellDetailPaneComponent implements OnChanges {
  @Input() feature: google.maps.Data.Feature;
  @Output() closePane = new EventEmitter<void>();

  properties: KeyValue<string, string | number | boolean>[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.feature?.currentValue) {
      this.setProperties();
    } else {
      this.properties = [];
    }
  }

  private setProperties(): void {
    this.properties = [];

    this.feature.forEachProperty(
      (value: string | number | boolean, key: string) => {
        if (!key.startsWith('_')) {
          this.properties.push({
            key,
            value,
          });
        }
      }
    );
  }
}
