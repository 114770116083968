import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { MapComponent } from './map.component';
import { HttpClient } from '@angular/common/http';
import { first, filter, map, mergeMap } from 'rxjs/operators';
import { TopologyModel } from 'app/models/api.models';
import { ClientService } from 'app/services/client.service';
import { SiteService } from 'app/services/site.service';
import { environment } from 'environments/environment';
import { Feature, Geometry } from 'geojson';
import { MapStateService } from 'app/services/map-state.service';

/**
 * A service class to handel map related stuff
 * The actual work will be done in MapComponent
 */
@Injectable()
export class MapService {
  sliderOpacityLayers: string[] = [LAYER_NAME_INTERPOLATION];
  sliderOpacity = 0.9;

  private mainMap$ = new ReplaySubject<MapComponent>(1);
  private map$ = new ReplaySubject<{ [name: string]: MapComponent }>(1);
  private mapDic: { [name: string]: MapComponent } = {};

  private clickEvents: {
    [layerName: string]: (
      event: google.maps.Data.MouseEvent,
      dvMap: MapComponent
    ) => void;
  };
  private styleOptions: {
    [layerName: string]: (
      feature: google.maps.Data.Feature
    ) => google.maps.Data.StyleOptions;
  };

  private overrideStyleOptions: {
    [layerName: string]: (
      feature: google.maps.Data.Feature
    ) => google.maps.Data.StyleOptions;
  };

  constructor(
    private http: HttpClient,
    private clientService: ClientService,
    private siteService: SiteService,
    private mapStateService: MapStateService
  ) {
    this.clickEvents = {};
    this.styleOptions = {};
    this.overrideStyleOptions = {};
  }

  /**
   * Register a global click event for a given layer
   * @param layerName
   * @param event
   */
  registerClickEvent(
    layerName: string,
    event: (event: google.maps.Data.MouseEvent, dvMap: MapComponent) => void
  ): void {
    this.clickEvents[layerName] = event;
  }
  /**
   * Gets a  global click event for a given layer
   * @param layerName
   */
  clickEvent(
    layerName: string,
    event: google.maps.Data.MouseEvent,
    dvMap: MapComponent
  ): void {
    const clickRef = this.clickEvents[layerName];
    if (clickRef) {
      clickRef(event, dvMap);
    } else {
      this.selectFeature(event.feature, dvMap);
    }
  }

  topology(
    operator: TopologyModel
  ): Observable<Feature<Geometry, { [name: string]: any }>[]> {
    return this.clientService.client().pipe(
      mergeMap((client) =>
        this.http.post<GeoJSON.Feature[]>(
          `${environment.baseApiUrl}klient/${client.id}/map/topology`,
          operator
        )
      ),
      map((features) => {
        return features.map((f) => {
          f.properties['areal'] =
            Math.round(
              this.siteService.calculateArea(f.properties['area']) * 100
            ) / 100;
          return f;
        });
      })
    );
  }

  getCropSatDates(fc: GeoJSON.FeatureCollection, ar?: number) {
    const obj = {
      featureCollection: fc,
    };
    return this.clientService.client().pipe(
      mergeMap((client) => {
        if (!ar) {
          ar = client.ar;
        }

        const fromDate: string = ar + '-01-01';
        const toDate: string = ar + '-12-31';
        return this.http.post<any>(
          'https://apicropsat.azurewebsites.net/api/raster?country=' +
            client.land +
            '&year=' +
            client.ar +
            '&fromDate=' +
            fromDate +
            '&toDate=' +
            toDate,
          obj
        );
      }),
      map((dates) => {
        return dates;
      })
    );
  }

  getCropSatImage(fc: GeoJSON.FeatureCollection, date: string) {
    const obj = {
      featureCollection: fc,
    };
    return this.clientService.client().pipe(
      mergeMap((client) => {
        const fromDate: string = client.ar + '-01-01';
        const toDate: string = client.ar + '-12-31';
        return this.http.post<any>(
          'https://apicropsat.azurewebsites.net/api/ndvi?date=' +
            date +
            '&country=' +
            client.land +
            '&cellsize=20&strategy=Manual',
          fc
        );
      }),
      map((cropsatData) => {
        cropsatData.featureCollection.features.forEach((f) => {
          f.properties['strokeColor'] = f.properties['color'];
          f.properties['fillOpacity'] = 1;
        });
        return cropsatData;
      })
    );
  }

  selectFeature(feature: google.maps.Data.Feature, dvMap: MapComponent): void {
    const oldSelect = feature.getProperty(LAYER_SELECTED);
    const interpolationActive =
      dvMap.getFeatures(LAYER_NAME_INTERPOLATION).length > 0;

    if (dvMap.multiSelect && !interpolationActive) {
      feature.setProperty(LAYER_SELECTED, !oldSelect);
    } else {
      dvMap.map.data.forEach((f) => {
        if (f.getProperty(LAYER_SELECTED) && f.getId() !== feature.getId()) {
          f.setProperty(LAYER_SELECTED, false);
          dvMap.onDeSelected.next(f);
        }
      });
      feature.setProperty(LAYER_SELECTED, !oldSelect);
    }

    if (feature.getProperty(LAYER_SELECTED)) {
      dvMap.onSelected.next(feature);
    } else {
      feature.setProperty('manual', true);
      dvMap.onDeSelected.next(feature);
    }
  }

  /**
   * This does NOT raise an onDeselected event since that breaks the loop
   * @param dvMap
   */
  deselectAllFeatures(dvMap: MapComponent): void {
    dvMap.map.data.forEach((f) => {
      if (f.getProperty(LAYER_SELECTED)) {
        f.setProperty(LAYER_SELECTED, false);
      }
    });
  }

  /**
   * Register a global style role for a given layer
   * @param layerName
   * @param styleRef
   */
  registerStyle(
    layerName: string,
    styleRef: (
      feature: google.maps.Data.Feature
    ) => google.maps.Data.StyleOptions
  ): void {
    this.styleOptions[layerName] = styleRef;
  }

  registerOverrideStyle(
    layerName: string,
    styleRef: (
      feature: google.maps.Data.Feature
    ) => google.maps.Data.StyleOptions
  ): void {
    this.overrideStyleOptions[layerName] = styleRef;
  }

  revertOverrideStyles(layerName: string): void {
    if (layerName) {
      this.overrideStyleOptions[layerName] = null;
    } else {
      this.overrideStyleOptions = {};
    }
  }

  updateLayerStyles(dvMap: MapComponent): void {
    if (dvMap) {
      dvMap.map.data.setStyle((feature: google.maps.Data.Feature) => {
        return this.styles(feature.getProperty(LAYER_NAME), feature, dvMap);
      });
    }
  }

  /**
   * Gets a global style role for a given layer
   * @param layerName
   */
  styles(
    layerName: string,
    feature: google.maps.Data.Feature,
    dvMap: MapComponent
  ): google.maps.Data.StyleOptions {
    const styleRef = this.styleOptions[layerName];
    const overrideRef = this.overrideStyleOptions[layerName];
    if (styleRef) {
      const option = styleRef(feature);
      if (overrideRef) {
        const override = overrideRef(feature);
        return { ...option, ...override };
      }
      return option;
    }

    let color =
      feature.getProperty('color') ||
      feature.getProperty('_color') ||
      feature.getProperty('_Color') ||
      '#F44336';
    let strokeColor =
      feature.getProperty('strokeColor') ||
      feature.getProperty('_strokeColor') ||
      color;
    let strokeOpacity =
      feature.getProperty('strokeOpacity') ||
      feature.getProperty('_strokeOpacity') ||
      0.9;
    let fillOpacity =
      feature.getProperty('fillOpacity') ||
      feature.getProperty('_fillOpacity') ||
      0.8;

    if (
      this.sliderOpacity !== undefined &&
      this.sliderOpacityLayers.includes(layerName)
    ) {
      strokeOpacity = this.sliderOpacity;
      fillOpacity = this.sliderOpacity;
    }

    if (feature.getProperty(LAYER_SELECTED)) {
      color = this.shadeColor2(color, 0.5);
      strokeColor = '#ffffff';
    }

    if (!feature.getProperty(LAYER_ZINDEX)) {
      feature.setProperty(LAYER_ZINDEX, dvMap.zIndex(feature));
    }

    const style: google.maps.Data.StyleOptions = {
      fillColor: color,
      strokeColor: strokeColor,
      strokeOpacity: strokeOpacity,
      fillOpacity: fillOpacity,
      strokeWeight:
        feature.getProperty('strokeWeight') ||
        feature.getProperty('_strokeWeight') ||
        2,
      icon: {
        url:
          'https://api.datavaxt.se/map/dot/' + color.substring(1, color.length),
      },
      zIndex: feature.getProperty(LAYER_ZINDEX),
    };

    style.clickable = !dvMap.disableSelect;
    if (overrideRef) {
      const override = overrideRef(feature);
      return { ...style, ...override };
    }

    return style;
  }

  /**
   * Returns the same color shaded, the percent sets the shade
   * @param color
   * @param percent
   */
  shadeColor2(color: string, percent: number): string {
    if (!color) {
      return color;
    }
    const f = parseInt(color.slice(1), 16),
      t = percent < 0 ? 0 : 255,
      p = percent < 0 ? percent * -1 : percent,
      R = f >> 16,
      G = (f >> 8) & 0x00ff,
      B = f & 0x0000ff;
    return (
      '#' +
      (
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)
    );
  }

  /**
   * Gets the registred main map, the application may only have one main map
   * Its not guarantee that the application has a registered main map
   */
  mainMap(): Observable<MapComponent> {
    return this.mainMap$.asObservable().pipe(
      filter((m) => m !== null),
      first()
    );
  }
  /**
   * The application can have one and only one main map, this will register that map.
   * Use main map to featch a observable that will resolve in the main map
   * @param map
   */
  registerMainMap(map: MapComponent): void {
    this.mainMap$.next(map);
  }
  /**Register a given map and a name, the name is used to get a specific map */
  registerMap(map: MapComponent, name: string): void {
    this.mapDic[name] = map;
    this.map$.next(this.mapDic);
  }
  /**Removes a given map */
  removeMap(name: string): void {
    delete this.mapDic[name];
    this.map$.next(this.mapDic);
  }
  /**Gets all maps, will not give the main map, to access the main map use mainMap */
  maps(): Observable<{
    [name: string]: MapComponent;
  }> {
    return this.map$.asObservable().pipe(first());
  }
  /**Gets a given map by name, this is a hot observe and subscribe is important */
  map(name: string): Observable<MapComponent> {
    return this.map$.asObservable().pipe(
      filter((maps) => maps[name] !== undefined),
      map((maps) => maps[name])
    );
  }
}

export enum LAYER_PROPS {
  NAME = 'namn',
  SKIFTE_ID = 'skifteId',
}

export const LAYER_NAME_MARKERING = 'markeringar';
export const LAYER_NAME = '_layerName';
export const LAYER_SELECTED = '_isLayerSelected';
export const LAYER_ZINDEX = '_zindex';
export const LAYER_NAME_SKIFTEN = 'skiften';
export const LAYER_NAME_FILEDATA = 'filedata';
export const LAYER_NAME_BLOCK = 'block';
export const LAYER_NAME_INTERPOLATION = 'interpolation';
export const LAYER_NAME_SPREADBOUNDARY = 'spreadboundary';
export const LAYER_NAME_VALUEPOINT = 'valuepoints';
export const SAM_COLOR = '#2E67C7';
export const LAYER_EDITABLE = '_editable';
export const LAYER_EDITABLE_CONTROLS = '_editable_controls';
export const COLORS = [
  '#F44336',
  '#4CAF50',
  '#03A9F4',
  '#FF9800',
  '#673AB7',
  '#E91E63',
];
export const METERS: any = { units: 'metres' };
export const KILOMETERS: any = { units: 'kilometers' };
export class Extent {
  constructor(latLngBounds?: google.maps.LatLngBounds) {
    if (latLngBounds) {
      this.minX = latLngBounds.getSouthWest().lat();
      this.minY = latLngBounds.getSouthWest().lng();
      this.maxX = latLngBounds.getNorthEast().lat();
      this.maxY = latLngBounds.getNorthEast().lng();
    }
  }

  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}
