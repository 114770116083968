import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'dv-fileupload',
    templateUrl: './fileupload.component.html',
    styleUrls: ['./fileupload.component.scss'],
})
export class FileuploadComponent implements OnInit {
    shpHelper = new ShpHelper();

    @Output() files = new EventEmitter<File[]>();

    @Input() fileTypes = '';
    @Input() disableShpHelper: boolean = false;
    @Input() singleFileOnly: boolean = false;

    singleFileError: boolean = false;

    constructor() {}

    ngOnInit() {}

    selectedFiles(files: FileList) {
        this.singleFileError = false;
        if (this.singleFileOnly && files.length > 1) {
            this.singleFileError = true;
            return;
        }
        this.shpHelper.addFiles(files);
        if (this.shpHelper.valid() || this.disableShpHelper) this.files.next(this.shpHelper.files);
    }
    filePicked(ev) {
        this.shpHelper.addFiles(ev.target.files);
        if (this.shpHelper.valid() || this.disableShpHelper) this.files.next(this.shpHelper.files);
    }

    clearFiles() {
        this.shpHelper.files = [];
    }
}

export class ShpHelper {
    private _valid: boolean = true;
    public files: File[] = [];

    public hints = [
        {
            ext: '.shx',
            color: 'warn',
        },
        {
            ext: '.dbf',
            color: 'warn',
        },
        {
            ext: '.shp',
            color: 'warn',
        },
    ];

    addFiles(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            let file = files.item(i);
            if (this.files.find((f) => f.name === file.name)) continue;

            this.files.push(file);
        }

        this._valid =
            this.files.find((f) => f.name.endsWith('.shp')) === undefined ||
            (this.files.find((f) => f.name.endsWith('.dbf')) !== undefined &&
                this.files.find((f) => f.name.endsWith('.shx')) !== undefined);

        this.hints.forEach((hint) => {
            hint.color = this.files.find((f) => f.name.endsWith(hint.ext)) ? 'accent' : 'warn';
        });
    }

    clear() {
        this.files = [];
    }

    valid() {
        return this._valid;
    }
}
