<div class="container">
  <div
    *ngIf="
      availableProperties &&
      availableProperties.length <= 3 &&
      availableProperties.length > 0
    "
  >
    <mat-button-toggle-group
      class="properties-select"
      (change)="setActiveProperty($event)"
      aria-label="Properties"
      [value]="selectedProperty$ | async"
    >
      <!-- translate or go by displayName?-->
      <mat-button-toggle
        *ngFor="let prop of availableProperties"
        [value]="prop"
      >
        {{ '_propertyname_' + prop.field | dvt: '_' + prop.displayName }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div *ngIf="availableProperties && availableProperties.length > 3">
    <mat-form-field class="prop-select" appearance="outline">
      <mat-select
        [value]="selectedProperty$ | async"
        (selectionChange)="setActiveProperty($event)"
      >
        <mat-option *ngFor="let prop of availableProperties" [value]="prop">
          {{ '_propertyname_' + prop.field | dvt: '_' + prop.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="main-interpolation">
    <mat-card appearance="outlined" *ngIf="legend || loadingInterpolation || !initiated; else noData">
      <dv-legend
        [legend]="legend"
        [loading]="loadingInterpolation && !error"
        [pointMode]="pointMode"
      ></dv-legend>
    </mat-card>
    <ng-template #noData>
      <mat-card appearance="outlined" class="no-data">
        <mat-icon>bar_chart</mat-icon>
        <div>
          {{
            '_interpolation_geodata_nogrids'
              | dvt
                : '_Unfortunately, this geodata does not have any interpolateable data'
          }}
        </div>
      </mat-card>
    </ng-template>
  </div>

  <mat-expansion-panel
    class="expansion settings"
    *ngIf="legend || loadingInterpolation"
  >
    <mat-expansion-panel-header class="expansion">
      <mat-icon>settings</mat-icon>
      <p class="title">{{ 'Settings' | dvt }}</p>
    </mat-expansion-panel-header>
    <div class="options">
      <dv-yield-disclaimer
        *ngIf="interpolationType === 'Skordedata'"
        class="options-disclaimer"
      ></dv-yield-disclaimer>

      <div class="yield-calibrate-parent" *ngIf="showCalculateYield">
        <div class="yield-calibrate-child right">
          <div class="yield-title">
            {{ 'Total yield from data source' | dvt }}
          </div>
          <div #yieldSum>{{ metaDataTotalYield }}</div>
        </div>

        <div class="yield-calibrate-child left">
          <button (click)="recalibrateYield()" mat-menu-item>
            <mat-icon>refresh</mat-icon>
            {{ 'Recalibrate' | dvt }}
          </button>
        </div>
      </div>

      <div class="data-point-slides" *ngIf="!pointMode">
        <div>{{ '_layer_opacity' | dvt }}</div>
        <mat-slider
          [thumbLabel]="true"
          min="0"
          max="1"
          step="0.1"
          color="primary"
         #ngSlider><input matSliderThumb [value]="mapService.sliderOpacity" (change)="setOpacity({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}.value)" #ngSliderThumb="matSliderThumb" /></mat-slider>
      </div>
    </div>
  </mat-expansion-panel>
  <div *ngIf="interpolationType === 'Tilldelning'">
    <dvm-button (click)="export()" ghost full-width class="export-button">
      <mat-icon>add_circle_outlined </mat-icon>
      {{ 'exportera' | dvt }}
    </dvm-button>
  </div>
  <div *ngIf="error" class="errmsg">
    <mat-icon color="warn">error</mat-icon>
    <span class="dv-text-color status-text">{{ error }}</span>
  </div>
</div>
