import { KeyValue } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { ImportModel } from 'app/models/import.model';
import { SiteService } from 'app/services/site.service';

@Component({
  selector: 'dv-yield',
  templateUrl: './yield.component.html',
  styleUrls: ['./yield.component.scss', '../import-buttons.scss'],
})
export class YieldComponent implements AfterViewInit {
  @Input() importModel: ImportModel = null;
  @Output() importChange = new EventEmitter<ImportModel>();
  hasSelectedType = false;
  fileTypesMetadata: KeyValue<string, string> = {} as KeyValue<string, string>;
  showBetaFunction$ = this.siteService.showBetaFunction$;

  constructor(private siteService: SiteService) {}

  ngAfterViewInit(): void {
    this.fileTypesMetadata = this.importModel.metadata.find(
      (data) => data.key === 'fileTypes'
    );
  }

  setManufacture(manufacture, forceNext = false): void {
    this.importModel.manufacture = manufacture;
    switch (manufacture) {
      case 'johndeere':
        this.setFileTypes('.zip');
        this.hasSelectedType = true;
        break;
      case 'claas':
        this.setFileTypes('.zip');
        break;
      case 'yieldmaster':
        this.setFileTypes('.csv');
        break;
      case 'claasAft':
        this.setFileTypes('.aft,.zip');
        this.hasSelectedType = true;
        break;
      case 'yieldmasterpro':
        this.setFileTypes('.csv,.zip');
        this.hasSelectedType = true;
        break;
      default:
        this.setFileTypes('.zip');
        this.hasSelectedType = true;
        break;
    }
    if (forceNext) {
      this.hasSelectedType = true;
    }
  }

  private setFileTypes(types: string): void {
    this.importModel.addMetadata('fileTypes', types);
  }

  setManufactureVersion(version: string): void {
    this.importModel.addMetadata('tag', version);
    this.importChange.next(this.importModel);
  }

  fileSelected(files: File[]): void {
    this.importModel.files = [];
    for (const file of files) {
      this.importModel.files.push(file);
    }

    this.importChange.next(this.importModel);
  }
}
