<div>
    <div>
        <div [class.inactive]="minimized" class="chart" #histogram></div>
        <div class="graph">
            <div [class.inactive]="minimized" class="chart" #legend></div>
            <div [class.inactive]="loading || !legendReady" class="info">
                <div class="property">
                    <span>{{ 'Areal' | dvt }}</span>
                    <p>{{ legendObj?.totalArea | number: '1.2-2' }}</p>
                </div>
                <div class="property">
                    <span>{{ 'Max' | dvt }}</span>
                    <p>{{ legendObj?.max | number: '1.2-2' }}</p>
                </div>
                <div class="property">
                    <span>{{ 'Min' | dvt }}</span>
                    <p>{{ legendObj?.min | number: '1.2-2' }}</p>
                </div>
                <div class="property">
                    <span>{{ 'Average' | dvt }}</span>
                    <p>{{ legendObj?.avg | number: '1.2-2' }}</p>
                </div>
                <div class="property">
                    <span>{{ 'Sum' | dvt }}</span>
                    <p>{{ legendObj?.sum | number: '1.2-2' }}</p>
                </div>
            </div>
        </div>
        <div class="loading-chart" *ngIf="loading || !legendReady">
            <img src="/assets/images/animated-bar-chart.svg" />
        </div>
    </div>
</div>
