<div
  *ngIf="
    !viewInited || filesError || loadingFiles || (filesetLength$ | async) == 0
  "
  class="no-files"
>
  <p *ngIf="!loadingFiles && filesError">{{ filesError }}</p>
  <p *ngIf="!loadingFiles && !filesError && (filesetLength$ | async) == 0">
    {{ 'You have no files' | dvt }}
  </p>
  <mat-progress-spinner
    *ngIf="!viewInited || loadingFiles"
    mode="indeterminate"
  ></mat-progress-spinner>
</div>
<mat-table
  *ngIf="
    viewInited && !loadingFiles && !filesError && (filesetLength$ | async) > 0
  "
  [dataSource]="filesetDataSource"
  class="mat-elevation-z8 file-table"
  multiTemplateDataRows
  matSort
  (matSortChange)="onSortChange($event)"
>
  <ng-container [matColumnDef]="COLUMN_KEYS.CHECKBOX">
    <mat-header-cell class="no-pointer" *matHeaderCellDef>
      <mat-checkbox
        (change)="selectAllFiles($event.checked)"
        (click)="$event.stopPropagation()"
      ></mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      <mat-checkbox
        (change)="checkedChange(file.fileId)"
        [checked]="selectedFiles.includes(file.fileId)"
        (click)="$event.stopPropagation()"
      ></mat-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_KEYS.NAME">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'File' | dvt }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.NAME"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell class="file-name" *matCellDef="let file">
      <div class="file-name-text" [matTooltip]="file.name">{{ file.name }}</div>
      <dv-file-status [status]="file.status"></dv-file-status>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_KEYS.TYPE">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'Contains' | dvt }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.TYPE"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      {{ file.translations?.aggregatedFileTypes }}
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_KEYS.CLIENT">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ '__metadata_owner' | dvt: '_Customer' }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.CLIENT"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      <div class="value-ellipse">
        {{ getDataFileMetaAggregateString(file, metaTag.customer) }}
      </div>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_KEYS.FARM">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ '__metadata_farm' | dvt: '_Farm' }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.FARM"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      <div class="value-ellipse">
        {{ getDataFileMetaAggregateString(file, metaTag.farm) }}
      </div>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_KEYS.DATE">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'Uploaded' | dvt }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.DATE"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      {{ file.uploaded | date: 'short' }}
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_KEYS.MANUFACTOR">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'Data format' | dvt }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.MANUFACTOR"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      {{ '_data_format_' + file.dataFormat | dvt: '_' + file.dataFormat }}
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_KEYS.MORE">
    <mat-header-cell class="no-pointer" *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let file">
      <img src="assets/images/chevron_right.svg" class="detail-icon" />
      <button
        (click)="$event.stopPropagation()"
        mat-icon-button
        [matMenuTriggerFor]="fileContextMenu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #fileContextMenu="matMenu" xPosition="before">
        <button
          (click)="fileSrv.requestDeleteFileset(file.fileId)"
          mat-menu-item
        >
          <mat-icon>delete</mat-icon>
          {{ 'Delete' | dvt }}
        </button>
        <button (click)="fileSrv.reimportFile(file.fileId)" mat-menu-item>
          <mat-icon>refresh</mat-icon>
          {{ 'Reimport' | dvt }}
        </button>
        <button (click)="toggleRow(file)" mat-menu-item>
          <mat-icon>description</mat-icon>
          {{ 'View details' | dvt }}
        </button>
        <button
          (click)="fileSrv.downloadRawDataFileSet(file.fileId, file.name)"
          mat-menu-item
        >
          <mat-icon>download</mat-icon>
          {{ 'Download' | dvt }}
        </button>
      </mat-menu>
    </mat-cell>
  </ng-container>

  <mat-row
    class="table-header-row"
    *matHeaderRowDef="displayedColumns"
  ></mat-row>
  <mat-row
    class="file-row"
    *matRowDef="let row; let i = dataIndex; columns: displayedColumns"
    (click)="toggleRow(row)"
  ></mat-row>
</mat-table>
<div class="bottom-action-area">
  <div class="file-options">
    <button
      mat-stroked-button
      (click)="fileSrv.shareFiles()"
      [disabled]="!selectedFiles.length"
    >
      {{ 'Share' | dvt }}
      {{ selectedFiles.length ? ' (' + selectedFiles.length + ')' : '' }}
      <img class="bulk-button-icon" src="assets/images/expand-more.svg" />
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="openShareRawFilesDialog()"
      [disabled]="!selectedFiles.length"
    >
      {{ 'Apply' | dvt }}
    </button>
  </div>
  <mat-paginator
    #filePaginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [aria-label]="'Number of files per page' | dvt"
  ></mat-paginator>
</div>
