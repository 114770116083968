import { Injectable } from '@angular/core';
import { GeoDataFileMetaTags } from 'app/models/geodata.model';
import {  GeoDataFileModel, RawFileSetModel } from 'app/models/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataFilterService {
  public CLEARED_FILTER = {
    fileType: null,
    customer: null,
    farm: null,
    from: null,
    to: null,
    searchString: null
  };
  private _randomString: string = '!"#¤%&/()=?^*_:;>';
  public metaTag = GeoDataFileMetaTags;
  public activeFilters = { ...this.CLEARED_FILTER };
  public availableCustomers$ : BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public availableFarms$ : BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public availableFileTypes$ : BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public filterChange$ : BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
  ) { }

  public setFilter(type: string, filterValue: any) : void { 
    if (this.activeFilters[type] !== filterValue) { 
      this.activeFilters[type] = filterValue;
      this.filterChange$.next(filterValue);
    }
  }

  // this is a bit hacky, but works
  public reset() : string {
    return this._randomString;
  }

  public clearFilters() : void {
    this.activeFilters = { ...this.CLEARED_FILTER };
    this.filterChange$.next(null);
  }

  public initFileSetFilter() : any {
    return (data: RawFileSetModel, filter: string) => {
      const { fileType, customer, farm, from, to, searchString } = {...this.activeFilters};

      let typeFound = data.dataFiles.find((data) => {
          return data.fileType != undefined && data.fileType == fileType
      });
      if (fileType != null && typeFound === undefined) return false;
      if (customer != null && (!data.dataFiles)) return false;
      let customerFound = data.dataFiles.filter((data) => {
          let value = data.metadata.find(d => d.key === this.metaTag.customer)?.value;
          return value == customer;
      });
      if (customer != null && customerFound.length == 0)  return false;
      
      if (farm != null && (!data.dataFiles)) return false;
      let farmFound = data.dataFiles.filter((data) => {
          let value = data.metadata.find(d => d.key === this.metaTag.farm)?.value;
          return value == farm;
      });
      if (farm != null && farmFound.length == 0)  return false;

      let containsDates = data.dataFiles.filter((data) => {
        let dateFound = data.metadata.find(d => d.key === this.metaTag.created)?.value;
        if (dateFound) {
          //because of javascript automatic timezone magic, we need to "clear" time part 
          from?.setHours(0, 0, 0, 0);
          to?.setHours(0, 0, 0, 0);
          const createdDate : Date = new Date(dateFound);
          createdDate.setHours(0,0,0,0);
          if (from != null && from > createdDate) return false;
          if (to != null && to < createdDate) return false;
          return true;
        } else
          return false;
      });
      if ((from != null || to != null) && containsDates?.length == 0) return false;

      let stringFound = data.dataFiles.filter((data) => {
          if (data.name.includes(searchString)) return true;
          let value = data.metadata.find(d => d.value.toLowerCase().includes(searchString));
          return value != undefined;
      })
      if (searchString != null && stringFound.length == 0) return false;

      return true;
    };
  }

  public initGeoDataFileFilter() : any {
    return (data: GeoDataFileModel, filter: string) => { 
      const { fileType, customer, farm, from, to, searchString } = { ...this.activeFilters};
      
      if (fileType != null &&data.fileType !== fileType) return false;
      
      let customerFound = data.metadata.find(d => d.key === this.metaTag.customer)?.value;
      if (customer != null && customerFound !== customer)  return false;
      
      let farmFound = data.metadata.find(d => d.key === this.metaTag.farm)?.value;
      if (farm != null && farmFound !== farm)  return false;

      let dateFound = data.metadata.find(d => d.key === this.metaTag.created)?.value;
      if (dateFound) {
        //because of javascript automatic timezone magic, we need to "clear" time part 
        from?.setHours(0, 0, 0, 0);
        to?.setHours(0, 0, 0, 0);
        const createdDate = new Date(dateFound);
        createdDate.setHours(0, 0, 0, 0);
        if (from != null && from > createdDate) return false;
        if (to != null && to < createdDate) return false;
      } else if (from != null || to != null)
        return false;
      let stringFound = data.metadata.find(d => d.value.toLowerCase().includes(searchString))?.value;
      if (searchString != null 
        && (
          (stringFound == undefined || stringFound.length == 0) 
          && !data.name.toLowerCase().includes(searchString)
        )
        
      ) return false;

      return true;
    };
  }

}

