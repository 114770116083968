<mat-dialog-content *ngIf="!activeEnh">
  <button mat-dialog-close mat-mini-fab color="primary">
    <mat-icon>close</mat-icon>
  </button>
  <h2 mat-dialog-title>
    {{ 'Farms' | dvt }}
  </h2>
  <div class="farms">
    <div
      class="farm"
      *ngFor="let brukEnh of brukEnheter"
      (click)="openFarm(brukEnh)"
    >
      <img src="\assets\images\menu\icon-start.svg" />
      <span class="name">{{ brukEnh.namn }}</span>
    </div>
  </div>
  <dvm-button class="add" (click)="addFarm()">
    {{ 'Add farm' | dvt }}
  </dvm-button>
</mat-dialog-content>
<mat-dialog-content *ngIf="activeEnh">
  <button mat-dialog-close mat-mini-fab color="primary">
    <mat-icon>close</mat-icon>
  </button>
  <h2 mat-dialog-title>
    {{ activeEnh.id > 0 ? ('Edit farm' | dvt) : ('Add farm' | dvt) }}
  </h2>
  <dvm-input [label]="'Name' | dvt" [(ngModel)]="activeEnh.namn"></dvm-input>

  <p class="error" *ngIf="error">{{ error }}</p>
  <p class="error" *ngIf="deleteError">
    {{ 'Cannot remove a farm that has parcels' | dvt }}
  </p>
  <mat-dialog-actions class="actions">
    <dvm-button
      *ngIf="activeEnh.id > 0"
      mat-raised-button
      delete
      (click)="delete(activeEnh)"
      color="warn"
    >
      {{ 'Delete' | dvt }}
    </dvm-button>
    <div class="sub-actions">
      <dvm-button secondary (click)="cancel()">{{ 'Cancel' | dvt }}</dvm-button>
      <dvm-button
        *ngIf="activeEnh.id > 0"
        [disabled]="loading"
        (click)="save(activeEnh)"
      >
        {{ loading ? ('Saving' | dvt) : ('Save' | dvt) }}
      </dvm-button>
      <dvm-button *ngIf="activeEnh.id < 0" [disabled]="loading" (click)="add()">
        {{ 'Add' | dvt }}
      </dvm-button>
    </div>
  </mat-dialog-actions>
</mat-dialog-content>
