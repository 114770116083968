import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
} from '@angular/core';
import { META_DATA } from 'app/components/data/fileset/fileset-list-detail/fileset-list-detail.types';
import {
  WrappedNestedFile,
  WrappedNestedList,
} from './nested-preview-list.types';
import {
  FunctionParameterWithType,
  FunctionGeoDataDefinition,
  NestedValue,
  PARAMETER_TYPES,
  FunctionGeographicDefinition,
} from 'app/views/prescription-wizard-page/components/step-settings/step-settings.types';
import {
  DvmRadioButtonGroupModule,
  DvmRadioButtonModule,
} from '@dvm/components';
import { NgFor, NgIf } from '@angular/common';
import { NestedItemComponent } from 'app/views/prescription-wizard-page/components/step-settings/components/file-section/components/nested-geo-data-content/components/nested-preview-list/components/nested-item/nested-item.component';
import { NestedListItemComponent } from 'app/views/prescription-wizard-page/components/step-settings/components/file-section/components/nested-geo-data-content/components/nested-preview-list/components/nested-list-item/nested-list-item.component';

@Component({
  selector: 'dv-nested-preview-list',
  templateUrl: 'nested-preview-list.component.html',
  styleUrls: ['nested-preview-list.component.scss'],
  standalone: true,
  imports: [
    DvmRadioButtonGroupModule,
    DvmRadioButtonModule,
    NestedItemComponent,
    NestedListItemComponent,
    NgFor,
    NgIf,
  ],
})
export class NestedPreviewListComponent implements OnChanges {
  @Input() nestedData: FunctionParameterWithType<FunctionGeoDataDefinition>[];
  @Input() disabled: boolean;
  @Output() fileChange = new EventEmitter<NestedValue>();
  @Output() itemChange = new EventEmitter<NestedValue>();

  selectedIndex = 0;
  wrappedLists: Array<WrappedNestedList> = [];
  selectedList: WrappedNestedList;
  PARAMETER_TYPES = PARAMETER_TYPES;

  ngOnChanges(): void {
    if (this.nestedData) {
      this.wrappedLists = this.nestedData.map((functionParameter) => {
        return {
          functionParameter,
          parameterType: functionParameter.parameterType,
          list: this.populateFileList(functionParameter.definition),
        };
      });
    }

    this.selectedList = this.wrappedLists[this.selectedIndex];
  }

  private populateFileList({
    files,
    selectedFiles,
  }: FunctionGeoDataDefinition): WrappedNestedFile[] {
    return files?.map((file) => {
      return {
        checked: (selectedFiles as number[]).includes(file.fileId),
        createdDate: file.metadata.find(
          (data) => data.key === META_DATA.CREATED
        )?.value,
        file,
      };
    });
  }

  selectList(index: number): void {
    if (index === undefined) {
      return;
    }

    this.selectedIndex = index;
    this.selectedList = this.wrappedLists[index];

    const key = this.getSelectedItemId();
    const type = this.selectedList.functionParameter.parameterType;

    if (type === PARAMETER_TYPES.GEO_DATA) {
      this.emitFileChange(key);
    } else if (type === PARAMETER_TYPES.GEOGRAPHIC) {
      this.emitItemChange(key);
    }
  }

  onFileChange(index: number): void {
    if (index === this.selectedIndex) {
      this.emitFileChange(this.getSelectedItemId());
    }
  }

  private getSelectedItemId(): number {
    return this.selectedList.functionParameter.id;
  }

  private emitFileChange(key: number): void {
    const { selectedFiles } = this.selectedList.functionParameter
      .definition as FunctionGeoDataDefinition;
    const value: NestedValue = {
      [key]: selectedFiles,
    };

    this.fileChange.emit(value);
  }

  private emitItemChange(key: number): void {
    const { geoDataType } = this.selectedList.functionParameter
      .definition as FunctionGeographicDefinition;
    const value: NestedValue = {
      [key]: geoDataType,
    };

    this.itemChange.emit(value);
  }
}
