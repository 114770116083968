import { HttpClient, HttpEvent, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ArKopieringModel,
  BrukningsenhetModel,
  InterpolationLayerModel,
  Layer,
  NewSkifteEnkelModel,
  PoiImageModel,
  PoiKategoriModel,
  PoiModel,
  PointModel,
  ProduktModel,
  ProduktUtsadeModel,
  SkifteDetielsModel,
  TabellUtsadeModel,
  UtsadeModel,
} from 'app/models/api.models';
import * as turf from '@turf/turf';
import { Extent } from 'app/components/map/map.service';
import { FunctionModel } from 'app/models/api.models';
import { ImportModel } from 'app/models/import.model';
import { ImportStatusGroup } from 'app/models/importStatusGroup.model';
import {
  InterpolationCollection,
  InterpolationModel,
} from 'app/models/interpolation.model';
import { MissingSkifteModel } from 'app/models/missingSkifteModel';
import {
  FieldGridModel,
  FileModel,
  GeoDataFileModel,
  GeoDataMetaData,
  RawFileSetModel,
  GridFieldModel,
  ImportStatusModel,
  MappingLayerModel,
  PrescriptionModel,
  PropertyInfoModel,
} from 'app/models/models';
import {
  PrescriptionFileModel,
  PrescriptionUntypedFileModel,
} from 'app/models/prescriptiongrid.model';
import { TrimSystemfolder } from 'app/pipes/pipe/trim-system-folder.pipe';
import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import {
  delayWhen,
  distinctUntilChanged,
  filter,
  first,
  flatMap,
  map,
  mergeMap,
  retryWhen,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { FILE_FORMAT } from './data-file.service';
import { AllGeoJSON, FeatureCollection } from '@turf/turf';
import { CalibratingYieldModel } from 'app/components/data/geodata/geodata-interpolation/geodata-interpolation-types';
import {
  AdjustPrescriptionModel,
  GridSettings,
  PrescriptionSettings,
  TargetPhInput,
  TargetPhResponse,
} from 'app/views/prescription-wizard-page/components/step-settings/step-settings.types';
import { FileImportStatusLogEntry } from 'app/models/filestatus.model';
import { Client, DvClientService } from '@dv/toolbar-msal';

@Injectable({ providedIn: 'root' })
export class ClientService {
  private mappingGroups$ = new ReplaySubject<string[]>(1);
  private tabellUtsade = new ReplaySubject<TabellUtsadeModel[]>(1);
  private skifteLayer$ = new BehaviorSubject<GeoJSON.FeatureCollection>(null);
  private skiften$ = new BehaviorSubject<SkifteDetielsModel[]>([]);
  private poiCategorys$ = new BehaviorSubject<PoiKategoriModel[]>([]);
  private createFieldPoint$ = new Subject<{ lat: number; lng: number }>();
  private _clientAr$ = new BehaviorSubject<number>(
    parseInt(window.localStorage.getItem('ar')) || new Date().getFullYear()
  );

  private skifteIsFetched: boolean;

  tabellUtsade$ = this.tabellUtsade.asObservable();

  constructor(
    private http: HttpClient,
    private dvClientService: DvClientService,
    private trimSystemfolder: TrimSystemfolder
  ) {
    combineLatest([this._clientAr$, this.client()]).subscribe((res) => {
      const client = <Client>res[1];
      const ar = res[0];

      //if this client doesn't have any years yet, just fake it with current year
      if (client.tillgangligaAr.length === 0) {
        client.tillgangligaAr.push(new Date().getFullYear());
      }

      if (client.tillgangligaAr.includes(ar)) {
        client.ar = ar;
        window.localStorage.setItem('ar', ar.toString());
      } else if (client.tillgangligaAr.includes(new Date().getFullYear())) {
        this._clientAr$.next(new Date().getFullYear());
      } else {
        this._clientAr$.next(client.tillgangligaAr[0]);
      }

      this.loadTabellUtsade().pipe(take(1)).subscribe();
      this.loadSkifteLayer();
      this.loadMappingGroups();
    });
  }

  client(): Observable<Client> {
    return this.dvClientService.client$.pipe(filter((client) => !!client));
  }

  clientId(): Observable<number> {
    return this.client().pipe(
      map((client) => client.id),
      first()
    );
  }

  clientAr(): Observable<number> {
    return this._clientAr$.asObservable().pipe(distinctUntilChanged());
  }

  clientList(): Observable<Client[]> {
    return this.http.get<Client[]>(environment.baseApiUrl + 'klient/').pipe(
      map((res) => {
        return res.filter((k) => k.redigera && k.rattigheter['cropMap']);
      })
    );
  }

  fetchIdFromOrgNr(orgNumber: string): Observable<number> {
    const url = environment.baseApiUrl + `klient/orgnr/${orgNumber}`;
    return this.http.get<Client>(url).pipe(map((response) => response.id));
  }

  setClientAr(ar: number): void {
    this._clientAr$.next(ar);
    this.loadSkiften();
    this.loadTabellUtsade().pipe(take(1)).subscribe();
  }

  reloadYear(): void {
    this.clientAr()
      .pipe(first())
      .subscribe((ar) => {
        this.setClientAr(ar);
      });
  }

  getLandCode(): Observable<string> {
    return this.client().pipe(map((k) => k.land));
  }

  isAgrometsius(): Observable<boolean> {
    return this.client().pipe(
      map((k) => k.land === 'nl' || k.land === 'de' || k.land === 'be')
    );
  }

  getBlocks(extent: Extent): Observable<GeoJSON.FeatureCollection> {
    return this.http.get<GeoJSON.FeatureCollection>(
      environment.baseApiUrl +
        'map/block?minX=' +
        extent.minX +
        '&minY=' +
        extent.minY +
        '&maxX=' +
        extent.maxX +
        '&maxY=' +
        extent.maxY
    );
  }
  getLayers(): Observable<Layer[]> {
    return this.clientId().pipe(
      flatMap((id) =>
        this.http.get<Layer[]>(
          environment.baseApiUrl + 'klient/' + id + '/map/layer'
        )
      )
    );
  }

  getSoilSamplingCoordinateLayers(): Observable<Layer[]> {
    return this.clientId().pipe(
      flatMap((id) =>
        this.http.get<Layer[]>(
          environment.baseApiUrl +
            'klient/' +
            id +
            '/map/layer?layerType=Kartering'
        )
      )
    );
  }

  getSoilSamplingCoordinateJson(
    layerId: number
  ): Observable<GeoJSON.FeatureCollection> {
    return this.clientId().pipe(
      flatMap((id) =>
        this.http.get<GeoJSON.FeatureCollection>(
          environment.baseApiUrl + 'klient/' + id + '/map/layer/json/' + layerId
        )
      )
    );
  }

  //Tror det är number
  softDeleteLayer(layerId): Observable<number> {
    return this.clientId().pipe(
      flatMap((id) =>
        this.http.delete<number>(
          environment.baseApiUrl +
            'klient/' +
            id +
            '/map/layer/' +
            layerId +
            '?softDelete=true'
        )
      )
    );
  }

  getLayersFeatures(ids: number[]): Observable<GeoJSON.FeatureCollection> {
    return this.clientId().pipe(
      flatMap((id) =>
        this.http.get<GeoJSON.FeatureCollection>(
          environment.baseApiUrl +
            'klient/' +
            id +
            '/map/interpolation/layer/boundary' +
            ids
              .map((id, index) => {
                return (index === 0 ? '?' : '&') + `layerIds[${index}]=${id}`;
              })
              .join('')
        )
      )
    );
  }

  getMarking(layerId: number): Observable<GeoJSON.FeatureCollection> {
    return this.clientId().pipe(
      flatMap((id) =>
        this.http.get<GeoJSON.FeatureCollection>(
          environment.baseApiUrl + 'klient/' + id + '/map/layer/json/' + layerId
        )
      )
    );
  }

  getMarkings(): Observable<GeoJSON.FeatureCollection> {
    return this.clientId().pipe(
      flatMap((id) =>
        this.http.get<GeoJSON.FeatureCollection>(
          environment.baseApiUrl + 'klient/' + id + '/map/layer'
        )
      )
    );
  }

  getFiles(): Observable<FileModel[]> {
    return this.clientId().pipe(
      flatMap((id) =>
        this.http.get<FileModel[]>(
          environment.baseApiUrl + 'klient/' + id + '/map/interpolation/layer'
        )
      ),
      map((res) => res.reduce((acc, val) => acc.concat(val), []))
    );
  }
  getLayer(layerId: number): Observable<GeoJSON.FeatureCollection> {
    return this.clientId().pipe(
      flatMap((id) =>
        this.http.get<GeoJSON.FeatureCollection>(
          environment.baseApiUrl + 'klient/' + id + '/map/layer/' + layerId
        )
      )
    );
  }
  deleteLayer(layerId: number): Observable<GeoJSON.FeatureCollection> {
    return this.clientId().pipe(
      flatMap((id) =>
        this.http.delete<GeoJSON.FeatureCollection>(
          environment.baseApiUrl + 'klient/' + id + '/map/layer/' + layerId
        )
      )
    );
  }

  getGeoDataJson(fileId: number): Observable<GeoJSON.FeatureCollection> {
    return this.clientId().pipe(
      flatMap((id) =>
        this.http.get<GeoJSON.FeatureCollection>(
          environment.baseApiUrl +
            'klient/' +
            id +
            '/map/file/geodata/' +
            fileId
        )
      )
    );
  }

  getPoi(id: number): Observable<PoiModel> {
    try {
      return this.clientId().pipe(
        flatMap((clientId) =>
          this.http.get<PoiModel>(
            environment.baseApiUrl + 'klient/' + clientId + '/map/poi/' + id
          )
        )
      );
    } catch (err) {
      console.log(err.response);
      return null;
    }
  }

  getAllPoi(): Observable<PoiModel[]> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.get<PoiModel[]>(
          environment.baseApiUrl + 'klient/' + client.id + '/map/poi/'
        )
      )
    );
  }

  getPoiLayer(): Observable<GeoJSON.FeatureCollection> {
    return this.clientId().pipe(
      flatMap((id) =>
        this.http.get<GeoJSON.FeatureCollection>(
          environment.baseApiUrl + 'klient/' + id + '/map/poi/layer/json'
        )
      )
    );
  }

  removePoi(id: number): Observable<GeoJSON.FeatureCollection> {
    return this.clientId().pipe(
      flatMap((klientId) =>
        this.http.delete<GeoJSON.FeatureCollection>(
          environment.baseApiUrl + 'klient/' + klientId + '/map/poi/' + id
        )
      )
    );
  }

  togglePoi(
    id: number,
    utford: boolean
  ): Observable<GeoJSON.FeatureCollection> {
    if (utford) {
      return this.clientId().pipe(
        flatMap((klientId) =>
          this.http.put<GeoJSON.FeatureCollection>(
            environment.baseApiUrl +
              'klient/' +
              klientId +
              '/map/poi/' +
              id +
              '/utford',
            {}
          )
        )
      );
    } else {
      return this.clientId().pipe(
        flatMap((klientId) =>
          this.http.delete<GeoJSON.FeatureCollection>(
            environment.baseApiUrl +
              'klient/' +
              klientId +
              '/map/poi/' +
              id +
              '/utford',
            {}
          )
        )
      );
    }
  }

  loadPoiCategorys(): Subscription {
    return this.clientId()
      .pipe(
        flatMap((id) =>
          this.http.get<PoiKategoriModel[]>(
            environment.baseApiUrl +
              'klient/' +
              id +
              '/' +
              new Date().getFullYear() +
              '/poikategori'
          )
        )
      )
      .subscribe((res) => this.poiCategorys$.next(res));
  }
  getPoisCategorys(): Observable<PoiKategoriModel[]> {
    return this.poiCategorys$.asObservable();
  }

  savePoiCategory(cat: PoiKategoriModel): Observable<PoiKategoriModel> {
    return this.client()
      .pipe(
        flatMap((client) =>
          this.http.put<PoiKategoriModel>(
            environment.baseApiUrl +
              'klient/' +
              client.id +
              '/' +
              client.ar +
              '/poikategori/' +
              cat.id,
            cat
          )
        )
      )
      .pipe(tap(() => this.loadPoiCategorys()));
  }

  addPoiCategory(name: string): Observable<PoiKategoriModel> {
    return this.client()
      .pipe(
        flatMap((client) =>
          this.http.post<PoiKategoriModel>(
            environment.baseApiUrl +
              'klient/' +
              client.id +
              '/' +
              client.ar +
              '/poikategori',
            {
              id: -1,
              namn: name,
            }
          )
        )
      )
      .pipe(tap(() => this.loadPoiCategorys()));
  }

  deletePoiCategory(cat: PoiKategoriModel): Observable<void> {
    return this.client()
      .pipe(
        flatMap((client) =>
          this.http.delete<void>(
            environment.baseApiUrl +
              'klient/' +
              client.id +
              '/' +
              client.ar +
              '/poikategori/' +
              cat.id
          )
        )
      )
      .pipe(tap(() => this.loadPoiCategorys()));
  }

  savePoi(poi: PoiModel, imageDeleted = false): Observable<PoiModel> {
    if (poi.id > 0) {
      if (poi.image === null && imageDeleted) {
        return this.clientId().pipe(
          flatMap((clientId) =>
            this.http.put<PoiModel>(
              environment.baseApiUrl +
                'klient/' +
                clientId +
                '/map/poi/' +
                poi.id,
              poi
            )
          )
        );
      } else {
        const poiPatch: PoiPatchModel = poi;
        if (poiPatch.image === null) {
          delete poiPatch['image'];
        }
        poiPatch.utfordDatum = poiPatch.utford;
        poiPatch.utford = !!poiPatch.utford;
        return this.clientId().pipe(
          flatMap((clientId) =>
            this.http.patch<PoiModel>(
              environment.baseApiUrl +
                'klient/' +
                clientId +
                '/map/poi/' +
                poiPatch.id,
              poiPatch
            )
          )
        );
      }
    } else {
      return this.clientId().pipe(
        flatMap((clientId) =>
          this.http.post<PoiModel>(
            environment.baseApiUrl + 'klient/' + clientId + '/map/poi',
            poi
          )
        )
      );
    }
  }

  savePoiImg(img: File): Observable<PoiImageModel> {
    const formData: FormData = new FormData();
    formData.append('file', img);
    return this.clientId().pipe(
      flatMap((clientId) =>
        this.http.post<PoiImageModel>(
          environment.baseApiUrl + 'klient/' + clientId + '/map/poi/images',
          formData
        )
      )
    );
  }

  getProdUts(inklSort = false): Observable<ProduktUtsadeModel[]> {
    return this.clientId().pipe(
      flatMap((clientId) =>
        this.http.get<ProduktUtsadeModel[]>(
          environment.baseApiUrl + 'klient/' + clientId + '/produkt/utsade'
        )
      ),
      map((utsaden) => {
        if (inklSort) {
          return utsaden;
        }
        const uts: ProduktUtsadeModel[] = [];
        utsaden.forEach((utsade) => {
          const index = uts.findIndex((u) => u.groda === utsade.groda);
          if (index === -1) {
            utsade.sort = '';
            uts.push(utsade);
          }
        });

        return uts;
      })
    );
  }

  loadTabellUtsade(): Observable<TabellUtsadeModel[]> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.get<TabellUtsadeModel[]>(
          `${environment.baseApiUrl}klient/${client.id}/${client.ar}/tabellutsade`
        )
      ),
      tap((res) => this.tabellUtsade.next(res))
    );
  }

  saveTabellUtsade(model: ProduktModel): Observable<TabellUtsadeModel> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.post<TabellUtsadeModel>(
          `${environment.baseApiUrl}klient/${client.id}/${client.ar}/tabellutsade`,
          model
        )
      ),
      map((res) => {
        return res;
      })
    );
  }

  getSvg(points: PointModel[]): Observable<string> {
    return this.http
      .post<{ svg: string }>(`${environment.baseApiUrl}map/block/svg`, points)
      .pipe(map((res) => res.svg));
  }

  /**
   * Gets a FeatureCollection with skiften, this is a cached stream from year and client id, to refetch use loadSkifteLayer
   */
  getSkifteLayer(): Observable<GeoJSON.FeatureCollection> {
    return this.skifteLayer$.pipe(
      filter((fc) => fc !== null),
      mergeMap((fc) => {
        return of(fc);
      })
    );
  }

  getFieldFunctions(fieldId: number): Observable<FunctionModel[]> {
    return this.clientId().pipe(
      mergeMap((id) =>
        this.http.get<FunctionModel[]>(
          `${environment.baseApiUrl}klient/${id}/map/prescription/${fieldId}/functions`
        )
      )
    );
  }
  /**
   * loads skifte, often it is not needed to use, sins it will be called on new year and new client id
   */
  loadSkifteLayer(): void {
    //clears out the cache so its safe to subscribe on the getSkifteLayer with out to now when the skifte has been finished loaded
    this.skifteLayer$.next(null);
    this.client()
      .pipe(
        flatMap((client) =>
          this.http.get<GeoJSON.FeatureCollection>(
            `${environment.baseApiUrl}klient/${client.id}/map/kopplad/json/${client.ar}`
          )
        )
      )
      .subscribe((res) => this.skifteLayer$.next(res));
  }

  skiftenIsFetched(): boolean {
    return this.skifteIsFetched;
  }
  /**
   * Stores a new skifte to the database, call loadSkifteLayer to se the new skifte in the feature collection
   * @param skifte the skifte to add
   */
  addSkifte(skifte: NewSkifteEnkelModel[]): Observable<void> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.post<void>(
          `${environment.baseApiUrl}klient/${client.id}/${client.ar}/skifte/enkel`,
          skifte
        )
      ),
      map((skiften) => {
        this.setGuideVisited();
        return skiften;
      })
    );
  }

  setGuideVisited(): void {
    const model = { guide: 'odling' };
    this.client().pipe(
      flatMap((client) =>
        this.http.post(
          environment.baseApiUrl + 'klient/' + client.id + '/guide',
          model
        )
      )
    );
  }

  deleteSkifte(skifteId: number, force = false): Observable<void> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.delete<void>(
          environment.baseApiUrl +
            'klient/' +
            client.id +
            '/' +
            client.ar +
            '/skifte/' +
            skifteId +
            '?force=' +
            force
        )
      )
    );
  }

  getSkifte(skifteId: number): Observable<SkifteDetielsModel> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.get<SkifteDetielsModel>(
          environment.baseApiUrl +
            'klient/' +
            client.id +
            '/' +
            client.ar +
            '/skifte/' +
            skifteId +
            '?include=none'
        )
      )
    );
  }

  getSkiften(): Observable<SkifteDetielsModel[]> {
    if (this.skiften$.value.length === 0) {
      this.loadSkiften();
    }
    return this.skiften$.pipe(
      map((skiften) => {
        let ids: number[];
        return skiften.filter((s: SkifteDetielsModel) => {
          if (ids) {
            return ids.includes(s.id, 0);
          } else {
            return true;
          }
        });
      })
    );
  }

  loadSkiften(): void {
    this.skifteIsFetched = false;
    this.client()
      .pipe(
        flatMap((client) => {
          if (client.ar === 0) {
            throw client.ar;
          }
          return this.http.get<SkifteDetielsModel[]>(
            `${environment.baseApiUrl}klient/${client.id}/${client.ar}/skifte?expand=true&include=none`
          );
        }),
        retryWhen((errors) => {
          return errors.pipe(delayWhen(() => timer(5)));
        })
      )
      .subscribe((res) => {
        this.skifteIsFetched = true;
        this.skiften$.next(res);
      });
  }

  getSkiftenNorge(
    kommun: number,
    gard: number,
    brukare: number
  ): Observable<GeoJSON.FeatureCollection<GeoJSON.GeometryObject>> {
    return this.client().pipe(
      flatMap(() =>
        this.http.get<FeatureCollection>(
          environment.baseApiUrl +
            'map/block/norge/' +
            kommun +
            '/' +
            gard +
            '/' +
            brukare
        )
      ),
      map((res) => {
        const block = res as GeoJSON.FeatureCollection<GeoJSON.GeometryObject>;
        block.bbox = turf.bbox(res as AllGeoJSON);
        return block as GeoJSON.FeatureCollection<GeoJSON.GeometryObject>;
      })
    );
  }

  changeHuvudgroda(
    skifte: SkifteDetielsModel,
    newTabellUtsadeId: number
  ): Observable<UtsadeModel> {
    if (!skifte.huvudgroda) {
      return this.client().pipe(
        flatMap((client) =>
          this.http.post<UtsadeModel>(
            environment.baseApiUrl +
              'klient/' +
              client.id +
              '/' +
              client.ar +
              '/skifte/' +
              skifte.id +
              '/utsade/',
            {
              id: -1,
              utsadeId: newTabellUtsadeId,
              huvudgroda: true,
              korning: 1,
              areal: 1,
            }
          )
        )
      );
    } else {
      const utsade = skifte.huvudgroda;
      utsade.utsadeId = newTabellUtsadeId;
      return this.client().pipe(
        flatMap((client) =>
          this.http.put<UtsadeModel>(
            environment.baseApiUrl +
              'klient/' +
              client.id +
              '/' +
              client.ar +
              '/skifte/' +
              skifte.id +
              '/utsade/' +
              utsade.id,
            utsade
          )
        )
      );
    }
  }
  saveSkifte(skifte: SkifteDetielsModel): Observable<SkifteDetielsModel> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.put<SkifteDetielsModel>(
          environment.baseApiUrl +
            'klient/' +
            client.id +
            '/' +
            client.ar +
            '/skifte/' +
            skifte.id,
          skifte
        )
      )
    );
  }

  getBrukningsenheter(): Observable<BrukningsenhetModel[]> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.get<BrukningsenhetModel[]>(
          environment.baseApiUrl +
            'klient/' +
            client.id +
            '/' +
            client.ar +
            '/brukningsenhet'
        )
      )
    );
  }

  updateBrukningsenhet(
    brukenhet: BrukningsenhetModel
  ): Observable<BrukningsenhetModel[]> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.put<BrukningsenhetModel[]>(
          environment.baseApiUrl +
            'klient/' +
            client.id +
            '/' +
            client.ar +
            '/brukningsenhet/' +
            brukenhet.id,
          brukenhet
        )
      )
    );
  }

  deleteBrukningsenhet(
    brukenhet: BrukningsenhetModel
  ): Observable<BrukningsenhetModel[]> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.delete<BrukningsenhetModel[]>(
          environment.baseApiUrl +
            'klient/' +
            client.id +
            '/' +
            client.ar +
            '/brukningsenhet/' +
            brukenhet.id
        )
      )
    );
  }

  createBrukningsenhet(
    brukenhet: BrukningsenhetModel
  ): Observable<BrukningsenhetModel> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.post<BrukningsenhetModel>(
          environment.baseApiUrl +
            'klient/' +
            client.id +
            '/' +
            client.ar +
            '/brukningsenhet',
          brukenhet
        )
      )
    );
  }

  uploadFiles(importSettings: ImportModel): Observable<HttpEvent<string>> {
    const formData = new FormData();
    importSettings.files.forEach((file, index) => {
      formData.append('file-' + index, file, file.name);
    });
    formData.append('manufacture', importSettings.manufacture);
    formData.append('type', importSettings.type);

    const filteredMetadata = importSettings.metadata.filter((data) => {
      return data.key !== '' && data.value !== '';
    });
    formData.append('metadata', JSON.stringify(filteredMetadata));

    return this.client().pipe(
      flatMap((client) =>
        this.http.post<string>(
          environment.baseApiUrl + 'klient/' + client.id + '/map/file',
          formData,
          { reportProgress: true, observe: 'events' }
        )
      )
    );
  }

  exportLayer(layer: Layer, type: string): Observable<string> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.post(
          `${environment.baseApiUrl}klient/${client.id}/map/layer/${
            layer.id
          }/export/${type}?fileName=${this.trimSystemfolder.transform(
            layer.name
          )}`,
          {},
          { observe: 'response' }
        )
      ),
      map((res) => {
        return res.headers.get('Location');
      })
    );
  }
  updateLayer(layer: Layer): Observable<void> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.put<void>(
          `${environment.baseApiUrl}klient/${client.id}/map/layer/${layer.id}`,
          layer
        )
      )
    );
  }

  updateLayerFilename(layerId: number, name: string): Observable<void> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.put<void>(
          `${environment.baseApiUrl}klient/${client.id}/map/interpolation/layer`,
          { layerId: layerId, fileName: name }
        )
      )
    );
  }

  importStatusReaded(blobRef: string): Observable<ImportStatusModel> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.put<ImportStatusModel>(
          environment.baseApiUrl +
            'klient/' +
            client.id +
            '/map/file/status/' +
            blobRef,
          {}
        )
      )
    );
  }

  importStatus(onlyNew: boolean): Observable<ImportStatusGroup[]> {
    return this.client()
      .pipe(
        flatMap((client) =>
          this.http.get<ImportStatusModel[]>(
            `${environment.baseApiUrl}klient/${client.id}/map/file/status?onlyNew=${onlyNew}`
          )
        )
      )
      .pipe(
        map((res) => {
          let importGroups: ImportStatusGroup[] = [];
          res.forEach((status) => {
            let group = importGroups.find((g) => g.blobRef === status.blobRef);
            if (!group) {
              group = new ImportStatusGroup();
              importGroups.push(group);
            }
            group.blobRef = status.blobRef;
            group.date = status.date;
            group.message = status.message;
            if (group.success) {
              group.success = status.success;
            }
            group.status.push(status);
          });

          importGroups = importGroups.sort((a, b) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          });

          return importGroups;
        })
      );
  }

  getFileImportStatus(fileId: number): Observable<FileImportStatusLogEntry[]> {
    return this.client().pipe(
      mergeMap((client) =>
        this.http.get<FileImportStatusLogEntry[]>(
          `${environment.baseApiUrl}klient/${client.id}/map/file/fileset/${fileId}/statuslog`
        )
      )
    );
  }

  loadMappingGroups(): void {
    this.client()
      .pipe(
        flatMap((client) =>
          this.http.get<string[]>(
            `${environment.baseApiUrl}klient/${client.id}/map/mapping/groups`
          )
        )
      )
      .subscribe((res) => {
        res.push('ControlMASTER');
        this.mappingGroups$.next(res);
      });
  }

  getGroupOptions(layers: number[]): Observable<InterpolationLayerModel[]> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.get<InterpolationLayerModel[]>(
          environment.baseApiUrl +
            `klient/${client.id}/map/interpolation/skifte` +
            layers
              .map(
                (layer, index) =>
                  (index === 0 ? '?' : '&') + `skifteIds[${index}]=${layer}`
              )
              .join('')
        )
      )
    );
  }

  getMappingLayersAllYears(
    features: GeoJSON.FeatureCollection
  ): Observable<MappingLayerModel[][]> {
    if (features === null || features.features.length === 0) {
      return this.client().pipe(
        flatMap((client) =>
          this.http.get<MappingLayerModel[][]>(
            environment.baseApiUrl + 'klient/' + client.id + '/map/mapping'
          )
        )
      );
    } else {
      return this.client().pipe(
        flatMap((client) => {
          return this.http.put<MappingLayerModel[][]>(
            `${environment.baseApiUrl}klient/${client.id}/map/mapping/`,
            features
          );
        })
      );
    }
  }

  getGeoDataPointSpreadBoundary(
    fileId: number
  ): Observable<GeoJSON.FeatureCollection> {
    return this.client().pipe(
      switchMap((client) => {
        return this.http.get<GeoJSON.FeatureCollection>(
          `${environment.baseApiUrl}klient/${client.id}/map/file/geodata/${fileId}/boundary/spread`
        );
      })
    );
  }

  getGeoData(
    fileId: number
    // boundary: GeoJSON.FeatureCollection
  ): Observable<GeoJSON.FeatureCollection> {
    return this.client().pipe(
      switchMap((client) => {
        return this.http.get<GeoJSON.FeatureCollection>(
          `${environment.baseApiUrl}klient/${client.id}/map/file/geodata/${fileId}`
        );
      })
    );
  }

  getGeoDataProperties(fileId: number): Observable<PropertyInfoModel[]> {
    return this.client().pipe(
      switchMap((client) => {
        return this.http.get<PropertyInfoModel[]>(
          `${environment.baseApiUrl}klient/${client.id}/map/file/geodata/${fileId}/properties`
        );
      })
    );
  }

  getGeoDataFields(fileId: number): Observable<GridFieldModel[]> {
    return this.client().pipe(
      switchMap((client) => {
        return this.http.get<GridFieldModel[]>(
          `${environment.baseApiUrl}klient/${client.id}/map/file/geodata/${fileId}/fields`
        );
      })
    );
  }

  getGeodataFromFields(fieldIds: number[]): Observable<FieldGridModel[]> {
    return this.client().pipe(
      switchMap((client) => {
        const params = new HttpParams({
          fromObject: { fieldIds: fieldIds.join(',') },
        });
        return this.http.get<FieldGridModel[]>(
          `${environment.baseApiUrl}klient/${client.id}/map/file/geodata/field/grid`,
          { params: params }
        );
      })
    );
  }

  getGeographicDataForFields(fieldIds: number[]): Observable<string[]> {
    const payLoad = {
      fieldIds: fieldIds,
    };
    return this.client().pipe(
      mergeMap((client) => {
        return this.http.post<string[]>(
          `${environment.baseApiUrl}klient/${client.id}/map/interpolation/contour/geographic`,
          payLoad
        );
      })
    );
  }

  getInterpolationProperties(): Observable<PropertyInfoModel[]> {
    return this.client().pipe(
      switchMap((client) => {
        return this.http.get<PropertyInfoModel[]>(
          `${environment.baseApiUrl}klient/${client.id}/map/file/properties`
        );
      })
    );
  }

  getInterpolation(
    type: string,
    property: string,
    fc: GeoJSON.FeatureCollection,
    layerIds: number[],
    cutToBoundary = true
  ): Observable<InterpolationModel> {
    const payLoad = {
      fc: fc,
      layerIds: layerIds,
      cutToBoundary: cutToBoundary,
    };
    return this.client().pipe(
      switchMap((client) => {
        return this.http.post<InterpolationModel>(
          `${environment.baseApiUrl}klient/${client.id}/map/mapping/${client.ar}/${type}/${property}`,
          payLoad
        );
      })
    );
  }

  getGeoDataInterpolation(
    type: string,
    property: string,
    fc: GeoJSON.FeatureCollection,
    geoDataIds: number[],
    asIndex = false
  ): Observable<InterpolationCollection> {
    const payLoad = {
      fc: fc,
      layerIds: [],
      geoDataFileIds: geoDataIds,
      cutToBoundary: true,
      asIndex: asIndex,
    };
    return this.client().pipe(
      mergeMap((client) => {
        return this.http.put<InterpolationCollection>(
          `${environment.baseApiUrl}klient/${client.id}/map/interpolation/contour/${client.ar}/${type}/${property}`,
          payLoad
        );
      })
    );
  }

  getSguInterpolation(fieldIds: number[]): Observable<InterpolationCollection> {
    const payLoad = {
      fieldIds: fieldIds,
    };
    return this.client().pipe(
      mergeMap((client) => {
        return this.http.post<InterpolationCollection>(
          `${environment.baseApiUrl}klient/${client.id}/map/interpolation/contour/sgu`,
          payLoad
        );
      })
    );
  }

  getKhclInterpolation(
    fieldId: number,
    fileIds: number[]
  ): Observable<InterpolationCollection> {
    return this.client().pipe(
      switchMap((client) => {
        const url = `${environment.baseApiUrl}klient/${client.id}/map/interpolation/${fieldId}/function/khclclay`;

        return this.http.post<InterpolationCollection>(url, fileIds);
      })
    );
  }

  getGeoDataInterpolationPoints(
    type: string,
    property: string,
    fc: GeoJSON.FeatureCollection,
    geoDataIds: number[]
  ): Observable<InterpolationModel> {
    const payLoad = {
      fc: fc,
      layerIds: [],
      geoDataFileIds: geoDataIds,
      cutToBoundary: true,
    };
    return this.client().pipe(
      switchMap((client) => {
        return this.http.put<InterpolationModel>(
          `${environment.baseApiUrl}klient/${client.id}/map/interpolation/points/${client.ar}/${type}/${property}`,
          payLoad
        );
      })
    );
  }

  getTargetPhInterpolation(
    fieldId: number,
    body: TargetPhInput
  ): Observable<TargetPhResponse> {
    return this.client().pipe(
      switchMap((client) => {
        const url = `${environment.baseApiUrl}klient/${client.id}/map/interpolation/${fieldId}/function/targetph`;

        return this.http.post<TargetPhResponse>(url, body);
      })
    );
  }

  exportPrescription(
    prescriptionFile: PrescriptionFileModel
  ): Observable<HttpResponse<Blob>> {
    const payLoad = prescriptionFile;
    return this.client().pipe(
      switchMap((client) => {
        return this.http.post<Blob>(
          `${environment.baseApiUrl}klient/${client.id}/map/prescription/export`,
          payLoad,
          {
            observe: 'response',
            responseType: 'blob' as 'json',
            reportProgress: true,
          }
        );
      })
    );
  }

  exportUntypedPrescription(
    payload: PrescriptionUntypedFileModel
  ): Observable<HttpResponse<Blob>> {
    return this.client().pipe(
      switchMap((client) => {
        return this.http.post<Blob>(
          `${environment.baseApiUrl}klient/${client.id}/map/file/featureCollection`,
          payload,
          { observe: 'response' }
        );
      })
    );
  }

  getPrescriptionGridPreview(
    gridSettings: GridSettings,
    fieldId: number
  ): Observable<FeatureCollection> {
    return this.client().pipe(
      switchMap((client) => {
        const url = `${environment.baseApiUrl}klient/${client.id}/map/prescription/${fieldId}/grid`;
        return this.http.post<FeatureCollection>(url, gridSettings);
      })
    );
  }

  getPrescriptionGrid(
    settings: PrescriptionSettings,
    skifteId: number
  ): Observable<PrescriptionModel> {
    return this.client().pipe(
      switchMap((client) => {
        return this.http.post<PrescriptionModel>(
          `${environment.baseApiUrl}klient/${client.id}/map/prescription/skifte/function/${skifteId}`,
          settings
        );
      })
    );
  }

  getPrescriptionGridUpdate(
    settings: AdjustPrescriptionModel
  ): Observable<PrescriptionModel> {
    return this.clientId().pipe(
      switchMap((clientId) => {
        return this.http.post<PrescriptionModel>(
          `${environment.baseApiUrl}klient/${clientId}/map/prescription/adjust`,
          settings
        );
      })
    );
  }

  getCreateFieldPoint(): Observable<PointModel> {
    return this.createFieldPoint$.asObservable();
  }

  setCreateFieldPoint(model: { lat: number; lng: number }): void {
    this.createFieldPoint$.next(model);
  }

  getBlockFeatureFromPoint(model: {
    lat: number;
    lng: number;
  }): Observable<GeoJSON.FeatureCollection> {
    return this.http.get<GeoJSON.FeatureCollection>(
      `${environment.baseApiUrl}map/block?lat=${model.lat}&lng=${model.lng}`
    );
  }

  startNewYear(fromYear: number, toYear: number): Observable<ArKopieringModel> {
    return this.client().pipe(
      flatMap((client) =>
        this.http
          .get<ArKopieringModel>(
            environment.baseApiUrl +
              `/klient/${client.id}/ar/${fromYear}/kopiera/${toYear}`
          )
          .pipe(
            map((arModel) => {
              //värdet vi får i tabeller är vilka tabeller som är kopierade
              //värdet vi skickar upp är vilka tabeller som skall kopieras
              //genom att vända på det så kopierar vi endast de tabeller som inte redan är kopierade
              for (const key in arModel.tabeller) {
                arModel.tabeller[key] = !arModel.tabeller[key];
              }
              return arModel;
            })
          )
      )
    );
  }

  createNewYear(
    fromYear: number,
    toYear: number,
    arKopiering: ArKopieringModel
  ): Observable<boolean> {
    return this.client().pipe(
      flatMap((client) =>
        this.http
          .post<Response>(
            environment.baseApiUrl +
              `/klient/${client.id}/ar/${fromYear}/kopiera/${toYear}`,
            arKopiering,
            { observe: 'response' }
          )
          .pipe(
            map((res) => {
              return res.status === 201;
            })
          )
      )
    );
  }

  missingSkifteCount(year: number): Observable<MissingSkifteModel> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.get<MissingSkifteModel>(
          `${environment.baseApiUrl}klient/${client.id}/map/mapping/missingskifte/${year}`
        )
      )
    );
  }

  missingSkifteClear(): Observable<MissingSkifteModel> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.delete<MissingSkifteModel>(
          `${environment.baseApiUrl}klient/${client.id}/map/mapping/missingskifte`
        )
      )
    );
  }

  missingSkifteImport(
    brukId: number,
    selectedYear: number
  ): Observable<MissingSkifteModel> {
    return this.client().pipe(
      flatMap((client) =>
        this.http.post<MissingSkifteModel>(
          `${environment.baseApiUrl}klient/${client.id}/map/mapping/missingskifte/${selectedYear}`,
          { id: brukId }
        )
      )
    );
  }

  upgradeToCropMapAdvanced(): Observable<boolean> {
    return this.client().pipe(
      flatMap(() =>
        this.http.post<boolean>(
          `${environment.baseApiUrl}customer/order/cropmap`,
          {}
        )
      )
    );
  }

  getGeoRawFiles(): Observable<RawFileSetModel[]> {
    return this.clientId().pipe(
      mergeMap((clientId) =>
        this.http.get<RawFileSetModel[]>(
          `${environment.baseApiUrl}klient/${clientId}/map/file`
        )
      )
    );
  }

  getGeodataFiles(type?: string): Observable<GeoDataFileModel[]> {
    return this.clientId().pipe(
      mergeMap((clientId) =>
        this.http.get<GeoDataFileModel[]>(
          `${environment.baseApiUrl}klient/${clientId}/map/file/geodata`,
          {
            params: type ? { type } : undefined,
          }
        )
      )
    );
  }

  updateGeodataMetadata(
    geodataId: number,
    metadata: GeoDataMetaData
  ): Observable<void> {
    return this.clientId().pipe(
      mergeMap((clientId) =>
        this.http.post<void>(
          `${environment.baseApiUrl}klient/${clientId}/map/file/geodata/${geodataId}/metadata`,
          metadata
        )
      )
    );
  }

  deleteGeoRawFiles(filedataId: number): Observable<HttpResponse<Blob>> {
    return this.clientId().pipe(
      mergeMap((clientId) =>
        this.http.delete<HttpResponse<Blob>>(
          `${environment.baseApiUrl}klient/${clientId}/map/file/${filedataId}`
        )
      )
    );
  }

  deleteGeoDataFiles(geodataId: number): Observable<HttpResponse<Blob>> {
    return this.clientId().pipe(
      mergeMap((clientId) =>
        this.http.delete<HttpResponse<Blob>>(
          `${environment.baseApiUrl}klient/${clientId}/map/file/geodata/${geodataId}`
        )
      )
    );
  }

  downloadFileByFormat(
    filesetId: number,
    format: FILE_FORMAT
  ): Observable<HttpResponse<Blob>> {
    return this.clientId().pipe(
      mergeMap((clientId) => {
        return this.http.post<Blob>(
          `${environment.baseApiUrl}klient/${clientId}/map/file/geodata/${filesetId}/download/${format}`,
          {},
          {
            observe: 'response',
            responseType: 'blob' as 'json',
            reportProgress: true,
          }
        );
      })
    );
  }

  downloadRawFiles(filesetId: number): Observable<HttpResponse<Blob>> {
    return this.clientId().pipe(
      mergeMap((clientId) => {
        return this.http.post<Blob>(
          `${environment.baseApiUrl}klient/${clientId}/map/file/fileset/${filesetId}/download`,
          {},
          {
            observe: 'response',
            responseType: 'blob' as 'json',
            reportProgress: true,
          }
        );
      })
    );
  }

  reimportGeoData(geodataId: number): Observable<HttpResponse<Blob>> {
    return this.clientId().pipe(
      mergeMap((clientId) => {
        return this.http.post<Blob>(
          `${environment.baseApiUrl}klient/${clientId}/map/file/geodata/${geodataId}/reimport`,
          {},
          {
            observe: 'response',
          }
        );
      })
    );
  }

  reimportFileSet(filesetId: number): Observable<HttpResponse<Blob>> {
    return this.clientId().pipe(
      mergeMap((clientId) => {
        return this.http.post<Blob>(
          `${environment.baseApiUrl}klient/${clientId}/map/file/fileset/${filesetId}/reimport`,
          {},
          {
            observe: 'response',
          }
        );
      })
    );
  }

  recalibrateYield(
    geodataId: number,
    yieldInput: CalibratingYieldModel
  ): Observable<HttpResponse<Blob>> {
    return this.clientId().pipe(
      mergeMap((clientId) => {
        return this.http.post<Blob>(
          `${environment.baseApiUrl}klient/${clientId}/map/file/geodata/${geodataId}/calibrate`,
          { yieldInput },
          {
            observe: 'response',
          }
        );
      })
    );
  }
}

interface PoiPatchModel extends PoiModel {
  utfordDatum?: Date;
}
