/**
 * Dialog that shows shareable map link
 */
import { Clipboard } from '@angular/cdk/clipboard';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DvAuthService, DvToolbarTranslateService } from '@dv/toolbar-msal';
import { environment } from 'environments/environment';

@Component({
  selector: 'dv-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  link: string = null;
  key: string;
  hasCopied = false;
  emails: EmailItem[] = [new EmailItem('')];
  placeholderText: string;
  comment = '';
  matcher = new MyErrorStateMatcher();
  loading: true;
  title: string;
  sendingEmail = false;
  includeDataFiles = true;
  files: number[] = [];
  providerHint = '';

  constructor(
    private clipboard: Clipboard,
    private translate: DvToolbarTranslateService,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private dialogRef: MatDialogRef<ShareComponent>,
    private dvAuthService: DvAuthService
  ) {}

  ngOnInit(): void {
    this.placeholderText = this.translate.t('Enter email');
    this.title = this.translate.t('Share interpolating');
    const providerDomain = this.dvAuthService.getDomain();

    switch (providerDomain) {
      case 'taakkaart.nl':
        this.providerHint = 'AGR';
        break;
      case 'taakkaart.be':
        this.providerHint = 'AGR-BE';
        break;
      case 'cartedemodulation.be':
        this.providerHint = 'AGR-BEFR';
        break;
      case 'applikationskarte.de':
        this.providerHint = 'AGR-DE';
        break;
    }
  }

  /**
   * Updates sharable link
   * @param key link ID
   */
  setKey(key: string): void {
    this.key = key;
    this.link = `${window.location.protocol}//${window.location.host}/share/${key}`;
    if (this.providerHint && this.providerHint.length > 0) {
      this.link += '?provider=' + this.providerHint;
    }
  }

  /**
   * Set files to share
   * @param fileIds
   */
  setFiles(fileIds: number[]): void {
    this.files = fileIds;
  }

  copyLink(): void {
    this.clipboard.copy(this.link);
    this.hasCopied = true;

    this.snackBar.open(this.translate.t('Copied to clipboard'), null, {
      duration: 3000,
    });
  }

  sendEmail(): void {
    this.sendingEmail = true;

    const recipients: string[] = [];

    this.emails.forEach((mailform) => {
      recipients.push(mailform.email);
    });

    this.http
      .post(`${environment.baseApiUrl}share/mail/map`, {
        url: this.link,
        recipients,
      })
      .subscribe(
        () => {
          this.snackBar.open(this.translate.t('Map Shared'), null, {
            duration: 3000,
          });
          this.close();
        },
        (error) => {
          this.snackBar.open(this.translate.t('An error has occurred'), null, {
            duration: 3000,
          });
          console.error(error);
          this.sendingEmail = false;
        }
      );
  }

  close(): void {
    this.dialogRef.close();
  }

  setComment(comment: string): void {
    this.http
      .put(`${environment.baseApiUrl}share/${this.key}/comment`, {
        comment: comment,
      })
      .subscribe();
  }

  shareFiles(): void {
    let f = this.files;
    if (!this.includeDataFiles) {
      f = [];
    }

    this.http
      .put(`${environment.baseApiUrl}share/${this.key}/files`, {
        fileIds: f,
      })
      .subscribe();
  }

  addEmail(e: Event): void {
    e.preventDefault();
    this.emails = [...this.emails, new EmailItem('')];
  }

  removeEmail(e: Event, pos: number): void {
    e.preventDefault();
    this.emails.splice(pos, 1);
  }

  hasErrors(): boolean {
    return (
      this.emails.filter(
        (e: EmailItem) =>
          e.formControl.hasError('email') || e.formControl.hasError('required')
      ).length > 0
    );
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

class EmailItem {
  constructor(email: string) {
    this.formControl = new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.minLength(3),
    ]);
    this.email = email;
  }
  email = '';
  formControl;
}
