import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PrescriptionExportComponent } from 'app/components/prescription-view/prescription-export/prescription-export.component';
import { FILE_TYPE } from 'app/models/file.model';
import { PrescriptionModel, PrescriptionBucketModel } from 'app/models/models';
import {
  PrescriptionFunctionModel,
  PrescriptionGridModel,
  PrescriptionType,
  PrescriptionUnit,
} from 'app/models/prescriptiongrid.model';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ClientService } from './client.service';
import { MapStateService } from './map-state.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Injectable({
  providedIn: 'root',
})
export class PrescriptionService {
  //state vars
  prescriptionCalculationLoading$ = new BehaviorSubject<boolean>(false);

  readonly EMPTY_PRESCRIPTION: PrescriptionModel;
  readonly DEVIATION_COLORS: string[] = [
    '#AFCA00',
    '#5DE082',
    '#E0601C',
    '#EFA922',
    '#F5689B',
    '#E78FFD',
    '#6DB870',
    '#AE9B68',
    '#54A089',
    '#E8BD8B',
  ];
  private exportRef: MatDialogRef<PrescriptionExportComponent> = null;
  originalGridSettings: PrescriptionGridModel;
  unit = PrescriptionUnit.kg;
  totalUnit = PrescriptionUnit[this.unit];
  type = PrescriptionType.fertilizer;
  defaultRotation = 0;

  constructor(
    private http: HttpClient,
    private clientSrv: ClientService,
    private router: Router,
    private dvTranslate: DvToolbarTranslateService,
    private dialog: MatDialog,
    private mapStateSrv: MapStateService
  ) {
    this.EMPTY_PRESCRIPTION = this.generateEmptyPrescription();
    this.clientSrv.getLandCode().subscribe((res) => {
      if (res === 'nl' || res === 'de' || res === 'be') {
        this.defaultRotation = -22;
      }
    });
  }

  /**
   * gets a set of new default grid settings
   * @returns
   */
  getNewGridSetting(): PrescriptionGridModel {
    return this.generateEmptyGridSetting();
  }

  initGrid(
    boundary: GeoJSON.FeatureCollection,
    geodataIds: number[],
    type: string,
    property: string
  ): void {
    this.originalGridSettings = this.getNewGridSetting();
    this.originalGridSettings.fc = boundary;
    this.originalGridSettings.type = type;
    this.originalGridSettings.property = property;
    this.originalGridSettings.geoDataFileIds = geodataIds;
  }

  setLoading(loading: boolean): void {
    this.prescriptionCalculationLoading$.next(loading);
  }

  /**
   * draws prescription features on map
   * @param prescription
   */
  drawPrescriptionGrid(prescription: PrescriptionModel): void {
    if (prescription?.fc?.features?.length) {
      this.mapStateSrv.setPrescription(
        <GeoJSON.FeatureCollection>prescription.fc
      );
    } else {
      this.clearPrescriptionGrid();
    }
  }

  /**
   * clears interpolation features from map
   */
  clearPrescriptionGrid(): void {
    this.mapStateSrv.setPrescription(null);
  }

  /**
   * calculates a new grid from given settings
   * @param gridSettings
   */
  calculateGrid(
    gridSettings: PrescriptionGridModel
  ): Observable<PrescriptionModel> {
    if (this.validateGridSettings(gridSettings)) {
      const payLoad = gridSettings;
      return this.clientSrv
        .clientId()
        .pipe(
          mergeMap((clientId) =>
            this.http.post<PrescriptionModel>(
              `${environment.baseApiUrl}klient/${clientId}/map/prescription/grid`,
              payLoad
            )
          )
        );
    } else {
      return of(null);
    }
  }

  /**
   * recalculate prescription on existing grid
   * @param gridSettings
   */
  recalculateGrid(
    oldPrescription: PrescriptionModel
  ): Observable<PrescriptionModel> {
    if (!this.validateRecalcSettings(oldPrescription)) {
      return of(null);
    }
    return this.clientSrv.client().pipe(
      mergeMap((client) => {
        return this.http.put<PrescriptionModel>(
          `${environment.baseApiUrl}klient/${client.id}/map/prescription/grid`,
          oldPrescription
        );
      })
    );
  }

  getPrescriptionFunctions(): Observable<PrescriptionFunctionModel[]> {
    return this.clientSrv
      .clientId()
      .pipe(
        mergeMap((clientId) =>
          this.http.get<PrescriptionFunctionModel[]>(
            `${environment.baseApiUrl}klient/${clientId}/map/prescription/function/all`
          )
        )
      );
  }

  /** set prescription global unit */
  setUnit(unit: PrescriptionUnit): void {
    this.unit = unit;
    this.totalUnit = PrescriptionUnit[this.unit];
  }

  /**
   * navigates to prescription view
   */
  gotoPrescription(): void {
    this.clientSrv.clientId().subscribe((clientId) => {
      this.router.navigateByUrl('client/' + clientId + '/map/prescription');
    });
  }

  getDeviationColor(pos: number): string {
    return this.DEVIATION_COLORS[pos % this.DEVIATION_COLORS.length];
  }

  unitAsText(unit: PrescriptionUnit = null): string {
    if (unit === null) {
      unit = this.unit;
    }
    switch (unit) {
      case PrescriptionUnit.kg:
        return 'kg/ha';
      case PrescriptionUnit.liter:
        return 'l/ha';
      case PrescriptionUnit.ton:
        return 'ton/ha';
    }
  }

  getTotalUnit(unit: PrescriptionUnit = null): string {
    if (unit === null) {
      unit = this.unit;
    }
    switch (unit) {
      case PrescriptionUnit.kg:
        return 'kg';
      case PrescriptionUnit.liter:
        return 'l';
      case PrescriptionUnit.ton:
        return 'ton';
      default:
        '';
    }
  }

  typeAsText(type: PrescriptionType = null): string {
    if (type === null) {
      type = this.type;
    }
    switch (type) {
      case PrescriptionType.fertilizer:
        return this.dvTranslate.t('Fertilizing');
      case PrescriptionType.seed:
        return this.dvTranslate.t('Sowing');
      case PrescriptionType.plantprotection:
        return this.dvTranslate.t('Spraying');
      case PrescriptionType.lime:
        return this.dvTranslate.t('Liming');
      default:
        return '';
    }
  }

  typeAsUntranslatedText(type: PrescriptionType = null): string {
    if (type === null) {
      type = this.type;
    }
    switch (type) {
      case PrescriptionType.fertilizer:
        return 'Fertilizing';
      case PrescriptionType.seed:
        return 'Sowing';
      case PrescriptionType.plantprotection:
        return 'Spraying';
      case PrescriptionType.lime:
        return 'Liming';
      default:
        return '';
    }
  }

  setDefaultValues(): void {
    switch (this.type) {
      case PrescriptionType.fertilizer:
        this.unit = PrescriptionUnit.kg;
        break;
      case PrescriptionType.seed:
        this.unit = PrescriptionUnit.kg;
        break;
      case PrescriptionType.plantprotection:
        this.unit = PrescriptionUnit.liter;
        break;
      case PrescriptionType.lime:
        this.unit = PrescriptionUnit.kg;
        break;
    }
  }

  /* export stuff ==================================================================== */
  openExportDialog(
    prescription: PrescriptionModel,
    nutritionalContent: number,
    functionPrescription?: boolean,
    exportOnly?: boolean,
    seedUnit?: string
  ): MatDialogRef<PrescriptionExportComponent, { saved: boolean } | null> {
    this.exportRef = this.dialog.open(PrescriptionExportComponent, {
      panelClass: 'dialog-no-padding',
      width: 'min-content',
      data: {
        prescription: prescription,
        nutritionalContent,
        unit: seedUnit ?? this.totalUnit,
        functionPrescription: !!functionPrescription,
        exportOnly: !!exportOnly,
      },
    });

    return this.exportRef;
  }

  closeExportDialog(): void {
    this.exportRef.close();
  }

  /* prescription basis stuff ======================================================== */

  private validateGridSettings(gridSettings: PrescriptionGridModel): boolean {
    if (!gridSettings) {
      return false;
    }
    if (!gridSettings.fc || gridSettings.fc.features?.length === 0) {
      return false;
    }
    if (
      !gridSettings.geoDataFileIds ||
      gridSettings.geoDataFileIds.length === 0
    ) {
      return false;
    }
    if (gridSettings.type === FILE_TYPE.Other) {
      return false;
    }
    if (gridSettings.type === FILE_TYPE.Prescription) {
      return false;
    }
    return true;
  }

  private validateRecalcSettings(prescription: PrescriptionModel): boolean {
    if (!prescription.fc || prescription.fc.features?.length === 0) {
      return false;
    }
    return true;
  }

  private generateEmptyGridSetting(): PrescriptionGridModel {
    return {
      fc: null,
      geoDataFileIds: [],
      type: null,
      property: null,
      prescription: this.EMPTY_PRESCRIPTION,
      functionId: 0,
      minRate: 0,
      maxRate: 0,
      targetYield: 0,
      cutOffPoint: 0,
    };
  }

  private generateEmptyPrescription(): PrescriptionModel {
    return {
      average: 0,
      total: 0,
      bucketCount: 5,
      gridSize: 30,
      gridRotation: this.defaultRotation,
      gridStartPoint: null,
      buckets: [
        this.generateEmptyBucket(0),
        this.generateEmptyBucket(1),
        this.generateEmptyBucket(2),
        this.generateEmptyBucket(3),
        this.generateEmptyBucket(4),
      ],
      fc: null,
      functionId: 0,
      maxRate: 0,
      minRate: 0,
      yield: 0,
      cutoffPoint: 0,
      content: 100,
    };
  }

  generateEmptyBucket(bucketIndex: number): PrescriptionBucketModel {
    return {
      breakpoint: bucketIndex,
      offset: null,
      color: '#ffffff',
      area: null,
      prescription: 0,
    };
  }
}
