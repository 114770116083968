<div *ngIf="(file && file.length > 0) || processing">
  <div class="container">
    <p *ngIf="!processing">
      {{
        progress !== null
          ? progress
          : ('File: {0} is being uploaded' | dvt: file)
      }}
    </p>
    <p *ngIf="processing">{{ 'Processing file' | dvt }}</p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>
