<ng-template #fileSetDialog>
  <div class="section">
    <mat-card appearance="outlined" class="dialogBox">
      <mat-card-header class="file-header">
        <mat-spinner
          class="file-icon-spinner"
          *ngIf="saving; else fileIcon"
          [diameter]="73"
        ></mat-spinner>
        <ng-template #fileIcon>
          <mat-icon class="file-icon" [inline]="true"> description </mat-icon>
        </ng-template>
        <div class="file-name" *ngFor="let set of fileSets">{{ set.key }}</div>
      </mat-card-header>
      <mat-card-actions class="file-action">
        <div class="button-section">
          <dvm-button (click)="saveFile()" [disabled]="!isLoggedIn">
            <mat-icon>save</mat-icon> {{ 'Save attached file' | dvt }}
          </dvm-button>
          <dvm-button (click)="downloadFile()" [disabled]="!isLoggedIn">
            <mat-icon>download</mat-icon> {{ 'Download' | dvt }}
          </dvm-button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</ng-template>

<div
  class="map-area"
  [class.narrow-layout]="isNarrowLayout"
  [class.wide-layout]="!isNarrowLayout"
>
  <dv-map [class.narrow-map-layout]="isNarrowLayout" [isMainMap]="true" #map>
    <dv-coordinate *ngIf="showMapCoordinates"></dv-coordinate>
    <dv-measure [map]="map"></dv-measure>
  </dv-map>
  <div
    style="display: flex; flex-direction: column"
    [class.narrow-sidebar-layout]="isNarrowLayout"
    [class.wide-sidebar-layout]="!isNarrowLayout"
    class="context-menu mat-elevation-z2 sidebar-share"
    *ngIf="!fileSets || fileSets.length === 0; else fileSetDialog"
  >
    <mat-card appearance="outlined" *ngIf="errorMessage" class="error-message">
      <mat-card-title>
        {{ errorMessage | dvt }}
      </mat-card-title>
    </mat-card>
    <mat-card appearance="outlined" *ngIf="comment">
      <mat-card-content style="white-space: pre-wrap">
        {{ comment }}
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" *ngIf="layerIds || geoDataIds">
      <div *ngIf="legend">
        <mat-card-title>
          {{ legend.title | dvt }}
        </mat-card-title>
        <mat-card-content>
          <dv-legend [legend]="legend" [loading]="!legend"></dv-legend>
        </mat-card-content>
      </div>
      <mat-card-action class="file-action">
        <dvm-button (click)="saveGeoData()" [disabled]="!isLoggedIn">
          <mat-icon>save</mat-icon> {{ 'Save GeoData' | dvt }}
        </dvm-button>
        <mat-spinner *ngIf="saving" [diameter]="30"></mat-spinner>
        <dvm-button [disabled]="!isLoggedIn" (click)="goToAnalyse()">
          <mat-icon>timeline</mat-icon> {{ 'Analyze' | dvt }}
        </dvm-button>
      </mat-card-action>
    </mat-card>
    <dv-login-prompt
      [class.narrow-login-prompt]="isNarrowLayout"
      *ngIf="!isLoggedIn && legend"
    ></dv-login-prompt>
  </div>
</div>
