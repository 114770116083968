<dv-dialog-content [loading]="isCreating" [title]="title" [closeBtn]="true">
<p style="text-align:center">
  {{'_newyear_copy_text1'|dvt:'_Table data from {0} will be copied to {1}.':data.from:data.to}}<br />
  {{'_newyear_copy_text2'|dvt:'_The parcels are copied but no bets are transferred between the years.'}}
</p>
<div style="padding-top: 10px; text-align: center;">
  <button (click)="copy()" mat-raised-button color="primary">
    <mat-icon>add_circle_outline</mat-icon> {{'Create year'|dvt}}
  </button>
</div>
</dv-dialog-content>