<dvm-radio-button-group
  class="list"
  [value]="selectedIndex"
  (change)="selectList($event.value)"
>
  <ng-container *ngFor="let list of wrappedLists; let i = index">
    <dv-nested-list-item
      *ngIf="
        list.parameterType === PARAMETER_TYPES.GEO_DATA;
        else itemComponent
      "
      [list]="list"
      [index]="i"
      [disabled]="disabled"
      (fileChange)="onFileChange(i)"
    >
      <dvm-radio-button
        size="regular"
        [value]="i"
        [disabled]="disabled"
        [checked]="i === selectedIndex"
        (click)="$event.stopPropagation()"
      >
        {{ list.functionParameter.name }}
      </dvm-radio-button>
    </dv-nested-list-item>
    <ng-template #itemComponent>
      <dv-nested-item>
        <dvm-radio-button
          size="regular"
          [value]="i"
          [disabled]="disabled"
          [checked]="i === selectedIndex"
          (click)="$event.stopPropagation()"
        >
          {{ list.functionParameter.name }}
        </dvm-radio-button>
      </dv-nested-item>
    </ng-template>
  </ng-container>
</dvm-radio-button-group>
