<div class="header">
  <div class="head">
    <button mat-button class="back-button" (click)="gotoInterpolationWizard()">
      <mat-icon class="material-icons">arrow_back</mat-icon>
    </button>
    <h3>{{ 'Create prescription file' | dvt }}</h3>
  </div>
  <mat-button-toggle-group
    *ngIf="prescriptionFunctions?.length > 0"
    class="type-select"
    (change)="setSelectedType($event.value)"
    aria-label="Types"
    [value]="prescrSrv.type"
  >
    <mat-button-toggle
      *ngFor="let type of types | keyvalue"
      [value]="type.key * 1"
    >
      {{ type.value }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
<div class="errmsg" *ngIf="prescError && prescError.length > 0">
  <mat-icon color="warn">error</mat-icon>
  <span class="dv-text-color status-text">{{ prescError }}</span>
</div>
<mat-card
  appearance="outlined"
  class="wizard"
  *ngIf="!prescError && gridSetting?.prescription"
>
  <div class="formula" *ngIf="prescriptionFunctions?.length > 0">
    <button
      [disabled]="availablePrescriptionFunctions()?.length === 0"
      class="formula-select"
      mat-button
      [matMenuTriggerFor]="menu"
    >
      {{ selectedFormula ? selectedFormula.functionName : ''
      }}<mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu class="formulas" #menu="matMenu">
      <button
        class="formulas-button"
        [class.active]="selectedFormula.id === MANUAL_METHOD.id"
        (click)="setSelectedFormula(MANUAL_METHOD)"
        mat-menu-item
      >
        {{ MANUAL_METHOD.functionName }}
      </button>
      <button
        class="formulas-button"
        [class.active]="selectedFormula.id === formula.id"
        *ngFor="let formula of availablePrescriptionFunctions()"
        (click)="setSelectedFormula(formula)"
        mat-menu-item
      >
        <span>{{ formula.functionName }}</span>
        <span>({{ formula.activeIngredient }})</span>
      </button>
      <div
        class="inactive-forms"
        *ngIf="inactivePrescriptionFunctions()?.length > 0"
      >
        <p>{{ 'Inactive functions' | dvt }}</p>
        <div
          class="row"
          *ngFor="let inactiveFormula of inactivePrescriptionFunctions()"
        >
          <span>{{ inactiveFormula.functionName }}</span>
          <span>({{ inactiveFormula.activeIngredient }})</span>
        </div>
      </div>
    </mat-menu>
    <div class="help-settings">
      <div class="help-area">
        <button class="icon help" (click)="toggleHelp()">
          <img src="assets/images/Questionmark.svg" />
        </button>
        <div class="help-container" *ngIf="showHelp">
          <button (click)="toggleHelp()"><mat-icon>close</mat-icon></button>
          <div class="container">
            <p class="title">{{ 'How to use the function' | dvt }}</p>
            <p class="content" p>
              {{
                '_formula-help-content'
                  | dvt
                    : '_Nu kan du se variationerna i dina fält. Välj en stategi nedan för de valda fälten. Siffrorna nedan visar vegetationsindexet för fem olika intervall. Skriv önskad kvävegiva i kg/ha för respektive intervall'
              }}
            </p>
          </div>
        </div>
      </div>
      <button mat-button class="icon" (click)="toggleSettings()">
        <mat-icon [class.hidden]="showSettings" class="material-icons">
          settings
        </mat-icon>
        <mat-icon [class.hidden]="!showSettings" class="material-icons">
          cancel
        </mat-icon>
      </button>
    </div>
  </div>
  <div
    class="manual-options"
    *ngIf="!prescriptionFunctions || prescriptionFunctions?.length === 0"
  >
    <button mat-button class="icon" (click)="toggleSettings()">
      <mat-icon [class.hidden]="showSettings" class="material-icons">
        settings
      </mat-icon>
      <mat-icon [class.hidden]="!showSettings" class="material-icons">
        cancel
      </mat-icon>
    </button>
  </div>
  <mat-card-title
    class="sub-header"
    *ngIf="
      gridSetting != null &&
      gridSetting?.type != '' &&
      gridSetting?.property != ''
    "
  >
    <mat-card
      appearance="outlined"
      class="setting-content submenu-z"
      *ngIf="showSettings"
    >
      <mat-card-content class="setting-properties">
        <div *ngIf="!selectedFormula || selectedFormula.id === 0">
          <span class="label">{{
            'Interval count' | dvt : '_Intervalls'
          }}</span>
          <mat-form-field appearance="outline">
            <mat-select
              (selectionChange)="updateBucketCount($event)"
              [(value)]="bucketCount"
            >
              <mat-option [value]="3">3</mat-option>
              <mat-option [value]="4">4</mat-option>
              <mat-option [value]="5">5</mat-option>
              <mat-option [value]="6">6</mat-option>
              <mat-option [value]="7">7</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <span class="label">{{ 'Cell size' | dvt }} (m)</span>
          <mat-form-field appearance="outline">
            <mat-select
              (selectionChange)="updateGridSize($event)"
              [(value)]="gridSetting.prescription.gridSize"
            >
              <mat-option [value]="10">10 x 10</mat-option>
              <mat-option [value]="20">20 x 20</mat-option>
              <mat-option [value]="30">30 x 30</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <span class="label">{{ 'Unit' | dvt }}</span>
          <mat-form-field appearance="outline">
            <mat-select
              (selectionChange)="updateUnit($event)"
              [(value)]="prescrSrv.unit"
            >
              <mat-option
                *ngFor="let unit of units | keyvalue"
                [value]="unit.key * 1"
              >
                {{ unit.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <span class="label">{{ 'Grid rotation' | dvt }} (°)</span>
          <!-- TODO: The 'tickInterval' property no longer exists -->
          <mat-slider thumbLabel min="-45" max="45" step="1" #ngSlider
            ><input
              matSliderThumb
              [value]="gridSetting.prescription.gridRotation"
              (change)="
                updateGridRotation({
                  source: ngSliderThumb,
                  parent: ngSlider,
                  value: ngSliderThumb.value
                })
              "
              #ngSliderThumb="matSliderThumb"
          /></mat-slider>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-card-title>
  <mat-card-content [class.grid-auto]="selectedFormula.id > 0">
    <dv-prescription-legend
      *ngIf="gridSetting.prescription?.buckets?.length > 0"
      [prescription]="gridSetting.prescription"
    ></dv-prescription-legend>
    <dv-prescription-buckets
      *ngIf="
        gridSetting.prescription?.buckets?.length > 0 &&
        (!selectedFormula || selectedFormula.id === 0)
      "
      [prescription]="gridSetting.prescription"
      [unit]="prescrSrv.unitAsText()"
      [disabled]="prescrSrv.waitForGrid"
      (focusout)="unFocusInput()"
      (prescriptionChange)="startUpdatePrescriptionTimer()"
    ></dv-prescription-buckets>
    <div class="toggle-draw">
      <button
        class="mark-deviations"
        *ngIf="deviations.length === 0"
        mat-stroked-button
        (click)="addDeviation()"
      >
        <mat-icon>edit</mat-icon>{{ 'Mark deviations' | dvt }}
      </button>
      <div class="deviations" *ngIf="deviations.length > 0">
        <div
          class="deviation-container"
          *ngFor="let deviation of deviations; let i = index"
        >
          <button
            mat-icon-button
            class="close"
            (click)="deleteDeviation(deviation)"
            [class.selected]="i === activeDeviation"
          >
            <mat-icon>close</mat-icon>
          </button>
          <div
            class="deviation value"
            [class.selected]="i === activeDeviation"
            (click)="setActiveDeviation(i)"
          >
            <div [ngStyle]="{ 'background-color': deviation.color }">
              &nbsp;
            </div>
            <input
              (ngModelChange)="updateDeviationFeatureValues(deviation)"
              [(ngModel)]="deviation.deviationRate"
              type="number"
            />
          </div>
        </div>
        <button mat-icon-button class="deviation add" (click)="addDeviation()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
    <div class="form-meta" *ngIf="!selectedFormula || selectedFormula.id === 0">
      <span class="center">
        {{ 'Average rate ({0})' | dvt : prescrSrv.unitAsText() }}:
        <span class="rate">{{ gridSetting.prescription.average }}</span>
      </span>
      <div class="form-grid">
        <span
          class="label"
          *ngIf="!prescriptionFunctions || prescriptionFunctions.length === 0"
        >
          {{ 'Prescription type' | dvt }}
        </span>
        <mat-form-field
          appearance="outline"
          *ngIf="!prescriptionFunctions || prescriptionFunctions.length === 0"
        >
          <mat-select
            (selectionChange)="updatePrescriptionType($event)"
            [(value)]="prescrSrv.type"
          >
            <mat-option
              [value]="type.key * 1"
              *ngFor="let type of types | keyvalue"
            >
              {{ type.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span class="label">{{ 'Nutritional content' | dvt }} (%)</span>
        <mat-form-field appearance="outline">
          <input
            matInput
            type="number"
            min="0"
            max="100"
            pattern="^0?[0-9]?[0-9]$|^(100)$"
            [(ngModel)]="nutritionalContent"
          />
        </mat-form-field>
        <span class="label">
          {{ 'Total prescription' | dvt }} ({{ prescrSrv.getTotalUnit() }})
        </span>
        <mat-form-field appearance="outline">
          <input
            matInput
            disabled="true"
            type="number"
            min="0"
            [value]="getTotal()"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="form-meta" *ngIf="selectedFormula && selectedFormula.id !== 0">
      <span class="center">
        {{ 'Average rate ({0})' | dvt : prescrSrv.unitAsText() }}:
        <span class="rate">{{ gridSetting.prescription.average }}</span>
      </span>
      <div class="form-grid">
        <span class="label" *ngIf="selectedFormula.yield">
          {{ 'Target harvest ({0})' | dvt : prescrSrv.unitAsText() }}
        </span>
        <mat-form-field appearance="outline" *ngIf="selectedFormula.yield">
          <input
            [disabled]="loading"
            (focusout)="unFocusInput()"
            [(ngModel)]="gridSetting.prescription.targetYield"
            min="0"
            matInput
            type="number"
            pattern="^0?[0-9]?[0-9]$"
            (ngModelChange)="startUpdatePrescriptionTimer()"
          />
        </mat-form-field>
        <span class="label">{{ 'Nutritional content' | dvt }} %</span>
        <mat-form-field appearance="outline">
          <input
            [disabled]="loading"
            (focusout)="unFocusInput()"
            [(ngModel)]="nutritionalContent"
            matInput
            type="number"
            min="0"
            max="100"
            pattern="^0?[0-9]?[0-9]$|^(100)$"
          />
        </mat-form-field>
        <span *ngIf="selectedFormula.maxRate" class="label">
          {{ 'Max rate' | dvt }}
        </span>
        <mat-form-field *ngIf="selectedFormula.maxRate" appearance="outline">
          <input
            [disabled]="loading"
            (focusout)="unFocusInput()"
            [(ngModel)]="gridSetting.prescription.maxRate"
            matInput
            pattern="^0?[0-9]?[0-9]$"
            (ngModelChange)="startUpdatePrescriptionTimer()"
          />
        </mat-form-field>
        <span class="label" *ngIf="selectedFormula.minRate">
          {{ 'Min rate' | dvt }}
        </span>
        <mat-form-field *ngIf="selectedFormula.minRate" appearance="outline">
          <input
            [disabled]="loading"
            (focusout)="unFocusInput()"
            [(ngModel)]="gridSetting.prescription.minRate"
            matInput
            pattern="^0?[0-9]?[0-9]$"
            (ngModelChange)="startUpdatePrescriptionTimer()"
          />
        </mat-form-field>
        <span class="label" *ngIf="selectedFormula.cutoffPoint">
          {{ 'Cut off point ({0})' | dvt : prescrSrv.unitAsText() }}
        </span>
        <mat-form-field
          *ngIf="selectedFormula.cutoffPoint"
          appearance="outline"
        >
          <input
            [disabled]="loading"
            (focusout)="unFocusInput()"
            [(ngModel)]="gridSetting.prescription.cutOffPoint"
            matInput
            type="number"
            min="0"
            pattern="^0?[0-9]?[0-9]$"
            (ngModelChange)="startUpdatePrescriptionTimer()"
          />
        </mat-form-field>
        <span class="label">
          {{ 'Total ({0})' | dvt : prescrSrv.getTotalUnit() }}
        </span>
        <mat-form-field appearance="outline">
          <input
            disabled
            matInput
            [value]="getTotal()"
            [readonly]="true"
            value="0"
            type="number"
            min="0"
            pattern="^0?[0-9]?[0-9]$"
          />
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>
  <div class="file-row">
    <mat-expansion-panel
      *ngIf="basisInterpolation && basisInterpolation.interpolation"
      class="expansion"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="expansion-row space-between expansion">
          <span class="expansion-title">
            {{ '_data_filetype_' + basisInterpolation.type | dvt }} ({{
              '_propertyname_' + basisInterpolation.property
                | dvt : '_' + basisInterpolation.property
            }})
          </span>
          <span
            *ngIf="
              basisInterpolation.selectedData?.length > 1;
              else single_file
            "
            class="date"
          >
            ({{
              basisInterpolation.selectedData.length +
                ' ' +
                ('of {0} Files' | dvt : selectedData.length)
            }})
          </span>
          <ng-template #single_file>
            ({{
              basisInterpolation.selectedData[0].createdDate
                | date : 'yy-MM-dd'
            }})
          </ng-template>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansion-content">
        <div class="expansion-row">
          <button
            class="toggle-visibility"
            matTooltipPosition="above"
            matTooltip="Correct tooltip text here"
            [class.active]="showBasisInterpolation"
            mat-button
            (click)="toggleShownInterpolation()"
          >
            <mat-icon>visibility</mat-icon>
          </button>
          <mat-form-field class="file-select" appearance="outline">
            <mat-select
              [(value)]="activeFiles"
              (selectionChange)="updateSelectedGeodata($event)"
              multiple
            >
              <mat-option
                [value]="data"
                *ngFor="let data of basisInterpolation.selectedData"
              >
                {{ data.name }}
                {{ data.createdDate | date : 'shortDate' }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <dv-basis-legend
          [legend]="basisInterpolation?.interpolation.legend"
          [loading]="!basisInterpolation?.interpolation"
          [pointMode]="false"
        ></dv-basis-legend>
      </div>
    </mat-expansion-panel>
  </div>
  <mat-card-actions class="next-button-area">
    <button
      class="next-button"
      mat-raised-button
      color="primary"
      (click)="export()"
      [disabled]="loading && (prescError?.length === 0 || !prescError)"
    >
      {{ 'Next' | dvt }}
    </button>
  </mat-card-actions>
</mat-card>
