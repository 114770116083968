import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FileStatus } from 'app/models/filestatus.model';

@Component({
  selector: 'dv-file-status',
  templateUrl: './file-status.component.html',
  styleUrls: ['./file-status.component.scss'],
})
export class FileStatusComponent implements OnChanges {
  @Input() status: FileStatus;
  progress = 100;
  isError = false;
  isWarning = false;
  isImportProgress = false;
  isImportStatus = false;

  get isErrorOrWarning(): boolean {
    return this.isError || this.isWarning;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status.currentValue !== changes.status.previousValue) {
      this.updateProgress();
    }
  }

  updateProgress(): void {
    switch (this.status) {
      case FileStatus.Saved:
        this.clearAll();
        this.isImportStatus = true;
        break;
      case FileStatus.InQueue:
        this.setImportProgress(20);
        break;
      case FileStatus.InProcess:
        this.setImportProgress(70);
        break;
      case FileStatus.Succeeded:
        this.clearAll();
        break;
      case FileStatus.Error:
        this.setImportStatus(true);
        break;
      case FileStatus.Failed:
      case FileStatus.Outdated:
        this.setImportStatus(false);
        break;

      default:
        this.clearAll();
    }
  }

  private setImportProgress(progress: number): void {
    this.progress = progress;
    this.isImportProgress = true;
    this.isImportStatus = false;
    this.isError = false;
    this.isWarning = false;
  }

  private clearAll(): void {
    this.progress = 100;
    this.isImportProgress = false;
    this.isImportStatus = false;
    this.isError = false;
    this.isWarning = false;
  }

  setImportStatus(isError: boolean): void {
    this.isError = isError;
    this.isWarning = !isError;
    this.isImportProgress = false;
    this.isImportStatus = true;
  }
}
