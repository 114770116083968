import { Component, Input } from '@angular/core';

@Component({
  selector: 'dv-prescription-button',
  templateUrl: 'prescription-button.component.html',
  styleUrls: ['prescription-button.component.scss'],
})
export class PrescriptionButtonComponent {
  @Input() disabled = true;
  @Input() imgSrc = '';
  @Input() text = '';
}
