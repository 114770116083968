import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { META_DATA } from 'app/components/data/fileset/fileset-list-detail/fileset-list-detail.types';
import { FileDefinition, WrappedFile } from './file-preview-list.types';
import {
  DvmCheckboxModule,
  DvmRadioButtonGroupModule,
  DvmRadioButtonModule,
  DvmRadioChange,
} from '@dvm/components';
import { DatePipe, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'dv-file-preview-list',
  templateUrl: 'file-preview-list.component.html',
  styleUrls: ['file-preview-list.component.scss'],
  standalone: true,
  imports: [
    DatePipe,
    DvmCheckboxModule,
    DvmRadioButtonGroupModule,
    DvmRadioButtonModule,
    NgFor,
    NgIf,
  ],
})
export class FilePreviewListComponent implements OnChanges {
  @Output() fileChange = new EventEmitter<number[]>();
  @Input() files: FileDefinition[];
  @Input() selectedFiles: number[] = [];
  @Input() multiSelect: boolean;
  @Input() disabled: boolean;

  wrappedFiles: WrappedFile[] = [];
  private selectedFileIds = new Set<number>();

  get singleSelectFileId(): number {
    return Array.from(this.selectedFileIds)[0];
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const files: FileDefinition[] = changes.files?.currentValue;
    const selectedFiles: number[] = changes.selectedFiles?.currentValue;
    if (files) {
      this.setCheckedValue();
    }

    if (!this.disabled && selectedFiles) {
      this.selectedFileIds.clear();
      selectedFiles.forEach((file) => this.selectedFileIds.add(file));
      this.setCheckedValue();
    }
  }

  private setCheckedValue(): void {
    this.wrappedFiles = this.files.map((file) => ({
      file,
      checked: this.selectedFileIds.has(file.fileId),
      createdDate: file.metadata.find((data) => data.key === META_DATA.CREATED)
        ?.value,
    }));
    this.cd.markForCheck();
  }

  onChange(wrappedFile: WrappedFile, fileId: number): void {
    wrappedFile.checked = !wrappedFile.checked;

    if (wrappedFile.checked) {
      this.selectedFileIds.add(fileId);
    } else {
      this.selectedFileIds.delete(fileId);
    }

    this.fileChange.emit(Array.from(this.selectedFileIds));
  }

  onSingleSelectChange(change: DvmRadioChange): void {
    this.selectedFileIds.clear();
    this.selectedFileIds.add(change.value);

    this.fileChange.emit(Array.from(this.selectedFileIds));
  }
}
