import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  BrukningsenhetModel,
  SkifteDetielsModel,
  TabellUtsadeModel,
} from 'app/models/api.models';
import {
  LAYER_NAME_INTERPOLATION,
  LAYER_NAME_SKIFTEN,
  LAYER_SELECTED,
  MapService,
} from 'app/components/map/map.service';
import { AddCropComponent } from 'app/field-list-page/add-crop/add-crop.component';
import { ClientService } from 'app/services/client.service';
import { MapStateService } from 'app/services/map-state.service';
import { SiteService } from 'app/services/site.service';
import { first, takeUntil, tap } from 'rxjs/operators';
import { MappingWizardService } from '../../../mapping-wizard.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { TableExpandedRowComponent } from '../../table-expanded-row/table-expanded-row.component';
import { compare } from '../table-utils';
import { MapComponent } from 'app/components/map/map.component';
import { TableFilterOptions } from '../../wizard-table.types';
import { Feature } from 'geojson';
import { Subject } from 'rxjs';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-table-skifte',
  templateUrl: './table-skifte.component.html',
  styleUrls: ['./table-skifte.component.scss', '../table-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSkifteComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() skiften: SkifteDetielsModel[] = [];
  @Input() filters: TableFilterOptions;
  @Input() search = '';
  @Input() loading = false;
  @Output() addFieldCalled = new EventEmitter<void>(null);
  @Output() fieldRemoved = new EventEmitter<number>(null);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('cropSelect') cropSelect: MatSelect;
  @ViewChildren('tableRow', { read: ViewContainerRef }) rowContainers;

  matDataSource = new MatTableDataSource<SkifteDetielsModel>();
  displayedColumns: string[] = [
    'select',
    'name',
    'crop',
    'areal',
    'farm',
    'forfrukt',
    'lastmodified',
    'edit',
  ];
  map: MapComponent;
  editingField = null;
  editingRow: SkifteDetielsModel = null;
  expandedRow = -1;
  randomString = '!"#¤%&/()=?^*_:;>';
  tabellUtsaden: TabellUtsadeModel[] = [];
  brukningsenhter: BrukningsenhetModel[] = [];
  grodor = [];
  availableYears: number[] = [];
  selectedYear: number;
  loadingField = false;
  allFieldsSelected = false;
  // selection is used as both SelectionModel<Feature> and a
  // SelectionModel<SkifteDetielsModel>. As for now type assertion
  // (using keyword as) is used to avoid errors, but needs to be looked
  // into. A task is created for this, #9959
  selection = new SelectionModel<Feature>(true, []);
  selectedFeatures: GeoJSON.FeatureCollection;
  fieldBoundaries: GeoJSON.FeatureCollection;
  sortObj: Sort = null;
  editFields = false;
  activeFieldIds: number[] = [];
  filterTimeout: ReturnType<typeof setTimeout>;
  viewInited = false;
  private addedTabellutsade: TabellUtsadeModel;
  private _unsub$: Subject<void> = new Subject<void>();

  constructor(
    private clientSrv: ClientService,
    private dialog: MatDialog,
    private resolver: ComponentFactoryResolver,
    private wizSrv: MappingWizardService,
    private mapStateService: MapStateService,
    private changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe,
    private siteService: SiteService,
    private snackBar: MatSnackBar,
    private translate: DvToolbarTranslateService,
    private mapService: MapService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInited = true;
      this.changeDetectorRef.detectChanges();
    }, 500);
  }

  ngOnInit(): void {
    this.mapService
      .mainMap()
      .pipe(takeUntil(this._unsub$))
      .subscribe((map) => {
        this.map = map;
        this.syncMapStateToMapFields();
      });

    this.matDataSource.filterPredicate = (
      data: SkifteDetielsModel,
      filter: string
    ): boolean => {
      const search =
        this.randomString === filter ? this.search.toLowerCase() : filter;

      if (
        this.filters.crops.length > 0 &&
        !(
          data.huvudgroda &&
          this.filters.crops.includes(data.huvudgroda.benamning)
        )
      ) {
        return false;
      }

      if (
        this.filters.farms.length > 0 &&
        !(data.brukEnhNamn && this.filters.farms.includes(data.brukEnhNamn))
      ) {
        return false;
      }

      return search.length === 0
        ? true
        : (data.brukEnhNamn &&
            data.brukEnhNamn.toLowerCase().includes(search)) ||
            (data.huvudgroda &&
              data.huvudgroda.benamning.toLowerCase().includes(search)) ||
            (data.namn && data.namn.toLowerCase().includes(search)) ||
            (data['name'] && data['name'].toLowerCase().includes(search)) ||
            (data['category'] &&
              data['category'].toLowerCase().includes(search));
    };

    this.clientSrv
      .getSkifteLayer()
      .pipe(takeUntil(this._unsub$))
      .subscribe((fieldBoundaries) => {
        if (this.map) {
          this.map.removeLayer(LAYER_NAME_SKIFTEN);
        }
        this.fieldBoundaries = fieldBoundaries;
        this.syncMapStateToMapFields();
      });

    this.mapStateService
      .getSelectedFeatures()
      .pipe(takeUntil(this._unsub$))
      .subscribe((mapStateFields) => {
        this.selectedFeatures = mapStateFields;
        this.syncMapSelectionToListSelection();
        this.syncMapStateToMapFields();
        this.triggerSort();
      });

    this.clientSrv
      .getBrukningsenheter()
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        this.brukningsenhter = res;
      });

    this.clientSrv.tabellUtsade$
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        this.tabellUtsaden = res;
        if (this.addedTabellutsade) {
          this.editingField.huvudgroda = res.find(
            (t) =>
              t.groda === this.addedTabellutsade.groda &&
              t.sort === this.addedTabellutsade.sort
          );
          this.addedTabellutsade = null;
          this.changeDetectorRef.detectChanges();
        }
      });
    this.clientSrv
      .client()
      .pipe(takeUntil(this._unsub$))
      .subscribe((client) => {
        this.availableYears = client.tillgangligaAr.filter((ar) => ar > 0);
        if (this.availableYears.length === 0) {
          this.availableYears.push(new Date().getFullYear());
        }
      });

    this.clientSrv
      .clientAr()
      .pipe(takeUntil(this._unsub$))
      .subscribe((ar) => {
        this.selectedYear = ar;
      });

    this.siteService
      .hasOdling()
      .pipe(takeUntil(this._unsub$))
      .subscribe((hasOdling) => {
        this.editFields = !hasOdling;
        this.changeDetectorRef.markForCheck();
      });

    //After sort?
    this.matDataSource.sort = this.sort;
    this.matDataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading) {
      this.loading = changes.loading.currentValue;
      this.changeDetectorRef.detectChanges();
    }
    if (changes.skiften) {
      if (
        this.selectedFeatures &&
        changes.skiften.currentValue &&
        changes.skiften.currentValue.length > 0
      ) {
        this.selectedFeatures.features.forEach((f) => {
          if (!this.selection.isSelected(f)) {
            this.selection.select(
              changes.skiften.currentValue.find((feat) => f.id === feat.id)
            );
          }
        });
      }
      this.matDataSource.data = changes.skiften.currentValue;
      this.changeDetectorRef.detectChanges();
    }
    if (changes.map) {
      this.map = changes.map.currentValue;
    }
    if (changes.filters) {
      this.filters = changes.filters.currentValue;
      this.matDataSource.filter = this.randomString;
      this.clearFilteredSkiften();
    }
    if (changes.search) {
      this.search = changes.search.currentValue;
      this.matDataSource.filter = this.randomString;
      this.clearFilteredSkiften();
    }
    if (!this.matDataSource.sort && this.sort) {
      this.matDataSource.sort = this.sort;
      this.sort.sortChange.pipe(takeUntil(this._unsub$)).subscribe((event) => {
        setTimeout(() => {
          this.sortObj = event;
        }, 0);
      });
    }
  }

  /* Remove selected status for unfilteredValues */
  private clearFilteredSkiften(): void {
    if (!this.map) {
      return;
    }
    const selectedF: google.maps.Data.Feature[] =
      this.map.getSelectedFeatures(LAYER_NAME_SKIFTEN);

    this.matDataSource.data.forEach((data: SkifteDetielsModel) => {
      if (selectedF.find((f) => f.getProperty('skifteId') === data.id)) {
        if (
          !this.matDataSource.filteredData.find(
            (d: SkifteDetielsModel) => d.id === data.id
          )
        ) {
          this.map.selectedFeature(data.id);
        }
      }
    });
  }

  insertComponent(skifte: SkifteDetielsModel, index: number): void {
    this.clearSelected();

    if (this.expandedRow === index) {
      this.expandedRow = -1;
    } else {
      const container = this.rowContainers
        .toArray()
        .find((el) => el.element.nativeElement.id === 'row-' + index);
      const factory: ComponentFactory<TableExpandedRowComponent> =
        this.resolver.resolveComponentFactory(TableExpandedRowComponent);
      const inlineComponent = container.createComponent(factory);

      inlineComponent.instance.skifte = skifte;
      inlineComponent.instance.loading = this.loadingField;
      inlineComponent.instance.onClear
        .pipe(takeUntil(this._unsub$))
        .subscribe(() => {
          this.setEdit(null, this.expandedRow);
        });

      inlineComponent.instance.onDelete
        .pipe(takeUntil(this._unsub$))
        .subscribe(() => {
          const dialogRef = this.dialog.open(DialogComponent);

          dialogRef.componentInstance.onDelete
            .pipe(takeUntil(this._unsub$))
            .subscribe(() => {
              this.deleteSkifte(this.editingRow, inlineComponent.instance);
            });
        });

      inlineComponent.instance.onEditBorders
        .pipe(takeUntil(this._unsub$))
        .subscribe(() => {
          this.wizSrv.setEditBordersField(this.editingRow);
        });

      inlineComponent.instance.onSave
        .pipe(takeUntil(this._unsub$))
        .subscribe(() => {
          this.saveField(
            this.editingField,
            this.editingRow,
            inlineComponent.instance
          );
        });

      this.expandedRow = index;
    }
  }

  clearSelected(): void {
    if (this.expandedRow !== -1) {
      const identifier = 'row';
      this.rowContainers
        .toArray()
        .find(
          (el) =>
            el.element.nativeElement.id === identifier + '-' + this.expandedRow
        )
        .clear();
    }
  }

  deleteSkifte(skifte: SkifteDetielsModel, instance): void {
    instance.loading = true;
    this.loadingField = true;
    this.changeDetectorRef.detectChanges();

    this.clientSrv
      .deleteSkifte(skifte.id)
      .pipe(first())
      .subscribe(
        () => {
          this.matDataSource.data = this.matDataSource.data.filter(
            (row) => row['id'] !== skifte.id
          );
          this.loadingField = false;
          this.map.removeLayer(LAYER_NAME_SKIFTEN);
          this.fieldRemoved.emit(skifte.id);
          this.clearSelected();
          this.expandedRow = -1;
        },
        () => {
          instance.loading = false;
        }
      );
  }

  setEdit(row: SkifteDetielsModel, index: number): void {
    if (this.expandedRow === index) {
      this.clearSelected();
      this.expandedRow = -1;
    } else {
      this.editingField = { ...row };
      this.editingRow = row;
      if (this.editingField.huvudgroda) {
        this.editingField.huvudgroda = this.tabellUtsaden.find(
          (tb) => tb.id === this.editingField.huvudgroda['utsadeId']
        );
      }
      this.editingField['bruk'] = this.brukningsenhter.find(
        (be) => be.id === this.editingField.brukEnhId
      );
      this.insertComponent(row, index);
    }
  }

  saveField(
    newField: SkifteDetielsModel,
    oldField: SkifteDetielsModel,
    instance
  ): void {
    instance.loading = true;
    if (
      (newField.huvudgroda && !oldField.huvudgroda) ||
      oldField.huvudgroda?.utsadeId !== newField.huvudgroda?.id
    ) {
      this.clientSrv
        .changeHuvudgroda(oldField, newField.huvudgroda.id)
        .pipe(first())
        .subscribe((res) => {
          oldField.huvudgroda = res;
          oldField.huvudgrodaBenamning = res.benamning;
          oldField.huvudgrodaId = res.id;
          oldField.brukEnhId = newField.brukEnhId;
          oldField.brukEnhNamn = newField.brukEnhNamn;
          oldField.namn = newField.namn;
          this.clientSrv
            .saveSkifte(oldField)
            .pipe(first())
            .subscribe(
              () => {
                this.clientSrv.loadSkifteLayer();
                this.clearSelected();
                this.expandedRow = -1;
                this.editingRow = null;
                this.loadingField = false;
              },
              () => {
                instance.loading = false;
              }
            );
        });
    } else {
      oldField.brukEnhId = newField.brukEnhId;
      oldField.brukEnhNamn = newField.brukEnhNamn;
      oldField.namn = newField.namn;
      this.clientSrv
        .saveSkifte(oldField)
        .pipe(first())
        .subscribe(
          () => {
            this.clientSrv.loadSkifteLayer();
            this.clearSelected();
            this.expandedRow = -1;
            this.editingRow = null;
            this.loadingField = false;
          },
          () => {
            instance.loading = false;
          }
        );
    }
  }

  sortData(sort: Sort): number {
    this.clearSelected();
    this.expandedRow = -1;
    const data = this.matDataSource.data;
    if (!sort || !sort.active || sort.direction === '') {
      this.matDataSource.data = this.sortSelectedFirst(this.skiften);
      this.changeDetectorRef.detectChanges();
      return;
    }

    this.matDataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      if (
        this.selection.isSelected(a as unknown as Feature) !==
        this.selection.isSelected(b as unknown as Feature)
      ) {
        return this.selection.isSelected(a as unknown as Feature) ? -1 : 1;
      }

      switch (sort.active) {
        case 'name':
          return compare(a['namn'], b['namn'], isAsc);
        case 'crop':
          return compare(
            a['huvudgroda'] ? a['huvudgroda'].benamning : '',
            b['huvudgroda'] ? b['huvudgroda'].benamning : '',
            isAsc
          );
        case 'areal':
          return compare(
            parseFloat(a['areal'].toFixed(2)),
            parseFloat(b['areal'].toFixed(2)),
            isAsc
          );
        case 'farm':
          return compare(a['brukenhNamn'], b['brukenhNamn'], isAsc);
        case 'forfrukt':
          return compare(
            a['forfrukt'] ? a['forfrukt'] : '',
            b['forfrukt'] ? b['forfrukt'] : '',
            isAsc
          );
        case 'lastmodified':
          return compare(a['andradTid'], b['andradTid'], !isAsc);
        default:
          return 0;
      }
    });
  }

  selectionChanged(row): void {
    this.map.selectedFeature(row['id']);
    this.triggerSort();
  }

  triggerSort(): void {
    if (!this.sort) {
      return;
    }
    setTimeout(() => {
      this.sort.sort({ id: null, start: null, disableClear: false });
      this.sort.sort({
        id: this.sortObj?.active ? this.sortObj.active : '',
        start: this.sortObj?.direction ? this.sortObj.direction : null,
        disableClear: false,
      });

      this.allFieldsSelected =
        this.selection.selected.length === this.skiften.length;
      this.changeDetectorRef.detectChanges();
    }, 0);
  }

  toggleAll(): void {
    if (this.selection?.selected?.length > 0) {
      this.selection.clear();
      this.mapStateService.clearSelectedFeatures();
    } else {
      if (
        this.matDataSource.data.length ===
        this.matDataSource.filteredData.length
      ) {
        this.map.selectAllFeatures();
      } else {
        this.matDataSource.filteredData.forEach(
          (skifte: SkifteDetielsModel) => {
            this.map.selectedFeature(skifte.id);
          }
        );
      }
    }
    this.triggerSort();
  }

  toggleAddField(): void {
    this.addFieldCalled.emit();
  }

  private sortSelectedFirst(
    skiften: SkifteDetielsModel[]
  ): SkifteDetielsModel[] {
    return skiften.sort((a, b) => {
      if (
        this.selection.selected.filter((s) => s.id === a.id || s.id === b.id)
          .length === 1
      ) {
        return this.selection.selected.find((s) => s.id === a.id) ? -1 : 1;
      }
    });
  }

  getDateString(unixTime: number): string {
    if (!unixTime || unixTime < 1) {
      return '-';
    }

    try {
      return this.datePipe.transform(new Date(unixTime * 1000), 'shortDate');
    } catch (err) {
      return '-';
    }
  }

  addCrop(): void {
    this.cropSelect.close();
    this.dialog
      .open(AddCropComponent, { data: this.tabellUtsaden })
      .afterClosed()
      .pipe(
        tap((tabellUtsade) => {
          if (tabellUtsade) {
            this.loadSeeds();
          }
        })
      )
      .subscribe((tabellUtsade) => {
        this.addedTabellutsade = tabellUtsade;
        if (tabellUtsade) {
          this.snackBar.open(this.translate.t('Crop(s) added'), null, {
            duration: 3000,
          });
        }
      });
  }

  private loadSeeds(): void {
    this.clientSrv.loadTabellUtsade().pipe(takeUntil(this._unsub$)).subscribe();
  }

  private addSkiftenToMap(
    map: MapComponent,
    fields: GeoJSON.FeatureCollection,
    selectedFieldIds: number[]
  ): void {
    map.addGeoJson(fields, LAYER_NAME_SKIFTEN).forEach((f) => {
      f.setProperty('fillOpacity', 0.8);
      f.setProperty(
        LAYER_SELECTED,
        selectedFieldIds?.includes(<number>f.getId())
      );
    });
  }

  compareCrops(c1: TabellUtsadeModel, c2: TabellUtsadeModel): boolean {
    return c1 && c2 ? c1.groda === c2.groda && c1.sort === c2.sort : c1 === c2;
  }

  setBrukenhet(event): void {
    this.editingField.bruk = event.value;
    this.editingField.brukEnhId = event.value.id;
    this.editingField.brukEnhNamn = event.value.namn;
  }

  private syncMapSelectionToListSelection(): void {
    if (this.selectedFeatures?.features?.length) {
      this.activeFieldIds = this.selectedFeatures.features.map(
        (sel) => <number>sel.id
      );
      this.selectedFeatures.features.forEach((f) => {
        if (!this.selection.isSelected(f)) {
          this.selection.select(
            this.skiften.find(
              (feat) => <number>f.id === <number>feat.id
            ) as unknown as Feature
          );
        }
      });
      this.selection.selected.forEach((sel) => {
        if (!this.selectedFeatures.features.find((f) => f.id === sel?.id)) {
          this.selection.deselect(sel);
        }
      });
    }

    if (!this.selectedFeatures?.features?.length) {
      this.selection.clear();
      this.activeFieldIds = [];
    }

    this.changeDetectorRef.detectChanges();
  }

  private syncMapStateToMapFields(): void {
    if (this.fieldBoundaries && this.map) {
      this.map.removeLayer(LAYER_NAME_INTERPOLATION);
      this.addSkiftenToMap(
        this.map,
        this.fieldBoundaries,
        this.selectedFeatures?.features?.map((feature) => <number>feature.id)
      );
      this.map.fitFeature(this.map.getSelectedFeatures(LAYER_NAME_SKIFTEN));

      this.changeDetectorRef.detectChanges();
    }
  }
}
