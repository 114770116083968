import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GeoDataFileMetaTags } from 'app/models/geodata.model';
import { RawFileSetModel } from 'app/models/models';
import { DataFileService, FILE_FORMAT } from 'app/services/data-file.service';
import { DataFilterService } from 'app/services/data-filter.service';
import { DialogService } from '../../../dialog/dialog.service';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
  takeWhile,
} from 'rxjs/operators';
import { COLUMN_KEYS } from '../../data-table.types';
import { expandableRowAnimation } from '../../expandable-row.animation';
import { ShareDialogResult } from './fileset-list-share-dialog/fileset-list-share-dialog-types';
import { DataSharePayload, ShareService } from 'app/services/share.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileSetListShareDialogComponent } from './fileset-list-share-dialog/fileset-list-share-dialog.component';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';
import { ExtendedFileSetModel } from 'app/components/data/fileset/fileset-list/fileset-list.types';

@Component({
  selector: 'dv-file-set-list',
  templateUrl: './fileset-list.component.html',
  styleUrls: ['./fileset-list.component.scss', '../../data-table.scss'],
  animations: [expandableRowAnimation],
})
export class FileSetListComponent
  implements AfterContentInit, AfterViewInit, OnDestroy
{
  private _unsub$: Subject<void> = new Subject<void>();

  @Input() set selected(value: number) {
    const idx = this.filesetDataSource.filteredData.find(
      (d) => d.fileId === value
    );
    this.toggleRow(idx);
  }
  @Input() set filter(value: string) {
    this.filesetDataSource.filter = value;
  }
  @ViewChild('filePaginator') paginator: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.filesetDataSource.sort = sort;
  }
  @Output() listLength = new EventEmitter<number>();
  @Output() fileDetail = new EventEmitter<ExtendedFileSetModel>();

  filesError: string;
  selectedFiles: number[] = [];
  metaTag = GeoDataFileMetaTags;
  loadingFiles = true;
  filesetDataSource = new MatTableDataSource<ExtendedFileSetModel>([]);
  filesetLength$ = new BehaviorSubject<number>(0);
  viewInited = false;
  currentSort: Sort;
  COLUMN_KEYS = COLUMN_KEYS;
  FILE_FORMAT = FILE_FORMAT;

  displayedColumns: string[] = [
    COLUMN_KEYS.CHECKBOX,
    COLUMN_KEYS.NAME,
    COLUMN_KEYS.TYPE,
    COLUMN_KEYS.CLIENT,
    COLUMN_KEYS.FARM,
    COLUMN_KEYS.DATE,
    COLUMN_KEYS.MANUFACTOR,
    COLUMN_KEYS.MORE,
  ];

  private sortingDataAccessor = (
    item: RawFileSetModel,
    property: string
  ): string => {
    switch (property) {
      case COLUMN_KEYS.TYPE:
        return this.getDataFileAggregateFileTypes(item);
      case COLUMN_KEYS.CLIENT:
        return this.getDataFileMetaAggregateString(item, this.metaTag.customer);
      case COLUMN_KEYS.FARM:
        return this.getDataFileMetaAggregateString(item, this.metaTag.farm);
      case COLUMN_KEYS.DATE:
        return item.uploaded.toString();
      case COLUMN_KEYS.MANUFACTOR:
        return this.translateSrv.t(
          '_data_format_' + item.dataFormat,
          '_' + item.dataFormat
        );
      default:
        return item[property];
    }
  };

  constructor(
    public translateSrv: DvToolbarTranslateService,
    public fileSrv: DataFileService,
    private filterSrv: DataFilterService,
    private dialogService: DialogService,
    private shareService: ShareService,
    private snackBar: MatSnackBar
  ) {
    this.fileSrv.loadingFileset$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .subscribe((loading) => {
        this.loadingFiles = loading;
      });

    this.fileSrv
      .getFilesets()
      .pipe(
        takeUntil(this._unsub$),
        takeWhile((data) => !data, true),
        filter((data) => !!data),
        map((data) => this.getTranslations(data))
      )
      .subscribe((data) => {
        if (data) {
          this.filesetDataSource.data = data;
          this.filesetLength$.next(
            this.fileSrv.filteredDataSourceLength(this.filesetDataSource)
          );
        } else {
          this.filesetDataSource.data = [];
          this.filesetLength$.next(0);
        }
      });

    // when filtering is done, we will reset progress indicator
    this.filesetLength$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .subscribe(() => {
        this.loadingFiles = this.fileSrv.loadingFileset$.value;
      });

    this.fileSrv.filesetErr$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .subscribe((error) => (this.filesError = error));
  }

  private getTranslations(data: RawFileSetModel[]): ExtendedFileSetModel[] {
    return data.map<ExtendedFileSetModel>((file) => ({
      ...file,
      translations: {
        aggregatedFileTypes: this.getDataFileAggregateFileTypes(file),
      },
    }));
  }

  ngAfterViewInit(): void {
    this.setUpDataSource();

    // We need to cut the table rendering some slack, otherwise
    // it seems that change detection will run once for each
    // row populated with data, which takes like forever (4s for 300 rows)
    setTimeout(() => {
      this.viewInited = true;
    }, 500);
  }

  ngAfterContentInit(): void {
    this.filesetLength$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .pipe(distinctUntilChanged())
      .subscribe((length) => {
        this.listLength.emit(length);
      });
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  private setUpDataSource(): void {
    this.paginator._intl.itemsPerPageLabel =
      this.translateSrv.t('Files per page');
    this.filesetDataSource.paginator = this.paginator;
    this.filesetDataSource.filterPredicate = this.filterSrv.initFileSetFilter();
    this.filesetDataSource.sortingDataAccessor = this.sortingDataAccessor;

    this.filterSrv.filterChange$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .subscribe((value) => {
        this.loadingFiles = true;
        this.filesetDataSource.filter = value;
        this.filesetLength$.next(
          this.fileSrv.filteredDataSourceLength(this.filesetDataSource)
        );
      });
  }

  toggleRow(fileset: ExtendedFileSetModel): void {
    this.fileDetail.emit(fileset);
  }

  checkedChange(fileId: number): void {
    if (!this.selectedFiles.includes(fileId)) {
      this.selectedFiles.push(fileId);
    } else {
      this.selectedFiles = this.selectedFiles.filter((id) => id !== fileId);
    }
  }

  getDataFileAggregateFileTypes(rawFile: RawFileSetModel): string {
    const contents = rawFile.dataFiles.map((a) =>
      this.translateSrv.t(
        '_data_filetype_' + a['fileType'],
        '_' + a['fileType']
      )
    );
    const unique = contents
      .filter((o, i) => {
        return contents.indexOf(o) === i;
      })
      .join(', ');

    return unique.length ? unique : '-';
  }

  getDataFileMetaAggregateString(
    rawFile: RawFileSetModel,
    metaKey: string
  ): string {
    let contents = rawFile.dataFiles.map((a) => {
      if (!a.metadata) {
        return null;
      }
      return a.metadata.find((data) => data.key === metaKey)?.value;
    });

    // eslint-disable-next-line prefer-spread
    contents = [].concat.apply([], contents);
    const unique = contents
      .filter((o, i) => contents.indexOf(o) === i)
      .join(', ');

    return unique.length ? unique : '-';
  }

  selectAllFiles(checked: boolean): void {
    if (checked) {
      this.filesetDataSource.data.forEach((file) =>
        this.selectedFiles.push(file.fileId)
      );
    } else {
      this.selectedFiles = [];
    }
  }

  onSortChange(sort: Sort): void {
    this.currentSort = sort;
  }

  openShareRawFilesDialog(): void {
    const ref = this.dialogService.open(FileSetListShareDialogComponent, {
      selectedFiles: this.filesetDataSource.data.filter((file) =>
        this.selectedFiles.includes(file.fileId)
      ),
    });

    ref.afterClosed().subscribe((result: ShareDialogResult) => {
      if (result?.data) {
        const payload: DataSharePayload = {
          message: result.data.message,
          recipients: result.data.emails,
          urls: result.data.links,
        };
        this.shareService.shareRawFileLinks(payload).subscribe(
          () => this.showSnackBar('Success'),
          () => this.showSnackBar('Something went wrong, please try again!')
        );
      }
    });
  }

  private showSnackBar(text: string): void {
    this.snackBar.open(this.translateSrv.t(text), null, {
      duration: 3500,
    });
  }
}
