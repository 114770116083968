import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  PointModel,
  SkifteDetielsModel,
  TabellUtsadeModel,
} from 'app/models/api.models';
import { LayerDialogComponent } from 'app/components/layer-dialog/layer-dialog.component';
import { MapService } from 'app/components/map/map.service';
import { LAYER_TYPE } from 'app/models/layertype.model';
import { MappingLayerModel, PropertyInfoModel } from 'app/models/models';
import { ParcelInfo } from 'app/models/parcell-info.model';
import { ClientService } from 'app/services/client.service';
import { MapStateService } from 'app/services/map-state.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Injectable({
  providedIn: 'root',
})
export class MappingWizardService {
  /** default crops */
  private readonly NO_SEED: TabellUtsadeModel = <TabellUtsadeModel>{
    groda: this.translSrv.t('no crop', '_No crop'),
    grodKod: 0,
  };
  private readonly UNKNOWN_SEED: TabellUtsadeModel = <TabellUtsadeModel>{
    groda: this.translSrv.t('unknown crop', '_Unknown crop'),
    grodKod: 0,
  };

  private readonly NO_SEED_TABLE_STRING: string = 'Ingen groda';

  /** holds table states */
  selectedMarkingYear$: BehaviorSubject<number> = new BehaviorSubject<number>(
    -1
  );
  activeIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  tableMarkingsState$: BehaviorSubject<TableMarkingState> =
    new BehaviorSubject<TableMarkingState>(null);

  /** holds group states */
  selectedGroup$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  interpolationType$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  showPoi$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /** holds state from properties */
  selectedProperty$: BehaviorSubject<PropertyInfoModel> =
    new BehaviorSubject<PropertyInfoModel>(null);

  /** holds state for layers */
  allLayers$: BehaviorSubject<MappingLayerModel[]> = new BehaviorSubject<
    MappingLayerModel[]
  >([]);
  private _availableLayers$: BehaviorSubject<MappingLayerModel[]>;
  selectedLayers$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(
    []
  );

  /** holds state for loading data */
  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  reloadFields$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  showTable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  editSkifte$: BehaviorSubject<SkifteDetielsModel> =
    new BehaviorSubject<SkifteDetielsModel>(null);
  editBordersField$: BehaviorSubject<SkifteDetielsModel> =
    new BehaviorSubject<SkifteDetielsModel>(null);
  layerFeatureCollection: GeoJSON.FeatureCollection;
  layerDetailViewRef: MatDialogRef<LayerDialogComponent>;
  showPoi = false;

  constructor(
    private translSrv: DvToolbarTranslateService,
    private clientSrv: ClientService,
    private mapService: MapService,
    private mapStateSrv: MapStateService,
    public dialog: MatDialog
  ) {}

  reset(resetSelection = false): void {
    this.selectedProperty$.next(null);
    this.mapStateSrv.clearValuePoints();
    if (resetSelection) {
      this.mapStateSrv.clearSelectedFeatures();
    }
    this.selectedGroup$.next(null);
    this.selectedLayers$.next(null);
  }

  setLoadingStatus(loading: boolean): void {
    this._loading$.next(loading);
  }

  loading(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  // TABLE HANDLING
  setMarkingsYear(year: number): void {
    this.selectedMarkingYear$.next(year);
  }

  getMarkingsYear(): Observable<number> {
    return this.selectedMarkingYear$.asObservable();
  }
  //tab index
  getActiveIndex(): Observable<number> {
    return this.activeIndex$.asObservable();
  }
  setActiveIndex(index: number): void {
    this.activeIndex$.next(index);
  }

  setTableMarkingsState(obj: TableMarkingState): void {
    this.tableMarkingsState$.next(obj);
  }
  getTableMarkingsState(): Observable<TableMarkingState> {
    return this.tableMarkingsState$.asObservable();
  }

  // ==== PARCEL HANDLING ===========================================================
  parcelInfo(): Observable<ParcelInfo> {
    return this.mapStateSrv
      .getSelectedFeatures()
      .pipe(map((l) => this.convertToParcelInfo(l)));
  }

  setLayerFeatureCollection(fc: GeoJSON.FeatureCollection): void {
    this.layerFeatureCollection = fc;
  }

  getLayerFeatureCollection(): GeoJSON.FeatureCollection {
    return this.layerFeatureCollection;
  }

  getReloadFields(): Observable<boolean> {
    return this.reloadFields$.asObservable();
  }

  setReloadFields(reload: boolean): void {
    this.reloadFields$.next(reload);
  }

  /** Returns all layers that matches current choices */
  availableLayers(): Observable<MappingLayerModel[]> {
    if (!this._availableLayers$) {
      this._availableLayers$ = new BehaviorSubject<MappingLayerModel[]>(null);
      combineLatest([
        this.allLayers$,
        this.selectedGroup$.pipe(distinctUntilChanged()),
        this.selectedProperty$.pipe(
          distinctUntilChanged(
            (prev, curr) =>
              (!prev && !curr) || (prev && curr && prev.field === curr.field)
          )
        ),
      ])
        .pipe(
          map(([layers, group, property]) => {
            if (group === LAYER_TYPE.VegIndex) {
              return [
                <MappingLayerModel>{
                  displayName: 'Biomassa',
                  description: 'Vegetationsindex',
                  layerId: 0,
                  created: null,
                  year: null,
                  klientId: null,
                  group: LAYER_TYPE.VegIndex,
                  type: LAYER_TYPE.VegIndex,
                  field: 'Biomassa',
                  fileName: null,
                },
              ];
            } else {
              return layers.filter((ls) => {
                if (group && ls.type !== group) {
                  return false;
                }
                if (property && ls.field !== property.field) {
                  return false;
                }

                return true;
              });
            }
          })
        )
        .subscribe((l) => this._availableLayers$.next(l));
    }
    return this._availableLayers$.asObservable();
  }

  unselectMapParcels(): void {
    this.mapStateSrv.clearSelectedFeatures();
  }

  private convertToParcelInfo(
    featureCol: GeoJSON.FeatureCollection
  ): ParcelInfo {
    if (!featureCol || featureCol.features.length === 0) {
      return null;
    }
    if (featureCol.features.length === 1) {
      if (
        featureCol.features[0].properties.groda &&
        featureCol.features[0].properties.groda.toLowerCase() ===
          this.NO_SEED_TABLE_STRING
      ) {
        featureCol.features[0].properties.groda = this.NO_SEED.groda;
      }
      return {
        featId: featureCol.features[0].id,
        name: featureCol.features[0].properties.namn,
        groda: featureCol.features[0].properties.groda,
        area: featureCol.features[0].properties.areal,
      };
    } else {
      const groda = featureCol.features
        .map((f) => f.properties.groda)
        .filter((v, i, s) => s.indexOf(v) === i);
      return {
        featId: null,
        name: this.translSrv.t(
          '{0} fields selected',
          featureCol.features.length
        ),
        groda: groda.length === 1 ? groda[0] : '',
        area: featureCol.features
          .map((f) => f.properties.areal)
          .reduce((s, areal) => s + areal),
      };
    }
  }

  setShowPoi(show: boolean): void {
    this.showPoi$.next(show);
  }

  getShowPoi(): Observable<boolean> {
    return this.showPoi$.asObservable();
  }

  getShowTableView(): Observable<boolean> {
    combineLatest([
      this.selectedGroup$,
      this.editBordersField$,
      this.showPoi$,
    ]).subscribe(([selectedGroup, editBorder, poi]) => {
      this.showTable$.next(!(selectedGroup || editBorder || poi));
    });
    return this.showTable$.asObservable();
  }

  getFeatureSvg(): Observable<string> {
    const feats = this.mapStateSrv.getCurrentSelectedFeatures();
    return this.clientSrv.getSvg(this.getPointArray(feats));
  }

  setLayers(layers: MappingLayerModel[]): void {
    this.allLayers$.next(layers);
  }

  setSelectedGroup(group: string): void {
    this.selectedGroup$.next(group);
  }

  setInterpolationType(type): void {
    this.interpolationType$.next(type);
  }

  getInterpolationType(): Observable<string> {
    return this.interpolationType$.asObservable();
  }

  setSelectedProperty(prop: PropertyInfoModel): void {
    this.selectedProperty$.next(prop);
  }

  setEditBordersField(skifte: SkifteDetielsModel): void {
    if (this.editBordersField$.value && !skifte) {
      this.clientSrv.loadSkifteLayer();
    }
    this.editBordersField$.next(skifte);
  }

  getEditBordersField(): Observable<SkifteDetielsModel> {
    return this.editBordersField$.asObservable();
  }

  // ==== UTILS ======================================================================

  private getPointArray(feats: GeoJSON.FeatureCollection): PointModel[] {
    const geometry = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    feats.features.forEach((feature: GeoJSON.Feature<any>) => {
      if (feature.geometry.coordinates) {
        const part: PointModel[] = [];
        feature.geometry.coordinates.forEach((geom) => {
          geom.forEach((c) => {
            part.push({ lng: c[0], lat: c[1] });
          });
        });
        geometry.push(part);
      }
    });
    return geometry;
  }

  getNoOfSelectableLayers(group: string): number {
    switch (group) {
      case LAYER_TYPE.Yield:
      case LAYER_TYPE.Veris:
      case LAYER_TYPE.SoilSampling:
        return -1;
      case LAYER_TYPE.NSensor:
      case LAYER_TYPE.Prescription:
      case LAYER_TYPE.VegIndex:
        return 1;
      default:
        return -1;
    }
  }

  getEditSkifte(): Observable<SkifteDetielsModel> {
    return this.editSkifte$.asObservable();
  }

  setEditSkifte(skifte: SkifteDetielsModel): void {
    this.editSkifte$.next(skifte);
  }

  colorHash(inputString): string {
    let sum = 0;

    for (const i in inputString) {
      sum += inputString.charCodeAt(i);
    }

    const r = ~~(
      parseFloat(
        `0.${Math.sin(sum + 1)
          .toString()
          .substr(6)}`
      ) * 256
    );
    const g = ~~(
      parseFloat(
        `0.${Math.sin(sum + 2)
          .toString()
          .substr(6)}`
      ) * 256
    );
    const b = ~~(
      parseFloat(
        `0.${Math.sin(sum + 3)
          .toString()
          .substr(6)}`
      ) * 256
    );

    let hex = '#';

    hex += ('00' + r.toString(16)).substr(-2, 2).toUpperCase();
    hex += ('00' + g.toString(16)).substr(-2, 2).toUpperCase();
    hex += ('00' + b.toString(16)).substr(-2, 2).toUpperCase();

    return hex;
  }

  showLayerDetailView(fc: GeoJSON.FeatureCollection): void {
    if (this.layerDetailViewRef) {
      try {
        this.layerDetailViewRef.componentInstance.setData(fc);
      } catch (err) {
        console.log(err);
      }
    } else {
      const options = {
        width: '400px',
        height: 'auto',
        marginTop: 'auto',
        position: {
          bottom: '25px',
          right: '25px',
        },
        panelClass: 'dv-layer-detail-dialog',
        disableClose: true,
        hasBackdrop: false,
        data: fc,
      };
      this.layerDetailViewRef = this.dialog.open(LayerDialogComponent, options);

      this.layerDetailViewRef.afterClosed().subscribe((closed) => {
        if (closed) {
          this.mapStateSrv.setSelectedMarkings({
            features: [],
            type: 'FeatureCollection',
          });
          this.mapService
            .mainMap()
            .pipe(first())
            .subscribe((res) => {
              this.mapService.deselectAllFeatures(res);
            });
        }
        this.layerDetailViewRef = null;
      });
    }
  }

  closeLayerDetailView(): void {
    setTimeout(() => {
      try {
        this.layerDetailViewRef.close();
      } catch (err) {
        return;
      }
    });
  }

  clearSteps(): void {
    this.selectedGroup$.next(null);
    this.interpolationType$.next(null);
    this.selectedProperty$.next(null);
  }
}

export interface TableMarkingState {
  activeFeatureIds: number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activeLayers: any[];
  newPois: number[];
  deletedPois: number[];
}
