<div class="no-data">
  <mat-icon color="primary">info</mat-icon>
  <div class="no-data-header">{{ 'Adjust' | dvt }}</div>
  <div class="no-data-description">
    {{
      '_prescription_customize_description'
        | dvt
          : '_Customise your prescription files by adding different adjustments'
    }}
  </div>
</div>
