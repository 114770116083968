<dvm-card class="card" elevation="3">
  <img
    class="card-icon"
    src="https://cdn.datavaxt.se/icons/CropMAP/select-grid-cell-icon.svg"
  />
  <div class="card-text-wrapper">
    <dvm-text body>
      {{ '_cell_select_info_title' | dvt: '_Select a point in the map' }}
    </dvm-text>
    <dvm-text label>
      {{
        '_cell_select_info_body'
          | dvt
            : '_You can inspect the imported data at each point by selecting them in the map.'
      }}
    </dvm-text>
  </div>
</dvm-card>
