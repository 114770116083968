import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Adjustments,
  ADJUSTMENT_TYPE,
  Adjustment,
} from '../../step-adjust.types';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'dv-adjustments-dialog',
  templateUrl: './adjustments-dialog.component.html',
  styleUrls: ['./adjustments-dialog.component.scss'],
})
export class AdjustmentsDialogComponent {
  adjustments: Adjustments;
  selectedAdjustments = new Set<string>();
  changed = false;
  ADJUSTMENT_TYPE = ADJUSTMENT_TYPE;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: Adjustments,
    private dialogRef: MatDialogRef<AdjustmentsDialogComponent>
  ) {
    if (this.data) {
      this.adjustments = this.data;
      Object.keys(this.data).forEach((key) => {
        if (this.data[key].added) {
          this.selectedAdjustments.add(key);
        }
      });
    }
  }

  close(): void {
    this.dialogRef.close(this.adjustments);
  }

  change(adjustment: KeyValue<string, Adjustment>): void {
    this.changed = true;
    this.selectedAdjustments.has(adjustment.key)
      ? this.selectedAdjustments.delete(adjustment.key)
      : this.selectedAdjustments.add(adjustment.key);
  }

  addAdjustments(): void {
    Object.keys(this.adjustments).forEach((key) => {
      this.adjustments[key].added = Array.from(
        this.selectedAdjustments
      ).includes(key);
    });
    this.dialogRef.close(this.adjustments);
  }

  noSort(): number {
    return 0;
  }
}
