import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

/**
 * Dialog content is a helper component that is used to create a default dialog
 */
@Component({
  selector: 'dv-dialog-content',
  templateUrl: './dialog.component.html',
   styleUrls: ['./dialog.component.scss']
})
export class DialogContent {

  @HostBinding('class.dv-dialog-loading') isLoading: boolean = false;

  @Input() loading: boolean;
  @Input() closeBtn: boolean;
  @Input() title: string;

  constructor() { }

  close() {
    this.onClose.next(false);
  }

  @Output() onClose = new EventEmitter<boolean>();
}
