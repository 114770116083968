import { Component, Input, OnInit, ElementRef, ChangeDetectionStrategy } from '@angular/core';



@Component({
    selector: 'dv-svg',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgComponent implements OnInit {
    @Input() svg: string;
    @Input() color: string;
    @Input() size: string = "120px";

    constructor(private elem: ElementRef) {

    }

    ngOnInit() {
        this.LoadSvg();
    }

    ngOnChanges() {
        if (this.elem.nativeElement.innerHTML !== this.svg)
            this.LoadSvg();
    }

    private LoadSvg() {
        if (this.svg) {
            this.elem.nativeElement.innerHTML = this.svg;
            if (this.elem.nativeElement.children.length > 0) {
                this.elem.nativeElement.children[0].style.width = this.size;
                this.elem.nativeElement.children[0].style.height = this.size;
                this.elem.nativeElement.children[0].style.margin = "4px";
                if (this.elem.nativeElement.children[0].children &&
                    this.elem.nativeElement.children[0].children.length > 0)
                    this.elem.nativeElement.children[0].children[0].style.fill = this.color ? this.color : "#fff";
            }
        }
    }
}