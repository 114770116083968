<button mat-fab class="close" color="primary" (click)="close()">
  <mat-icon>close</mat-icon>
</button>
<div class="dialog-title">
  <dvm-text h3>{{ 'Choose units to save this file for' | dvt }}?</dvm-text>
</div>
<div mat-dialog-content>
  <div class="input-row">
    <dvm-input
      class="input-row-input"
      label="{{ 'File name' | dvt }}"
      type="text"
      [errorMessage]="fileNameErrorMessage"
      (ngModelChange)="checkChars()"
      [(ngModel)]="fileName"
    ></dvm-input>
    <dvm-input
      *ngIf="!hasNutritionalContent"
      class="input-row-input"
      label="{{ 'Nutritional content' | dvt }} (1-100%)"
      [disabled]="loading"
      (blur)="numberCorrection()"
      type="number"
      min="1"
      max="100"
      [(ngModel)]="fileSrv.effectiveContent"
    ></dvm-input>
  </div>
  <div class="export-buttons">
    <ng-container
      *ngFor="let option of prescriptionExportService.options$ | async"
    >
      <dv-prescription-button
        *ngIf="option.showOption"
        [disabled]="disableItem"
        [imgSrc]="option.imgSrc"
        [text]="option.text"
        (click)="export(option.key)"
      ></dv-prescription-button>
    </ng-container>
  </div>
  <div class="loading-area">
    <mat-progress-bar
      [mode]="'indeterminate'"
      *ngIf="loading"
    ></mat-progress-bar>
    <span class="file-status">{{ fileStatus }}</span>
  </div>
  <button
    *ngIf="!noSaveClose"
    class="save-close-button"
    mat-raised-button
    color="primary"
    [disabled]="disableItem"
    (click)="saveAndClose()"
  >
    {{ 'Save and close' | dvt }}
  </button>
</div>
