<!-- Close Button-->
<button *ngIf="closeBtn" id="close-btn" mat-fab color="primary" (click)="close()" mat-dialog-close>
    <mat-icon class="md-24">clear</mat-icon>
</button>

<!-- Window Title-->
<div *ngIf="title" style="height: 60px; align-content: center; display: flex;" id="title-backgound">
    <div style="font-size: 20px; margin: auto;">
        {{ title }}
    </div>
</div>

<!-- Loading -->
<mat-progress-bar *ngIf="loading" [mode]="'indeterminate'"></mat-progress-bar>
<div *ngIf="loading" class="dv-dialog-shadow"></div>

<!-- Padded Dialog-->
<div style="padding: 20px; background: white;">
    <ng-content></ng-content>
</div>
