import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FieldGridModel } from 'app/models/models';
import { ClientService } from 'app/services/client.service';
import { FieldInterpolationService } from 'app/services/field-interpolation.service';
import { MapStateService } from 'app/services/map-state.service';
import { Observable, Subject, forkJoin } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { INTERPOLATION_TYPE } from '../field.types';

@Component({
  selector: 'dv-interpolation-groups',
  templateUrl: './interpolation-groups.component.html',
  styleUrls: ['./interpolation-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InterpolationGroupsComponent implements OnInit, OnDestroy {
  private _unsub$ = new Subject<void>();

  @Output() groupsChanged = new EventEmitter<boolean>();

  selectedFieldIds = [];
  groups: string[] = [];
  loadingData = false;
  groupsError = false;
  isSe = false;
  geodata: FieldGridModel[];
  sguIsAvailable = false;

  constructor(
    private mapStateSrv: MapStateService,
    private clientSrv: ClientService,
    private interpolationSrv: FieldInterpolationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  ngOnInit(): void {
    this.subscribeToClient();

    this.mapStateSrv
      .getSelectedFeatures()
      .pipe(takeUntil(this._unsub$))
      .subscribe((features) => {
        this.groupsError = false;
        this.loadingData = true;
        if (features && features.features?.length > 0) {
          this.selectedFieldIds = features.features
            .filter((feature) => !!feature.id)
            .map((feature) => feature.id);

          this.loadGeodata();
        } else {
          this.loadingData = false;
          this.selectedFieldIds = [];
          this.interpolationSrv.resetInterpolatableGeodata();
          this.groups = [];
          this.groupsChanged.emit(false);
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  private subscribeToClient(): void {
    this.clientSrv
      .client()
      .pipe(takeUntil(this._unsub$))
      .subscribe((client) => (this.isSe = client?.land === 'se'));
  }

  private loadGeodata(): void {
    if (!this.selectedFieldIds?.length) {
      this.changeDetectorRef.detectChanges();
      return;
    }

    forkJoin([
      this.clientSrv
        .getGeodataFromFields(this.selectedFieldIds)
        .pipe(takeUntil(this._unsub$), first()),
      ((): Observable<string[]> => {
        return this.clientSrv.getGeographicDataForFields(this.selectedFieldIds);
      })().pipe(takeUntil(this._unsub$), first()),
    ])
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        ([geodata, geographicData]) => {
          this.handleGeodataResponse(geodata);
          this.groupsChanged.emit(
            this.groups.length > 0 || this.selectedFieldIds?.length === 1
          );
          this.handleGeographicDataResponse(geographicData);
          this.changeDetectorRef.detectChanges();
        },
        () => this.handleGeodataError(),
        () => {
          this.loadingData = false;
          this.changeDetectorRef.detectChanges();
        }
      );
  }

  private handleGeodataResponse(geodata: FieldGridModel[]): void {
    this.geodata = geodata;
    this.interpolationSrv.setInterpolatableGeodata(geodata);
    this.groups = geodata
      .map((data) => data.fileType)
      .filter((type, index, arr) => arr.indexOf(type) === index);
  }

  private handleGeographicDataResponse(geographicData: string[]): void {
    this.sguIsAvailable =
      geographicData.findIndex((gd) => gd === 'SGULerhalt') !== -1;
  }

  private handleGeodataError(): void {
    this.loadingData = false;
    this.groups = [];
    this.groupsChanged.emit(false);
    this.groupsError = true;
    this.changeDetectorRef.detectChanges();
  }

  interpolateCropSat(): void {
    this.interpolationSrv.setInterpolationType(INTERPOLATION_TYPE.CROP_SAT);
  }

  interpolateFieldData(): void {
    this.interpolationSrv.setInterpolationType(INTERPOLATION_TYPE.CLAY_CONTENT);
  }

  setGroup(group: string): void {
    const geodata = this.geodata.filter((d) => d.fileType === group);
    this.interpolationSrv.setInterpolatableGeodata(geodata);
    this.interpolationSrv.setInterpolationType(group);
  }
}
