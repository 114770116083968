import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileModel, MarkeringModel } from 'app/models/models';
import { MappingWizardService } from '../../mapping-wizard.service';

@Component({
    selector: 'dv-marking-detail-view',
    templateUrl: './marking-detail-view.component.html',
    styleUrls: ['./marking-detail-view.component.scss'],
})
export class MarkingDetailViewComponent implements OnInit {
    @Input() marking: MarkeringModel;
    @Input() file: FileModel;
    @Input() saving: boolean = false;
    @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    public editing: boolean = false;
    @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDownload: EventEmitter<any> = new EventEmitter<any>();
    constructor(public wizSrv: MappingWizardService) {}

    ngOnInit(): void {}

    toggleEdit() {
        this.onEdit.emit({saving: this.editing});
        this.editing = !this.editing;
    }

    edit(){
        this.onEdit.emit();
    }

    close() {
        this.onClose.emit();
    }

    download(type: string) {
        this.onDownload.emit(type);
    }

    delete() {
        this.onDelete.emit(this.file);
    }
}
