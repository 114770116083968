import { Component, OnInit, Input } from '@angular/core';
import { MapComponent } from '../map.component';
import { SiteService } from 'app/services/site.service';
import { until } from 'protractor';

@Component({
  selector: 'dv-measure',
  templateUrl: './measure.component.html',
  styleUrls: ['./measure.component.css']
})
export class MeasureComponent implements OnInit {
  measure: boolean = false;
  private areaUnit: string = 'ha';
  private measureLine: google.maps.Polyline;
  private measureLabel: MeasureOverlay;
  private measureMarker: google.maps.Marker
  private measureMarkerEnd: google.maps.Marker
  private measurePolygon: google.maps.Polygon;
  @Input('map') dvMap: MapComponent;

  constructor(private siteService: SiteService) {

    this.siteService.getAreaUnit().subscribe(unit => this.areaUnit = unit);

  }

  ngOnInit() {
  }
  toggleMeasure() {
    this.measure = !this.measure;

    if (this.measure)
      this.startMeasure();
    else
      this.stopMeasure();
  }


  public pauseMeasure() {
    google.maps.event.clearListeners(this.dvMap.map, 'dblclick');
    google.maps.event.clearListeners(this.dvMap.map, 'mousemove');
    if (this.measureMarker)
      google.maps.event.clearListeners(this.measureMarker, 'click');
  }

  public stopMeasure() {
    this.dvMap.map.setOptions({
      draggableCursor: '',
      disableDoubleClickZoom: false
    });
    this.dvMap.map.setOptions({});
    this.dvMap.disableSelect = false;
    this.dvMap.map.data.forEach(f => {
      f.setProperty("_measure", false);
    })

    if (this.measureLine)
      this.measureLine.setMap(null);

    if (this.measureLabel)
      this.measureLabel.remove();

    if (this.measureMarker)
      this.measureMarker.setMap(null);

    if (this.measureMarkerEnd)
      this.measureMarkerEnd.setMap(null);

    if (this.measurePolygon)
      this.measurePolygon.setMap(null);

    google.maps.event.clearListeners(this.dvMap.map, 'mousemove');
    google.maps.event.clearListeners(this.dvMap.map, 'click');
    google.maps.event.clearListeners(this.dvMap.map, 'dblclick');
    google.maps.event.clearListeners(document, 'keyup');

  }

  resetMeasure() {
    this.stopMeasure();
    this.startMeasure();
  }
  public startMeasure() {
    this.dvMap.disableSelect = true;

    this.dvMap.map.data.forEach(f => {
      f.setProperty("_measure", true);
    })

    let strokeColor = "#fff";
    if (this.dvMap.map.getMapTypeId() === 'roadmap')
      strokeColor = "#649EFD"

    this.dvMap.map.setOptions({
      draggableCursor: 'crosshair',
      disableDoubleClickZoom: true
    });

    var lineSymbol = {
      path: 'M 0,-1 0,1',
      strokeOpacity: 1,
      scale: 3,
      strokeWeight: 2,
      strokeColor: strokeColor
    };


    this.measureLine = new google.maps.Polyline({
      strokeOpacity: 0,
      clickable: false,
      zIndex: 10000,
      icons: [{
        icon: lineSymbol,
        offset: '0',
        repeat: '15px'
      }],
    });

    this.measureLine.setMap(this.dvMap.map);



    this.dvMap.map.addListener('mousemove', (event: google.maps.PolyMouseEvent) => {
      var path = this.measureLine.getPath();
      if (path.getLength() > 1) {
        path.setAt(path.getLength() - 1, event.latLng)
        if (this.measureLabel) {

          let lineLength = 0;
          for (var i = 0; i < path.getLength(); i++) {
            if (i > 0) lineLength += google.maps.geometry.spherical.computeDistanceBetween(path.getAt(i), path.getAt(i - 1));
          }

          this.measureLabel.update(lineLength.toFixed(0) + " m", event.latLng);
        }
      }
    })

    this.dvMap.map.addListener('dblclick', (event: google.maps.PolyMouseEvent) => {
      google.maps.event.clearListeners(this.dvMap.map, 'mousemove');
      google.maps.event.clearListeners(this.dvMap.map, 'click');
      google.maps.event.clearListeners(this.measureMarker, 'click');
      google.maps.event.clearListeners(this.dvMap.map, 'dblclick');

      this.measureMarkerEnd = new google.maps.Marker({
        position: event.latLng,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: '#649EFD',
          fillOpacity: 1,
          scale: 7,
          strokeColor: strokeColor,
          strokeWeight: 1
        },
        map: this.dvMap.map
      });

    })

    google.maps.event.addDomListener(document, 'keyup', (e: any) => {
      let code = (e.keyCode ? e.keyCode : e.which);

      //ESC
      if (code === 27) {
        this.resetMeasure();
      }
    })

    this.dvMap.map.addListener('click', (event: google.maps.PolyMouseEvent) => {
      var path = this.measureLine.getPath();

      if (path.getLength() > 0) {
        path.setAt(path.getLength() - 1, event.latLng)
        path.push(event.latLng);
      }
      else
        path.push(event.latLng);

      if (path.getLength() === 1) {
        this.measureLabel = new MeasureOverlay("", event.latLng, this.dvMap.map);
        this.measureMarker = new google.maps.Marker({
          position: event.latLng,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: '#649EFD',
            fillOpacity: 1,
            scale: 7,
            strokeColor: strokeColor,
            strokeWeight: 1
          },
          map: this.dvMap.map
        });

        this.measureMarker.addListener('click', (event: any) => {
          if (path.getLength() > 2) {
            path.removeAt(path.getLength() - 1);
            this.measurePolygon = new google.maps.Polygon({
              paths: path,
              strokeColor: strokeColor,
              strokeOpacity: 1,
              strokeWeight: 2,
              fillColor: '#649EFD',
              fillOpacity: 0.35,
              zIndex: 10000
            });

            google.maps.event.clearListeners(this.dvMap.map, 'mousemove');
            google.maps.event.clearListeners(this.dvMap.map, 'click');
            google.maps.event.clearListeners(this.dvMap.map, 'dblclick');
            this.measurePolygon.setMap(this.dvMap.map);
            this.measureLine.setMap(null);
            this.measureMarker.setMap(null);

            let lineLength = 0;
            for (var i = 0; i < path.getLength(); i++) {
              if (i > 0) lineLength += google.maps.geometry.spherical.computeDistanceBetween(path.getAt(i), path.getAt(i - 1));
            }
            let area = google.maps.geometry.spherical.computeArea(this.measurePolygon.getPath());

            var bounds = new google.maps.LatLngBounds();
            let polyPath = this.measurePolygon.getPath();
            for (let i = 0; i < polyPath.getLength(); i++) {
              bounds.extend(polyPath.getAt(i));
            }

            this.measureLabel.update(lineLength.toFixed(0) + " m<br>" + Math.round(this.siteService.calculateArea(area) * 100) / 100 + " " + this.areaUnit, bounds.getCenter());

          }
        });

        path.push(event.latLng);
      }
    });
  }
}


export class MeasureOverlay extends google.maps.OverlayView {
  private el: HTMLDivElement;


  constructor(public text: string, protected pos: google.maps.LatLng, private map: google.maps.Map) {
    super();
    this.setMap(map);
  }

  onAdd() {
    this.el = document.createElement('div');
    this.el.innerHTML = this.text;
    this.el.className = 'google-maps-overlay measure';

    this.updateElPos();
    var panes = this.getPanes();
    panes.floatPane.appendChild(this.el);
  }

  draw() {
    this.updateElPos();
    if (this.el)
      this.el.innerHTML = this.text;
  }

  remove() {
    this.onRemove();
  }

  onRemove() {
    let panes = this.getPanes();
    this.setMap(null);

    if (panes && panes.floatPane && this.el) {
      if (panes.floatPane.hasChildNodes())
        panes.floatPane.removeChild(this.el);
    }
  }

  update(text: string, pos: google.maps.LatLng) {
    this.pos = pos;
    this.text = text;
    this.draw();
  }

  private updateElPos() {
    let overlayProjection = this.getProjection();
    if (overlayProjection && this.pos) {
      let position = overlayProjection.fromLatLngToDivPixel(this.pos);
      if (this.el) {
        this.el.style.left = position.x + 'px';
        this.el.style.top = position.y + 'px';
      }
    }
  }
}