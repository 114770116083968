<dv-dialog-content closeBtn="true">
    <div class="content">
        <div class="title">{{ 'Share' | dvt }}</div>
        <div class="label">{{ 'Selected data' | dvt }}</div>
        <div class="file-list">
            <div class="section">
                <div class="file-list-item">
                    <div class="file-list-item-left" *ngFor="let data of shareData">
                        <div>{{ data.file.name }}</div>
                    </div>
                    <div class="file-list-item-right" >
                        <div *ngIf="!fetching; else spinner">
                            <button mat-icon-button  *ngFor="let link of links">
                                <a link
                                style = "margin-right: 15px;"
                                target="blank"
                                [href]="link.link"
                            >{{ link.link }}</a>
                                <mat-icon 
                                    class="file-list-item-right-icon" 
                                    [inline]="true"
                                    (click)="copyLink(link.link)">file_copy</mat-icon>
                            </button>
                        </div>
                        <ng-template #spinner>
                            <mat-progress-spinner
                             diameter="30"
                             mode="indeterminate"
                            ></mat-progress-spinner>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="section-recipients">
                <div class="label">{{ 'Recipients' | dvt }}</div>
                <div class="email-list">
                    <div class="email-row" *ngFor="let email of emails; let i = index">
                        <mat-form-field class="email-row-form" appearance="outline">
                            <input class="email-row-input" matInput type="email" [formControl]="email"
                                [errorStateMatcher]="matcher" [placeholder]="'Email' | dvt" />
                            <mat-error *ngIf="email.hasError('email')">
                                {{ 'Please enter a valid email address' | dvt }}
                            </mat-error>
                            <mat-error *ngIf="email.hasError('required')">
                                {{ 'Email is required' | dvt }}
                            </mat-error>
                        </mat-form-field>
                        <button class="fab" *ngIf="i === emails.length - 1" mat-mini-fab (click)="addEmail()">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button class="fab fab-light" *ngIf="i !== emails.length - 1" mat-mini-fab
                            (click)="removeEmail(i)">
                            <mat-icon>remove</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-message">
                <div class="label">{{ 'Message' | dvt }}</div>
                <textarea class="message" [placeholder]="'Enter message...' | dvt" rows="3"
                    [(ngModel)]="message"></textarea>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end">
        <button mat-flat-button [mat-dialog-close]="false">{{ 'Cancel' | dvt }}</button>
        <button mat-flat-button [disabled]="fetching || hasFetchError" color="primary" (click)="complete()">{{ 'Send' |
            dvt }}</button>
    </mat-dialog-actions>
</dv-dialog-content>