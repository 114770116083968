import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { HttpBackend, HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'app/components/dialog/dialog.service';
import { InsufficientRightsDialogComponent } from 'app/components/insufficient-rights-dialog/insufficient-rights-dialog.component';
import { MapComponent } from 'app/components/map/map.component';
import { MapService } from 'app/components/map/map.service';
import { MappingLegendModel } from 'app/models/interpolation.model';
import { ClientService } from 'app/services/client.service';
import { ShareService } from 'app/services/share.service';
import { SiteService } from 'app/services/site.service';
import { environment } from 'environments/environment';
import { Subject, lastValueFrom } from 'rxjs';
import { takeUntil, switchMap, map, take } from 'rxjs/operators';
import { ShareDataResponse } from './share-page.types';
import {
  DvAuthService,
  DvClientService,
  DvToolbarTranslateService,
} from '@dv/toolbar-msal';
@Component({
  selector: 'dv-share-page',
  templateUrl: './share-page.component.html',
  styleUrls: ['./share-page.component.scss'],
})
export class SharePageComponent implements OnInit, OnDestroy {
  private _unsub$: Subject<void> = new Subject<void>();
  private httpClient: HttpClient;
  private _paketId = {
    cropMapEurofins: 11,
  };
  private _sender: string = null;
  legend: MappingLegendModel = null;
  isLoggedIn: boolean;
  isNarrowLayout: boolean;
  showMapCoordinates: boolean;
  comment: string = null;
  layerIds: number[];
  geoDataIds: number[];
  fileSets: number[];
  key: string;
  queryParams?: string;
  saving: boolean;
  completed = false;
  hasCropMapAdvanced = false;
  errorMessage: string;

  constructor(
    handler: HttpBackend,
    private mapService: MapService,
    private route: ActivatedRoute,
    private breakPointObserver: BreakpointObserver,
    private shareService: ShareService,
    private snackBar: MatSnackBar,
    private translate: DvToolbarTranslateService,
    private router: Router,
    private clientService: ClientService,
    private siteService: SiteService,
    private dialog: DialogService,
    private dvAuthService: DvAuthService,
    private dvClientService: DvClientService
  ) {
    this.httpClient = new HttpClient(handler);
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((param) => {
      this._sender = param.get('sender');
      if (this._sender) {
        switch (this._sender.toLowerCase()) {
          case 'eurofins':
            this.dvAuthService.setOrderPackages(this._paketId.cropMapEurofins);
            break;
          default:
          //do nothing
        }
      }
    });
    this.mapService.mainMap().subscribe((mainMap) => {
      this.setKeyAndQueryParams();
      this.httpClient
        .get<ShareDataResponse>(`${environment.baseApiUrl}/share/${this.key}`)
        .subscribe(
          (res) => this.onFetchSuccess(mainMap, res),
          (err) => this.onFetchError(err.status)
        );
    });

    // Secons breakpoint is just a trigger
    const widthObserver = this.breakPointObserver.observe([
      '(max-width: 1023px)',
      '(min-width: 1024px)',
    ]);

    widthObserver
      .pipe(takeUntil(this._unsub$))
      .subscribe((result: BreakpointState) => {
        const res = result.breakpoints['(max-width: 1023px)'];
        this.applyLayout(res);
      });

    // Check if we have a logged-in user
    this.dvAuthService.isLoggedIn$
      .pipe(takeUntil(this._unsub$))
      .subscribe((hasActiveSession: boolean) => {
        this.isLoggedIn = hasActiveSession;
      });

    this.siteService
      .hasCropMapAdvanced()
      .pipe(takeUntil(this._unsub$))
      .subscribe((hasCropmapAdvanced) => {
        this.hasCropMapAdvanced = hasCropmapAdvanced;
      });
  }

  ngOnDestroy(): void {
    this._unsub$.complete();
  }

  private onFetchError(status: number): void {
    switch (status) {
      case 404:
        this.errorMessage = 'Link not valid';
        break;
      case 500:
      default:
        this.errorMessage = 'Something went wrong';
        break;
    }
  }

  private onFetchSuccess(mainMap: MapComponent, res: ShareDataResponse): void {
    if (res.fileSets === null) {
      mainMap.fitFeature(mainMap.addGeoJson(res.preview, 'share'));
    }
    this.legend = res.preview?.legend;
    this.comment = res.message;
    this.layerIds = res.layerIds;
    this.geoDataIds = res.geoDataIds;
    this.fileSets = res.fileSets;
    if (this.fileSets !== null) {
      mainMap.setDraggable(false);
    }
  }

  setKeyAndQueryParams(): void {
    const rawKey = this.route.snapshot.paramMap.get('key');
    const index = rawKey.indexOf('?');

    if (index !== -1) {
      this.key = rawKey.substring(0, index);
      this.queryParams = rawKey.substring(index);
    } else {
      this.key = rawKey;
    }
  }

  goToAnalyse(): void {
    if (this.hasCropMapAdvanced) {
      this.clientService.clientId().subscribe((clientId) => {
        this.router.navigateByUrl('client/' + clientId + '/analyze/split');
      });
    } else {
      this.openMissingRightsDialog();
    }
  }

  openMissingRightsDialog(): void {
    const conf = {
      width: '800px',
      data: { functionName: this.translate.t('Analyze') },
      panelClass: 'insufficient-dialog',
    };
    this.dialog.open(InsufficientRightsDialogComponent, conf);
  }

  async saveFile(): Promise<void> {
    this.saving = true;
    const clientId = await lastValueFrom(
      this.dvClientService.client$.pipe(
        take(1),
        map((client) => client.id)
      )
    );

    this.shareService.saveFileToClient(this.key, clientId).subscribe(
      () => this.onSaveSuccess('Files saved'),
      () => this.onSaveError()
    );
  }

  downloadFile(): void {
    this.saving = true;
    this.shareService.downloadFile(this.key).subscribe(
      (file: HttpResponse<Blob>) => this.handleDownloadResponse(file),
      () => this.handleDownloadError(),
      () => (this.saving = false)
    );
  }

  //This logic (next three functions) might be better somewhere else?
  handleDownloadResponse(file: HttpResponse<Blob>): void {
    const dlName: string = this.getFilenameFromHeaders(file);

    const link = document.createElement('a');
    link.setAttribute('style', 'display:none;');
    document.body.appendChild(link);
    link.download = dlName;
    link.href = URL.createObjectURL(file.body);
    link.target = '_blank';
    link.click();
    document.body.removeChild(link);
  }

  private getFilenameFromHeaders(response: HttpResponse<Blob>): string {
    let fileName: string;
    try {
      const disp = response.headers.get('content-disposition');
      const regExp = /(?:filename=)(.+)(?:)/;
      fileName = decodeURI(regExp.exec(disp)[1]);
    } catch (err) {
      fileName = 'test';
    }
    return fileName;
  }

  private handleDownloadError(): void {
    this.snackBar.open(this.translate.t('Failed to download'), null, {
      duration: 5000,
    });
  }

  async saveFiles(): Promise<void> {
    this.saving = true;
    const clientId = await lastValueFrom(
      this.dvClientService.client$.pipe(
        take(1),
        map((client) => client.id)
      )
    );

    this.shareService
      .saveFiles(this.key, clientId, this.layerIds, this.queryParams)
      .subscribe(
        () => this.onSaveSuccess('Files saved'),
        () => this.onSaveError()
      );
  }

  saveGeoData(): void {
    this.saving = true;
    this.clientService
      .clientId()
      .pipe(switchMap((id) => this.shareService.saveGeoData(this.key, id)))
      .subscribe(
        () => this.onSaveSuccess('GeoData saved'),
        () => this.onSaveError()
      );
  }

  private onSaveSuccess(message: string): void {
    this.saving = false;
    this.completed = true;
    this.snackBar.open(this.translate.t(message), null, {
      duration: 3000,
    });
  }

  private onSaveError(): void {
    this.saving = false;
    this.snackBar.open(
      this.translate.t('Something went wrong. Please try again'),
      null,
      {
        duration: 3000,
      }
    );
  }

  applyLayout(isNarrow: boolean): void {
    this.isNarrowLayout = isNarrow;
    this.showMapCoordinates = !isNarrow;
  }
}
