export interface MappingLayer {
  id: number;
  name: string;
  newFilestack: boolean;
}

export enum FILE_EXTENSION {
  DBF = '.dbf',
  SHP = '.shp',
  TXT = '.txt',
  CSV = '.csv',
}

export interface TextFileRow {
  [key: string]: unknown;
  x: string;
  y: string;
}

export type ParseResultData = string[][];
