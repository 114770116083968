import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  Inject,
  AfterViewInit,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { SkifteDetielsModel } from 'app/models/api.models';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { PrescriptionWizardService } from 'app/views/prescription-wizard-page/prescription-wizard.service';
import {
  SkifteSelection,
  SkifteTableChange,
  SKIFTE_SELECTION_SOURCE,
  SKIFTE_TABLE_COLUMNS,
} from 'app/views/prescription-wizard-page/prescription-wizard.types';
import { FieldTableItem } from './step-field.types';

@Component({
  selector: 'dv-step-field',
  templateUrl: 'step-field.component.html',
  styleUrls: ['step-field.component.scss', '../steps.scss'],
})
export class StepFieldComponent implements OnChanges, OnInit, AfterViewInit {
  @Input() skiften: SkifteDetielsModel[];
  @Input() loading = true;
  selectedId: number;
  freeSearchCtrl = new UntypedFormControl();
  skifteDataSource = new MatTableDataSource<FieldTableItem>();
  SKIFTE_TABLE_COLUMNS = SKIFTE_TABLE_COLUMNS;
  columns = [
    SKIFTE_TABLE_COLUMNS.RADIO_BUTTON,
    SKIFTE_TABLE_COLUMNS.FIELD,
    SKIFTE_TABLE_COLUMNS.CROP,
    SKIFTE_TABLE_COLUMNS.UNIT,
  ];
  private unsub$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public sanitizer: DomSanitizer,
    private wizardService: PrescriptionWizardService
  ) {}

  ngOnInit(): void {
    this.wizardService.selectedSkifte$
      .pipe(takeUntil(this.unsub$))
      .subscribe((selection) => this.handleSkifteSelection(selection));

    this.setFilterPrediction();
  }

  ngAfterViewInit(): void {
    this.freeSearchCtrl.valueChanges
      .pipe(debounceTime(200))
      .subscribe((filterText: string) => {
        filterText = filterText?.trim();
        this.skifteDataSource.filter = filterText;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.skiften?.currentValue) {
      this.skifteDataSource.data = this.skiften.map((skifte) => ({
        svg: this.sanitizer.bypassSecurityTrustHtml(skifte.svg || ''),
        skifte,
      }));
    }
    if (changes.loading) {
      changes.loading.currentValue
        ? this.freeSearchCtrl.disable()
        : this.freeSearchCtrl.enable();
    }
  }

  onChange({ id, namn }: SkifteTableChange): void {
    this.selectedId = id;
    this.wizardService.selectSkifte({
      id,
      name: namn,
      source: SKIFTE_SELECTION_SOURCE.TABLE,
    });
  }

  private setFilterPrediction(): void {
    this.skifteDataSource.filterPredicate = (
      { skifte }: FieldTableItem,
      filter: string
    ): boolean => {
      const { namn, brukEnhNamn, huvudgrodaBenamning } = skifte;
      const lowerCaseFilter = filter.toLowerCase();

      return (
        namn.toLowerCase().includes(lowerCaseFilter) ||
        brukEnhNamn.toLowerCase().includes(lowerCaseFilter) ||
        huvudgrodaBenamning.toLowerCase().includes(lowerCaseFilter)
      );
    };
  }

  private handleSkifteSelection(selection: SkifteSelection): void {
    this.selectedId = selection?.id ?? -1;

    if (selection?.source === SKIFTE_SELECTION_SOURCE.MAP) {
      const selectedRow: HTMLCollection =
        this.document.getElementsByClassName('table-row-selected');
      setTimeout(() =>
        selectedRow.item(0)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      );
    }
  }
}
