<dv-geo-data-header
  title="{{ 'Customize data layer' | dvt }}"
  [min]="data.definition.minLimit"
  [max]="data.definition.maxLimit"
  [showFixedValueToggle]="data.definition.allowConstant"
  (useFixedValueChange)="onUseFixedValueChange($event)"
  (fixedValueChange)="onFixedValueChange($event)"
></dv-geo-data-header>
<div class="divider"></div>
<dv-file-preview-list
  [files]="data.definition.files"
  [selectedFiles]="data.value"
  [multiSelect]="data.definition.multiselect"
  [disabled]="useFixedValueChange"
  (fileChange)="fileChange.emit($event)"
></dv-file-preview-list>
