import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { MapComponent } from 'app/components/map/map.component';
import {
  LAYER_NAME_FILEDATA,
  LAYER_NAME_INTERPOLATION,
  LAYER_NAME_SKIFTEN,
  LAYER_NAME_SPREADBOUNDARY,
  MapService,
} from 'app/components/map/map.service';
import { GeoDataFileModel, RawFileSetModel } from 'app/models/models';
import { ClientService } from 'app/services/client.service';
import { DataFileService } from 'app/services/data-file.service';
import { MapStateService } from 'app/services/map-state.service';
import { FeatureCollection } from 'geojson';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dv-geodata-detail',
  templateUrl: './geodata-detail.component.html',
  styleUrls: ['./geodata-detail.component.scss'],
})
export class GeodataDetailComponent implements OnDestroy, AfterViewInit {
  geodata: GeoDataFileModel;
  loading = true;
  dvMap: MapComponent;
  private filesetSource: RawFileSetModel;
  private skiften: FeatureCollection;
  private _unsub$: Subject<void> = new Subject<void>();
  private selectedTab: GeodataDetailTabs = GeodataDetailTabs.INTERPOLATION;

  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  constructor(
    private mapService: MapService,
    private fileService: DataFileService,
    private clientService: ClientService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    combineLatest([
      this.route.paramMap,
      this.fileService.selectedGeodata$.asObservable(),
      this.fileService.getGeodata(),
    ])
      .pipe(takeUntil(this._unsub$))
      .subscribe(([params, selected, geodataFiles]) => {
        const paramId = Number(params.get('id'));

        if (!Number.isNaN(paramId) && paramId != selected) {
          this.fileService.selectedGeodata$.next(paramId);
        }

        this.getGeodata();
      });
  }

  ngAfterViewInit(): void {
    this.mapService
      .mainMap()
      .pipe(takeUntil(this._unsub$))
      .subscribe((dvMap) => {
        this.dvMap = dvMap;

        if (dvMap.map.getZoom() <= 13) {
          dvMap.map.setZoom(14);
        }

        dvMap.multiSelect = false;

        this.setTabMapStyleOptions();
      });

    this.tabGroup.selectedTabChange.subscribe((tab: MatTabChangeEvent) => {
      this.selectedTab = tab.index;
      this.setTabMapStyleOptions();
    });
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();

    this.restoreLayerVisibility();
    this.clearMap();
  }

  private restoreLayerVisibility(): void {
    const layers = [
      LAYER_NAME_SKIFTEN,
      LAYER_NAME_FILEDATA,
      LAYER_NAME_INTERPOLATION,
      LAYER_NAME_SPREADBOUNDARY,
    ];

    layers.forEach((layer) => this.mapService.revertOverrideStyles(layer));
  }

  private clearMap(): void {
    const layers = [
      LAYER_NAME_FILEDATA,
      LAYER_NAME_INTERPOLATION,
      LAYER_NAME_SPREADBOUNDARY,
    ];
    layers.forEach((layer) => this.dvMap.removeLayer(layer));
  }

  private getGeodata(): void {
    if (this.fileService.geodata$.value?.length > 0) {
      this.geodata = this.fileService.geodata$.value.find(
        (d) => d.fileId === this.fileService.selectedGeodata$.value
      );

      if (this.geodata) {
        this.loading = false;
      }

      if (
        this.geodata?.rawFileId &&
        this.fileService.fileset$.value &&
        this.fileService.fileset$.value?.length > 0
      ) {
        this.filesetSource = this.fileService.fileset$.value.find(
          (f) => f.fileId === this.geodata.rawFileId
        );
      }
    }
  }

  goToGeodataList(): void {
    this.clientService.clientId().subscribe((clientId) => {
      this.router.navigateByUrl(`client/${clientId}/data/${0}`);
    });
  }

  goToFilesetList(): void {
    this.clientService.clientId().subscribe((clientId) => {
      this.router.navigateByUrl(`client/${clientId}/data/${1}`);
    });
  }

  private setTabMapStyleOptions(): void {
    if (!this.dvMap) {
      return;
    }

    this.mapService.registerOverrideStyle(LAYER_NAME_SKIFTEN, (_) => {
      return {
        fillOpacity: 0.6,
        visible: this.selectedTab == GeodataDetailTabs.DETAILS,
      };
    });

    this.mapService.registerOverrideStyle(LAYER_NAME_FILEDATA, (_) => {
      return {
        visible: this.selectedTab == GeodataDetailTabs.FILEDATA,
      };
    });

    this.mapService.registerOverrideStyle(LAYER_NAME_INTERPOLATION, (_) => {
      return {
        clickable: false,
        visible: this.selectedTab == GeodataDetailTabs.INTERPOLATION,
      };
    });

    this.mapService.registerOverrideStyle(LAYER_NAME_SPREADBOUNDARY, (_) => {
      return {
        fillOpacity: 0.5,
        zIndex: -1,
        clickable: false,
        visible: this.selectedTab == GeodataDetailTabs.DETAILS,
      };
    });
    this.mapService.updateLayerStyles(this.dvMap);
  }
}

export enum GeodataDetailTabs {
  INTERPOLATION,
  FILEDATA,
  DETAILS,
}
