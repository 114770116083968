import { Injectable } from '@angular/core';
import { FunctionModel } from 'app/models/api.models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  PRESCRIPTION_STEPS,
  SelectedValue,
  SkifteSelection,
  SKIFTE_SELECTION_SOURCE,
  StepState,
} from './prescription-wizard.types';
import { PrescriptionSettings } from './components/step-settings/step-settings.types';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

const defaultStepState: StepState[] = [
  {
    label: 'Choose field',
    completed: false,
  },
  {
    label: 'Choose method',
    completed: false,
  },
  {
    label: 'Choices and settings',
    completed: false,
  },
  {
    label: 'Review and adjust',
    completed: false,
  },
];

@Injectable({
  providedIn: 'root',
})
export class PrescriptionWizardService {
  private selectedSkifte = new BehaviorSubject<SkifteSelection>(null);
  private selectedFunction = new BehaviorSubject<FunctionModel>(null);
  private selectedSettings = new BehaviorSubject<PrescriptionSettings>(null);
  private stepState = new BehaviorSubject<StepState[]>(defaultStepState);
  private selectedGeoDataTypeIndex = new BehaviorSubject<number>(0);
  private selectedStepIndex = new BehaviorSubject<PRESCRIPTION_STEPS>(
    PRESCRIPTION_STEPS.FIELD
  );
  private prescriptionSaved = new Subject<void>();
  private restartWizard = new Subject<void>();
  ongoingGridCalculation = new BehaviorSubject<boolean>(false);
  selectedSkifte$ = this.selectedSkifte.asObservable();
  selectedFunction$ = this.selectedFunction.asObservable();
  selectedSettings$ = this.selectedSettings.asObservable();
  stepState$ = this.stepState.asObservable();
  selectedGeoDataTypeIndex$ = this.selectedGeoDataTypeIndex.asObservable();
  selectedStepIndex$ = this.selectedStepIndex.asObservable();
  prescriptionSaved$ = this.prescriptionSaved.asObservable();
  restartWizard$ = this.restartWizard.asObservable();

  private lastSelectionWasFromTable = false;

  constructor(private translateService: DvToolbarTranslateService) {
    this.translateDefaultStepLabels();
  }

  selectSkifte(selection: SkifteSelection): void {
    // Selecting a skifte from the table, causes selectSkifte() to be
    // triggered twice or three times (an additional deSelect-event is
    // triggered) if a previous selection was made. This code prevents
    // the selectedSkifte$-observable to emit an additional selection
    // with source "MAP" when the selection was made in the table.
    if (this.lastSelectionWasFromTable) {
      this.lastSelectionWasFromTable = !selection;
      return;
    }
    this.lastSelectionWasFromTable =
      selection?.source === SKIFTE_SELECTION_SOURCE.TABLE;
    this.selectedSkifte.next(selection);

    const label = selection
      ? selection.name?.trim() || this.translateService.t('Field selected')
      : this.translateService.t(
          defaultStepState[PRESCRIPTION_STEPS.FIELD].label
        );

    this.setState(
      {
        label,
        completed: !!selection,
      },
      PRESCRIPTION_STEPS.FIELD
    );
  }

  selectFunction(selectedFunction: FunctionModel): void {
    const label = selectedFunction
      ? selectedFunction.name
      : this.translateService.t(
          defaultStepState[PRESCRIPTION_STEPS.METHOD].label
        );

    this.setState(
      {
        label,
        completed: !!selectedFunction,
      },
      PRESCRIPTION_STEPS.METHOD
    );

    this.selectedFunction.next(selectedFunction);
  }

  selectFunctionParameters(completed: boolean): void {
    this.setState(
      {
        label: defaultStepState[PRESCRIPTION_STEPS.SETTINGS].label,
        completed,
      },
      PRESCRIPTION_STEPS.SETTINGS
    );
  }

  selectAdjustments(completed: boolean): void {
    this.setState(
      {
        label: defaultStepState[PRESCRIPTION_STEPS.ADJUST].label,
        completed,
      },
      PRESCRIPTION_STEPS.ADJUST
    );
  }

  clearStateFromStep(index: PRESCRIPTION_STEPS): void {
    const selectedValues: BehaviorSubject<SelectedValue>[] = [
      this.selectedSkifte,
      this.selectedFunction,
      this.selectedSettings,
    ];
    while (index < PRESCRIPTION_STEPS.ADJUST) {
      index++;
      this.setState(
        {
          label: defaultStepState[index].label,
          completed: false,
        },
        index
      );

      selectedValues[index]?.next(null);
    }
  }

  getStepState(): void {
    this.stepState.next(this.stepState.value);
  }

  getSelectedStepIndex(): number {
    return this.selectedStepIndex.value;
  }

  setGeoDataTypeIndex(index: number): void {
    this.selectedGeoDataTypeIndex.next(index);
  }

  setSelectedStepIndex(index: PRESCRIPTION_STEPS): void {
    this.selectedStepIndex.next(index);
  }

  publishPrescriptionSaved(isSaved: boolean): void {
    if (isSaved) {
      this.prescriptionSaved.next();
    }
  }

  publishRestartWizard(): void {
    this.restartWizard.next();
  }

  private setState(
    { label, completed }: StepState,
    stepIndex: PRESCRIPTION_STEPS
  ): void {
    const newState = this.stepState.value.map((step, index) => {
      if (index === stepIndex) {
        return { label, completed };
      } else {
        return { ...step };
      }
    });

    this.stepState.next(newState);
  }

  private translateDefaultStepLabels(): void {
    defaultStepState.forEach(
      (stateItem: StepState) =>
        (stateItem.label = this.translateService.t(stateItem.label))
    );
  }
}
