<dv-no-data *ngIf="showNoData | async; else dataTable"></dv-no-data>
<ng-template #dataTable>
  <div class="title-row section">
    <h1>{{ 'My data' | dvt }}</h1>
    <button
      class="title-row-upload-button"
      mat-flat-button
      (click)="importFiles()"
    >
      <mat-icon>cloud_upload</mat-icon>
      {{ 'Upload new file' | dvt }}
    </button>
  </div>
  <dv-filter-section
    *ngIf="filter"
    [@filterGrowAnimation]
    class="filter-section"
    [filterValues]="filterValues"
    [availableFilterValues]="availableFilterValues"
    (filterChange)="setFilter($event.key, $event.value)"
    (filterCleared)="onFilterCleared()"
  ></dv-filter-section>
  <div class="tab-action">
    <div class="tab-action-container">
      <mat-form-field class="search-form-field">
        <mat-icon matPrefix>search</mat-icon>
        <input
          matInput
          [formControl]="freeSearchCtrl"
          type="text"
          [placeholder]="translateService.t('Search')"
        />
      </mat-form-field>
      <div class="filter-search">
        <button (click)="filter = !filter" mat-flat-button>
          <mat-icon
            [matBadge]="numberOfActiveFilters"
            [matBadgeHidden]="!(numberOfActiveFilters > 0)"
            matBadgeSize="small"
            matBadgePosition="after"
          >
            filter_alt
          </mat-icon>
          <span>{{
            filter ? ('Hide filter' | dvt) : ('Show filter' | dvt)
          }}</span>
        </button>
      </div>
    </div>
  </div>
  <mat-tab-group mat-stretch-tabs="false">
    <mat-tab>
      <ng-template mat-tab-label>
        {{ 'Data' | dvt }}
        <mat-progress-spinner
          mode="indeterminate"
          diameter="18"
          *ngIf="fileService.loadingGeodata$ | async; else geodataNbr"
        ></mat-progress-spinner>
        <ng-template #geodataNbr> ({{ geodataLength }})</ng-template>
      </ng-template>
      <dv-geodata-list
        *ngIf="!showNoDataSearchResult; else noSearchResult"
        (listLength)="updateGeodataLength($event)"
        (fileDetail)="onShowFileDetails($event)"
      ></dv-geodata-list>
    </mat-tab>
    <mat-tab (label)="translateService.t('Uploaded files')">
      <ng-template mat-tab-label>
        {{ 'Files' | dvt }}
        <mat-progress-spinner
          mode="indeterminate"
          diameter="18"
          *ngIf="fileService.loadingFileset$ | async; else filesetNbr"
        ></mat-progress-spinner>
        <ng-template #filesetNbr> ({{ filesetLength }})</ng-template>
      </ng-template>
      <dv-file-set-list
        *ngIf="!showNoFileSearchResult; else noSearchResult"
        (listLength)="updateFilesetLength($event)"
        (fileDetail)="onShowFileDetails($event)"
      ></dv-file-set-list>
    </mat-tab>
    <ng-template #noSearchResult>
      <dv-empty-search-result class="no-search-result"></dv-empty-search-result>
    </ng-template>
  </mat-tab-group>
</ng-template>
<dv-import-progress></dv-import-progress>
<dv-file-set-list-detail
  class="file-details"
  [ngClass]="showFileDetails ? 'file-details-visible' : 'file-details-hidden'"
  [fileDetails]="fileDetails"
  (hideFileDetails)="onHideFileDetails()"
></dv-file-set-list-detail>
