import { Component, Inject } from "@angular/core";
import { Overlay, OverlayModule } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { CONTAINER_DATA } from "./Injector";
import { SearchableSelectService } from "./searchable-select.service";


@Component({
    selector: 'dv-searchable-select-overlay',
    templateUrl: 'searchable-select-overlay.component.html',
})

export class SearchableSelectOverlayComponent {
    searchControl = new UntypedFormControl();
    filteredData: Observable<any>;
    data: any
    constructor(private overlay: Overlay, @Inject(CONTAINER_DATA) public dataFromComponent, private searchableSelectService : SearchableSelectService) {
        this.data = dataFromComponent;
    }

    ngOnInit() {
        this.filteredData = this.searchControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filter(value))
            );
    }

    private _filter(value: any): any[] {
        const filterValue = value.toLowerCase();

        return this.data.filter(data => data.name.toLowerCase().includes(filterValue) || data.value == filterValue);
    }


    choose(value) {
        this.searchableSelectService.setSelectedValue(value);
    }

    close() {
        this.searchableSelectService.setCloseOverlay();
    }

}
