import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { OptionModel } from '@dvm/components';
import { GridSettings } from '../../step-settings.types';

@Component({
  selector: 'dv-settings-pane',
  templateUrl: 'settings-pane.component.html',
  styleUrls: ['settings-pane.component.scss'],
})
export class SettingsPaneComponent implements OnChanges {
  @Input() showPane = false;
  @Input() loading: boolean;
  @Input() gridSettings: GridSettings;
  @Output() hideSettingsPane = new EventEmitter<GridSettings>();
  @Output() gridSettingsChange = new EventEmitter<GridSettings>();

  gridSizeOptions: OptionModel[] = [];
  tempGridSettings: GridSettings;

  private readonly GRID_SIZES: number[] = [10, 20, 30];

  constructor() {
    this.GRID_SIZES.forEach((size) =>
      this.gridSizeOptions.push(this.createOption(String(size)))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { showPane } = changes;

    if (
      showPane?.currentValue === true &&
      showPane?.currentValue !== showPane?.previousValue &&
      this.gridSettings
    ) {
      this.tempGridSettings = { ...this.gridSettings };
      this.gridSettingsChange.emit(this.tempGridSettings);
    }
  }

  closePane(save = false): void {
    if (save) {
      this.hideSettingsPane.emit({ ...this.tempGridSettings });
    } else {
      this.hideSettingsPane.emit();
    }
  }

  onGridChange(): void {
    this.gridSettingsChange.emit(this.tempGridSettings);
  }

  private createOption(value: string): OptionModel {
    return {
      key: value,
      label: value,
      checked: undefined,
      disabled: undefined,
    };
  }
}
