<div class="header">
  <div>
    <dvm-text h4>{{ 'Choose adjustments' | dvt }}</dvm-text>
    <dvm-text label>
      {{
        '_adjustment_choice_description'
          | dvt
            : '_Check the adjustments that you want to use to customise your prescription file'
      }}
    </dvm-text>
  </div>
  <dvm-button ghost (click)="close()">
    <mat-icon>close</mat-icon>
  </dvm-button>
</div>
<div class="adjustment-list">
  <ng-container *ngFor="let adjustment of adjustments | keyvalue: noSort">
    <dvm-checkbox
      class="adjustment-list-item"
      *ngIf="adjustment.key !== ADJUSTMENT_TYPE.cutoff"
      size="regular"
      [checked]="adjustment.value.added"
      (change)="change(adjustment)"
    >
      {{
        '_prescription_adjustment_' + adjustment.key | dvt: '_' + adjustment.key
      }}
    </dvm-checkbox>
  </ng-container>
</div>
<dvm-button (click)="addAdjustments()" [disabled]="!changed">
  {{ 'Add adjustments' | dvt }}
</dvm-button>
