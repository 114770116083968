<button class="back-button" mat-flat-button (click)="onBackClick()">
  <img class="back-button-icon" src="assets/images/chevron_left.svg" />
  {{ 'Back to files' | dvt }}
</button>
<dv-filter-section
  *ngIf="filter"
  [@filterGrowAnimation]
  class="filter-section"
  [filterValues]="filterValues"
  [availableFilterValues]="availableFilterValues"
  (filterChange)="setFilter($event)"
  (filterCleared)="onFilterCleared()"
></dv-filter-section>
<div class="section-header">
  <div class="section-header-left">
    <img class="section-header-left-icon" src="assets/images/file.svg" />
    <div class="details">
      <div class="details-name">{{ fileDetails?.name }}</div>
      <div class="details-path">
        <span class="details-path-prefix">
          {{ 'fileshare / files / ' | dvt }}
        </span>
        {{ fileDetails?.name }}
      </div>
      <button
        mat-stroked-button
        class="details-status-button"
        (click)="onFileStatusClick()"
        [disabled]="!statusLog"
      >
        <ng-container *ngIf="currentImportStatus">
          <img
            *ngIf="currentImportStatus === fileStatus.Succeeded"
            class="details-status-button-icon"
            src="assets/images/icon-success.svg"
          />
          <img
            *ngIf="currentImportStatus === fileStatus.Error"
            class="details-status-button-icon"
            src="assets/images/icon-error.svg"
          />
          <img
            *ngIf="currentImportStatus === fileStatus.Failed"
            class="details-status-button-icon"
            src="assets/images/icon-warning.svg"
          />
        </ng-container>
        {{ 'Show file status' | dvt }}
      </button>
    </div>
  </div>
  <div class="section-header-right">
    <mat-form-field class="search-form-field">
      <mat-icon matPrefix>search</mat-icon>
      <input
        matInput
        [formControl]="freeSearchCtrl"
        type="text"
        [placeholder]="'Search' | dvt"
      />
    </mat-form-field>
    <div class="filter-search">
      <button
        class="filter-search-button"
        (click)="filter = !filter"
        mat-flat-button
      >
        <mat-icon
          [matBadge]="numberOfActiveFilters"
          [matBadgeHidden]="!(numberOfActiveFilters > 0)"
          matBadgeSize="small"
          matBadgePosition="after"
        >
          filter_alt
        </mat-icon>
        <span>{{
          filter ? ('Hide filter' | dvt) : ('Show filter' | dvt)
        }}</span>
      </button>
    </div>
  </div>
</div>

<mat-table
  class="mat-elevation-z8 file-table file-table-striped"
  [dataSource]="dataSource"
  matSort
  (matSortChange)="onSortChange($event)"
>
  <ng-container matColumnDef="checkbox">
    <mat-header-cell class="no-pointer" *matHeaderCellDef>
      <mat-checkbox
        (change)="selectAllFiles($event.checked)"
        (click)="$event.stopPropagation()"
      ></mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      <mat-checkbox
        (change)="checkedChange(file.fileId)"
        [checked]="selectedFiles.includes(file.fileId)"
        (click)="$event.stopPropagation()"
      ></mat-checkbox>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="COLUMN_KEYS.NAME">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'Name' | dvt }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.NAME"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      <div class="file-name-text" [matTooltip]="file.name">
        {{ file.name }}
      </div>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="COLUMN_KEYS.TYPE">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'Type' | dvt }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.TYPE"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      {{ getFileType(file) }}
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="COLUMN_KEYS.CUSTOMER">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ '__metadata_owner' | dvt: '_Customer' }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.CUSTOMER"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      <div class="value-ellipse">
        {{ getMetaData(file, metaData.CUSTOMER) }}
      </div>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="COLUMN_KEYS.FARM">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ '__metadata_farm' | dvt: '_Farm' }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.FARM"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      <div class="value-ellipse">
        {{ getMetaData(file, metaData.FARM) }}
      </div>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="COLUMN_KEYS.FIELD">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ '__metadata_field' | dvt: '_Field' }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.FIELD"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      <div class="value-ellipse">
        {{ getMetaData(file, metaData.FIELD) }}
      </div>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="COLUMN_KEYS.CREATED">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ '__metadata_created' | dvt: '_Created' }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.CREATED"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      <ng-container
        *ngIf="getMetaData(file, metaData.CREATED) !== '-'; else noDate"
      >
        {{ getMetaData(file, metaData.CREATED) | date: 'short' }}
      </ng-container>
      <ng-template #noDate>
        {{ '-' }}
      </ng-template>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="COLUMN_KEYS.MORE">
    <mat-header-cell class="no-pointer" *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let file">
      <button
        (click)="$event.stopPropagation()"
        mat-icon-button
        [matMenuTriggerFor]="fileContextMenu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #fileContextMenu="matMenu" xPosition="before">
        <button
          (click)="fileService.requestDeleteGeodata(file.fileId)"
          mat-menu-item
        >
          <mat-icon>delete</mat-icon>
          {{ 'Hide in CropMAP' | dvt }}
        </button>
        <button
          (click)="fileService.openGeodataMetadata(file.fileId)"
          mat-menu-item
        >
          <mat-icon>edit</mat-icon>
          {{ 'Edit' | dvt }}
        </button>
        <button
          (click)="fileService.openGeodataDetail(file.fileId)"
          mat-menu-item
        >
          <mat-icon>description</mat-icon>
          {{ 'Imported data details' | dvt }}
        </button>
        <button
          (click)="fileService.reimportGeoData(file.fileId)"
          mat-menu-item
        >
          <mat-icon>refresh</mat-icon>
          {{ 'Reimport' | dvt }}
        </button>
      </mat-menu>
    </mat-cell>
  </ng-container>
  <mat-header-row
    class="table-header-row"
    *matHeaderRowDef="displayedColumns"
  ></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<div class="bottom-action-area">
  <div class="file-options">
    <button
      mat-stroked-button
      (click)="fileService.shareFiles()"
      [disabled]="!selectedFiles.length"
    >
      {{ 'Share' | dvt }}
      {{ selectedFiles.length ? ' (' + selectedFiles.length + ')' : '' }}
      <img class="bulk-button-icon" src="assets/images/expand-more.svg" />
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="openShareFileDialog()"
      [disabled]="!selectedFiles.length"
    >
      {{ 'Apply' | dvt }}
    </button>
  </div>
  <mat-paginator
    #filePaginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [aria-label]="'Number of files per page' | dvt"
  ></mat-paginator>
</div>
