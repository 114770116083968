import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  DvAuthService,
  DvClientService,
  DvToolbarTranslateService,
} from '@dv/toolbar-msal';
import { APP_PATH } from 'app/app.types';
import { CarouselItem } from 'app/login-page/login-page.types';
import { lastValueFrom, map, take } from 'rxjs';

@Component({
  selector: 'dv-login',
  templateUrl: 'login-page.component.html',
  styleUrls: ['login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  unauth: boolean;
  carousel: CarouselItem[] = [];
  currentItem: CarouselItem = null;
  offset = 0;
  widthOfImg = 540;
  timeoutId = null;
  loading = true;

  constructor(
    private router: Router,
    private translateService: DvToolbarTranslateService,
    private activatedRoute: ActivatedRoute,
    private zone: NgZone,
    private dvClientService: DvClientService,
    private dvAuthService: DvAuthService
  ) {}

  ngOnInit(): void {
    this.setUpCarousel();
    this.handleRouteParams();
  }

  private async handleRouteParams(): Promise<void> {
    const params = await lastValueFrom(
      this.activatedRoute.queryParams.pipe(take(1))
    );
    if (params && params['status'] === '401') {
      this.unauth = true;
      this.loading = false;
    } else {
      this.handleRouteParamsWithNoStatusParam();
    }
  }

  private async handleRouteParamsWithNoStatusParam(): Promise<void> {
    const isLoggedIn = await lastValueFrom(
      this.dvAuthService.isLoggedIn$.pipe(take(1))
    );

    if (isLoggedIn) {
      const clientId = await lastValueFrom(
        this.dvClientService.client$.pipe(
          take(1),
          map((client) => client.id)
        )
      );

      this.router.navigate([APP_PATH.CLIENT, clientId]);
    } else {
      this.zone.run(() => {
        this.loading = false;
      });
    }

    this.timeoutId = window.setTimeout(() => {
      this.move(-1);
    }, 5000);
  }

  ngOnDestroy(): void {
    window.clearTimeout(this.timeoutId);
  }

  login(): void {
    this.dvAuthService.signIn();
  }

  move(dir: number): void {
    const maxWidth = (this.carousel.length - 1) * this.widthOfImg;
    this.offset += dir * this.widthOfImg;
    if (this.offset > maxWidth) {
      this.offset = 0;
    } else if (this.offset < 0) {
      this.offset = maxWidth;
    }

    window.clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(() => {
      this.move(dir);
    }, 5000);
  }

  isNo(): boolean {
    return this.translateService.getLang() === 'no';
  }

  isSv(): boolean {
    return this.translateService.getLang() === 'sv';
  }

  isNl(): boolean {
    const lang = this.translateService.getLang();
    return lang === 'nl' || lang === 'de' || lang === 'fr';
  }

  private setUpCarousel(): void {
    this.carousel.push(
      {
        src: 'assets/images/backgrounds/image1@1x.png',
        dir: 'left',
      },
      {
        src: 'assets/images/backgrounds/image2@1x.png',
        dir: 'left',
      },
      {
        src: 'assets/images/backgrounds/image3@1x.png',
        dir: 'left',
      }
    );
    this.currentItem = this.carousel[0];
  }
}
