
    <mat-list-item *ngIf="!autoload">
        <button mat-button  (click)="showBlock()">{{'Visa block' | dvt}}</button>
    </mat-list-item>
    
<div class="block-info" [ngClass]="{'active':loading}">
    <mat-spinner style="width:50px; height:50px; display:inline-block; vertical-align:middle;"></mat-spinner> {{'Laddar block ...' | dvt}}
</div>
<div class="block-info" [ngClass]="{'active':needZoom}" >
    {{'Zooma in för att välja block' | dvt}}
</div>