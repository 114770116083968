<div *ngIf="loading" class="loading-area">
  <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
</div>

<div class="content">
  <dv-base-fields
    [selectedFieldIds]="selectedIds"
    [clientId]="clientId"
  ></dv-base-fields>
</div>
