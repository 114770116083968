import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'dv-machine-connection-header',
  templateUrl: 'machine-connection-header.component.html',
  styleUrls: ['machine-connection-header.component.scss'],
})
export class MachineConnectionHeaderComponent {
  @Output() closeDialog = new EventEmitter<void>();
}
