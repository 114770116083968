<div class="main">
  <button *ngIf="!loading" mat-dialog-close mat-mini-fab color="primary">
    <mat-icon>close</mat-icon>
  </button>
  <h2 mat-dialog-title>CropMAP</h2>
  <div class="header">&nbsp;</div>
  <div class="content" *ngIf="!isAgrometius; else agrometiusErrors">
    <p>
      {{
        '_insufficient_rights_contact_support'
          | dvt
            : '_Your subscription is not configured for this functionality. Please contact support regarding your subscription.'
      }}
    </p>
    <p *ngIf="!upgradeError && !contactSupport">
      {{
        '_insufficient_rights_info_trial'
          | dvt
            : '_To gain access temporarily, click the Start Trial Period button below'
      }}
    </p>
  </div>
  <ng-template #agrometiusErrors>
    <div
      class="content"
      *ngIf="isAgrometius && !upgradeError && !contactSupport"
    >
      <p>{{ '_insufficient_rights_info_{0}' | dvt: functionName }}</p>
      <p>{{ '_insufficient_rights_price_{0}' | dvt: price }}</p>
    </div>
    <div class="content" *ngIf="contactSupport || upgradeError">
      <p *ngIf="contactSupport">
        {{
          '_insufficient_rights_upgrade_error_400'
            | dvt
              : '_You have already tried the full version of CropMap, contact the support to start a subscription'
        }}
      </p>
      <p *ngIf="upgradeError">
        {{
          '_insufficient_rights_upgrade_error'
            | dvt
              : '_Something went wrong. Please contact the support regarding your subscription'
        }}
      </p>
    </div>
  </ng-template>
  <mat-dialog-actions class="actions">
    <button [mat-dialog-close]="true" mat-stroked-button>
      {{ 'Cancel' | dvt }}
    </button>
    <button
      *ngIf="!contactSupport && !upgradeError"
      mat-raised-button
      color="primary"
      (click)="save()"
    >
      {{ 'Order' | dvt }}
    </button>
  </mat-dialog-actions>
</div>
<div class="loading-area" *ngIf="loading">
  <mat-spinner [diameter]="100"></mat-spinner>
</div>
