<div class="wrapper">
  <dv-map
    [mapName]="mapName"
    [multiSelect]="true"
    [fullScreen]="false"
  ></dv-map>
  <div class="column info dv-background mat-elevation-z2">
    <div class="row">
      <img src="assets/images/icon-finger.svg" />
      <span class="dv-text-color">{{
        '_analyze_pick_field'
          | dvt
            : '_Click on one or more fields on which you want to compare maps'
      }}</span>
    </div>
    <button
      [disabled]="!featuresSelected || !interpolationProperties"
      color="primary"
      mat-raised-button
      (click)="next()"
    >
      {{ 'Next' | dvt }}
    </button>
  </div>
</div>
