import { Injectable } from '@angular/core';
import MarkerClusterer from '@google/markerclusterer';
import * as turf from '@turf/turf';

@Injectable({
  providedIn: 'root',
})
export class MapUtilService {
  getValuePoints(
    points: GeoJSON.FeatureCollection,
    propertyKey = 'showValue'
  ): ValuePointModel[] {
    const valuePoints: ValuePointModel[] = points.features
      .filter((point) => !!point.properties[propertyKey])
      .map((pt) => {
        const centerpos = turf.center(<turf.Geometry>pt.geometry);
        return <ValuePointModel>{
          pos: new google.maps.LatLng(
            centerpos.geometry.coordinates[1],
            centerpos.geometry.coordinates[0]
          ),
          value: pt.properties[propertyKey],
        };
      });

    return valuePoints;
  }

  getValuePointsInsideInterpolation(
    points: ValuePointModel[],
    features: Feature[]
  ): ValuePointModel[] {
    return points.filter((point) => {
      let inside = false;
      const polygons = this.splitMultiPolygons(features);

      for (const feature of polygons) {
        inside = turf.booleanContains(
          <turf.Feature>feature,
          turf.point([point.pos.lng(), point.pos.lat()])
        );
        if (inside) {
          break;
        }
      }
      return inside;
    });
  }

  splitMultiPolygons(features: Feature[]): Feature[] {
    const polygons: Feature[] = [];

    features.forEach((feature) => {
      if (feature.geometry.type !== 'MultiPolygon') {
        polygons.push(feature);
      } else {
        feature.geometry.coordinates.forEach((coordinates) => {
          const polygon: Feature = {
            ...feature,
            geometry: {
              coordinates,
              type: 'Polygon',
            },
          };

          polygons.push(polygon);
        });
      }
    });

    return polygons;
  }

  getValuePointLabels(points: ValuePointModel[]): google.maps.Marker[] {
    return points.map((p) => {
      return new google.maps.Marker({
        position: p.pos,
        label: {
          text: String(p.value),
          fontSize: '20px',
          className: 'data-point-label',
        },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 1,
          labelOrigin: new google.maps.Point(0, -20),
        },
        zIndex: 500,
      });
    });
  }

  drawValuePointLabelsOnMap(
    markers: google.maps.Marker[],
    map: google.maps.Map<Element>
  ): MarkerClusterer {
    const clusterOpts = {
      imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      maxZoom: 15,
    };

    return new MarkerClusterer(map, markers, clusterOpts);
  }
}

export type Feature = GeoJSON.Feature<
  GeoJSON.Geometry,
  {
    [name: string]: unknown;
  }
>;

export interface ValuePointModel {
  pos: google.maps.LatLng;
  value: string;
}
