<mat-list-item *ngIf="!isActive">
  <button
    mat-button
    [disabled]="selectedFeature === null"
    (click)="toggleActive()"
  >
    <img
      class="map-split-area-non-distance-image"
      src="assets/images/icon-split-area.svg"
      *ngIf="splitMode !== 'distance'"
    />
    <img
      class="map-split-area-distance-image"
      src="assets/images/icon-split-distance.svg"
      *ngIf="splitMode === 'distance'"
    />
    {{ splitMode === 'distance' ? splitDistance : splitArea }}
  </button>
</mat-list-item>
<div class="info dv-maps-tool" *ngIf="isActive">
  <div class="content">
    <div>
      <mat-form-field *ngIf="splitMode === 'distance'">
        <input
          matInput
          placeholder="{{ 'Distance from edge' | dvt }}"
          type="number"
          [(ngModel)]="targetDistance"
          name="distance"
          autocomplete="off"
        />
        <span matSuffix>m</span>
      </mat-form-field>
      <mat-form-field *ngIf="splitMode === 'areal'">
        <input
          matInput
          placeholder="{{ 'Area' | dvt }}"
          type="number"
          [(ngModel)]="targetArea"
          name="areal"
          autocomplete="off"
        />
        <span matSuffix>{{ siteService.getAreaUnit() | async }}</span>
      </mat-form-field>
      <div
        class="dv-warn map-split-area-warning-wrapper"
        *ngIf="splitMode === 'areal' && selectedArea < targetArea"
      >
        <mat-icon class="map-split-area-warning-icon">warning</mat-icon>
        {{ 'Chosen hectare is bigger then the parcel' | dvt }}
      </div>
    </div>
    <div
      *ngIf="
        parts.length < 2 && (targetDistance !== null || targetArea !== null)
      "
    >
      {{ 'Click aside the parcel to split' | dvt }}
    </div>
    <div
      *ngIf="
        splitMode === 'distance' && targetDistance === null && parts.length < 2
      "
    >
      {{ 'Provide splitdistance in meters' | dvt }}
    </div>
    <div
      *ngIf="splitMode === 'areal' && targetArea === null && parts.length < 2"
    >
      {{ 'Provide splitdistance in hectare' | dvt }}
    </div>
    <dv-map-field-part
      [skifteId]="skifteId"
      [parts]="parts"
      (save)="onSave($event)"
      (cancel)="toggleActive()"
    ></dv-map-field-part>
  </div>
</div>
