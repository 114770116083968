<div>
  <h1 mat-dialog-title>
    {{ kategori }}
  </h1>
  <p>
    {{ anteckning }}
  </p>

  <img [src]="img" (click)="openUrl()" *ngIf="img" />
</div>
<div class="buttons">
  <button mat-stroked-button (click)="edit()">
    <mat-icon>edit</mat-icon> {{ 'Edit' | dvt }}
  </button>
  <button mat-raised-button color="warn" (click)="deleteDetail()">
    <mat-icon>delete</mat-icon> {{ 'Delete' | dvt }}
  </button>
</div>
