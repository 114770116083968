<mat-button-toggle-group
  class="toggle-group"
  (change)="markerToggle($event.value)"
>
  <mat-button-toggle
    class="toggle"
    [matTooltip]="'Add marker to all maps'"
    [matTooltipShowDelay]="500"
    [checked]="placeMarker"
    [value]="true"
  >
    <mat-icon class="material-icons-outlined toggle-icon">
      add_location
    </mat-icon>
  </mat-button-toggle>
  <mat-button-toggle
    class="toggle"
    [matTooltip]="'Remove marker from all maps'"
    [matTooltipShowDelay]="500"
    [checked]="!placeMarker"
    [value]="false"
  >
    <mat-icon class="material-icons-outlined toggle-icon">
      location_off
    </mat-icon>
  </mat-button-toggle>
</mat-button-toggle-group>
