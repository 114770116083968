<div class="content">
    <div class="title">{{ 'Fileshare' | dvt }}</div>
    <div class="subtitle">{{ 'Upload your first files and start sharing data' | dvt }}</div>
    <mat-card
        appearance="outlined"
        class="card"
        matRipple
        (click)="onClick()"
    >
        <mat-icon class="card-icon">cloud_upload</mat-icon>
        <div class="card-title">{{ 'Upload your first files' | dvt }}</div>
    </mat-card>
</div>
