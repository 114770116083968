<mat-stepper #stepper animationDuration="0ms" class="dialog-stepper">
  <mat-step>
    <dv-machine-connection-header (closeDialog)="onCloseDialog()">
      {{ 'Initiating' | dvt }}
    </dv-machine-connection-header>
    <div class="content step-init">
      <dvm-text h4>{{ initStatus | dvt }}</dvm-text>
      <mat-progress-bar
        mode="determinate"
        [value]="initPercentage"
      ></mat-progress-bar>
    </div>
    <dv-machine-connection-actions
      [loading]="loading"
    ></dv-machine-connection-actions>
  </mat-step>

  <mat-step>
    <dv-machine-connection-header (closeDialog)="onCloseDialog()">
      {{
        '_provider_select' | dvt: '_Select providers you have an account with'
      }}
    </dv-machine-connection-header>
    <div class="content">
      <dvm-text>
        {{
          '_provider_info'
            | dvt
              : '_Please note that you first need to have created an account with your provider for the connection to work.'
        }}
      </dvm-text>
      <div class="manufacture-list">
        <dvm-checkbox
          *ngFor="let provider of providers"
          [disabled]="loading"
          [checked]="provider.isConnected"
          (change)="onChange(provider, $event.target.checked)"
        >
          {{ provider.name }}
        </dvm-checkbox>
      </div>
    </div>
    <dv-machine-connection-actions [loading]="loading">
      <dvm-button [disabled]="loading" (click)="createConnection()">
        {{ 'Create connection' | dvt }}
      </dvm-button>
    </dv-machine-connection-actions>
  </mat-step>

  <mat-step>
    <dv-machine-connection-header (closeDialog)="onCloseDialog()">
      {{ 'Sign in to the provider' | dvt }}
    </dv-machine-connection-header>
    <div content class="content">
      <dvm-text>
        {{
          '_provider_sign_in'
            | dvt
              : '_You need to sign in to your account with the provider. The sign in opens in a new window. Once the sign in is complete, your machines will be listed here.'
        }}
      </dvm-text>
    </div>
    <dv-machine-connection-actions [loading]="loading">
      <dvm-button secondary [disabled]="loading" (click)="toProviders()">
        {{ 'Select providers' | dvt }}
      </dvm-button>
      <dvm-button [disabled]="loading" (click)="signIn()">
        {{ 'Sign in to provider' | dvt }}
        <mat-icon right>tab</mat-icon>
      </dvm-button>
    </dv-machine-connection-actions>
  </mat-step>

  <mat-step>
    <dv-machine-connection-header (closeDialog)="onCloseDialog()">
      {{ 'My connected machines' | dvt }}
    </dv-machine-connection-header>
    <div content class="content step-machines">
      <dvm-text>
        {{
          '_machine_select'
            | dvt
              : '_Select the machine you want to send your prescription file to:'
        }}
      </dvm-text>
      <dvm-radio-button-group
        class="machine-list"
        [disabled]="loading"
        (change)="selectedMachine = $event.value"
      >
        <dvm-radio-button
          size="regular"
          *ngFor="let machine of machines"
          [value]="machine"
        >
          {{ machine.name }}
        </dvm-radio-button>
      </dvm-radio-button-group>
    </div>
    <dv-machine-connection-actions [loading]="loading">
      <dvm-button secondary [disabled]="loading" (click)="toProviders()">
        {{ 'Select providers' | dvt }}
      </dvm-button>
      <dvm-button
        [disabled]="loading || !selectedMachine"
        (click)="sendToMachine()"
      >
        {{ 'Send prescription file' | dvt }}
      </dvm-button>
    </dv-machine-connection-actions>
  </mat-step>

  <mat-step>
    <dv-machine-connection-header (closeDialog)="onCloseDialog()">
      {{ 'Prescription file sent' | dvt }}
    </dv-machine-connection-header>
    <div class="content">
      <dvm-text>
        {{ successText }}
      </dvm-text>
      <dvm-text>{{ 'Much pleasure!' | dvt }}</dvm-text>
    </div>
    <dv-machine-connection-actions [loading]="loading">
      <dvm-button secondary (click)="onCloseDialog()">
        {{ 'Close' | dvt }}
      </dvm-button>
    </dv-machine-connection-actions>
  </mat-step>
</mat-stepper>
