<dv-fileupload
  (files)="files($event)"
  singleFileOnly="true"
  #fileUpload
  style="margin-bottom: 20px"
></dv-fileupload>

<div *ngIf="needAdditionalFiles" style="margin-bottom: 20px">
  <span *ngIf="soilsamplingFiles?.shp === null">{{
    '_markk_saknarpunkter'
      | dvt: '_GPS punkter saknas, ladda upp en .shp fil för att matcha punkter'
  }}</span>
  <span *ngIf="soilsamplingFiles?.shp !== null">{{
    '_markk_saknarpunkter_not_shp'
      | dvt: '_Analysis file is missing. Upload the remaining file'
  }}</span>
  <mat-chip-listbox>
    <mat-chip-option
      selected
      [disableRipple]="true"
      color="{{ soilsamplingFiles?.shp === null ? 'warn' : '' }}"
    >
      <mat-icon *ngIf="soilsamplingFiles?.shp !== null">done</mat-icon> *.shp
      {{ 'fil' | dvtl }}
    </mat-chip-option>
    <mat-chip-option
      selected
      [disableRipple]="true"
      color="{{ soilsamplingFiles?.dbf === null ? 'warn' : '' }}"
    >
      <mat-icon *ngIf="soilsamplingFiles?.dbf !== null">done</mat-icon> *.dbf
      {{ 'fil' | dvtl }}
    </mat-chip-option>
    <mat-chip-option
      selected
      [disableRipple]="true"
      color="{{ soilsamplingFiles?.txt === null ? 'warn' : '' }}"
    >
      <mat-icon *ngIf="soilsamplingFiles?.txt !== null">done</mat-icon> *.csv /
      *.txt {{ 'fil' | dvtl }}
    </mat-chip-option>
  </mat-chip-listbox>
</div>

<div class="col" style="height: 135px">
  <h3 *ngIf="skiften && skiften.length > 0">
    {{ 'Found {0} fields' | dvt: skiften.length }}
  </h3>
  <div *ngIf="message.length > 0">
    <span style="color: red" *ngFor="let m of message">{{ m.message }}</span>
  </div>
  <div *ngIf="skiften && skiften.length > 0 && brukenh.length > 1" class="col">
    <span>{{ 'Choose farm to save fields at' | dvt }}</span>
    <mat-form-field appearance="outline">
      <mat-select [(ngModel)]="selectedBrukId">
        <mat-option *ngFor="let b of brukenh" [value]="b.id">
          {{ b.namn }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="col">
  <button
    style="margin-bottom: 20px"
    color="accent"
    (click)="done()"
    [disabled]="!skiften || skiften.length === 0"
    mat-raised-button
  >
    {{ 'Next' | dvt }}
  </button>
</div>
