import { Directive, Output, EventEmitter, ElementRef } from '@angular/core';

@Directive({
  selector: '[dvFiledrop]'
})
export class FiledropDirective {


  @Output('changed') fileDroped = new EventEmitter<FileList>();

  constructor(el: ElementRef<HTMLDivElement>) {

    el.nativeElement.addEventListener('dragover', (e: DragEvent) => { this.dragOver(e, el); }, false);
    el.nativeElement.addEventListener('drop', (e: DragEvent) => { this.handleDrop(e, el); }, false);
    el.nativeElement.addEventListener('dragleave', (e: DragEvent) => { this.dragLeave(e, el); }, false);
  }

  handleDrop(event: DragEvent, el: ElementRef) {
    event.preventDefault();
    event.stopPropagation();
    this.fileDroped.emit(event.dataTransfer.files);
    el.nativeElement.classList.remove('active');
  }

  dragOver(event: DragEvent, el: ElementRef<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
    el.nativeElement.classList.add("active");
  }

  dragLeave(event: DragEvent, el: ElementRef<HTMLDivElement>) {
    el.nativeElement.classList.remove('active');
  }
}
