<mat-card appearance="outlined">
    <div *ngIf="unauth" class="error">
        {{'A CropMAP licens is required' | dvt}}
    </div>
    <div class="row">
        <div class="info">
            <div>
                <h3>{{'Welcome to CropMAP'|dvt}}</h3>
                {{'_welcome_text_login'|dvt}}

                <h3> {{'Do you want to know more about CropMAP'|dvt}}?</h3>
                {{'_know_more_login'|dvt}}
            </div>
            <div>
                <a *ngIf="!isNo() && !isNl()" href="https://datavaxt.com/sv/produkter/cropmap/" target="_blank"
                    mat-raised-button color="primary">{{'Read more'|dvt}}</a>
                <a *ngIf="isNo()" href="https://datavaxt.com/no/produkter/cropmap/" target="_blank" mat-raised-button
                    color="primary">{{'Read more'|dvt}}</a>
                <a *ngIf="isNl()" href="https://www.taakkaart.nl/MoreInfo/SensorData" target="_blank" mat-raised-button
                    color="primary">{{'Read more'|dvt}}</a>
                <button mat-raised-button color="accent" (click)="login()">{{'Sign in'|dvt}}</button>

            </div>
        </div>
        <div class="carousel">

            <div class="carousel-imgs"
                [ngStyle]="{width:(carousel.length*widthOfImg)+'px', transform:'translate(-'+offset+'px,0)'}">
                <img [src]="item.src" [style.width]="widthOfImg+'px'" *ngFor="let item of carousel" />
            </div>
            <span class="nav left" (click)="move(1)"></span>
            <span class="nav right" (click)="move(-1)"></span>

            <div class="carousel-dots">
                <span *ngFor="let item of carousel; let index=index;"
                    class="carousel-dot {{offset===(carousel.length-index-1)*widthOfImg?'active':''}}"></span>

            </div>
        </div>
    </div>
</mat-card>

<div class="loading" *ngIf="loading">
  <div class="sk-cube-grid">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>
