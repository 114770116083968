<div class="import-progress" *ngIf="isImportProgress">
  <div class="import-progress-text">
    {{ '_filestatus_' + status | dvt: '_' + status }}
  </div>
  <mat-progress-bar
    class="import-progress-bar"
    mode="determinate"
    [value]="progress"
  ></mat-progress-bar>
</div>
<div
  class="import-status"
  *ngIf="isImportStatus"
  [ngClass]="{ 'import-status-error': isError }"
>
  <img
    class="import-status-icon"
    src="assets/images/icon-success.svg"
    *ngIf="!isErrorOrWarning"
  />
  <img
    class="import-status-icon"
    src="assets/images/icon-error.svg"
    *ngIf="isError"
  />
  <img
    class="import-status-icon"
    src="assets/images/icon-warning.svg"
    *ngIf="isWarning"
  />
  <div class="import-status-text">
    {{ '_filestatus_' + status | dvt: '_' + status }}
  </div>
</div>
