<div class="table-view">
  <div class="box" [class.four-rows]="filterActive">
    <mat-tab-group
      [selectedIndex]="activeIndex"
      (selectedTabChange)="tabChanged($event)"
      disablePagination="true"
      class="tabs"
      mat-align-tabs="start"
      mat-stretch-tabs="false"
    >
      <mat-tab>
        <ng-template mat-tab-label>{{ 'Fields' | dvt }}</ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>{{ 'Markings' | dvt }}</ng-template>
      </mat-tab>
    </mat-tab-group>
    <div class="filtering">
      <div class="filter-search">
        <div class="year" *ngIf="getYear() && getYears()?.length > 0">
          <mat-form-field appearance="outline">
            <mat-select
              #yearSelect
              disableOptionCentering
              [value]="getYear()"
              (selectionChange)="setYear($event.value)"
            >
              <button
                [style]="selectButtonStyle"
                (click)="createNewYear(newYearMax - 1, newYearMax)"
                *ngIf="newYearMax > 0 && activeIndex === INDEX_FIELDS"
              >
                {{ 'New year' | dvt }} ({{ newYearMax }})
              </button>
              <mat-option *ngIf="activeIndex !== INDEX_FIELDS" [value]="-1">
                - {{ 'All years' | dvt }} -
              </mat-option>
              <mat-option [value]="ar" *ngFor="let ar of getYears()">
                {{ ar }}
              </mat-option>
              <button
                [style]="selectButtonStyle"
                (click)="createNewYear(newYearMin + 1, newYearMin)"
                *ngIf="newYearMin > 0 && activeIndex === INDEX_FIELDS"
              >
                {{ 'New year' | dvt }} ({{ newYearMin }})
              </button>
            </mat-select>
          </mat-form-field>
        </div>

        <button
          (click)="toggleFilter()"
          [class.active]="filterActive"
          mat-stroked-button
        >
          {{ 'Filter' | dvt }}
        </button>
        <button
          mat-stroked-button
          *ngIf="activeIndex === INDEX_FIELDS"
          (click)="toggleAddField()"
          [class.active]="addField"
          class="add add-fields"
        >
          <mat-icon class="material-icons-round"> add_rounded </mat-icon>
          {{ 'Add' | dvt }}
        </button>
        <button
          mat-stroked-button
          *ngIf="activeIndex === INDEX_MARKINGS"
          (click)="addPoi()"
          class="add add-markings"
        >
          <mat-icon class="material-icons-round"> add_rounded </mat-icon>
          {{ 'Add' | dvt }}
        </button>
      </div>
    </div>
    <div class="filters" *ngIf="filterActive">
      <div class="search">
        <mat-icon>search</mat-icon>
        <mat-form-field appearance="outline">
          <input
            [placeholder]="'Search' | dvt"
            [(ngModel)]="currentSearchTerm"
            (mouseup)="detectSearchClear($event)"
            (keyup)="applyFilter($event)"
            matInput
            type="search"
        /></mat-form-field>
      </div>
      <div class="filters-container">
        <div class="filter-section" *ngIf="activeIndex === 0">
          <p class="strong">{{ 'Farm' | dvt }}</p>
          <div class="filters-options">
            <div *ngFor="let val of filterOptions.farms" class="option">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? toggleActiveFilters(val, 'farms') : null"
                [checked]="selectedFilterOptions.farms.includes(val)"
              ></mat-checkbox>
              <span>{{ val }}</span>
            </div>
          </div>
        </div>
        <div class="filter-section" *ngIf="activeIndex === 0">
          <p class="strong">{{ 'Crop' | dvt }}</p>
          <div class="filters-options">
            <div *ngFor="let val of filterOptions.crops" class="option">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? toggleActiveFilters(val, 'crops') : null"
                [checked]="selectedFilterOptions.crops.includes(val)"
              ></mat-checkbox>
              <span>{{ val }}</span>
            </div>
          </div>
        </div>
        <div class="filter-section" *ngIf="activeIndex === 1">
          <p class="strong">{{ 'Completed' | dvt }}</p>
          <div class="filters-options">
            <div class="option">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="
                  $event
                    ? toggleActiveFilters(OPTION_UTFORD_TRUE, 'utford')
                    : null
                "
                [checked]="
                  selectedFilterOptions.utford.includes(OPTION_UTFORD_TRUE)
                "
              ></mat-checkbox>
              <span>{{ 'Yes' | dvt }}</span>
            </div>
            <div class="option">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="
                  $event
                    ? toggleActiveFilters(OPTION_UTFORD_FALSE, 'utford')
                    : null
                "
                [checked]="
                  selectedFilterOptions.utford.includes(OPTION_UTFORD_FALSE)
                "
              ></mat-checkbox>
              <span>{{ 'No' | dvt }}</span>
            </div>
          </div>
        </div>
        <div class="filter-section" *ngIf="activeIndex === 1">
          <p class="strong">{{ 'Type' | dvt }}</p>
          <div class="filters-options">
            <div *ngFor="let val of filterOptions.markTypes" class="option">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? toggleActiveFilters(val, 'markTypes') : null"
                [checked]="selectedFilterOptions.markTypes.includes(val)"
              ></mat-checkbox>
              <span>{{ val }}</span>
            </div>
          </div>
        </div>
        <div class="filter-section" *ngIf="activeIndex === 2">
          <p class="strong">{{ 'Type' | dvt }}</p>
          <div class="filters-options">
            <div *ngFor="let val of filterOptions.fileTypes" class="option">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? toggleActiveFilters(val, 'fileTypes') : null"
                [checked]="selectedFilterOptions.fileTypes.includes(val)"
              ></mat-checkbox>
              <span>{{ '_groupname_' + val | dvt : '_' + val }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="parent">
      <div *ngIf="addField" class="add-field">
        <dv-field-page
          (cancel)="addField = false"
          (fieldSaved)="updateFields()"
        ></dv-field-page>
      </div>

      <div *ngIf="!addField" class="drag">
        <dv-table-skifte
          (fieldRemoved)="removeSkifteLayer()"
          (addFieldCalled)="toggleAddField()"
          [loading]="!firstFetchComplete"
          *ngIf="activeIndex === INDEX_FIELDS"
          [search]="currentSearchTerm"
          [filters]="selectedFilterOptions"
          [skiften]="skiften"
        ></dv-table-skifte>
        <dv-table-markings
          (markingAdded)="showPoi()"
          *ngIf="activeIndex === INDEX_MARKINGS"
          [filters]="selectedFilterOptions"
          [search]="currentSearchTerm"
          [map]="dvMap"
          (markingDeleted)="getMarkings()"
          [markings]="getMarkingsByYear()"
        ></dv-table-markings>
      </div>
    </div>

    <div class="bottom-container" *ngIf="activeIndex !== INDEX_MARKINGS">
      <span *ngIf="activeIndex !== INDEX_FIELDS">&nbsp;</span>
      <div class="toggle-all" *ngIf="activeIndex === INDEX_FIELDS">
        <mat-checkbox
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? toggleAll() : null"
          [checked]="hasFieldsSelected"
        >
        </mat-checkbox>
        <span *ngIf="!hasFieldsSelected">
          {{ 'Mark all fields' | dvt }}
        </span>
        <span *ngIf="hasFieldsSelected">
          {{ 'Unmark all fields' | dvt }}
        </span>
      </div>
      <div
        class="toggle-options"
        *ngIf="
          (activeIndex === INDEX_FILES && fileGroups?.length > 0) ||
          (activeIndex === INDEX_FIELDS && hasChoices)
        "
        (click)="toggleShowOptions()"
      >
        <span>
          {{
            showOptions ? ('Hide options' | dvt) : ('Show more options' | dvt)
          }}
        </span>
        <mat-icon>{{
          showOptions ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
        }}</mat-icon>
      </div>
    </div>
    <dv-interpolation-groups
      (groupsChanged)="setShowOptions($event)"
      *ngIf="!addField && hasFieldsSelected"
    ></dv-interpolation-groups>
  </div>
</div>
