import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { SkifteDetielsModel } from 'app/models/api.models';
import { DialogService } from 'app/components/dialog/dialog.service';
import { MapComponent } from 'app/components/map/map.component';
import {
  LAYER_NAME_BLOCK,
  LAYER_NAME_INTERPOLATION,
  LAYER_NAME_SKIFTEN,
  MapService,
} from 'app/components/map/map.service';
import { NewYearComponent } from 'app/components/new-year/new-year.component';
import { MarkeringModel } from 'app/models/models';
import { ClientService } from 'app/services/client.service';
import { combineLatest, interval, Subject } from 'rxjs';
import { debounce, first, takeUntil } from 'rxjs/operators';
import { MappingWizardService } from '../mapping-wizard.service';
import { TableSkifteComponent } from './tables/table-skifte/table-skifte.component';
import { ActivatedRoute } from '@angular/router';
import { TableFilterOptions } from './wizard-table.types';
import { PoiService } from 'app/components/poi/poi.service';
import { POI_STATES } from 'app/components/poi/poi.types';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-wizard-table',
  templateUrl: './wizard-table.component.html',
  styleUrls: ['./wizard-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(TableSkifteComponent) skifteTable: TableSkifteComponent;
  @ViewChild('yearSelect') yearSelect: MatSelect;

  loadingField = false;
  showOptions = false;
  skiften: SkifteDetielsModel[] = [];
  markings: MarkeringModel[] = [];
  filterTimeout: ReturnType<typeof setTimeout>;
  filterActive = false;
  firstFetchComplete = false;
  addField = false;
  selectedYear: number;
  availableYears: number[] = [];
  dvMap: MapComponent;
  INDEX_FIELDS = 0;
  INDEX_MARKINGS = 1;
  activeIndex = 0;
  filterOptions: TableFilterOptions;
  selectedFilterOptions: TableFilterOptions;
  currentSearchTerm = '';
  randomString = '!"#¤%&/()=?^*_:;>';
  selectedMarkingYear = -1;
  markingYears: number[];
  expandedRow: number;
  prevIndex = -1;
  newYearMin = -1;
  newYearMax = -1;
  OPTION_UTFORD_TRUE = 0;
  OPTION_UTFORD_FALSE = 1;
  hasChoices = false;
  selectButtonStyle = `
    font-size: 14px;
    line-height: 3em;
    height: 3em;
    background: none;
    border: none;
    padding: 0 1rem;
    cursor: pointer;
    font-family: Roboto, "Helvetica Neue", sans-serif;`;
  private _unsub$: Subject<void> = new Subject<void>();
  private emptyFilterOptions: TableFilterOptions = {
    farms: [],
    crops: [],
    markTypes: [],
    fileTypes: [],
    utford: [],
  };

  get hasFieldsSelected(): boolean {
    return !!this.skifteTable?.selectedFeatures?.features.length;
  }

  constructor(
    private clientSrv: ClientService,
    private mapService: MapService,
    private changeDetectorRef: ChangeDetectorRef,
    private wizSrv: MappingWizardService,
    public dialog: MatDialog,
    private dvTranslate: DvToolbarTranslateService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private poiService: PoiService
  ) {
    this.filterOptions = this.emptyFilterOptions;
    this.selectedFilterOptions = this.emptyFilterOptions;

    combineLatest([this.route.paramMap, this.clientSrv.clientAr()])
      .pipe(first())
      .subscribe(([params, clientYear]) => {
        const year = Number(params.get('year'));
        this.selectedYear = year ? year : clientYear;

        if (year && year !== clientYear) {
          this.clientSrv.setClientAr(year);
        }
      });
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  ngOnInit(): void {
    this.mapService
      .mainMap()
      .pipe(first())
      .subscribe((dvMap) => {
        this.dvMap = dvMap;
      });

    this.clientSrv
      .clientAr()
      .pipe(takeUntil(this._unsub$))
      .subscribe((ar) => {
        this.selectedYear = ar;
      });

    this.clientSrv
      .client()
      .pipe(takeUntil(this._unsub$))
      .subscribe((client) => {
        this.availableYears = client.tillgangligaAr.filter((ar) => ar > 0);
        if (this.availableYears.length === 0) {
          this.availableYears.push(new Date().getFullYear());
        }

        this.newYearMax =
          Math.max(...this.availableYears.filter((ar) => ar > 0)) + 1;
        this.newYearMin =
          Math.min(...this.availableYears.filter((ar) => ar > 0)) - 1;
      });

    this.wizSrv
      .getMarkingsYear()
      .pipe(takeUntil(this._unsub$))
      .subscribe((markYear) => {
        this.selectedMarkingYear = markYear;
      });
    this.wizSrv
      .getActiveIndex()
      .pipe(takeUntil(this._unsub$))
      .subscribe((index) => {
        this.activeIndex = index;
        if (this.prevIndex === -1) {
          this.prevIndex = index;
        }
      });
  }

  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.clientSrv.getSkiften(),
      this.mapService.mainMap().pipe(first()),
    ])
      .pipe(takeUntil(this._unsub$))
      .subscribe(([skiften]) => {
        this.skiften = skiften;
        if (!skiften) {
          return;
        }

        this.getFilterValues();
        setTimeout(() => {
          this.firstFetchComplete = this.clientSrv.skiftenIsFetched();
          this.changeDetectorRef.detectChanges();
        });
      });

    this.getMarkings();
  }

  getMarkings(): void {
    this.clientSrv
      .getAllPoi()
      .pipe(
        first(),
        debounce(() => interval(500))
      )
      .subscribe((poi) => {
        const arr: MarkeringModel[] = [];
        poi.forEach((p) => {
          arr.push({
            id: p.id,
            anteckning: p.anteckning,
            created: p.datum ? new Date(p.datum) : null,
            name: this.dvTranslate.t('Marking'),
            ownerId: p.skapadAv,
            category: p.kategori,
            brukEnhNamn: null,
            editable: true,
            utford: !!p.utford,
            ar: p.ar,
          });
        });

        this.markingYears = poi
          .map((p) => p.ar)
          .filter((x, i, a) => a.indexOf(x) === i)
          .sort((a, b) => (a > b ? -1 : 1));
        this.markings = arr;
        this.getFilterValues();
      });
  }

  toggleAddField(): void {
    this.addField = !this.addField;
    this.changeDetectorRef.detectChanges();
  }

  toggleFilter(): void {
    this.filterActive = !this.filterActive;
    this.changeDetectorRef.detectChanges();
  }

  getFilterValues(): void {
    const farms = [],
      crops = [],
      markTypes = [],
      fileTypes = [];
    this.skiften.forEach((skift) => {
      if (skift.brukEnhNamn && !farms.includes(skift.brukEnhNamn)) {
        farms.push(skift.brukEnhNamn);
      }
      if (skift.huvudgroda && !crops.includes(skift.huvudgroda.benamning)) {
        crops.push(skift.huvudgroda.benamning);
      }
    });
    this.markings.forEach((mk) => {
      if (mk['category'] && !markTypes.includes(mk['category'])) {
        markTypes.push(mk['category']);
      }
    });

    this.filterOptions = {
      crops,
      farms,
      markTypes,
      fileTypes,
    };
    this.changeDetectorRef.detectChanges();
  }

  toggleActiveFilters(filter, type): void {
    this.selectedFilterOptions[type].includes(filter)
      ? this.selectedFilterOptions[type].splice(
          this.selectedFilterOptions[type].indexOf(filter),
          1
        )
      : this.selectedFilterOptions[type].push(filter);

    this.selectedFilterOptions = Object.assign({}, this.selectedFilterOptions);
    this.changeDetectorRef.detectChanges();
  }

  toggleShowOptions(): void {
    this.showOptions = !this.showOptions;
    this.changeDetectorRef.detectChanges();
  }

  tabChanged(event): void {
    // this.clearSelected();
    this.addField = false;
    this.expandedRow = -1;
    this.wizSrv.setActiveIndex(event.index);
    this.detectSearchClear();
    this.interpolateByIndex();
    this.changeDetectorRef.detectChanges();
  }

  getYears(): number[] {
    switch (this.activeIndex) {
      case this.INDEX_FIELDS:
        return this.availableYears?.filter((year) => year > 0);
      case this.INDEX_MARKINGS:
        return this.markingYears?.filter((year) => year > 0);
      default:
        return this.availableYears?.filter((year) => year > 0);
    }
  }

  getYear(): number {
    switch (this.activeIndex) {
      case this.INDEX_FIELDS:
        return this.selectedYear;
      case this.INDEX_MARKINGS:
        return this.selectedMarkingYear;
      default:
        return this.selectedYear;
    }
  }

  setYear(year): void {
    if (!year) {
      return;
    }

    if (this.activeIndex === this.INDEX_FIELDS) {
      this.selectedYear = year;
      this.firstFetchComplete = false;
      this.clientSrv.setClientAr(year);
    } else if (this.activeIndex === this.INDEX_MARKINGS) {
      this.wizSrv.setMarkingsYear(year);
    }

    this.changeDetectorRef.detectChanges();
  }

  resetSelectedFilters(): void {
    this.selectedFilterOptions = this.emptyFilterOptions;
    this.currentSearchTerm = '';
    this.changeDetectorRef.detectChanges();
  }

  interpolateByIndex(): void {
    if (this.prevIndex === this.INDEX_FIELDS) {
      this.dvMap.removeLayer(LAYER_NAME_SKIFTEN);
    }
    this.prevIndex = this.activeIndex;
    this.dvMap.removeLayer(LAYER_NAME_INTERPOLATION);
    this.dvMap.removeLayer(LAYER_NAME_BLOCK);
    this.changeDetectorRef.detectChanges();
  }

  removeSkifteLayer(): void {
    this.clientSrv.reloadYear();
  }

  updateFields(): void {
    this.firstFetchComplete = false;
    this.clientSrv.loadSkiften();
    this.addField = false;
    this.changeDetectorRef.detectChanges();
  }

  getMarkingsByYear(): MarkeringModel[] {
    if (this.selectedMarkingYear === -1) {
      return this.markings;
    }

    return this.markings.filter(
      (mark) => !mark.ar || mark.ar === this.selectedMarkingYear
    );
  }

  setShowOptions(show: boolean): void {
    this.hasChoices = show;
    this.showOptions = show;
    this.changeDetectorRef.detectChanges();
  }

  toggleAll(): void {
    this.skifteTable.toggleAll();
    this.changeDetectorRef.detectChanges();
  }

  detectSearchClear(): void {
    this.currentSearchTerm = '';
    this.changeDetectorRef.detectChanges();
  }

  applyFilter(event: Event): void {
    this.currentSearchTerm = (event.target as HTMLInputElement).value;
    this.changeDetectorRef.detectChanges();
  }

  addPoi(): void {
    this.poiService.setState(POI_STATES.ADD);
  }

  showPoi(): void {
    this.wizSrv.setShowPoi(true);
  }

  createNewYear(fromYear: number, toYear: number): void {
    this.yearSelect.close();
    this.dialogService
      .open(NewYearComponent, { from: fromYear, to: toYear })
      .afterClosed()
      .subscribe((res) => {
        if (res.result) {
          this.availableYears.push(toYear);
          this.availableYears.sort((a, b) => b - a);
          this.newYearMax =
            Math.max(...this.availableYears.filter((ar) => ar > 0)) + 1;
          this.newYearMin =
            Math.min(...this.availableYears.filter((ar) => ar > 0)) - 1;
          this.setYear(toYear);
        }
      });
  }
}
