<dvm-card padding="none">
  <div class="header" (click)="expanded = disabled ? false : !expanded">
    <ng-content select="dvm-radio-button"></ng-content>
    <div class="expand-icon" [ngClass]="{ 'expand-icon-expanded': expanded }">
      <svg
        width="18"
        height="10"
        viewBox="0 0 18 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.00017 0.833659C9.13975 0.833659 9.28604 0.860498 9.43903 0.914175C9.59313 0.967852 9.73998 1.06179 9.87956 1.19598L17.1241 8.16055C17.2637 8.29474 17.3335 8.42894 17.3335 8.56313C17.3335 8.69732 17.2637 8.83151 17.1241 8.9657C16.9845 9.0999 16.8449 9.16699 16.7054 9.16699C16.5658 9.16699 16.4262 9.0999 16.2866 8.9657L9.00017 1.96087L1.71373 8.9657C1.57415 9.09989 1.43456 9.16699 1.29497 9.16699C1.15539 9.16699 1.0158 9.09989 0.876213 8.9657C0.736626 8.83151 0.666832 8.69732 0.666832 8.56313C0.666832 8.42893 0.736626 8.29474 0.876213 8.16055L8.12077 1.19598C8.26036 1.06178 8.39994 0.967852 8.53953 0.914175C8.67912 0.860498 8.83266 0.833659 9.00017 0.833659Z"
        />
      </svg>
    </div>
  </div>
  <div class="content" [ngClass]="{ 'content-expanded': expanded }">
    <div class="divider" [ngClass]="{ 'divider-expanded': expanded }"></div>
    <dv-file-preview-list
      class="list"
      [ngStyle]="{
        height: expanded ? listHeight : '0px'
      }"
      [files]="list.functionParameter.definition.files"
      [selectedFiles]="list.functionParameter.definition.selectedFiles"
      [multiSelect]="list.functionParameter.definition.multiselect"
      [disabled]="disabled"
      (fileChange)="onFileChange($event)"
    ></dv-file-preview-list>
  </div>
</dvm-card>
