import { DatePipe, NgFor, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { META_DATA } from 'app/components/data/fileset/fileset-list-detail/fileset-list-detail.types';
import {
  FunctionParameterWithType,
  FunctionGeoDataDefinition,
  FunctionNestedDefinition,
  PARAMETER_TYPES,
  NestedValue,
} from '../../step-settings.types';
import {
  ACCORDION_ITEM_MODE,
  DvmAccordionItemModule,
  DvmAccordionModule,
  DvmTextModule,
  DvmTooltipDirective,
} from '@dvm/components';
import { GeoDataContentComponent } from 'app/views/prescription-wizard-page/components/step-settings/components/file-section/components/geo-data-content/geo-data-content.component';
import { NestedGeoDataContentComponent } from 'app/views/prescription-wizard-page/components/step-settings/components/file-section/components/nested-geo-data-content/nested-geo-data-content.component';
import { DvToolbarTranslateService, DvTranslatePipe } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-file-section',
  templateUrl: 'file-section.component.html',
  styleUrls: ['file-section.component.scss'],
  standalone: true,
  imports: [
    DvmAccordionItemModule,
    DvmAccordionModule,
    DvmTextModule,
    DvmTooltipDirective,
    DvTranslatePipe,
    GeoDataContentComponent,
    NestedGeoDataContentComponent,
    NgFor,
    NgIf,
  ],
})
export class FileSectionComponent implements OnChanges {
  @Input() geoData: FunctionParameterWithType<
    FunctionGeoDataDefinition | FunctionNestedDefinition
  >[] = [];
  @Output() fileChange = new EventEmitter<number>();
  @Output() itemChange = new EventEmitter<number>();
  @Output() valid = new EventEmitter<boolean>();

  geoDataSelectedFileText: string[];
  PARAMETER_TYPES = PARAMETER_TYPES;
  ACCORDION_ITEM_MODE = ACCORDION_ITEM_MODE;

  constructor(
    private datePipe: DatePipe,
    private translate: DvToolbarTranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.geoData?.currentValue?.length) {
      this.getGeoDataSelectedFileText();
      this.checkValidity();
    } else {
      this.geoDataSelectedFileText = [];
    }
  }

  onFileChange(fileIds: number[], index: number): void {
    this.geoData[index].value = fileIds;
    this.getGeoDataSelectedFileText();
    this.checkValidity();

    this.fileChange.emit(index);
  }

  onFixedValueChange(index: number): void {
    this.checkValidity();

    this.fileChange.emit(index);
  }

  onNestedFileChange(
    value: NestedValue,
    data: FunctionParameterWithType<FunctionNestedDefinition>,
    index: number
  ): void {
    const key = Object.keys(value).pop();
    const length = data.definition.nestedParameter.find(
      (param) => Number(key) === param.id
    ).definition.files.length;

    data.value = {
      ...value,
      length,
    };

    this.getGeoDataSelectedFileText();
    this.checkValidity();

    this.fileChange.emit(index);
  }

  onNestedItemChange(
    value: NestedValue,
    data: FunctionParameterWithType<FunctionNestedDefinition>,
    index: number
  ): void {
    data.value = value;

    this.checkValidity();

    this.itemChange.emit(index);
  }

  private checkValidity(): void {
    const hasMissingFiles = this.checkMissingFiles();
    const hasInvalidFixedValues = this.checkInvalidFixedValues();

    const isValid = !hasMissingFiles && !hasInvalidFixedValues;
    this.valid.emit(isValid);
  }

  private checkInvalidFixedValues(): boolean {
    return !!this.geoData.filter((data) => {
      if (!data.useFixedValue) {
        return false;
      }

      const value = data.fixedValue as number;
      const { minLimit, maxLimit } = data.definition;

      if (!this.hasValue(value)) {
        data.errorMessage = this.translate.t('Fixed value required');
      } else if (this.hasValue(minLimit) && value < minLimit) {
        data.errorMessage = `${this.translate.t(
          'Minimum allowed fixed value'
        )}: ${minLimit}`;
      } else if (this.hasValue(maxLimit) && value > maxLimit) {
        data.errorMessage = `${this.translate.t(
          'Maximum allowed fixed value'
        )}: ${maxLimit}`;
      } else {
        data.errorMessage = '';
      }

      return !!data.errorMessage;
    }).length;
  }

  private hasValue(value?: number): boolean {
    return value !== undefined && value !== null;
  }

  private checkMissingFiles(): boolean {
    return !!this.geoData.filter((data) => {
      data.errorMessage = '';

      if (data.useFixedValue) {
        return false;
      }

      if (data.parameterType === PARAMETER_TYPES.NESTED) {
        const key = Object.keys(data.value)[0];
        data.hasSelectedFile = !!data.value[key].length;
      } else if (
        data.parameterType === PARAMETER_TYPES.TARGET_PH ||
        data.optional
      ) {
        data.hasSelectedFile = true;
      } else {
        data.hasSelectedFile = !!(data.value as number[])?.length;
      }

      if (!data.hasSelectedFile) {
        data.errorMessage = this.translate.t(
          'At least one file must be selected'
        );
      }

      return !data.hasSelectedFile;
    }).length;
  }

  private getGeoDataSelectedFileText(): void {
    this.geoDataSelectedFileText = this.geoData.reduce((prev, curr) => {
      let selectedFileId: string;
      let definition;

      if (curr.parameterType === PARAMETER_TYPES.NESTED) {
        const selectedKey = Object.keys(curr.value).find(
          (key) => key !== 'length'
        );
        selectedFileId = curr.value ? curr.value[selectedKey][0] : null;
        selectedFileId = isNaN(Number(selectedFileId)) ? null : selectedFileId;
        definition = (
          curr.definition as FunctionNestedDefinition
        ).nestedParameter.find(
          (param) => param.id === Number(selectedKey)
        ).definition;
      } else {
        selectedFileId = curr.value ? curr.value[0] : null;
        definition = curr.definition;
      }
      let text = ' ';

      if (selectedFileId) {
        const selectedFile = definition.files.find(
          (file) => file.fileId === selectedFileId
        );

        if (selectedFile) {
          const date = new Date(
            selectedFile.metadata.find(
              (data) => data.key === META_DATA.CREATED
            )?.value
          );
          text = `${
            isNaN(date.valueOf()) ? '' : this.datePipe.transform(date, 'short')
          } - ${selectedFile.name}`;
        }
      }

      prev.push(text);

      return prev;
    }, []);
  }
}
