import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'numberDecimal' })
export class NumberDecimalPipe implements PipeTransform {
  private readonly FORMAT = '0.1-1';

  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: unknown): string {
    const numberValue = Number(value);

    if (isNaN(numberValue)) {
      return `${value}`;
    } else {
      return `${this.decimalPipe.transform(numberValue, this.FORMAT)}`;
    }
  }
}
