import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'dv-layer-dialog',
    templateUrl: './layer-dialog.component.html',
    styleUrls: ['./layer-dialog.component.scss'],
})
export class LayerDialogComponent {
    @Output() onClose = new EventEmitter<null>();
    public featureProps = [];

    public editing = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<LayerDialogComponent>) {
        this.setData(data);
    }

    getImage(props) {
        const img = props.find((p) => p.key === 'imgeRef') ? props.find((p) => p.key === 'imgeRef').value : null;
        return img
            ? 'https://dvwebphoto.blob.core.windows.net/' +
                  props.find((p) => p.key === 'klientId').value +
                  '/' +
                  img +
                  '/large.jpg'
            : null;
    }

    openUrl(img: string) {
        window.open(img.replace('/large', ''), '_blank');
    }

    close() {
        this.onClose.emit();
        this.dialogRef.close(true);
    }

    getProperties(f: google.maps.Data.Feature) {
        try {
            let props = {};
            f.forEachProperty((value, name) => {
                if (name.indexOf('_') !== 0) props[name]= value;
            });
            return props;
        } catch (err) {
            return [];
        }
    }

    setData(data) {
        if (data) {
            this.featureProps = [];
            data.features.forEach((f) => {
                let props = [];
                if (!f.properties) f.properties = this.getProperties(f);
                for (const [key, value] of Object.entries(f.properties)) {
                    if (key && value && value !== '' && key.charAt(0) !== '_') props.push({ key, value });
                }
                this.featureProps.push(props);
            });
        }
      }
}
