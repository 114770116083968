<div class="text-wrapper">
  <mat-icon class="text-wrapper-icon">check_circle</mat-icon>
  <dvm-text body>
    {{
      '_prescription_wizard_saved_message'
        | dvt: '_The file has been saved in your Dataväxt'
    }}
  </dvm-text>
</div>
<dvm-button primary class="restart-button" (click)="restartWizard()">
  {{ '_prescription_wizard_restart_message' | dvt: '_Exit and start over' }}
</dvm-button>
