import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientService } from './client.service';
import { DvUserService } from '@dv/toolbar-msal';
import { BETA_FEATURE_FLAG } from 'app/types/featureflags.types';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  private useDekar = false;
  private clientCountry: string;
  private _hasCropmapAdvanced$ = new BehaviorSubject<boolean>(false);
  private _hasCropmap$ = new Subject<boolean>();
  private _hasOdling$ = new BehaviorSubject<boolean>(false);

  constructor(
    private clientService: ClientService,
    private userService: DvUserService
  ) {
    this.clientService.client().subscribe((client) => {
      this._hasCropmapAdvanced$.next(
        client.rattigheter.cropMapAdvanced ? true : false
      );
      this._hasCropmap$.next(client.rattigheter.cropMap ? true : false);
      this._hasOdling$.next(client.rattigheter.odling ? true : false);
      this.clientCountry = client.land;
      if (this.isClientNo()) {
        this.loadNorwaySettings();
      }
    });
  }

  private loadNorwaySettings(): void {
    this.useDekar = true;
  }

  getAreaUnit(): Observable<string> {
    return this.clientService.getLandCode().pipe(
      map((landCode: string) => {
        switch (landCode) {
          case 'no':
            return 'daa';
          default:
            return 'ha';
        }
      })
    );
  }

  getCurrencyUnit(): Observable<string> {
    return this.clientService.getLandCode().pipe(
      map((landCode: string) => {
        switch (landCode) {
          case 'no':
            return 'kr';
          case 'se':
            return 'kr';
          default:
            return '€';
        }
      })
    );
  }

  getCurrencyPerAreaUnit(): Observable<string> {
    return this.clientService.getLandCode().pipe(
      map((landCode: string) => {
        switch (landCode) {
          case 'no':
            return 'kr/daa';
          case 'se':
            return 'kr/ha';
          default:
            return '€/ha';
        }
      })
    );
  }

  getMassPerAreaUnit(massUnit: string): Observable<string> {
    return this.clientService.getLandCode().pipe(
      map((landCode: string) => {
        switch (landCode) {
          case 'no':
            return massUnit + '/daa';
          default:
            return massUnit + '/ha';
        }
      })
    );
  }

  getCurrencyPerMassUnit(massUnit: string): Observable<string> {
    return this.clientService.getLandCode().pipe(
      map((landCode: string) => {
        switch (landCode) {
          case 'se':
            return 'kr/' + massUnit;
          case 'no':
            return 'kr/' + massUnit;
          default:
            return '€/' + massUnit;
        }
      })
    );
  }

  getAreaFactor(): number {
    return this.useDekar ? 1000 : 10000;
  }

  getAreaUnitFromSettings(): string {
    return this.useDekar ? 'daa' : 'ha';
  }

  getUnitByAreaUnitFromSettings(customUnit: string): string {
    return customUnit + (this.useDekar ? '/daa' : '/ha');
  }

  isClientNo(): boolean {
    return this.clientCountry === 'no';
  }

  isClientSe(): boolean {
    return this.clientCountry === 'se';
  }

  isClientNl(): boolean {
    return this.clientCountry === 'nl';
  }

  hasCropMapAdvanced(): Observable<boolean> {
    return this._hasCropmapAdvanced$.asObservable();
  }

  setHasCropMapAdvanced(hasAdvanced: boolean): void {
    this._hasCropmapAdvanced$.next(hasAdvanced);
  }

  hasCropMap(): Observable<boolean> {
    return this._hasCropmap$.asObservable();
  }

  hasOdling(): Observable<boolean> {
    return this._hasOdling$.asObservable();
  }

  calculateArea(area: number): number {
    return area / this.getAreaFactor();
  }

  calculateAreaFromHa(area: number): number {
    return (area / this.getAreaFactor()) * 10000;
  }

  getEncoding(): string {
    return 'UTF-8';
  }

  showBetaFunction$ = this.userService.user$.pipe(
    map(
      (user) =>
        !!user?.organisationer?.find(
          ({ keyName }) => keyName === BETA_FEATURE_FLAG
        )
    )
  );
}
