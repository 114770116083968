import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { AnalyzeService, SplitMode } from 'app/services/analyze.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dv-map-split-mode',
  templateUrl: './map-split-mode.component.html',
  styleUrls: ['./map-split-mode.component.scss'],
})
export class MapSplitModeComponent implements OnInit, OnDestroy {
  private _unsub$: Subject<void> = new Subject<void>();
  showModeButton = true;
  splitMode = 2;

  constructor(private analyzeService: AnalyzeService) {}

  ngOnInit(): void {
    this.analyzeService
      .getSplitMode()
      .pipe(takeUntil(this._unsub$))
      .subscribe((res: SplitMode) => {
        this.splitMode = res === SplitMode.Two ? 2 : 4;
      });
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  change(value: MatButtonToggleChange): void {
    this.analyzeService.setSplitMode(
      value.value == 2 ? SplitMode.Two : SplitMode.Four
    );
  }
}
