import { EventEmitter, Input } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';


@Component({
  selector: 'dv-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>(null);
  constructor() { }

  ngOnInit(): void {
  }

  delete(){
    this.onDelete.emit();
  }
}
