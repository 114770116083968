<ng-container *ngIf="function">
  <div class="pane-header">
    <div class="title">3. {{ 'Selections and settings' | dvt }}</div>
    <mat-icon class="pane-header-settings" (click)="onShowSettingsPane()">
      settings
    </mat-icon>
  </div>
  <div class="function-info">
    <div class="function-info-border">
      <dvm-text h4>
        {{ function.name }}
      </dvm-text>
      <ng-container *ngIf="function.shortDescription">
        <dvm-text label>
          {{ function.shortDescription }}
        </dvm-text>
        <a
          class="function-info-link"
          [href]="function.helpLink ?? LINK_ROUTES.SUPPORT"
          target="_blank"
        >
          {{ 'Find more information here' | dvt }}
        </a>
      </ng-container>
    </div>
  </div>
  <div class="function-inputs" *ngIf="inputs">
    <dv-input-section
      [inputs]="inputs"
      [targetPhParameter]="targetPhParameter"
      (valid)="setInputsValidity($event)"
      (adjustPhValueChange)="onAdjustPhValueChange($event)"
    ></dv-input-section>
  </div>
  <div class="preview">
    <dv-file-section
      [geoData]="filteredGeoData"
      (fileChange)="onFileChange($event)"
      (itemChange)="onItemChange($event)"
      (valid)="setGeoDataValidity($event)"
    ></dv-file-section>
  </div>
</ng-container>
<dv-settings-pane
  [loading]="loading"
  [showPane]="showGridSettingsPane"
  [gridSettings]="gridSettings"
  (hideSettingsPane)="onHideSettingsPane($event)"
  (gridSettingsChange)="gridSettingsChange.emit($event)"
>
</dv-settings-pane>
