<div class="crop-basis" *ngIf="cropsBasis.length > 0">
  <p>{{ 'Basis' | dvt }}</p>
  <div class="preview-list">
    <dv-interpolation-basis-preview
      *ngFor="let basis of cropsBasis"
      [boundary]="basis.features"
      [crop]="basis.crop"
      [color]="basis.color"
    ></dv-interpolation-basis-preview>
  </div>
</div>
