import { ChangeDetectorRef, Component, Input, NgZone } from '@angular/core';
import * as turf from '@turf/turf';
import { Feature, Polygon } from '@turf/turf';
import { SiteService } from 'app/services/site.service';
import { LAYER_ZINDEX, MapService } from '../../map.service';
import { MapToolComponent } from '../map-tool';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-map-draw',
  templateUrl: 'draw.component.html',
  styleUrls: ['draw.component.scss'],
})
export class DrawComponent extends MapToolComponent {
  private mapClienEv: google.maps.MapsEventListener;
  private mapMouseupEv: google.maps.MapsEventListener;

  currAreal = 0;
  isDrawing = false;
  buttonText: string = this.translate.t('Rita skifte');
  selectedPart = -1;

  history: google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>>[] =
    [];
  historyIndex = 0;

  @Input() set btnText(value: string) {
    this.buttonText = this.translate.t(value);
  }

  private drawMainPoly = new google.maps.Polygon({
    editable: true,
  });

  private currentPolygon = new google.maps.Polygon();
  private polygonParts: google.maps.Polygon[] = [];

  constructor(
    siteService: SiteService,
    public translate: DvToolbarTranslateService,
    mapService: MapService,
    cd: ChangeDetectorRef,
    zone: NgZone
  ) {
    super(siteService, mapService, cd, zone, translate);
  }

  addPolygon(paths?: google.maps.LatLng[]): void {
    const poly = new google.maps.Polygon({
      editable: true,
      fillOpacity: 0.15,
      fillColor: '#ffffff',
      clickable: false,
    });

    poly.addListener('click', (event: google.maps.PolyMouseEvent) => {
      if (poly.getEditable() && event.vertex !== undefined) {
        poly.getPath().removeAt(event.vertex);
      }
    });

    this.showPolygon(poly);
    if (paths) {
      poly.setPath(paths);
    } else {
      poly.setPath([]);
    }
    this.polygonPartsSetEditable(false);
    this.polygonParts.push(poly);
    this.setCurrentPolygon(this.polygonParts.length - 1);
  }

  removePolygon(): void {
    this.polygonParts[this.selectedPart].setMap(null);
    this.polygonParts.splice(this.selectedPart, 1);

    this.setCurrentPolygon(-1);
  }

  showPolygon(polygon: google.maps.Polygon): void {
    polygon.setMap(this.dvMap.map);
  }

  polygonPartsSetEditable(value: boolean): void {
    this.polygonParts.forEach((p) => {
      p.setEditable(value);
    });

    this.drawMainPoly.setEditable(value);
  }

  polygonPartChanged(value: number): void {
    this.setCurrentPolygon(value);
  }

  setCurrentPolygon(index: number): void {
    if (index === -1) {
      this.currentPolygon = this.drawMainPoly;
    } else {
      this.currentPolygon = this.polygonParts[index];
    }

    this.polygonPartsSetEditable(false);
    this.currentPolygon.setEditable(true);
    this.selectedPart = index;
  }

  protected onMapInit(): void {
    // Empty method
  }

  protected onActive(): void {
    this.drawMainPoly.setOptions({
      zIndex: this.orginalFeature
        ? this.orginalFeature.getProperty(LAYER_ZINDEX) + 1
        : 10000,
      editable: true,
      clickable: false,
    });

    this.history = [];
    this.historyIndex = -1;
    this.addHistory(this.drawMainPoly.getPaths());

    this.startDraw();

    this.currentPolygon = this.drawMainPoly;

    this.mapMouseupEv = this.dvMap.map.addListener('mouseup', () => {
      this.updateAreal();
    });

    this.currentPolygon.addListener('click', (event) => {
      this.addHistory(this.currentPolygon.getPaths());
      if (this.currentPolygon.getEditable() && event.vertex !== undefined) {
        this.currentPolygon.getPath().removeAt(event.vertex);
      }

      this.updateAreal();
    });
    this.currentPolygon.addListener('mouseup', () => {
      this.updateAreal();
    });

    this.mapClienEv = this.dvMap.map.addListener(
      'click',
      (ev: google.maps.MouseEvent) => {
        const path = this.currentPolygon.getPath();
        this.addHistory(this.currentPolygon.getPaths());

        let closestDist = Number.MAX_SAFE_INTEGER;
        let closestIndex = -1;
        path.forEach((p, i) => {
          const dist = google.maps.geometry.spherical.computeDistanceBetween(
            p,
            ev.latLng
          );
          if (dist < closestDist) {
            closestDist = dist;
            closestIndex = i;
          }
        });

        if (closestIndex > -1) {
          const tmpPoly = new google.maps.Polygon();
          const tempPath = new google.maps.MVCArray<google.maps.LatLng>();
          path.forEach((p, i) => {
            tempPath.insertAt(i, p);
          });

          tempPath.insertAt(closestIndex, ev.latLng);
          tmpPoly.setPath(tempPath);
          let poly = this.getTurfPolygon(tmpPoly);

          if (poly) {
            /**
             * kinks kollar om polygonen överlappar sig själv.
             * Gör den det så hoppar vi bak ett steg från närmsta punkt. Om det fortfarande är överlapp
             * hoppar vi fram 1 ett steg från närmsta punkt.
             */
            let res = turf.kinks(poly);
            if (res.features.length > 0) {
              closestIndex -= 1;

              tempPath.insertAt(closestIndex, ev.latLng);
              tmpPoly.setPath(tempPath);
              poly = this.getTurfPolygon(tmpPoly);
              res = turf.kinks(poly);

              if (res.features.length > 0) {
                closestIndex += 2;
              }
            }
          }
        }

        if (closestIndex === -1) {
          closestIndex = 0;
        }
        path.insertAt(closestIndex, ev.latLng);
        this.currentPolygon.setPath(path);

        this.updateAreal();
      }
    );

    this.updateAreal();

    this.activated.next();
  }

  private updateAreal(): void {
    this.zone.run(() => {
      const diff = this.diffPolygons();
      if (diff) {
        this.currAreal = this.siteService.calculateArea(turf.area(diff));
        this.cd.markForCheck();
      }
    });
  }

  protected onDeActivated(): void {
    if (this.mapClienEv) {
      this.mapClienEv.remove();
    }

    if (this.mapMouseupEv) {
      this.mapMouseupEv.remove();
    }

    this.drawMainPoly.setMap(null);
    this.dvMap.show(this.orginalFeature);

    this.cancel.next();
  }
  startDraw(): void {
    this.setParts([]);

    this.isDrawing = true;
    this.drawMainPoly.setMap(this.dvMap.map);

    if (this.orginalFeature) {
      this.dvMap.hide(this.orginalFeature);

      if (this.polygonParts.length > 0) {
        this.showPolygon(this.drawMainPoly);
        this.polygonParts.forEach((p) => {
          this.showPolygon(p);
        });
      } else {
        const orgPolygons = (<google.maps.Data.Polygon>(
          this.orginalFeature.getGeometry()
        ))
          .getArray()
          .map((outer) => {
            return outer.getArray().map((inner) => {
              return inner;
            });
          });

        this.drawMainPoly.setPaths(orgPolygons[0]);

        for (let i = 1; i < orgPolygons.length; i++) {
          this.addPolygon(orgPolygons[i]);
        }
      }

      this.setCurrentPolygon(-1);
    } else {
      this.drawMainPoly.setPath([]);
    }

    this.updateAreal();
  }

  doneDraw(): void {
    this.isDrawing = false;

    this.removePolygonsFromMap();

    const mainPoly = this.diffPolygons();

    this.setParts([mainPoly]);

    this.updateAreal();
  }

  diffPolygons(): turf.helpers.Feature<
    turf.helpers.Polygon,
    {
      [name: string]: any;
    }
  > {
    let mainPoly = this.getTurfPolygon(this.drawMainPoly);
    this.polygonParts.forEach((p) => {
      const hole = this.getTurfPolygon(p);
      if (hole) {
        mainPoly = <Feature<Polygon>>turf.difference(mainPoly, hole);
      }
    });

    return mainPoly;
  }

  getTurfPolygon(googlePoly: google.maps.Polygon): turf.helpers.Feature<
    turf.helpers.Polygon,
    {
      [name: string]: any;
    }
  > | null {
    const rings = [];
    googlePoly.getPaths().forEach((ring) => {
      const ringLatLng = ring.getArray().map((latLng) => {
        return [latLng.lng(), latLng.lat()];
      });
      ringLatLng.push(ringLatLng[0]);

      rings.push(ringLatLng);
    });

    if (rings.length > 0 && rings[0].length > 3) {
      return turf.polygon(rings);
    }
    return null;
  }

  removePolygonsFromMap(): void {
    this.drawMainPoly.setMap(null);
    this.polygonParts.forEach((p) => {
      p.setMap(null);
    });
  }

  setHistory(dir: number): void {
    //the history is not done
    this.historyIndex += dir;
    if (this.historyIndex < 0) {
      this.historyIndex = 0;
    } else if (this.historyIndex >= this.history.length) {
      this.historyIndex = this.history.length - 1;
    }

    this.drawMainPoly.setPaths(this.history[this.historyIndex]);
  }
  addHistory(
    paths: google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>>
  ): void {
    //the history is not done
    this.historyIndex += 1;
    this.history.splice(
      this.historyIndex,
      this.history.length - this.historyIndex
    );

    this.history.push(new google.maps.MVCArray(paths.getArray()));
  }
}
