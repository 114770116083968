import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientService } from 'app/services/client.service';
import { DialogService } from 'app/components/dialog/dialog.service';
import { SiteService } from 'app/services/site.service';
import { Observable, Subscription } from 'rxjs';
import { PrescriptionFileService } from './prescription-file.service';
import { EXPORT_KEY, ExportOption } from './prescription-export.types';
import { PrescriptionExportService } from './prescription-export.service';
import { MachineConnectionDialogComponent } from './machine-connection-dialog/machine-connection-dialog.component';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-prescription-export',
  templateUrl: './prescription-export.component.html',
  styleUrls: ['./prescription-export.component.scss'],
  providers: [PrescriptionExportService],
})
export class PrescriptionExportComponent implements OnInit, OnDestroy {
  fileName = '';
  fileStatus: string;
  private statusSub: Subscription;
  loading = false;
  valid = true;
  hasNutritionalContent: boolean;
  exportOnly: boolean;

  get options(): Observable<ExportOption[]> {
    return this.prescriptionExportService.options$;
  }

  get disableItem(): boolean {
    return (
      this.loading || !this.valid || !this.fileName || this.fileName.length < 1
    );
  }

  get noSaveClose(): boolean {
    return this.data.exportOnly;
  }

  get fileNameErrorMessage(): string {
    return this.valid
      ? ''
      : this.dvTranslate.t(
          '_special_chars_err',
          '_The file name may not contain special characters'
        );
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public fileSrv: PrescriptionFileService,
    private dialogRef: MatDialogRef<PrescriptionExportComponent>,
    public siteService: SiteService,
    public clientService: ClientService,
    private dialogSrv: DialogService,
    private dvTranslate: DvToolbarTranslateService,
    public prescriptionExportService: PrescriptionExportService
  ) {}

  ngOnInit(): void {
    this.hasNutritionalContent = this.data.nutritionalContent !== null;
    this.exportOnly = this.data.exportOnly;
    this.fileSrv.init(
      this.data.prescription,
      this.data.nutritionalContent,
      this.data.unit,
      this.data.functionPrescription
    );

    this.statusSub = this.fileSrv.fileStatus$.subscribe((status) => {
      this.fileStatus = status;
      this.loading = status !== null;
    });
  }

  ngOnDestroy(): void {
    if (this.statusSub) {
      this.statusSub.unsubscribe();
    }
  }

  export(exportType: string): void {
    if (this.disableItem) {
      return;
    } else if (exportType === EXPORT_KEY.MACHINE_CONNECTION) {
      this.dialogSrv.open(
        MachineConnectionDialogComponent,
        {
          ...this.data,
          fileName: this.fileName,
        },
        {
          panelClass: 'machine-connection-overlay',
          maxWidth: '40vw',
          minWidth: '720px',
        }
      );

      return;
    } else {
      this.fileSrv.generateExport(
        this.fileName,
        exportType,
        exportType === EXPORT_KEY.CONNECTED_FARM
      );
    }
  }

  saveAndClose(): void {
    this.fileSrv.generateExport(this.fileName, null, true, () => {
      this.dialogRef.close({ saved: true });
      this.dialogSrv.message(this.dvTranslate.t('Processing file'));
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  checkChars(): void {
    const format = /^([a-zA-Z0-9]|-|_|!|,| )+$/;
    setTimeout(() => {
      this.valid = format.test(this.fileName);
    }, 100);
  }

  numberCorrection(): void {
    const { effectiveContent } = this.fileSrv;
    let newValue = effectiveContent;

    if (effectiveContent <= 0) {
      newValue = 1;
    } else if (effectiveContent > 100) {
      newValue = 100;
    }

    this.fileSrv.effectiveContent = newValue;
  }
}
