import { Injectable } from '@angular/core';

import { DvAuthService } from '@dv/toolbar-msal';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService  {
  constructor(private dvAuthService: DvAuthService) {}

  canActivate(): Observable<boolean> {
    return this.dvAuthService.isLoggedIn$.pipe(
      map((result) => {
        return result;
      })
    );
  }
}
