<div class="pane-header">
  <div class="title">1. {{ 'Select field' | dvt }}</div>
  <mat-form-field class="search-form-field">
    <mat-icon matPrefix>search</mat-icon>
    <input
      matInput
      [formControl]="freeSearchCtrl"
      type="text"
      [placeholder]="'Search here...' | dvt"
    />
  </mat-form-field>
</div>
<div *ngIf="loading; else table" class="loader">
  <mat-spinner [diameter]="100"></mat-spinner>
</div>
<ng-template #table>
  <mat-table class="table" [dataSource]="skifteDataSource">
    <ng-container [matColumnDef]="SKIFTE_TABLE_COLUMNS.RADIO_BUTTON">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-radio-button
          [checked]="element.skifte.id === selectedId"
          [value]="{ id: element.skifte.id, namn: element.skifte.namn }"
          (change)="onChange($event.value)"
        ></mat-radio-button>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="SKIFTE_TABLE_COLUMNS.FIELD">
      <mat-header-cell *matHeaderCellDef>{{ 'Field' | dvt }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="table-field">
          <div class="table-field-svg" [innerHTML]="element.svg"></div>
          {{ element.skifte.namn }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="SKIFTE_TABLE_COLUMNS.CROP">
      <mat-header-cell *matHeaderCellDef>{{ 'Crop' | dvt }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div
          class="crop-wrapper"
          *ngIf="element.skifte.huvudgroda; else noHuvudgroda"
        >
          <div
            class="crop-bullet"
            [ngStyle]="{ 'background-color': element.skifte.huvudgroda.farg }"
          ></div>
          <div class="crop-name">{{ element.skifte.huvudgroda.benamning }}</div>
        </div>
        <ng-template #noHuvudgroda>
          <div class="crop-missing">{{ 'Crop missing' | dvt }}</div>
        </ng-template>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="SKIFTE_TABLE_COLUMNS.UNIT">
      <mat-header-cell *matHeaderCellDef>{{ 'farm' | dvt }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{
        element.skifte.brukEnhNamn
      }}</mat-cell>
    </ng-container>
    <mat-row mat-header-row *matHeaderRowDef="columns"></mat-row>
    <mat-row
      class="table-row"
      [ngClass]="{ 'table-row-selected': row.skifte.id === selectedId }"
      mat-row
      *matRowDef="let row; columns: columns; let i = index"
    ></mat-row>
  </mat-table>
</ng-template>
