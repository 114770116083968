<div class="map-area" *ngIf="!editBorders">
  <div>
    <router-outlet></router-outlet>
  </div>
  <dv-map [isMainMap]="true" #map>
    <dv-map-menu></dv-map-menu>
    <dv-measure [map]="map"></dv-measure>
  </dv-map>
</div>
<dv-edit-field-map *ngIf="editBorders"></dv-edit-field-map>
<dv-import-progress></dv-import-progress>
