<div class="edit-field-map-wrapper">
  <mat-dialog-content class="edit-field-map-dialog-content">
    <div class="edit-field-map-dialog-content-wrapper">
      <dv-map [isMainMap]="false" [mapName]="mapName" #map>
        <button class="close-button" (click)="goBack()" map-tool-left>
          <mat-icon>arrow_back</mat-icon><span>{{ 'Cancel' | dvt }}</span>
        </button>
        <dv-map-split
          (activated)="activateTool()"
          (cancel)="deActivateTool()"
          map-tool-left
          (save)="saveMap($event)"
        ></dv-map-split>
        <dv-map-split-area
          (activated)="activateTool()"
          (cancel)="deActivateTool()"
          splitMode="distance"
          map-tool-left
          (save)="saveMap($event)"
        ></dv-map-split-area>
        <dv-map-split-area
          (activated)="activateTool()"
          (cancel)="deActivateTool()"
          class="edit-field-split-by-area"
          splitMode="areal"
          map-tool-left
          (save)="saveMap($event)"
        ></dv-map-split-area>
        <dv-map-vandteg
          (activated)="activateTool()"
          (cancel)="deActivateTool()"
          map-tool-left
          (save)="saveMap($event)"
        ></dv-map-vandteg>
        <dv-map-draw
          (activated)="activateTool()"
          (cancel)="deActivateTool()"
          class="edit-field-draw-or-change"
          [btnText]="'Draw or change parcel'"
          [skifteId]="activeField.id"
          map-tool-left
          (save)="saveMap($event)"
        ></dv-map-draw>
        <dv-map-merge
          (activated)="activateTool()"
          (cancel)="deActivateTool()"
          [skifteId]="activeField.id"
          map-tool-left
          (save)="saveMap($event)"
        ></dv-map-merge>
        <dv-map-connect
          (activated)="activateTool()"
          (cancel)="deActivateTool()"
          [skifteId]="activeField.id"
          map-tool-left
          (save)="saveMap($event)"
        ></dv-map-connect>
        <dv-map-connect-norge
          (activated)="activateTool()"
          (cancel)="deActivateTool()"
          [skifteId]="activeField.id"
          map-tool-left
          (save)="saveMap($event)"
          *ngIf="siteService.isClientNo() | async"
        ></dv-map-connect-norge>
        <dv-map-connect-field
          (activated)="activateTool()"
          (cancel)="deActivateTool()"
          [skifteId]="activeField.id"
          map-tool-left
          (save)="saveMap($event)"
        ></dv-map-connect-field>
      </dv-map>
    </div>
  </mat-dialog-content>
  <div class="edit-field-map-bottom-element"></div>
</div>
