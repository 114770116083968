<dv-field-tool
  [feature]="selectedFeature"
  (fieldSaved)="onFieldSaved()"
  (changeFeature)="startEdit($event)"
  (drawFeature)="startDraw($event)"
  (toolCancel)="onCancel()"
  [validZoom]="validZoom"
  [standAlone]="true"
  (toolClose)="closeBox()"
></dv-field-tool>
