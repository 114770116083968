import { KeyValue } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from 'app/components/dialog/dialog.service';
import { ImportTypes } from 'app/models/import.model';
import { PrescriptionModel } from 'app/models/models';
import {
  PrescriptionFileModel,
  PrescriptionUntypedFileModel,
} from 'app/models/prescriptiongrid.model';
import { ClientService } from 'app/services/client.service';
import { BehaviorSubject } from 'rxjs';
import { ConnectedFarmRedirectComponent } from './connected-farm-redirect/connected-farm-redirect.component';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Injectable({
  providedIn: 'root',
})
export class PrescriptionFileService {
  private prescription: PrescriptionModel;
  effectiveContent: number;
  private unit: string;
  private functionPrescription: boolean;
  fileStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private clientSrv: ClientService,
    private snackBar: MatSnackBar,
    private dvTranslate: DvToolbarTranslateService,
    private dialogService: DialogService
  ) {}

  init(
    prescription: PrescriptionModel,
    effectiveContent: number,
    unit: string,
    functionPrescription: boolean
  ): void {
    this.prescription = prescription;
    this.effectiveContent = effectiveContent ?? 100;
    this.unit = unit;
    this.functionPrescription = functionPrescription;
  }

  private downloadExportFile(
    file: HttpResponse<Blob>,
    fileName: string,
    exportType: string
  ): void {
    this.fileStatus$.next(this.dvTranslate.t('Downloading file'));
    const dlName: string = this.getFilenameFromHeaders(
      file,
      fileName,
      exportType
    );

    const link = document.createElement('a');
    link.setAttribute('style', 'display:none;');
    document.body.appendChild(link);
    link.download = dlName;
    link.href = URL.createObjectURL(file.body);
    link.target = '_blank';
    link.click();
    document.body.removeChild(link);
    this.fileStatus$.next(null);
  }

  private handleExportResponse(
    fileName: string,
    exportType: string,
    saveOnly: boolean,
    file?: HttpResponse<Blob>,
    closeWindow?: () => void
  ): void {
    if (exportType === 'ConnectedFarm') {
      file.body.text().then((url) => {
        this.dialogService
          .open(ConnectedFarmRedirectComponent, { url: url.replace(/"/g, '') })
          .afterClosed()
          .subscribe(() => {
            this.fileStatus$.next(null);
          });
      });
      return;
    }

    if (saveOnly || !file) {
      this.fileStatus$.next(null);

      if (closeWindow) {
        closeWindow();
      }
      return;
    }

    this.downloadExportFile(file, fileName, exportType);
  }

  private handleExportError(): void {
    this.snackBar.open(this.dvTranslate.t('Failed to download'), null, {
      duration: 5000,
    });
    this.fileStatus$.next(null);
  }

  private generateTypedExport(
    fileName: string,
    exportType: string,
    saveOnly: boolean
  ): void {
    const fileSettings: PrescriptionFileModel = {
      fc: this.prescription.fc,
      average: this.prescription.average,
      total: this.prescription.total,
      bucketCount: this.prescription.bucketCount,
      gridSize: this.prescription.gridSize,
      content: this.effectiveContent / 100,
      fileName: fileName,
      fileType: exportType ? exportType.replace(/\s/g, '') : null,
      unit: this.unit,
      settings: this.prescription,
      adjustedWithContent: this.functionPrescription,
    };
    this.clientSrv.exportPrescription(fileSettings).subscribe(
      (file: HttpResponse<Blob>) =>
        this.handleExportResponse(fileName, exportType, saveOnly, file),
      () => this.handleExportError()
    );
  }

  private generateUntypedExport(
    fileName: string,
    exportType: string,
    saveOnly = false,
    closeWindow?: () => void
  ): void {
    const manufacturer = 'cropmap';
    const type: ImportTypes = 'prescription';
    const payload: PrescriptionUntypedFileModel = {
      fileName,
      featureCollection: this.prescription.fc,
      manufacturer,
      metadata: [
        {
          key: 'nutrition',
          value: String(this.effectiveContent),
        },
        {
          key: 'valueColumn',
          value: 'rate',
        },
        {
          key: 'unit',
          value: this.unit,
        },
      ] as KeyValue<string, string>[],
      type,
    };

    this.clientSrv.exportUntypedPrescription(payload).subscribe(
      () =>
        this.handleExportResponse(
          fileName,
          exportType,
          saveOnly,
          null,
          closeWindow
        ),
      () => this.handleExportError()
    );
  }

  generateExport(
    fileName: string,
    exportType: string,
    saveOnly = false,
    closeWindow?: () => void
  ): void {
    if (fileName === null || fileName.length === 0) {
      this.snackBar.open(this.dvTranslate.t('Enter a file name first'), null, {
        duration: 3000,
      });
      return;
    }

    this.fileStatus$.next(this.dvTranslate.t('Generating file'));
    if (exportType) {
      this.generateTypedExport(fileName, exportType, saveOnly);
    } else {
      this.generateUntypedExport(fileName, exportType, saveOnly, closeWindow);
    }
  }

  private getFilenameFromHeaders(
    response: HttpResponse<Blob>,
    defaultName: string,
    exportType: string
  ): string {
    let fileName: string;
    try {
      const disp = response.headers.get('content-disposition');
      const regExp = /(?:filename=")(.+)(?:")/;
      fileName = decodeURI(regExp.exec(disp)[1]);
    } catch (err) {
      fileName = defaultName;
    }

    if (exportType) {
      const fileExtension = this.getFileExtension(exportType.toLowerCase());
      if (fileName.split('.').pop() !== fileExtension) {
        fileName += '.' + fileExtension;
      }
    }

    return fileName;
  }

  getFileExtension(exportType): string {
    switch (exportType) {
      case 'jpg':
        return 'jpg';
      case 'shape':
        return 'zip';
      case 'text':
        return 'txt';
      default:
        return 'zip';
    }
  }
}
