/// <reference types="@types/googlemaps" />
import { ChangeDetectorRef, Component, Host, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { MapComponent } from '../../map.component';

@Component({
  selector: 'dv-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['../map.component.scss', './map-search.component.scss']
})
export class MapSearchComponent {

  @ViewChild('mInput', { static: false }) mInput: MatInput;
  input: any;
  active: boolean = false;
  map: MapComponent;
  constructor(@Host() map: MapComponent, private cd: ChangeDetectorRef) {
    this.map = map;
   }

  ngAfterViewInit() {
    this.map.mapLoaded.subscribe(res => {
      this.input = document.getElementById('pac-input');
      let searchBox = new google.maps.places.SearchBox(this.input);

      this.enableEnterKey(this.input);
      this.input.focus();

      this.map.map.addListener('bounds_changed', () => {
        searchBox.setBounds(this.map.map.getBounds());
      });

      searchBox.addListener('places_changed', () => {
        var places = searchBox.getPlaces();

        if (places.length == 0) {
          return;
        }

        var bounds = new google.maps.LatLngBounds();
        places.forEach(function(place) {
          if (!place.geometry) {
            console.log("Returned place contains no geometry");
            return;
          }
          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        this.map.map.fitBounds(bounds);
      });

    })
  }

  toggleActive() {
    this.active = !this.active;

    if (this.input) {
      this.input.value = "";
      window.setTimeout(() => {
        this.input.focus();
      }, 1)
    }
  }

  private enableEnterKey(input) {

    /* Store original event listener */
    const _addEventListener = input.addEventListener

    const addEventListenerWrapper = (type, listener) => {
      if (type === "keydown") {
        /* Store existing listener function */
        const _listener = listener
        listener = (event) => {
          /* Simulate a 'down arrow' keypress if no address has been selected */
          const suggestion_selected = document.getElementsByClassName('pac-item-selected').length > 0
          if (event.key === 'Enter' && !suggestion_selected) {
            const e = JSON.parse(JSON.stringify(event))
            e.key = 'ArrowDown'
            e.code = 'ArrowDown'
            _listener.apply(input, [e])
          }
          _listener.apply(input, [event])
        }
      }
      _addEventListener.apply(input, [type, listener])
    }

    input.addEventListener = addEventListenerWrapper
  }

  
}


