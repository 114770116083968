<div class="head">
  <button mat-button class="back-button" (click)="goBack()">
    <mat-icon class="material-icons">arrow_back</mat-icon>
  </button>
  <h3 *ngIf="type">{{ '_filetype_' + type | dvt: '_' + type }}</h3>
</div>
<div class="container">
  <div *ngIf="properties?.length <= 3; else propertySelect">
    <mat-button-toggle-group
      class="properties-select"
      (change)="setActiveProperty($event)"
      aria-label="Properties"
      [value]="selectedProperty"
    >
      <!-- translate or go by displayName?-->
      <mat-button-toggle *ngFor="let prop of properties" [value]="prop">{{
        '_propertyname_' + prop.field | dvt: prop.displayName
      }}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <ng-template #propertySelect>
    <mat-form-field
      class="prop-select"
      appearance="outline"
      *ngIf="properties?.length > 0"
    >
      <mat-select
        [value]="selectedProperty"
        (selectionChange)="setActiveProperty($event)"
      >
        <mat-option *ngFor="let prop of properties" [value]="prop">{{
          '_propertyname_' + prop.field | dvt: prop.displayName
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-template>

  <div class="main-interpolation">
    <mat-card appearance="outlined" *ngIf="legend || interpolationLoading">
      <dv-legend
        [legend]="legend"
        [loading]="interpolationLoading && !error"
        [pointMode]="pointMode"
      ></dv-legend>
    </mat-card>
  </div>

  <dv-interpolation-settings
    [dataQuality]="legend?.qualityUncertainty"
    [loading]="interpolationLoading"
  ></dv-interpolation-settings>

  <div *ngIf="error" class="errmsg">
    <mat-icon color="warn">error</mat-icon>
    <span class="dv-text-color status-text">{{ error }}</span>
  </div>
  <dv-interpolation-basis></dv-interpolation-basis>
  <div class="tools" *ngIf="!interpolationLoading && !error">
    <button mat-stroked-button (click)="analyseField()">
      {{ 'compare maps' | dvt }}
    </button>
    <button mat-stroked-button (click)="shareInterpolation()">
      {{ 'share map' | dvt }}
    </button>

    <button
      mat-raised-button
      class="add"
      (click)="createPrescription()"
      color="primary"
      *ngIf="boundary?.features?.length === 1 && !presExport"
    >
      {{ 'generate prescription' | dvt }}
    </button>
    <button
      mat-raised-button
      class="add"
      (click)="export()"
      color="primary"
      *ngIf="boundary?.features?.length === 1 && presExport"
    >
      {{ 'prescription' | dvt }}
    </button>
  </div>
</div>
