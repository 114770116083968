<mat-dialog-content>
    <div class="feature" *ngFor="let props of featureProps">
        <img (click)="openUrl(getImage(props))" class="img" *ngIf="getImage(props)" [src]="getImage(props)" />
        <div class="prop-container">
            <div *ngFor="let prop of props" class="grid">
                <span *ngIf="prop.key !== imgeRef">{{ prop.key }}</span>
                <span *ngIf="prop.key !== imgeRef">{{ prop.value }}</span>
            </div>
            <div *ngIf="props.length == 0">
                <span>{{'No data available'| dvt}}</span>
            </div>
        </div>
    </div>
    <button mat-dialog-close [mat-dialog-close]="true" mat-mini-fab color="primary">
        <mat-icon>close</mat-icon>
    </button>
</mat-dialog-content>
