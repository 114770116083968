import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { MapComponent, MapInitDirective } from './map.component';
import { MapService } from './map.service';
import { MultiSelectComponent } from './multi-select.component';
import { DvMaterialModule } from 'app/material.module';
import { MapBackgroundComponent } from './components/map-background/map-background.component';
import { MapZoomComponent } from './components/map-zoom/map-zoom.component';
import { MapSearchComponent } from './components/map-search/map-search.component';
import { MapLoaderComponent } from './components/map-loader/map-loader.component';
import { DvTranslatePipe } from '@dv/toolbar-msal';

@NgModule({
  imports: [CommonModule, DvMaterialModule, DvTranslatePipe, FormsModule],
  providers: [DatePipe, MapService],
  declarations: [
    MapBackgroundComponent,
    MapComponent,
    MapInitDirective,
    MapLoaderComponent,
    MapSearchComponent,
    MapZoomComponent,
    MultiSelectComponent,
  ],
  exports: [MapComponent, MapInitDirective, MultiSelectComponent],
})
export class MapModule {}
