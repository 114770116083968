import { Component, OnDestroy, OnInit } from '@angular/core';
import { SkifteDetielsModel } from 'app/models/api.models';
import { SiteService } from 'app/services/site.service';
import { Subject, forkJoin } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DialogService } from '../dialog/dialog.service';
import { FieldViewService } from '../field-view/field-view.service';
import { MapService } from '../map/map.service';
import { MappingWizardService } from '../mapping-view/mapping-wizard/mapping-wizard.service';
import { ClientService } from 'app/services/client.service';

@Component({
  selector: 'dv-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
})
export class MapViewComponent implements OnInit, OnDestroy {
  showFieldView = false;
  _unsub$: Subject<void> = new Subject<void>();
  editBorders: SkifteDetielsModel;

  constructor(
    private fieldViewService: FieldViewService,
    private mapService: MapService,
    public wizSrv: MappingWizardService,
    private siteSrv: SiteService,
    private dialog: DialogService,
    private clientService: ClientService
  ) {
    this.fieldViewService
      .onVisible()
      .subscribe((show) => (this.showFieldView = show));
  }

  ngOnInit(): void {
    this.wizSrv
      .getEditBordersField()
      .pipe(takeUntil(this._unsub$))
      .subscribe((editB) => {
        this.editBorders = editB;
      });
  }

  ngOnDestroy(): void {
    this.mapService.registerMainMap(null);
    this._unsub$.next(null);
    this._unsub$.complete();
  }
}
