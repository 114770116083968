export interface ManualDrawPayload {
  rateOverride: Record<string, number>;
}

export interface Adjustment<T = number | boolean | null> {
  added: boolean;
  value: T;
}

export interface Adjustments {
  maxRate: Adjustment;
  minRate: Adjustment;
  cutoff: Adjustment;
  averageRate: Adjustment;
  flatAdjustment: Adjustment;
  availableProduct: Adjustment;
  rateOverride: Adjustment<Record<string, number>>;
  subGeodataFile: Adjustment;
}

export enum ADJUSTMENT_TYPE {
  minRate = 'minRate',
  maxRate = 'maxRate',
  cutoff = 'cutoff',
  averageRate = 'averageRate',
  availableProduct = 'availableProduct',
  flatAdjustment = 'flatAdjustment',
  rateOverride = 'rateOverride',
  gridRotation = 'gridRotation',
  gridSize = 'gridSize',
  subGeodataFile = 'subGeodataFile',
}

export const DEFAULT_SEED_UNIT = 'kg/ha';
export const DEFAULT_SEED_TOTAL_UNIT = 'kg';
export const ALTERNATIVE_SEED_UNIT = 'frö/kvm';
