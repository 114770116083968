import { DvToolbarEnvironment } from '@dv/toolbar-msal';

export const environment: DvToolbarEnvironment = {
  appKey: 'ddfbda2c-6e52-4e4b-b541-1d0d643dbf3a',
  appName: 'CropMAP',
  defaultLanguage: 'sv',
  dvApiUrl: 'https://api.datavaxt.se/api/v1/',
  iframeProxyUrl: 'https://toolbar.datavaxt.se',
  production: true,
  redirectUri: 'https://cropmap.datavaxt.se/',
  staging: false,

  baseApiUrl: 'https://api.datavaxt.se/api/v1/',
  machineProviderUrl: 'https://machineprovider.datavaxt.se/',
  messagesUrl: 'https://messages.datavaxt.se/',
};
