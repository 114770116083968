import { KeyValue } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { DialogService } from 'app/components/dialog/dialog.service';
import { Router, NavigationEnd } from '@angular/router';
import { MapComponent } from 'app/components/map/map.component';
import { PRESCRIPTION_STEPS } from 'app/views/prescription-wizard-page/prescription-wizard.types';
import { PrescriptionWizardService } from 'app/views/prescription-wizard-page/prescription-wizard.service';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';
import {
  AdjustPrescriptionModel,
  PrescriptionAdjustments,
} from '../step-settings/step-settings.types';
import { AdjustmentsDialogComponent } from './components/adjustments-dialog/adjustments-dialog.component';
import {
  ADJUSTMENT_TYPE,
  Adjustment,
  Adjustments,
  DEFAULT_SEED_TOTAL_UNIT,
  DEFAULT_SEED_UNIT,
} from './step-adjust.types';
import { ManualDrawService } from './components/manual-draw-pane/manual-draw.service';
import { ClientService } from 'app/services/client.service';
import { FILE_TYPE } from 'app/models/file.model';
import { OptionModel } from '@dvm/components';
import { PrescriptionModel } from 'app/models/models';

@Component({
  selector: 'dv-step-adjust',
  templateUrl: 'step-adjust.component.html',
  styleUrls: ['step-adjust.component.scss', '../steps.scss'],
  providers: [ManualDrawService],
})
export class StepAdjustComponent implements OnInit, OnChanges, OnDestroy {
  @Input() grid: PrescriptionModel;
  @Input() dvMap: MapComponent;
  @Input() loading = false;
  private _selectedSeedUnit = DEFAULT_SEED_UNIT;
  @Input() set selectedSeedUnit(value: string) {
    if (value) {
      this._selectedSeedUnit = value;
      this.seedTotalUnit = value.split('/')[0];
    }
  }
  get selectedSeedUnit(): string {
    return this._selectedSeedUnit;
  }
  @Output() adjustments = new EventEmitter<AdjustPrescriptionModel>();
  private unsub$ = new Subject<void>();
  private featureSelectUnsub$ = new Subject<void>();
  seedTotalUnit = DEFAULT_SEED_TOTAL_UNIT;
  showManualDrawPane = false;
  loadingPrescription = false;
  ADJUSTMENT_TYPE = ADJUSTMENT_TYPE;
  adjustRequest = new Subject<
    KeyValue<ADJUSTMENT_TYPE, number | boolean | Record<string, number>>
  >();
  geodataList: OptionModel[] = [];
  fileError: string;
  selectedFeature?: google.maps.Data.Feature;
  isPrescriptionSaved = false;

  currentAdjustments: Adjustments = {
    minRate: {
      added: false,
      value: null,
    },
    maxRate: {
      added: false,
      value: null,
    },
    averageRate: {
      added: false,
      value: null,
    },
    cutoff: {
      added: false,
      value: null,
    },
    flatAdjustment: {
      added: false,
      value: null,
    },
    availableProduct: {
      added: false,
      value: null,
    },
    rateOverride: {
      added: false,
      value: null,
    },
    subGeodataFile: {
      added: false,
      value: null,
    },
  };
  addedAdjustments = 0;

  get hasDeviations$(): Observable<boolean> {
    return this.manualDrawService.deviations$.pipe(
      map((deviations) => !!deviations.length)
    );
  }

  constructor(
    private wizardService: PrescriptionWizardService,
    private dialogService: DialogService,
    private manualDrawService: ManualDrawService,
    private clientServivice: ClientService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.adjustRequest
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.unsub$))
      .subscribe(() => this._handleAdjustRequest());

    this.handleStepIndexChange();
    this.handlePrescriptionSaved();

    if (this.grid) {
      this.wizardService.selectAdjustments(true);
    }
  }

  private handlePrescriptionSaved(): void {
    this.wizardService.prescriptionSaved$
      .pipe(takeUntil(this.unsub$))
      .subscribe(() => (this.isPrescriptionSaved = true));
  }

  ngOnChanges(): void {
    if (this.grid) {
      this.wizardService.selectAdjustments(true);
    }
  }

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  onValueChange(
    value: number | boolean | Record<string, number>,
    key: ADJUSTMENT_TYPE
  ): void {
    if (key === ADJUSTMENT_TYPE.subGeodataFile) {
      value = Number(value);
    }
    this.currentAdjustments[key].value = value;
    this.adjustRequest.next({
      key: key,
      value: value,
    });
  }

  onDetailPaneClose(): void {
    this.dvMap.deSelectedFeatures();
    this.selectedFeature = null;
  }

  private handleStepIndexChange(): void {
    this.wizardService.selectedStepIndex$
      .pipe(takeUntil(this.unsub$))
      .subscribe((index) => {
        if (index === PRESCRIPTION_STEPS.ADJUST) {
          this.onAdjustStepSelected();
        } else {
          if (index !== PRESCRIPTION_STEPS.SETTINGS) {
            this.resetCurrentAddjustments();
          }
          if (!this.featureSelectUnsub$.closed) {
            this.showManualDrawPane = false;
            this.manualDrawService.reset();
            this.featureSelectUnsub$.next();
            this.featureSelectUnsub$.complete();
          }
        }
      });
  }

  private onAdjustStepSelected(): void {
    this.featureSelectUnsub$ = new Subject<void>();
    this.isPrescriptionSaved = false;

    this.dvMap?.onSelected
      .pipe(takeUntil(this.featureSelectUnsub$))
      .subscribe((feature) => {
        if (!this.showManualDrawPane) {
          this.selectedFeature = feature;
        }
      });

    this.dvMap?.onDeSelected
      .pipe(takeUntil(this.featureSelectUnsub$))
      .subscribe(() => (this.selectedFeature = null));

    if (this.addedAdjustments > 0) {
      this.adjustments.emit(this._generatePrescriptionAdjustmentModel());
    }
  }

  private _handleAdjustRequest(): void {
    if (this.wizardService.ongoingGridCalculation.value) {
      // if there are ongoing grid recalculations, we need to wait
      // for them to finnish
      const done = new Subject<void>();
      this.wizardService.ongoingGridCalculation
        .asObservable()
        .pipe(takeUntil(done))
        .subscribe((ongoing) => {
          if (!ongoing) {
            done.next();
            this.adjustments.emit(this._generatePrescriptionAdjustmentModel());
          }
        });
    } else {
      this.adjustments.emit(this._generatePrescriptionAdjustmentModel());
    }
  }

  private _generateAdjustment(): PrescriptionAdjustments {
    return {
      minRate: this.currentAdjustments.minRate.added
        ? Number(this.currentAdjustments.minRate.value)
        : null,
      maxRate: this.currentAdjustments.maxRate.added
        ? Number(this.currentAdjustments.maxRate.value)
        : null,
      cutoff: this.currentAdjustments.minRate.added
        ? this.currentAdjustments.cutoff.value
        : null,
      availableProduct: this.currentAdjustments.availableProduct.added
        ? Number(this.currentAdjustments.availableProduct.value)
        : null,
      averageRate: this.currentAdjustments.averageRate.added
        ? Number(this.currentAdjustments.averageRate.value)
        : null,
      flatAdjustment: this.currentAdjustments.flatAdjustment.added
        ? Number(this.currentAdjustments.flatAdjustment.value)
        : null,
      rateOverride: this.currentAdjustments.rateOverride.added
        ? this.currentAdjustments.rateOverride.value
        : null,
      subPrescription: {
        geodataFileId: this.currentAdjustments.subGeodataFile.added
          ? Number(this.currentAdjustments.subGeodataFile.value)
          : null,
      },
    };
  }

  _generatePrescriptionAdjustmentModel(): AdjustPrescriptionModel {
    return <AdjustPrescriptionModel>{
      adjustments: this._generateAdjustment(),
      prescription: this.grid,
    };
  }

  openAdjustments(): void {
    const ref = this.dialogService.open(AdjustmentsDialogComponent, null, {
      data: this.currentAdjustments,
    });

    ref.afterClosed().subscribe((result: Adjustments) => {
      if (result) {
        this.currentAdjustments = result;
      }
      this.addedAdjustments = Object.values(this.currentAdjustments)
        .map((a: Adjustment) => (a.added ? 1 : 0))
        .reduce((a, b) => a + b, 0);
      if (!this.currentAdjustments.rateOverride.added) {
        this._resetDeviations();
      }
      if (this.currentAdjustments.subGeodataFile.added) {
        this.getGeoDataList();
      }
    });
    return;
  }

  private getGeoDataList(): void {
    this.clientServivice.getGeodataFiles(FILE_TYPE.Prescription).subscribe(
      (data) => {
        this.geodataList = data.map(
          (geoData) =>
            <OptionModel>{
              key: geoData.fileId.toString(),
              label: geoData.name,
              disabled: false,
            }
        );
      },
      (error) => {
        this.fileError = error;
      }
    );
  }

  remove(adjustment: ADJUSTMENT_TYPE): void {
    this.currentAdjustments[adjustment].added = false;
    this.currentAdjustments[adjustment].value = null;
    if (adjustment === ADJUSTMENT_TYPE.rateOverride) {
      this._resetDeviations();
    }
    this.adjustRequest.next({
      key: adjustment,
      value: null,
    });
  }

  onHideManualDrawPane(payload: Record<string, number>): void {
    this.showManualDrawPane = false;

    if (payload) {
      this.onValueChange(payload, ADJUSTMENT_TYPE.rateOverride);
    }
  }

  resetCurrentAddjustments(): void {
    Object.values(this.currentAdjustments).forEach((adjustment) => {
      adjustment.added = false;
      adjustment.value = null;
    });
  }

  private _resetDeviations(): void {
    this.manualDrawService.reset();
  }
}
