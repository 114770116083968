<dv-dialog-content closeBtn="true">
  <div class="content">
    <div class="title">{{ 'Share' | dvt }}</div>
    <mat-horizontal-stepper #stepper>
      <mat-step>
        <div class="label">{{ 'Selected data' | dvt }}</div>
        <div class="file-list">
          <div class="file-list-item" *ngFor="let data of shareData">
            <div class="file-list-item-left">
              <div>{{ data.file.name }}</div>
              <mat-checkbox [(ngModel)]="data.shareRawData">
                {{ 'Include data' | dvt }}
              </mat-checkbox>
            </div>
            <div class="file-list-item-right">
              <button
                *ngIf="shareData.length !== 1"
                mat-flat-button
                (click)="removeFile(data.file.fileId)"
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <div class="step-second">
          <div class="section">
            <div class="label">{{ 'Recipients' | dvt }}</div>
            <div class="email-list">
              <div
                class="email-row"
                *ngFor="let email of emails; let i = index"
              >
                <mat-form-field class="email-row-form" appearance="outline">
                  <input
                    class="email-row-input"
                    matInput
                    type="email"
                    [formControl]="email"
                    [errorStateMatcher]="matcher"
                    [placeholder]="'Email' | dvt"
                  />
                  <mat-error *ngIf="email.hasError('email')">
                    {{ 'Please enter a valid email address' | dvt }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      (emails.length === 1 || i !== emails.length - 1) &&
                      email.hasError('required')
                    "
                  >
                    {{ 'Email is required' | dvt }}
                  </mat-error>
                </mat-form-field>
                <button
                  class="fab"
                  *ngIf="i === emails.length - 1"
                  mat-mini-fab
                  (click)="addEmail()"
                >
                  <mat-icon>add</mat-icon>
                </button>
                <button
                  class="fab fab-light"
                  *ngIf="i !== emails.length - 1"
                  mat-mini-fab
                  (click)="removeEmail(i)"
                >
                  <mat-icon>remove</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="section-message">
            <div class="label">{{ 'Message' | dvt }}</div>
            <textarea
              class="message"
              [placeholder]="'Enter message...' | dvt"
              rows="3"
              [(ngModel)]="message"
            ></textarea>
          </div>
          <div class="section-link">
            <div
              class="link-header"
              (click)="linkExpand = !linkExpand"
              [ngClass]="{ 'link-header-loading': fetching }"
            >
              <div class="link-header-left">
                <img
                  src="assets/images/expand-more.svg"
                  class="expand-icon"
                  [ngClass]="{ 'expand-icon-active': linkExpand }"
                />
                <div class="label label-link">{{ 'Preview links' | dvt }}</div>
              </div>
              <div class="link-header-right">
                <div class="link-count" *ngIf="!fetching; else spinner">
                  {{ '(' + links.length + ')' }}
                </div>
                <ng-template #spinner>
                  <mat-progress-spinner
                    diameter="30"
                    mode="indeterminate"
                  ></mat-progress-spinner>
                </ng-template>
              </div>
            </div>
            <div
              class="link-body"
              [ngClass]="{ 'link-body-expanded': linkExpand }"
            >
              <div class="link-item" *ngFor="let link of links">
                <div>{{ link.fileName }}</div>
                <div class="link-item-right">
                  <a target="blank" [href]="link.link">{{ link.link }}</a>
                  <mat-icon class="link-icon" (click)="copyLink(link.link)">
                    file_copy
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <mat-dialog-actions align="end">
    <button mat-flat-button [mat-dialog-close]="false">
      {{ 'Cancel' | dvt }}
    </button>
    <button
      *ngIf="selectedIndex === 0; else lastStep"
      mat-flat-button
      cdkFocusInitial
      color="primary"
      (click)="next(stepper)"
    >
      {{ 'Continue' | dvt }}
    </button>
    <ng-template #lastStep>
      <button
        mat-flat-button
        [disabled]="fetching || hasFetchError || hasErrors()"
        color="primary"
        (click)="complete()"
      >
        {{ 'Send' | dvt }}
      </button>
    </ng-template>
  </mat-dialog-actions>
</dv-dialog-content>
