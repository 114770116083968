import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ShareComponent } from 'app/share-page/share/share.component';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { DialogService } from 'app/components/dialog/dialog.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  private dialogRef: MatDialogRef<ShareComponent> = null;

  constructor(private http: HttpClient, private dialogService: DialogService) {}

  share(
    featureCollection: GeoJSON.FeatureCollection,
    fileIds: number[] = null
  ) {
    this.dialogRef = this.dialogService.open(ShareComponent, null, {
      minWidth: 250,
    });

    this.http
      .post<string>(`${environment.baseApiUrl}share`, featureCollection)
      .subscribe((key) => {
        this.dialogRef.componentInstance.setKey(key);

        if (fileIds !== null) {
          this.dialogRef.componentInstance.setFiles(fileIds);
          this.dialogRef.componentInstance.shareFiles();
        }
      });

    this.dialogRef.afterClosed().subscribe(() => (this.dialogRef = null));
  }

  setFiles(key: string, files: number[], queryParams?: string) {
    return this.http
      .put(
        environment.baseApiUrl +
          'share/' +
          key +
          '/files' +
          (queryParams ?? ''),
        { fileIds: files }
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  saveFiles(
    key: string,
    clientId: number,
    fileIds: number[],
    queryParams?: string
  ) {
    const obj = {
      clientId: clientId,
      fileIds: fileIds,
    };

    return this.http
      .put(
        environment.baseApiUrl +
          'share/' +
          key +
          '/files/copy' +
          (queryParams ?? ''),
        obj
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  saveGeoData(key: string, id: number): Observable<any> {
    return this.http.put(
      `${environment.baseApiUrl}share/${key}/geodata/save/${id}`,
      {}
    );
  }

  setComment(key: string, comment: string, queryParams?: string) {
    return this.http
      .put(
        environment.baseApiUrl +
          'share/' +
          key +
          '/comment' +
          (queryParams ?? ''),
        { comment: comment }
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getGeoDataKeys(data: GetGeoDataKeysPayload[]): Observable<GeoDataKeyItem[]> {
    return this.http.post<GeoDataKeyItem[]>(
      `${environment.baseApiUrl}share/geodata`,
      data
    );
  }

  shareGeoDataLinks(payload: DataSharePayload): Observable<any> {
    return this.http.post(
      `${environment.baseApiUrl}share/mail/cropmap/data`,
      payload
    );
  }

  getFileSetKeys(fileId: number[]): Observable<FileSetKeyItem[]> {
    return this.http.post<FileSetKeyItem[]>(
      `${environment.baseApiUrl}share/fileset`,
      fileId
    );
  }

  shareRawFileLinks(payload: DataSharePayload): Observable<any> {
    return this.http.post(
      `${environment.baseApiUrl}share/mail/cropmap/data`,
      payload
    );
  }

  saveFileToClient(key: string, clientId: number): Observable<any> {
    return this.http.put(
      `${environment.baseApiUrl}share/${key}/fileset/save/${clientId}`,
      {}
    );
  }

  downloadFile(key: string): Observable<HttpResponse<Blob>> {
    return this.http.post<any>(
      `${environment.baseApiUrl}share/${key}/fileset/download`,
      {},
      {
        observe: 'response',
        responseType: 'blob' as 'json',
        reportProgress: true,
      }
    );
  }
}

export interface GetGeoDataKeysPayload {
  geoDataId: number;
  includeData: boolean;
  preview: GeoJSON.FeatureCollection;
}

export interface GeoDataKeyItem {
  geoDataId: number;
  name: string;
  key: string;
}

export interface FileSetKeyItem {
  fileSetId: number[];
  name: string;
  key: string;
}

export interface DataSharePayload {
  recipients: string[];
  message: string;
  urls: string[];
}
