<mat-sidenav-container class="dv-map">
  <mat-sidenav mode="side" #leftMenu [position]="'start'" opened="true">
    <mat-list>
      <ng-content
        select="[map-tool-left]"
        class="mat-elevation-z2"
      ></ng-content>
    </mat-list>
  </mat-sidenav>
  <mat-sidenav-content style="margin-right: 0; width: 100%">
    <dv-map-loader *ngIf="loading" [text]="loadingText"></dv-map-loader>
    <div
      class="google-maps-btns column"
      fxLayoutGap="5px"
      style="position: absolute; bottom: 30px; left: 8px; z-index: 1"
    >
      <div class="column">
        <dv-map-search></dv-map-search>
        <dv-map-background
          (backgroundChange)="setType($event)"
        ></dv-map-background>
      </div>
      <dv-map-zoom (zoomIn)="zoomIn()" (zoomOut)="zoomOut()"></dv-map-zoom>
    </div>
    <div
      class="dv-map-google"
      style="width: 100%; height: 100%"
      (dvMapInit)="mapInit($event)"
    ></div>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
