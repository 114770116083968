<dv-drag-container [active]="showTable">
  <div class="content">
    <dv-wizard-table *ngIf="showTable"></dv-wizard-table>
    <dv-poi *ngIf="showPoi"></dv-poi>
    <dv-field-interpolation *ngIf="showInterpolation"></dv-field-interpolation>
    <dv-cropsat-index
      [parcells]="cropsatParcels"
      *ngIf="showCropSat"
    ></dv-cropsat-index>
    <dv-clay-content
      *ngIf="showClayContent"
      [clientId]="clientId"
      [fieldIds]="selectedFieldIds"
    ></dv-clay-content>
  </div>
</dv-drag-container>
