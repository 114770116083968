<div class="pane-header">
  <div class="title">2. {{ 'Select method' | dvt }}</div>
</div>
<div *ngIf="loading; else functionsList" class="loader">
  <mat-spinner [diameter]="100"></mat-spinner>
</div>
<ng-template #functionsList>
  <div class="function-list">
    <dv-selectable-card
      *ngFor="let function of functions; let i = index"
      [title]="function.name"
      [description]="function.shortDescription"
      [selected]="selectedIndex === i"
      (cardSelect)="selectFunction(i)"
    ></dv-selectable-card>
  </div>
</ng-template>
