import { Injectable } from '@angular/core';
import { DeviationModel } from 'app/models/interpolation.model';
import { PrescriptionService } from 'app/services/prescription.service';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ManualDrawPayload } from '../../step-adjust.types';

@Injectable()
export class ManualDrawService {
  private deviations = new BehaviorSubject<DeviationModel[]>([]);
  private tempDeviations = new BehaviorSubject<DeviationModel[]>([]);
  private activeDeviationIndex = new BehaviorSubject<number>(-1);
  deviations$ = this.deviations.asObservable();
  tempDeviations$ = this.tempDeviations.asObservable();
  activeDeviationIndex$ = this.activeDeviationIndex.asObservable();

  private idCounter = 0;
  private tempFeatureDeviationMap = new Map<string, number>();
  private savedFeatureDeviationMap = new Map<string, number>();

  constructor(private prescriptionService: PrescriptionService) {}

  clearTempDeviations(mapData: google.maps.Data): void {
    const observables = Array.from(this.tempFeatureDeviationMap.entries()).map(
      (entry) => {
        const feature = mapData.getFeatureById(entry[0]);
        return this.removeFeatureFromDeviationById(feature, entry[1]);
      }
    );

    forkJoin(observables).subscribe(() => this.tempDeviations.next([]));
  }

  clearDeviations(): void {
    this.deviations.next([]);
  }

  addTempDeviation(): void {
    this.tempDeviations.pipe(take(1)).subscribe((tempDeviations) => {
      const newDeviations = [...tempDeviations];
      newDeviations.push({
        id: this.idCounter,
        color: this.prescriptionService.getDeviationColor(this.idCounter),
        features: [],
        deviationRate: 0,
      });

      this.idCounter += 1;

      this.tempDeviations.next(newDeviations);
      this.activeDeviationIndex.next(newDeviations.length - 1);
    });
  }

  deleteTempDeviation(deviation: DeviationModel): void {
    this.setActiveDeviationIndex(-1);

    const observables = deviation.features.map((feature) =>
      this.removeFeatureFromDeviationById(feature, deviation.id)
    );

    forkJoin(observables)
      .pipe(
        switchMap(() => this.tempDeviations$),
        take(1)
      )
      .subscribe((tempDeviations) => {
        this.tempDeviations.next([
          ...tempDeviations.filter((element) => element.id !== deviation.id),
        ]);
      });
  }

  setActiveDeviationIndex(index: number): void {
    this.activeDeviationIndex.next(index);
  }

  saveAndGetPayload(): Observable<ManualDrawPayload> {
    return this.tempDeviations.pipe(
      take(1),
      tap((deviations) => {
        this.deviations.next(deviations);
        this.savedFeatureDeviationMap = new Map(
          this.tempFeatureDeviationMap.entries()
        );
      }),
      map((deviations) => {
        const payload: ManualDrawPayload = {
          rateOverride: {},
        };

        deviations.forEach((deviation) => {
          return deviation.features.reduce((previous, current) => {
            previous.rateOverride[current.getId()] = deviation.deviationRate;

            return previous;
          }, payload);
        });

        return payload;
      })
    );
  }

  reset(): void {
    this.clearDeviations();
    this.tempDeviations.next([]);
    this.idCounter = 0;
    this.savedFeatureDeviationMap = new Map();
    this.tempFeatureDeviationMap = new Map();
  }

  restoreSavedDeviations(mapData: google.maps.Data): void {
    this.tempFeatureDeviationMap = new Map(
      this.savedFeatureDeviationMap.entries()
    );
    const mapEntries = Array.from(this.tempFeatureDeviationMap.entries()).map(
      (entry) => ({ featureId: entry[0], deviationId: entry[1] })
    );

    this.deviations$.pipe(take(1)).subscribe((deviations) => {
      const deviationCopies = deviations.reduce((previous, current) => {
        const deviationFeatures = mapEntries
          .filter((entry) => entry.deviationId === current.id)
          .map((entry) => mapData.getFeatureById(entry.featureId));

        const deviation = {
          ...current,
          features: [],
        };
        previous.push(deviation);

        deviationFeatures.forEach((feature) =>
          this.addFeatureToDeviation(feature, deviation)
        );

        return previous;
      }, []);

      this.tempDeviations.next(deviationCopies);

      return this.tempDeviations$;
    });
  }

  toggleFeatureDeviation(
    feature: google.maps.Data.Feature,
    deviation: DeviationModel,
    forceAdd = false
  ): void {
    if (deviation) {
      const featureId = String(feature.getId());
      const featureDeviationId = this.tempFeatureDeviationMap.get(featureId);

      if (featureDeviationId !== undefined) {
        if (featureDeviationId === deviation.id && !forceAdd) {
          this.removeFeatureFromDeviation(feature, deviation);
        } else {
          this.removeFeatureFromDeviationById(
            feature,
            featureDeviationId
          ).subscribe(() => this.addFeatureToDeviation(feature, deviation));
        }
      } else {
        this.addFeatureToDeviation(feature, deviation);
      }
    }
  }

  private addFeatureToDeviation(
    feature: google.maps.Data.Feature,
    deviation: DeviationModel
  ): void {
    if (!feature) {
      return;
    }

    feature.setProperty('oldColor', feature.getProperty('color'));
    feature.setProperty('color', deviation.color);

    const featureId = String(feature.getId());
    this.tempFeatureDeviationMap.set(featureId, deviation.id);
    deviation.features.push(feature);
  }

  private removeFeatureFromDeviation(
    feature: google.maps.Data.Feature,
    deviation: DeviationModel
  ): void {
    feature.setProperty('color', feature.getProperty('oldColor'));
    feature.removeProperty('oldColor');

    const featureId = String(feature.getId());
    const featureIndex = this.featureIndexInDeviation(deviation, featureId);
    this.tempFeatureDeviationMap.delete(featureId);
    deviation.features.splice(featureIndex, 1);
  }

  private removeFeatureFromDeviationById(
    feature: google.maps.Data.Feature,
    deviationId: number
  ): Observable<DeviationModel[]> {
    return this.tempDeviations$.pipe(
      take(1),
      tap((deviations) => {
        const tempDeviation = deviations.find(
          (element) => element.id === deviationId
        );
        this.removeFeatureFromDeviation(feature, tempDeviation);
      })
    );
  }

  private featureIndexInDeviation(
    deviation: DeviationModel,
    featureId: number | string
  ): number {
    return deviation.features.findIndex((element) => {
      return element.getId() === Number(featureId);
    });
  }
}
