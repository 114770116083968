import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WrappedNestedList } from '../../nested-preview-list.types';
import { FunctionGeoDataDefinition } from 'app/views/prescription-wizard-page/components/step-settings/step-settings.types';
import { DvmCardModule } from '@dvm/components';
import { NgClass, NgStyle } from '@angular/common';
import { FilePreviewListComponent } from 'app/views/prescription-wizard-page/components/step-settings/components/file-section/components/file-preview-list/file-preview-list.component';

@Component({
  selector: 'dv-nested-list-item',
  templateUrl: 'nested-list-item.component.html',
  styleUrls: ['nested-list-item.component.scss'],
  standalone: true,
  imports: [DvmCardModule, FilePreviewListComponent, NgClass, NgStyle],
})
export class NestedListItemComponent {
  @Input() list: WrappedNestedList<FunctionGeoDataDefinition>;
  @Input() index: number;
  @Input() disabled: boolean;
  @Output() fileChange = new EventEmitter<number[]>();

  expanded = false;
  private readonly MAX_HEIGHT_PX = 140;
  private readonly ITEM_HEIGHT_PX = 34;
  private readonly VISIBLE_ROW_NUM = 5;

  get listHeight(): string {
    const { length } = this.list.functionParameter.definition.files;
    const height =
      length > this.VISIBLE_ROW_NUM
        ? this.MAX_HEIGHT_PX
        : length * this.ITEM_HEIGHT_PX;

    return `${height}px`;
  }

  onFileChange(fileIds: number[]): void {
    this.list.functionParameter.definition.selectedFiles = fileIds;
    this.fileChange.emit();
  }
}
