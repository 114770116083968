<dv-sliding-pane [showPane]="showPane">
  <div class="content">
    <dvm-text class="title" h2>{{ 'Basic settings' | dvt }}</dvm-text>
    <div class="settings" *ngIf="tempGridSettings">
      <div class="settings-item">
        <dvm-select
          label="{{ '_prescription_adjustment_gridSize' | dvt : '_grid size' }}"
          [options]="gridSizeOptions"
          [(ngModel)]="tempGridSettings.cellSize"
          (selectionChange)="onGridChange($event)"
        ></dvm-select>
      </div>
      <div class="settings-item">
        <div class="adjustments-item-label">
          {{ '_prescription_adjustment_gridRotation' | dvt : '_grid rotation' }}
        </div>
        <div class="settings-item-slider">
          <mat-slider
            min="0"
            max="90"
            [(ngModel)]="tempGridSettings.angle"
            (valueChange)="onGridChange($event)"
            color="primary"
          ><input matSliderThumb />
            <input matSliderThumb />
          </mat-slider>
          <div class="settings-item-slider-value">
            {{ tempGridSettings?.angle }}&deg;
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons">
    <dvm-button
      class="button"
      size="large"
      secondary
      [disabled]="loading"
      (click)="closePane()"
    >
      {{ 'Close' | dvt }}
    </dvm-button>
    <dvm-button
      class="button"
      size="large"
      primary
      [disabled]="loading"
      (click)="closePane(true)"
    >
      {{ 'Save' | dvt }}
    </dvm-button>
  </div>
</dv-sliding-pane>
