import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  MapService,
  LAYER_NAME_INTERPOLATION,
} from 'app/components/map/map.service';
import { Subject, Subscription, zip } from 'rxjs';
import { CropsatLegendComponent } from '../cropsat-legend/cropsat-legend.component';
import { ClientService } from 'app/services/client.service';
import { first, takeUntil } from 'rxjs/operators';
import { MapStateService } from 'app/services/map-state.service';
import { FieldInterpolationService } from 'app/services/field-interpolation.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-cropsat-index',
  templateUrl: './cropsat-index.component.html',
  styleUrls: ['./cropsat-index.component.scss'],
})
export class CropsatIndexComponent implements OnInit, OnDestroy {
  private _unsub$ = new Subject<void>();

  dates: string[] = [];
  selectedDate: string;
  loading: boolean;
  mapSub: Subscription;
  error: string = null;
  fields: GeoJSON.FeatureCollection;

  @ViewChild('legend') legend: CropsatLegendComponent;

  constructor(
    private translateService: DvToolbarTranslateService,
    private mapService: MapService,
    private cd: ChangeDetectorRef,
    private clientService: ClientService,
    private mappingSrv: MapStateService,
    private interpolateSrv: FieldInterpolationService
  ) {}

  ngOnInit(): void {
    this.mappingSrv
      .getSelectedFeatures()
      .pipe(takeUntil(this._unsub$))
      .subscribe((features) => {
        if (features?.features.length === 1) {
          this.fields = features;
          this.loadDates();
        }
      });
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
    if (this.mapSub) {
      this.mapSub.unsubscribe();
    }
  }

  loadDates(): void {
    this.loading = true;
    this.mapService.getCropSatDates(this.fields).subscribe((dates) => {
      this.dates = dates;
      if (this.dates && this.dates.length > 0) {
        this.selectedDate = dates[0].date;
        this.loadMap();
      } else {
        this.loading = false;
        this.error = this.translateService.t('No data found for this year');
        this.cd.markForCheck();
      }
    });
  }

  loadMap(): void {
    this.loading = true;
    this.mapService
      .getCropSatImage(this.fields, this.selectedDate)
      .subscribe((data) => {
        this.mapSub = this.mapService.mainMap().subscribe((map) => {
          map.removeLayer(LAYER_NAME_INTERPOLATION);
          map.addGeoJson(data.featureCollection, LAYER_NAME_INTERPOLATION);
          this.legend.setData(data);
          this.loading = false;
          this.cd.markForCheck();
        });
      });
  }

  openCropsat(): void {
    zip(
      this.clientService.client().pipe(first()),
      this.mapService.mainMap().pipe(first())
    ).subscribe((res) => {
      //set the correct country in cropsat
      const url = res[0].land;

      /*
        ** this will zoom in at cropsat at a given center location and a given zoom
        ** but cropsat re center the view when skiften is loaded, so that needs to be fixed
        ** sins we allways is loged in when we navigate to cropsat from this link
                url += '#@' + res[1].map.getCenter().lat();
                url += ',' + res[1].map.getCenter().lng();
                url += ',' + res[1].map.getZoom()+'z';
        */
      window.open('https://cropsat.com/' + url, '_blank');
    });
  }

  close(): void {
    this.interpolateSrv.resetInterpolationType();
  }
}
