<div mat-dialog-content>
    <h2>{{data.text}}</h2>
    <mat-form-field  appearance="outline" [floatLabel]="'always'">
        <mat-label>{{data.placeholder}}</mat-label>
        <input [(ngModel)]="value" matInput/>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="save()">{{'Ok' | dvt }}</button>
    <button mat-raised-button (click)="cancel()">{{'Cancel' | dvt}}</button>

</div>