<mat-dialog-content>
    <button mat-dialog-close mat-mini-fab color="primary">
        <mat-icon>close</mat-icon>
    </button>
    <h2 mat-dialog-title>
        {{'About CropMAP'|dvt}}

    </h2>
    <p>{{'Contact' | dvt}}: <a target="_blank" [href]="supportUri">{{supportUri}}</a>
    </p>
    <p>{{'Version' | dvt}}: {{version}}</p>
</mat-dialog-content>
