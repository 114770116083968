import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientService } from 'app/services/client.service';
import { forkJoin, combineLatest } from 'rxjs';

@Component({
  selector: 'dv-edit-farms',
  templateUrl: './edit-farms.component.html',
  styleUrls: ['./edit-farms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditFarmsComponent implements OnInit {

  constructor(private clientService: ClientService, private dialogRef: MatDialogRef<EditFarmsComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  save() {
    let arr: any[] = [];
    this.data.brukenheter.forEach(b => {
      arr.push(this.clientService.updateBrukningsenhet(b));
    });

    if (arr.length > 0)
      combineLatest(arr).subscribe(res => {
        this.dialogRef.close(true);
      })
    else
      this.dialogRef.close(false);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
