import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'dv-connected-farm-redirect',
  templateUrl: './connected-farm-redirect.component.html',
  styleUrls: ['./connected-farm-redirect.component.scss']
})

export class ConnectedFarmRedirectComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ConnectedFarmRedirectComponent>, @Inject(MAT_DIALOG_DATA) public data: { url: string }) {
  }

  ngOnInit() {
  }

  openUrl() {
    window.open(this.data.url, '_blank');
    this.dialogRef.close();
  }
}
