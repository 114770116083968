import { Component, Inject, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShareService } from 'app/services/share.service';
import {
  RawFiledataListShareDialogData,
  LinkItem,
  ShareData,
} from './fileset-list-share-dialog-types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertType, DvmDialogService } from '@dvm/components';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-fileset-list-share-dialog',
  templateUrl: 'fileset-list-share-dialog.component.html',
  styleUrls: ['fileset-list-share-dialog.component.scss'],
})
export class FileSetListShareDialogComponent implements OnInit {
  fetching = false;
  hasFetchError = false;
  emails: FormControl[] = [
    new FormControl('', [Validators.required, Validators.email]),
  ];
  emailError = false;
  shareData: ShareData[] = [];
  links: LinkItem[] = [];
  message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: RawFiledataListShareDialogData,
    private dialogRef: MatDialogRef<FileSetListShareDialogComponent>,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private translate: DvToolbarTranslateService,
    private shareService: ShareService,
    private dialogService: DvmDialogService
  ) {}

  ngOnInit(): void {
    this.shareData = this.data.selectedFiles.map((file) => ({
      file,
    }));
    this.fetchLinks();
  }

  private fetchLinks(): void {
    this.fetching = true;
    const data: number[] = this.shareData.map((data) => data.file.fileId);

    this.shareService.getFileSetKeys(data).subscribe(
      (keyItems) => {
        this.fetching = false;
        this.hasFetchError = false;
        if (keyItems.length === 1) {
          this.links.push({
            fileName: keyItems[0].name,
            link: `${window.location.protocol}//${window.location.host}/share/${keyItems[0].key}`,
          });
        }
      },
      () => {
        this.fetching = false;
        this.hasFetchError = true;
        this.dialogService.alert(
          AlertType.warning,
          this.translate.t('Something went wrong'),
          this.translate.tl(
            '_Unexpected_error_file_share',
            '_An unexpected error has occurred and it was not possible to provide links for the files you have chosen to share'
          )
        );
        this.dialogRef.close();
      }
    );
  }

  removeFile(fileId: number): void {
    this.shareData = this.shareData.filter(
      (data) => data.file.fileId !== fileId
    );
  }

  complete(): void {
    this.dialogRef.close({
      data: {
        links: this.links,
        emails: this.emails.map((email) => email.value),
        message: this.message,
      },
    });
  }

  addEmail(): void {
    this.emails.push(
      new FormControl('', [Validators.required, Validators.email])
    );
  }

  removeEmail(index: number): void {
    this.emails.splice(index, 1);
  }

  copyLink(link: string): void {
    this.clipboard.copy(link);

    this.snackBar.open(this.translate.t('Copied to clipboard'), null, {
      duration: 3000,
    });
  }

  hasErrors(): boolean {
    return (
      this.emails.filter(
        (email) => email.hasError('email') || email.hasError('required')
      ).length > 0
    );
  }
}
