import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImportDoneComponent } from './import-done/import-done.component';
import { ImportViewComponent } from './import-view.component';

@Injectable({
  providedIn: 'root',
})
export class ImportService implements OnDestroy {
  private _unsub$: Subject<void> = new Subject<void>();
  private importRef: MatDialogRef<ImportViewComponent> = null;
  fileStatus$ = new BehaviorSubject<string>(null);
  fetchFiles$ = new BehaviorSubject<boolean>(false);

  constructor(private dialog: MatDialog) {}

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  tryOpenImportDone(
    selectedYear: number = null,
    hideImportStatus = false
  ): MatDialogRef<ImportDoneComponent> {
    this.fetchFiles$.next(true);
    return this.dialog.open(ImportDoneComponent, {
      panelClass: 'dialog-no-padding',
      data: {
        importStatus: [],
        selectedYear: selectedYear,
        hideImportStatus: hideImportStatus,
      },
      width: '450px',
      disableClose: true,
    });
  }

  open(): void {
    this.importRef = this.dialog.open(ImportViewComponent, {
      panelClass: 'dialog-no-padding',
    });
    this.importRef.componentInstance.fileStatusUpdate
      .pipe(takeUntil(this._unsub$))
      .subscribe((fileObj) => {
        this.setFileStatus(fileObj.file);
      });
    this.importRef.afterClosed().subscribe(() => {
      this.importRef = null;
    });
  }

  setFetchFiles(fetch): void {
    this.fetchFiles$.next(fetch);
  }

  getFetchFiles(): Observable<boolean> {
    return this.fetchFiles$.asObservable();
  }

  getFileStatus(): Observable<string> {
    return this.fileStatus$.asObservable();
  }

  setFileStatus(fileStatus: string): void {
    this.fileStatus$.next(fileStatus);
  }
}
