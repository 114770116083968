<div class="filler"></div>
<div class="main-area">
  <dv-menu></dv-menu>
  <main>
    <router-outlet></router-outlet>
  </main>
</div>

<div *ngIf="loading" class="loading dialog-over-z">
  Laddar
  <div class="sk-cube-grid">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>
