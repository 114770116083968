import { Component, Input, OnDestroy } from '@angular/core';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';
import {
  InterpolationCollection,
  InterpolationModel,
  MappingLegendModel,
} from 'app/models/interpolation.model';
import { PropertyInfoModel } from 'app/models/models';
import { ClientService } from 'app/services/client.service';
import { FieldInterpolationService } from 'app/services/field-interpolation.service';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dv-interpolation-basis-preview',
  templateUrl: './interpolation-basis-preview.component.html',
  styleUrls: ['./interpolation-basis-preview.component.scss'],
})
export class InterpolationBasisPreviewComponent implements OnDestroy {
  @Input() boundary: GeoJSON.Feature[];
  @Input() crop: string;
  @Input() color: string;

  ready = false;
  legend: MappingLegendModel;
  interpolation: InterpolationModel;
  error: string;
  expanded = false;
  pointMode = false;

  private disabledFeatures: number[];
  private type: string;
  private property: PropertyInfoModel;
  private geodataIds: number[];
  private _unsub$: Subject<void> = new Subject<void>();

  constructor(
    private interpolateSrv: FieldInterpolationService,
    private clientSrv: ClientService,
    private translateSrv: DvToolbarTranslateService
  ) {
    combineLatest([
      this.interpolateSrv.getSelectedGeodata(),
      this.interpolateSrv.getInterpolationType(),
      this.interpolateSrv.getInterpolationProperty(),
    ])
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        ([geodataIds, type, property]) => {
          this.ready = false;
          this.type = type;
          this.property = property;
          this.geodataIds = geodataIds;
          if (this.expanded) {
            this.getInterpolation();
          }
        },
        (err) => {
          this.handleGeodataError(err.message);
        }
      );
  }

  toggleActiveField(id: number): void {
    this.interpolateSrv.disabledFields$
      .pipe(take(1))
      .subscribe((disabledFeatures) => {
        this.disabledFeatures = disabledFeatures;
        if (this.disabledFeatures) {
          if (this.disabledFeatures.includes(id)) {
            this.disabledFeatures.splice(this.disabledFeatures.indexOf(id), 1);
          } else {
            this.disabledFeatures.push(id);
          }
        } else {
          this.disabledFeatures = [id];
        }
        this.interpolation = null;
        this.getInterpolation();
        this.interpolateSrv.setDisabledFields([...this.disabledFeatures]);
      });
  }

  isFieldActive(id: number): boolean {
    return !(this.disabledFeatures && this.disabledFeatures.includes(id));
  }

  private handleGeodataError(errMsg: string): void {
    this.ready = true;
    this.error = errMsg;
  }

  private handleInterpolation(interpolation: InterpolationModel): void {
    this.legend = interpolation.legend;
    this.interpolation = interpolation;
    this.ready = true;
  }

  getInterpolation(): void {
    if (!this.type || !this.property || this.geodataIds?.length === 0) {
      const error = this.translateSrv.t(
        '_interpolaiton_missing input',
        '_Interpolation could not be calculated due to missing input values'
      );
      this.handleGeodataError(error);
      return;
    }
    this.error = '';
    this.expanded = true;
    this.clientSrv
      .getGeoDataInterpolation(
        this.type,
        this.property.field,
        {
          type: 'FeatureCollection',
          features: this.boundary.filter(
            (ft) =>
              !this.disabledFeatures ||
              !this.disabledFeatures.includes(<number>ft.id)
          ),
        },
        this.geodataIds
      )
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        (interpolation) => this.handleInterpolationSuccess(interpolation),
        () =>
          this.handleGeodataError(this.translateSrv.t('Something went wrong'))
      );
  }

  handleInterpolationSuccess(interpolation: InterpolationCollection): void {
    if (interpolation?.interpolations) {
      this.handleInterpolation(interpolation.interpolations);
    } else {
      const error = this.translateSrv.t(
        '_interpolaiton_no_matching_fields_error',
        '_There are no matching layers and parcels in this request'
      );
      this.handleGeodataError(error);
    }
  }

  ngOnDestroy(): void {
    this._unsub$?.next();
    this._unsub$?.complete();
  }
}
