<dvm-table [dataSource]="dataSource">
  <ng-container [dvmColumnDef]="LEGEND_COLUMN.AREAL">
    <th dvm-header-cell *dvmHeaderCellDef>
      {{ '_areal' | dvt: '_Area' }}: {{ totalArea | number: '1.1-1' }}
    </th>
    <td dvm-cell *dvmCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <ng-container [dvmColumnDef]="LEGEND_COLUMN.MIN">
    <th dvm-header-cell *dvmHeaderCellDef>
      <div class="header-with-color">
        <div
          class="header-color"
          [style.background]="LEGEND_VALUE_COLORS.MIN"
        ></div>
        {{ '_min' | dvt: '_Min' }} ({{ seedUnit }})
      </div>
    </th>
    <td dvm-cell *dvmCellDef="let element">{{ element.min }}</td>
  </ng-container>

  <ng-container [dvmColumnDef]="LEGEND_COLUMN.AVERAGE">
    <th dvm-header-cell *dvmHeaderCellDef>
      <div class="header-with-color">
        <div
          class="header-color"
          [style.background]="LEGEND_VALUE_COLORS.AVERAGE"
        ></div>
        {{ '_average' | dvt: '_Average' }} ({{ seedUnit }})
      </div>
    </th>
    <td dvm-cell *dvmCellDef="let element">{{ element.average }}</td>
  </ng-container>

  <ng-container [dvmColumnDef]="LEGEND_COLUMN.MAX">
    <th dvm-header-cell *dvmHeaderCellDef>
      <div class="header-with-color">
        <div
          class="header-color"
          [style.background]="LEGEND_VALUE_COLORS.MAX"
        ></div>
        {{ '_max' | dvt: '_Max' }} ({{ seedUnit }})
      </div>
    </th>
    <td dvm-cell *dvmCellDef="let element">{{ element.max }}</td>
  </ng-container>

  <ng-container [dvmColumnDef]="LEGEND_COLUMN.SUM">
    <th dvm-header-cell *dvmHeaderCellDef>
      {{ '_sum' | dvt: '_Sum' }} ({{ seedUnitTotal }})
    </th>
    <td dvm-cell *dvmCellDef="let element">{{ element.total }}</td>
  </ng-container>

  <tr dvm-header-row *dvmHeaderRowDef="displayedColumns"></tr>
  <tr
    dvm-row
    *dvmRowDef="let row; columns: displayedColumns"
    [ngClass]="{ 'base-row': row.isBase }"
  ></tr>
</dvm-table>
