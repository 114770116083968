import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'dv-sliding-pane',
  templateUrl: 'sliding-pane.component.html',
  styleUrls: ['sliding-pane.component.scss'],
})
export class SlidingPaneComponent {
  @Input() showPane: boolean;

  @HostBinding('class.visible') get visible(): boolean {
    return !!this.showPane;
  }

  @HostBinding('class.hidden') get hidden(): boolean {
    return !this.showPane;
  }
}
