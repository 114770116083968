import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MapService } from 'app/components/map/map.service';
import { MappingWizardService } from 'app/components/mapping-view/mapping-wizard/mapping-wizard.service';
import { AnalyzeFieldModel } from 'app/models/analyze-field.model';
import { FILE_TYPE } from 'app/models/file.model';
import { InterpolationModel } from 'app/models/interpolation.model';
import { LAYER_TYPE } from 'app/models/layertype.model';
import { FieldGridModel, PropertyInfoModel } from 'app/models/models';
import { ClientService } from 'app/services/client.service';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import {
  Field,
  FieldGridModelWithYear,
  Group,
  File,
  InterpolationChange,
  PointLabelChange,
} from './map-split-prop-picker.types';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-map-split-prop-picker',
  templateUrl: './map-split-prop-picker.component.html',
  styleUrls: ['./map-split-prop-picker.component.scss'],
})
export class MapSplitPropPickerComponent implements AfterViewInit, OnDestroy {
  @Input() setInitValues: boolean;
  @Input() fieldToAnalyzeModel: AnalyzeFieldModel;
  @Input() feature: GeoJSON.FeatureCollection;
  @Input() interpolationProperties: PropertyInfoModel[];
  @Output() interpolationChanged = new EventEmitter<InterpolationChange>();
  @Output() pointLabelChange = new EventEmitter<PointLabelChange>();
  data: FieldGridModelWithYear[] = [];
  groups: Group[] = [];
  currentGroup: Group;
  fields: Field[] = [];
  currentField: Field;
  files: File[] = [];
  currentFiles: File[] = [];
  selectableFiles: File[];
  selectableFileNo = -1;
  years: number[] = [];
  currentYear: number;
  clientYear: number;
  error: string;
  nodata = false;
  advExpanded = false;
  currentSelection = new AnalyzeFieldModel();
  loaded = false;
  working = false;
  pointMode = false;
  labelMode = false;
  FILE_TYPE = FILE_TYPE;
  readonly CROP_SAT_NAME = LAYER_TYPE.VegIndex;
  private _unsub$: Subject<void> = new Subject<void>();

  get showDataPointSlides(): boolean {
    return (
      this.loaded &&
      !this.working &&
      this.currentGroup &&
      this.currentGroup.name !== FILE_TYPE.Prescription &&
      this.currentGroup.name !== this.CROP_SAT_NAME
    );
  }

  get selectedFileIds(): number[] {
    return this.selectableFiles
      .filter((f) => f.checked)
      .map((f) => f.geoDataId);
  }

  constructor(
    private clientService: ClientService,
    private cd: ChangeDetectorRef,
    private translateService: DvToolbarTranslateService,
    private mapService: MapService,
    private wizSrv: MappingWizardService
  ) {}

  ngAfterViewInit(): void {
    if (!this.fieldToAnalyzeModel) {
      return;
    }

    const fieldIds: number[] = this.fieldToAnalyzeModel.feature.features.map(
      (feature) => feature.id
    );
    this.clientService
      .getGeodataFromFields(fieldIds)
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        const yearGroups = this.getYearGroups(res);

        if (res && res.length > 0) {
          yearGroups.forEach((yearGroup) => this.data.push(...yearGroup));

          this.clientService
            .clientAr()
            .pipe(takeUntil(this._unsub$))
            .subscribe((clientYear) => {
              this.clientYear = clientYear;
              this.setGroups();
              this.initValues();
              this.loaded = true;
            });
        } else {
          this.loaded = true;
          this.nodata = true;
        }
      });
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  toggleInterpolationPoints(showPoints: boolean): void {
    this.pointMode = showPoints;
    this.working = true;

    this.getInterpolation().subscribe((interpolation) => {
      this.interpolationChanged.emit({
        interpolation,
        pointMode: this.pointMode,
      });
      this.working = false;
    });
  }

  togglePointLabels(showPointLabels: boolean): void {
    this.labelMode = showPointLabels;

    const fileIds: number[] = this.selectableFiles
      .filter((file) => file.checked)
      .map((file) => file.geoDataId);

    if (fileIds.length === 1) {
      this.working = true;

      this.clientService
        .getGeoData(fileIds[0])
        .pipe(takeUntil(this._unsub$), take(1))
        .subscribe((points) => {
          this.pointLabelChange.emit({
            labelMode: this.labelMode,
            points,
            field: this.feature,
            propertyKey: this.currentField.name,
          });
          this.working = false;
        });
    } else {
      this.pointLabelChange.emit();
    }
  }

  private getYearGroups(
    result: FieldGridModel[]
  ): Map<string, FieldGridModelWithYear[]> {
    return result.reduce((prev, current) => {
      const year = current.createdDate.substring(0, 4);
      const entry = {
        ...current,
        year: Number(year),
      };

      prev.has(year) ? prev.get(year).push(entry) : prev.set(year, [entry]);

      return prev;
    }, new Map<string, FieldGridModelWithYear[]>());
  }

  initValues(): void {
    if (
      this.fieldToAnalyzeModel &&
      this.fieldToAnalyzeModel.year &&
      this.fieldToAnalyzeModel.group
    ) {
      this.currentYear = this.fieldToAnalyzeModel.year;
      this.selectGroup(
        this.groups.find(
          (g) =>
            g.name.toLowerCase() ===
            this.fieldToAnalyzeModel.group.toLowerCase()
        )
      );
      this.selectYear(this.currentYear);
      if (this.fieldToAnalyzeModel.type) {
        this.selectField(
          this.fields.find(
            (f) =>
              f.name.toLowerCase() ===
              this.fieldToAnalyzeModel.type.toLowerCase()
          )
        );
      }

      if (
        this.fieldToAnalyzeModel.fileIds &&
        this.fieldToAnalyzeModel.fileIds.length > 0
      ) {
        this.files.forEach((f) => {
          f.checked = false;
        });

        this.fieldToAnalyzeModel.fileIds.forEach((fileId) => {
          const file = this.files.find((f) => f.geoDataId === fileId);
          if (file) {
            this.checkFile(file);
          }
        });

        if (!this.files.some((f) => f.checked)) {
          // CHANGE THIS
          this.files[0].checked = true;
        }
      }
    } else if (this.setInitValues) {
      if (this.groups && this.groups.length > 0) {
        this.selectGroup(this.groups[0]);
      }

      this.currentYear = this.years[0];
      this.selectYear(this.currentYear);

      if (this.fields && this.fields.length > 0) {
        this.selectField(this.fields[0]);
      }
    }
  }

  selectYear(year: number): void {
    this.currentYear = year;
    this.setFields();
    this.currentField = null;
    this.interpolationChanged.emit({
      interpolation: null,
      pointMode: this.pointMode,
    });
  }

  selectGroup(group: Group): void {
    this.labelMode = false;
    this.pointMode = false;
    this.currentGroup = group;
    this.setYears();
    this.selectableFileNo = this.wizSrv.getNoOfSelectableLayers(group.name);
  }

  selectField(field: Field, skipSetFiles = false): void {
    this.currentField = field;

    if (!skipSetFiles || !this.selectableFiles.length) {
      this.setFiles();

      this.selectableFiles = this.files.filter(
        (f) => f.year === this.currentYear
      );

      if (this.selectableFiles.length > 0) {
        if (this.selectableFileNo === -1) {
          for (const file of this.selectableFiles) {
            file.checked = true;
          }
        } else {
          this.selectableFiles[0].checked = true;
        }
      }
    }

    if (this.currentGroup.name === LAYER_TYPE.VegIndex) {
      this.cropsat();
    } else {
      this.interpolate();
    }
  }

  checkFile(file: File): void {
    file.checked = !file.checked;
    this.selectableFiles = [...this.selectableFiles];
    this.pointMode = false;
    this.labelMode = false;
    this.cd.markForCheck();
    this.interpolate();
  }

  private getInterpolation(): Observable<InterpolationModel> {
    const files = this.selectableFiles
      .filter((f) => f.checked)
      .map((f) => f.geoDataId);

    if (this.pointMode) {
      return this.clientService.getGeoDataInterpolationPoints(
        this.currentGroup.type,
        this.currentField.name,
        this.feature,
        files
      );
    } else {
      return this.clientService
        .getGeoDataInterpolation(
          this.currentGroup.type,
          this.currentField.name,
          this.feature,
          files
        )
        .pipe(
          takeUntil(this._unsub$),
          map((interpolation) => interpolation.interpolations)
        );
    }
  }

  interpolate(): void {
    this.error = null;
    this.working = true;

    if (!this.currentField || !this.selectedFileIds.length) {
      return;
    }

    this.getInterpolation()
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        (interpolation) => {
          this.interpolationChanged.emit({
            interpolation,
            pointMode: this.pointMode,
          });
          this.togglePointLabels(this.labelMode);
          this.working = false;
        },
        () => {
          this.error = this.translateService.t('Interpolation failed');
          this.working = false;
        }
      );
  }

  cropsat(): void {
    this.working = true;
    this.mapService
      .getCropSatImage(this.feature, this.currentField.name)
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        this.working = false;

        const fc = res.featureCollection;
        fc['legend'] = res;
        fc['legend'].isCropsat = true;

        this.interpolationChanged.emit({
          interpolation: fc,
          pointMode: this.pointMode,
        });
      });
  }

  private setYears(): void {
    if (this.currentGroup.name === LAYER_TYPE.VegIndex) {
      this.years = [];
      for (let y = new Date().getFullYear(); y > 2015; y--) {
        this.years.push(y);
      }
    } else {
      const filtered = this.data
        .filter((d) => d.fileType === this.currentGroup.name)
        .map((d) => d.year);
      this.years = filtered.filter((x, i, e) => e.indexOf(x) === i);
      this.years.sort((a, b) => {
        return b - a;
      });
    }
    if (this.years.findIndex((y) => y === this.clientYear) > -1) {
      this.currentYear = this.clientYear;
    } else {
      this.currentYear = this.years[0];
    }

    this.selectYear(this.currentYear);
  }

  private setGroups(): void {
    this.currentGroup = null;
    this.groups = [];
    const mappedGroups = this.data.map((d) => {
      const gr = new Group();
      gr.name = d.fileType;
      gr.type = d.fileType;

      return gr;
    });

    mappedGroups.forEach((g) => {
      if (
        this.groups.filter((g1) => g1.name === g.name && g1.type === g.type)
          .length === 0
      ) {
        if (g.name === FILE_TYPE.SoilSampling) {
          g.displayName = this.translateService.t(
            '_groupname_' + g.name,
            '_' + g.name
          );
          g.icon = 'assets/images/icon-Markkartering.svg';
        } else if (g.name === FILE_TYPE.Yield) {
          g.displayName = this.translateService.t(
            '_groupname_' + g.name,
            '_' + g.name
          );
          g.icon = 'assets/images/icon-Skörd.svg';
        } else if (g.name === FILE_TYPE.NSensor) {
          g.displayName = this.translateService.t(
            '_groupname_' + g.name,
            '_' + g.name
          );
          g.icon = 'assets/images/icon-NSensor.svg';
        } else if (g.name === FILE_TYPE.Prescription) {
          g.displayName = this.translateService.t(
            '_groupname_' + g.name,
            '_' + g.name
          );
          g.icon = 'assets/images/icon-Tilldelning.svg';
        } else if (g.name === FILE_TYPE.Veris) {
          g.displayName = this.translateService.t(
            '_groupname_' + g.name,
            '_' + g.name
          );
          g.icon = 'assets/images/icon-Veris.svg';
        }
        this.groups.push(g);
      }
    });
    if (this.feature.features.length === 1) {
      this.groups.push({
        displayName: 'CropSAT',
        icon: 'assets/images/icon-Biomassa.svg',
        name: LAYER_TYPE.VegIndex,
        type: LAYER_TYPE.VegIndex,
      });
    }
  }

  private setFields(): void {
    this.files = [];
    this.selectableFiles = [];
    this.currentFiles = [];
    this.fields = [];
    this.currentField = null;

    if (this.currentGroup.name === LAYER_TYPE.VegIndex) {
      this.working = true;
      this.mapService
        .getCropSatDates(this.feature, this.currentYear)
        .pipe(takeUntil(this._unsub$))
        .subscribe((res) => {
          this.working = false;
          this.fields = res.map((r) => {
            return {
              name: r.date,
              displayName: r.date,
            };
          });
        });
    } else if (this.currentGroup) {
      const filterByGroupAndYear = (d: FieldGridModelWithYear): boolean =>
        d.fileType === this.currentGroup.name && d.year === this.currentYear;

      this.data.filter(filterByGroupAndYear).forEach((d) => {
        if (this.fields.findIndex((f) => f.name === d.property) === -1) {
          this.fields.push({
            name: d.property,
            displayName: this.translateService.t(
              '_propertyname_' + d.property,
              '_' + d.property
            ),
          });
        }
      });

      this.sortFields();
    }

    this.fields = [...this.fields];
  }

  private sortFields(): void {
    if (!this.interpolationProperties) {
      return;
    }

    this.fields.sort((fieldA, fieldB) => {
      const displayOrderA = this.getFieldDisplayOrder(fieldA);
      const displayOrderB = this.getFieldDisplayOrder(fieldB);

      if (displayOrderA === 0) {
        return 1;
      } else if (displayOrderB === 0) {
        return -1;
      }
      return displayOrderA < displayOrderB ? -1 : 1;
    });
  }

  private getFieldDisplayOrder(field: Field): number {
    return (
      this.interpolationProperties.find(
        (property) =>
          property.field === field.name &&
          property.type === this.currentGroup.name
      )?.displayOrder ?? 0
    );
  }

  private setFiles(): void {
    this.files = [];
    this.currentFiles = [];
    this.selectableFiles = [];

    if (this.currentGroup.name === LAYER_TYPE.VegIndex) {
      return;
    }

    if (this.currentGroup && this.currentField) {
      this.data
        .filter(
          (d) =>
            d.fileType === this.currentGroup.name &&
            d.property === this.currentField.name
        )
        .forEach((f) => {
          this.files.push({
            fileName: f.name,
            geoDataId: f.geoDataId,
            checked: false,
            created: f.createdDate,
            year: f.year,
          });
        });

      this.files = this.files.sort(
        (a, b) => Date.parse(b.created) - Date.parse(a.created)
      );
    }
    this.files = [...this.files];
    this.selectableFiles = this.files;
  }
}
