<mat-list-item>
  <button mat-button (click)="toggleActive()">
    <img class="map-connect-image" src="assets/images/icon-connect.svg" />
    {{ 'Pick from blockmap' | dvt }}
  </button>
</mat-list-item>
<div class="dv-maps-tool" *ngIf="isActive">
  <div class="content">
    <p class="map-connect-paragraph" *ngIf="parts.length === 0">
      {{ 'Select parcel to connect to field' | dvt }}
    </p>

    <dv-map-field-part
      [skifteId]="skifteId"
      fxLayout="column"
      [parts]="parts"
      (save)="onSave($event)"
      (cancel)="toggleActive()"
    ></dv-map-field-part>
    <dv-map-block
      (onBlocksLoaded)="onBlocksLoaded($event)"
      autoload="true "
    ></dv-map-block>
  </div>
</div>
