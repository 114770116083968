<div mat-dialog-content>
    <div class="column">
        <div>
            <img style="width: 280px;" src="assets/images/connected-farm.png"/>
        </div>
        <div class="row">
            <h4>{{"Fil uppladdad!"|dvt}}</h4>
            <button mat-raised-button color="primary" (click)="openUrl()">{{"Show upload"|dvt}}</button>
        </div>
    </div>
</div>