<mat-progress-bar mode="indeterminate" *ngIf="loadingData"></mat-progress-bar>
<div *ngIf="groupsError" class="errmsg">
  <mat-icon color="warn">error</mat-icon>
  <span class="dv-text-color status-text"
    >{{
      '_err_interpolationgroups_failed'
        | dvt: '_Failed to fetch data groups for selected fields'
    }}
  </span>
</div>
<div class="groups" *ngIf="groups?.length > 0 || selectedFieldIds?.length > 0">
  <button
    mat-stroked-button
    *ngFor="let group of groups"
    (click)="setGroup(group)"
    [disabled]="loadingData"
  >
    {{ '_groupname_' + group | dvt: group }}
  </button>
  <button
    mat-stroked-button
    *ngIf="selectedFieldIds?.length === 1"
    (click)="interpolateCropSat()"
    [disabled]="loadingData"
  >
    CropSAT
  </button>
  <button
    mat-stroked-button
    *ngIf="sguIsAvailable"
    [disabled]="loadingData"
    (click)="interpolateFieldData()"
  >
    {{ 'Clay content' | dvt }}
  </button>
</div>
