import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { PartOption } from '../PartOption';
import { BrukningsenhetModel, SkifteDetielsModel } from 'app/models/api.models';
import { SiteService } from 'app/services/site.service';
import { DialogService } from 'app/components/dialog/dialog.service';
import { ClientService } from 'app/services/client.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-map-field-part',
  templateUrl: 'map-field-part.component.html',
  styleUrls: ['map-field-part.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapFieldPartComponent {
  private brukenheter: BrukningsenhetModel[];
  private skifte: SkifteDetielsModel = null;
  private _parts: PartOption[] = [];

  @Input()
  set skifteId(id: number) {
    this.clientService.getSkifte(id).subscribe((skifte) => {
      this.skifte = skifte;
      if (this.skifte) {
        this._parts.forEach((p) => (p.brukEnhId = this.skifte.brukEnhId));
      }
    });
  }

  @Input()
  set parts(p: PartOption[]) {
    this._parts = p;
    if (this.skifte) {
      this._parts.forEach((p) => (p.brukEnhId = this.skifte.brukEnhId));
    }
    this.cd.markForCheck();
  }
  get parts(): PartOption[] {
    return this._parts;
  }
  @Output()
  cancel = new EventEmitter<void>();

  @Output()
  save = new EventEmitter<PartOption[]>();

  constructor(
    public siteService: SiteService,
    private translateService: DvToolbarTranslateService,
    private cd: ChangeDetectorRef,
    private dialogService: DialogService,
    private clientService: ClientService
  ) {
    this.clientService.getBrukningsenheter().subscribe((brukenheter) => {
      this.brukenheter = brukenheter;
    });
  }

  changeBrukenh(part: PartOption): void {
    const maxNr = this.getMaxSkifteNrFromPartsInklBrukenh(part.brukEnhId);
    part.skifteId = maxNr + 1;
  }

  getName(): string {
    const value = 'name' + Math.ceil(Math.random() * 1000);
    return value;
  }

  /** Flips action on two this._parts */
  flipSkifte(): void {
    const copy0 = {
      action: this._parts[1].action,
      areal: this._parts[0].areal,
      color: this._parts[0].color,
      feature: this._parts[0].feature,
      featureText: this._parts[0].featureText,
    };

    const copy1 = {
      action: this._parts[0].action,
      areal: this._parts[1].areal,
      color: this._parts[1].color,
      feature: this._parts[1].feature,
      featureText: this._parts[1].featureText,
    };

    this._parts = [];
    this._parts.push(copy0);
    this.parts.push(copy1);
  }

  getMaxSkifteNrFromParts(): number {
    let maxSkifteNummer = 0;
    this._parts
      .filter((p) => p.action === 'new' || p.action === 'part')
      .forEach((p) => {
        if (p.nr > maxSkifteNummer) {
          maxSkifteNummer = p.nr;
        }
      });

    return maxSkifteNummer;
  }

  getMaxSkifteNrFromPartsInklBrukenh(brukenhId: number): number {
    let maxSkifteNummer = 0;
    this._parts
      .filter(
        (p) =>
          (p.action === 'new' || p.action === 'part') &&
          p.brukEnhId === brukenhId
      )
      .forEach((p) => {
        if (p.nr > maxSkifteNummer) {
          maxSkifteNummer = p.nr;
        }
      });

    return maxSkifteNummer;
  }

  onSaveClick(): void {
    if (this.skifte && this._parts.length < 1) {
      this.dialogService.message(
        this.translateService.t('Atleast one part must be created')
      );
    } else if (
      this.skifte &&
      this._parts.filter((p) => p.action === 'update').length !== 1
    ) {
      this.dialogService.message(
        this.translateService.t('Only one part can update the original field')
      );
    } else {
      this.save.next(this._parts);
    }
  }

  onCancelClick(): void {
    this.cancel.next();
  }
}
