import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { PRESCRIPTION_STEPS, StepState } from '../../prescription-wizard.types';
import { PrescriptionWizardService } from '../../prescription-wizard.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dv-stepper-header',
  templateUrl: 'stepper-header.component.html',
  styleUrls: ['stepper-header.component.scss'],
})
export class StepperHeaderComponent implements OnInit {
  @Input() stepState: StepState[];
  @Input() selectedIndex = 0;
  @Output() selectStep = new EventEmitter<number>();

  private unsub$ = new Subject<void>();

  constructor(private wizardService: PrescriptionWizardService) {}

  ngOnInit(): void {
    this.wizardService.restartWizard$
      .pipe(takeUntil(this.unsub$))
      .subscribe(() => this.onSelectStep(PRESCRIPTION_STEPS.FIELD));
  }

  onSelectStep(index: number): void {
    if (index === 0 || this.stepState[index - 1].completed) {
      this.selectStep.emit(index);
    }
  }
}
