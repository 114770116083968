import { Injectable } from '@angular/core';
import { InterpolationModel } from 'app/models/interpolation.model';
import { PrescriptionBasisModel } from 'app/models/prescriptiongrid.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root',
})
export class MapStateService {
  private selectedFeatures$ = new BehaviorSubject<GeoJSON.FeatureCollection>(
    null
  );
  private selectedMarkings$ = new BehaviorSubject<GeoJSON.FeatureCollection>(
    null
  );

  private interpolation$ = new BehaviorSubject<InterpolationModel>(null);
  private valuePoints$ = new BehaviorSubject<GeoJSON.FeatureCollection>(null);

  private prescription$ = new BehaviorSubject<GeoJSON.FeatureCollection>(null);
  private prescriptionBasis$ = new BehaviorSubject<PrescriptionBasisModel>(
    null
  );

  constructor(private clientSrv: ClientService) {
    this.setInterpolation(null);

    this.selectedFeatures$.subscribe((feat) => {
      this.setInterpolation(null);
    });

    this.fetchGroups();
  }

  /* FIELDS ====================================================== */
  /**
   *
   * @returns selected (field) features in map
   */
  getSelectedFeatures(): Observable<GeoJSON.FeatureCollection> {
    return this.selectedFeatures$.asObservable();
  }

  /**
   *
   * @returns the currently selected (field) feature in map
   */
  getCurrentSelectedFeatures(): GeoJSON.FeatureCollection {
    return this.selectedFeatures$.value;
  }

  /**
   * Selects a map (field) feature
   * @param feat feature to be selected in map
   */
  setSelectedFeature(feat: google.maps.Data.Feature): void {
    if (feat) {
      feat.toGeoJson((geo: GeoJSON.Feature) => {
        // clear selection if a new feature is selected after interpolation is done
        this._setFeature(geo);
      });
    } else {
      this.selectedFeatures$.next(null);
    }
  }

  /**
   *
   * @param fc features to be selected in map
   */
  setSelectedFeatures(fc: GeoJSON.FeatureCollection): void {
    this.selectedFeatures$.next(fc);
  }

  addSelectedFeatures(fc: GeoJSON.FeatureCollection): void {
    const selected = this.selectedFeatures$.value;
    if (selected?.features?.length) {
      if (fc?.features?.length) {
        fc.features.forEach((feature) => {
          if (
            !selected.features.find((f) => {
              return <number>f.id === <number>feature.id;
            })
          ) {
            selected.features.push(feature);
          }
        });
        this.selectedFeatures$.next(selected);
      }
    } else if (fc?.features?.length) {
      this.selectedFeatures$.next(fc);
    }
  }

  /**
   *
   * @param feat (field) features to be unselected in map
   * @returns
   */
  unsetSelectedFeature(feat: google.maps.Data.Feature): void {
    if (feat) {
      const id = feat.getId();
      const current = this.selectedFeatures$.value;
      if (!current) {
        return;
      }
      if (current.features.find((f) => f.id == id)) {
        current.features = current.features.filter((f) => f.id != id);
        if (current.features.length > 0) {
          this.selectedFeatures$.next(current);
        } else {
          this.selectedFeatures$.next(null);
        }
      }
    }
  }

  private _setFeature(geo: GeoJSON.Feature): void {
    let current = this.selectedFeatures$.value;

    if (current) {
      if (current.features.find((f) => f.id == geo.id)) {
        return;
      } //already selected, do nothing
      current.features.push(geo);
    } else {
      current = <GeoJSON.FeatureCollection>{
        features: [geo],
        type: 'FeatureCollection',
        id: 0,
      };
    }
    this.selectedFeatures$.next(current);
  }

  /* MARKINGS ====================================================== */
  /**
   *
   * @returns markings selected in map
   */
  getSelectedMarkings(): Observable<GeoJSON.FeatureCollection> {
    return this.selectedMarkings$.asObservable();
  }

  /**
   *
   * @param feat marking feature to be selected in map
   */
  setSelectedMarking(feat: google.maps.Data.Feature): void {
    if (feat) {
      feat.toGeoJson((geo: GeoJSON.Feature) => {
        this._setMarkingFeature(geo);
      });
    } else {
      this.selectedMarkings$.next(null);
    }
  }

  /**
   *
   * @param fc marking features to be selected in map
   */
  setSelectedMarkings(fc: GeoJSON.FeatureCollection): void {
    this.selectedMarkings$.next(fc);
  }

  /**
   *
   * @param feat marking features to be unselected in map
   * @returns
   */
  unsetSelectedMarking(feat: google.maps.Data.Feature): void {
    if (feat) {
      const id = feat.getId();
      const current = this.selectedMarkings$.value;
      if (!current) {
        return;
      }
      if (current.features.find((f) => f.id == id)) {
        current.features = current.features.filter((f) => f.id != id);
        if (current.features.length > 0) {
          this.selectedMarkings$.next(current);
        } else {
          this.selectedMarkings$.next(null);
        }
      }
    }
  }

  private _setMarkingFeature(geo: GeoJSON.Feature): void {
    let current = this.selectedMarkings$.value;
    if (current) {
      if (current.features.find((f) => f.id == geo.id)) {
        return;
      } //already selected, do nothing
      current.features.push(geo);
    } else {
      current = <GeoJSON.FeatureCollection>{
        features: [geo],
        type: 'FeatureCollection',
        id: 0,
      };
    }
    this.selectedMarkings$.next(current);
  }

  /* GEODATA ===================================================== */
  /**
   *
   * @param feat geodata feature to select in map
   */
  setSelectedGeoFeature(feat: GeoJSON.Feature): void {
    if (feat) {
      this._setFeature(feat);
    } else {
      this.selectedFeatures$.next(null);
    }
  }

  clearSelectedFeatures(): void {
    this.setSelectedFeature(null);
  }

  /* HELPER FUNCTIONS ============================================= */
  resetInterpolationFeatures(): void {
    this.setInterpolation(null);
    this.setValuePoints(null);
  }

  /* VALUE POINTS ================================================ */
  /**
   * Gets an observable of current value points
   * @returns current value points
   */
  getValuePoints(): Observable<GeoJSON.FeatureCollection> {
    return this.valuePoints$.asObservable();
  }

  setValuePoints(pts: GeoJSON.FeatureCollection): void {
    this.valuePoints$.next(pts);
  }

  clearValuePoints(): void {
    this.valuePoints$.next(null);
  }

  /* INTERPOLATION FEATURES ====================================== */
  setInterpolation(interpolation: InterpolationModel): void {
    this.interpolation$.next(interpolation);
  }

  getInterpolation(): Observable<InterpolationModel> {
    return this.interpolation$.asObservable();
  }

  clearInterpolation(): void {
    this.interpolation$.next(null);
  }

  /* PRESCRIPTION FEATURES ======================================= */
  setPrescription(prescription: GeoJSON.FeatureCollection): void {
    this.prescription$.next(prescription);
  }

  getPrescription(): Observable<GeoJSON.FeatureCollection> {
    return this.prescription$.asObservable();
  }

  clearPrescription(): void {
    this.prescription$.next(null);
  }

  setPrescriptionBasis(interpolation: PrescriptionBasisModel): void {
    this.prescriptionBasis$.next(interpolation);
  }

  getPrescriptionBasis(): Observable<PrescriptionBasisModel> {
    return this.prescriptionBasis$.asObservable();
  }

  clearPrescriptionBasis(): void {
    this.prescriptionBasis$.next(null);
  }

  /* GROUPS ====================================================== */
  fetchGroups(): void {
    this.clientSrv.loadMappingGroups();
  }
}
