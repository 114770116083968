import { Injectable, NgZone } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmComponent } from './confirm/confirm.component';
import { PromptComponent } from './prompt/prompt.component';
import { NoteComponent } from './note/note.component';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private zone: NgZone,
    private dialog: MatDialog,
    private translate: DvToolbarTranslateService,
    private snackBar: MatSnackBar
  ) {}

  message(text: string): void {
    this.snackBar.open(text, null, {
      duration: 5500,
    });
  }

  confirm(
    text: string,
    yesText?: string,
    cancelText?: string
  ): MatDialogRef<ConfirmComponent> {
    return this.dialog.open(ConfirmComponent, {
      data: {
        text: text,
        yesText: yesText || this.translate.t('Yes'),
        cancelText: cancelText || this.translate.t('Cancel'),
      },
      disableClose: true,
    });
  }

  prompt(text: string, placeholder: string): MatDialogRef<PromptComponent> {
    return this.dialog.open(PromptComponent, {
      data: {
        text: text,
        placeholder: placeholder,
      },
      disableClose: true,
    });
  }

  note(
    endpointCall: boolean,
    headerText: string,
    maintext: string,
    prompt?: string
  ): MatDialogRef<NoteComponent> {
    return this.dialog.open(NoteComponent, {
      data: {
        endpoint: endpointCall,
        header: headerText,
        main: maintext,
        prompt: prompt,
      },
    });
  }

  /**
   * Opens a dialog, if no config is suplayd a dialog with default settings is open, then use dv-dialog-content, to style the dialog
   * The ComponentType T must be registred as a entryComponents in a module
   * @param component
   * @param config
   */
  open<IBaseDialog>(
    component: ComponentType<IBaseDialog>,
    data?: any,
    config?: MatDialogConfig
  ): MatDialogRef<IBaseDialog, any> {
    let dialogRef: MatDialogRef<IBaseDialog>;

    if (config === undefined) {
      config = new MatDialogConfig();
      config.disableClose = true;
    }

    this.zone.run(() => {
      dialogRef = this.dialog.open(component, config);
    });

    if (data !== undefined) {
      (<any>dialogRef.componentInstance).data = data;
    }

    return dialogRef;
  }
}
