import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LayerDialogComponent } from 'app/components/layer-dialog/layer-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class FeatureDataService {
    public disabled: boolean = false;
    dialogRef: MatDialogRef<LayerDialogComponent> = null;

    constructor(private dialog: MatDialog) {}

    public show(features: google.maps.Data.Feature[]) {
        if (this.disabled) return;
        //dont show for skiften
        if (features.length == 0 || features[0].getProperty('skifteId')) return;

        if (this.dialogRef) {
            try {
                this.dialogRef.componentInstance.setData({ type: 'FeatureCollection', features });
            } catch (err) {
                console.log(err);
            }
        } else {
            const options = {
                width: '400px',
                height: 'auto',
                marginTop: 'auto',
                position: {
                    bottom: '25px',
                    right: '25px',
                },
                panelClass: 'dv-layer-detail-dialog',
                disableClose: true,
                hasBackdrop: false,
                data: { type: 'FeatureCollection', features },
            };

            this.dialogRef = this.dialog.open(LayerDialogComponent, options);
            this.dialogRef.afterClosed().subscribe((res) => {
                this.dialogRef = null;
            });
        }
    }
}
