import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  DvmInputModule,
  DvmRadioButtonGroupModule,
  DvmRadioButtonModule,
  DvmTextModule,
  DvmTooltipDirective,
} from '@dvm/components';
import {
  FunctionNumberInputDefinition,
  FunctionParameterDefinition,
  FunctionParameterWithType,
  PARAMETER_IDS,
} from '../../../../step-settings.types';
import { NgIf } from '@angular/common';
import { InputLimits, PhChangeEvent, PhParameterIds } from './ph-input.types';
import { DvToolbarTranslateService, DvTranslatePipe } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-ph-input',
  templateUrl: 'ph-input.component.html',
  styleUrls: ['ph-input.component.scss', '../../input-section.component.scss'],
  standalone: true,
  imports: [
    DvmInputModule,
    DvmRadioButtonGroupModule,
    DvmRadioButtonModule,
    DvmTextModule,
    DvmTooltipDirective,
    DvTranslatePipe,
    NgIf,
  ],
})
export class PhInputComponent implements OnChanges {
  @Input()
  adjustPhParameter: FunctionParameterWithType<FunctionNumberInputDefinition>;
  @Input()
  targetPhParameter: FunctionParameterWithType<FunctionParameterDefinition>;
  @Output() phChange = new EventEmitter<PhChangeEvent>();
  value: number;
  selectedParameterId?: PhParameterIds;
  PARAMETER_IDS = PARAMETER_IDS;
  errorMessage = '';
  private parameterLimits: Record<PhParameterIds, InputLimits>;

  get selectedParameterLimits(): InputLimits {
    return this.parameterLimits[this.selectedParameterId] ?? {};
  }

  constructor(private translate: DvToolbarTranslateService) {}

  ngOnChanges(): void {
    if (this.targetPhParameter && this.adjustPhParameter) {
      this.adjustPhParameter.value = 0;
      this.value = this.adjustPhParameter.value as number;
      this.selectedParameterId = PARAMETER_IDS.ADJUST_PH;

      this.parameterLimits = {
        [PARAMETER_IDS.ADJUST_PH]: {
          minLimit: this.adjustPhParameter.definition.minLimit,
          maxLimit: this.adjustPhParameter.definition.maxLimit,
        },
        [PARAMETER_IDS.TARGET_PH]: {
          minLimit: 0,
          maxLimit: 14,
        },
      };
    }
  }

  onInputSelection(id: PhParameterIds): void {
    this.selectedParameterId = id;
    this.value = 0;

    this.resetParameter(this.adjustPhParameter);
    this.resetParameter(this.targetPhParameter);
    this.validateNumericValue(this.selectedParameterLimits, false);

    this.phChange.emit({
      parameterId: this.selectedParameterId,
      isValid: this.isValid(),
    });
  }

  private resetParameter(
    parameter: FunctionParameterWithType<FunctionParameterDefinition>
  ): void {
    parameter.value = 0;
    parameter.errorMessage = '';
  }

  onValueChange(stringValue: string, badInput: boolean): void {
    const value = Number(stringValue);
    if (!badInput) {
      this.value = value;
    }
    this.validateNumericValue(this.selectedParameterLimits, badInput);

    this.targetPhParameter.touched = true;
    this.adjustPhParameter.touched = true;

    if (this.selectedParameterId === PARAMETER_IDS.ADJUST_PH) {
      this.adjustPhParameter.value = value;
      this.adjustPhParameter.errorMessage = this.errorMessage;
      this.resetParameter(this.targetPhParameter);
    } else {
      this.targetPhParameter.value = value;
      this.targetPhParameter.errorMessage = this.errorMessage;
      this.resetParameter(this.adjustPhParameter);
    }

    this.phChange.emit({
      parameterId: this.selectedParameterId,
      isValid: this.isValid(),
    });
  }

  private isValid(): boolean {
    return (
      !this.adjustPhParameter.errorMessage &&
      !this.targetPhParameter.errorMessage
    );
  }

  private validateNumericValue(limits: InputLimits, badInput: boolean): void {
    const { minLimit, maxLimit } = limits;

    if (badInput) {
      this.errorMessage = this.translate.t('Invalid format');
    } else if (this.value < minLimit) {
      this.errorMessage = `${this.translate.t(
        'Minimum allowed value'
      )}: ${minLimit}`;
    } else if (this.value > maxLimit) {
      this.errorMessage = `${this.translate.t(
        'Maximum allowed value'
      )}: ${maxLimit}`;
    } else {
      this.errorMessage = '';
    }
  }
}
