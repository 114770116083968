<div>
    <button style="margin:5px 0 2px 5px;; z-index:100" color="primary" mat-mini-fab (click)="toggleMeasure()">
        <mat-icon *ngIf="!measure">
            <img style="margin-bottom: 5px" src="/assets/images/icon-ruler.svg">
        </mat-icon>
        <mat-icon *ngIf="measure">close</mat-icon>
    </button>
    <mat-chip-listbox *ngIf="measure">
        <mat-chip-option class="submenu-over-z">{{'Mätverktyg aktiverat' | dvt}}
            <mat-icon (click)="resetMeasure()" class="hand" mdChipRemove>restore</mat-icon>
        </mat-chip-option>
    </mat-chip-listbox>
</div>