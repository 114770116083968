<mat-list-item>
  <button mat-button (click)="toggleActive()">
    <img class="draw-image" src="assets/images/icon-draw-field.svg" />
    {{ buttonText }}
  </button>
</mat-list-item>
<div class="info dv-maps-tool" *ngIf="isActive">
  <div class="content">
    <p class="dv-lcenter-text">
      {{ 'Change parcel' | dvt }}
    </p>
    <p>{{ 'Area' | dvt }} {{ currAreal | areaUnit: '1.0-2' }}</p>
    <mat-divider></mat-divider>
    <mat-card appearance="outlined" *ngIf="isDrawing" class="dv-background draw-card">
      <mat-card-header>
        <mat-card-title>{{ 'Manage parts' | dvt }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field>
          <mat-select
            [value]="selectedPart"
            (selectionChange)="polygonPartChanged($event.value)"
            placeholder="Delar"
          >
            <mat-option [value]="-1"> {{ 'Orginal area' | dvt }} </mat-option>
            <mat-option
              *ngFor="let del of polygonParts; let i = index"
              [value]="i"
            >
              {{ 'Part' | dvt }} {{ i + 1 }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <div fxLayout="row" fxLayoutAlign="space-around">
          <button mat-button (click)="addPolygon()" color="accent">
            {{ 'New part' | dvt }}
          </button>
          <button mat-button (click)="removePolygon()" color="warn">
            {{ 'Remove part' | dvt }}
          </button>
        </div>
      </mat-card-actions>
    </mat-card>

    <div class="draw-button-wrapper">
      <button *ngIf="isDrawing" mat-raised-button (click)="setHistory(-1)">
        <mat-icon>undo</mat-icon>
      </button>
      <button *ngIf="isDrawing" mat-raised-button (click)="setHistory(1)">
        <mat-icon>redo</mat-icon>
      </button>
    </div>
    <button
      *ngIf="isDrawing"
      mat-raised-button
      color="primary"
      (click)="doneDraw()"
    >
      {{ 'Done' | dvt }}
    </button>
    <button
      *ngIf="!isDrawing"
      mat-raised-button
      color="primary"
      (click)="startDraw()"
    >
      {{ 'New parcel' | dvt }}
    </button>

    <dv-map-field-part
      [skifteId]="skifteId"
      [parts]="parts"
      (save)="onSave($event)"
      (cancel)="toggleActive()"
    ></dv-map-field-part>
  </div>
</div>
