import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { HttpEventType } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';
import { NewSkifteEnkelModel } from 'app/models/api.models';
import { ImportModel, ImportTypes } from 'app/models/import.model';
import { GeoDataMetaData } from 'app/models/models';
import { ClientService } from 'app/services/client.service';
import { SiteService } from 'app/services/site.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dv-import-view',
  templateUrl: './import-view.component.html',
  styleUrls: ['./import-view.component.scss', 'import-buttons.scss'],
})
export class ImportViewComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: MatStepper;
  @Output() fileStatusUpdate = new EventEmitter<{ file: string }>();
  importModel = new ImportModel();
  fileUploadProgress = 0;
  fileUploadMode = 'buffer';
  uploadFileHelpText = '';
  fileImportStatus: string = null;
  fileImportColor = 'accent';
  fields: NewSkifteEnkelModel[];
  showVeris = false;
  fileStatus: string = null;
  error: string = null;
  stepperIndex = 0;
  private _unsub$ = new Subject<void>();
  showBetaFunction$ = this.siteService.showBetaFunction$;

  constructor(
    private clientService: ClientService,
    private dialogRef: MatDialogRef<ImportViewComponent>,
    private translateService: DvToolbarTranslateService,
    private siteService: SiteService
  ) {}

  ngOnInit(): void {
    this.clientService
      .isAgrometsius()
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        this.showVeris = res;
      });
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
    this.error = null;
  }

  stepTwoChanged(importModel: ImportModel): void {
    this.importModel = importModel;

    window.setTimeout(() => {
      this.stepper.next();
    }, 0);
  }

  setType(type: ImportTypes): void {
    this.importModel.type = type;

    //we have  a timing issue this will solve that
    window.setTimeout(() => {
      this.stepper.next();
    }, 0);
  }

  isStepTwoDone(): boolean {
    switch (this.importModel.type) {
      case 'yield':
        return this.importModel.manufacture !== 'unknown';
      case 'soilsampling':
        return (
          this.importModel.files !== null && this.importModel.files.length > 0
        );
      default:
        return false;
    }
  }

  fieldsDone(fields: NewSkifteEnkelModel[]): void {
    this.fields = fields;
    window.setTimeout(() => {
      this.stepper.next();
    }, 0);
  }

  stepperChanged(event: StepperSelectionEvent): void {
    this.error = null;
    this.stepperIndex = event.selectedIndex;

    if (event.selectedIndex === 0) {
      this.importModel = new ImportModel();
      this.fileUploadProgress = 0;
    } else if (
      event.selectedIndex === 2 &&
      this.importModel.type === 'fieldboundary'
    ) {
      if (this.importModel.files && this.importModel.files.length > 0) {
        this.uploadFiles();
      } else if (this.fields && this.fields.length > 0) {
        this.uploadFields();
      }
    } else if (event.selectedIndex === 3) {
      let uploadFileHelpTextKey = '';
      uploadFileHelpTextKey += '_' + this.importModel.type;

      if (this.importModel.manufacture !== 'unknown') {
        uploadFileHelpTextKey += '_' + this.importModel.manufacture;
      }

      const tagValue =
        this.importModel.metadata.find((data) => data.key === 'tag')?.value ??
        '';

      if (tagValue !== '') {
        uploadFileHelpTextKey += '_' + tagValue;
      }

      this.uploadFileHelpText = this.translateService.t(uploadFileHelpTextKey);
    }
  }

  onMetadataSave(metadata?: GeoDataMetaData): void {
    this.importModel.metadata = this.importModel.metadata.concat(
      metadata ?? []
    );
    if (this.importModel.files && this.importModel.files.length > 0) {
      this.uploadFiles();
    } else if (this.fields && this.fields.length > 0) {
      this.uploadFields();
    }
    this.stepper.next();
  }

  uploadFiles(): void {
    this.error = null;
    this.fileUploadMode = 'buffer';
    this.fileImportColor = 'accent';
    this.fileUploadProgress = 0;
    this.fileImportStatus = '';

    this.clientService
      .uploadFiles(this.importModel)
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        (event) => {
          this.fileUploadMode = 'determinate';
          this.fileStatusUpdate.emit({
            file: this.importModel.files[0].name,
          });
          if (event.type === HttpEventType.UploadProgress) {
            if (!this.fileStatus && event.loaded !== event.total) {
              if (this.importModel.files?.length > 0) {
                this.fileStatus = this.importModel.files[0].name;
              }
            } else if (this.fileStatus && event.loaded === event.total) {
              this.fileStatus = null;
            }

            this.fileUploadProgress = Math.round(
              (event.loaded / event.total) * 100
            );
          }
          if (event.type === HttpEventType.Sent) {
            this.fileImportStatus = this.translateService.t(
              'Your files are uploaded, we processing them now'
            );
          }
        },
        () => {
          this.error = this.translateService.t(
            'Something went wrong. Please try again'
          );
          this.importModel.files = null;
        }
      );
  }

  uploadFields(): void {
    this.fileUploadMode = 'buffer';
    this.fileImportColor = 'accent';
    this.fileUploadProgress = 0;
    this.fileImportStatus = '';
    this.clientService
      .addSkifte(this.fields)
      .pipe(takeUntil(this._unsub$))
      .subscribe(() => {
        this.fileUploadProgress = 100;
        this.fileImportStatus = this.translateService.t(
          'Your fields are created'
        );
        this.clientService.reloadYear();
      });
  }

  close(): void {
    this.dialogRef.close();
  }
}
