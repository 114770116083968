import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, BehaviorSubject, Observable, Subject } from 'rxjs';
import { AnalyzeFieldModel } from 'app/models/analyze-field.model';
import { Router } from '@angular/router';
import { ClientService } from './client.service';
import { PropertyInfoModel } from 'app/models/models';
import { first, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AnalyzeService implements OnDestroy {
  private showSplitMode$ = new BehaviorSubject<boolean>(false);
  private splitMode$ = new BehaviorSubject<SplitMode>(SplitMode.Two);
  private fieldToAnalyze$ = new ReplaySubject<AnalyzeFieldModel>(1);
  private interpolationProperties = new BehaviorSubject<PropertyInfoModel[]>(
    null
  );
  private unsub$ = new Subject<void>();
  interpolationProperties$ = this.interpolationProperties.asObservable();

  constructor(private router: Router, private clientService: ClientService) {
    this.fieldToAnalyze$.next(null);
    this.getInterpolationProperties();
  }

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  getShowSplitTool(): Observable<boolean> {
    return this.showSplitMode$.asObservable();
  }

  setShowSplitTool(value: boolean): void {
    this.showSplitMode$.next(value);
  }

  getSplitMode(): Observable<SplitMode> {
    return this.splitMode$.asObservable();
  }

  setSplitMode(value: SplitMode): void {
    this.splitMode$.next(value);
  }

  /**
   * Sets fieldanalyze model and navigates to map split view
   * @param model Field to analyze
   */
  analyzeField(model: AnalyzeFieldModel): void {
    this.fieldToAnalyze$.next(model);
    this.clientService.clientId().subscribe((clientId) => {
      this.router.navigateByUrl('client/' + clientId + '/analyze/split');
    });
  }

  getFieldToAnalyze(): Observable<AnalyzeFieldModel> {
    return this.fieldToAnalyze$.asObservable();
  }

  private getInterpolationProperties(): void {
    this.clientService
      .getInterpolationProperties()
      .pipe(takeUntil(this.unsub$), first())
      .subscribe((properties) => this.interpolationProperties.next(properties));
  }
}

export enum SplitMode {
  Two,
  Four,
}
