export enum PROPERTY_TYPE {
  Yield = 'Yield',
  Protein = 'Protein',
  Moisture = 'Moisture',
  Speed = 'Speed',
  Witdth = 'Width',
}
export interface CalibratingYieldModel {
  calibratedYield: number;
  actualYield: number;
}
