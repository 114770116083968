import { Component, ContentChild, ElementRef, HostListener, Input, NgZone, OnInit, ViewChild } from '@angular/core';

const enum Status {
    OFF = 0,
    RESIZE = 1,
}

@Component({
    selector: 'dv-drag-container',
    templateUrl: './drag-container.component.html',
    styleUrls: ['./drag-container.component.scss'],
})
export class DragContainerComponent implements OnInit {
    @ViewChild('dragContainer') public dragContainer: ElementRef;
    @ContentChild('content') content: ElementRef;
    @Input() active: boolean = false;
    maxWidth: number = 450;
    constructor() {}

    ngOnInit(): void {
    }

    toggleSize() {
        if (this.maxWidth === 450) {
            this.maxWidth = 1000;
            setTimeout(() => {
                this.maxWidth = this.dragContainer.nativeElement.getBoundingClientRect().width;
            }, 1000);
        } else {
            this.maxWidth = 450;
        }
    }


}
