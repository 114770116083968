<div class="card" [ngClass]="{ 'card-selected': selected }">
  <div class="card-content">
    <div class="card-text">
      <dvm-text h4 class="card-title">{{ title }}</dvm-text>
      <div class="card-description">
        {{ description }}
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
