import { Component, OnInit } from '@angular/core';
import { ClientService } from 'app/services/client.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'dv-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  version = window['cropMap'].version;
  public supportUri : string;

  constructor(private clientService : ClientService) { }

  ngOnInit() {
    this.clientService.getLandCode().pipe(first()).subscribe(countryCode => {
      switch (countryCode) {
        case 'nl':
        case 'de':
        case 'be':
          this.supportUri = 'https://www.vantage-agrometius.nl/support/';
          break;
        default:
          this.supportUri = 'http://support.datavaxt.se/';
      }
    })
  }

}
