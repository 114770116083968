<div class="head">
  <button mat-button class="back-button" (click)="back()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <dvm-text h3>{{ 'Clay content' | dvt }}</dvm-text>
</div>
<div class="container">
  <div class="main-interpolation">
    <mat-card appearance="outlined" *ngIf="legend || interpolationLoading">
      <dv-legend
        [legend]="legend"
        [loading]="interpolationLoading && !error"
        [pointMode]="pointMode"
      ></dv-legend>
    </mat-card>
  </div>
</div>
