import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import {
  GeoDataKeyItem,
  GetGeoDataKeysPayload,
  ShareService,
} from 'app/services/share.service';
import {
  GeodataListShareDialogData,
  LinkItem,
  ShareData,
} from './geodata-list-share-dialog.types';
import { AlertType, DvmDialogService } from '@dvm/components';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-geodata-list-share-dialog',
  templateUrl: 'geodata-list-share-dialog.component.html',
  styleUrls: ['geodata-list-share-dialog.component.scss'],
})
export class GeodataListShareDialogComponent implements OnInit {
  @ViewChild('stepper') private stepper: MatStepper;
  fetching = false;
  hasFetchError = false;
  emails: UntypedFormControl[] = [
    new UntypedFormControl('', [Validators.required, Validators.email]),
  ];
  shareData: ShareData[] = [];
  links: LinkItem[] = [];
  linkExpand = false;
  message: string;
  private preview: GeoJSON.FeatureCollection = null;

  get selectedIndex(): number {
    return this.stepper?.selectedIndex ?? 0;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: GeodataListShareDialogData,
    private dialogRef: MatDialogRef<GeodataListShareDialogComponent>,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private translate: DvToolbarTranslateService,
    private shareService: ShareService,
    private dialogService: DvmDialogService
  ) {}

  ngOnInit(): void {
    this.shareData = this.data.selectedFiles.map((file) => ({
      file,
      shareRawData: true,
    }));
    if (this.data.preview && this.data.preview.features?.length > 0) {
      this.preview = this.data.preview;
    }
  }

  removeFile(fileId: number): void {
    this.shareData = this.shareData.filter(
      (data) => data.file.fileId !== fileId
    );
  }

  next(stepper: MatStepper): void {
    stepper.next();
    if (!this.links.length) {
      this.fetchLinks();
    }
  }

  private fetchLinks(): void {
    this.fetching = true;
    const data: GetGeoDataKeysPayload[] = this.shareData.map((data) => ({
      geoDataId: data.file.fileId,
      includeData: data.shareRawData,
      preview: this.preview,
    }));

    this.shareService.getGeoDataKeys(data).subscribe(
      (keyItems) => {
        this.fetching = false;
        this.hasFetchError = false;
        this.constructLinks(keyItems);
      },
      () => {
        this.fetching = false;
        this.hasFetchError = true;
        this.dialogService.alert(
          AlertType.warning,
          this.translate.t('Something went wrong'),
          this.translate.tl(
            '_Unexpected_error_file_share',
            '_An unexpected error has occurred and it was not possible to provide links for the files you have chosen to share'
          )
        );
        this.dialogRef.close();
      }
    );
  }

  private constructLinks(keyItems: GeoDataKeyItem[]): void {
    keyItems.forEach((item) =>
      this.links.push({
        fileName: item.name,
        link: `${window.location.protocol}//${window.location.host}/share/${item.key}`,
      })
    );
  }

  complete(): void {
    this.dialogRef.close({
      data: {
        links: this.links,
        emails: this.emails.map((email) => email.value),
        message: this.message,
      },
    });
  }

  addEmail(): void {
    this.emails.push(
      new UntypedFormControl('', [Validators.required, Validators.email])
    );
  }

  removeEmail(index: number): void {
    this.emails.splice(index, 1);
  }

  copyLink(link: string): void {
    this.clipboard.copy(link);

    this.snackBar.open(this.translate.t('Copied to clipboard'), null, {
      duration: 3000,
    });
  }

  hasErrors(): boolean {
    return this.selectedIndex === 0
      ? false
      : this.emails.filter((email, index) => {
          if (
            this.emails.length > 1 &&
            index === this.emails.length - 1 &&
            email.value === ''
          ) {
            return false;
          } else if (email.hasError('email')) {
            return true;
          } else if (email.hasError('required')) {
            return true;
          }
        }).length > 0;
  }
}
