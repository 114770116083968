import { Component, EventEmitter, Input, Output, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, filter, switchMap, first } from 'rxjs/operators';
import { Extent, MapService } from '../../map.service';
import { MapComponent } from '../../map.component';
import { ClientService } from 'app/services/client.service';
@Component({
    selector: 'dv-map-block',
    templateUrl: 'map-block.component.html',
    styleUrls: ['map-block.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapBlockComponent implements OnDestroy {

    private _boundsChangedListener: any = null;
    private _autoLoad = true;
    private extent$ = new Subject<Extent>();

    @Output() onBlocksLoaded = new EventEmitter<boolean>();
    @Output() onSelectedId = new EventEmitter<number>();
    @Input() set autoload(value: boolean) {
        this._autoLoad = value;
        this.handelAutoLoad();
    }
    get autoload() { return this._autoLoad; }

    @Input() useNorway : boolean = false;

    loading: boolean;
    needZoom: boolean;

    constructor(private clientService : ClientService,  private map: MapComponent, private mapService: MapService, private cd: ChangeDetectorRef, private zone: NgZone) {
        this.map.mapLoaded.pipe(first()).subscribe(() => {
            this.mapInit();
        });
    }

    mapInit() {


        this.extent$.pipe(
            debounceTime(750),
            filter(() => {
                this.needZoom = this.map.map.getZoom() < 14;
                this.cd.markForCheck();
                return !this.needZoom;
            }),
            switchMap(extent => {
                this.loading = true;
                this.cd.markForCheck();
                return this.clientService.getBlocks(extent);
            })
        ).subscribe((blocks : any) => {
            this.loading = false;
            this.cd.markForCheck();
            this.map.addGeoJson(blocks, 'block');
            this.onBlocksLoaded.emit(true);
        });


        this.handelAutoLoad();
        this.showBlock();
    }

    ngOnDestroy() {
        this.map.removeLayer('block');
        this._boundsChangedListener.remove();
        this.extent$.unsubscribe();
    }

    showBlock() {
        this.extent$.next(new Extent(this.map.map.getBounds()));
    }

    private handelAutoLoad() {
        if (!this.map.map)
            return;

        if (this._autoLoad) {
            this._boundsChangedListener = this.map.map.addListener('bounds_changed', () => {
                this.zone.run(() => {
                    this.extent$.next(new Extent(this.map.map.getBounds()));
                });
            });
            this.map.map.setCenter(this.map.map.getCenter());
        }
        else {
            this._boundsChangedListener.remove();
        }
    }
}  