import { Component } from '@angular/core';
import { PrescriptionWizardService } from 'app/views/prescription-wizard-page/prescription-wizard.service';

@Component({
  selector: 'dv-prescription-saved-notice',
  templateUrl: 'prescription-saved-notice.component.html',
  styleUrls: ['prescription-saved-notice.component.scss'],
})
export class PrescriptionSavedNoticeComponent {
  constructor(private wizardService: PrescriptionWizardService) {}

  restartWizard(): void {
    this.wizardService.publishRestartWizard();
  }
}
