import {
  Component,
  EventEmitter,
  Input,
  Output,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'dv-selectable-card',
  templateUrl: 'selectable-card.component.html',
  styleUrls: ['selectable-card.component.scss'],
})
export class SelectableCardComponent {
  @Input() selected: boolean;
  @Input() title: string;
  @Input() description: string;
  @Output() cardSelect = new EventEmitter<void>();

  @HostListener('click') onClick(): void {
    this.cardSelect.emit();
  }
}
