<mat-expansion-panel class="expansion settings">
  <mat-expansion-panel-header class="expansion">
    <mat-icon>settings</mat-icon>
    <p class="title">{{ 'Settings' | dvt }}</p>
    <span class="count"> ({{ getNumActiveLayers() }}) </span>
  </mat-expansion-panel-header>
  <div class="options">
    <dv-yield-disclaimer
      *ngIf="selectedType === 'Skordedata'"
      class="options-disclaimer"
    ></dv-yield-disclaimer>
    <mat-form-field
      class="year-select-form"
      *ngIf="selectionLimit == -1 && geodataYears?.length > 1"
      appearance="outline"
    >
      <mat-select
        [(ngModel)]="selectedLayerYear"
        (selectionChange)="selectGeodataByYear($event)"
        [placeholder]="'Select all geodata from year' | dvt"
      >
        <mat-option *ngFor="let year of geodataYears" [value]="year">
          {{ year }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="files">
      <mat-checkbox
        *ngFor="let file of geodata"
        [(ngModel)]="file.selected"
        [disabled]="disabled && file.selected"
        (change)="toggleSelectedGeodata(file.geoDataId)"
      >
        <span class="subtitle" [matTooltip]="file.fileName | trimSystemfolder">
          {{ (file.createdDate | date: 'short') + ' - ' }}{{ file.name }}
        </span>
      </mat-checkbox>
    </div>
    <div
      class="data-point-slides"
      *ngIf="selectedType !== FILE_TYPE.Prescription"
    >
      <mat-slide-toggle
        [(ngModel)]="pointMode"
        [disabled]="!pointModeAvailable"
        (change)="toggleInterpolationPoints($event.checked)"
        [matTooltipDisabled]="pointModeAvailable || loading"
        matTooltip="{{
          '_interpolationpoints_not_available'
            | dvt: '_Interpolationpoints are not available for multiple fields'
        }}"
        >{{ 'Show interpolationpoints' | dvt }}
      </mat-slide-toggle>
      <mat-slide-toggle
        [(ngModel)]="labelMode"
        [disabled]="!pointLabelAvailable"
        (change)="togglePointLabels($event.checked)"
        [matTooltipDisabled]="pointLabelAvailable || loading"
        matTooltip="{{
          '_datapoints_not_available' | dvt: '_Datapoints are not available'
        }}"
        >{{ 'Show labels' | dvt }}
      </mat-slide-toggle>
    </div>
    <div *ngIf="!pointMode" class="opacity">
      <div>{{ '_layer_opacity' | dvt }}</div>
      <mat-slider
        class="opacity-slider"
        [thumbLabel]="true"
        min="0"
        max="1"
        step="0.1"
        color="primary"
       #ngSlider><input matSliderThumb [value]="mapService.sliderOpacity" (change)="setOpacity({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}.value)" #ngSliderThumb="matSliderThumb" /></mat-slider>
    </div>
  </div>
</mat-expansion-panel>
