import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MarkkarteringSkifteModel } from 'app/models/api.models';
import { DialogService } from 'app/components/dialog/dialog.service';
import { MapComponent } from 'app/components/map/map.component';
import { LAYER_NAME_MARKERING } from 'app/components/map/map.service';
import { PoiService } from 'app/components/poi/poi.service';
import { MarkeringModel } from 'app/models/models';
import { ClientService } from 'app/services/client.service';
import { MapStateService } from 'app/services/map-state.service';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { MappingWizardService } from '../../../mapping-wizard.service';
import { MarkingDetailViewComponent } from '../../marking-detail-view/marking-detail-view.component';
import { compare } from '../table-utils';
import { POI_STATES } from 'app/components/poi/poi.types';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-table-markings',
  templateUrl: './table-markings.component.html',
  styleUrls: ['../table-styles.scss', './table-markings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableMarkingsComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @Input() markings: MarkkarteringSkifteModel[] = [];
  @Input() filters;
  @Input() map: MapComponent;
  @Input() search = '';
  @Output() markingAdded = new EventEmitter();
  @Output() markingDeleted = new EventEmitter();

  private layers: GeoJSON.FeatureCollection = null;

  loading = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selection = new SelectionModel<any>(true, []);
  expandedRow = -1;
  markingYears: number[];
  selectedMarkingYear: number;
  randomString = '!"#¤%&/()=?^*_:;>';
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChildren('tableRow', { read: ViewContainerRef }) rowContainers;
  displayedColumnsMarkings: string[] = [
    'visibility',
    'category',
    'created',
    'note',
  ];
  private _unsub$: Subject<void> = new Subject<void>();
  activeFeatures = [];
  activeLayers = [];
  private activeFeatureIds: number[] = [];
  viewInited = false;

  matDataMarkings = new MatTableDataSource();
  mapUpdated = false;
  selectedMarkings: GeoJSON.FeatureCollection;
  showLayerDetails = true;
  private newPois: number[] = [];
  private deletedPois: number[] = [];
  private activeMapFeatureIds: string[] = [];

  constructor(
    public wizSrv: MappingWizardService,
    private resolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private poiSrv: PoiService,
    private clientSrv: ClientService,
    private mapStateService: MapStateService,
    private dialogService: DialogService,
    private translateService: DvToolbarTranslateService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInited = true;
      this.changeDetectorRef.detectChanges();
    }, 500);
  }

  ngOnInit(): void {
    this.clientSrv
      .getPoiLayer()
      .pipe(takeUntil(this._unsub$))
      .subscribe((layers) => {
        this.layers = layers;
      });

    this.matDataMarkings.filterPredicate = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: any,
      filter: string
    ): boolean => {
      const search =
        this.randomString === filter ? this.search.toLowerCase() : filter;

      if (
        this.filters.markTypes.length > 0 &&
        !(data.category && this.filters.markTypes.includes(data.category))
      ) {
        return false;
      }

      if (this.filters.utford.length > 0) {
        if (!data.editable) {
          return false;
        }

        if (!this.filters.utford.includes(data.utford ? 0 : 1)) {
          return false;
        }
      }

      return search.length === 0
        ? true
        : (data['namn'] && data['namn'].toLowerCase().indexOf(search) !== -1) ||
            (data['name'] &&
              data['name'].toLowerCase().indexOf(search) !== -1) ||
            (data['category'] &&
              data['category'].toLowerCase().indexOf(search) !== -1);
    };
    this.matDataMarkings.data = this.markings;
    this.wizSrv
      .getMarkingsYear()
      .pipe(takeUntil(this._unsub$))
      .subscribe(() => {
        try {
          this.selection.clear();
          this.map.removeLayer(LAYER_NAME_MARKERING);
          this.activeFeatures = [];
          this.activeLayers = [];
        } catch (err) {
          return;
        }
      });
    this.wizSrv
      .getTableMarkingsState()
      .pipe(first())
      .subscribe((state) => {
        if (state) {
          if (state.activeLayers) {
            this.activeLayers = state.activeLayers;
          }
          if (state.activeFeatureIds) {
            this.activeFeatureIds = state.activeFeatureIds;
            this.updateMapLayers();
          }
          if (state.newPois) {
            this.newPois = state.newPois;
          }
          if (state.deletedPois) {
            this.deletedPois = state.deletedPois;
          }
        }
      });
    this.mapStateService
      .getSelectedMarkings()
      .pipe(takeUntil(this._unsub$))
      .subscribe((selected) => {
        if (selected && selected.features.length > 0) {
          this.wizSrv.showLayerDetailView(selected);
        } else {
          this.wizSrv.closeLayerDetailView();
        }
      });
  }

  ngOnDestroy(): void {
    this.wizSrv.closeLayerDetailView();
    this._unsub$.next();
    this._unsub$.complete();
    this.map.removeLayer(LAYER_NAME_MARKERING);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.markings) {
      this.loading = false;
      setTimeout(() => {
        this.matDataMarkings.data = changes.markings.currentValue;
        if (this.activeFeatureIds) {
          this.activeFeatureIds.forEach((id) => {
            if (this.matDataMarkings.data.find((d) => d['id'] === id)) {
              this.selection.select(
                this.matDataMarkings.data.find((d) => d['id'] === id)
              );
            }
          });
        }
        if (this.newPois.length > 0) {
          this.activeLayers = this.activeLayers.filter(
            (l) => !this.newPois.includes(l.properties.id)
          );
          this.newPois.forEach((poi) => {
            const p = this.markings.find((data) => data['id'] === poi);

            if (p) {
              this.toggleVisibility(p, null, true);
              this.newPois.splice(this.newPois.indexOf(poi), 1);
            }
          });
          this.updateMarkingsState();
        }
        if (this.deletedPois.length > 0) {
          this.deletedPois.forEach((poi) => {
            if (this.activeFeatureIds.includes(poi)) {
              this.activeFeatureIds.splice(
                this.activeFeatureIds.indexOf(poi),
                1
              );
              const removeLayers = this.activeFeatures.filter(
                (f) => f.getProperty('id') === poi
              );
              this.activeFeatures = this.activeFeatures.filter(
                (f) => f.getProperty('id') !== poi
              );
              if (removeLayers.length > 0) {
                removeLayers.forEach((removeLayer) => {
                  this.map.map.data.remove(removeLayer);
                });
              }
              this.deletedPois.splice(this.deletedPois.indexOf(poi), 1);
            }
          });
          this.updateMarkingsState();
        }
      });
      this.changeDetectorRef.detectChanges();
    }
    if (changes.filters) {
      this.filters = changes.filters.currentValue;
      this.matDataMarkings.filter = this.randomString;
    }
    if (changes.search) {
      this.search = changes.search.currentValue;
      this.matDataMarkings.filter = this.randomString;
    }
    if (changes.layers) {
      this.layers = changes.layers.currentValue;
    }
    if (!this.matDataMarkings.sort && this.sort) {
      this.matDataMarkings.sort = this.sort;
      this.sort.sortChange.subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  updateMapLayers(): void {
    if (
      this.activeFeatureIds &&
      this.activeFeatureIds.length > 0 &&
      this.activeLayers &&
      this.activeLayers.length > 0 &&
      !this.mapUpdated
    ) {
      this.mapUpdated = true;
      try {
        this.activeFeatures = this.map.addGeoJson(
          {
            type: 'FeatureCollection',
            features: this.activeLayers.filter((af) =>
              this.activeFeatureIds.includes(af.properties.id)
            ),
          },
          LAYER_NAME_MARKERING
        );

        this.map.fitFeature(this.activeFeatures);
      } catch (err) {
        console.log('err', err);
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  sortMarkData(sort: Sort): number {
    this.clearSelected();
    this.expandedRow = -1;
    const data = this.matDataMarkings.data;
    if (!sort.active || sort.direction === '') {
      this.matDataMarkings.data = this.markings;
      return;
    }

    this.matDataMarkings.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a['name'], b['name'], isAsc);
        case 'category':
          return compare(a['category'], b['category'], isAsc);
        case 'created':
          return compare(a['created'], b['created'], isAsc);
        default:
          return 0;
      }
    });
  }

  toggleVisibility(row, $event = null, show = false): void {
    if ($event) {
      $event.stopPropagation();
    }

    const id = row.id ? row.id : row.layerId;

    if (this.selection.isSelected(row) && !show) {
      this.activeFeatureIds.splice(this.activeFeatureIds.indexOf(id), 1);
      const removeLayers = this.activeFeatures.filter(
        (f) => f.getProperty('id') === id
      );
      this.activeFeatures = this.activeFeatures.filter(
        (f) => f.getProperty('id') !== id
      );
      if (removeLayers.length > 0) {
        removeLayers.forEach((removeLayer) => {
          this.map.map.data.remove(removeLayer);
        });
      }
      //For markings with alot of points...
      setTimeout(() => {
        this.selection.deselect(row);
      });
    } else {
      if (!this.selection.isSelected(row)) {
        this.selection.select(row);
        this.activeFeatureIds.push(id);
      }
      const lc = [...this.layers.features, ...this.activeLayers];
      const layers = lc.filter((l) => l.properties.id === row.id);
      if (layers && layers.length > 0) {
        this.activeLayers.push(...layers);
        this.activeFeatures.push(
          ...this.map.addGeoJson(
            { type: 'FeatureCollection', features: layers },
            LAYER_NAME_MARKERING
          )
        );
      } else {
        this.getAndShowLayer(row.id);
      }
      if (this.activeFeatures && this.activeFeatures.length > 0) {
        try {
          this.map.fitFeature(this.activeFeatures);
        } catch (err) {
          return;
        }
      }
    }
    setTimeout(() => {
      this.updateMarkingsState();
      this.changeDetectorRef.detectChanges();
    });
  }

  getAndShowLayer(layerId: number): void {
    this.clientSrv
      .getMarking(layerId)
      .pipe(first())
      .subscribe((res) => {
        const fc = res;

        fc.features.forEach((f) => {
          f.properties.id = layerId;
          if (f.id === 0) {
            f.id = this.getUniqueId();
          }
        });
        this.activeFeatureIds.push(layerId);
        this.activeLayers.push(...fc.features);
        this.activeFeatures.push(
          ...this.map.addGeoJson(fc, LAYER_NAME_MARKERING)
        );
        this.map.fitFeature(this.activeFeatures);
        this.updateMarkingsState();
      });
  }

  updateMarkingsState(): void {
    this.wizSrv.setTableMarkingsState({
      activeFeatureIds: this.activeFeatureIds,
      activeLayers: this.activeLayers,
      newPois: this.newPois,
      deletedPois: null,
    });
  }

  clearSelected(): void {
    if (this.expandedRow !== -1) {
      const identifier = 'mark-row';
      this.rowContainers
        .toArray()
        .find(
          (el) =>
            el.element.nativeElement.id === identifier + '-' + this.expandedRow
        )
        .clear();
    }
  }

  openDetailView(data: MarkeringModel, index: number, type: string): void {
    this.clearSelected();

    if (this.expandedRow === index) {
      this.expandedRow = -1;
    } else {
      const container = this.rowContainers
        .toArray()
        .find((el) => el.element.nativeElement.id === 'mark-row-' + index);
      const factory = this.resolver.resolveComponentFactory(
        MarkingDetailViewComponent
      );
      const inlineComponent = container.createComponent(factory);

      if (type === 'file') {
        inlineComponent.instance.file = data;
      } else {
        inlineComponent.instance.marking = data;
      }

      inlineComponent.instance.onEdit
        .pipe(takeUntil(this._unsub$))
        .subscribe(() => {
          const feat = this.layers.features.find(
            (l) => l.properties.id === data['id']
          );
          if (feat) {
            const feats = this.map.addGeoJson(
              { type: 'FeatureCollection', features: [feat] },
              LAYER_NAME_MARKERING
            );
            this.map.fitFeature(feats);
            this.poiSrv.setPoiFeature(feats[0]);
            this.poiSrv.setState(POI_STATES.EDIT_SINGLE);
            this.wizSrv.setShowPoi(true);
          }
        });

      inlineComponent.instance.onDelete
        .pipe(takeUntil(this._unsub$))
        .subscribe(() => {
          this.dialogService
            .confirm(
              this.translateService.t(
                'Are you sure you want to remove this marking?'
              )
            )
            .afterClosed()
            .subscribe((res) => {
              if (res) {
                if (data.editable) {
                  this.deletePoi(data);
                } else {
                  this.deleteMarking(data);
                }
              }
            });
        });

      inlineComponent.instance.onClose
        .pipe(takeUntil(this._unsub$))
        .subscribe(() => {
          this.clearSelected();
          this.expandedRow = -1;
        });

      this.expandedRow = index;
    }
  }

  deleteMarking(data: MarkeringModel): void {
    this.loading = true;
    this.clientSrv
      .deleteLayer(data.id)
      .pipe(first())
      .subscribe(() => {
        this.loading = false;
        this.removeCachedMarking(data);
        this.markingDeleted.emit(true);
        this.clearSelected();
        this.expandedRow = -1;
      });
  }

  deletePoi(data: MarkeringModel): void {
    this.loading = true;
    this.clientSrv
      .removePoi(data.id)
      .pipe(first())
      .subscribe(() => {
        this.loading = false;
        this.removeCachedMarking(data);
        this.markingDeleted.emit(true);
        this.clearSelected();
        this.expandedRow = -1;
      });
  }

  removeCachedMarking(data: MarkeringModel): void {
    const index = this.activeFeatureIds.indexOf(data.id);
    if (index > 0) {
      this.activeFeatureIds.splice(index, 1);
    }

    const removeLayers = this.activeFeatures.filter(
      (f) => f.getProperty('id') === data.id
    );
    this.activeFeatures = this.activeFeatures.filter(
      (f) => f.getProperty('id') !== data.id
    );
    if (removeLayers.length > 0) {
      removeLayers.forEach((removeLayer) => {
        this.map.map.data.remove(removeLayer);
      });
    }
  }

  addMarking(): void {
    this.markingAdded.emit();
  }

  getUniqueId(): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 7; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    if (this.activeMapFeatureIds.includes(result)) {
      return this.getUniqueId();
    } else {
      this.activeMapFeatureIds.push(result);
      return result;
    }
  }
}
