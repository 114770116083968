<div
  class="bar-item"
  *ngFor="let item of items; let i = index"
  (click)="selectItem(i)"
  [ngClass]="{
    'bar-item-selected': selectedIndex === i && !disabled,
    'bar-item-disabled': disabled
  }"
>
  {{ item.name }}
</div>
