<div>
  <h1>
    {{ poi?.kategori }}
  </h1>

  <div *ngIf="poi !== null">
    <div class="form">
      <label>{{ 'Category' | dvt }}</label>
      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="poi.kategoriId" appearance="outline">
          <mat-option class="form-option" (click)="editCateogrys()">
            {{ 'Add category' | dvt }}
          </mat-option>
          <mat-option *ngFor="let cat of categorys" [value]="cat.id">
            {{ cat.namn }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <label>{{ 'Anteckning' | dvt }}</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          placeholder="{{ 'Anteckning' | dvt }}"
          [(ngModel)]="poi.anteckning"
        />
      </mat-form-field>
      <mat-slide-toggle
        class="toggle"
        [checked]="poi.utford"
        (change)="toggleDone($event)"
      >
        {{ utfordText }}
      </mat-slide-toggle>
      <div class="img" *ngIf="currentImage || poi.image">
        <img [src]="poi.image ? poi.image.src : currentImage" class="image" />
        <button class="remove-img" (click)="deleteImg()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button
      *ngIf="poi && poi.id > 0"
      mat-icon-button
      color="warn"
      class="delete"
      [disabled]="loading"
      (click)="delete()"
    >
      <mat-icon>delete</mat-icon>
    </button>
    <input
      class="buttons-file"
      type="file"
      #img
      [disabled]="loading"
      (change)="saveImg($event)"
    />
    <button mat-stroked-button [disabled]="loading" (click)="img.click()">
      <mat-icon>image</mat-icon><span>{{ 'Add' | dvt }}</span>
    </button>
    <button mat-stroked-button [disabled]="loading" (click)="cancelAdd()">
      {{ 'Cancel' | dvt }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="poi?.kategoriId === -1 || loading"
      (click)="savePoi()"
    >
      {{ 'Save' | dvt }}
    </button>
  </div>
</div>
