export enum COLUMN_KEYS {
    AREA = 'area',
    CHECKBOX = 'checkbox',
    CLIENT = 'client',
    CREATED = 'created',
    CUSTOMER = 'customer',
    DATE = 'date',
    FARM = 'farm',
    FIELD = 'field',
    MANUFACTOR = 'manufactor',
    MORE = 'more',
    NAME = 'name',
    TYPE = 'type',
}
