<div id="wrapper">
    <div class="child">
        <div id="title">
            {{ 'Shared map' | dvt }}
        </div>

        <img
            style="width: 100px; display: flex; margin: auto"
            src="assets/images/icon-map-poi.svg"
            alt="icon of a map"
        />

        <div style="color: #5f7694">
            {{ '_map-shared-with-you' | dvt }}
        </div>

        <button mat-raised-button style="max-width: 110px" (click)="login()" color="primary">
            {{ 'Login' | dvt | uppercase }}
        </button>
    </div>
    <mat-divider style="color: #1f2d3d; opacity: 0.3; margin: inherit"></mat-divider>
    <div class="child">  
        <div style="font-size: 1.3em; color: #495e7b">
            {{ '_no-acoount-title' | dvt }}
        </div>

        <div style="color: #5f7694">
            {{ '_create-account-description' | dvt }}
        </div>

        <button mat-raised-button (click)="signUp()" color="accent">
            {{ 'Signup' | dvt | uppercase }}
        </button>
    </div>
</div>
