import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { take } from 'rxjs/operators';
import { ActiveFilters, AvailableFilterValues } from './filter-section.types';
import { DvToolbarTranslateService, DvUserService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-filter-section',
  templateUrl: 'filter-section.component.html',
  styleUrls: ['filter-section.component.scss'],
})
export class FilterSectionComponent implements OnInit {
  @Input() filterValues: ActiveFilters;
  @Input() availableFilterValues: AvailableFilterValues;
  @Output() filterChange = new EventEmitter<
    KeyValue<string, string | Date | null>
  >();
  @Output() filterCleared = new EventEmitter<undefined>();

  private isDutch = false;
  private readonly DUTCH_LANGUAGE_CODE = 'nl';

  constructor(
    public translateService: DvToolbarTranslateService,
    private dateAdapter: DateAdapter<Date>,
    private dvUserService: DvUserService
  ) {}

  ngOnInit(): void {
    this.dvUserService.user$.pipe(take(1)).subscribe((user) => {
      if (user.languageCode === this.DUTCH_LANGUAGE_CODE) {
        this.dateAdapter.setLocale(this.DUTCH_LANGUAGE_CODE);
        this.isDutch = true;
      }
    });
  }

  setFilter(event: MatSelectChange, key: string): void {
    if (this.isDutch) {
      event.value = this.formatDate(event.value);
    }

    let value: string | Date | null = '';
    switch (key) {
      case 'from':
        value = event.value ? new Date(event.value) : null;
        break;
      case 'to':
        value = event.value ? new Date(event.value) : null;
        break;
      default:
        value = event.value;
    }

    this.filterChange.emit({ key, value });
  }

  private formatDate(dateString: string): string {
    return dateString.split('-').reverse().join('-');
  }

  clearFilters(): void {
    Object.keys(this.filterValues).forEach(
      (key) => (this.filterValues[key] = null)
    );
    this.filterCleared.emit();
  }
}
