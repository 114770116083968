<mat-card appearance="outlined">
  <div fxLayout="row" fxLayoutAlign="end end">
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <span>{{'Sök efter kommun' | dvt}}</span>
  </div>
  <mat-form-field>
    <mat-label>{{'Sök' | dvt}}</mat-label>
    <input autofocus [formControl]="searchControl" matInput placeholder="{{'Sök' | dvt}}">
  </mat-form-field>
  <div style="max-height: 200px; overflow-y: scroll">
    <mat-action-list *ngFor="let d of filteredData | async">
      <button mat-list-item (click)="choose(d.value)">{{d.name}} ({{d.value}})</button>
    </mat-action-list>
  </div>
</mat-card>