import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MachineConnectionService } from './machine-connection.service';
import {
  INIT_STATUS,
  MACHINE_CONNECTION_STEP,
  Machine,
  MachineProvider,
} from './machine-connection.types';
import { MatStepper } from '@angular/material/stepper';
import { DialogRef } from '@angular/cdk/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrescriptionExportData } from '../prescription-export.types';
import { delay } from 'rxjs/operators';
import { DvmDialogService } from '@dvm/components';
import { extractErrorText } from 'app/util/error_utils';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-machine-connection-dialog',
  templateUrl: 'machine-connection-dialog.component.html',
  styleUrls: ['machine-connection-dialog.component.scss'],
  providers: [MachineConnectionService],
})
export class MachineConnectionDialogComponent implements AfterViewInit {
  loading = true;
  successText = '';
  initPercentage = 0;
  machines: Machine[] = [];
  selectedMachine: Machine;
  initStatus = INIT_STATUS.INIT;
  providers: MachineProvider[] = [];
  private originalProviders: string[] = [];
  private readonly ERROR_MESSAGE_DURATION_MS = 5000;

  @ViewChild('stepper') stepperRef: MatStepper;

  constructor(
    @Inject(DialogRef) private dialogRef: DialogRef,
    @Inject(MAT_DIALOG_DATA) private data: PrescriptionExportData,
    private machineConnectionService: MachineConnectionService,
    private translate: DvToolbarTranslateService,
    private dvmDialogService: DvmDialogService
  ) {}

  ngAfterViewInit(): void {
    this.getProviders();
  }

  private getProviders(): void {
    this.updateInitStatus(INIT_STATUS.FETCHING_PROVIDERS);

    this.machineConnectionService
      .getProviders()
      .pipe(delay(1000)) // A delay is needed to avoid dialog from sometimes being empty
      .subscribe(
        (result) => {
          this.providers = result;
          this.setOriginalProviders();

          if (this.originalProviders.length) {
            this.getMachines();
          } else {
            this.toProviders();
            this.loading = false;
          }
        },
        (error) => {
          this.showErrorMessage(error.error.error);
          this.dialogRef.close();
        }
      );
  }

  private setOriginalProviders(): void {
    this.originalProviders = this.providers
      .filter((provider) => provider.isConnected)
      .map((provider) => provider.providerCode);
  }

  private hasConnectedProviders(): boolean {
    return !!this.providers.filter((provider) => provider.isConnected).length;
  }

  private updateInitStatus(newStatus: INIT_STATUS): void {
    this.initStatus = newStatus;
    const statuses = Object.values(INIT_STATUS);

    this.initPercentage =
      (statuses.findIndex((status) => status === newStatus) / statuses.length) *
      100;
  }

  onChange(provider: MachineProvider, checked: boolean): void {
    provider.isConnected = checked;
  }

  createConnection(): void {
    this.setProviders();
    this.revokeProviders();
  }

  private setProviders(): void {
    this.loading = true;

    this.machineConnectionService.setProviders(this.providers).subscribe(
      () => {
        this.loading = false;
        if (this.hasConnectedProviders()) {
          this.stepperRef.next();
        }
      },
      () => {
        this.loading = false;
        this.showErrorMessage();
      }
    );
  }

  private revokeProviders(): void {
    const providersToRevoke = this.providers
      .filter((provider) => !provider.isConnected)
      .filter((provider) =>
        this.originalProviders.includes(provider.providerCode)
      );

    this.machineConnectionService
      .revokeProviders(providersToRevoke)
      .subscribe(() => this.setOriginalProviders());
  }

  toProviders(): void {
    this.stepperRef.selectedIndex = MACHINE_CONNECTION_STEP.SELECT_PROVIDER;
  }

  signIn(): void {
    this.loading = true;
    this.updateInitStatus(INIT_STATUS.PROVIDER_AUTH);

    this.machineConnectionService.signInToProviders().subscribe(
      () => {
        this.getMachines();
      },
      () => {
        this.loading = false;
        this.showErrorMessage();
      }
    );
  }

  onCloseDialog(): void {
    this.stepperRef.reset();
    this.dialogRef.close();
  }

  private getMachines(): void {
    this.updateInitStatus(INIT_STATUS.FETCHING_MACHINES);

    this.machineConnectionService.getMachines().subscribe(
      (machines) => {
        this.machines = machines;
        this.loading = false;
        this.stepperRef.selectedIndex = MACHINE_CONNECTION_STEP.MACHINE_LIST;
      },
      () => {
        this.stepperRef.selectedIndex = MACHINE_CONNECTION_STEP.SIGN_IN;
        this.loading = false;
      }
    );
  }

  sendToMachine(): void {
    this.loading = true;

    this.machineConnectionService
      .sendToMachine({
        ...this.data,
        machineId: this.selectedMachine.id,
      })
      .subscribe(
        () => {
          this.loading = false;
          this.stepperRef.next();
          this.updateSuccessText();
        },
        () => {
          this.showErrorMessage();
          this.loading = false;
        }
      );
  }

  private showErrorMessage(errorText = ''): void {
    const error = extractErrorText(errorText);

    this.dvmDialogService.message(
      this.translate.t(error),
      this.translate.t('Please try again.'),
      true,
      false,
      this.ERROR_MESSAGE_DURATION_MS
    );
  }

  private updateSuccessText(): void {
    this.successText = this.translate.t(
      '_machine_success',
      '_Machine {0} has received prescription file {1}.',
      this.selectedMachine.name,
      this.data.fileName
    );
  }
}
