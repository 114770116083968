import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from 'app/services/client.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'dv-redirect',
  template: '<p>Redirecting...</p>',
  standalone: true,
})
export class RedirectFromMarkkarteringComponent implements OnInit {
  id: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientService
  ) {}

  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          const orgNumber = params.get('orgnumber');
          return this.clientService.fetchIdFromOrgNr(orgNumber);
        })
      )
      .subscribe(
        (id) => {
          console.log('Fetched ID:', id);
          this.router.navigate([`client/${id}/data`]);
        },
        (error) => {
          console.error('Failed to fetch ID', error);
        }
      );
  }
}
