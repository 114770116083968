import { Component } from '@angular/core';
import { DvmCardModule } from '@dvm/components';

@Component({
  selector: 'dv-nested-item',
  templateUrl: 'nested-item.component.html',
  styleUrls: ['nested-item.component.scss'],
  standalone: true,
  imports: [DvmCardModule],
})
export class NestedItemComponent {}
