<div class="section">
    <div class="section-header">{{ 'Imported data points' | dvt }}</div>
    <div class="point-info" *ngIf="!loadingData; else infoSpinner">{{ pointLength }}</div>
    <ng-template #infoSpinner>
        <mat-progress-spinner
            class="point-info-spinner"
            diameter="40"
            mode="indeterminate"
        ></mat-progress-spinner>
    </ng-template>
</div>
<div class="section">
    <div *ngIf="selectedProperties; else selectGuide">
        <div class="section-header">{{ 'Selected data point' | dvt }}</div>
        <mat-card appearance="outlined" class="data-card">
            <div
                *ngIf="propKeys.length > 0; else noProperties"
                class="data-wrapper"
            >
                <div
                    class="data-row"
                    *ngFor="let prop of propKeys"
                >
                    <div class="label">{{ prop }}</div>
                    <div class="value">{{ isNumber(selectedProperties[prop]) ? (selectedProperties[prop] | number: '1.2-2': 'en') : selectedProperties[prop] }}</div>
                </div>
            </div>
            <ng-template #noProperties>
                {{ 'No properties found in the selected point' | dvt }}
            </ng-template>
        </mat-card>
    </div>
    <ng-template #selectGuide>
        <mat-card appearance="outlined" class="point-guide">
            <img
                class="point-guide-image"
                src="assets/images/point-guide.png"
            />
            <div class="point-guide-info">
                <div class="point-guide-title">
                    {{ '_select_point_on_map' | dvt: '_Select a point on the map' }}
                </div>
                <div class="point-guide-subtitle">
                    {{ '_guide_select_geodatapoints' | dvt: '_You can inspect the imported data at each point by selecting them in the map.' }}
                </div>
            </div>
        </mat-card>
    </ng-template>
</div>
