import { Injectable, NgZone } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import {
  FileSetImportStatusMsg,
  GeoDataInterpolationStatusMsg,
} from 'app/models/filestatus.model';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { ClientService } from './client.service';
import { DvAuthService } from '@dv/toolbar-msal';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  private importFileSetStatus$ = new Subject<FileSetImportStatusMsg>();
  private interpolateGeoDataStatus$ =
    new Subject<GeoDataInterpolationStatusMsg>();
  private connection: signalR.HubConnection = null;

  constructor(
    private zone: NgZone,
    private clientSrv: ClientService,
    private dvAuthService: DvAuthService
  ) {
    this.clientSrv.clientId().subscribe((clientId) => {
      this.initSignalR(clientId);
    });
  }

  private initSignalR(clientId: number): void {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.messagesUrl}mapping?clientid=${clientId}`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => {
          return this.dvAuthService.getAccessToken() as string;
        },
      })
      .configureLogging(
        environment.production
          ? signalR.LogLevel.Error
          : signalR.LogLevel.Information
      )
      .withAutomaticReconnect()
      .build();

    this.connection.on(
      'OnImport',
      (_fileSetId: number, statusMsg: FileSetImportStatusMsg) => {
        this.zone.run(() => {
          this.importFileSetStatus$.next(statusMsg);
        });
      }
    );

    this.connection.on(
      'OnInterpolation',
      (_geoDataId: number, statusMsg: GeoDataInterpolationStatusMsg) => {
        this.zone.run(() => {
          this.interpolateGeoDataStatus$.next(statusMsg);
        });
      }
    );

    this.connection
      .start()
      .then(() => {
        return;
      })
      .catch((err) => console.warn(err));
  }

  onFileSetImportStatus(): Observable<FileSetImportStatusMsg> {
    return this.importFileSetStatus$.asObservable();
  }

  onGeoDataInterpolationStatus(): Observable<GeoDataInterpolationStatusMsg> {
    return this.interpolateGeoDataStatus$.asObservable();
  }
}
