import { TextOverlay } from './TextOverlay';
import * as turf from '@turf/turf';
import { MapComponent } from '../map.component';
export class FeatureTextOverlay extends TextOverlay {


    constructor(private feature: google.maps.Data.Feature, private property: string, dvMap: MapComponent) {
        super(<string>feature.getProperty(property), new google.maps.LatLng(0, 0), dvMap.map);


        this.set("skifteId", this.feature.getProperty('skifteId'));

        dvMap.getGeoJson([feature]).subscribe(fc => {
            let center = turf.pointOnFeature(<turf.AllGeoJSON>fc).geometry.coordinates;

            this.centerPoint = new google.maps.LatLng(center[1], center[0]);

            if (this.feature.getProperty('labelHide') === true || this.feature.getProperty('labelHide') === 1)
                this.visible = false;

            if (this.feature.getProperty('labelX') && this.feature.getProperty('labelY') && this.feature.getProperty('labelX') !== 0 && this.feature.getProperty('labelY') !== 0) {
                let x = Number(this.feature.getProperty('labelX'));
                let y = Number(this.feature.getProperty('labelY'))
                this.pos = new google.maps.LatLng(y, x);
            }
            else
                this.pos = new google.maps.LatLng(center[1], center[0]);
        });
    }

    draw() {
        this.text = <string>this.feature.getProperty(this.property);
        super.draw();
    }

}