import { Component, Input, OnInit } from '@angular/core';
import { MapComponent } from 'app/components/map/map.component';
import { DeviationModel } from 'app/models/interpolation.model';
import { ManualDrawService } from '../../manual-draw.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'dv-adjustments-manual-draw',
  templateUrl: './adjustments-manual-draw.component.html',
  styleUrls: ['./adjustments-manual-draw.component.scss'],
})
export class AdjustmentsManualDrawComponent implements OnInit {
  @Input() dvMap: MapComponent;

  currentIndex = -1;
  private drawListeners: google.maps.MapsEventListener[] = [];
  private isDrawing = false;

  get deviations$(): Observable<DeviationModel[]> {
    return this.manualDrawService.tempDeviations$;
  }

  get activeDeviationIndex$(): Observable<number> {
    return this.manualDrawService.activeDeviationIndex$;
  }

  constructor(private manualDrawService: ManualDrawService) {}

  ngOnInit(): void {
    this.activeDeviationIndex$.subscribe((index) => {
      this.currentIndex = index;

      if (index > -1) {
        this.deactivateDrawingMode();
        this.activateDrawingMode(index);
      } else {
        this.deactivateDrawingMode();
      }
    });
  }

  private activateDrawingMode(index: number): void {
    if (this.dvMap) {
      this.deviations$.pipe(take(1)).subscribe((deviations) => {
        this.dvMap.map.setOptions({ draggable: false });

        this.drawListeners.push(
          this.dvMap.map.data.addListener('mousedown', (event) => {
            this.manualDrawService.toggleFeatureDeviation(
              event.feature,
              deviations[index]
            );
            this.isDrawing = true;
          })
        );

        this.drawListeners.push(
          this.dvMap.map.data.addListener(
            'mouseup',
            () => (this.isDrawing = false)
          )
        );

        this.drawListeners.push(
          this.dvMap.map.data.addListener('mouseover', (event) => {
            if (this.isDrawing) {
              this.manualDrawService.toggleFeatureDeviation(
                event.feature,
                deviations[index],
                true
              );
            }
          })
        );
      });
    }
  }

  private deactivateDrawingMode(): void {
    this.drawListeners.forEach((listener) =>
      google.maps.event.removeListener(listener)
    );
    this.drawListeners = [];
  }

  addDeviation(): void {
    this.manualDrawService.addTempDeviation();
  }

  setActiveDeviation(newIndex: number): void {
    this.manualDrawService.setActiveDeviationIndex(newIndex);
  }

  deleteDeviation(deviation: DeviationModel): void {
    this.manualDrawService.deleteTempDeviation(deviation);
  }
}
