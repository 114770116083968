<ng-container *ngFor="let input of inputs">
  <div
    class="function-input-wrapper"
    *ngIf="input.id !== PARAMETER_IDS.ADJUST_PH; else adjustPhTemplate"
  >
    <div class="parameter-text-wrapper">
      <dvm-text h4 [dvmTooltip]="input.description">
        {{ input.name }}
        <span
          *ngIf="
            !input.optional && input.parameterType !== PARAMETER_TYPES.OPTION
          "
        >
          *
        </span>
      </dvm-text>
    </div>
    <dvm-input
      *ngIf="input.parameterType === PARAMETER_TYPES.CONSTANT"
      class="parameter-input"
      [type]="input.type"
      [min]="input.definition.minLimit"
      [max]="input.definition.maxLimit"
      [placeholder]="0"
      [value]="input.value"
      [errorMessage]="input.errorMessage"
      (input)="
        onValueChange(
          $event.target.value,
          input,
          $event.target.validity.badInput
        )
      "
    >
      <span unit>{{ input.unit }}</span>
    </dvm-input>
    <dvm-select
      *ngIf="input.parameterType === PARAMETER_TYPES.SELECT"
      class="parameter-input"
      [options]="input.definition.mappedOptions"
      [value]="input.definition.selected"
      (selectionChange)="onValueChange($event.key, input)"
    ></dvm-select>
    <dvm-checkbox
      *ngIf="input.parameterType === PARAMETER_TYPES.OPTION"
      class="parameter-option"
      [checked]="input.value"
      (change)="optionValueChange($event.target, input)"
    ></dvm-checkbox>
  </div>
  <ng-template #adjustPhTemplate>
    <dv-ph-input
      class="function-input-wrapper"
      [adjustPhParameter]="input"
      [targetPhParameter]="targetPhParameter"
      (phChange)="onPhValueChange($event)"
    ></dv-ph-input>
  </ng-template>
</ng-container>
