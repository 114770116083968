import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import {
  DeviationModel,
  InterpolationModel,
} from 'app/models/interpolation.model';
import { PrescriptionModel, PropertyInfoModel } from 'app/models/models';
import {
  PrescriptionBasisModel,
  PrescriptionFunctionModel,
  PrescriptionGridModel,
  PrescriptionType,
  PrescriptionUnit,
} from 'app/models/prescriptiongrid.model';
import { ClientService } from 'app/services/client.service';
import {
  FieldInterpolationService,
  SelectableFieldGridModel,
} from 'app/services/field-interpolation.service';
import { MapStateService } from 'app/services/map-state.service';
import { PrescriptionService } from 'app/services/prescription.service';
import { SiteService } from 'app/services/site.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { InsufficientRightsDialogComponent } from '../insufficient-rights-dialog/insufficient-rights-dialog.component';
import { MapComponent } from '../map/map.component';
import { MapService } from '../map/map.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-prescription-view',
  templateUrl: './prescription-view.component.html',
  styleUrls: ['./prescription-view.component.scss'],
})
export class PrescriptionViewComponent implements OnInit, OnDestroy {
  @Input() loading: boolean;
  private _unsub$: Subject<void> = new Subject<void>();
  private updatePrescriptionTypeTimer: ReturnType<typeof setTimeout>;
  private updateDeviationTimer: ReturnType<typeof setTimeout>;
  private readonly ERR_NOGRID = this.translateService.t(
    '_prescriptionerr_no_grid',
    '_something went wrong. No grid available'
  );
  private readonly ERR_PRESCRIPTION_FAILED = this.translateService.t(
    '_prescriptionerr_no_prescription',
    '_something went wrong. No prescription could be calculated'
  );

  readonly MANUAL_METHOD: PrescriptionFunctionModel = {
    id: 0,
    activeIngredient: '',
    cutoffPoint: false,
    functionName: this.translateService.t('Manual'),
    functionType: '',
    maxRate: false,
    minRate: false,
    variables: null,
    yield: false,
  };

  gridSetting: PrescriptionGridModel;

  showSettings = false;
  showHelp = false;
  emptyBucketPrescriptions = true;
  types: string[];
  units: string[];
  bucketCount = 5;
  selectedData: SelectableFieldGridModel[] = [];
  selectedType: string;
  selectedProp: PropertyInfoModel;
  selectedFormula: PrescriptionFunctionModel = this.MANUAL_METHOD;
  activeFiles = [];
  prescriptionFunctions: PrescriptionFunctionModel[];
  allPrescriptionFunctions: PrescriptionFunctionModel[];
  showBasisInterpolation = false;
  basisInterpolation: PrescriptionBasisModel = null;
  basisError: string;
  nutritionalContent = 100;
  basisType: string = null;
  private hasCropMapAdvanced = false;

  deviations: DeviationModel[] = [];
  activeDeviation = -1;
  prescError: string = null;
  private availableProps: PropertyInfoModel[];

  dvMap: MapComponent;

  constructor(
    private prescrSrv: PrescriptionService,
    public mapStateService: MapStateService,
    public clientSrv: ClientService,
    public translateService: DvToolbarTranslateService,
    public mapSrv: MapService,
    private siteService: SiteService,
    private router: Router,
    private dialog: MatDialog,
    private interpolateSrv: FieldInterpolationService
  ) {
    this.types = this.getTypes();
    this.units = this.getUnits();
  }

  ngOnDestroy(): void {
    this.dvMap.clearDeviations();
    this._unsub$.next();
    this._unsub$.complete();
  }

  ngOnInit(): void {
    this.getCropMapAdvancedAccess();

    this.gridSetting = this.prescrSrv.originalGridSettings;
    if (!this.gridSetting || !this.gridSetting.geoDataFileIds?.length) {
      this.prescError = this.translateService.t(
        '_prescriptionerr_no_geodata',
        '_no geodata selected'
      );
    }
    this.getGeodataInfo();

    this.mapSrv
      .mainMap()
      .pipe(take(1))
      .subscribe((dvMap) => {
        this.dvMap = dvMap;
        //TODO this.trackDeviationChanges();
        this.dvMap.setDrawingMode(
          false,
          this.activeDeviation !== -1
            ? this.deviations[this.activeDeviation]
            : null
        );
        this.prescrSrv.drawPrescriptionGrid(this.gridSetting.prescription);
        this.getBasisInfo();
      });

    this.getGrid();
  }

  updateBucketCount(bucketChange: MatSelectChange): void {
    this.bucketCount = Number(bucketChange.value);
    this.gridSetting.prescription.bucketCount = this.bucketCount;
    this.getGrid();
  }

  updateUnit(unitChange: MatSelectChange): void {
    this.prescrSrv.setUnit(<PrescriptionUnit>Number(unitChange.value));
  }

  updateGridSize(sizeChange: MatSelectChange): void {
    this.gridSetting.prescription.gridSize = sizeChange.value;
    this.getGrid();
  }

  updateGridRotation(rotChange: MatSelectChange): void {
    this.gridSetting.prescription.gridRotation = rotChange.value;
    this.getGrid();
  }

  updatePrescriptionType(typeChange: MatSelectChange): void {
    this.prescrSrv.type = <PrescriptionType>Number(typeChange.value);
    this.prescrSrv.setDefaultValues();
  }

  startUpdatePrescriptionTimer(): void {
    clearTimeout(this.updatePrescriptionTypeTimer);
    this.updatePrescriptionTypeTimer = setTimeout(() => {
      this.updatePrescription();
    }, 3000);
  }

  unFocusInput(): void {
    setTimeout(() => {
      if (
        document.activeElement.tagName &&
        document.activeElement.tagName !== 'INPUT'
      ) {
        clearTimeout(this.updatePrescriptionTypeTimer);
        this.updatePrescription();
      }
    }, 0);
  }

  toggleSettings(): void {
    this.showSettings = !this.showSettings;
  }

  toggleHelp(): void {
    this.showHelp = !this.showHelp;
  }

  resetFormula(): void {
    this.resetPrescriptionFunctionSettings();
    //TODO this.OLDPRESC.setBucketCount(5);
    this.gridSetting.prescription = this.prescrSrv.EMPTY_PRESCRIPTION;
    this.selectedFormula = this.MANUAL_METHOD;
  }

  getPrescriptionFunctions(): void {
    this.resetFormula();
    if (
      !this.allPrescriptionFunctions ||
      !this.allPrescriptionFunctions.length ||
      !this.availableProps
    ) {
      return;
    }
    this.prescriptionFunctions = this.allPrescriptionFunctions.filter(
      (prescFunc) => {
        let propExists = true;
        prescFunc.variables.forEach((funcVar) => {
          if (
            this.availableProps.find(
              (prop) =>
                prop.type === funcVar.layerType &&
                prop.field === funcVar.layerField
            ) === undefined
          ) {
            propExists = false;
          }
        });
        return propExists;
      }
    );
  }

  export(): void {
    if (this.hasCropMapAdvanced) {
      this.prescrSrv.openExportDialog(
        this.gridSetting.prescription,
        this.nutritionalContent
      );
    } else {
      const conf = {
        width: '800px',
        data: {
          functionName: this.translateService.t('Save prescription file'),
        },
        panelClass: 'insufficient-dialog',
      };
      this.dialog.open(InsufficientRightsDialogComponent, conf);
    }
  }

  getTotal(): number {
    return !this.gridSetting.prescription ||
      !this.gridSetting.prescription.total
      ? 0
      : this.nutritionalContent &&
        this.nutritionalContent > 0 &&
        this.gridSetting.prescription.total
      ? Math.round(
          (this.gridSetting.prescription.total /
            (this.nutritionalContent / 100)) *
            10
        ) / 10
      : Math.round(this.gridSetting.prescription.total * 10) / 10;
  }

  resetPrescriptionFunctionSettings(): void {
    if (!this.gridSetting) {
      this.gridSetting = this.prescrSrv.getNewGridSetting();
    }
    this.gridSetting.functionId = 0;
    this.nutritionalContent = 100;
    if (this.gridSetting.prescription) {
      this.gridSetting.prescription.functionId = 0;
      this.gridSetting.prescription.maxRate = null;
      this.gridSetting.prescription.minRate = null;
      this.gridSetting.prescription.yield = 0;
      this.gridSetting.prescription.cutoffPoint = 0;
    }
  }

  setSelectedFormula(formula: PrescriptionFunctionModel): void {
    if (this.gridSetting.prescription.functionId !== formula.id) {
      this.resetPrescriptionFunctionSettings();
      this.selectedFormula = formula;
      this.gridSetting.prescription.functionId = formula.id;
      this.gridSetting.functionId = formula.id;
      if (formula.id !== this.MANUAL_METHOD.id) {
        const property = formula.variables.find(
          (func) => func.layerType === this.prescrSrv.originalGridSettings.type
        )?.layerField;
        if (property) {
          this.gridSetting.property = property;
        } else {
          this.handleCantFindPropertyInDataError(
            formula.variables[0].layerField
          );
          return;
        }
      } else {
        this.gridSetting.property =
          this.prescrSrv.originalGridSettings.property;
      }
      this.getGrid();
      this.getBasisInfo();
    }
  }

  setSelectedType(value: PrescriptionType): void {
    if (value !== this.prescrSrv.type) {
      this.prescrSrv.type = value;
      this.prescrSrv.setDefaultValues();
      this.resetFormula();
      this.getGrid();
    }
  }

  toggleShownInterpolation(): void {
    this.showBasisInterpolation = !this.showBasisInterpolation;
    if (this.showBasisInterpolation) {
      if (this.activeDeviation !== -1) {
        this.setActiveDeviation(-1);
      }
      this.mapStateService.setPrescriptionBasis(this.basisInterpolation);
    } else {
      this.mapStateService.clearPrescriptionBasis();
    }
  }

  setActiveDeviation(pos: number): void {
    if (pos !== this.activeDeviation) {
      if (this.activeDeviation !== -1) {
        this.dvMap.setDrawingMode(false, this.deviations[this.activeDeviation]);
      }
      this.activeDeviation = pos;
      if (pos !== -1) {
        if (this.showBasisInterpolation) {
          this.toggleShownInterpolation();
        }
        this.dvMap.setDrawingMode(true, this.deviations[this.activeDeviation]);
      }
    } else if (
      document.activeElement.tagName &&
      document.activeElement.tagName !== 'INPUT'
    ) {
      this.setActiveDeviation(-1);
    }
  }

  gotoInterpolationWizard(): void {
    this.clientSrv.clientId().subscribe((clientId) => {
      this.router.navigateByUrl('client/' + clientId + '/map/mapping');
    });
  }

  addDeviation(): void {
    this.deviations.push({
      id: this.deviations.length,
      color: this.prescrSrv.getDeviationColor(this.deviations.length),
      features: [],
      deviationRate: 0,
    });
    this.setActiveDeviation(this.deviations.length - 1);
  }

  deleteDeviation(deviation: DeviationModel): void {
    this.activeDeviation = -1;
    this.dvMap.deleteDeviation(deviation);
  }

  updateDeviationFeatureValues(deviation: DeviationModel): void {
    clearTimeout(this.updateDeviationTimer);
    this.updateDeviationTimer = setTimeout(() => {
      deviation.features.forEach((ft) => {
        ft.setProperty('rate', deviation.deviationRate);
      });
    }, 200);
  }

  updateSelectedGeodata(event): void {
    const geodataIds: number[] = event.value.map(
      (element) => element.geoDataId
    );
    this.interpolateSrv.setSelectedGeodata(geodataIds);
    this.getBasisInfo();
  }

  availablePrescriptionFunctions(): PrescriptionFunctionModel[] {
    return this.prescriptionFunctions?.filter(
      (pf) =>
        pf.functionType.toLowerCase() ===
        this.prescrSrv
          .typeAsUntranslatedText(this.prescrSrv.type)
          ?.toLowerCase()
    );
  }
  inactivePrescriptionFunctions(): PrescriptionFunctionModel[] {
    const availableIds = this.availablePrescriptionFunctions().map((f) => f.id);
    return this.allPrescriptionFunctions?.filter(
      (apf) =>
        apf.functionType.toLowerCase() ===
          this.prescrSrv
            .typeAsUntranslatedText(this.prescrSrv.type)
            ?.toLowerCase() && !availableIds.includes(apf.id)
    );
  }

  isOptionAvailable(option: string): boolean {
    return (
      this.prescriptionFunctions?.filter(
        (pf) => pf.functionType.toLowerCase() === option.toLowerCase()
      ).length > 0
    );
  }

  private getCropMapAdvancedAccess(): void {
    this.siteService
      .hasCropMapAdvanced()
      .pipe(takeUntil(this._unsub$))
      .subscribe((hasCropmapAdvanced) => {
        this.hasCropMapAdvanced = hasCropmapAdvanced;
      });
  }

  private getGrid(): void {
    if (
      !this.gridSetting ||
      !this.gridSetting.geoDataFileIds?.length ||
      !this.gridSetting.fc ||
      !this.gridSetting.fc.features?.length
    ) {
      return;
    }
    this.prescError = undefined;
    this.prescrSrv.setLoading(true);
    this.prescrSrv
      .calculateGrid(this.gridSetting)
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        (prescription) => {
          if (prescription) {
            this.handleGridSuccess(prescription);
          } else {
            this.handleGridFail(this.ERR_NOGRID);
          }
        },
        () => {
          this.handleGridFail(this.ERR_NOGRID);
        }
      );
  }

  private handleGridSuccess(prescription: PrescriptionModel): void {
    this.gridSetting.prescription = prescription;
    this.prescrSrv.drawPrescriptionGrid(prescription);
    this.prescrSrv.setLoading(false);
  }

  private handleGridFail(error: string): void {
    this.prescError = error;
    this.prescrSrv.setLoading(false);
  }

  private updatePrescription(): void {
    if (!this.gridSetting?.prescription || !this.gridSetting?.prescription.fc) {
      this.prescError = this.ERR_NOGRID;
      return;
    }
    this.prescError = undefined;
    this.prescrSrv.setLoading(true);
    this.prescrSrv
      .recalculateGrid(this.gridSetting.prescription)
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        (prescription) => {
          if (prescription) {
            this.handlePrescriptionSuccess(prescription);
          } else {
            this.handlePrescriptionFail(this.ERR_PRESCRIPTION_FAILED);
          }
        },
        () => {
          this.handlePrescriptionFail(this.ERR_PRESCRIPTION_FAILED);
        }
      );
  }

  private handlePrescriptionSuccess(prescription: PrescriptionModel): void {
    this.gridSetting.prescription = prescription;
    this.prescrSrv.drawPrescriptionGrid(prescription);
    this.prescrSrv.setLoading(false);
  }

  private handlePrescriptionFail(errorMsg: string): void {
    this.prescError = errorMsg;
    this.prescrSrv.setLoading(false);
  }

  private handleCantFindPropertyInDataError(propertyName: string): void {
    this.prescError = this.translateService.t(
      '_prescriptionerr_property_not_in_data',
      '_Cannot find {0} in the selected data. Prescription cannot be calculated',
      propertyName
    );
  }

  private getAvailablePrescriptionFunctions(): void {
    this.prescrSrv
      .getPrescriptionFunctions()
      .pipe(takeUntil(this._unsub$))
      .subscribe((funcs) => {
        this.allPrescriptionFunctions = funcs;
        this.getPrescriptionFunctions();
      });
  }

  private getGeodataInfo(): void {
    this.interpolateSrv
      .getInterpolationProperties()
      .pipe(take(1))
      .subscribe((properties) => {
        this.availableProps = properties;
        this.getAvailablePrescriptionFunctions();
      });
  }

  private getBasisInfo(): void {
    this.interpolateSrv
      .getSelectableGeodata()
      .pipe(take(1))
      .subscribe((fieldGrids) => {
        this.selectedData = fieldGrids;
        this.basisInterpolation = <PrescriptionBasisModel>{
          property: this.gridSetting.property,
          selectedData: this.selectedData.filter((basis) => basis.selected),
          type: this.gridSetting.type,
          selectedParcels: this.gridSetting.fc,
        };
        this.getBasisInterpolation();
      });
  }

  private getBasisInterpolation(): void {
    this.basisError = undefined;

    this.clientSrv
      .getGeoDataInterpolation(
        this.basisInterpolation.type,
        this.basisInterpolation.property,
        this.basisInterpolation.selectedParcels,
        this.basisInterpolation.selectedData.map((data) => data.geoDataId)
      )
      .pipe(takeUntil(this._unsub$), take(1))
      .subscribe((interpolation) => {
        if (interpolation?.interpolations) {
          this.handleInterpolationSuccess(interpolation.interpolations);
        } else {
          const error = this.translateService.t(
            '_interpolaiton_no_matching_fields_error',
            '_There are no matching data and parcels in this request'
          );
          this.handleInterpolationError(error);
        }
      });
  }

  private handleInterpolationSuccess(interpolation: InterpolationModel): void {
    this.basisInterpolation.interpolation = interpolation;
  }

  private handleInterpolationError(errorMsg: string): void {
    this.basisError = errorMsg;
  }

  private getTypes(): string[] {
    const types: string[] = [];
    for (const type in PrescriptionType) {
      if (isNaN(Number(type))) {
        const typeEnum = PrescriptionType[<string>type];
        types.push(this.prescrSrv.typeAsText(typeEnum));
      }
    }
    return types;
  }

  private getUnits(): string[] {
    const units: string[] = [];
    for (const unit in PrescriptionUnit) {
      if (isNaN(Number(unit))) {
        const unitEnum = PrescriptionUnit[<string>unit];
        units.push(this.prescrSrv.unitAsText(unitEnum));
      }
    }
    return units;
  }
}
