<div class="section">
    <div class="section-header">
        <div>{{ 'Data information' | dvt }}</div>
        <div class="section-action">
            <button
                mat-button
                (click)="openGeodataMetadata(geodata.fileId)"
            >
                <mat-icon>edit</mat-icon>
                {{ 'Edit' | dvt }}
            </button>
        </div>
    </div>
    <mat-card appearance="outlined" class="information-card">
        <div class="data-wrapper">
            <div
                *ngFor="let data of reservedData"
                class="data-row"
            >
                <div class="label">{{ data.key }}</div>
                <div class="value">{{ data.value }}</div>
            </div>
            <div
                *ngFor="let data of customData"
                class="data-row"
            >
                <div class="label">{{ data.key }}</div>
                <div class="value">{{ data.value }}</div>
            </div>
        </div>
    </mat-card>
</div>
<div class="section">
    <div class="section-header">
        <div>
            {{ 'Overlapping field areas' | dvt }}
            <mat-progress-spinner 
                *ngIf="fieldsLoading" 
                mode="indeterminate" 
                diameter="18"
            ></mat-progress-spinner>
        </div>
        <div class="section-action">
            <button
                class="select-button"
                #selectButton
                mat-button
                (focus)="addOverlay()"
            >
                <mat-icon>expand_more</mat-icon>
                {{ selectedYear }}
                <mat-select
                    class="hidden-select"
                    (selectionChange)="toggleYear($event)"
                    (closed)="removeOverlay()"
                    [(ngModel)]="selectedYear"
                >
                    <mat-option
                        *ngFor="let yearOpt of years"
                        [value]="yearOpt"
                    >{{ yearOpt }}</mat-option>
                    <mat-option
                        *ngIf="years?.length == 0"
                        [value]="undefined"
                        >{{ 'No years' | dvt}}</mat-option>
                </mat-select>
            </button>
        </div>
    </div>
    <div class="field-cards-wrapper">
        <mat-card
            appearance="outlined"
            class="field-card"
            (click)="goToField(field.id)"
            *ngFor="let field of displayFields"
        >
            <dv-svg [svg]="field.svg" size="40px" color="#606066" class="field-card-icon"></dv-svg>
            <div class="field-card-label">{{field.name ? field.name:'-'}}</div>
            <div class="field-card-action"></div>
        </mat-card>
        <mat-card 
            appearance="outlined" 
            class="field-card"
            *ngIf="!fieldsLoading  && displayFields?.length == 0"
        >{{ 'No overlapping fields' | dvt }}</mat-card>
    </div>
</div>
<div class="section">
    <div class="section-header">
        <div>{{ 'Import information' | dvt }}</div>
    </div>
    <mat-card appearance="outlined" class="information-card">
        <div class="data-wrapper">
            <div class="data-row">
                <div class="label">{{ 'Imported' | dvt }}</div>
                <div class="value">{{ geodata?.importedDate | date: 'short' }}</div>
            </div>
            <div class="data-row">
                <div class="label">{{ 'Importer type and version' | dvt }}</div>
                <div class="value">{{ geodata?.importer }} v.{{ geodata?.importerVersion }}</div>
            </div>
            <div class="data-row">
                <div class="label">{{ 'File type' | dvt }}</div>
                <div class="value">
                    {{ '_data_filetype_' + geodata?.fileType | dvt: '_' + geodata?.fileType }}
                </div>
            </div>
            <div class="data-row" *ngIf="geodata?.rawFileId">
                <div class="label">{{ 'Imported from uploaded file' | dvt }}</div>
                <div class="value">{{ filesetInfo?.name }}</div>
            </div>
            <div class="data-row">
                <div class="label">{{ 'Interpolation status' | dvt }}</div>
                <div class="value">{{ geodata?.status }}</div>
            </div>
        </div>
    </mat-card>
</div>
