import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';
import {
  BucketModel,
  MappingLegendModel,
} from 'app/models/interpolation.model';
declare let google: any;

@Component({
  selector: 'dv-basis-legend',
  templateUrl: './basis-legend.component.html',
  styleUrls: ['./basis-legend.component.scss'],
})
export class BasisLegendComponent implements OnChanges {
  @Input() legend: MappingLegendModel;
  @Input() instance = '0';
  @Input() pointMode = false;
  @Input() maxArea: number;

  private _loading = true;
  @Input() set loading(value: boolean) {
    this._loading = value;
  }
  get loading(): boolean {
    return this._loading;
  }

  @ViewChild('histogram') histogramEl: any;
  @ViewChild('legend') legendEl: any;
  minimized = false;
  legendObj: MappingLegendModel;
  legendReady = false;
  roundingStr = '1.0-1';
  area: number;

  constructor(private translSrv: DvToolbarTranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.histogramEl) {
      this.histogramEl.nativeElement.innerHTML = '';
    }
    if (this.legendEl) {
      this.legendEl.nativeElement.innerHTML = '';
    }

    if (changes.legend.currentValue) {
      this.legendReady = false;
      this.legendObj = this.legend;
      const decimal = this.legendObj.decimal ? this.legendObj.decimal : 0;
      this.roundingStr = '1.0-' + decimal;
      google.charts.load('current', { packages: ['corechart'] });
      google.charts.setOnLoadCallback(
        ((): void => this.drawLegend(this.legend)).bind(this)
      );
      this.area = this.maxArea
        ? Math.min(this.maxArea, this.legend.totalArea)
        : this.legend.totalArea;
    }
  }

  drawLegend(legend: MappingLegendModel): void {
    const showAnnotation = true;
    // create datatable to plot
    const data = new google.visualization.DataTable();
    data.addColumn('string', this.translSrv.t('Label'));
    data.addColumn('number', this.translSrv.t('Area'));
    data.addColumn({ type: 'string', role: 'style' });
    data.addColumn({ type: 'string', role: 'tooltip' });
    if (showAnnotation && !this.pointMode) {
      data.addColumn({ type: 'string', role: 'annotation' });
    }

    // add rows to data table
    let rows = legend.intervals.map((intv) => {
      const lbl = intv.label
        ? intv.label
        : intv.min.toFixed(legend.decimal).toString();
      const val = intv.areal / 10000;
      const unit = this.pointMode
        ? ' ' + this.translSrv.t('unit_pts', '_pts') + ' '
        : ' ha ';
      const annot = val.toFixed(1) + unit;
      const tooltip =
        val.toFixed(1) +
        unit +
        ', [' +
        intv.min.toFixed(legend.decimal + 1) +
        ' - ' +
        intv.max.toFixed(legend.decimal + 1) +
        '] ' +
        legend.title;
      if (showAnnotation && !this.pointMode) {
        return [
          lbl,
          val,
          'bar{color: rgb(' +
            intv.minColor +
            '); fill-color: rgb(' +
            intv.minColor +
            ')}',
          tooltip,
          annot,
        ];
      } else {
        return [lbl, val, 'bar{color: rgb(' + intv.minColor + ')}', tooltip];
      }
    });

    // remove any empty buckets in the start and end
    rows = this.clearLeadingEmptyBuckets(rows);
    rows = this.clearTrailingEmptyBuckets(rows);
    data.addRows(rows);
    let annotationOpacity = 1;
    if (rows.length > 7) {
      annotationOpacity = 0;
    }
    // customise chart options
    const options = {
      title: this.pointMode ? '' : legend.title,
      width: 270,
      height: 270,
      legend: { position: 'center' },
      vAxis: this.pointMode
        ? {
            format: '# ' + this.translSrv.t('unit_pts', '_pts'),
            viewWindow: { min: 0 },
          }
        : { format: '# ha', viewWindow: { min: 0 } },
      bar: { groupWidth: '95%' },
      hAxis: { slantedText: true, slantedTextAngle: 45 },
      backgroundColor: { fill: 'transparent' },
      annotations: {
        textStyle: { opacity: annotationOpacity },
        stem: { color: 'transparent' },
      },
      chartArea: { width: '90%' },
    };

    // instantiate and draw our chart
    let chart = null;
    switch (legend.chartType) {
      case 'bar':
        chart = new google.visualization.BarChart(this.legendEl.nativeElement);
        break;
      case 'pie':
        chart = new google.visualization.PieChart(this.legendEl.nativeElement);
        break;
      default: //column chart is default
        chart = new google.visualization.ColumnChart(
          this.legendEl.nativeElement
        );
    }

    chart.draw(data, options);

    if (legend.buckets && legend.buckets.length > 0) {
      this.drawHistogram(legend.buckets);
    }

    this.legendReady = true;
  }

  drawHistogram(buckets: BucketModel[]): void {
    // create data table
    const data = new google.visualization.DataTable();
    data.addColumn('string', this.translSrv.t('Label'));
    data.addColumn('number', this.translSrv.t('Proportion'));
    data.addColumn({ type: 'string', role: 'style' });

    // add rows to data table
    const rows = buckets.map((b) => {
      const lbl = b.label;
      const val = b.percentage;
      return [lbl, val, 'bar{color: ' + b.color + '}'];
    });

    data.addRows(rows);

    // customise chart options
    const options = {
      title: this.translSrv.t('classification'),
      width: 360,
      height: 150,
      legend: { position: 'none' },
      vAxis: {
        format: "#' %'",
        viewWindowMode: 'explicit',
        viewWindow: {
          max: 100,
          min: 0,
          interval: 25,
        },
      },
      bar: { groupWidth: '95%' },
      backgroundColor: { fill: 'transparent' },
    };
    const formatter = new google.visualization.NumberFormat({
      fractionDigits: 0,
      suffix: '%',
    });
    formatter.format(data, 1);

    const chart = new google.visualization.ColumnChart(
      this.histogramEl.nativeElement
    );
    chart.draw(data, options);
  }

  private clearTrailingEmptyBuckets(rows: any[]): any[] {
    if (rows.length === 0) {
      return rows;
    }
    const element = rows.pop();
    if (element[1]) {
      rows.push(element);
      return rows;
    } else {
      return this.clearTrailingEmptyBuckets(rows);
    }
  }

  private clearLeadingEmptyBuckets(rows: any[]): any[] {
    if (rows.length === 0) {
      return rows;
    }
    const element = rows.shift();
    if (element[1]) {
      rows.unshift(element);
      return rows;
    } else {
      return this.clearLeadingEmptyBuckets(rows);
    }
  }
}
