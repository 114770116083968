import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PoiKategoriModel } from 'app/models/api.models';
import { ClientService } from 'app/services/client.service';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MapComponent } from '../map/map.component';
import { LAYER_NAME_MARKERING, MapService } from '../map/map.service';
import {
  MappingWizardService,
  TableMarkingState,
} from '../mapping-view/mapping-wizard/mapping-wizard.service';
import { PoiAddComponent } from './poi-add/poi-add.component';
import { PoiToolbarComponent } from './poi-toolbar/poi-toolbar.component';
import { PoiService } from './poi.service';
import { POI_STATES } from './poi.types';

@Component({
  selector: 'dv-poi',
  templateUrl: './poi.component.html',
  styleUrls: ['./poi.component.scss'],
})
export class PoiComponent implements OnInit, OnDestroy {
  @ViewChild(PoiToolbarComponent) poiToolbar: PoiToolbarComponent;
  @ViewChild(PoiAddComponent) poiAddComponent: PoiAddComponent;

  showFilter = false;
  categorys: PoiKategoriModel[] = null;
  selCategorys: string[] = [];
  showPOIButton = true;
  feature: google.maps.Data.Feature;
  state: string;
  loadingLayers = false;
  mainMap: MapComponent;
  POI_STATES = POI_STATES;
  private _unsub$: Subject<void> = new Subject<void>();
  private markingState: TableMarkingState;

  constructor(
    private clientService: ClientService,
    private mapService: MapService,
    private wizSrv: MappingWizardService,
    private poiSrv: PoiService
  ) {}

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
    this.poiSrv.setPoiFeature(null);
    if (this.mainMap) {
      this.mainMap.removeLayer(LAYER_NAME_MARKERING);
    }
    this.mapService.registerClickEvent(LAYER_NAME_MARKERING, null);
  }

  ngOnInit(): void {
    this.showPOIButton = true;

    this.clientService.loadPoiCategorys();
    this.clientService
      .getPoisCategorys()
      .subscribe((res) => (this.categorys = res));

    this.mapService.mainMap().subscribe((mainMap) => this.mainMapInit(mainMap));

    this.poiSrv
      .getPoiFeature()
      .pipe(takeUntil(this._unsub$))
      .subscribe((poi) => {
        this.feature = poi;
      });

    this.poiSrv
      .getState()
      .pipe(takeUntil(this._unsub$))
      .subscribe((state) => {
        this.state = state;
      });

    this.wizSrv
      .getTableMarkingsState()
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        this.markingState = res;
      });

    this.clientService
      .getPoisCategorys()
      .subscribe((res) => (this.categorys = res));
  }

  mainMapInit(mainMap: MapComponent): void {
    this.mainMap = mainMap;
    this.mapService.registerClickEvent(LAYER_NAME_MARKERING, (event) => {
      this.poiSrv.setPoiFeature(event.feature, POI_STATES.DETAIL);
    });

    this.loadLayer();
  }

  isCatSel(name: string): boolean {
    return this.selCategorys.includes(name);
  }

  toggleCat(name: string, sel: boolean): void {
    if (sel) {
      if (!this.selCategorys.includes(name)) {
        this.selCategorys.push(name);
      }
    } else {
      const index = this.selCategorys.indexOf(name);
      this.selCategorys.splice(index, 1);
    }
    this.mapService.mainMap().subscribe(() => {
      this.loadLayer();
    });
  }

  loadLayer(): void {
    this.loadingLayers = true;
    forkJoin(
      this.mapService.mainMap(),
      this.clientService.getPoiLayer()
    ).subscribe((res) => {
      res[1].features = res[1].features.filter((f) => {
        return (
          this.selCategorys.length === 0 ||
          this.selCategorys.includes(f.properties['kategori'])
        );
      });
      res[0].removeLayer(LAYER_NAME_MARKERING);
      res[0].addGeoJson(res[1], LAYER_NAME_MARKERING);
      this.loadingLayers = false;
    });
  }

  clearCategorys(): void {
    this.selCategorys = [];
  }

  editCategorys(): void {
    this.showFilter = false;
    this.poiSrv.setState(POI_STATES.CATEGORY);
  }

  goBack(): void {
    if (this.state === POI_STATES.CATEGORY) {
      this.poiSrv.setPrevState();
    } else {
      this.poiSrv.setState(null);
      this.wizSrv.setShowPoi(false);
    }
  }

  cancelDraw(): void {
    this.poiToolbar.cancelDraw();

    if (this.state === POI_STATES.EDIT_SINGLE) {
      this.poiSrv.setPoiFeature(null);
      this.wizSrv.setShowPoi(false);
    } else {
      this.poiSrv.setPoiFeature(null);
      this.loadLayer();
    }
  }

  featureSaved(event): void {
    this.poiToolbar.cancelDraw();
    if (!this.markingState) {
      this.markingState = {
        newPois: [event.id],
        activeFeatureIds: [],
        activeLayers: [],
        deletedPois: null,
      };
    } else if (this.markingState.newPois) {
      this.markingState.newPois.push(event.id);
    } else {
      this.markingState.newPois = [event.id];
    }

    this.wizSrv.setTableMarkingsState(this.markingState);
    this.wizSrv.setShowPoi(false);
    //Quits when layer is added
    // this.loadLayer();
  }

  featureDeleted(event): void {
    const id = event.getProperty('id');
    if (!this.markingState) {
      this.markingState = {
        newPois: null,
        activeFeatureIds: null,
        activeLayers: null,
        deletedPois: [id],
      };
    } else if (this.markingState.deletedPois) {
      this.markingState.deletedPois.push(id);
    } else {
      this.markingState.deletedPois = [id];
    }

    this.wizSrv.setTableMarkingsState(this.markingState);
    this.wizSrv.setShowPoi(false);
  }

  saveFeature(): void {
    this.poiAddComponent.savePoi();
  }
}
