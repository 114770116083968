<div class="detail-content">
  <div class="loading-area" *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="head">
    <div class="head-action" (click)="goToGeodataList()"></div>
    <div class="head-title" *ngIf="geodata">{{ geodata?.name }}</div>
  </div>
  <mat-tab-group class="tab-group-custom-border" mat-stretch-tabs="false">
    <mat-tab>
      <ng-template mat-tab-label>
        {{ 'Preview' | dvt }}
      </ng-template>
      <dv-geodata-interpolation
        *ngIf="geodata"
        [geodata]="geodata"
      ></dv-geodata-interpolation>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        {{ 'Imported data' | dvt }}
      </ng-template>
      <dv-geodata-rawdata-preview
        *ngIf="geodata"
        [geodata]="geodata"
      ></dv-geodata-rawdata-preview>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        {{ 'Details' | dvt }}
      </ng-template>
      <dv-geodata-file-details
        *ngIf="geodata"
        [geodata]="geodata"
      ></dv-geodata-file-details>
    </mat-tab>
  </mat-tab-group>
</div>
