<div class="content" *ngIf="!addMetadata">
    <h2>{{ 'File selected' | dvt }}</h2>
    <div>{{ '__metadata_add-metadata-question' | dvt: '_Do you want to add additional information about the file?' }}</div>
    <div class="button-wrapper">
        <button
            mat-button
            (click)="showAddMetadata()"
        >{{ 'Yes, add' | dvt }}</button>
        <button
            mat-raised-button
            color="primary"
            (click)="skip()"
        >{{ 'Skip' | dvt }}</button>
    </div>
</div>
<div class="content" *ngIf="addMetadata">
    <div class="scroll-wrapper" #scrollTarget>
        <h2>{{ 'Information about the file' | dvt }}</h2>
        <div>{{ 'Here you can tag your file with different keywords' | dvt }}</div>
        <div class="top-wrapper">
            <div class="input-wrapper">
                <label>{{ '__metadata_owner' | dvt:'_Customer' }}</label>
                <input
                    type="text"
                    [placeholder]="'Enter customer' | dvt"
                    [(ngModel)]="owner"
                />
            </div>
            <div class="input-wrapper">
                <label>{{ '__metadata_farm' | dvt:'_Farm' }}</label>
                <input
                    type="text"
                    [placeholder]="'Enter farm' | dvt"
                    [(ngModel)]="farm"
                />
            </div>
            <div class="input-wrapper">
                <label>{{ '__metadata_field' | dvt:'_Field' }}</label>
                <input
                    type="text"
                    [placeholder]="'Enter field' | dvt"
                    [(ngModel)]="field"
                />
            </div>
        </div>
        <div class="bottom-wrapper">
            <label>{{ 'Enter more rows' | dvt }}</label>
            <div class="arbitrary-input-column">
                <div class="arbitrary-input-row" *ngFor="let property of properties; index as i">
                    <div class="key-input-wrapper">
                        <input
                            type="text"
                            maxlength="50"
                            [placeholder]="'Enter a name' | dvt"
                            [(ngModel)]="property.key"
                            (keyup)="onKeyup(property)"
                        />
                        <span
                            *ngIf="property.errorMessage"
                            class="validation-message"
                        >* {{ property.errorMessage }}</span>
                    </div>
                    <input
                        type="text"
                        maxlength="252"
                        [placeholder]="'Enter a value' | dvt"
                        [(ngModel)]="property.value"
                    />
                    <button
                        *ngIf="i === properties.length - 1"
                        mat-mini-fab
                        class="fab"
                        (click)="addProperty()"
                    >
                        <mat-icon>add</mat-icon>
                    </button>
                    <button
                        *ngIf="i !== properties.length - 1"
                        mat-mini-fab
                        class="fab fab-light"
                        (click)="removeProperty(i)"
                    >
                        <mat-icon>remove</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <dvm-button secondary *ngIf="!metadata" (click)="skip()">{{
            'Skip' | dvt
          }}</dvm-button>
          <dvm-button secondary *ngIf="metadata" (click)="skip()">{{
            'Cancel' | dvt
          }}</dvm-button>
          <dvm-button [disabled]="disableSaveButton" (click)="saveMetadata()">{{
            'Save' | dvt
          }}</dvm-button>
    </div>
</div>
