import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dv-map-zoom',
  templateUrl: './map-zoom.component.html',
  styleUrls: ['../map.component.scss']
})
export class MapZoomComponent implements OnInit {

  @Output() zoomIn = new EventEmitter()
  @Output() zoomOut = new EventEmitter()

  constructor() { }

  ngOnInit() {
    
  }
  
  zoomInbtn() {
    this.zoomIn.emit();
  }

  zoomOutbtn() {
    this.zoomOut.emit();
  }
  
}
