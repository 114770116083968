<div mat-dialog-content style="width: 300px">
  <h2>{{'Farms' | dvt}}</h2>
  <span>{{'Change name' | dvt}}</span>
  <div class="row" *ngFor="let b of data.brukenheter">
    <img class="icon" src="assets/images/menu/icon-start.svg">
    <mat-form-field class="farm-edit" appearance="outline">
      <input [(ngModel)]="b.namn" matInput/>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="save()">{{'Save' | dvt}}</button>
    <button mat-raised-button (click)="cancel()">{{'Cancel' | dvt}}</button>
</div>
