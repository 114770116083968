import { Component, ViewChild, ElementRef, InjectionToken, Injector, Injectable, Output, EventEmitter, Input } from "@angular/core";
import { Overlay, OverlayModule, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { SearchableSelectOverlayComponent } from "./searchable-select-overlay.component";
import { CONTAINER_DATA } from "./Injector";
import { Observable, ReplaySubject } from "rxjs";
import { SearchableSelectService } from "./searchable-select.service";



@Component({
    selector: 'dv-searchable-select',
    templateUrl: 'searchable-select.component.html',
    styleUrls: ['searchable-select.component.css']
})
export class SearchableSelectComponent {
    selectedValue: any
    @Input() placeholderText: string = "";
    @Output() valueChanged = new EventEmitter()
    @Input() data: any;
    overlayRef: OverlayRef;

    constructor(private overlay : Overlay, private elementRef: ElementRef, private injector: Injector, private searchableSelectService : SearchableSelectService) {
        
    }

    ngOnInit() {
        this.searchableSelectService.getSelectedValue().subscribe(value => {
            this.selectedValue = value;

            if (this.overlayRef && this.overlayRef.hasAttached())
                this.hide();
            
            this.valueChanged.emit(this.selectedValue);
        })

        this.searchableSelectService.getCloseOverlay().subscribe(value => {
            if (value && this.overlayRef && this.overlayRef.hasAttached())
                this.hide();
        })
    }

    show() {
        if (this.overlayRef && this.overlayRef.hasAttached())
            this.hide();
        else {

            this.overlayRef = this.overlay.create({
                height: '225px',
                width: '100px',
                positionStrategy: this.overlay.position().flexibleConnectedTo(this.elementRef).withFlexibleDimensions(false)
                    .withDefaultOffsetY(-40)
                    .withLockedPosition()
                    .withPositions([
                        {
                            originX: 'start',
                            originY: 'bottom',
                            overlayX: 'start',
                            overlayY: 'top'
                        },
                        {
                            originX: 'start',
                            originY: 'top',
                            overlayX: 'start',
                            overlayY: 'bottom'
                        },
                        {
                            originX: 'end',
                            originY: 'bottom',
                            overlayX: 'end',
                            overlayY: 'top'
                        },
                        {
                            originX: 'end',
                            originY: 'top',
                            overlayX: 'end',
                            overlayY: 'bottom'
                        }])
            });

            this.overlayRef.backdropClick().subscribe(_ => this.hide());

            const component = new ComponentPortal(SearchableSelectOverlayComponent, null, this.createInjector(this.data));
            this.overlayRef.attach(component);
        }
    }

    createInjector(dataToPass): PortalInjector {
        const injectorTokens = new WeakMap();
        injectorTokens.set(CONTAINER_DATA, dataToPass);
        return new PortalInjector(this.injector, injectorTokens);
      }
    

    hide() {
        this.overlayRef.detach();
    }

    clear() {
        this.selectedValue = undefined;
        this.searchableSelectService.setSelectedValue(undefined);
    }

    ngOnDestroy() {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef.detach();
        }
        
        this.clear();
    }
}

