<div class="pane">
  <mat-horizontal-stepper #stepper class="stepper">
    <mat-step>
      <dv-step-field [skiften]="skiften" [loading]="loading"></dv-step-field>
    </mat-step>
    <mat-step>
      <dv-step-function
        [functions]="functions"
        [loading]="loading"
      ></dv-step-function>
    </mat-step>
    <mat-step>
      <dv-step-settings
        [function]="selectedFunction"
        [loading]="loading"
        (geoDataTypes)="onGeoDataTypes($event)"
        (settings)="onSettings($event)"
        (selectedSeedUnit)="onSelectedSeedUnit($event)"
        (gridSettingsChange)="onGridSettings($event)"
        (disablePreviewBar)="onDisablePreviewBar($event)"
      ></dv-step-settings>
    </mat-step>
    <mat-step>
      <dv-step-adjust
        [grid]="grid"
        [dvMap]="dvMap"
        [loading]="loading"
        [selectedSeedUnit]="selectedSeedUnit"
        (adjustments)="onAdjustments($event)"
      ></dv-step-adjust>
    </mat-step>
  </mat-horizontal-stepper>
  <div class="action-group">
    <dvm-button
      size="large"
      full-width
      [disabled]="selectedStepState$ | async"
      (click)="onStepSelect(selectedIndex + 1)"
    >
      <span *ngIf="selectedIndex < 3; else export">{{ 'NEXT' | dvt }}</span>
      <ng-template #export>
        <span>{{ 'Export' }}</span>
      </ng-template>
    </dvm-button>
  </div>
</div>
<div class="map">
  <dv-stepper-header
    class="stepper-header"
    (selectStep)="onStepSelect($event)"
    [selectedIndex]="selectedIndex"
    [stepState]="stepState$ | async"
  ></dv-stepper-header>
  <dv-settings-map-bar
    *ngIf="selectedIndex === PRESCRIPTION_STEPS.SETTINGS"
    [items]="geoDataTypes"
    [disabled]="disablePreviewBar"
  ></dv-settings-map-bar>
  <dv-map
    [isMainMap]="true"
    [fullScreen]="false"
    [multiSelect]="false"
    (onSelected)="onFieldSelected($event)"
    (onDeSelected)="onFieldDeselected($event)"
  ></dv-map>
  <dv-legend
    class="map-legend"
    *ngIf="legend"
    [legend]="legend"
    [resizable]="true"
    [instance]="dvMap"
  ></dv-legend>
  <dv-adjustments-legend
    *ngIf="grid && selectedIndex === PRESCRIPTION_STEPS.ADJUST"
    [totalArea]="totalArea"
    [grid]="grid"
    [seedUnit]="selectedSeedUnit"
  ></dv-adjustments-legend>
</div>
