<mat-list-item>
  <button mat-button (click)="toggleActive()">
    <img class="map-connect-field-image" src="assets/images/icon-connect.svg" />
    {{ 'Pick from previous year' | dvt }}
  </button>
</mat-list-item>
<div class="dv-maps-tool" *ngIf="isActive">
  <div class="content">
    <mat-form-field>
      <mat-select
        placeholder="Välj ett år"
        (selectionChange)="arSelected($event.value)"
      >
        <mat-option
          *ngFor="let ar of tillgangligaAr"
          [value]="ar"
          [disabled]="ar === currentAr"
        >
          {{ ar }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <p *ngIf="tillgangligaAr.length > 0 && ar === null">
      {{ 'Pick year to fetch parcels for' | dvt }}
    </p>
    <p
      class="map-connect-field-parts-paragraph"
      *ngIf="parts.length == 0 && ar !== null"
    >
      {{ 'Select parcel to connect to field' | dvt }}
    </p>

    <dv-map-field-part
      [skifteId]="skifteId"
      fxLayout="column"
      [parts]="parts"
      (save)="onSave($event)"
      (cancel)="toggleActive()"
    ></dv-map-field-part>
  </div>
</div>
