<div class="field-cards compact-form">
  <mat-card
    appearance="outlined"
    *ngIf="feature === null || feature === undefined"
    class="field-card dark add-field-box"
  >
    <mat-icon (click)="closeBox()" class="close-box">close</mat-icon>
    <div class="section">
      <div class="title">
        <span *ngIf="!drawMode">{{
          'Click the field you want to add' | dvt
        }}</span>
        <span *ngIf="drawMode">{{ 'Start draw field' | dvt }}</span>
      </div>
      <div class="content" *ngIf="!validZoom">
        {{ 'Zoom in to see blocks' | dvt }}
      </div>
      <div class="content" *ngIf="featureType !== 'skifte' && standAlone">
        <mat-slide-toggle
          color="primary"
          [(ngModel)]="drawField"
          (change)="drawFieldChange($event)"
        >
          {{ 'Draw field' | dvt }}
        </mat-slide-toggle>
      </div>
      <div class="svg" *ngIf="feature !== null && feature !== undefined">
        <dv-svg [svg]="svg" [color]="feature.getProperty('color')"></dv-svg>
      </div>
    </div>
  </mat-card>
  <!--Skifte-->
  <mat-card
    appearance="outlined"
    class="field-card active"
    *ngIf="
      feature !== null && feature !== undefined && featureType === 'skifte'
    "
  >
    <div class="section">
      <div class="row">
        <div class="col large">
          <label>{{ 'Field name' | dvt }}</label>
          <mat-form-field appearance="outline">
            <input
              matInput
              [placeholder]="'Field name' | dvt"
              autocomplete="off"
              [(ngModel)]="skifteName"
            />
          </mat-form-field>
        </div>
        <div class="col" *ngIf="feature && featureAreal">
          <label>{{ 'Areal' | dvt }}</label>
          <mat-form-field appearance="outline">
            <input
              disabled
              matInput
              [placeholder]="'Areal' | dvt"
              autocomplete="off"
              [value]="(featureAreal / 10000).toFixed(2)"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>{{ 'Select crop for this field' | dvt }}</label>
          <mat-form-field appearance="outline">
            <mat-select
              [compareWith]="compareCrops"
              #cropSelect
              class="utsaden"
              [(ngModel)]="selUtsade"
            >
              <mat-option>
                <button class="add-crop-field-button" (click)="addCrop()">
                  - {{ 'Add crop' | dvt }} -
                </button>
              </mat-option>
              <mat-option *ngFor="let utsade of tabellUtsaden" [value]="utsade">
                {{ utsade.groda }} {{ utsade.sort }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="brukningsenhter?.length > 1">
        <div class="col">
          <label>{{ 'Farm' | dvt }}</label>
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="selBruk">
              <mat-option *ngFor="let bruk of brukningsenhter" [value]="bruk">
                {{ bruk.namn }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="buttons">
        <button mat-stroked-button (click)="cancel()">
          {{ 'Cancel' | dvt }}
        </button>
        <div
          [matTooltip]="'Use CropPlan to edit your fields' | dvt"
          [matTooltipDisabled]="!hasOdling"
        >
          <button
            mat-raised-button
            color="primary"
            [disabled]="!skifteName || hasOdling"
            (click)="addSkifte()"
          >
            {{ 'Save' | dvt }}
          </button>
        </div>
      </div>
    </div>
  </mat-card>
  <!--Block-->
  <mat-card
    appearance="outlined"
    class="field-card active"
    *ngIf="feature !== null && feature !== undefined && featureType === 'block'"
  >
    <div class="section">
      <div class="row">
        <div class="col large">
          <label>{{ 'Field name' | dvt }}</label>
          <mat-form-field appearance="outline">
            <input
              matInput
              [placeholder]="'Field name' | dvt"
              autocomplete="off"
              [(ngModel)]="skifteName"
            />
          </mat-form-field>
        </div>
        <div class="col" *ngIf="feature && featureAreal">
          <label>{{ 'Areal' | dvt }}</label>
          <mat-form-field appearance="outline">
            <input
              disabled
              matInput
              [placeholder]="'Areal' | dvt"
              autocomplete="off"
              [value]="(featureAreal / 10000).toFixed(2)"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>{{ 'Select crop for this field' | dvt }}</label>
          <mat-form-field appearance="outline">
            <mat-select
              [compareWith]="compareCrops"
              #cropSelect
              class="utsaden"
              [(ngModel)]="selUtsade"
            >
              <mat-option>
                <button class="add-crop-field-button" (click)="addCrop()">
                  - {{ 'Add crop' | dvt }} -
                </button>
              </mat-option>
              <mat-option *ngFor="let utsade of tabellUtsaden" [value]="utsade">
                {{ utsade.groda }} {{ utsade.sort }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="brukningsenhter?.length > 1">
        <div class="col">
          <label>{{ 'Farm' | dvt }}</label>
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="selBruk">
              <mat-option *ngFor="let bruk of brukningsenhter" [value]="bruk">
                {{ bruk.namn }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="buttons">
        <mat-spinner
          *ngIf="loading"
          [diameter]="30"
          class="spinner"
        ></mat-spinner>
        <button mat-stroked-button (click)="cancel()">
          {{ 'Cancel' | dvt }}
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!skifteName || loading"
          (click)="addSkifte()"
        >
          {{ 'Save' | dvt }}
        </button>
      </div>
    </div>
  </mat-card>
</div>
