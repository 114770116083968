import { Component, OnInit, ViewChild, SimpleChanges, Input, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'dv-cropsat-legend',
  templateUrl: './cropsat-legend.component.html',
  styleUrls: ['./cropsat-legend.component.scss']
})
export class CropsatLegendComponent implements AfterViewInit {

  ctx: CanvasRenderingContext2D = null;
  widthOfColor: number = 48;
  margin: number = 0;
  data: any = null;
  startColor: string;
  endColor: string;
  height: number = 0;
  values: number[] = [];

  @Input() legendData;
  @Input() isCompact = false;

  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  constructor() { }

  ngAfterViewInit() {

    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.height = this.ctx.canvas.height - (this.margin * 2);

    this.draw();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.legendData.currentValue) {
      this.setData(changes.legendData.currentValue);
    }
  }

  public setData(data: any) {
    this.data = data;
    this.values = data.groups.slice(0, 5).map(g => g.value);
    this.startColor = this.colorToHex(this.data.startColor);
    this.endColor = this.colorToHex(this.data.endColor);

    this.draw();
  }

  private draw() {
    if (!this.ctx || !this.data)
      return;

    if (this.isCompact) {
      this.widthOfColor = 25;
      this.ctx.canvas.width = 25;
    }
    else {
      this.widthOfColor = 48;
      this.ctx.canvas.width = 50;
    }


    this.ctx.fillStyle = "#fff";
    this.ctx.fillRect(0, 0, 50, this.height);

    var margin = this.margin;
    var h = this.height - margin * 2;
    var w = this.widthOfColor;
    var x = margin;
    var y = margin;
    // fill recatangle
    for (var yy = y; yy < h + y; yy++) {
      var color = this.getColor(yy, h, this.startColor, this.endColor);

      this.ctx.fillStyle = color;
      this.ctx.strokeStyle = color;
      this.ctx.fillRect(x, yy, w, 1);
    }
  }

  private getColor(value: number, height: number, startColor: string, endColor: string) {

    var rgbTop = hexToRgb(endColor);
    var rgbBottom = hexToRgb(startColor);

    var r = Math.round(rgbTop.r + ((rgbBottom.r - rgbTop.r) * (height - value) / height));
    var g = Math.round(rgbTop.g + ((rgbBottom.g - rgbTop.g) * (height - value) / height));
    var b = Math.round(rgbTop.b + ((rgbBottom.b - rgbTop.b) * (height - value) / height));

    var newHex = rgbToHex(r, g, b);

    return newHex;

    function hexToRgb(hex) {
      if (hex[0] === "#")
        hex = hex.substring(1);

      var bigint = parseInt(hex, 16);
      var r = (bigint >> 16) & 255;
      var g = (bigint >> 8) & 255;
      var b = bigint & 255;

      return { r: r, g: g, b: b };
    }

    function componentToHex(rgb) {
      var hex = rgb.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    }

    function rgbToHex(r, g, b) {
      return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }


  }
  private colorToHex(color: string) {
    var colors = [{ name: 'aliceblue', value: '#f0f8ff' },
    { name: 'antiquewhite', value: '#faebd7' },
    { name: 'aqua', value: '#00ffff' },
    { name: 'aquamarine', value: '#7fffd4' },
    { name: 'azure', value: '#f0ffff' },
    { name: 'beige', value: '#f5f5dc' },
    { name: 'bisque', value: '#ffe4c4' },
    { name: 'black', value: '#000000' },
    { name: 'blanchedalmond', value: '#ffebcd' },
    { name: 'blue', value: '#0000ff' },
    { name: 'blueviolet', value: '#8a2be2' },
    { name: 'brown', value: '#a52a2a' },
    { name: 'burlywood', value: '#deb887' },
    { name: 'cadetblue', value: '#5f9ea0' },
    { name: 'chartreuse', value: '#7fff00' },
    { name: 'chocolate', value: '#d2691e' },
    { name: 'coral', value: '#ff7f50' },
    { name: 'cornflowerblue', value: '#6495ed' },
    { name: 'cornsilk', value: '#fff8dc' },
    { name: 'crimson', value: '#dc143c' },
    { name: 'cyan', value: '#00ffff' },
    { name: 'darkblue', value: '#00008b' },
    { name: 'darkcyan', value: '#008b8b' },
    { name: 'darkgoldenrod', value: '#b8860b' },
    { name: 'darkgray', value: '#a9a9a9' },
    { name: 'darkgrey', value: '#a9a9a9' },
    { name: 'darkgreen', value: '#006400' },
    { name: 'darkkhaki', value: '#bdb76b' },
    { name: 'darkmagenta', value: '#8b008b' },
    { name: 'darkolivegreen', value: '#556b2f' },
    { name: 'darkorange', value: '#ff8c00' },
    { name: 'darkorchid', value: '#9932cc' },
    { name: 'darkred', value: '#8b0000' },
    { name: 'darksalmon', value: '#e9967a' },
    { name: 'darkseagreen', value: '#8fbc8f' },
    { name: 'darkslateblue', value: '#483d8b' },
    { name: 'darkslategray', value: '#2f4f4f' },
    { name: 'darkslategrey', value: '#2f4f4f' },
    { name: 'darkturquoise', value: '#00ced1' },
    { name: 'darkviolet', value: '#9400d3' },
    { name: 'deeppink', value: '#ff1493' },
    { name: 'deepskyblue', value: '#00bfff' },
    { name: 'dimgray', value: '#696969' },
    { name: 'dimgrey', value: '#696969' },
    { name: 'dodgerblue', value: '#1e90ff' },
    { name: 'firebrick', value: '#b22222' },
    { name: 'floralwhite', value: '#fffaf0' },
    { name: 'forestgreen', value: '#228b22' },
    { name: 'fuchsia', value: '#ff00ff' },
    { name: 'gainsboro', value: '#dcdcdc' },
    { name: 'ghostwhite', value: '#f8f8ff' },
    { name: 'gold', value: '#ffd700' },
    { name: 'goldenrod', value: '#daa520' },
    { name: 'gray', value: '#808080' },
    { name: 'grey', value: '#808080' },
    { name: 'green', value: '#008000' },
    { name: 'greenyellow', value: '#adff2f' },
    { name: 'honeydew', value: '#f0fff0' },
    { name: 'hotpink', value: '#ff69b4' },
    { name: 'indianred', value: '#cd5c5c' },
    { name: 'indigo', value: '#4b0082' },
    { name: 'ivory', value: '#fffff0' },
    { name: 'khaki', value: '#f0e68c' },
    { name: 'lavender', value: '#e6e6fa' },
    { name: 'lavenderblush', value: '#fff0f5' },
    { name: 'lawngreen', value: '#7cfc00' },
    { name: 'lemonchiffon', value: '#fffacd' },
    { name: 'lightblue', value: '#add8e6' },
    { name: 'lightcoral', value: '#f08080' },
    { name: 'lightcyan', value: '#e0ffff' },
    { name: 'lightgoldenrodyellow', value: '#fafad2' },
    { name: 'lightgray', value: '#d3d3d3' },
    { name: 'lightgrey', value: '#d3d3d3' },
    { name: 'lightgreen', value: '#90ee90' },
    { name: 'lightpink', value: '#ffb6c1' },
    { name: 'lightsalmon', value: '#ffa07a' },
    { name: 'lightseagreen', value: '#20b2aa' },
    { name: 'lightskyblue', value: '#87cefa' },
    { name: 'lightslategray', value: '#778899' },
    { name: 'lightslategrey', value: '#778899' },
    { name: 'lightsteelblue', value: '#b0c4de' },
    { name: 'lightyellow', value: '#ffffe0' },
    { name: 'lime', value: '#00ff00' },
    { name: 'limegreen', value: '#32cd32' },
    { name: 'linen', value: '#faf0e6' },
    { name: 'magenta', value: '#ff00ff' },
    { name: 'maroon', value: '#800000' },
    { name: 'mediumaquamarine', value: '#66cdaa' },
    { name: 'mediumblue', value: '#0000cd' },
    { name: 'mediumorchid', value: '#ba55d3' },
    { name: 'mediumpurple', value: '#9370d8' },
    { name: 'mediumseagreen', value: '#3cb371' },
    { name: 'mediumslateblue', value: '#7b68ee' },
    { name: 'mediumspringgreen', value: '#00fa9a' },
    { name: 'mediumturquoise', value: '#48d1cc' },
    { name: 'mediumvioletred', value: '#c71585' },
    { name: 'midnightblue', value: '#191970' },
    { name: 'mintcream', value: '#f5fffa' },
    { name: 'mistyrose', value: '#ffe4e1' },
    { name: 'moccasin', value: '#ffe4b5' },
    { name: 'navajowhite', value: '#ffdead' },
    { name: 'navy', value: '#000080' },
    { name: 'oldlace', value: '#fdf5e6' },
    { name: 'olive', value: '#808000' },
    { name: 'olivedrab', value: '#6b8e23' },
    { name: 'orange', value: '#ffa500' },
    { name: 'orangered', value: '#ff4500' },
    { name: 'orchid', value: '#da70d6' },
    { name: 'palegoldenrod', value: '#eee8aa' },
    { name: 'palegreen', value: '#98fb98' },
    { name: 'paleturquoise', value: '#afeeee' },
    { name: 'palevioletred', value: '#d87093' },
    { name: 'papayawhip', value: '#ffefd5' },
    { name: 'peachpuff', value: '#ffdab9' },
    { name: 'peru', value: '#cd853f' },
    { name: 'pink', value: '#ffc0cb' },
    { name: 'plum', value: '#dda0dd' },
    { name: 'powderblue', value: '#b0e0e6' },
    { name: 'purple', value: '#800080' },
    { name: 'red', value: '#ff0000' },
    { name: 'rosybrown', value: '#bc8f8f' },
    { name: 'royalblue', value: '#4169e1' },
    { name: 'saddlebrown', value: '#8b4513' },
    { name: 'salmon', value: '#fa8072' },
    { name: 'sandybrown', value: '#f4a460' },
    { name: 'seagreen', value: '#2e8b57' },
    { name: 'seashell', value: '#fff5ee' },
    { name: 'sienna', value: '#a0522d' },
    { name: 'silver', value: '#c0c0c0' },
    { name: 'skyblue', value: '#87ceeb' },
    { name: 'slateblue', value: '#6a5acd' },
    { name: 'slategray', value: '#708090' },
    { name: 'slategrey', value: '#708090' },
    { name: 'snow', value: '#fffafa' },
    { name: 'springgreen', value: '#00ff7f' },
    { name: 'steelblue', value: '#4682b4' },
    { name: 'tan', value: '#d2b48c' },
    { name: 'teal', value: '#008080' },
    { name: 'thistle', value: '#d8bfd8' },
    { name: 'tomato', value: '#ff6347' },
    { name: 'turquoise', value: '#40e0d0' },
    { name: 'violet', value: '#ee82ee' },
    { name: 'wheat', value: '#f5deb3' },
    { name: 'white', value: '#ffffff' },
    { name: 'whitesmoke', value: '#f5f5f5' },
    { name: 'yellow', value: '#ffff00' },
    { name: 'yellowgreen', value: '#9acd32' }];

    var c = color.toLowerCase();
    var cols = colors.filter(function (col) {
      return col.name === c;
    });
    if (cols.length > 0)
      color = cols[0].value;

    return color;
  }
}
