<div class="wrapper">
  <div class="row">
    <div class="square up left">
      <ng-container
        [ngTemplateOutlet]="mapTools"
        [ngTemplateOutletContext]="{
          map: map1Name,
          legend: legend1,
          fieldToAnalyzeModel: fieldToAnalyze,
          interpolationProperties: interpolationProperties,
          setInitValues: true
        }"
      ></ng-container>
    </div>
    <div class="square up">
      <ng-container
        [ngTemplateOutlet]="mapTools"
        [ngTemplateOutletContext]="{
          map: map2Name,
          legend: legend2,
          fieldToAnalyzeModel: fieldToAnalyze,
          interpolationProperties: interpolationProperties,
          setInitValues: false
        }"
      ></ng-container>
    </div>
    <div
      class="square left"
      [ngClass]="{ hidden: splitMode !== splitModeEnum.Four }"
    >
      <ng-container
        [ngTemplateOutlet]="mapTools"
        [ngTemplateOutletContext]="{
          map: map3Name,
          legend: legend3,
          fieldToAnalyzeModel: fieldToAnalyze,
          interpolationProperties: interpolationProperties,
          setInitValues: false
        }"
      ></ng-container>
    </div>
    <div
      class="square"
      [ngClass]="{ hidden: splitMode !== splitModeEnum.Four }"
    >
      <ng-container
        [ngTemplateOutlet]="mapTools"
        [ngTemplateOutletContext]="{
          map: map4Name,
          legend: legend4,
          fieldToAnalyzeModel: fieldToAnalyze,
          interpolationProperties: interpolationProperties,
          setInitValues: false
        }"
      ></ng-container>
    </div>
  </div>
</div>

<ng-template
  #mapTools
  let-map="map"
  let-legend="legend"
  let-fieldToAnalyzeModel="fieldToAnalyzeModel"
  let-setInitValues="setInitValues"
  let-interpolationProperties="interpolationProperties"
>
  <div class="tools">
    <dv-map-split-prop-picker
      [setInitValues]="setInitValues"
      [fieldToAnalyzeModel]="fieldToAnalyzeModel"
      [interpolationProperties]="interpolationProperties"
      [feature]="fieldToAnalyze.feature"
      (interpolationChanged)="interpolationChanged($event, map)"
      (pointLabelChange)="onPointLabelChange($event, map)"
    ></dv-map-split-prop-picker>
  </div>
  <dv-map [mapName]="map" [fullScreen]="false"></dv-map>
  <dv-legend
    class="legend"
    [legend]="legend"
    *ngIf="!legend?.isCropsat"
    [resizable]="true"
    [instance]="map"
  ></dv-legend>
  <dv-cropsat-legend
    [legendData]="legend"
    [isCompact]="true"
    *ngIf="legend?.isCropsat"
  ></dv-cropsat-legend>
</ng-template>
<div class="map-split-prop">
  <dv-map-split-mode></dv-map-split-mode>
  <dv-map-split-marker-mode
    (mode)="onPlaceMarkerMode($event)"
  ></dv-map-split-marker-mode>
</div>
