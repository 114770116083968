<div *ngIf="!hasSelectedType">
  <h3>{{ 'What type of file do you want to upload' | dvt }}?</h3>
  <div class="import-btns-wrapper">
    <div class="import-btns">
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('unknown')"
      >
        <span class="import-btn-content">
          <img class="import-btn-icon" src="assets/images/icon-veris.svg" />
          {{ 'Veris' | dvt }}
        </span>
      </button>
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('lab')"
      >
        <span class="import-btn-content">
          <img class="import-btn-icon" src="assets/images/icon-veris.svg" />
          {{ 'Veris Lab + Shape' | dvt }}
        </span>
      </button>
    </div>
  </div>
</div>
<div *ngIf="hasSelectedType" class="file-upload">
  <dv-fileupload
    singleFileOnly="true"
    (files)="fileSelected($event)"
    [fileTypes]="fileTypesMetadata.value"
  ></dv-fileupload>
</div>
