<div class="legendwrapper" [class.compact]="isCompact">
  <div class="row cropsat-legend-area">
    <div style="width:80px; margin-right: 20px;" class="col end">
      <span class="legend-text" *ngIf="!isCompact">{{'Lower biomass' | dvt}}</span>
      <canvas #canvas id="cropsat-canvas" width="50" height="210"></canvas>
      <span class="legend-text" *ngIf="!isCompact">{{'Higher biomass' | dvt}}</span>
    </div>
    <div class="col center">
      <span class="index-text">{{'Index' | dvt}}</span>
      <div class="col box-wrapper">
        <div class="indexbox" *ngFor="let v of values">
          {{v | number: '1.2-2'}}
        </div>
      </div>
    </div>
  </div>
</div>