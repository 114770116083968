import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PrescriptionModel } from 'app/models/models';
declare var google: any;

@Component({
    selector: 'dv-prescription-legend',
    templateUrl: './prescription-legend.component.html',
    styleUrls: ['./prescription-legend.component.scss'],
})
export class PrescriptionLegendComponent implements OnInit {
    @Input() prescription: PrescriptionModel;
    @ViewChild('legend') legendEl: any;
    public emptyBucketPrescriptions: boolean = false;
    constructor() {}

    ngOnInit() {
        if (this.legendEl) this.legendEl.nativeElement.innerHTML = '';

        google.charts.load('current', { packages: ['corechart'] });
        google.charts.setOnLoadCallback((() => this.drawLegend()).bind(this));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['prescription']) {
            if (this.legendEl) this.legendEl.nativeElement.innerHTML = '';

            google.charts.load('current', { packages: ['corechart'] });
            google.charts.setOnLoadCallback(
                (() => {
                    this.drawLegend();
                    this.emptyBucketPrescriptions = this.prescription?.buckets.some((b) => b.prescription > 0) != true;
                }).bind(this)
            );
            this.emptyBucketPrescriptions = false;
        }
    }

    public drawLegend() {
        var data = new google.visualization.DataTable();
        data.addColumn('number', 'bucket');
        data.addColumn('number', 'value');

        let bucketCount = 1;
        let maxPrescription = 0;
        this.prescription.buckets.forEach((b) => {
            data.addRow([bucketCount, b.prescription]);
            maxPrescription = Math.max(maxPrescription, b.prescription);
            bucketCount++;
        });
        if (maxPrescription == 0) {
            maxPrescription = 100;
        }

        var options = {
            title: '',
            vAxis: {
                gridlines: { color: '#cccccc' },
                viewWindowMode: 'explicit',
                viewWindow: {
                    min: 0,
                    max: maxPrescription,
                },
            },
            hAxis: {
                minValue: 0,
                viewWindowMode: 'explicit',
                viewWindow: {
                    min: 1,
                    max: bucketCount - 1,
                },
                gridlines: { color: '#cccccc' },
                textPosition: 'none',
            },
            colors: ['#000000'],
            series: {
                0: {
                    pointSize: 6,
                },
            },
            legend: {
                position: 'none',
            },
            chartArea: { height: '100%', width: '100%', left: 60, top: 10, right: 20, bottom: 10 },
        };

        var container = document.getElementById('chart_div');
        if (container) {
            var chart = new google.visualization.AreaChart(container);
            //Fill chartarea with gradient specified in template
            google.visualization.events.addListener(chart, 'ready', function () {
                var observer = new MutationObserver(function () {
                    container.getElementsByTagName('svg')[0].setAttribute('xmlns', 'http://www.w3.org/2000/svg');
                    Array.prototype.forEach.call(container.getElementsByTagName('path'), function (rect) {
                        if (rect.getAttribute('fill') === '#000000') {
                            rect.setAttribute('fill', 'url(#grad)');
                            rect.setAttribute('fill-opacity', '0.8');
                        }
                    });
                });
                observer.observe(container, {
                    childList: true,
                    subtree: true,
                });
            });

            chart.draw(data, options);
        }
    }
}
