import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MapComponent } from 'app/components/map/map.component';
import {
  LAYER_NAME_SKIFTEN,
  LAYER_NAME_SPREADBOUNDARY,
  MapService,
} from 'app/components/map/map.service';
import { GeoDataFileMetaTags } from 'app/models/geodata.model';
import {
  GeoDataFileModel,
  GeoDataMetaData,
  RawFileSetModel,
  GridFieldModel,
} from 'app/models/models';
import { ClientService } from 'app/services/client.service';
import { DataFileService } from 'app/services/data-file.service';
import { BehaviorSubject, Subject, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MapStateService } from 'app/services/map-state.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';
import { APP_PATH } from 'app/app.types';

@Component({
  selector: 'dv-geodata-file-details',
  templateUrl: './geodata-file-details.component.html',
  styleUrls: ['./geodata-file-details.component.scss'],
})
export class GeodataFileDetailsComponent
  implements OnDestroy, AfterViewInit, OnChanges
{
  _unsub$: Subject<void> = new Subject<void>();
  map$: Subscription;
  dvMap: MapComponent;
  year$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  years: number[] = [];
  selectedYear: number = null;
  reservedData: GeoDataMetaData;
  customData: GeoDataMetaData;
  filesetInfo: RawFileSetModel;
  private overlayEl: Element;
  spreadBoundary$: BehaviorSubject<GeoJSON.FeatureCollection> =
    new BehaviorSubject<GeoJSON.FeatureCollection>(null);

  allFields: GridFieldModel[] = [];
  displayFields: GridFieldModel[] = [];
  private fieldYear: number = null;
  fieldsLoading = false;

  @Input() geodata: GeoDataFileModel;
  @ViewChild('selectButton', { read: ElementRef })
  selectButtonComponent: ElementRef;

  constructor(
    private fileService: DataFileService,
    private mapService: MapService,
    private translateService: DvToolbarTranslateService,
    private clientService: ClientService,
    private snackbar: MatSnackBar,
    private router: Router,
    private mapStateService: MapStateService
  ) {
    this.year$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .pipe(distinctUntilChanged())
      .subscribe((year) => {
        this.selectedYear = year;
        this.filterDisplayFields(year);
      });
  }

  ngAfterViewInit(): void {
    this.loadOverlappingFields();
    this.drawSpreadBoundary();
    this.getFilesetDataInfo();

    const el = (
      this.selectButtonComponent.nativeElement as Element
    ).querySelector('.mat-button-focus-overlay');
    if (el) {
      this.overlayEl = el;
    }

    this.map$ = this.mapService
      .mainMap()
      .pipe(takeUntil(this._unsub$))
      .subscribe((dvMap) => {
        this.dvMap = dvMap;
        if (dvMap.map.getZoom() <= 13) {
          dvMap.map.setZoom(14);
        }
        dvMap.multiSelect = false;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reservedData = [];
    this.customData = [];
    changes.geodata.currentValue.metadata.forEach((d) => {
      switch (d.key) {
        case GeoDataFileMetaTags.customer:
          this.reservedData.push({
            key: this.translateService.t(
              '__metadata_' + GeoDataFileMetaTags.customer,
              '_Customer'
            ),
            value: d.value,
          });
          break;
        case GeoDataFileMetaTags.farm:
          this.reservedData.push({
            key: this.translateService.t(
              '__metadata_' + GeoDataFileMetaTags.farm,
              '_Farm'
            ),
            value: d.value,
          });
          break;
        case GeoDataFileMetaTags.field:
          this.reservedData.push({
            key: this.translateService.t(
              '__metadata_' + GeoDataFileMetaTags.field,
              '_Field'
            ),
            value: d.value,
          });
          break;
        case GeoDataFileMetaTags.created:
          this.reservedData.push({
            key: this.translateService.t(
              '__metadata_' + GeoDataFileMetaTags.created,
              '_Created'
            ),
            value: d.value,
          });
          break;
        case GeoDataFileMetaTags.totalArea:
          this.reservedData.push({
            key: this.translateService.t(
              '__metadata_' + GeoDataFileMetaTags.totalArea,
              '_Total area'
            ),
            value: d.value + ' m2',
          });
          break;
        case GeoDataFileMetaTags.totalYield:
          this.reservedData.push({
            key: this.translateService.t(
              '__metadata_' + GeoDataFileMetaTags.totalYield,
              '_Total yield'
            ),
            value: d.value + 'kg',
          });
          break;
        default:
          this.customData.push(d);
      }
    });

    this.setDefaultFieldYear();
  }

  ngOnDestroy(): void {
    this.dvMap.clearMap();
    this._unsub$.next();
    this._unsub$.complete();
  }

  /* Overlapping field handling ================================================================== */
  private loadOverlappingFields(): void {
    this.fieldsLoading = true;
    this.clientService.getGeoDataFields(this.geodata.fileId).subscribe(
      (fields) => this.handleOverlappingFieldsResult(fields),
      (error) => this.handleOverlappingFieldsError(error)
    );
  }

  private handleOverlappingFieldsResult(fields: GridFieldModel[]): void {
    fields.forEach((field) => {
      if (this.years.findIndex((y) => y === field.year) === -1) {
        this.years.push(field.year);
      }
    });
    this.years.sort();
    this.allFields = fields;
    this.setDefaultFieldYear();
    this.filterDisplayFields(this.year$.value);
    this.fieldsLoading = false;
  }

  private handleOverlappingFieldsError(error: unknown): void {
    this.fieldsLoading = false;
    this.snackbar.open(
      this.translateService.t('Failed to fetch overlapping fields'),
      this.translateService.t('Ok'),
      {
        duration: 3000,
      }
    );
    console.error(error);
  }

  private drawFieldsArea(): void {
    /** The endpoint for getting overlapping
     * fields will return one feature collection
     * for each of the fields. We want to draw
     * all fields as one feature collection, since
     * it's a bit faster if there are many fields.
     * So this part merges all features in all
     * feature collection into one featurecollection
     */
    if (this.displayFields?.length > 0) {
      const fieldAreas: GeoJSON.FeatureCollection = {
        features: [],
        type: 'FeatureCollection',
      };
      const features = this.allFields.map(
        (fc) => fc.featureCollection.features
      );
      fieldAreas.features = [].concat(...features);

      this.mapService
        .mainMap()
        .pipe(take(1))
        .subscribe((map) => {
          map.removeLayer(LAYER_NAME_SKIFTEN);
          if (fieldAreas?.features?.length > 0) {
            map.addGeoJson(fieldAreas, LAYER_NAME_SKIFTEN);
          }
        });
    }
  }

  private setDefaultFieldYear(): void {
    const created = this.geodata?.metadata.find(
      (d) => d.key === GeoDataFileMetaTags.created
    );
    const createdYear = new Date(created.value).getFullYear();
    if (created && this.years.findIndex((y) => y === createdYear) > -1) {
      this.year$.next(createdYear);
    } else if (this.years?.length > 0) {
      this.year$.next(this.years[this.years.length - 1]);
    } else {
      this.year$.next(null);
    }
  }

  /* Spread boundary handling ==================================================================== */
  private drawSpreadBoundary(): void {
    combineLatest([
      this.clientService.getGeoDataPointSpreadBoundary(this.geodata.fileId),
      this.mapService.mainMap(),
    ]).subscribe(
      ([boundary, map]) => this.handleSpreadBoundaryResult(boundary, map),
      (error) => this.handleSpreadBoundaryError(error)
    );
  }

  private handleSpreadBoundaryResult(
    boundary: GeoJSON.FeatureCollection,
    map: MapComponent
  ): void {
    this.spreadBoundary$.next(boundary);
    map.removeLayer(LAYER_NAME_SPREADBOUNDARY);
    if (boundary?.features?.length > 0 && boundary.features[0].geometry) {
      map.fitFeature(map.addGeoJson(boundary, LAYER_NAME_SPREADBOUNDARY));
    }
  }

  private handleSpreadBoundaryError(error: unknown): void {
    this.snackbar.open(
      this.translateService.t('Failed to fetch spread boundary'),
      this.translateService.t('Ok'),
      {
        duration: 3000,
      }
    );
    console.error(error);
  }

  /* fileset data handling =========================================================== */
  private getFilesetDataInfo(): void {
    this.fileService.fileset$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .subscribe((fileset) => {
        this.filesetInfo = fileset?.find(
          (f) => f.fileId === this.geodata?.rawFileId
        );
      });
  }

  removeOverlay(): void {
    this.overlayEl.classList.remove('mat-button-focus-overlay');
  }

  addOverlay(): void {
    this.overlayEl.classList.add('mat-button-focus-overlay');
  }

  goToField(fieldId: number): void {
    const routerCommands = [
      ...this.router.url
        .split('/')
        .filter((s) => s.length > 0)
        .slice(0, 2),
      APP_PATH.MAP,
      APP_PATH.MAPPING,
      this.selectedYear,
    ];
    const field = this.displayFields.find((field) => field.id === fieldId);
    if (field) {
      this.mapStateService.setSelectedFeatures(
        <GeoJSON.FeatureCollection>field.featureCollection
      );
    }
    this.router.navigate(routerCommands);
  }

  toggleYear(year: MatSelectChange): void {
    if (year.value) {
      this.year$.next(year.value);
    }
  }

  notImplemented(): void {
    this.fileService.notImplemented();
  }

  openGeodataMetadata(id: number): void {
    this.fileService.openGeodataMetadata(id);
  }

  private filterDisplayFields(year: number): void {
    if (year === this.fieldYear) {
      return;
    }
    this.displayFields = this.allFields.filter((s) => s.year === year);
    this.drawFieldsArea();
  }
}
