import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from 'app/components/dialog/dialog.service';
import { MapService } from 'app/components/map/map.service';
import { ClientService } from 'app/services/client.service';
import { forkJoin } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';
import { PoiService } from '../poi.service';
import { POI_STATES } from '../poi.types';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-poi-detail',
  templateUrl: './poi-detail.component.html',
  styleUrls: ['./poi-detail.component.scss'],
})
export class PoiDetailComponent implements OnInit {
  @Input() feature: google.maps.Data.Feature;
  @Output() delete = new EventEmitter();

  utford = false;
  img: string = null;
  kategori = '';
  anteckning = '';
  id = 0;
  klientId = 0;

  constructor(
    private mapService: MapService,
    private clientService: ClientService,
    private dialogService: DialogService,
    private translate: DvToolbarTranslateService,
    private poiSrv: PoiService
  ) {}

  ngOnInit(): void {
    if (this.feature.getProperty('imgeRef')) {
      this.img =
        'https://dvwebphoto.blob.core.windows.net/' +
        this.feature.getProperty('klientId') +
        '/' +
        this.feature.getProperty('imgeRef') +
        '/large.jpg';
    }

    this.id = this.feature.getProperty('id');
    this.klientId = this.feature.getProperty('klientId');
    this.utford = this.feature.getProperty('utford') ? true : false;

    this.kategori = this.feature.getProperty('kategori');
    this.anteckning = this.feature.getProperty('anteckning');
  }

  openUrl(): void {
    window.open(this.img.replace('/large', ''), '_blank');
  }

  deleteDetail(): void {
    this.dialogService
      .confirm(this.translate.t('Remove mark?'))
      .afterClosed()
      .pipe(
        filter((res: boolean) => res),
        flatMap(() =>
          forkJoin(
            this.clientService.removePoi(this.id),
            this.mapService.mainMap()
          )
        )
      )
      .subscribe(() => {
        this.delete.emit(this.feature);
      });
  }

  toggleDone(): void {
    this.clientService.togglePoi(this.id, !this.utford).subscribe((res) => {
      this.feature.setProperty('utford', res);
      this.utford = this.feature.getProperty('utford') ? true : false;
    });
  }

  edit(): void {
    this.feature.toGeoJson(() => {
      this.poiSrv.setPoiFeature(this.feature, POI_STATES.EDIT);
    });
  }
}
