<mat-expansion-panel
  (opened)="getInterpolation()"
  (closed)="expanded = false"
  class="expansion crops"
>
  <mat-expansion-panel-header class="expansion">
    <div class="header-wrapper">
      <div class="header-label">
        <span class="color" [ngStyle]="{ 'background-color': color }">
          &nbsp;
        </span>
        <p>{{ crop }}</p>
      </div>
      <span class="count"> ({{ boundary.length }}) </span>
    </div>
  </mat-expansion-panel-header>
  <dv-legend
    [legend]="legend"
    [loading]="!interpolation && !error"
    [pointMode]="pointMode"
  ></dv-legend>
  <div *ngIf="error" class="errmsg">
    <mat-icon color="warn">error</mat-icon>
    <span class="dv-text-color status-text">{{ error }}</span>
  </div>
  <mat-expansion-panel class="crop-fields">
    <mat-expansion-panel-header>
      <p>{{ 'Fields' | dvt }} ({{ boundary.length }})</p>
    </mat-expansion-panel-header>
    <ul>
      <li *ngFor="let feature of boundary">
        <mat-checkbox
          [checked]="isFieldActive(feature.id)"
          (change)="toggleActiveField(feature.id)"
        ></mat-checkbox>
        <span class="name">{{ feature.properties.namn }}</span>
        <span class="crop">
          <span class="color" [ngStyle]="{ 'background-color': color }">
            &nbsp;
          </span>
          {{ feature.properties.groda }}
        </span>
        <span class="areal">{{ feature.properties.areal.toFixed(2) }} ha</span>
      </li>
    </ul>
  </mat-expansion-panel>
</mat-expansion-panel>
