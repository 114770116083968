import { Component, OnDestroy, OnInit } from '@angular/core';
import { FileStatus } from 'app/models/filestatus.model';
import { MessagesService } from 'app/services/messages.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImportService } from '../import.service';

@Component({
  selector: 'dv-import-progress',
  templateUrl: './import-progress.component.html',
  styleUrls: ['./import-progress.component.scss'],
})
export class ImportProgressComponent implements OnInit, OnDestroy {
  file: string = null;
  progress: string = null;
  processing = false;
  private _unsub$ = new Subject<void>();
  private SUCCESS_MESSAGE_DELAY_MS = 5000;

  constructor(
    private messageService: MessagesService,
    private importSrv: ImportService
  ) {}

  ngOnInit(): void {
    this.importSrv
      .getFileStatus()
      .pipe(takeUntil(this._unsub$))
      .subscribe((file) => {
        this.file = file;
      });

    this.messageService
      .onFileSetImportStatus()
      .pipe(takeUntil(this._unsub$))
      .subscribe((progress) => {
        if (
          progress?.status === FileStatus.Succeeded ||
          progress?.status === FileStatus.Error ||
          progress?.status === FileStatus.Failed
        ) {
          this.progress = progress.message;

          // Show terminating message for 5 seconds before hiding the component
          setTimeout(() => {
            this.progress = '';
            this.file = null;
            this.importSrv.setFileStatus(null);
          }, this.SUCCESS_MESSAGE_DELAY_MS);
        } else {
          this.progress = progress.message;
        }
      });
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }
}
