<div id="legend-cropsat">
    <div class="head">
        <button mat-button class="back-button" (click)="close()">
            <mat-icon class="material-icons">arrow_back</mat-icon>
        </button>
        <h3>{{ 'CropSAT' | dvt }}</h3>
    </div>
    <div class="row">
        <span style="font-weight: 700">{{ 'Pick date' | dvt }}:</span>
        <mat-form-field appearance="outline">
            <mat-select [disabled]="loading" [(ngModel)]="selectedDate" (selectionChange)="loadMap()">
                <mat-option *ngFor="let date of dates" [value]="date.date">
                    {{ date.date }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="loading-spinner" *ngIf="loading">
        <mat-spinner [diameter]="75"></mat-spinner>
        <span class="dv-text-color status-text">{{ 'Loading information...' | dvt }}</span>
    </div>
    <div *ngIf="error !== null" class="errmsg">
        <mat-icon color="warn">error</mat-icon>
        <span class="dv-text-color status-text">{{ error }}</span>
    </div>
    <dv-cropsat-legend class="cropsat-legend" [ngClass]="{ hidden: loading || error }" #legend></dv-cropsat-legend>
    <div class="bottom-info">
        <div class="row center info">
            <img src="assets/images/brands_cropsat.svg" />
            <span style="font-size: 12px; width: 270px"
                >{{ '_cropsat_info' | dvt: '_CropSAT is a free service that creates prescriptionfiles from biomass' }}
            </span>
        </div>
        <div style="margin-top: 5px" class="row center">
            <button (click)="openCropsat()" mat-stroked-button color="primary">
                <img src="assets/images/launch.svg" />{{ 'Open CropSAT' | dvt }}
            </button>
        </div>
    </div>
</div>
