<div mat-dialog-container>
  <div mat-dialog-content>
    <div class="header-txt">
      <mat-icon *ngIf="!data.endpoint">error</mat-icon>
      {{ data.header }}
    </div>
    <div class="text-wrap">
      <div class="close-txt">{{ data.main }}</div>
      <div class="close-txt">{{ data.prompt }}</div>
    </div>
    <button mat-raised-button *ngIf="data.endpoint" (click)="ok()">
      {{ 'OK, I understand' | dvt }}
    </button>
    <button
      mat-dialog-close
      mat-mini-fab
      color="primary"
      *ngIf="!data.endpoint"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
