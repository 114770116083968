<button class="prescription-button" mat-raised-button [disabled]="disabled">
  <div class="prescription-button-content">
    <span class="prescription-button-icon-wrapper">
      <img [src]="imgSrc" />
    </span>
    <span class="prescription-button-text">
      {{ text }}
    </span>
  </div>
</button>
