import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GeoDataMetaData } from 'app/models/models';
import * as xmlName from 'xml-name-validator';
import { GeoDataFileMetaTags } from 'app/models/geodata.model';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

interface MetaDataInput {
  key: string;
  value: string;
  errorMessage?: string;
}

@Component({
  selector: 'dv-metadata-prompt',
  templateUrl: 'metadata-prompt.component.html',
  styleUrls: ['metadata-prompt.component.scss'],
})
export class MetadataPromptComponent implements OnInit {
  owner = '';
  farm = '';
  field = '';
  properties: MetaDataInput[];
  disableSaveButton = false;

  @Input() addMetadata = false;
  @Input() metadata: GeoDataMetaData;
  @Output() save = new EventEmitter<GeoDataMetaData>();
  @ViewChild('scrollTarget') private scrollTarget: ElementRef;

  constructor(private translateService: DvToolbarTranslateService) {}

  ngOnInit(): void {
    if (this.metadata && this.metadata.length) {
      this.properties = [];
      this.metadata?.forEach((d) => {
        if (d.key === GeoDataFileMetaTags.customer) {
          this.owner = d.value;
        } else if (d.key === GeoDataFileMetaTags.farm) {
          this.farm = d.value;
        } else if (d.key === GeoDataFileMetaTags.field) {
          this.field = d.value;
        } else {
          this.properties.push(d);
        }
      });
    } else {
      this.properties = [
        {
          key: '',
          value: '',
        },
      ];
    }
  }

  onKeyup(property: MetaDataInput): void {
    const keyParts = this.getKeyParts(property.key);
    const formattedKey = keyParts.join(' ');

    const formatIsInvalid = this.formatIsInvalid(keyParts);
    const idNotUnique = !this.keyIsUnique(formattedKey);
    const isReservedKey = this.isReservedKey(formattedKey);

    if (formatIsInvalid) {
      property.errorMessage = this.translateService.t('Invalid format');
    } else if (idNotUnique) {
      property.errorMessage = this.translateService.t('Name not unique');
    } else if (isReservedKey) {
      property.errorMessage = this.translateService.t('Reserved name');
    } else {
      property.errorMessage = '';
    }

    this.setDisableSaveButton();
  }

  getKeyParts(key: string): string[] {
    return key
      .split(' ')
      .map((part) => part.trim())
      .filter((part) => part !== '');
  }

  formatIsInvalid(keyParts: string[]): boolean {
    return keyParts.some((part) => !xmlName.name(part));
  }

  keyIsUnique(key: string): boolean {
    if (key === '') {
      return true;
    }

    const occurrences = this.properties.reduce(
      (previous, current) =>
        this.getKeyParts(current.key).join(' ') === key
          ? previous + 1
          : previous,
      0
    );

    return occurrences === 1;
  }

  isReservedKey(key: string): boolean {
    return (
      key === GeoDataFileMetaTags.customer ||
      key === GeoDataFileMetaTags.farm ||
      key === GeoDataFileMetaTags.field
    );
  }

  setDisableSaveButton(): void {
    const okInputsLength = this.properties.filter(
      (prop) => !prop.errorMessage
    ).length;
    this.disableSaveButton = okInputsLength !== this.properties.length;
  }

  showAddMetadata(): void {
    this.addMetadata = true;
  }

  skip(): void {
    this.save.emit();
  }

  addProperty(): void {
    this.properties.push({
      key: '',
      value: '',
    });
    setTimeout(() => {
      this.scrollTarget.nativeElement.scroll({
        top: this.scrollTarget.nativeElement.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    });
  }

  removeProperty(index: number): void {
    this.properties.splice(index, 1);
    this.properties.forEach((prop) => this.onKeyup(prop));
  }

  saveMetadata(): void {
    const reserved = [
      {
        key: GeoDataFileMetaTags.customer,
        value: this.owner,
      },
      {
        key: GeoDataFileMetaTags.farm,
        value: this.farm,
      },
      {
        key: GeoDataFileMetaTags.field,
        value: this.field,
      },
    ];
    const props = this.properties
      .concat(reserved)
      .filter((prop) => prop.key !== '' && prop.value !== '')
      .map((prop) => ({
        key: this.getKeyParts(prop.key).join(' '),
        value: prop.value,
      }));

    this.save.emit(props);
  }
}
