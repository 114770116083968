<div *ngIf="!hasSelectedType">
  <h3>{{ 'What type of file do you want to upload' | dvt }}?</h3>
  <div class="import-btns-wrapper">
    <div class="import-btns">
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('trimble')"
      >
        <span class="import-btn-content">
          <img class="import-btn-icon" src="assets/images/logo-trimble.svg" />
          Trimble
        </span>
      </button>
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('johndeere')"
      >
        <span class="import-btn-content">
          <img class="import-btn-icon" src="assets/images/logo-johndeere.svg" />
          John Deere
        </span>
      </button>
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('agco')"
      >
        <span class="import-btn-content">
          <img class="import-btn-icon" src="assets/images/logo-agco.svg" />
          AGCO
        </span>
      </button>
      <button
        *ngIf="showBetaFunction$ | async"
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('fendt')"
      >
        <span class="import-btn-content">
          <img class="import-btn-icon" src="assets/images/icon-error.svg" />
          Fendt
        </span>
      </button>
    </div>
    <div class="import-btns">
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('cnh')"
      >
        <span class="import-btn-content">
          <img class="import-btn-icon" src="assets/images/logo-cnh.svg" />
          CNH
        </span>
      </button>
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('claas')"
        [color]="
          importModel.manufacture === 'claas' ||
          importModel.manufacture === 'claasAft'
            ? 'primary'
            : ''
        "
      >
        <span class="import-btn-content">
          <img class="import-btn-icon" src="assets/images/logo-claas.svg" />
          Claas
        </span>
      </button>
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('yieldmaster')"
        [color]="
          importModel.manufacture === 'yieldmaster' ||
          importModel.manufacture === 'yieldmasterpro'
            ? 'primary'
            : ''
        "
      >
        <span class="import-btn-content">
          <img
            class="import-btn-icon"
            src="assets/images/logo-yieldmaster.svg"
          />
          Yield Master
        </span>
      </button>
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('krone')"
      >
        <span class="import-btn-content">
          <img class="import-btn-icon" src="assets/images/logo-krone.png" />
          Krone
        </span>
      </button>
    </div>
    <div class="import-btns">
      <button
        *ngIf="showBetaFunction$ | async"
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('rds')"
      >
        <span class="import-btn-content">
          <img class="import-btn-icon" src="assets/images/icon-error.svg" />
          Rds
        </span>
      </button>
    </div>
  </div>

  <div
    class="import-type-wrapper"
    *ngIf="
      this.importModel.manufacture === 'claas' ||
      this.importModel.manufacture === 'claasAft'
    "
  >
    <h3>{{ 'What format are your files?' | dvt }}</h3>
    <div class="import-btns">
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('claas', true)"
      >
        <span class="import-btn-content">
          <span>IsoXML</span>
        </span>
      </button>
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('claasAft')"
      >
        <span class="import-btn-content">
          <span>AFT</span>
        </span>
      </button>
    </div>
  </div>

  <div
    class="import-type-wrapper"
    *ngIf="
      this.importModel.manufacture === 'yieldmaster' ||
      this.importModel.manufacture === 'yieldmasterpro'
    "
  >
    <h3>{{ 'What type of format are your files?' | dvt }}</h3>
    <div class="import-btns">
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('yieldmaster', true)"
      >
        <span class="import-btn-content">
          <span>Iso</span>
        </span>
      </button>
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('yieldmasterpro')"
      >
        <span class="import-btn-content">
          <span>Pro</span>
        </span>
      </button>
    </div>
  </div>
</div>
<div *ngIf="hasSelectedType" class="file-upload">
  <dv-fileupload
    singleFileOnly="true"
    (files)="fileSelected($event)"
    [fileTypes]="fileTypesMetadata.value"
  ></dv-fileupload>
</div>
