<dv-geo-data-header
  title="{{ 'Select and customize data layer' | dvt }}"
  [min]="data.definition.minLimit"
  [max]="data.definition.maxLimit"
  [showFixedValueToggle]="data?.definition?.allowConstant"
  (useFixedValueChange)="onUseFixedValueChange($event)"
  (fixedValueChange)="onFixedValueChange($event)"
></dv-geo-data-header>
<div class="divider"></div>
<dv-nested-preview-list
  [nestedData]="data.definition?.nestedParameter"
  [disabled]="useFixedValueChange"
  (fileChange)="fileChange.emit($event)"
  (itemChange)="itemChange.emit($event)"
></dv-nested-preview-list>
