<dvm-text h4>{{ title }}</dvm-text>
<dvm-toggle
  *ngIf="showFixedValueToggle"
  label="{{ 'Enter a fixed value' | dvt }}"
  [(ngModel)]="expanded"
  (change)="onToggleChange($event.checked)"
></dvm-toggle>
<div
  class="fixed-value-wrapper"
  [ngClass]="{ 'fixed-value-wrapper-expanded': expanded }"
>
  <div class="fixed-value-wrapper-inner">
    <dvm-text
      h4
      [dvmTooltip]="
        '_fixed_value_tooltip'
          | dvt
            : '_If you do not want to take historical crop maps into account, uncheck all files. Then the expected harvest is calculated with a fixed value for the entire shift.'
      "
    >
      {{ 'Fixed value' | dvt }}
    </dvm-text>
    <dvm-input
      class="fixed-value-input"
      type="number"
      [min]="min"
      [max]="max"
      placeholder="0"
      [(ngModel)]="fixedValue"
      (input)="onInputChange($event?.target?.valueAsNumber)"
    ></dvm-input>
  </div>
</div>
