import { Injectable } from '@angular/core';
import { FILE_TYPE } from 'app/models/file.model';
import { InterpolationCollection } from 'app/models/interpolation.model';
import { FieldGridModel, PropertyInfoModel } from 'app/models/models';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ClientService } from './client.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Injectable({
  providedIn: 'root',
})
export class FieldInterpolationService {
  /** keeps all available geodata */
  private geodata$: BehaviorSubject<FieldGridModel[]> = new BehaviorSubject<
    FieldGridModel[]
  >([]);
  /** keeps track on selected type of data */
  private selectedInterpolationType$ = new BehaviorSubject<string>(null);
  /** keeps track on selected property to interpolate on */
  private selectedInterpolationProperty$ =
    new BehaviorSubject<PropertyInfoModel>(null);
  /** keeps track on selected geodata to interpolate over */
  private selectedGeoData$ = new BehaviorSubject<number[]>([]);
  /** lookup for display settings for all available interpolation properties */
  private displayProperties: PropertyInfoModel[] = [];
  /** keeps mode of interpolation */
  private interpolationMode$ = new BehaviorSubject<FieldInterpolationMode>(
    FieldInterpolationMode.Contour
  );
  private showDataLabels$ = new BehaviorSubject<LabelInterpolationMode>(
    LabelInterpolationMode.Contour
  );
  private disabledFields = new BehaviorSubject<number[]>([]);
  disabledFields$ = this.disabledFields.asObservable();
  private cachedData: InterpolationCache = {
    contour: null,
    points: null,
    labels: null,
  };

  constructor(
    private clientSrv: ClientService,
    private translateSrv: DvToolbarTranslateService
  ) {
    this.clientSrv
      .getInterpolationProperties()
      .subscribe((properties) => (this.displayProperties = properties));
  }

  settingsUpdated(): void {
    this.clearCache();
  }

  /* GEODATA SECION ===================================================================================== */
  resetInterpolatableGeodata(): void {
    this.clearCache();
    this.geodata$.next([]);
  }

  getInterpolatableGeodata(): Observable<FieldGridModel[]> {
    return combineLatest([this.geodata$, this.selectedInterpolationType$]).pipe(
      map(([data, fileType]) => {
        return data.filter(
          (geodata) => geodata.fileType === fileType || fileType === null
        );
      })
    );
  }

  getGeodata(): Observable<FieldGridModel[]> {
    return this.geodata$.asObservable();
  }

  getSelectableGeodata(): Observable<SelectableFieldGridModel[]> {
    return combineLatest([this.geodata$, this.selectedGeoData$]).pipe(
      map(([data, ids]) => {
        return data
          .filter(
            (file, index, arr) =>
              arr.findIndex((item) => file.geoDataId === item.geoDataId) ===
              index
          )
          .map((geodata) => {
            const selectable = <SelectableFieldGridModel>geodata;
            selectable.selected =
              ids.findIndex((idx) => idx === geodata.geoDataId) >= 0;
            return selectable;
          });
      })
    );
  }

  geodataCount(): number {
    if (!this.geodata$.value) {
      return 0;
    }
    return this.geodata$.value
      .map((data) => data.geoDataId)
      .filter((value, index, arr) => arr.indexOf(value) === index).length;
  }

  getGeodataYears(): number[] {
    if (!this.geodata$.value) {
      return [];
    }
    return this.geodata$.value
      .map((data) => new Date(data.createdDate).getFullYear())
      .filter((value, index, arr) => arr.indexOf(value) === index);
  }

  setInterpolatableGeodata(geodata: FieldGridModel[]): void {
    this.clearCache();
    this.geodata$.next(
      geodata.sort((a, b) => {
        const aDate = new Date(a.createdDate).valueOf();
        const bDate = new Date(b.createdDate).valueOf();
        return <number>bDate - <number>aDate;
      })
    );
    this.resetPointLabels();
    this.resetInterpolationMode();
  }

  setSelectedGeodata(geodataIds: number[]): void {
    this.selectedGeoData$.next(geodataIds);
  }

  setSelectedGeodataByYear(year: number): void {
    const geodata = this.geodata$.value;
    if (geodata?.length) {
      const selectedData = geodata
        .filter((data) => new Date(data.createdDate).getFullYear() === year)
        .map((data) => data.geoDataId)
        .filter((id, index, arr) => arr.indexOf(id) === index);
      if (selectedData.length > 1) {
        this.setInterpolationMode(FieldInterpolationMode.Contour);
        this.showPointLabels(LabelInterpolationMode.Contour);
      }
      this.setSelectedGeodata(selectedData);
    } else {
      this.resetSelectedGeodata();
    }
  }

  getSelectedGeodata(): Observable<number[]> {
    return this.selectedGeoData$.asObservable();
  }

  toggleSelectedGeodata(geodataId: number): void {
    const selected = [...this.selectedGeoData$.value];
    const idx = selected.findIndex((id) => id === geodataId);
    if (idx > -1) {
      selected.splice(idx, 1);
      this.setSelectedGeodata(selected);
    } else {
      selected.push(geodataId);
      this.setSelectedGeodata(selected);
    }
  }

  resetSelectedGeodata(): void {
    this.selectedGeoData$.next([]);
  }

  selectedGeodataCount(): number {
    return this.selectedGeoData$.value?.length;
  }

  setGeodataFromYear(): void {
    const geodata = this.geodata$.value;
    if (geodata?.length) {
      const latestYear = Math.max(
        ...geodata.map((data) => new Date(data.createdDate).getFullYear())
      );

      const selectedData = geodata
        .filter(
          (data) => new Date(data.createdDate).getFullYear() === latestYear
        )
        .map((data) => data.geoDataId)
        .filter((id, index, arr) => arr.indexOf(id) === index);
      this.setSelectedGeodata(selectedData);
    } else {
      this.resetSelectedGeodata();
    }
  }

  selectMostRecentLayer(): void {
    const geodata = this.geodata$.value;
    if (geodata?.length > 0) {
      this.setSelectedGeodata([geodata[0].geoDataId]);
    } else {
      this.resetSelectedGeodata();
    }
  }

  getSelectableGeodataLimit(): number {
    switch (this.selectedInterpolationType$.value) {
      case FILE_TYPE.Yield:
      case FILE_TYPE.Veris:
      case FILE_TYPE.SoilSampling:
        return -1;
      case FILE_TYPE.NSensor:
      case FILE_TYPE.Prescription:
        return 1;
      default:
        return -1;
    }
  }

  /* INTERPOLATION FILE TYPE SECTION ============================================================ */
  resetInterpolationType(): void {
    this.selectedInterpolationType$.next(null);
    this.resetInterpolationProperty();
  }

  setInterpolationType(interpolationType: string): void {
    this.selectedInterpolationType$.next(interpolationType);
  }

  getInterpolationType(): Observable<string> {
    return this.selectedInterpolationType$.pipe(distinctUntilChanged());
  }

  /* INTERPOLATION PROPERTIES SECTION =================================================================== */
  getInterpolationProperties(): Observable<PropertyInfoModel[]> {
    return combineLatest([
      this.geodata$,
      this.selectedInterpolationType$,
      this.selectedGeoData$,
    ]).pipe(
      map(([data, fileType, selectedGeodata]) => {
        const input: FieldGridModel[] = data.filter((d) =>
          selectedGeodata.includes(d.geoDataId)
        );

        const properties = this.displayProperties.filter((property) => {
          return (
            input.find(
              (element) =>
                element.property === property.field &&
                element.fileType === fileType &&
                element.fileType === property.type
            ) !== undefined
          );
        });
        return properties;
      })
    );
  }

  resetInterpolationProperty(): void {
    this.clearCache();
    this.selectedInterpolationProperty$.next(null);
  }

  setInterpolationProperty(property: PropertyInfoModel): void {
    this.clearCache();
    this.selectedInterpolationProperty$.next(property);
  }

  getInterpolationProperty(): Observable<PropertyInfoModel> {
    return this.selectedInterpolationProperty$.asObservable();
  }

  /* INTERPOLATION GENERATIONS SECTION ===================================================================== */
  setInterpolationMode(mode: FieldInterpolationMode): void {
    if (this.interpolationMode$.value !== mode) {
      this.interpolationMode$.next(mode);
    }
  }

  getInterpolationMode(): Observable<FieldInterpolationMode> {
    return this.interpolationMode$.asObservable();
  }

  resetInterpolationMode(): void {
    this.interpolationMode$.next(FieldInterpolationMode.Contour);
  }

  getInterpolation(
    boundary: GeoJSON.FeatureCollection,
    fieldIds?: number[]
  ): Observable<InterpolationCollection> {
    if (fieldIds !== undefined && fieldIds !== null) {
      return this.clientSrv.getSguInterpolation(fieldIds).pipe(
        map((interpolation) => {
          this.cachedData.contour = interpolation;
          return interpolation;
        })
      );
    } else {
      try {
        this.validateBeforeInterpolation();
      } catch (error) {
        throw throwError(error);
      }

      if (this.interpolationMode$.value === FieldInterpolationMode.Contour) {
        return this.clientSrv
          .getGeoDataInterpolation(
            this.selectedInterpolationType$.value,
            this.selectedInterpolationProperty$.value.field,
            boundary,
            this.selectedGeoData$.value
          )
          .pipe(
            map((interpolation) => {
              this.cachedData.contour = interpolation;
              return interpolation;
            })
          );
      } else {
        if (this.cachedData.points) {
          return of(this.cachedData.points);
        }
        return this.clientSrv
          .getGeoDataInterpolationPoints(
            this.selectedInterpolationType$.value,
            this.selectedInterpolationProperty$.value.field,
            boundary,
            this.selectedGeoData$.value
          )
          .pipe(
            map((fc) => {
              const collection = { interpolations: fc };
              this.cachedData.points = collection;
              return collection;
            })
          );
      }
    }
  }

  private validateBeforeInterpolation(): void {
    if (
      !this.selectedGeoData$.value ||
      this.selectedGeoData$.value.length === 0
    ) {
      throw this.translateSrv.t(
        '_error__no data selected',
        '_You must choose at least one data source.'
      );
    }

    const dataCountLimit = this.getSelectableGeodataLimit();
    if (
      this.selectedGeoData$.value &&
      dataCountLimit !== -1 &&
      dataCountLimit < this.selectedGeoData$.value.length
    ) {
      throw this.translateSrv.t(
        '_error__only one layer allowed',
        '_For this data type, only one layer can be chosen at a time.'
      );
    }
  }

  getPointLabels(): Observable<GeoJSON.FeatureCollection> {
    if (this.selectedGeoData$.value?.length === 1) {
      if (!this.showDataLabels$.value) {
        return of(null);
      }
      if (this.cachedData.labels) {
        return of(this.cachedData.labels);
      }
      return this.clientSrv.getGeoData(this.selectedGeoData$.value[0]).pipe(
        map((features) => {
          for (let i = 0; i < features?.features.length; i++) {
            const f = features.features[i];
            f.properties.showValue =
              f.properties[
                Object.keys(f.properties).find(
                  (key) =>
                    key.toLowerCase() ===
                    this.selectedInterpolationProperty$.value.field.toLowerCase()
                )
              ];
          }
          this.cachedData.labels = features;
          return features;
        })
      );
    } else {
      return of(null);
    }
  }

  showPointLabels(show: LabelInterpolationMode): void {
    if (this.showDataLabels$.value !== show) {
      this.showDataLabels$.next(show);
    }
  }

  getShowPointLabels(): Observable<LabelInterpolationMode> {
    return this.showDataLabels$.asObservable();
  }

  resetPointLabels(): void {
    this.showDataLabels$.next(LabelInterpolationMode.Contour);
  }

  private clearCache(): void {
    this.cachedData = { contour: null, points: null, labels: null };
  }

  setDisabledFields(fieldIds: number[]): void {
    this.disabledFields.next(fieldIds);
  }
}

export interface SelectableFieldGridModel extends FieldGridModel {
  selected: boolean;
}

export enum FieldInterpolationMode {
  Contour,
  Points,
}

export enum LabelInterpolationMode {
  Contour,
  Labels,
}

export interface InterpolationCache {
  contour: InterpolationCollection;
  points: InterpolationCollection;
  labels: GeoJSON.FeatureCollection;
}
