export interface LegendData {
  rows: LegendDataRow[];
  totalArea: number;
}

export interface LegendDataRow {
  average: string;
  isBase: boolean;
  max: string;
  min: string;
  name: string;
  total: string;
}

export enum LEGEND_VALUE_COLORS {
  AVERAGE = '#7f7fbf',
  MAX = '#000080',
  MIN = '#ffffff',
}

export enum LEGEND_COLUMN {
  AREAL = 'areal',
  MIN = 'min',
  AVERAGE = 'average',
  MAX = 'max',
  SUM = 'sum',
}
