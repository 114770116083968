import { OptionModel } from '@dvm/components';
import { FunctionParameter, GeoFile } from 'app/models/api.models';
import { InterpolationCollection } from 'app/models/interpolation.model';
import { PrescriptionModel } from 'app/models/models';

export enum PARAMETER_TYPES {
  CONSTANT = 'Constant',
  SELECT = 'Select',
  GEO_DATA = 'Geodata',
  TARGET_PH = 'TargetPh',
  OPTION = 'Option',
  NESTED = 'Nested',
  GEOGRAPHIC = 'Geographic',
}

export enum PARAMETER_IDS {
  NUTRITION = 1,
  CROP_SELECTED = 2,
  EXPECTED_YIELD = 3,
  P_AL = 4,
  PH = 6,
  VARIED_YIELD = 7,
  K_AL = 8,
  ADJUST_PH = 9,
  HUMUS_CONTENT = 10,
  NESTED_CLAY_CONTENT = 15,
  TARGET_PH = 12,
  CROP_RESIDUES = 14,
  TOTALCLAY = 11,
  KHCL = 16,
  GEOGRAPHIC = 18,
  CLAY20 = 19,
  CLAY40 = 21,
  LIME_CUTOFF = 26,
  SEED_UNIT = 27,
}

export enum PRESCRIPTION_PROPERTY_TYPE {
  KAL = 'KAL',
  LERHALT = 'Lerhalt',
  PAL = 'PAL',
  PH = 'PH',
  YIELD = 'Yield',
  TOTALCLAY = 'TotalLerhalt',
  KHCL = 'KHCl',
}

export enum GEOGRAPHIC_TYPE {
  SGU = 'SGULerhalt',
}

export enum INDEX_TYPES {
  PH = 0,
  HUMUS = 1,
  CLAY = 2,
  TARGETPH = 3,
}

export enum SHAPE_TYPE {
  CONTOUR = 0,
  GRID = 1,
  HEXAGON = 2,
}

export enum LINK_ROUTES {
  SUPPORT = 'https://support.datavaxt.com/product_category/cropmap/',
}

export interface FunctionParameterWithType<
  T extends FunctionParameterDefinition
> extends FunctionParameter {
  definition: T;
  type?: INPUT_TYPE;
  useFixedValue?: boolean;
  fixedValue?: number;
  value?: string | number | number[] | boolean | NestedValue;
  asIndex?: boolean;
  errorMessage?: string;
  touched?: boolean;
  hasSelectedFile?: boolean;
  badInput?: boolean;
}

export type INPUT_TYPE = 'number' | 'text';

export interface NestedValue {
  [key: number]: number[] | GEOGRAPHIC_TYPE;
}

export interface FunctionNumberInputDefinition {
  maxLimit?: number | null;
  minLimit?: number | null;
  value?: number | null;
}

export interface FunctionSelectInputDefinition {
  mappedOptions?: OptionModel[];
  options: FunctionSelectInputOption[];
  selected: number;
}

export interface FunctionSelectInputOption {
  name: string;
  id: number;
  value: number;
}
export interface FunctionOptionInputDefinition {
  checked: boolean;
}

export interface FunctionGeoDataDefinition {
  allowConstant: boolean;
  files: GeoFile[];
  geoDataType: string;
  maxLimit: number | null;
  minLimit: number | null;
  multiselect: boolean;
  property: string;
  selectedFiles: number[];
}

export interface FunctionNestedDefinition {
  allowConstant: boolean;
  maxLimit: number | null;
  minLimit: number | null;
  nestedParameter: FunctionParameterWithType<FunctionGeoDataDefinition>[];
}

export interface FunctionGeographicDefinition {
  geoDataType: GEOGRAPHIC_TYPE;
}

export type FunctionParameterDefinition =
  | FunctionSelectInputDefinition
  | FunctionNumberInputDefinition
  | FunctionOptionInputDefinition
  | FunctionGeoDataDefinition
  | FunctionNestedDefinition
  | FunctionGeographicDefinition;

export interface GeoDataBarItem {
  id: number;
  name: string;
  type: PRESCRIPTION_PROPERTY_TYPE;
}

export interface PrescriptionSettings {
  gridSettings: GridSettings;
  functionInput: FunctionInput;
  adjustments: PrescriptionAdjustments;
}

export interface GridSettings {
  angle: number;
  cellSize: number;
}

export interface FunctionInput {
  functionId: number;
  parameters: FunctionInputParameters;
}

export interface FunctionInputParameters {
  [key: number]: string | number | number[] | boolean | NestedValue;
}

export interface PrescriptionAdjustments {
  maxRate: number | null;
  minRate: number | null;
  cutoff: number | boolean | null;
  availableProduct: number | null;
  averageRate: number | null;
  flatAdjustment: number | null;
  rateOverride: Record<string, number>;
  subPrescription: SubPrescription | null;
}

export interface SubPrescription {
  geodataFileId: number | null;
  content?: number | null;
}

export interface AdjustPrescriptionModel {
  prescription: PrescriptionModel;
  adjustments: PrescriptionAdjustments;
}

export interface TargetPhInput {
  shapeType?: SHAPE_TYPE;
  functionInput: FunctionInput;
}

export interface TargetPhResponse extends InterpolationCollection {
  notifications: unknown[];
}

export type GeoDataFileType = FunctionParameterWithType<
  FunctionGeoDataDefinition | FunctionNestedDefinition
>;

export enum SEED_UNIT_ID {
  KG_HA = 0,
  SEED_SQRM = 1,
}

export const SEED_UNIT_DICT = {
  [SEED_UNIT_ID.KG_HA]: 'kg',
  [SEED_UNIT_ID.SEED_SQRM]: 'seed',
};
