import { Component } from "@angular/core";
import { ImportService } from "app/components/import-view/import.service";

@Component({
    selector: 'dv-no-data',
    templateUrl: 'no-data.component.html',
    styleUrls: ['no-data.component.scss']
})
export class NoDataComponent {
    constructor(private importService: ImportService) {}

    onClick() {
        this.importService.open();
    }
}
