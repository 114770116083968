import { Component, Input } from '@angular/core';

@Component({
  selector: 'dv-map-loader',
  templateUrl: './map-loader.component.html',
  styleUrls: ['./map-loader.component.scss'],
})
export class MapLoaderComponent {
  @Input() text = 'loading';
}
