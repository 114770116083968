import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MapComponent } from 'app/components/map/map.component';
import { MapService } from 'app/components/map/map.service';
import { FILE_TYPE } from 'app/models/file.model';
import {
  FieldInterpolationMode,
  FieldInterpolationService,
  LabelInterpolationMode,
  SelectableFieldGridModel,
} from 'app/services/field-interpolation.service';
import { SiteService } from 'app/services/site.service';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dv-interpolation-settings',
  templateUrl: './interpolation-settings.component.html',
  styleUrls: ['./interpolation-settings.component.scss'],
})
export class InterpolationSettingsComponent implements OnDestroy, OnInit {
  @Input() dataQuality: number;
  @Input() loading: boolean;

  hasCropMapAdvanced = false;
  geodataYears: number[] = [];
  selectionLimit: number;
  geodata: SelectableFieldGridModel[];
  FILE_TYPE = FILE_TYPE;
  selectedType: string;
  interpolationMode: FieldInterpolationMode;
  pointMode: boolean;
  labelMode: boolean;
  pointModeAvailable: boolean;
  pointLabelAvailable: boolean;
  disabled: boolean;
  private _unsub$ = new Subject<void>();
  private activeMap: MapComponent;

  constructor(
    private interpolationSrv: FieldInterpolationService,
    private siteSrv: SiteService,
    private cd: ChangeDetectorRef,
    public mapService: MapService
  ) {
    this.siteSrv
      .hasCropMapAdvanced()
      .pipe(takeUntil(this._unsub$))
      .subscribe((hasCropmapAdvanced) => {
        this.hasCropMapAdvanced = hasCropmapAdvanced;
      });

    this.mapService
      .mainMap()
      .pipe(takeUntil(this._unsub$))
      .subscribe((map) => {
        this.activeMap = map;
      });
  }

  ngOnInit(): void {
    combineLatest([
      this.interpolationSrv.getSelectableGeodata(),
      this.interpolationSrv.getInterpolationType(),
      this.interpolationSrv.getInterpolationMode(),
      this.interpolationSrv.getShowPointLabels(),
    ])
      .pipe(takeUntil(this._unsub$))
      .subscribe(([geodata, selectedType, mode, labels]) => {
        this.geodataYears = this.interpolationSrv.getGeodataYears();
        this.selectionLimit = this.interpolationSrv.getSelectableGeodataLimit();
        this.selectedType = selectedType;
        this.interpolationMode = mode;
        this.pointMode = mode === FieldInterpolationMode.Points;
        this.labelMode = labels === LabelInterpolationMode.Labels;
        this.pointModeAvailable =
          geodata.filter((data) => data.selected)?.length === 1;
        this.pointLabelAvailable =
          geodata.filter((data) => data.selected)?.length === 1 &&
          selectedType === FILE_TYPE.SoilSampling;
        this.geodata = geodata;
        this.interpolationSrv.settingsUpdated();
        this.cd.markForCheck();
      });

    this.interpolationSrv
      .getSelectedGeodata()
      .pipe(takeUntil(this._unsub$))
      .subscribe((data) => {
        this.disabled = data.length === 1;
      });
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  selectGeodataByYear(event: MatSelectChange): void {
    this.interpolationSrv.setSelectedGeodataByYear(event.value);
  }

  getNumActiveLayers(): number {
    return this.interpolationSrv.geodataCount();
  }

  toggleSelectedGeodata(geodataId: number): void {
    if (this.geodata.length > 1) {
      this.toggleInterpolationPoints(false);
      this.togglePointLabels(false);
    }
    if (
      this.selectedType === FILE_TYPE.Prescription ||
      this.selectedType === FILE_TYPE.NSensor
    ) {
      this.interpolationSrv.setSelectedGeodata([geodataId]);
    } else {
      this.interpolationSrv.toggleSelectedGeodata(geodataId);
    }
  }

  setOpacity(value: number): void {
    this.mapService.sliderOpacity = value;
    if (this.activeMap) {
      this.mapService.sliderOpacityLayers.forEach((layerName) => {
        this.activeMap.getFeatures(layerName).forEach((f) => {
          f.setProperty('fillOpacity', value);
        });
      });
    }
  }

  toggleInterpolationPoints(showPoints: boolean): void {
    this.interpolationSrv.setInterpolationMode(
      showPoints
        ? FieldInterpolationMode.Points
        : FieldInterpolationMode.Contour
    );
  }

  togglePointLabels(showPointLabels: boolean): void {
    this.interpolationSrv.showPointLabels(
      showPointLabels
        ? LabelInterpolationMode.Labels
        : LabelInterpolationMode.Contour
    );
  }
}
