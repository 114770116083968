import { Injectable } from '@angular/core';
import { ClientService } from 'app/services/client.service';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ExportOption, EXPORT_KEY } from './prescription-export.types';
import { ClientRights, DvToolbarTranslateService } from '@dv/toolbar-msal';

@Injectable()
export class PrescriptionExportService {
  private options = new BehaviorSubject<ExportOption[]>([]);
  private exportOptions: ExportOption[] = [
    {
      key: EXPORT_KEY.CONNECTED_FARM,
      showOption: false,
      imgSrc: 'assets/images/connected-farm.png',
      text: this.translate.t(
        '__prescription-export-connectedfarm',
        '_Connected Farm'
      ),
    },
    {
      key: EXPORT_KEY.TRIMBLE,
      showOption: true,
      imgSrc: 'assets/images/logo-trimble.svg',
      text: this.translate.t('__prescription-export-trimble', '_Trimble'),
    },
    {
      key: EXPORT_KEY.JOHN_DEERE,
      showOption: true,
      imgSrc: 'assets/images/logo-johndeere.svg',
      text: this.translate.t('__prescription-export-johndeere', '_JohnDeere'),
    },
    {
      key: EXPORT_KEY.KVERNELAND,
      showOption: true,
      imgSrc: 'assets/images/logo-kverneland.svg',
      text: this.translate.t('__prescription-export-kverneland', '_Kverneland'),
    },
    {
      key: EXPORT_KEY.CNH,
      showOption: true,
      imgSrc: 'assets/images/logo-cnh.svg',
      text: this.translate.t('__prescription-export-cnh', '_CNH'),
    },
    {
      key: EXPORT_KEY.YARA,
      showOption: true,
      imgSrc: 'assets/images/logo-yara.svg',
      text: this.translate.t('__prescription-export-yara', '_Yara'),
    },
    {
      key: EXPORT_KEY.AGCO,
      showOption: true,
      imgSrc: 'assets/images/logo-agco.svg',
      text: this.translate.t('__prescription-export-agco', '_Agco'),
    },
    {
      key: EXPORT_KEY.MULLER,
      showOption: true,
      imgSrc: 'assets/images/logo-muller.svg',
      text: this.translate.t('__prescription-export-muller', '_Müller'),
    },
    {
      key: EXPORT_KEY.SHAPE,
      showOption: true,
      imgSrc: 'assets/images/icon-shape.svg',
      text: this.translate.t('__prescription-export-shape', '_Shape file'),
    },
    {
      key: EXPORT_KEY.JPG,
      showOption: true,
      imgSrc: 'assets/images/icon-jpg.svg',
      text: this.translate.t('__prescription-export-jpg', '_Image file'),
    },
    {
      key: EXPORT_KEY.TEXT,
      showOption: true,
      imgSrc: 'assets/images/icon-text.svg',
      text: this.translate.t('__prescription-export-text', '_Text file'),
    },
    {
      key: EXPORT_KEY.MACHINE_CONNECTION,
      showOption: false,
      imgSrc: 'https://cropsat.com/Content/Images/traktor_wifi.svg',
      text: this.translate.t(
        '__prescription-export-machine-connection',
        '_Machine Connection'
      ),
    },
  ];
  options$ = this.options.asObservable();

  constructor(
    private clientService: ClientService,
    private translate: DvToolbarTranslateService
  ) {
    this.initOptions();
  }

  private initOptions(): void {
    forkJoin({
      isAgrometius: this.clientService.isAgrometsius().pipe(take(1)),
      rights: this.clientService.client().pipe(
        take(1),
        map((client) => client.rattigheter)
      ),
    }).subscribe(({ isAgrometius, rights }) =>
      this.updateOptions(isAgrometius, rights)
    );
  }

  private updateOptions(isAgrometius: boolean, rights: ClientRights): void {
    const hasMachineConnectionRight = rights['machineConnection'];

    this.exportOptions.forEach((option) => {
      if (isAgrometius && option.key === EXPORT_KEY.CONNECTED_FARM) {
        option.showOption = true;
      }

      if (
        option.key === EXPORT_KEY.MACHINE_CONNECTION &&
        hasMachineConnectionRight
      ) {
        option.showOption = true;
      }
    });

    this.options.next(this.exportOptions);
  }
}
