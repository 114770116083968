import {
  Component,
  NgZone,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import * as turf from '@turf/turf';
import { MapToolComponent } from '../map-tool';
import { SiteService } from 'app/services/site.service';
import { MapService, LAYER_ZINDEX } from '../../map.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-map-split',
  templateUrl: 'map-split.component.html',
  styleUrls: ['map-split.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapSplitComponent extends MapToolComponent implements OnDestroy {
  skifteId: number;
  message = '';
  splitLine = new google.maps.Polyline({
    clickable: true,
    draggable: true,
    editable: true,
    strokeColor: '#1976d2',
    strokeWeight: 4,
  });
  splitLinePath = this.splitLine.getPath();

  private mapClickEvent: google.maps.MapsEventListener = null;

  constructor(
    siteService: SiteService,
    translateService: DvToolbarTranslateService,
    mapService: MapService,
    cd: ChangeDetectorRef,
    zone: NgZone
  ) {
    super(siteService, mapService, cd, zone, translateService);
  }

  protected onMapInit(): void {
    if (!this.splitLine) {
      this.splitLine = new google.maps.Polyline({
        clickable: true,
        draggable: true,
        editable: true,
        strokeColor: '#1976d2',
        strokeWeight: 4,
      });
    }

    this.splitLine.setMap(this.dvMap.map);
    this.message = this.translateService.t('Draw a splitline');

    this.splitLinePath.addListener('set_at', () => {
      this.onSplitLineChanged();
    });

    this.splitLinePath.addListener('insert_at', () => {
      this.onSplitLineChanged();
    });

    this.splitLine.addListener(
      'dblclick',
      (event: google.maps.PolyMouseEvent) => {
        if (this.splitLine.getEditable() && event.vertex !== undefined) {
          this.splitLinePath.removeAt(event.vertex);

          this.onSplitLineChanged();
        }
      }
    );
  }

  private onSplitLineChanged(): void {
    this._split();

    if (this.splitLinePath.getLength() === 1) {
      this.message = this.translateService.t(
        'Add point on the other of parcel'
      );
    }

    if (this.parts.length > 1) {
      this.message = this.translateService.t('Click done to connect');
    }

    this.zone.run(() => {
      this.cd.markForCheck();
    });
  }

  private addSplitLineVertex(event: google.maps.MouseEvent): void {
    this.splitLinePath.push(event.latLng);
    this.splitLine.setPath(this.splitLinePath);
    this.onSplitLineChanged();
  }

  /**
   * Called when the tool sould be actived
   */
  onActive(): void {
    this.splitLine.setMap(this.dvMap.map);
    this.splitLine.setOptions({
      zIndex: this.orginalFeature.getProperty(LAYER_ZINDEX) + 1000,
    });
    this.mapClickEvent = this.dvMap.map.addListener(
      'click',
      (event: google.maps.MouseEvent) => {
        this.addSplitLineVertex(event);
      }
    );

    this.activated.next();
  }

  /**
   * Called when the tool is deactivated ie not active
   */
  onDeActivated(): void {
    this.dvMap.edit(null);
    this.dvMap.removeLayer('split');
    this.splitLine.setPath([]);
    this.splitLinePath.clear();
    this.parts.forEach((p) => p.featureText.onRemove());
    this.parts = [];
    this.cancel.next();

    if (this.mapClickEvent) {
      this.mapClickEvent.remove();
    }
  }

  private _split(): void {
    if (this.splitLinePath.getLength() < 2) {
      return;
    }

    this.mapService
      .topology({
        featureA: this.selectedFeature,
        featureB: turf.lineString(
          this.splitLinePath.getArray().map((latLng: google.maps.LatLng) => {
            return [latLng.lng(), latLng.lat()];
          })
        ),
        pointA: null,
        pointB: null,
        dist: null,
        area: null,
        operator: 'Split',
      })
      .subscribe((f) => {
        this.setParts(f);
        this.cd.markForCheck();
      });
  }

  /**
   * Clean up, what is used
   */
  ngOnDestroy(): void {
    this.clean();
    this.onDeActivated();

    if (this.mapClickEvent) {
      this.mapClickEvent.remove();
    }
  }
}
