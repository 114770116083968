<form #mapSpiltForm="ngForm" fxLayout="column" fxFlex>
  <div class="map-field-part-inner-form-wrapper" fxFlex>
    <mat-accordion *ngIf="parts.length > 0">
      <mat-expansion-panel
        *ngFor="let part of _parts; index as i"
        fxLayout="column"
      >
        <mat-expansion-panel-header [ngSwitch]="part.action">
          <div
            fxFlexAlign="center"
            [style.background]="part.color"
            class="map-field-part-panel-header-part"
          ></div>
          <span *ngSwitchCase="'update'">{{ 'Update original' | dvt }}</span>
          <span *ngSwitchCase="'new'">{{ 'Create new field' | dvt }}</span>
          <span *ngSwitchCase="'delete'">{{ 'Remove part' | dvt }}</span>
        </mat-expansion-panel-header>
        <div>
          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row">
              <div fxFlexAlign="center">{{ part.areal | areaUnit }}</div>
            </div>
            <mat-form-field *ngIf="skifte">
              <mat-select
                class="map-field-part-field-select"
                fxFlexAlign="center"
                fxFlex
                [(ngModel)]="part.action"
                name="action-{{ i }}"
                (selectionChange)="setSkifteEuNr(part)"
              >
                <mat-option [value]="'update'" *ngIf="skifte">
                  {{ 'Update original' | dvt }}
                </mat-option>
                <mat-option [value]="'new'">
                  {{ 'Create new field' | dvt }}
                </mat-option>
                <mat-option [value]="'delete'" *ngIf="skifte">
                  {{ 'Remove part' | dvt }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div [ngSwitch]="part.action">
            <div *ngSwitchCase="'new'" fxLayout="column">
              <mat-form-field>
                <mat-select
                  class="map-field-part-brukenh-select"
                  required
                  [(ngModel)]="part.brukEnhId"
                  title="{{ 'Farm' | dvt }}"
                  placeholder="{{ 'Farm' | dvt }}"
                  name="brukenh-{{ i }}"
                  (selectionChange)="changeBrukenh(part)"
                >
                  <mat-option
                    *ngFor="let bruk of brukenheter"
                    [value]="bruk.id"
                  >
                    {{ bruk.namn }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  placeholder="{{ 'Skiftesnamn' | dvt }}"
                  type="text"
                  [(ngModel)]="part.namn"
                  name="name"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <button
      class="map-field-part-flip-button"
      mat-stroked-button
      *ngIf="parts.length === 2"
      (click)="flipSkifte()"
    >
      <mat-icon>swap_vert</mat-icon>
      {{ '_flip_selection' | dvt: '_Flip selection' }}
    </button>
  </div>

  <div class="map-field-part-button-wrapper">
    <button mat-raised-button (click)="onCancelClick()">
      {{ 'Cancel' | dvt }}
    </button>
    <button
      class="map-field-part-save-button"
      mat-raised-button
      color="accent"
      type="submit"
      *ngIf="parts.length > 0"
      [disabled]="!mapSpiltForm.valid"
      (click)="onSaveClick()"
    >
      {{ 'Done' | dvt }}
    </button>
  </div>
</form>
