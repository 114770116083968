import { Component } from '@angular/core';
import { MapPageBase } from 'app/components/map/map-page.base';
import { MapComponent } from 'app/components/map/map.component';
import {
  LAYER_NAME_INTERPOLATION,
  LAYER_NAME_MARKERING,
  MapService,
} from 'app/components/map/map.service';
import { DeviationModel } from 'app/models/interpolation.model';
import { MapStateService } from 'app/services/map-state.service';
import { PrescriptionService } from 'app/services/prescription.service';
import { combineLatest, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dv-prescription-page',
  templateUrl: './prescription-page.component.html',
  styleUrls: ['./prescription-page.component.scss'],
})
export class PrescriptionPageComponent extends MapPageBase {
  loading = true;
  mapName = 'map';
  private _unsub$: Subject<void> = new Subject<void>();
  activePrescription: GeoJSON.FeatureCollection;
  deviations: DeviationModel[];

  constructor(
    mapService: MapService,
    private prescSrv: PrescriptionService,
    private mapStateSrv: MapStateService
  ) {
    super(mapService);
  }

  onMapInit(dvMap: MapComponent): void {
    dvMap.multiSelect = false;

    this.prescSrv.prescriptionCalculationLoading$
      .pipe(takeUntil(this._unsub$), delay(0))
      .subscribe((calculating) => (this.loading = calculating));

    combineLatest([
      this.mapStateSrv.getPrescription(),
      this.mapStateSrv.getPrescriptionBasis(),
      dvMap.getDeviations(),
    ])
      .pipe(takeUntil(this._unsub$))
      .subscribe(([prescription, prescriptionBasis, devs]) => {
        this.deviations = devs;
        dvMap.removeLayer(LAYER_NAME_INTERPOLATION);
        if (prescriptionBasis !== null) {
          this.handlePrescriptionIntepolation(
            dvMap,
            prescriptionBasis.interpolation
          );
        } else if (prescription) {
          this.handlePrescription(
            dvMap,
            <GeoJSON.FeatureCollection>prescription
          );
        }
      });
  }

  handlePrescriptionIntepolation(
    map: MapComponent,
    interpolation: GeoJSON.FeatureCollection
  ): void {
    map.fitFeature(map.addGeoJson(interpolation, LAYER_NAME_INTERPOLATION));
  }

  handlePrescription(
    map: MapComponent,
    featureCollection: GeoJSON.FeatureCollection
  ): void {
    const deviationsIds = [];
    this.deviations.forEach((dev) => {
      dev.features.forEach((ft) => {
        const id = ft.properties ? ft.id : ft.getId();
        deviationsIds.push(id);
        const feat = [...featureCollection.features].find(
          (feat) => feat.id === id
        );
        if (feat) {
          if (!feat.properties.oldColor) {
            feat.properties.oldColor = feat.properties.color;
          }
          if (!feat.properties.oldRate) {
            feat.properties.oldRate = feat.properties.rate;
          }
          feat.properties.color = ft.properties
            ? ft.properties.color
            : ft.getProperty('color');
          feat.properties.rate = ft.properties
            ? ft.properties.rate
            : ft.getProperty('rate');
        }
      });
    });
    [...featureCollection.features]
      .filter(
        (feat) =>
          feat.properties.oldColor &&
          feat.properties.oldColor !== feat.properties.color &&
          !deviationsIds.includes(feat.id)
      )
      .forEach((f) => {
        f.properties.color = f.properties.oldColor;
        f.properties.rate = f.properties.oldRate;
        if (f.properties.activeDeviation) {
          delete f.properties.activeDeviation;
        }
        delete f.properties.oldColor;
        delete f.properties.oldRate;
      });
    map.fitFeature(map.addGeoJson(featureCollection, LAYER_NAME_INTERPOLATION));
  }

  onMapCleanUp(): void {
    this.dvMap.clearMap([LAYER_NAME_MARKERING]);
    this._unsub$.next();
    this._unsub$.complete();
  }
}
