import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MapService } from '../map.service';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { ClientService } from 'app/services/client.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';
@Component({
  selector: 'dv-map-menu',
  templateUrl: './map-menu.component.html',
  styleUrls: ['./map-menu.component.css'],
})
export class MapMenuComponent implements OnInit {
  pos = { lat: 0, lng: 0 };

  showCreateField = false;

  @ViewChild('menu') menu: MatMenu;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild('menuEl') menuSpan: ElementRef<HTMLSpanElement>;

  constructor(
    private clientService: ClientService,
    private mapService: MapService,
    private snackBar: MatSnackBar,
    private translate: DvToolbarTranslateService,
    private clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    this.mapService.mainMap().subscribe((mainMap) => {
      mainMap.map.addListener('rightclick', (ev: google.maps.MouseEvent) => {
        this.showCreateField = mainMap.rcmShowCreateField;
        ev.stop();

        this.pos.lat = ev.latLng.lat();
        this.pos.lng = ev.latLng.lng();

        const pixel = ev['pixel'];
        this.menuTrigger.openMenu();
        this.menuSpan.nativeElement.style.left = pixel.x + 'px';
        this.menuSpan.nativeElement.style.top = pixel.y + 'px';
      });
    });
  }

  copyPos(): void {
    this.clipboard.copy(this.pos.lat + ' ' + this.pos.lng);
    this.snackBar.open(this.translate.t('Copied to clipboard'), null, {
      duration: 3000,
    });
  }

  createField(): void {
    this.clientService.setCreateFieldPoint({
      lat: this.pos.lat,
      lng: this.pos.lng,
    });
  }
}
