<div *ngIf="file" class="container">
    <div class="row">
        <div class="col">
            <p>{{ 'Type' | dvt }}</p>
            <span class="vertical-center"
                ><span class="color-circle" [ngStyle]="{ 'background-color': wizSrv.colorHash(file.type) }">&nbsp;</span
                >{{ '_groupname_' + file.type | dvt: '_' + file.type }}</span
            >
        </div>
        <div class="col right" *ngIf="file.created">
            <p>{{ 'Created' | dvt }}</p>
            <span>{{ file.created | date: 'short' }}</span>
        </div>
    </div>
    <div class="col" *ngIf="file.brukEnhNamn">
        <p>{{ 'farm' | dvt }}</p>
        <span>{{ file.brukEnhNamn }}</span>
    </div>
</div>

<div *ngIf="marking" class="container">
    <div class="row">
        <div class="col">
            <p>{{ 'Type of marking' | dvt }}</p>
            <span class="vertical-center"
                ><span class="color-circle" [ngStyle]="{ 'background-color': wizSrv.colorHash(marking.category) }"
                    >&nbsp;</span
                >{{ marking.category }}</span
            >
        </div>
        <div class="col right" *ngIf="marking.created">
            <p>{{ 'Created' | dvt }}</p>
            <span>{{ marking.created | date: 'shortDate' }}</span>
        </div>
    </div>
    <div class="col" *ngIf="marking.brukEnhNamn">
        <p>{{ 'farm' | dvt }}</p>
        <span>{{ marking.brukEnhNamn }}</span>
    </div>
    <div class="col">
        <p>{{ 'note' | dvt }}</p>
        <span>{{ marking.anteckning ? marking.anteckning : '--' }}</span>
    </div>
    <div class="col" *ngIf="marking.editable">
        <p>{{ 'Completed' | dvt }}</p>
        <span>{{ marking.utford ? ('Yes' | dvt) : ('No' | dvt) }}</span>
    </div>
    <div class="buttons-container">
        <button mat-icon-button color="warn" class="delete" (click)="delete()"><mat-icon>delete</mat-icon></button>
        <button *ngIf="marking.editable" class="edit" (click)="edit()" mat-icon-button>
            <mat-icon>edit</mat-icon>
        </button>
        <button *ngIf="!marking.editable" (click)="close()" mat-stroked-button>{{ 'Close' | dvt }}</button>
    </div>
</div>
