<div class="meta-content">
    <div class="action-buttons">
        <button
            class="action-button"
            (click)="fileSrv.openGeodataDetail(geodata.fileId)"
            mat-menu-item
        >
            <img
                class="action-button-icon"
                src="assets/images/icon-geodata.svg"
            />
            {{ 'View details' | dvt }}
        </button>
        <button
            class="action-button"
            (click)="showFilesetDetail(geodata.rawFileId)"
            mat-menu-item
            *ngIf="geodata.rawFileId"
        >
            <img
                class="action-button-icon"
                src="assets/images/icon-raw-file.svg"
            />
            {{ 'View source file' | dvt }}
        </button>
    </div>
    <div class="data-rows">
        <div class="row" *ngFor="let meta of reservedData">
            <div class="label">{{ meta.key }}</div>
            <div class="value">{{ meta.value }}</div>
        </div>
        <div class="row" *ngFor="let meta of customData">
            <div class="label">{{ meta.key }}</div>
            <div class="value">{{ meta.value }}</div>
        </div>
    </div>
</div>
