<div
  class="empty-view markings"
  *ngIf="viewInited && markings?.length === 0 && !loading"
>
  <button mat-icon-button (click)="addMarking()" class="add-marking">
    <mat-icon>add</mat-icon>{{ 'Add marking' | dvt }}
  </button>
</div>
<div class="loading-area" *ngIf="!viewInited || loading">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<ng-container *ngIf="markings?.length > 0 && !loading">
  <mat-table
    *ngIf="viewInited"
    matSort
    [dataSource]="matDataMarkings"
    (matSortChange)="sortMarkData($event)"
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="visibility">
      <mat-header-cell
        class="visibility-header"
        mat-header-cell
        *matHeaderCellDef
      ></mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row" class="visibility-cell">
        <button (click)="toggleVisibility(row, $event)" mat-icon-button>
          <img
            [src]="
              selection.isSelected(row)
                ? 'assets/images/icon_eye.svg'
                : 'assets/images/icon_eye_disabled.svg'
            "
          />
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="category">
      <mat-header-cell
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="category"
      >
        {{ 'type' | dvt }}
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element; let i = index">
        <span class="vertical-center" *ngIf="expandedRow !== i">
          <span
            class="color-circle"
            [ngStyle]="{
              'background-color': wizSrv.colorHash(element.category)
            }"
          >
            &nbsp;
          </span>
          {{ element.category }}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="created">
      <mat-header-cell
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="created"
      >
        {{ 'Created' | dvt }}
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element; let i = index">
        <span *ngIf="expandedRow !== i">
          {{ element.created | date: 'shortDate' }}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="note">
      <mat-header-cell mat-header-cell *matHeaderCellDef>
        {{ 'note' | dvt }}
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element; let i = index">
        <span>{{ element.anteckning }}</span>
      </mat-cell>
    </ng-container>

    <mat-row
      mat-header-row
      *matHeaderRowDef="displayedColumnsMarkings"
    ></mat-row>

    <mat-row
      mat-row
      [class.edit]="i === expandedRow"
      [id]="'mark-row-' + i"
      *matRowDef="let row; columns: displayedColumnsMarkings; let i = index"
      #tableRow
      (click)="openDetailView(row, i, 'marking')"
    ></mat-row>
  </mat-table>
</ng-container>
