import { OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeNo from '@angular/common/locales/nb';
import localeNl from '@angular/common/locales/nl';
import localeSv from '@angular/common/locales/sv';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from 'environments/environment';
import { DragScrollModule } from 'ngx-drag-scroll';
import { AboutComponent } from './about/about.component';
import { AppComponent } from './app.component';
import { BrukEnhDialogComponent } from './bruk-enh-dialog/bruk-enh-dialog.component';
import { ChoiceButtonComponent } from './components/choice-button/choice-button.component';
import { ClientComponent } from './components/client/client.component';
import { ConfirmComponent } from './components/dialog/confirm/confirm.component';
import { DialogModule } from './components/dialog/dialog.module';
import { PromptComponent } from './components/dialog/prompt/prompt.component';
import { DragContainerComponent } from './components/drag-container/drag-container.component';
import { DropZoneComponent } from './components/drop-zone/drop-zone.component';
import { FieldToolComponent } from './components/field-tool/field-tool.component';
import { FieldViewComponent } from './components/field-view/field-view.component';
import { IframeComponent } from './components/iframe/iframe.component';
import { CommonComponent } from './components/import-view/common/common.component';
import { FieldBoundaryComponent } from './components/import-view/field-boundary/field-boundary.component';
import { FileuploadComponent } from './components/import-view/fileupload/fileupload.component';
import { ImportDoneComponent } from './components/import-view/import-done/import-done.component';
import { ImportProgressComponent } from './components/import-view/import-progress/import-progress.component';
import { ImportViewComponent } from './components/import-view/import-view.component';
import { LogdataComponent } from './components/import-view/logdata/logdata.component';
import { PrescriptionComponent } from './components/import-view/prescription/prescription.component';
import { SoilsamplingComponent } from './components/import-view/soilsampling/soilsampling.component';
import { SensordataComponent } from './components/import-view/sensordata/sensordata.component';
import { VerisComponent } from './components/import-view/veris/veris.component';
import { YieldComponent } from './components/import-view/yield/yield.component';
import { AsappliedComponent } from './components/import-view/asapplied/asapplied.component';
import { InsufficientRightsDialogComponent } from './components/insufficient-rights-dialog/insufficient-rights-dialog.component';
import { KlientInfoComponent } from './components/klient-info/klient-info.component';
import { LayerDialogComponent } from './components/layer-dialog/layer-dialog.component';
import { LayerViewComponent } from './components/layer-view/layer-view.component';
import { LoginPromptComponent } from './components/login-prompt/login-prompt.component';
import { MapViewComponent } from './components/map-view/map-view.component';
import { CoordinateComponent } from './components/map/coordinate/coordinate.component';
import { MapMenuComponent } from './components/map/map-menu/map-menu.component';
import { MapModule } from './components/map/map.module';
import { MeasureComponent } from './components/map/measure/measure.component';
import { MapBlockComponent } from './components/map/tools/block/map-block.component';
import { MapConnectNorgeComponent } from './components/map/tools/connect-norge/map-connect-norge.component';
import { MapConnectFieldComponent } from './components/map/tools/connect-skifte/map-connect-field.component';
import { MapConnectComponent } from './components/map/tools/connect/map-connect.component';
import { DrawDirectComponent } from './components/map/tools/draw-direct/draw-direct.component';
import { DrawComponent } from './components/map/tools/draw/draw.component';
import { MapFieldPartComponent } from './components/map/tools/map-field-part/map-field-part.component';
import { MergeComponent } from './components/map/tools/merge/merge.component';
import { MapSplitAreaComponent } from './components/map/tools/map-split-area/map-split-area.component';
import { MapSplitComponent } from './components/map/tools/map-split/map-split.component';
import { VandtegComponent } from './components/map/tools/vandteg/vandteg.component';
import { CropsatIndexComponent } from './components/mapping-view/cropsat-index/cropsat-index.component';
import { CropsatLegendComponent } from './components/mapping-view/cropsat-legend/cropsat-legend.component';
import { LegendComponent } from './components/mapping-view/legend/legend.component';
import { DialogComponent } from './components/mapping-view/mapping-wizard/wizard-table/dialog/dialog.component';
import { MarkingDetailViewComponent } from './components/mapping-view/mapping-wizard/wizard-table/marking-detail-view/marking-detail-view.component';
import { TableExpandedRowComponent } from './components/mapping-view/mapping-wizard/wizard-table/table-expanded-row/table-expanded-row.component';
import { TableMarkingsComponent } from './components/mapping-view/mapping-wizard/wizard-table/tables/table-markings/table-markings.component';
import { TableSkifteComponent } from './components/mapping-view/mapping-wizard/wizard-table/tables/table-skifte/table-skifte.component';
import { WizardTableComponent } from './components/mapping-view/mapping-wizard/wizard-table/wizard-table.component';
import { MenuComponent } from './components/menu/menu.component';
import { NewYearComponent } from './components/new-year/new-year.component';
import { PoiAddComponent } from './components/poi/poi-add/poi-add.component';
import { PoiCategorysComponent } from './components/poi/poi-categorys/poi-categorys.component';
import { PoiDetailComponent } from './components/poi/poi-detail/poi-detail.component';
import { PoiToolbarComponent } from './components/poi/poi-toolbar/poi-toolbar.component';
import { PoiComponent } from './components/poi/poi.component';
import { BasisLegendComponent } from './components/prescription-view/basis-legend/basis-legend.component';
import { PrescriptionBucketsComponent } from './components/prescription-view/prescription-buckets/prescription-buckets.component';
import { ConnectedFarmRedirectComponent } from './components/prescription-view/prescription-export/connected-farm-redirect/connected-farm-redirect.component';
import { PrescriptionExportComponent } from './components/prescription-view/prescription-export/prescription-export.component';
import { PrescriptionLegendComponent } from './components/prescription-view/prescription-legend/prescription-legend.component';
import { PrescriptionViewComponent } from './components/prescription-view/prescription-view.component';
import { SearchableSelectOverlayComponent } from './components/searchable-select/searchable-select-overlay.component';
import { SearchableSelectComponent } from './components/searchable-select/searchable-select.component';
import { SvgComponent } from './components/svg.component';
import { ToolBarComponent } from './components/tool-bar/tool-bar.component';
import { FiledropDirective } from './directives/filedrop.directive';
import { AddCropComponent } from './field-list-page/add-crop/add-crop.component';
import { EditFarmsComponent } from './field-list-page/edit-farms/edit-farms.component';
import { EditFieldMapComponent } from './field-list-page/edit-field-map/edit-field-map.component';
import { FieldListPageComponent } from './field-list-page/field-list-page.component';
import { FieldPageComponent } from './field-page/field-page.component';
import { DataPageComponent } from './data-page/data-page.component';
import { ExportComponent } from './components/mapping-view/mapping-wizard/wizard-interpolation/export/export.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { MapPageComponent } from './map-page/map-page.component';
import { MapSplitFieldPickPageComponent } from './map-split-page/map-split-field-pick/map-split-field-pick-page.component';
import { MapSplitModeComponent } from './map-split-page/map-split-mode/map-split-mode.component';
import { MapSplitPageComponent } from './map-split-page/map-split-page.component';
import { MapSplitPropPickerComponent } from './map-split-page/map-split-prop-picker/map-split-prop-picker.component';
import { MatPaginatorIntlCustom } from './mat-paginator-Intl';
import { DvMaterialModule } from './material.module';
import { PipeModule } from './pipes/pipe/pipe.module';
import { PrescriptionPageComponent } from './prescription-page/prescription-page.component';
import { SharePageComponent } from './share-page/share-page.component';
import { ShareComponent } from './share-page/share/share.component';
import { StartPageComponent } from './start-page/start-page.component';
import { FilesetListDetailComponent } from './components/data/fileset/fileset-list-detail/fileset-list-detail.component';
import { GeodataRawdataPreviewComponent } from './components/data/geodata/geodata-file-preview/geodata-file-preview.component';
import { MetadataPromptComponent } from './components/import-view/metadata-prompt/metadata-prompt.component';
import { FileSetListComponent } from './components/data/fileset/fileset-list/fileset-list.component';
import { GeoDataListComponent } from './components/data/geodata/geodata-list/geodata-list.component';
import { GeodataDetailComponent } from './data-page/geodata-detail/geodata-detail.component';
import { GeoDataListDetailComponent } from './components/data/geodata/geodata-list/geodata-list-detail/geodata-list-detail.component';
import { GeodataFileDetailsComponent } from './components/data/geodata/geodata-file-details/geodata-file-details.component';
import { GeodataInterpolationComponent } from './components/data/geodata/geodata-interpolation/geodata-interpolation.component';
import { NoDataComponent } from './data-page/no-data/no-data.component';
import { FilterSectionComponent } from './data-page/filter-section/filter-section.component';
import { SortArrows } from './components/data/sort-arrows/sort-arrows.component';
import { EmptySearchResultComponent } from './data-page/empty-search-result/empty-search-result.component';
import { FileStatusComponent } from './components/data/file-status/file-status.component';
import { GeodataListShareDialogComponent } from './components/data/geodata/geodata-list/geodata-list-share-dialog/geodata-list-share-dialog.component';
import { FileSetListShareDialogComponent } from './components/data/fileset/fileset-list/fileset-list-share-dialog/fileset-list-share-dialog.component';
import { PrescriptionWizardPageComponent } from './views/prescription-wizard-page/prescription-wizard-page.component';
import { StepFieldComponent } from './views/prescription-wizard-page/components/step-field/step-field.component';
import { StepperHeaderComponent } from './views/prescription-wizard-page/components/stepper-header/stepper-header.component';
import { StepFunctionComponent } from './views/prescription-wizard-page/components/step-function/step-function.component';
import { YieldDisclaimerComponent } from './components/yield-disclaimer/yield-disclaimer.component';
import { InterpolationGroupsComponent } from './components/mapping-view/field/interpolation-groups/interpolation-groups.component';
import { FieldInterpolationComponent } from './components/mapping-view/field/interpolation/field-interpolation.component';
import { BaseFieldsComponent } from './components/mapping-view/field/base-fields.component';
import { InterpolationSettingsComponent } from './components/mapping-view/field/interpolation-settings/interpolation-settings.component';
import { InterpolationBasisComponent } from './components/mapping-view/field/interpolation-basis/interpolation-basis.component';
import { InterpolationBasisPreviewComponent } from './components/mapping-view/field/interpolation-basis/interpolation-basis-preview/interpolation-basis-preview.component';
import { NoteComponent } from './components/dialog/note/note.component';
import { FileStatusLogComponent } from './components/file-status-log/file-status-log.component';
import { SelectableCardComponent } from './views/prescription-wizard-page/components/step-function/components/selectable-card/selectable-card.component';
import { SettingsMapBarComponent } from './views/prescription-wizard-page/components/settings-map-bar/settings-map-bar.component';
import { SelectComponent } from './components/select/select.component';
import { StepSettingsComponent } from './views/prescription-wizard-page/components/step-settings/step-settings.component';
import { InputComponent } from './components/input/input.component';
import { StepAdjustComponent } from './views/prescription-wizard-page/components/step-adjust/step-adjust.component';
import { StepAdjustNoDataComponent } from './views/prescription-wizard-page/components/step-adjust/components/step-adjust-no-data/step-adjust-no-data.component';
import { AdjustmentsDialogComponent } from './views/prescription-wizard-page/components/step-adjust/components/adjustments-dialog/adjustments-dialog.component';
import { AdjustmentsManualDrawComponent } from './views/prescription-wizard-page/components/step-adjust/components/manual-draw-pane/components/adjustments-manual-draw/adjustments-manual-draw.component';
import { MapSplitMarkerModeComponent } from './map-split-page/map-split-marker-mode/map-split-marker-mode.component';
import { FileSectionComponent } from './views/prescription-wizard-page/components/step-settings/components/file-section/file-section.component';
import { InputSectionComponent } from './views/prescription-wizard-page/components/step-settings/components/input-section/input-section.component';
import { PrescriptionButtonComponent } from './components/prescription-view/prescription-export/prescription-button/prescription-button.component';
import { MachineConnectionDialogComponent } from './components/prescription-view/prescription-export/machine-connection-dialog/machine-connection-dialog.component';
import { MachineConnectionActionsComponent } from './components/prescription-view/prescription-export/machine-connection-dialog/components/machine-connection-actions/machine-connection-actions.component';
import { MachineConnectionHeaderComponent } from './components/prescription-view/prescription-export/machine-connection-dialog/components/machine-connection-header/machine-connection-header.component';
import { AdjustmentsLegendComponent } from './views/prescription-wizard-page/components/step-adjust/components/adjustments-legend/adjustments-legend.component';
import { ClayContentComponent } from './components/mapping-view/clay-content/clay-content.component';
import { GeoDataHeaderComponent } from './views/prescription-wizard-page/components/step-settings/components/file-section/components/geo-data-header/geo-data-header.component';
import { PrescriptionSavedNoticeComponent } from './views/prescription-wizard-page/components/step-adjust/components/prescription-saved-notice/prescription-saved-notice.component';
import { GridCellDetailPaneComponent } from './views/prescription-wizard-page/components/step-adjust/components/grid-cell-detail-pane/grid-cell-detail-pane.component';
import { GridSelectInfoCardComponent } from './views/prescription-wizard-page/components/step-adjust/components/grid-select-info-card/grid-select-info-card.component';
import { SlidingPaneComponent } from './views/prescription-wizard-page/components/sliding-pane/sliding-pane.component';
import { ManualDrawPaneComponent } from './views/prescription-wizard-page/components/step-adjust/components/manual-draw-pane/manual-draw-pane.component';
import { SettingsPaneComponent } from './views/prescription-wizard-page/components/step-settings/components/settings-pane/settings-pane.component';
import { FeatureDataComponent } from './components/map/feature-data/feature-data.component';
import { APP_ROUTES } from 'app/app.routes';
import {
  DvToolbarMsalModule,
  DvToolbarMsalModuleOptions,
  DvToolbarTranslateModule,
  DvToolbarTranslateService,
  DvTranslatePipe,
  DvTranslateCasePipe,
  DvTranslateLowercasePipe,
  localeFactory,
  localeIdFactory,
} from '@dv/toolbar-msal';
import { lastValueFrom } from 'rxjs';
import { RedirectFromMarkkarteringComponent } from './views/redirect/redirectfrommarkkartering.component';

registerLocaleData(localeSv);
registerLocaleData(localeNl);
registerLocaleData(localeNo);
registerLocaleData(localeDe);
registerLocaleData(localeFr);

export function initTranslate(
  translate: DvToolbarTranslateService
): () => Promise<void> {
  return async () => lastValueFrom(translate.init());
}

@NgModule({ declarations: [
        AboutComponent,
        AddCropComponent,
        AdjustmentsDialogComponent,
        AdjustmentsLegendComponent,
        AdjustmentsManualDrawComponent,
        AppComponent,
        AsappliedComponent,
        BaseFieldsComponent,
        BasisLegendComponent,
        BrukEnhDialogComponent,
        ChoiceButtonComponent,
        ClayContentComponent,
        ClientComponent,
        CommonComponent,
        ConfirmComponent,
        ConnectedFarmRedirectComponent,
        CoordinateComponent,
        CropsatIndexComponent,
        CropsatLegendComponent,
        DataPageComponent,
        DialogComponent,
        DragContainerComponent,
        DrawComponent,
        DrawDirectComponent,
        DropZoneComponent,
        EditFarmsComponent,
        EditFieldMapComponent,
        EmptySearchResultComponent,
        ExportComponent,
        FeatureDataComponent,
        FieldBoundaryComponent,
        FieldInterpolationComponent,
        FieldListPageComponent,
        FieldPageComponent,
        FieldToolComponent,
        FieldViewComponent,
        FiledropDirective,
        FileSetListComponent,
        FilesetListDetailComponent,
        FileSetListShareDialogComponent,
        FileStatusComponent,
        FileStatusLogComponent,
        FileuploadComponent,
        FilterSectionComponent,
        GeodataDetailComponent,
        GeodataFileDetailsComponent,
        GeodataInterpolationComponent,
        GeoDataListComponent,
        GeoDataListDetailComponent,
        GeodataListShareDialogComponent,
        GeodataRawdataPreviewComponent,
        GridCellDetailPaneComponent,
        GridSelectInfoCardComponent,
        IframeComponent,
        ImportDoneComponent,
        ImportProgressComponent,
        ImportViewComponent,
        InputComponent,
        InsufficientRightsDialogComponent,
        InterpolationBasisComponent,
        InterpolationBasisPreviewComponent,
        InterpolationGroupsComponent,
        InterpolationSettingsComponent,
        KlientInfoComponent,
        LayerDialogComponent,
        LayerViewComponent,
        LegendComponent,
        LogdataComponent,
        LoginPageComponent,
        LoginPromptComponent,
        MachineConnectionActionsComponent,
        MachineConnectionDialogComponent,
        MachineConnectionHeaderComponent,
        ManualDrawPaneComponent,
        MapBlockComponent,
        MapConnectComponent,
        MapConnectFieldComponent,
        MapConnectNorgeComponent,
        MapFieldPartComponent,
        MapMenuComponent,
        MapPageComponent,
        MapSplitAreaComponent,
        MapSplitComponent,
        MapSplitFieldPickPageComponent,
        MapSplitMarkerModeComponent,
        MapSplitModeComponent,
        MapSplitPageComponent,
        MapSplitPropPickerComponent,
        MapViewComponent,
        MarkingDetailViewComponent,
        MeasureComponent,
        MenuComponent,
        MergeComponent,
        MetadataPromptComponent,
        NewYearComponent,
        NoDataComponent,
        NoteComponent,
        PoiAddComponent,
        PoiCategorysComponent,
        PoiComponent,
        PoiDetailComponent,
        PoiToolbarComponent,
        PrescriptionBucketsComponent,
        PrescriptionButtonComponent,
        PrescriptionComponent,
        PrescriptionExportComponent,
        PrescriptionLegendComponent,
        PrescriptionPageComponent,
        PrescriptionSavedNoticeComponent,
        PrescriptionViewComponent,
        PrescriptionWizardPageComponent,
        PromptComponent,
        SearchableSelectComponent,
        SearchableSelectOverlayComponent,
        SelectableCardComponent,
        SelectComponent,
        SensordataComponent,
        SettingsMapBarComponent,
        SettingsPaneComponent,
        ShareComponent,
        SharePageComponent,
        SlidingPaneComponent,
        SoilsamplingComponent,
        SortArrows,
        StartPageComponent,
        StepAdjustComponent,
        StepAdjustNoDataComponent,
        StepFieldComponent,
        StepFunctionComponent,
        StepperHeaderComponent,
        StepSettingsComponent,
        SvgComponent,
        TableExpandedRowComponent,
        TableMarkingsComponent,
        TableSkifteComponent,
        ToolBarComponent,
        VandtegComponent,
        VerisComponent,
        WizardTableComponent,
        YieldComponent,
        YieldDisclaimerComponent,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BrowserAnimationsModule,
        BrowserModule,
        DialogModule,
        DragScrollModule,
        DvMaterialModule,
        DvTranslateCasePipe,
        DvTranslateLowercasePipe,
        DvTranslatePipe,
        FileSectionComponent,
        FormsModule,
        GeoDataHeaderComponent,
        InputSectionComponent,
        MapModule,
        OverlayModule,
        PipeModule,
        ReactiveFormsModule,
        RedirectFromMarkkarteringComponent,
        RouterModule.forRoot(APP_ROUTES, {
            onSameUrlNavigation: 'reload'
        }),
        DvToolbarTranslateModule.forChild({
            appName: environment.appName,
            debug: !environment.production,
            defaultLanguage: environment.defaultLanguage,
            translateUrl: environment.dvApiUrl,
        }),
        DvToolbarMsalModule.forRoot({
            appKey: environment.appKey,
            defaultLanguage: environment.defaultLanguage,
            dvApiUrl: environment.dvApiUrl,
            iframeProxyUrl: environment.iframeProxyUrl,
            production: environment.production,
            redirectUri: environment.redirectUri,
            staging: environment.staging,
        })], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: localeFactory,
            multi: true,
            deps: [DvToolbarMsalModuleOptions],
        },
        {
            provide: LOCALE_ID,
            useFactory: localeIdFactory,
            deps: [APP_INITIALIZER],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initTranslate,
            deps: [DvToolbarTranslateService],
            multi: true,
        },
        {
            provide: MatPaginatorIntl,
            useFactory: (translate): MatPaginatorIntlCustom => {
                const customIntl = new MatPaginatorIntlCustom();
                customIntl.injectTranslateService(translate);
                return customIntl;
            },
            deps: [DvToolbarTranslateService],
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
