import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NewSkifteEnkelModel, SkifteDetielsModel } from 'app/models/api.models';
import { DialogService } from 'app/components/dialog/dialog.service';
import { MapComponent } from 'app/components/map/map.component';
import {
  LAYER_NAME_SKIFTEN,
  LAYER_SELECTED,
  MapService,
} from 'app/components/map/map.service';
import { PartOption } from 'app/components/map/tools/PartOption';
import { MappingWizardService } from 'app/components/mapping-view/mapping-wizard/mapping-wizard.service';
import { ClientService } from 'app/services/client.service';
import { SiteService } from 'app/services/site.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-edit-field-map',
  templateUrl: './edit-field-map.component.html',
  styleUrls: ['./edit-field-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditFieldMapComponent implements OnInit, OnDestroy {
  activeField: SkifteDetielsModel;
  loading: boolean;
  newSkiften: NewSkifteEnkelModel[] = [];
  title: string;
  connect = false;
  opacity = 0.2;
  standardOpacity = 0.8;

  originalArea: number;
  originalSeedArea: number;
  originalFertilizerArea: number;
  originalPlantProtectionArea: number;

  toolActive = false;
  originalFeature: GeoJSON.Feature;

  @ViewChild('map', { static: true }) map: MapComponent;

  skiften$: Subscription;
  map$: Subscription;
  mapName = 'editField';
  fieldFeature: any;
  isActive = false;

  constructor(
    private clientService: ClientService,
    private cd: ChangeDetectorRef,
    private mapService: MapService,
    private translateService: DvToolbarTranslateService,
    private dialogService: DialogService,
    public siteService: SiteService,
    public wizSrv: MappingWizardService
  ) {}

  ngOnInit(): void {
    this.wizSrv.getEditBordersField().subscribe((skifte) => {
      this.activeField = skifte;
      if (skifte) {
        this.map$ = this.mapService
          .map(this.mapName)
          .pipe(first())
          .subscribe((dvMap) => {
            this.skiften$ = this.clientService
              .getSkifteLayer()
              .subscribe((res) => {
                this.fieldFeature = Object.assign({}, res);
                this.fieldFeature.features = res.features;

                this.fieldFeature.features.forEach((f) => {
                  if (f.properties['skifteId'] !== this.activeField.id) {
                    f.properties['_fillOpacity'] = this.opacity;
                  } else {
                    this.originalFeature = f;
                    f.properties['_fillOpacity'] = this.standardOpacity;
                  }

                  delete f.properties[LAYER_SELECTED];
                });
                dvMap.addGeoJson(this.fieldFeature, LAYER_NAME_SKIFTEN);
                dvMap.selectedFeature(this.activeField.id);
                const f = dvMap.map.data.getFeatureById(this.activeField.id);
                dvMap.fitFeature([f], true);
                this.isActive = true;
                this.cd.markForCheck();
              });
          });
        this.originalArea = this.activeField.areal;
        this.originalSeedArea = this.activeField.arealUtsade;
        this.originalFertilizerArea = this.activeField.arealGodsel;
        this.originalPlantProtectionArea = this.activeField.arealVaxtskydd;
      }
    });

    if (this.connect) {
      this.title = this.translateService.t('Create field');
    } else {
      this.title = this.translateService.t('Change field');
    }
  }

  activateTool(): void {
    this.toolActive = true;
  }

  deActivateTool(): void {
    this.toolActive = false;
    this.cd.markForCheck();
  }

  saveToServer(): void {
    this.loading = true;
    if (this.activeField.id > 0) {
      this.clientService.saveSkifte(this.activeField).subscribe(
        () => {
          this.dialogService.message(this.translateService.t('Field updated'));
          this.clientService.loadSkiften();
          this.clientService.loadSkifteLayer();
          this.loading = false;
          this.wizSrv.setEditBordersField(null);
        },
        (err) => {
          this.dialogService.message(err);
          this.loading = false;
        }
      );
    }
    if (this.newSkiften.length > 0) {
      this.clientService.addSkifte(this.newSkiften).subscribe(
        () => {
          this.dialogService.message(this.translateService.t('Field created'));
          this.clientService.loadSkiften();
          this.clientService.loadSkifteLayer();
          this.loading = false;
        },
        (err) => {
          this.dialogService.message(err);
          this.loading = false;
        }
      );
    }
  }

  saveMap(partsList: PartOption[]): void {
    this.isActive = false;
    const partSkifte = <NewSkifteEnkelModel>{};
    const newSkifte = <NewSkifteEnkelModel>{};

    for (const parts of partsList) {
      switch (parts.action) {
        case 'update':
          this.activeField.areal = parts.areal;
          this.activeField.arealGodsel = parts.areal;
          this.activeField.arealUtsade = parts.areal;
          this.activeField.arealVaxtskydd = parts.areal;
          this.activeField.feature = parts.feature;
          this.activeField.arealSprid =
            this.activeField.arealSprid > parts.areal
              ? parts.areal
              : this.activeField.arealSprid;
          break;
        case 'part':
          partSkifte.areal = parts.areal;
          partSkifte.brukId = parts.brukEnhId;
          partSkifte.feature = parts.feature;
          partSkifte.namn = this.activeField.namn;
          this.newSkiften.push(partSkifte);
          break;
        case 'new':
          newSkifte.areal = parts.areal;
          newSkifte.namn = parts.namn;
          newSkifte.brukId = parts.brukEnhId;
          newSkifte.feature = parts.feature;
          this.newSkiften.push(newSkifte);
          break;
        case 'delete':
          this.clientService.deleteSkifte(parts.skifteId);
          break;
      }
    }

    this.saveToServer();
  }

  ngOnDestroy(): void {
    if (this.skiften$) {
      this.skiften$.unsubscribe();
    }
    if (this.map$) {
      this.map$.unsubscribe();
    }

    if (this.fieldFeature) {
      this.fieldFeature.features.forEach((f) => {
        delete f.properties[LAYER_SELECTED];
      });
    }

    this.mapService.removeMap(this.mapName);
  }

  goBack(): void {
    this.wizSrv.setEditBordersField(null);
    this.cd.markForCheck();
  }
}
