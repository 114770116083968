import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MapComponent } from 'app/components/map/map.component';
import { ManualDrawService } from './manual-draw.service';

@Component({
  selector: 'dv-manual-draw-pane',
  templateUrl: 'manual-draw-pane.component.html',
  styleUrls: ['manual-draw-pane.component.scss'],
})
export class ManualDrawPaneComponent implements OnChanges {
  @Input() dvMap: MapComponent;
  @Input() showManualDrawPane = false;
  @Output() hideManualDrawPane = new EventEmitter<Record<string, number>>();

  constructor(private manualDrawService: ManualDrawService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showManualDrawPane?.currentValue) {
      this.manualDrawService.setActiveDeviationIndex(-1);

      this.restoreSavedDeviations();
    }
  }

  closePane(save?: boolean): void {
    this.manualDrawService.setActiveDeviationIndex(-1);

    if (save) {
      this.manualDrawService
        .saveAndGetPayload()
        .subscribe((payload) =>
          this.hideManualDrawPane.emit(payload.rateOverride)
        );
    } else {
      this.manualDrawService.clearTempDeviations(this.dvMap.map.data);
      this.hideManualDrawPane.emit(null);
    }
  }

  private restoreSavedDeviations(): void {
    this.manualDrawService.restoreSavedDeviations(this.dvMap.map.data);
  }
}
