import { Directive, EventEmitter, Input, Output } from '@angular/core';
import {
  FunctionParameterWithType,
  NestedValue,
} from '../../../../step-settings.types';

@Directive()
export class BaseGeoDataContent<T> {
  @Input() data: FunctionParameterWithType<T>;
  @Output() fileChange = new EventEmitter<NestedValue>();
  @Output() itemChange = new EventEmitter<NestedValue>();
  @Output() fixedValueChange = new EventEmitter<void>();

  useFixedValueChange = false;

  onUseFixedValueChange(useFixedValue: boolean): void {
    this.data.useFixedValue = useFixedValue;
    this.useFixedValueChange = useFixedValue;

    this.fixedValueChange.emit();
  }

  onFixedValueChange(value: number): void {
    this.data.fixedValue = value;

    this.fixedValueChange.emit();
  }
}
