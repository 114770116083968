import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DialogService } from 'app/components/dialog/dialog.service';
import { MetadataPromptComponent } from 'app/components/import-view/metadata-prompt/metadata-prompt.component';
import { GeoDataFileMetaTags } from 'app/models/geodata.model';
import { GeoDataFileModel, RawFileSetModel } from 'app/models/models';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ClientService } from './client.service';
import { DataFilterService } from './data-filter.service';
import { MessagesService } from './messages.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Injectable({
  providedIn: 'root',
})
export class DataFileService {
  selectedFileset$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  selectedGeodata$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  fileset$: BehaviorSubject<RawFileSetModel[]> = new BehaviorSubject<
    RawFileSetModel[]
  >(null);
  geodata$: BehaviorSubject<GeoDataFileModel[]> = new BehaviorSubject<
    GeoDataFileModel[]
  >(null);
  loadingFileset$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  loadingGeodata$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  filesetErr$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  geodataErr$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  geoFileChildren: GeoDataFileModel[];

  constructor(
    private filterService: DataFilterService,
    private clientService: ClientService,
    private translateService: DvToolbarTranslateService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialogService: DialogService,
    private msgSrv: MessagesService
  ) {
    //keep filters
    combineLatest([this.fileset$, this.geodata$]).subscribe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([fileset, geodata]) => {
        this.updateFilterChoices();
        this.populateFilesetDatafiles();
      }
    );

    //look for client change
    this.clientService.client().subscribe(() => {
      this.clearFileset();
      this.clearGeodata();
      this.initFileset();
      this.initGeodata();
    });

    //check for geodata change
    this.msgSrv.onGeoDataInterpolationStatus().subscribe((statusMsg) => {
      if (statusMsg.geoDataId && this.geodata$.value) {
        const newGeodata = [...this.geodata$.value];
        const idx: number = newGeodata.findIndex(
          (d) => d.fileId === statusMsg.geoDataId
        );
        if (idx > -1) {
          newGeodata[idx].status = statusMsg.status;
          this.geodata$.next(newGeodata);
        } else {
          this.loadGeodata();
        }
      }
    });

    //check for fileset change
    this.msgSrv.onFileSetImportStatus().subscribe((statusMsg) => {
      if (statusMsg.fileId && this.fileset$.value) {
        const newFileset = [...this.fileset$.value];
        const idx: number = newFileset.findIndex(
          (d) => d.fileId === statusMsg.fileId
        );
        if (idx > -1) {
          newFileset[idx].status = statusMsg.status;
          this.fileset$.next(newFileset);
        } else {
          this.loadFileset();
        }
      }
    });
  }

  dataSourceLength(dataSource: MatTableDataSource<GeoDataFileModel>): number {
    return dataSource?.filteredData?.length || 0;
  }

  filteredDataSourceLength(
    dataSource: MatTableDataSource<RawFileSetModel>
  ): number {
    return dataSource?.filteredData?.length || 0;
  }

  /* FILESET ==========================================================================*/
  private initFileset(): void {
    if (this.fileset$.value === null && !this.loadingFileset$.value) {
      this.loadFileset();
    }
  }

  private loadFileset(): void {
    this.loadingFileset$.next(true);
    this.clientService.getGeoRawFiles().subscribe(
      (res) => {
        if (this.filesetErr$.value) {
          this.filesetErr$.next(null);
        }

        this.populateFilesetDatafiles();

        this.fileset$.next(
          res.sort((a, b) => {
            return a.uploaded < b.uploaded ? 1 : -1;
          })
        );
        this.loadingFileset$.next(false);
      },
      (error) => {
        this.loadingFileset$.next(false);
        this.filesetErr$.next(
          this.translateService.t(
            '_error_loading_fileset',
            '_Something went wrong. Please try again',
            error
          )
        );
      }
    );
  }

  getFilesets(): Observable<RawFileSetModel[]> {
    this.initFileset();

    return this.fileset$.asObservable();
  }

  private clearFileset(): void {
    this.fileset$.next(null);
  }

  private deleteFromFileset(fileSetId: number): void {
    const newFileset = [...this.fileset$.value];
    const fidx = newFileset.findIndex((f) => f.fileId === fileSetId);

    const newGeoData = [...this.geodata$.value];
    this.geoFileChildren = newFileset[fidx].dataFiles;
    for (const child of this.geoFileChildren) {
      const idx = newGeoData.findIndex((d) => d.fileId === child.fileId);
      newGeoData.splice(idx, 1);
      this.geodata$.next(newGeoData);
    }

    newFileset.splice(fidx, 1);
    this.fileset$.next(newFileset);
  }

  private populateFilesetDatafiles(): void {
    if (this.geodata$.value !== null && this.fileset$.value !== null) {
      this.fileset$.value.forEach((set, idx) => {
        this.fileset$.value[idx].dataFiles = this.geodata$.value.filter(
          (data) => data.rawFileId === set.fileId
        );
      });
    }
  }

  private requestDeleteFileset(filesetId: number): void {
    this.dialogService
      .confirm(
        this.translateService.t(
          '_confirm_delete_rawfile',
          '_Are you sure you want to delete this file and its contents?'
        )
      )
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.clientService.deleteGeoRawFiles(filesetId).subscribe(
            () => {
              this.deleteFromFileset(filesetId);
              this.snackBar.open(
                this.translateService.t('File and content deleted'),
                this.translateService.t('Ok'),
                {
                  duration: 3000,
                }
              );
            },
            (error) => {
              this.snackBar.open(
                this.translateService.t('File could not be deleted'),
                this.translateService.t('Ok', error),
                {
                  duration: 3000,
                }
              );
            }
          );
        }
      });
  }

  downloadRawDataFileSet(filesetId: number, fileName: string): void {
    this.clientService.downloadRawFiles(filesetId).subscribe(
      (file: HttpResponse<Blob>) => this.handleDownloadResponse(file, fileName),
      () => this.handleDownloadError()
    );
  }

  reimportFile(filesetId: number): void {
    this.clientService.reimportFileSet(filesetId).subscribe(
      () => {
        this.snackBar.open(
          this.translateService.t('File has been reimported'),
          this.translateService.t('Ok'),
          {
            duration: 3000,
          }
        );
      },
      (error) => {
        this.snackBar.open(
          this.translateService.t('File could not be reimported'),
          this.translateService.t('Ok', error),
          {
            duration: 3000,
          }
        );
      }
    );
  }

  /* GEODATA ==========================================================================*/
  private initGeodata(): void {
    if (this.geodata$.value === null && !this.loadingGeodata$.value) {
      this.loadGeodata();
    }
  }

  private loadGeodata(): void {
    this.loadingGeodata$.next(true);
    this.clientService.getGeodataFiles().subscribe(
      (res) => {
        if (this.geodataErr$.value) {
          this.geodataErr$.next(null);
        }

        this.geodata$.next(
          res.sort((a, b) => {
            const acreated = a.metadata.find(
              (meta) => meta.key === GeoDataFileMetaTags.created
            );
            const bcreated = b.metadata.find(
              (meta) => meta.key === GeoDataFileMetaTags.created
            );
            const adate = acreated?.value ? new Date(acreated.value) : null;
            const bdate = bcreated?.value ? new Date(bcreated.value) : null;
            if (adate) {
              return adate < bdate ? 1 : -1;
            }
            return 1;
          })
        );
        this.loadingGeodata$.next(false);
      },
      (error) => {
        this.loadingGeodata$.next(false);
        this.geodataErr$.next(
          this.translateService.t(
            '_error_loading_geodata',
            '_Something went wrong. Please try again',
            error
          )
        );
      }
    );
  }

  getGeodata(): Observable<GeoDataFileModel[]> {
    this.initGeodata();

    return this.geodata$.asObservable();
  }

  private clearGeodata(): void {
    this.geodata$.next(null);
  }

  openGeodataMetadata(geodataId: number): void {
    const metaPrompt = this.dialogService.open(MetadataPromptComponent);
    const metadata = this.geodata$.value?.find(
      (d) => d.fileId === geodataId
    )?.metadata;

    const promptInstance = metaPrompt.componentInstance;
    promptInstance.addMetadata = true;
    promptInstance.metadata = metadata;

    promptInstance.save.subscribe((data) => {
      metaPrompt.close();
      this.clientService
        .updateGeodataMetadata(geodataId, data)
        .subscribe(() => {
          this.snackBar.open(
            this.translateService.t('Ändringar sparade'),
            this.translateService.t('Ok'),
            {
              duration: 3000,
            }
          );

          const newGeodata = [...this.geodata$.value];
          const idx = newGeodata.findIndex((d) => d.fileId === geodataId);
          newGeodata[idx].metadata = data;
          this.geodata$.next(newGeodata);
          const newFileset = [...this.fileset$.value];
          const fIdx = newFileset.findIndex(
            (f) => f.fileId === newGeodata[idx].rawFileId
          );
          const dIdx = newFileset[fIdx].dataFiles.findIndex(
            (d) => d.fileId === geodataId
          );
          newFileset[fIdx].dataFiles[dIdx] = newGeodata[idx];
          this.fileset$.next(newFileset);
        });
    });
  }

  openGeodataDetail(filesetId: number): void {
    this.clientService.clientId().subscribe((clientId) => {
      this.router.navigateByUrl(
        `client/${clientId}/data/detail/geodata/${filesetId}`
      );
    });
  }

  deleteFromGeodata(geodataId: number): void {
    const newGeodata = [...this.geodata$.value];
    const idx = newGeodata.findIndex((d) => d.fileId === geodataId);
    newGeodata.splice(idx, 1);
    this.geodata$.next(newGeodata);

    const newFileset = [...this.fileset$.value];
    const fIdx = newFileset.findIndex(
      (f) => f.fileId === newGeodata[idx].rawFileId
    );
    const dIdx = newFileset[fIdx].dataFiles.findIndex(
      (d) => d.fileId === geodataId
    );
    newFileset[fIdx].dataFiles.splice(dIdx, 1);
    this.fileset$.next(newFileset);
  }

  requestDeleteGeodata(geodataId: number): void {
    this.dialogService
      .confirm(
        this.translateService.t(
          '_confirm_delete_geodatafile',
          '_Are you sure you want to hide this data in CropMAP? This can only be undone by re-importing the file.'
        )
      )
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.clientService.deleteGeoDataFiles(geodataId).subscribe(
            () => {
              this.snackBar.open(
                this.translateService.t('Data deleted'),
                this.translateService.t('Ok'),
                {
                  duration: 3000,
                }
              );
              this.deleteFromGeodata(geodataId);
            },
            (error) => {
              this.snackBar.open(
                this.translateService.t('Data could not be deleted'),
                this.translateService.t('Ok', error),
                {
                  duration: 3000,
                }
              );
            }
          );
        }
      });
  }

  downloadFileByFormat(
    filesetId: number,
    fileName: string,
    format: FILE_FORMAT
  ): void {
    this.clientService.downloadFileByFormat(filesetId, format).subscribe(
      (file: HttpResponse<Blob>) =>
        this.handleDownloadResponse(file, fileName, format),
      () => this.handleDownloadError()
    );
  }

  handleDownloadResponse(
    file: HttpResponse<Blob>,
    fileName: string,
    format?: string
  ): void {
    const dlName: string = this.getFilenameFromHeaders(file, fileName, format);

    const link = document.createElement('a');
    link.setAttribute('style', 'display:none;');
    document.body.appendChild(link);
    link.download = dlName;
    link.href = URL.createObjectURL(file.body);
    link.target = '_blank';
    link.click();
    document.body.removeChild(link);
  }

  private handleDownloadError(): void {
    this.snackBar.open(this.translateService.t('Failed to download'), null, {
      duration: 5000,
    });
  }

  private getFilenameFromHeaders(
    response: HttpResponse<Blob>,
    defaultName: string,
    fileFormat?: string
  ): string {
    let fileName: string;
    try {
      const disp = response.headers.get('content-disposition');
      const regExp = /(?:filename=)(.+)(?:)/;
      fileName = decodeURI(regExp.exec(disp)[1]);
    } catch (err) {
      fileName = defaultName;
    }

    if (fileFormat) {
      const fileExtension = this.getFileExtension(fileFormat.toLowerCase());
      if (fileName.split('.').pop() !== fileExtension) {
        fileName += '.' + fileExtension;
      }
    }

    return fileName;
  }

  reimportGeoData(geodataId: number): void {
    this.clientService.reimportGeoData(geodataId).subscribe(
      () => {
        this.snackBar.open(
          this.translateService.t('Geodata has been reimported'),
          this.translateService.t('Ok'),
          {
            duration: 3000,
          }
        );
      },
      (error) => {
        this.snackBar.open(
          this.translateService.t('Geodata could not be reimported'),
          this.translateService.t('Ok', error),
          {
            duration: 3000,
          }
        );
      }
    );
  }

  /* FILTER STUFF ======================================================================= */
  private updateFilterChoices(): void {
    const filetypes = [];
    const customers = [];
    const farms = [];

    if (this.fileset$.value !== null) {
      this.fileset$.value.forEach((file) => {
        const customer = file.metadata.find(
          (d) => d.key === GeoDataFileMetaTags.customer
        )?.value;

        if (customer !== undefined && !customers.includes(customer)) {
          customers.push(customer);
        }

        const farm = file.metadata.find(
          (d) => d.key === GeoDataFileMetaTags.farm
        )?.value;

        if (farm !== undefined && !farms.includes(farm)) {
          farms.push(farm);
        }
      });
    }

    if (this.geodata$.value !== null) {
      this.geodata$.value.forEach((file) => {
        if (!filetypes.includes(file.fileType)) {
          filetypes.push(file.fileType);
        }

        const customer = file.metadata.find(
          (d) => d.key === GeoDataFileMetaTags.customer
        )?.value;

        if (customer !== undefined && !customers.includes(customer)) {
          customers.push(customer);
        }

        const farm = file.metadata.find(
          (d) => d.key === GeoDataFileMetaTags.farm
        )?.value;

        if (farm !== undefined && !farms.includes(farm)) {
          farms.push(farm);
        }
      });
    }

    this.filterService.availableCustomers$.next(customers.sort());
    this.filterService.availableFarms$.next(farms.sort());
    this.filterService.availableFileTypes$.next(filetypes.sort());
  }

  shareFiles(): void {
    this.notImplemented();
  }

  notImplemented(): void {
    this.snackBar.open(
      "Stay tuned, we're working on this",
      this.translateService.t('Ok'),
      {
        duration: 3000,
      }
    );
  }

  getFileExtension(exportType): string {
    switch (exportType) {
      case 'geojson':
        return 'json';
      case 'csv':
        return 'csv';
      default:
        return 'zip';
    }
  }
}

export enum FILE_FORMAT {
  SHAPE = 'shp',
  CSV = 'csv',
  GEOJSON = 'geojson',
}
