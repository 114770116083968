import { Component, OnDestroy, OnInit } from '@angular/core';
import * as turf from '@turf/turf';
import {
  LAYER_NAME_SKIFTEN,
  LAYER_SELECTED,
  MapService,
} from 'app/components/map/map.service';
import { AnalyzeFieldModel } from 'app/models/analyze-field.model';
import { PropertyInfoModel } from 'app/models/models';
import { AnalyzeService } from 'app/services/analyze.service';
import { ClientService } from 'app/services/client.service';
import { MapStateService } from 'app/services/map-state.service';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'dv-map-split-field-pick-page',
  templateUrl: './map-split-field-pick-page.component.html',
  styleUrls: ['./map-split-field-pick-page.component.scss'],
})
export class MapSplitFieldPickPageComponent implements OnInit, OnDestroy {
  private _unsub$ = new Subject<void>();
  mapName = 'fieldpick';
  skiften$: Subscription;
  map$: Subscription;
  selected$: Subscription;
  featuresSelected: boolean;
  interpolationProperties: PropertyInfoModel[];
  private currentYear: number = null;

  constructor(
    private analyseService: AnalyzeService,
    private clientService: ClientService,
    private mapService: MapService,
    private mapStateService: MapStateService
  ) {}

  ngOnInit(): void {
    this.analyseService.interpolationProperties$.subscribe(
      (properties) => (this.interpolationProperties = properties)
    );
    this.map$ = this.mapService.map(this.mapName).subscribe((dvMap) => {
      dvMap.onSelected.subscribe((f: google.maps.Data.Feature) => {
        this.mapStateService.setSelectedFeature(f);
      });

      dvMap.onDeSelected.subscribe((feature) => {
        this.mapStateService.unsetSelectedFeature(feature);
      });
    });

    this.selected$ = combineLatest([
      this.mapService.map(this.mapName),
      this.mapStateService.getSelectedFeatures(),
      this.clientService.getSkifteLayer(),
    ])
      .pipe(takeUntil(this._unsub$))
      .subscribe(([dvMap, selected, skiften]) => {
        if (!dvMap) {
          return;
        }

        const features = dvMap.getFeatures(LAYER_NAME_SKIFTEN);

        if (!this.currentYear && features && features.length > 0) {
          this.currentYear = features[0].getProperty('ar');
        }

        if (
          skiften &&
          skiften.features[0] &&
          this.currentYear != skiften.features[0].properties.ar
        ) {
          this.currentYear = skiften.features[0].properties.ar;
          dvMap.removeLayer(LAYER_NAME_SKIFTEN);
          dvMap.addGeoJson(skiften, LAYER_NAME_SKIFTEN);
        } else if (
          !features ||
          features.length == 0 ||
          this.currentYear != skiften.features[0].properties.ar
        ) {
          dvMap.addGeoJson(skiften, LAYER_NAME_SKIFTEN);
        }

        if (selected) {
          this.featuresSelected = true;
          selected.features.forEach((selected) => {
            features.forEach((f) => {
              //set transparancy to opaque
              if (f.getId() == selected.id) {
                f.setProperty(LAYER_SELECTED, true);
              }
            });
          });
        } else {
          dvMap.deSelectedFeatures(LAYER_NAME_SKIFTEN);
          dvMap.fitFeature(dvMap.getFeatures(LAYER_NAME_SKIFTEN), true);
          this.featuresSelected = false;
        }
      });
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  next() {
    const tFeatures: turf.Feature[] = [];
    this.mapStateService
      .getCurrentSelectedFeatures()
      ?.features.forEach((f: any) => {
        const geometry = {
          type: f.geometry.type,
          coordinates: f.geometry.coordinates,
        };
        const feature = turf.feature(geometry, f.properties);
        feature.id = f.id;
        tFeatures.push(feature);
      });
    const model = new AnalyzeFieldModel();
    model.feature = turf.featureCollection(tFeatures);
    this.analyseService.analyzeField(model);
  }
}
