import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';
import {
  InterpolationCollection,
  InterpolationModel,
  MappingLegendModel,
} from 'app/models/interpolation.model';
import { FieldInterpolationService } from 'app/services/field-interpolation.service';
import { MapStateService } from 'app/services/map-state.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dv-clay-content',
  templateUrl: 'clay-content.component.html',
  styleUrls: ['clay-content.component.scss'],
})
export class ClayContentComponent implements OnInit {
  @Input() fieldIds: number[] = [];
  @Input() clientId: number;

  currentInterpolation: InterpolationModel;
  legend: MappingLegendModel = null;
  interpolationLoading = false;
  error: string;
  private _unsub$ = new Subject<void>();

  constructor(
    private interpolationService: FieldInterpolationService,
    private mapStateSrv: MapStateService,
    private cd: ChangeDetectorRef,
    private translateSrv: DvToolbarTranslateService
  ) {}

  ngOnInit(): void {
    this.interpolate();
  }

  private interpolate(): void {
    this.interpolationLoading = true;
    this.interpolationService
      .getInterpolation(null, this.fieldIds)
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        (interpolation) => {
          if (
            !interpolation ||
            !interpolation.interpolations ||
            interpolation.interpolations.features?.length === 0
          ) {
            if (interpolation && interpolation['Message']) {
              this.parseInterpolationError(interpolation['Message']);
            } else {
              this.printError(
                this.translateSrv.t(
                  '_error_interpolation_nomatch_sgu',
                  [],
                  'No data could be found. Data is based clay values from Geological Survey of Sweden.'
                )
              );
            }
            return;
          }
          this.currentInterpolation = interpolation.interpolations;
          this.drawInterpolation(interpolation);
          this.error = undefined;
        },
        () => this.handleInterpolationOtherError()
      );
  }

  private drawInterpolation(interpolation: InterpolationCollection): void {
    this.mapStateSrv.setInterpolation(interpolation.interpolations);
    this.legend = interpolation.interpolations.legend;
    this.interpolationLoading = false;
    this.cd.markForCheck();
  }

  private handleInterpolationOtherError(): void {
    this.printError(
      this.translateSrv.t(
        '_error_interpolation failed',
        [],
        'Interpolation failed. Try deselecting files under advanced settings to pinpoint corrupt files'
      )
    );
  }

  private parseInterpolationError(message: string): void {
    const errCode = message.substring(0, message.indexOf('.'));
    const errMsg = message.substring(message.indexOf('.') + 2);
    this.printError(
      this.translateSrv.t('_error_interpolation_' + errCode, '_' + errMsg)
    );
  }

  private printError(errorMsg: string): void {
    this.error = errorMsg;
  }

  back(): void {
    this.mapStateSrv.clearInterpolation();
    this.interpolationService.resetInterpolationType();
  }
}
