import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { ImportModel } from 'app/models/import.model';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'dv-veris',
  templateUrl: './veris.component.html',
  styleUrls: ['./veris.component.scss', '../import-buttons.scss'],
})
export class VerisComponent implements AfterViewInit {
  @Input() importModel: ImportModel = null;
  @Output() importChange = new EventEmitter<ImportModel>();

  hasSelectedType = false;
  fileTypesMetadata: KeyValue<string, string> = {} as KeyValue<string, string>;
  canShowVerisLab = false;

  ngAfterViewInit(): void {
    this.fileTypesMetadata = this.importModel.metadata.find(
      (data) => data.key === 'fileTypes'
    );
  }

  files(files: File[]): void {
    if (files.length > 0) {
      this.importModel.type = 'veris';
      this.importModel.files = files;
      this.importModel.manufacture = 'veris';
      this.importChange.next(this.importModel);
    }
  }

  setManufacture(manufacture: string): void {
    this.importModel.type = 'veris';
    this.importModel.manufacture = manufacture;
    if (manufacture === 'veris') {
      this.importModel.addMetadata('fileTypes', '.csv');
    } else {
      this.importModel.addMetadata('fileTypes', '.zip');
    }
    this.hasSelectedType = true;
  }

  fileSelected(files: File[]): void {
    this.importModel.files = [];
    for (const file of files) {
      this.importModel.files.push(file);
    }

    this.importChange.next(this.importModel);
  }
}
