import {
    animate,
    state,
    style,
    transition,
    trigger
} from "@angular/animations";

export const filterGrowAnimation = trigger('filterGrowAnimation', [
    state('minimized, void', style({
        height: '0px',
        opacity: 0,
    })),
    state('maximized', style({
        height: '*',
        opacity: 1,
    })),
    transition(
        'minimized <=> maximized, void <=> *',
        animate('225ms ease-in'),
    ),
]);
