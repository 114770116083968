import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AboutComponent } from 'app/about/about.component';
import { BrukEnhDialogComponent } from 'app/bruk-enh-dialog/bruk-enh-dialog.component';
import { MissingSkifteModel } from 'app/models/missingSkifteModel';
import { ClientService } from 'app/services/client.service';
import { SiteService } from 'app/services/site.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImportService } from '../import-view/import.service';
import { InsufficientRightsDialogComponent } from '../insufficient-rights-dialog/insufficient-rights-dialog.component';
import { DvAuthService, DvToolbarTranslateService } from '@dv/toolbar-msal';
import { APP_PATH } from 'app/app.types';

@Component({
  selector: 'dv-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  private _unsub$: Subject<void> = new Subject<void>();
  farmStyle = `
    width: 24px;
    height: 24px;
    margin-right: 16px;
    vertical-align: middle;
  `;
  hasCropMapAdvanced = false;
  isAgrometsius = false;
  showLayers = false;
  selectedYear: number;
  missingSkiften: MissingSkifteModel;
  APP_PATH = APP_PATH;

  constructor(
    public siteService: SiteService,
    private dialog: MatDialog,
    private translate: DvToolbarTranslateService,
    private clientSrv: ClientService,
    private importService: ImportService,
    private dvAuthService: DvAuthService
  ) {}

  ngOnInit(): void {
    this.siteService
      .hasCropMapAdvanced()
      .pipe(takeUntil(this._unsub$))
      .subscribe((hasCropmapAdvanced) => {
        this.hasCropMapAdvanced = hasCropmapAdvanced;
      });
    this.clientSrv
      .isAgrometsius()
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        this.isAgrometsius = res;
      });
    this.clientSrv
      .clientAr()
      .pipe(takeUntil(this._unsub$))
      .subscribe((ar) => {
        this.selectedYear = ar;
        this.loadMissingSkifte();
      });
  }

  about(): void {
    this.dialog.open(AboutComponent);
  }

  logout(): void {
    this.dvAuthService.signOut();
  }

  brukEnh(): void {
    const conf = new MatDialogConfig();

    conf.width = '450px';
    conf.height = '500px';

    this.dialog.open(BrukEnhDialogComponent, conf);
  }

  openMissingRightsDialog(): void {
    if (!this.hasCropMapAdvanced) {
      const conf = {
        width: '800px',
        data: { functionName: this.translate.t('Analyze') },
        panelClass: 'insufficient-dialog',
      };
      this.dialog.open(InsufficientRightsDialogComponent, conf);
    }
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  private loadMissingSkifte(): void {
    this.clientSrv
      .missingSkifteCount(this.selectedYear)
      .pipe(takeUntil(this._unsub$))
      .subscribe((skifteCount) => {
        this.missingSkiften = skifteCount;
      });
  }

  importFields(): void {
    this.importService
      .tryOpenImportDone(this.selectedYear, true)
      .afterClosed()
      .subscribe(() => {
        this.clientSrv.setClientAr(this.selectedYear);
        this.loadMissingSkifte();
      });
  }
}
