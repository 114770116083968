import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MapComponent } from 'app/components/map/map.component';
import {
  LAYER_NAME_INTERPOLATION,
  LAYER_NAME_SKIFTEN,
  LAYER_NAME_VALUEPOINT,
  MapService,
} from 'app/components/map/map.service';
import { AnalyzeFieldModel } from 'app/models/analyze-field.model';
import { MappingLegendModel } from 'app/models/interpolation.model';
import { PropertyInfoModel } from 'app/models/models';
import { AnalyzeService, SplitMode } from 'app/services/analyze.service';
import { ClientService } from 'app/services/client.service';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  takeUntil,
} from 'rxjs/operators';
import {
  InterpolationChange,
  PointLabelChange,
} from './map-split-prop-picker/map-split-prop-picker.types';
import { MapUtilService, ValuePointModel } from 'app/services/map-util.service';
import { MapName, PointClusterDictionary } from './map-split-page.types';

@Component({
  selector: 'dv-map-split-page',
  templateUrl: './map-split-page.component.html',
  styleUrls: ['./map-split-page.component.scss'],
})
export class MapSplitPageComponent implements OnInit, OnDestroy {
  private _unsub$: Subject<void> = new Subject<void>();
  mapSub$: Subscription;

  map1: MapComponent;
  map2: MapComponent;
  map3: MapComponent;
  map4: MapComponent;

  legend1: MappingLegendModel;
  legend2: MappingLegendModel;
  legend3: MappingLegendModel;
  legend4: MappingLegendModel;

  mapPanSub = new Subject<google.maps.LatLng>();
  mapZoomSub = new Subject<number>();

  splitMode: SplitMode = SplitMode.Two;
  splitModeEnum = SplitMode;
  fieldToAnalyze: AnalyzeFieldModel = new AnalyzeFieldModel();
  interpolationProperties: PropertyInfoModel[];

  map1Name: MapName = 'analyzeMap1';
  map2Name: MapName = 'analyzeMap2';
  map3Name: MapName = 'analyzeMap3';
  map4Name: MapName = 'analyzeMap4';

  private mapClickHandlers: google.maps.MapsEventListener[] = [];
  private mapMarkers: google.maps.Marker[] = [];
  private pointClusters: PointClusterDictionary = {
    analyzeMap1: null,
    analyzeMap2: null,
    analyzeMap3: null,
    analyzeMap4: null,
  };

  constructor(
    private analyseService: AnalyzeService,
    private clientService: ClientService,
    private router: Router,
    private mapService: MapService,
    private mapUtilService: MapUtilService
  ) {}

  ngOnInit(): void {
    this.analyseService.interpolationProperties$.subscribe(
      (properties) => (this.interpolationProperties = properties)
    );
    this.listenToSplitMode();
    this.getFieldToAnalyze();
    this.listenToMapPan();
    this.listenToMapZoom();
  }

  ngOnDestroy(): void {
    if (this.mapSub$) {
      this.mapSub$.unsubscribe();
    }
    this.map1 = null;
    this.map2 = null;
    this.map3 = null;
    this.map4 = null;

    this._unsub$.next();
    this._unsub$.complete();
  }

  private listenToSplitMode(): void {
    this.analyseService
      .getSplitMode()
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        this.splitMode = res;
        if (this.splitMode === SplitMode.Four) {
          this.initMap(this.map3);
          this.initMap(this.map4);
        }
        window.setTimeout(() => {
          this.fitFeature(this.map1, true);
        }, 1);
      });
  }

  private getFieldToAnalyze(): void {
    this.analyseService
      .getFieldToAnalyze()
      .pipe(first())
      .subscribe((model) => {
        if (!model || !model.feature) {
          this.clientService
            .clientId()
            .pipe(takeUntil(this._unsub$))
            .subscribe((clientId) => {
              this.router.navigateByUrl('client/' + clientId + '/analyze/pick');
            });
        } else {
          this.fieldToAnalyze = model;
          this.initMaps();
        }
      });
  }

  onPlaceMarkerMode(placeMarker: boolean): void {
    if (placeMarker) {
      this.addClickHandlers();
    } else {
      this.removeClickHandlers();
      this.removeMapMarkers();
      this.setMapsDefaultCursor();
    }
  }

  private addClickHandlers(): void {
    this.addClickHandler(this.map1.map);
    this.addClickHandler(this.map2.map);
    this.addClickHandler(this.map3.map);
    this.addClickHandler(this.map4.map);
  }

  private addClickHandler(map: google.maps.Map<Element>): void {
    this.mapClickHandlers.push(
      google.maps.event.addListener(
        map,
        'click',
        (event: google.maps.MapMouseEvent) => {
          this.addMarkerToMaps(event.latLng);
          this.removeClickHandlers();
          this.setMapsDefaultCursor();
        }
      )
    );
    map.setOptions({ draggableCursor: 'crosshair' });
  }

  private setMapsDefaultCursor(): void {
    this.setMapDefaultCursor(this.map1.map);
    this.setMapDefaultCursor(this.map2.map);
    this.setMapDefaultCursor(this.map3.map);
    this.setMapDefaultCursor(this.map4.map);
  }

  private setMapDefaultCursor(map: google.maps.Map<Element>): void {
    map.setOptions({ draggableCursor: null });
  }

  private addMarkerToMaps(position: google.maps.LatLng): void {
    this.addMarkerToMap(this.map1.map, position);
    this.addMarkerToMap(this.map2.map, position);
    this.addMarkerToMap(this.map3.map, position);
    this.addMarkerToMap(this.map4.map, position);
  }

  private addMarkerToMap(
    map: google.maps.Map<Element>,
    position: google.maps.LatLng
  ): void {
    this.mapMarkers.push(
      new google.maps.Marker({
        map,
        position,
      })
    );
  }

  private removeClickHandlers(): void {
    if (this.mapClickHandlers?.length) {
      this.mapClickHandlers.forEach((handler) => handler.remove());
      this.mapClickHandlers = [];
    }
  }

  private removeMapMarkers(): void {
    if (this.mapMarkers?.length) {
      this.mapMarkers.forEach((marker) => marker.setMap(null));
      this.mapMarkers = [];
    }
  }

  private listenToMapPan(): void {
    this.mapPanSub
      .pipe(distinctUntilChanged(), debounceTime(100))
      .pipe(takeUntil(this._unsub$))
      .subscribe((center: google.maps.LatLng) => {
        this.map1.map.setCenter(center);
        this.map2.map.setCenter(center);
        this.map3.map.setCenter(center);
        this.map4.map.setCenter(center);
      });
  }

  private listenToMapZoom(): void {
    this.mapZoomSub
      .pipe(distinctUntilChanged())
      .pipe(takeUntil(this._unsub$))
      .subscribe((zoom: number) => {
        this.map1.map.setZoom(zoom);
        this.map2.map.setZoom(zoom);
        this.map3.map.setZoom(zoom);
        this.map4.map.setZoom(zoom);
      });
  }

  initMaps(): void {
    const arr: Observable<MapComponent>[] = [];

    arr.push(
      this.mapService.map(this.map1Name),
      this.mapService.map(this.map2Name),
      this.mapService.map(this.map3Name),
      this.mapService.map(this.map4Name)
    );

    this.mapSub$ = combineLatest(arr).subscribe((maps) => {
      this.map1 = maps[0];
      this.map2 = maps[1];
      this.map3 = maps[2];
      this.map4 = maps[3];

      this.initMap(this.map1);
      this.initMap(this.map2);
      if (this.splitMode === SplitMode.Four) {
        this.initMap(this.map3);
        this.initMap(this.map4);
      }

      const opts: google.maps.MapOptions = {
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
      };

      this.map1.map.setOptions(opts);
      this.map2.map.setOptions(opts);
      this.map3.map.setOptions(opts);
      this.map4.map.setOptions(opts);
    });
  }

  private initMap(map: MapComponent): void {
    if (!map) {
      return;
    }

    map.disableSelect = true;
    map.removeLayer(LAYER_NAME_SKIFTEN);
    map.addGeoJson(this.fieldToAnalyze.feature, LAYER_NAME_SKIFTEN);
    this.fitFeature(map, true);
    google.maps.event.addListener(map.map, 'center_changed', () => {
      this.mapPanSub.next(map.map.getCenter());
    });

    google.maps.event.addListener(map.map, 'zoom_changed', () => {
      this.mapZoomSub.next(map.map.getZoom());
    });
  }

  private fitFeature(map: MapComponent, onlyIfOutside = false): void {
    if (map) {
      const features = map.getFeatures(LAYER_NAME_SKIFTEN);
      map.fitFeature(features, onlyIfOutside);
    }
  }

  private setLegend(mapName: MapName, legend: MappingLegendModel): void {
    switch (mapName) {
      case this.map1Name:
        this.legend1 = legend;
        break;
      case this.map2Name:
        this.legend2 = legend;
        break;
      case this.map3Name:
        this.legend3 = legend;
        break;
      case this.map4Name:
        this.legend4 = legend;
        break;
    }
  }

  interpolationChanged(event: InterpolationChange, mapName: MapName): void {
    this.mapService
      .map(mapName)
      .pipe(first())
      .subscribe((map) => {
        map.removeLayer(LAYER_NAME_INTERPOLATION);
        map.removeLayer(LAYER_NAME_VALUEPOINT);

        if (event.interpolation) {
          map.addGeoJson(
            event.interpolation,
            event.pointMode ? LAYER_NAME_VALUEPOINT : LAYER_NAME_INTERPOLATION
          );
        } else {
          this.hideValuePoints(mapName);
        }
      });

    this.setLegend(mapName, event.interpolation?.legend);
  }

  private hideValuePoints(mapName: MapName): void {
    const cluster = this.pointClusters[mapName];
    if (cluster) {
      cluster.clearMarkers();
    }
  }

  onPointLabelChange(event: PointLabelChange, mapName: MapName): void {
    this.hideValuePoints(mapName);

    if (!event?.labelMode) {
      return;
    }

    let points: ValuePointModel[] = this.mapUtilService.getValuePoints(
      event.points,
      event.propertyKey
    );

    points = this.mapUtilService.getValuePointsInsideInterpolation(
      points,
      event.field.features
    );

    this.drawValuePointLabels(points, mapName);
  }

  private drawValuePointLabels(
    points: ValuePointModel[],
    mapName: MapName
  ): void {
    const datapoints = this.mapUtilService.getValuePointLabels(points);
    this.pointClusters[mapName] = this.mapUtilService.drawValuePointLabelsOnMap(
      datapoints,
      this.getMapComponent(mapName).map
    );
  }

  private getMapComponent(mapName: MapName): MapComponent {
    switch (mapName) {
      case this.map1Name:
        return this.map1;
      case this.map2Name:
        return this.map2;
      case this.map3Name:
        return this.map3;
      case this.map4Name:
        return this.map4;
    }
  }
}
