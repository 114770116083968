import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  LegendDataRow,
  LEGEND_VALUE_COLORS,
  LEGEND_COLUMN,
} from './adjustments-legend.types';
import { PrescriptionModel } from 'app/models/models';
import { DecimalPipe } from '@angular/common';
import {
  ALTERNATIVE_SEED_UNIT,
  DEFAULT_SEED_TOTAL_UNIT,
  DEFAULT_SEED_UNIT,
} from '../../step-adjust.types';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-adjustments-legend',
  templateUrl: 'adjustments-legend.component.html',
  styleUrls: ['adjustments-legend.component.scss'],
})
export class AdjustmentsLegendComponent implements OnChanges {
  @Input() totalArea?: number;
  @Input() grid?: PrescriptionModel;
  private _seedUnit = DEFAULT_SEED_UNIT;
  @Input() set seedUnit(value: string) {
    if (value) {
      this._seedUnit = value;
      this.seedUnitTotal = value.split('/')[0];
    }
  }
  get seedUnit(): string {
    return this._seedUnit;
  }
  seedUnitTotal = DEFAULT_SEED_TOTAL_UNIT;
  dataSource: MatTableDataSource<LegendDataRow>;
  displayedColumns = Object.values(LEGEND_COLUMN);
  LEGEND_COLUMN = LEGEND_COLUMN;
  LEGEND_VALUE_COLORS = LEGEND_VALUE_COLORS;
  private readonly TWO_DECIMAL_THRESHOLD = 10;
  private readonly ONE_DECIMAL_THRESHOLD = 100;
  private readonly ONE_HUNDRED_PROCENT = 100;
  private readonly HECTAR = 10000;

  constructor(
    private translate: DvToolbarTranslateService,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.grid?.currentValue) {
      this.dataSource = new MatTableDataSource(
        this.gridToLegendData(changes.grid.currentValue)
      );
    }
  }

  private gridToLegendData(gridData: PrescriptionModel): LegendDataRow[] {
    const percentage = gridData.content / 100;

    if (this.seedUnit === ALTERNATIVE_SEED_UNIT) {
      gridData.total = gridData.total * this.HECTAR;
    }

    const baseRow = this.getRow(gridData, this.translate.t('Product'), 1, true);

    if (gridData.content === this.ONE_HUNDRED_PROCENT) {
      return [baseRow];
    }

    const nutritionName = `${this.translate.t('Nutrition')} 1 (${
      gridData.content
    }%)`;

    const row = this.getRow(gridData, nutritionName, percentage);

    return [row, baseRow];
  }

  private getRow(
    data: PrescriptionModel,
    name: string,
    percentage = 1,
    isBase = false
  ): LegendDataRow {
    return {
      average: this.formatNumber(data.average * percentage),
      isBase,
      max: this.formatNumber(data.maxRate * percentage),
      min: this.formatNumber(data.minRate * percentage),
      name,
      total: this.formatNumber(data.total * percentage),
    };
  }

  private formatNumber(value: number): string {
    let digitsInfo: string;

    if (
      value < this.ONE_DECIMAL_THRESHOLD &&
      value >= this.TWO_DECIMAL_THRESHOLD
    ) {
      digitsInfo = '1.1-1';
    } else if (value < this.TWO_DECIMAL_THRESHOLD && value > 0) {
      digitsInfo = '1.2-2';
    } else {
      digitsInfo = '1.0-0';
    }

    return this.decimalPipe.transform(value, digitsInfo);
  }
}
