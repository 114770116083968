import { InterpolationModel } from 'app/models/interpolation.model';
import { FieldGridModel } from 'app/models/models';

export interface FieldGridModelWithYear extends FieldGridModel {
  year: number;
}

export class Group {
  name: string;
  displayName: string;
  icon: string;
  type: string;
}

export class Field {
  name: string;
  displayName: string;
}

export class File {
  fileName: string;
  geoDataId: number;
  checked: boolean;
  created: string;
  year: number;
}

export interface InterpolationChange {
  interpolation: InterpolationModel;
  pointMode: boolean;
}

export interface PointLabelChange {
  points: GeoJSON.FeatureCollection;
  field: GeoJSON.FeatureCollection;
  labelMode: boolean;
  propertyKey: string;
}
