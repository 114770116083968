import { Injectable } from '@angular/core';
import { ReplaySubject, from, BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { KommunModel } from 'app/models/api.models';
import { first, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private kommunerSub$ = new BehaviorSubject<KommunModel[]>([]);

  constructor(private http: HttpClient) {

  }

  getKommunerNorge(): Observable<KommunModel[]> {
    // Lazy load
    if (this.kommunerSub$.value.length === 0) {
        this.loadKommunerNorge();
    }

    return this.kommunerSub$.asObservable();
}

loadKommunerNorge(): void {
    this.http.get<KommunModel[]>((environment.baseApiUrl + 'utility/kommun/norge'))
        .subscribe(res => {
            this.kommunerSub$.next(res);
        });
}



}
