import { KeyValue } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { ImportModel } from 'app/models/import.model';
import { ClientService } from 'app/services/client.service';

@Component({
  selector: 'dv-asapplied',
  templateUrl: './asapplied.component.html',
  styleUrls: ['./asapplied.component.scss', '../import-buttons.scss'],
})
export class AsappliedComponent implements AfterViewInit {
  @Input() importModel: ImportModel = null;
  @Output() importChange = new EventEmitter<ImportModel>();
  hasSelectedType = false;
  fileTypesMetadata: KeyValue<string, string> = {} as KeyValue<string, string>;

  constructor(private clientService: ClientService) {}

  ngAfterViewInit(): void {
    this.fileTypesMetadata = this.importModel.metadata.find(
      (data) => data.key === 'fileTypes'
    );
  }

  setManufacture(manufacture, forceNext = false): void {
    this.importModel.manufacture = manufacture;
    switch (manufacture) {
      case 'johndeereshape':
        this.setFileTypes('.zip');
        this.hasSelectedType = true;
        this.importModel.addMetadata('Created', new Date().toISOString());
        break;
      default:
          this.setFileTypes('.zip');
          this.hasSelectedType = true;
          break;
    }
    if (forceNext) {
      this.hasSelectedType = true;
    }
  }

  private setFileTypes(types: string): void {
    this.importModel.addMetadata('fileTypes', types);
  }

  setManufactureVersion(version: string): void {
    this.importModel.addMetadata('tag', version);
    this.importChange.next(this.importModel);
  }

  fileSelected(files: File[]): void {
    this.importModel.files = [];
    for (const file of files) {
      this.importModel.files.push(file);
    }

    this.importChange.next(this.importModel);
  }
}
