import { Injectable } from '@angular/core';
import { InterpolationCollection } from 'app/models/interpolation.model';
import { Observable, Subject, Subscription, forkJoin } from 'rxjs';
import {
  FunctionGeoDataDefinition,
  FunctionGeographicDefinition,
  FunctionInput,
  FunctionNestedDefinition,
  FunctionParameterDefinition,
  FunctionParameterWithType,
  NestedValue,
  PARAMETER_IDS,
  PARAMETER_TYPES,
  SHAPE_TYPE,
  TargetPhInput,
} from '../components/step-settings/step-settings.types';
import { PrescriptionWizardService } from '../prescription-wizard.service';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';
import { ClientService } from 'app/services/client.service';
import { ApiError, ErrorResponse } from 'app/models/models';
import { MapStateService } from 'app/services/map-state.service';
import { FunctionModel } from 'app/models/api.models';

@Injectable({ providedIn: 'root' })
export class PrescriptionWizardInterpolationService {
  private interpolation = new Subject<InterpolationCollection>();
  private clearInterpolation = new Subject<void>();
  private interpolationError = new Subject<ApiError>();
  private interpolationLoading = new Subject<boolean>();
  interpolation$ = this.interpolation.asObservable();
  clearInterpolation$ = this.clearInterpolation.asObservable();
  interpolationError$ = this.interpolationError.asObservable();
  interpolationLoading$ = this.interpolationLoading.asObservable();
  private interpolationSubscription: Subscription;

  constructor(
    private clientService: ClientService,
    private mapStateService: MapStateService,
    private wizardService: PrescriptionWizardService
  ) {}

  interpolate(
    parameter?: FunctionParameterWithType<
      | FunctionGeoDataDefinition
      | FunctionNestedDefinition
      | FunctionGeographicDefinition
    >
  ): void {
    this.clearInterpolation.next();
    this.unsubscribeFromInterpolation();

    if (!parameter || parameter.useFixedValue) {
      return;
    }

    if (parameter && parameter.parameterType === PARAMETER_TYPES.TARGET_PH) {
      this.interpolateTargetPh();
    }
    if (parameter.parameterType === PARAMETER_TYPES.GEO_DATA) {
      this.interpolateGeoData(
        parameter as FunctionParameterWithType<FunctionGeoDataDefinition>
      );
    } else if (parameter.parameterType === PARAMETER_TYPES.NESTED) {
      this.determineNestedInterpolationType(parameter);
    }
  }

  private determineNestedInterpolationType(
    parameter: FunctionParameterWithType<
      | FunctionNestedDefinition
      | FunctionGeographicDefinition
      | FunctionGeoDataDefinition
    >
  ): void {
    const keys = Object.keys(parameter.value).filter(
      (key) => !isNaN(Number(key))
    );
    const key = keys[0];

    if (Number(key) === PARAMETER_IDS.KHCL) {
      this.interpolateKhcl(
        parameter as FunctionParameterWithType<FunctionNestedDefinition>
      );
    } else if (Array.isArray(parameter.value[key])) {
      this.interpolateNestedGeoData(
        parameter as FunctionParameterWithType<FunctionNestedDefinition>
      );
    } else {
      this.interpolateGeographic();
    }
  }

  private interpolateKhcl(
    parameter: FunctionParameterWithType<FunctionNestedDefinition>
  ): void {
    const fileIds = parameter.value[PARAMETER_IDS.KHCL];

    this.interpolationSubscription = this.getSelectedFieldId()
      .pipe(
        switchMap((fieldId) =>
          this.clientService.getKhclInterpolation(fieldId, fileIds)
        )
      )
      .subscribe(
        (interpolation) => this.interpolation.next(interpolation),
        ({ error }: ErrorResponse) => this.interpolationError.next(error)
      );
  }

  removeInterpolation(): void {
    this.clearInterpolation.next();
  }

  unsubscribeFromInterpolation(): void {
    if (
      this.interpolationSubscription &&
      !this.interpolationSubscription.closed
    ) {
      this.interpolationSubscription.unsubscribe();
    }
  }

  private getSelectedFieldId(): Observable<number> {
    return this.wizardService.selectedSkifte$.pipe(
      take(1),
      map((skifte) => skifte.id)
    );
  }

  private interpolateGeographic(): void {
    this.interpolationLoading.next(true);

    this.interpolationSubscription = this.getSelectedFieldId()
      .pipe(
        mergeMap((fieldId) => this.clientService.getSguInterpolation([fieldId]))
      )
      .subscribe(
        (interpolation) => this.interpolation.next(interpolation),
        ({ error }: ErrorResponse) => this.interpolationError.next(error)
      );
  }

  private interpolateNestedGeoData(
    value: FunctionParameterWithType<FunctionNestedDefinition>
  ): void {
    const selectedId = Number(
      Object.keys(value.value).find((key) => key !== 'length')
    );
    const selectedParam = value.definition.nestedParameter.find(
      (param) => param.id === selectedId
    );

    const hasSelectedGeoData = value.value[selectedId]?.length;

    if (!hasSelectedGeoData) {
      return;
    }

    this.interpolationLoading.next(true);

    this.interpolationSubscription = this.mapStateService
      .getSelectedFeatures()
      .pipe(
        take(1),
        mergeMap((features) => {
          return this.clientService.getGeoDataInterpolation(
            selectedParam.definition.geoDataType,
            selectedParam.definition.property,
            features,
            value.value[selectedId],
            value.asIndex
          );
        })
      )
      .subscribe(
        (interpolation) => this.interpolation.next(interpolation),
        ({ error }: ErrorResponse) => this.interpolationError.next(error)
      );
  }

  private interpolateGeoData(
    parameter: FunctionParameterWithType<FunctionGeoDataDefinition>
  ): void {
    if (!(parameter?.value as number[])?.length) {
      return;
    }

    this.interpolationLoading.next(true);

    this.interpolationSubscription = this.mapStateService
      .getSelectedFeatures()
      .pipe(
        take(1),
        mergeMap((features) => {
          return this.clientService.getGeoDataInterpolation(
            parameter.definition.geoDataType,
            parameter.definition.property,
            features,
            parameter.value as number[],
            parameter.asIndex
          );
        })
      )
      .subscribe(
        (interpolation) => this.interpolation.next(interpolation),
        ({ error }: ErrorResponse) => this.interpolationError.next(error)
      );
  }

  private interpolateTargetPh(): void {
    this.interpolationLoading.next(true);
    this.interpolationSubscription = forkJoin({
      selectedFunction: this.wizardService.selectedFunction$.pipe(take(1)),
      selectedSkifte: this.wizardService.selectedSkifte$.pipe(take(1)),
    })
      .pipe(
        switchMap(({ selectedFunction, selectedSkifte }) => {
          const body: TargetPhInput = {
            functionInput: this.getFunctions(selectedFunction),
            shapeType: SHAPE_TYPE.CONTOUR,
          };
          return this.clientService.getTargetPhInterpolation(
            selectedSkifte.id,
            body
          );
        })
      )
      .subscribe(
        (interpolation) => this.interpolation.next(interpolation),
        ({ error }: ErrorResponse) => this.interpolationError.next(error)
      );
  }

  private getFunctions(selectedFunction: FunctionModel): FunctionInput {
    const funcInput: FunctionInput = {
      functionId: 3,
      parameters: {
        [PARAMETER_IDS.PH]: this.getValueFromParameters<number[]>(
          PARAMETER_IDS.PH,
          [],
          selectedFunction
        ),
        [PARAMETER_IDS.HUMUS_CONTENT]: this.getValueFromParameters<number[]>(
          PARAMETER_IDS.HUMUS_CONTENT,
          [],
          selectedFunction
        ),
        [PARAMETER_IDS.ADJUST_PH]: this.getValueFromParameters<number>(
          PARAMETER_IDS.ADJUST_PH,
          0,
          selectedFunction
        ),
        [PARAMETER_IDS.NESTED_CLAY_CONTENT]: this.getClayValueFromParameters(
          PARAMETER_IDS.NESTED_CLAY_CONTENT,
          selectedFunction
        ),
      },
    };
    return funcInput;
  }

  private getValueFromParameters<T>(
    id: number,
    defaultValue: T,
    selectedFunction: FunctionModel
  ): T {
    const parameters =
      selectedFunction.parameters as FunctionParameterWithType<FunctionParameterDefinition>[];
    const parameter = parameters.find((param) => param.id === id);
    const value = (parameter?.value as T) ?? defaultValue;
    return value;
  }

  private getClayValueFromParameters(
    id: number,
    selectedFunction: FunctionModel
  ): NestedValue {
    const parameters =
      selectedFunction.parameters as FunctionParameterWithType<FunctionParameterDefinition>[];
    const parameter = parameters.find((param) => param.id === id);
    const nestedValue = parameter?.value as NestedValue;

    // Fix to get rid of the 'lenght' attribute, for when object is an array
    const filteredNestedValue = Array.isArray(nestedValue)
      ? [...nestedValue]
      : { ...nestedValue };
    if ('length' in filteredNestedValue) {
      delete filteredNestedValue.length;
    }

    return filteredNestedValue;
  }
}
