import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FunctionNumberInputDefinition,
  FunctionOptionInputDefinition,
  FunctionParameterDefinition,
  FunctionParameterWithType,
  FunctionSelectInputDefinition,
  PARAMETER_IDS,
  PARAMETER_TYPES,
} from '../../step-settings.types';
import { PhChangeEvent } from './components/ph-input/ph-input.types';
import { AdjustPhValueChange } from './input-section.types';
import { NgFor, NgIf } from '@angular/common';
import {
  DvmCheckboxModule,
  DvmInputModule,
  DvmSelectModule,
  DvmTextModule,
  DvmTooltipDirective,
} from '@dvm/components';
import { PhInputComponent } from 'app/views/prescription-wizard-page/components/step-settings/components/input-section/components/ph-input/ph-input.component';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-input-section',
  templateUrl: 'input-section.component.html',
  styleUrls: ['input-section.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    DvmCheckboxModule,
    DvmInputModule,
    DvmSelectModule,
    DvmTextModule,
    DvmTooltipDirective,
    PhInputComponent,
  ],
})
export class InputSectionComponent implements OnInit, OnChanges {
  @Input() inputs: FunctionParameterWithType<FunctionParameterDefinition>[] =
    [];
  @Input()
  targetPhParameter: FunctionParameterWithType<FunctionParameterDefinition>;
  @Output() adjustPhValueChange = new EventEmitter<AdjustPhValueChange>();
  @Output() valid = new EventEmitter<boolean>();

  PARAMETER_TYPES = PARAMETER_TYPES;
  PARAMETER_IDS = PARAMETER_IDS;

  constructor(private translateService: DvToolbarTranslateService) {}

  ngOnInit(): void {
    this.checkValidity();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputs) {
      this.inputs.forEach((input) => {
        if (input.parameterType === PARAMETER_TYPES.SELECT) {
          const selectDefinition =
            input.definition as FunctionSelectInputDefinition;
          selectDefinition.mappedOptions = selectDefinition.options.map(
            (option) => ({
              checked: option.id === selectDefinition.selected,
              disabled: false,
              key: String(option.id),
              label: option.name,
            })
          );
        }
      });
    }
  }

  onValueChange(
    value: string,
    input: FunctionParameterWithType<FunctionParameterDefinition>,
    badInput?: boolean
  ): void {
    input.touched = true;
    input.value = value ? (isNaN(Number(value)) ? value : Number(value)) : '';
    input.badInput = badInput;

    if (input.id === PARAMETER_IDS.CROP_SELECTED) {
      this.handleCropSelectedValueChange(value, input);
    }

    this.checkValidity();
  }

  optionValueChange(
    event: HTMLInputElement,
    input: FunctionParameterWithType<FunctionOptionInputDefinition>
  ): void {
    input.value = event.checked;
    this.checkValidity();
  }

  onPhValueChange(event: PhChangeEvent): void {
    if (event.parameterId === PARAMETER_IDS.ADJUST_PH && event.isValid) {
      this.adjustPhValueChange.emit({
        clearInterpolation: false,
        parameterId: event.parameterId,
      });
    } else {
      this.adjustPhValueChange.emit({
        clearInterpolation: true,
        parameterId: event.parameterId,
      });
    }

    if (!event.isValid) {
      this.valid.emit(false);
    } else {
      this.checkValidity();
    }
  }

  private checkValidity(): void {
    const invalidInputs = this.inputs.filter((input) => {
      if (input.type === 'number' && input.touched) {
        this.validateNumericValue(input);
      }

      return (
        input.errorMessage ||
        (!input.optional &&
          (input.value === null ||
            input.value === undefined ||
            input.value === ''))
      );
    });

    const isValid = !invalidInputs.length;

    this.valid.emit(isValid);
  }

  private handleCropSelectedValueChange(
    value: string,
    input: FunctionParameterWithType<FunctionParameterDefinition>
  ): void {
    const selectInput =
      input as FunctionParameterWithType<FunctionSelectInputDefinition>;
    const expectedYield = selectInput.definition.options.find(
      (option) => option.id === Number(value)
    )?.value;

    this.inputs.find(
      (element) => element.id === PARAMETER_IDS.EXPECTED_YIELD
    ).value = expectedYield;
  }

  private validateNumericValue(
    input: FunctionParameterWithType<FunctionParameterDefinition>
  ): void {
    const { minLimit, maxLimit } =
      input.definition as FunctionNumberInputDefinition;
    const value = input.value ? Number(input.value) : null;

    if (input.badInput) {
      input.errorMessage = this.translateService.t('Invalid format');
    } else if (!input.optional && value === null) {
      input.errorMessage = this.translateService.t('Required field');
    } else if (
      minLimit !== undefined &&
      minLimit !== null &&
      value < minLimit
    ) {
      input.errorMessage = `${this.translateService.t(
        'Minimum allowed value'
      )}: ${minLimit}`;
    } else if (
      maxLimit !== undefined &&
      maxLimit !== null &&
      value > maxLimit
    ) {
      input.errorMessage = `${this.translateService.t(
        'Maximum allowed value'
      )}: ${maxLimit}`;
    } else {
      input.errorMessage = '';
    }
  }
}
