import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { SkifteDetielsModel } from 'app/models/api.models';
import { MapComponent } from 'app/components/map/map.component';
import {
  LAYER_NAME_INTERPOLATION,
  LAYER_NAME_SKIFTEN,
  LAYER_PROPS,
  MapService,
} from 'app/components/map/map.service';
import { FunctionModel } from 'app/models/api.models';
import { ClientService } from 'app/services/client.service';
import { Observable, Subject, Subscription, forkJoin } from 'rxjs';
import {
  takeUntil,
  map,
  tap,
  mergeMap,
  take,
  delay,
  switchMap,
} from 'rxjs/operators';
import { PrescriptionWizardService } from './prescription-wizard.service';
import {
  PRESCRIPTION_STEPS,
  SkifteSelection,
  SKIFTE_SELECTION_SOURCE,
  StepState,
} from './prescription-wizard.types';
import {
  AdjustPrescriptionModel,
  FunctionSelectInputOption,
  GeoDataBarItem,
  GridSettings,
  PrescriptionSettings,
  SEED_UNIT_DICT,
} from './components/step-settings/step-settings.types';
import { MapStateService } from 'app/services/map-state.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DeviationModel,
  InterpolationCollection,
  MappingLegendModel,
} from 'app/models/interpolation.model';
import { PrescriptionService } from 'app/services/prescription.service';
import { ApiError, ErrorResponse, PrescriptionModel } from 'app/models/models';
import { extractErrorText } from 'app/util/error_utils';
import { PrescriptionWizardInterpolationService } from './services/perscription-wizard-interpolation.service';
import { FeatureCollection } from '@turf/turf';
import {
  DEFAULT_SEED_TOTAL_UNIT,
  DEFAULT_SEED_UNIT,
} from './components/step-adjust/step-adjust.types';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-prescription-wizard-page',
  templateUrl: 'prescription-wizard-page.component.html',
  styleUrls: ['prescription-wizard-page.component.scss'],
})
export class PrescriptionWizardPageComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') private stepper: MatStepper;
  selectedIndex = 0;
  loading = true;
  functionPrescription: boolean;
  skiften: SkifteDetielsModel[];
  skifteLayers: GeoJSON.FeatureCollection;
  selectedStepState$: Observable<boolean>;
  functions: FunctionModel[];
  legend: MappingLegendModel;
  selectedFeatures: google.maps.Data.Feature[];
  selectedFunction: FunctionModel;
  geoDataTypes: GeoDataBarItem[] = [];
  PRESCRIPTION_STEPS = PRESCRIPTION_STEPS;
  dvMap: MapComponent;
  settings: PrescriptionSettings;
  grid: PrescriptionModel;
  deviations: DeviationModel[];
  totalArea = 0;
  disablePreviewBar = false;
  selectedSeedUnit?: string;
  readonly HECTARE_IN_SQM = 10000;
  private _unsub$ = new Subject<void>();
  private cancelableSubscription?: Subscription;
  private exportSeedUnit: string;
  private readonly CALCULATING_PRESCRIPTION_MSG = 'Calculating prescription...';
  private readonly CALCULATING_PREVIEW_MSG = 'Calculating preview...';

  get selectedSkifte$(): Observable<SkifteSelection> {
    return this.wizardService?.selectedSkifte$;
  }

  get stepState$(): Observable<StepState[]> {
    return this.wizardService?.stepState$;
  }

  constructor(
    private clientService: ClientService,
    private mapService: MapService,
    private mapStateService: MapStateService,
    private wizardService: PrescriptionWizardService,
    private snackBar: MatSnackBar,
    private translateService: DvToolbarTranslateService,
    private prescriptionService: PrescriptionService,
    private interpolationService: PrescriptionWizardInterpolationService
  ) {}

  ngOnInit(): void {
    this.getMap();
    this.getSkiften();
    this.listenToSkifteSelection();
    this.listenToStepState();
    this.listenToFunctionSelection();
    this.listenToInterpolations();
    this.listenToInterpolationUtils();
    this.listenToStepIndex();
  }

  ngOnDestroy(): void {
    this.dvMap.clearDeviations();
    this.prescriptionService.clearPrescriptionGrid();
    this.interpolationService.unsubscribeFromInterpolation();
    this._unsub$.next();
    this._unsub$.complete();
    this.grid = null;
    this.wizardService.setSelectedStepIndex(PRESCRIPTION_STEPS.FIELD);
  }

  onSelectedSeedUnit(unit?: FunctionSelectInputOption): void {
    if (unit) {
      this.selectedSeedUnit = unit.name;
      this.exportSeedUnit = SEED_UNIT_DICT[unit.id];
    } else {
      this.selectedSeedUnit = DEFAULT_SEED_UNIT;
      this.exportSeedUnit = DEFAULT_SEED_TOTAL_UNIT;
    }
  }

  private listenToStepIndex(): void {
    this.wizardService.selectedStepIndex$
      .pipe(takeUntil(this._unsub$))
      .subscribe((index) => {
        if (index !== PRESCRIPTION_STEPS.SETTINGS) {
          this.cancelSubscription();
        }
      });
  }

  private listenToInterpolationUtils(): void {
    this.interpolationService.clearInterpolation$
      .pipe(
        takeUntil(this._unsub$),
        switchMap(() =>
          forkJoin({
            skifte: this.selectedSkifte$.pipe(take(1)),
            stepIndex: this.wizardService.selectedStepIndex$.pipe(take(1)),
          })
        )
      )
      .subscribe(({ skifte, stepIndex }) => {
        this.clearMapAndLegend();
        if (skifte && stepIndex !== PRESCRIPTION_STEPS.FIELD) {
          this.onlyShowSelectedSkifte(skifte.id);
        }
      });

    this.interpolationService.interpolationError$
      .pipe(takeUntil(this._unsub$))
      .subscribe((error) => this.handleInterpolationError(error));

    this.interpolationService.interpolationLoading$
      .pipe(takeUntil(this._unsub$))
      .subscribe((loading) => {
        if (loading) {
          this.showMapLoader(this.CALCULATING_PREVIEW_MSG);
        }
      });
  }

  private listenToInterpolations(): void {
    this.cancelableSubscription = this.interpolationService.interpolation$
      .pipe(takeUntil(this._unsub$))
      .subscribe((interpolation) => {
        this.loadingStatus(false);

        if (interpolation) {
          this.drawInterpolation(interpolation);
        }
      });
  }

  private listenToSkifteSelection(): void {
    this.wizardService.selectedSkifte$
      .pipe(takeUntil(this._unsub$))
      .subscribe((selection) => this.handleSkifteSelection(selection));
  }

  private listenToStepState(): void {
    this.selectedStepState$ = this.wizardService.stepState$.pipe(
      takeUntil(this._unsub$),
      map((stepState) => !stepState[this.selectedIndex]?.completed)
    );
  }

  private listenToFunctionSelection(): void {
    this.wizardService.selectedFunction$
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        (selectedFunction) => (this.selectedFunction = selectedFunction)
      );
  }

  private getMap(): void {
    this.mapService
      .mainMap()
      .pipe(take(1))
      .subscribe((dvMap) => {
        this.prescriptionService.clearPrescriptionGrid();
        this.dvMap = dvMap;
        this.getSkifteLayer();
        this._listenToPrescriptionChanges();
        this.dvMap.loading = this.loading;
      });
  }

  private getSkiften(): void {
    this.loadingStatus(true);

    this.clientService
      .getSkiften()
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        (skiften) => {
          this.skiften = skiften;

          if (skiften?.length) {
            this.loadingStatus(false);
          }
        },
        ({ error }: ErrorResponse) => this.handleInterpolationError(error)
      );
  }

  private getSkifteLayer(): void {
    this.clientService
      .getSkifteLayer()
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        (skifteLayers) => {
          this.skifteLayers = skifteLayers;
          this.addSkifteLayersToMap();
          this.handleSelectedFeatures();
        },
        ({ error }: ErrorResponse) => this.handleInterpolationError(error)
      );
  }

  private handleSelectedFeatures(): void {
    this.selectedFeatures = this.dvMap.getSelectedFeatures(LAYER_NAME_SKIFTEN);

    if (this.selectedFeatures.length > 1) {
      this.dvMap.deSelectedFeatures();
    } else if (this.selectedFeatures.length === 1) {
      this.dvMap.fitFeature(this.selectedFeatures);
      this.onFieldSelected(this.selectedFeatures[0]);
    }
  }

  private addSkifteLayersToMap(): void {
    if (this.dvMap) {
      this.dvMap.addGeoJson(this.skifteLayers, LAYER_NAME_SKIFTEN);
      const features = this.dvMap.getFeatures(LAYER_NAME_SKIFTEN);
      features.forEach((feature) => {
        feature.setProperty('fillOpacity', 0.8);
      });
    }
  }

  private handleSkifteSelection(selection: SkifteSelection): void {
    if (selection?.source === SKIFTE_SELECTION_SOURCE.TABLE && this.dvMap) {
      this.dvMap.selectedFeature(selection.id);

      const feature = this.dvMap.getSelectedFeatures();
      this.dvMap.fitFeature(feature);
    }
  }

  onStepSelect(index: number): void {
    const isExport = index > 3;
    index = !isExport ? index : index - 1;
    this.selectedIndex = index;

    if (isExport) {
      this.functionPrescription = true;
      const dialogRef = this.prescriptionService.openExportDialog(
        this.grid,
        this.settings.functionInput.parameters[1] as number,
        this.functionPrescription,
        undefined,
        this.exportSeedUnit
      );

      dialogRef
        .afterClosed()
        .subscribe((result) =>
          this.wizardService.publishPrescriptionSaved(result.saved)
        );
    } else {
      this.cancelSubscription();
      this.wizardService.clearStateFromStep(index);
      this.wizardService.getStepState();
      this.wizardService.setSelectedStepIndex(index);

      switch (index) {
        case PRESCRIPTION_STEPS.FIELD:
          this.handleFieldStepNav();
          break;
        case PRESCRIPTION_STEPS.METHOD:
          this.handleMethodStepNav();
          break;
        case PRESCRIPTION_STEPS.ADJUST:
          this.grid = null;
          break;
      }
    }

    this.stepper.selectedIndex = index;
  }

  private handleFieldStepNav(): void {
    this.dvMap.clearMap();
    this.dvMap.disableSelect = false;
    this.addSkifteLayersToMap();
    this.handleSelectedFeatures();
  }

  private handleMethodStepNav(): void {
    this.loading = true;
    this.cancelableSubscription = this.selectedSkifte$
      .pipe(
        takeUntil(this._unsub$),
        take(1),
        tap((skifte) => this.onlyShowSelectedSkifte(skifte.id)),
        mergeMap((skifte) => this.clientService.getFieldFunctions(skifte.id)),
        // Using delay to let stepper animation complete, if not
        // the animation is laggy
        delay(250)
      )
      .subscribe((functions) => {
        this.loadingStatus(false);
        this.functions = functions;
      });
  }

  private onlyShowSelectedSkifte(id: number): void {
    const feature = this.skifteLayers.features.find(
      (feature) => feature.id === id
    );
    const skifteLayer = {
      ...this.skifteLayers,
      features: [feature],
    };

    this.dvMap.clearMap();
    this.dvMap.disableSelect = true;
    this.dvMap.addGeoJson(skifteLayer, LAYER_NAME_SKIFTEN);
  }

  onFieldSelected(feature: google.maps.Data.Feature): void {
    const id = feature.getProperty(LAYER_PROPS.SKIFTE_ID);
    const name = feature.getProperty(LAYER_PROPS.NAME);
    if (id) {
      this.wizardService.selectSkifte({
        id,
        name,
        source: SKIFTE_SELECTION_SOURCE.MAP,
      });

      this.mapStateService.clearSelectedFeatures();
      this.mapStateService.setSelectedFeature(feature);
    }
  }

  onFieldDeselected(feature: google.maps.Data.Feature): void {
    const id = feature.getProperty(LAYER_PROPS.SKIFTE_ID);
    if (id) {
      this.wizardService.selectSkifte(null);
    }
  }

  onGeoDataTypes(types: GeoDataBarItem[]): void {
    this.geoDataTypes = types;
  }

  private clearMapAndLegend(): void {
    this.legend = null;
    this.dvMap?.removeLayer(LAYER_NAME_INTERPOLATION);
  }

  private drawInterpolation(interpolation: InterpolationCollection): void {
    const stepIndex = this.wizardService.getSelectedStepIndex();

    if (stepIndex !== PRESCRIPTION_STEPS.SETTINGS) {
      return;
    }

    if (interpolation?.interpolations?.features?.length > 0) {
      this.dvMap.fitFeature(
        this.dvMap.addGeoJson(
          interpolation.interpolations,
          LAYER_NAME_INTERPOLATION
        )
      );
      this.legend = interpolation.interpolations.legend;
      this.totalArea = this.legend.totalArea;
    } else {
      this.handleInterpolationError();
    }
  }

  private handleInterpolationError(error?: ApiError): void {
    this.showSnackBar(extractErrorText(error?.Message));
    this.clearMapAndLegend();
  }

  private showSnackBar(text: string): void {
    this.snackBar.open(this.translateService.t(text), null, {
      duration: 3500,
    });
  }

  private showMapLoader(text: string): void {
    this.dvMap.loadingText = text;
    this.loadingStatus(true);
  }

  onSettings(settings: PrescriptionSettings): void {
    this.showMapLoader(this.CALCULATING_PRESCRIPTION_MSG);
    this.settings = settings;
    this.wizardService.ongoingGridCalculation.next(true);
    this.getGrid(true);
  }

  private handleNavigationToAdjustStep(grid: PrescriptionModel): void {
    this.prescriptionService.drawPrescriptionGrid(grid);
    this.dvMap.disableSelect = false;
    this.wizardService.ongoingGridCalculation.next(false);
  }

  private _listenToPrescriptionChanges(): void {
    this.mapStateService
      .getPrescription()
      .pipe(takeUntil(this._unsub$))
      .subscribe((prescription) => {
        this.dvMap.removeLayer(LAYER_NAME_INTERPOLATION);
        if (prescription) {
          this.handlePrescription(this.dvMap, prescription);
        }
      });
  }

  handlePrescription(
    map: MapComponent,
    featureCollection: GeoJSON.FeatureCollection
  ): void {
    map.fitFeature(map.addGeoJson(featureCollection, LAYER_NAME_INTERPOLATION));
    this.loadingStatus(false);
  }

  onAdjustments(adjustments: AdjustPrescriptionModel): void {
    this.showMapLoader(this.CALCULATING_PRESCRIPTION_MSG);
    this.settings.adjustments = adjustments.adjustments;
    this.cancelableSubscription = this.clientService
      .getPrescriptionGridUpdate(adjustments)
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        (grid) => this.handleGridResult(grid),
        ({ error }: ErrorResponse) => {
          this.loadingStatus(false);
          this.handleInterpolationError(error);
        }
      );
  }

  onGridSettings(gridSettings: GridSettings): void {
    this.cancelSubscription();
    this.showMapLoader(this.CALCULATING_PRESCRIPTION_MSG);
    this.wizardService.ongoingGridCalculation.next(true);
    this.getGridPreview(gridSettings);
  }

  private getGridPreview(gridSettings: GridSettings): void {
    this.cancelableSubscription = this.selectedSkifte$
      .pipe(
        takeUntil(this._unsub$),
        take(1),
        switchMap((skifte) => {
          return this.clientService.getPrescriptionGridPreview(
            gridSettings,
            skifte.id
          );
        })
      )
      .subscribe(
        (grid) => this.handleGridPreviewResult(grid),
        ({ error }: ErrorResponse) => {
          this.loadingStatus(false);
          this.handleInterpolationError(error);
        }
      );
  }

  private handleGridPreviewResult(grid: FeatureCollection): void {
    this.wizardService.ongoingGridCalculation.next(false);
    this.loadingStatus(false);
    grid.features.forEach((feature) => {
      feature.properties.color = '#eaecec';
      feature.properties.strokeColor = '#ffffff';
    });
    this.dvMap.removeLayer(LAYER_NAME_INTERPOLATION);
    this.dvMap.fitFeature(
      this.dvMap.addGeoJson(grid, LAYER_NAME_INTERPOLATION)
    );
  }

  private getGrid(cancelOngoingGridCalculation = false): void {
    this.cancelableSubscription = this.selectedSkifte$
      .pipe(
        take(1),
        switchMap((skifte) => {
          return this.clientService.getPrescriptionGrid(
            this.settings,
            skifte.id
          );
        }),
        takeUntil(this._unsub$)
      )
      .subscribe(
        (grid) => {
          if (cancelOngoingGridCalculation) {
            this.wizardService.ongoingGridCalculation.next(false);
          }
          this.handleGridResult(grid);
        },
        ({ error }: ErrorResponse) => {
          this.loadingStatus(false);
          this.handleInterpolationError(error);
        }
      );
  }

  handleGridResult(grid: PrescriptionModel): void {
    this.grid = grid;
    this.dvMap.clearMap();
    this.handleNavigationToAdjustStep(grid);
    this.loadingStatus(false);
  }

  private loadingStatus(loading: boolean): void {
    this.loading = loading;
    if (this.dvMap) {
      this.dvMap.setLoadStatus(loading);
    }
  }

  onDisablePreviewBar(value: boolean): void {
    this.disablePreviewBar = value;

    if (value) {
      this.cancelSubscription();
    } else {
      this.listenToInterpolations();
    }
  }

  private cancelSubscription(): void {
    this.loadingStatus(false);

    if (this.cancelableSubscription && !this.cancelableSubscription.closed) {
      this.cancelableSubscription.unsubscribe();
    }
  }
}
