import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dv-map-background',
  templateUrl: './map-background.component.html',
  styleUrls: ['../map.component.scss']
})
export class MapBackgroundComponent implements OnInit {

  @Output() backgroundChange = new EventEmitter()

  constructor() { }

  ngOnInit() {

  }

  changeBackground(type: 'karta' | 'satellit' | 'sattelitEtikett') {
    let mapType;
    switch (type) {
      case "karta":
        mapType = google.maps.MapTypeId.ROADMAP;
        break;
      case "satellit":
        mapType = google.maps.MapTypeId.SATELLITE;
        break;
      case "sattelitEtikett":
        mapType = google.maps.MapTypeId.HYBRID;
        break;
      default:
        mapType = google.maps.MapTypeId.ROADMAP;
        break;
    }
    
    this.backgroundChange.next(mapType);
  }
}
