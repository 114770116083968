import { GeoDataMetaData } from "./models";

export enum GeoDataFileMetaTags {
    customer = 'Owner',
    farm = 'Farm',
    field = 'Field',
    totalYield = 'Total Yield',
    totalArea = 'Total Area',
    created = 'Created'
}

export class GeoMetaDataUtil {
    /// will sort out any special metadata in GeoDataFileMetaTags
    public othermMetaDataArray(metaObj: GeoDataMetaData) : any[] {
        return metaObj.filter((data) => {
            return !(
                data.key === GeoDataFileMetaTags.customer ||
                data.key === GeoDataFileMetaTags.farm ||
                data.key === GeoDataFileMetaTags.field ||
                data.key === GeoDataFileMetaTags.created ||
                data.key === GeoDataFileMetaTags.totalArea ||
                data.key === GeoDataFileMetaTags.totalYield ||
                !data.value
            );
        });
    }

    /// will check if metadata is set on file
    public isOverriddenMeta(fileMetaObj: GeoDataMetaData, dataMetaObj: GeoDataMetaData, key: string) : boolean {
        if (!dataMetaObj || !fileMetaObj) return false;
        if (dataMetaObj[key] === undefined || fileMetaObj[key] === undefined) return false;
        return dataMetaObj[key] === fileMetaObj[key];
    }
}