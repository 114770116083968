import { Component, Input } from '@angular/core';

@Component({
  selector: 'dv-machine-connection-actions',
  templateUrl: 'machine-connection-actions.component.html',
  styleUrls: ['machine-connection-actions.component.scss'],
})
export class MachineConnectionActionsComponent {
  @Input() loading = false;
}
