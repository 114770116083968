<div class="filter">
    <div class="filter-section">
        <div class="filter-section-label">{{ 'Created' | dvt }}</div>
        <div class="filter-section-filters filter-section-filters-dates">
            <mat-form-field
                appearance="outline"
                floatLabel="never"
            >
                <input
                    #from
                    (dateChange)="setFilter(from, 'from')"
                    matInput
                    [matDatepicker]="dpFrom"
                    [(ngModel)]="filterValues.from"
                    [placeholder]="translateService.t('From')"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="dpFrom"
                ></mat-datepicker-toggle>
                <mat-datepicker
                    #dpFrom
                    disabled="false"
                ></mat-datepicker>
            </mat-form-field>
            <mat-form-field
                appearance="outline"
                floatLabel="never"
            >
                <input
                    #to
                    (dateChange)="setFilter(to, 'to')"
                    matInput
                    [matDatepicker]="dpTo"
                    [(ngModel)]="filterValues.to"
                    [placeholder]="translateService.t('To')"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="dpTo"
                ></mat-datepicker-toggle>
                <mat-datepicker
                    #dpTo
                    disabled="false"
                ></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="filter-section">
        <div class="filter-section-label">{{ 'Data types' | dvt }}</div>
        <mat-form-field
            appearance="outline"
            floatLabel="never"
        >
            <mat-select
                (selectionChange)="setFilter($event, 'fileType')"
                [(ngModel)]="filterValues.fileType"
                [placeholder]="translateService.t('All types')"
            >
                <mat-option [value]="null">{{ 'All types' | dvt }}</mat-option>
                <mat-option
                    *ngFor="let type of availableFilterValues.fileTypes"
                    [value]="type"
                >
                    {{ '_data_filetype_' + type | dvt: '_' + type }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="filter-section">
        <div class="filter-section-label">{{ '__metadata_owner' | dvt: '_Customer' }}</div>
        <mat-form-field
            appearance="outline"
            floatLabel="never"
        >
            <mat-select
                (selectionChange)="setFilter($event, 'customer')"
                [(ngModel)]="filterValues.customer"
                [placeholder]="translateService.t('All metadata customers', '_All customers')"
            >
                <mat-option [value]="null">{{ 'All metadata clients' | dvt: '_All customers' }}</mat-option>
                <mat-option
                    *ngFor="let customer of availableFilterValues.customers"
                    [value]="customer"
                >
                    {{ customer }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="filter-section">
        <div class="filter-section-label">{{ '__metadata_farm' | dvt: '_Farm' }}</div>
        <mat-form-field
            appearance="outline"
            floatLabel="never"
        >
            <mat-select
                (selectionChange)="setFilter($event, 'farm')"
                [(ngModel)]="filterValues.farm"
                [placeholder]="translateService.t('All metadata farms', '_All farms')"
            >
                <mat-option [value]="null">{{ 'All metadata farms' | dvt: '_All farms' }}</mat-option>
                <mat-option
                    *ngFor="let farm of availableFilterValues.farms"
                    [value]="farm"
                >
                    {{ farm }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <button
        class="filter-reset-button"
        mat-flat-button
        (click)="clearFilters()"
    >
        <mat-icon>sync</mat-icon>
        {{ 'reset' | dvt }}
    </button>
</div>
