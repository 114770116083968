import { KeyValue } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImportModel } from 'app/models/import.model';
import { ClientService } from 'app/services/client.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileuploadComponent } from '../fileupload/fileupload.component';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-logdata',
  templateUrl: './logdata.component.html',
  styleUrls: ['./logdata.component.scss', '../import-buttons.scss'],
})
export class LogdataComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() importModel: ImportModel = null;
  @Output() importChange = new EventEmitter<ImportModel>();

  csvFileMissing = false;
  shpFileMissing = false;
  dbfFileMissing = false;
  needAdditionalFiles = false;
  logdataFiles = {
    shp: null,
    dbf: null,
    shx: null,
  };
  gotFiles: boolean;
  showAgrometius = false;
  showAugmenta = false;
  uploadIsaria = false;
  uploadAugmenta = false;
  hasSelectedType = false;
  fileTypesMetadata = {} as KeyValue<string, string>;
  private _unsub$ = new Subject<void>();

  @ViewChild('fileUpload') fileUpload: FileuploadComponent;

  constructor(
    private translateService: DvToolbarTranslateService,
    private snackBar: MatSnackBar,
    private clientService: ClientService
  ) {}

  ngOnInit(): void {
    this.clientService
      .getLandCode()
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        this.showAgrometius = res === 'nl' || res === 'de' || res === 'be';
      });
  }

  ngAfterViewInit(): void {
    this.fileTypesMetadata = this.importModel.metadata.find(
      (data) => data.key === 'fileTypes'
    );
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  setManufacture(manufacture: string): void {
    this.importModel.type = 'nsensor';
    this.importModel.manufacture = manufacture;
    if (manufacture === 'yara') {
      this.importModel.addMetadata('fileTypes', '.log,.zip');
    } else {
      this.importModel.addMetadata('fileTypes', '.shp,.shx,.dbf');
      if (manufacture === 'isaria') {
        this.uploadIsaria = true;
      } else if (manufacture === 'augmenta') {
        this.uploadAugmenta = true;
      } else {
        /// ???
      }
    }
    this.hasSelectedType = true;
  }

  files(files: File[]): void {
    files.forEach((file) => {
      switch (file.name.toLowerCase().substr(file.name.lastIndexOf('.'))) {
        case '.dbf':
          this.logdataFiles.dbf = file;
          break;
        case '.shp':
          this.logdataFiles.shp = file;
          break;
        case '.shx':
          this.logdataFiles.shx = file;
          break;
        default:
          this.snackBar.open(
            this.translateService.t('Unknown filetype'),
            null,
            { duration: 3000 }
          );
      }
    });
    this.updateFileStatus();
  }

  updateFileStatus(): void {
    this.gotFiles = true;
    this.shpFileMissing = this.logdataFiles.shp === null ? true : false;
    this.dbfFileMissing = this.logdataFiles.dbf === null ? true : false;
    this.csvFileMissing = this.logdataFiles.shx === null ? true : false;
    this.needAdditionalFiles =
      !this.dbfFileMissing && !this.shpFileMissing && !this.csvFileMissing
        ? false
        : true;
  }

  yaraFileSelected(files: File[]): void {
    this.importModel.files = [];
    for (const file of files) {
      this.importModel.files.push(file);
    }

    this.importChange.next(this.importModel);
  }

  done(): void {
    this.importModel.files = [
      this.logdataFiles.dbf,
      this.logdataFiles.shp,
      this.logdataFiles.shx,
    ];
    this.importChange.next(this.importModel);
    this.reset();
  }

  reset(): void {
    this.gotFiles = false;
    this.logdataFiles = {
      shp: null,
      dbf: null,
      shx: null,
    };
  }
}
