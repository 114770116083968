import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { GeoDataFileMetaTags } from 'app/models/geodata.model';
import { GeoDataFileModel, RawFileSetModel } from 'app/models/models';
import { DataFileService, FILE_FORMAT } from 'app/services/data-file.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { expandableRowAnimation } from '../../expandable-row.animation';
import { MatTableDataSource } from '@angular/material/table';
import { DataFilterService } from 'app/services/data-filter.service';
import { COLUMN_KEYS } from '../../data-table.types';
import { DialogService } from '../../../dialog/dialog.service';
import { GeodataListShareDialogComponent } from './geodata-list-share-dialog/geodata-list-share-dialog.component';
import { ShareDialogResult } from './geodata-list-share-dialog/geodata-list-share-dialog.types';
import { DataSharePayload, ShareService } from 'app/services/share.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-geodata-list',
  templateUrl: './geodata-list.component.html',
  styleUrls: ['./geodata-list.component.scss', '../../data-table.scss'],
  animations: [expandableRowAnimation],
})
export class GeoDataListComponent
  implements AfterContentInit, AfterViewInit, OnDestroy
{
  private _unsub$: Subject<void> = new Subject<void>();

  @ViewChild('geodataPaginator') paginator: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.geodataDataSource.sort = sort;
  }
  @Input() set selected(value: number) {
    this.toggleRow(value);
  }
  @Input() set filter(value: string) {
    this.geodataDataSource.filter = value;
  }
  @Output() farms = new EventEmitter<string[]>();
  @Output() customers = new EventEmitter<string[]>();
  @Output() filetypes = new EventEmitter<string[]>();
  @Output() loading = new EventEmitter<boolean>();
  @Output() listLength = new EventEmitter<number>();
  @Output() fileDetail = new EventEmitter<RawFileSetModel>();

  expandedRow = -1;
  dataError: string;
  selectedFiles: number[] = [];
  metaTag = GeoDataFileMetaTags;
  loadingFiles = true;
  geodataDataSource: MatTableDataSource<GeoDataFileModel> =
    new MatTableDataSource<GeoDataFileModel>([]);
  geodataLength$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  viewInited = false;
  filesError: string;
  currentSort: Sort;
  COLUMN_KEYS = COLUMN_KEYS;
  FILE_FORMAT = FILE_FORMAT;

  displayedColumns: string[] = [
    COLUMN_KEYS.CHECKBOX,
    COLUMN_KEYS.NAME,
    COLUMN_KEYS.TYPE,
    COLUMN_KEYS.AREA,
    COLUMN_KEYS.CLIENT,
    COLUMN_KEYS.FARM,
    COLUMN_KEYS.FIELD,
    COLUMN_KEYS.DATE,
    COLUMN_KEYS.MORE,
  ];

  private sortingDataAccessor = (
    item: GeoDataFileModel,
    property: string
  ): string | number => {
    switch (property) {
      case COLUMN_KEYS.TYPE:
        return this.translateSrv.t(
          '_data_filetype_' + item.fileType,
          '_' + item.fileType
        );
      case COLUMN_KEYS.AREA: {
        const area = Number(
          this.getMetaData(item.fileId, this.metaTag.totalArea)
        );
        return isNaN(area) ? -1 : area;
      }
      case COLUMN_KEYS.CLIENT:
        return this.getMetaData(item.fileId, this.metaTag.customer);
      case COLUMN_KEYS.FARM:
        return this.getMetaData(item.fileId, this.metaTag.farm);
      case COLUMN_KEYS.FIELD:
        return this.getMetaData(item.fileId, this.metaTag.field);
      case COLUMN_KEYS.DATE:
        return this.getMetaData(item.fileId, this.metaTag.created);
      default:
        return item[property];
    }
  };

  constructor(
    public translateSrv: DvToolbarTranslateService,
    public fileSrv: DataFileService,
    public filterSrv: DataFilterService,
    private dialogService: DialogService,
    private shareService: ShareService,
    private snackBar: MatSnackBar
  ) {
    this.fileSrv.loadingGeodata$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .subscribe((loading) => {
        this.loadingFiles = loading;
      });

    this.fileSrv
      .getGeodata()
      .pipe(takeUntil(this._unsub$))
      .subscribe((data) => {
        if (data) {
          this.geodataDataSource.data = data;
          this.geodataLength$.next(
            this.fileSrv.dataSourceLength(this.geodataDataSource)
          );
        } else {
          this.geodataDataSource.data = [];
          this.geodataLength$.next(0);
        }
      });

    // when filtering is done, we will reset progress indicator
    this.geodataLength$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .subscribe(() => {
        this.loadingFiles = this.fileSrv.loadingGeodata$.value;
      });

    this.fileSrv.filesetErr$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .subscribe((error) => (this.filesError = error));
  }

  ngAfterContentInit(): void {
    this.geodataLength$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .pipe(distinctUntilChanged())
      .subscribe((length) => {
        this.listLength.emit(length);
      });
  }

  ngAfterViewInit(): void {
    this.setUpDataSource();

    // We need to cut the table rendering some slack, otherwise
    // it seems that change detection will run once for each
    // row populated with data, which takes like forever (4s for 300 rows)
    setTimeout(() => {
      this.viewInited = true;
    }, 500);
  }

  private setUpDataSource(): void {
    this.paginator._intl.itemsPerPageLabel =
      this.translateSrv.t('Datasets per page');
    this.geodataDataSource.paginator = this.paginator;
    this.geodataDataSource.filterPredicate =
      this.filterSrv.initGeoDataFileFilter();
    this.geodataDataSource.sortingDataAccessor = this.sortingDataAccessor;

    this.filterSrv.filterChange$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .subscribe((value) => {
        this.loadingFiles = true;
        this.geodataDataSource.filter = value;
        this.geodataLength$.next(
          this.fileSrv.dataSourceLength(this.geodataDataSource)
        );
      });
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  getMetaData(geodataId: number, metaKey: string): string {
    const metadata = this.geodataDataSource?.data?.find(
      (d) => d.fileId === geodataId
    )?.metadata;
    const value = metadata?.find((data) => data.key === metaKey)?.value;

    return value === undefined ? '-' : value;
  }

  showFileDetail(id: number): void {
    this.fileSrv.fileset$.subscribe((fileSet) => {
      const file = fileSet.find((set) => set.fileId === id);

      if (file) {
        this.fileDetail.emit(file);
      }
    });
  }

  toggleRow(geodataId: number): void {
    if (this.expandedRow !== -1) {
      const toggleActive = geodataId === this.expandedRow;
      this.clearExpandedRow();
      if (toggleActive) {
        return;
      }
    }

    this.fileSrv.selectedGeodata$.next(geodataId);
    this.expandedRow = geodataId;
  }

  clearExpandedRow(): void {
    this.expandedRow = -1;
    this.fileSrv.selectedGeodata$.next(null);
  }

  checkedChange(fileId: number): void {
    if (!this.selectedFiles.includes(fileId)) {
      this.selectedFiles.push(fileId);
    } else {
      this.selectedFiles = this.selectedFiles.filter((id) => id !== fileId);
    }
  }

  selectAllFiles(checked: boolean): void {
    if (checked) {
      this.geodataDataSource.filteredData.forEach((file) =>
        this.selectedFiles.push(file.fileId)
      );
    } else {
      this.selectedFiles = [];
    }
  }

  onSortChange(sort: Sort): void {
    this.currentSort = sort;
  }

  openShareFilesDialog(): void {
    const ref = this.dialogService.open(GeodataListShareDialogComponent, {
      selectedFiles: this.geodataDataSource.data.filter((file) =>
        this.selectedFiles.includes(file.fileId)
      ),
    });

    ref.afterClosed().subscribe((result: ShareDialogResult) => {
      if (result?.data) {
        const payload: DataSharePayload = {
          message: result.data.message,
          recipients: result.data.emails,
          urls: result.data.links,
        };

        this.shareService.shareGeoDataLinks(payload).subscribe(
          () => this.showSnackBar('Success'),
          () => this.showSnackBar('Something went wrong, please try again!')
        );
      }
    });
  }

  private showSnackBar(text: string): void {
    this.snackBar.open(this.translateSrv.t(text), null, {
      duration: 3500,
    });
  }
}
