import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FunctionModel } from 'app/models/api.models';
import { PrescriptionWizardService } from 'app/views/prescription-wizard-page/prescription-wizard.service';
import { PRESCRIPTION_STEPS } from 'app/views/prescription-wizard-page/prescription-wizard.types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dv-step-function',
  templateUrl: 'step-function.component.html',
  styleUrls: ['step-function.component.scss', '../steps.scss'],
})
export class StepFunctionComponent implements OnInit, OnDestroy {
  @Input() functions: FunctionModel[];
  @Input() loading: boolean;
  selectedIndex = -1;
  private unsub$ = new Subject<void>();

  constructor(private wizardService: PrescriptionWizardService) {}

  ngOnInit(): void {
    this.wizardService.selectedFunction$
      .pipe(takeUntil(this.unsub$))
      .subscribe((selectedFunction) =>
        this.handleSelectedFunction(selectedFunction)
      );

    this.wizardService.selectedStepIndex$
      .pipe(takeUntil(this.unsub$))
      .subscribe((index) => {
        if (index === PRESCRIPTION_STEPS.METHOD) {
          this.selectFunction(-1);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  handleSelectedFunction(selectedFunction?: FunctionModel): void {
    let index = -1;

    if (selectedFunction && this.functions) {
      index = this.functions.findIndex(
        (func) => func.id === selectedFunction.id
      );
    }
    this.selectedIndex = index;
  }

  selectFunction(index: number): void {
    this.wizardService.selectFunction(
      index > -1 ? this.functions[index] : null
    );
  }
}
