<dv-dialog-content [title]="title" [loading]="loading" [closeBtn]="true" style="min-width: 350px">
  <div class="main-container">
    <div class="flex" style="flex-direction: column">
      <div class="flex-column-center" style="flex-direction: column">
        <div style="height: 50px">
          <mat-icon style="font-size: 50px; width: auto; height: auto" class="datavaxt-blue">share</mat-icon>
        </div>

        <div class="link-loading" *ngIf="link === null">
          {{ 'Preparing for sharing...' | dvt }}
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="link !== null" class="flex-column-center" style="flex-direction: column">
          <!-- Share by Email -->
          <div class="flex" style="flex-direction: column">
            <mat-form-field class="example-full-width" style="margin-bottom: 15px">
              <mat-label>{{ 'Leave a comment' | dvt }}</mat-label>
              <textarea #area maxlength="256" matInput (blur)="setComment(area.value)" rows="3"
                placeholder="{{ 'Leave a comment' | dvt }}"></textarea>
              <mat-hint align="end">{{ area.value.length }} / 256</mat-hint>
            </mat-form-field>

            <mat-checkbox *ngIf="files && files.length > 0" [(ngModel)]="includeDataFiles" (change)="shareFiles()">{{
              'Include data files' | dvt }}</mat-checkbox>

            <p style="margin: 0px; font-weight: 500">{{ 'Share by email' | dvt }}:</p>
            <div *ngIf="sendingEmail" style="min-height: 64px; min-width: 368px; padding-top: 8px">
              {{ 'Sending mail' | dvt }}
              <mat-progress-bar mode="indeterminate" style="margin-top: 15px"></mat-progress-bar>
            </div>
            <form class="flex" (ngSubmit)="sendEmail()" #emailForm="ngForm" *ngIf="!sendingEmail">
              <div class="form-rows">
                <mat-form-field class="share-email-form row-width" appearance="outline"
                  *ngFor="let item of emails; let index = index" style="margin: 0px; margin-right: 15px">
                  <input matInput [formControl]="item.formControl" [errorStateMatcher]="matcher"
                    [placeholder]="placeholderText" [(ngModel)]="item.email" />

                  <button *ngIf="index != 0" mat-icon-button color="error" (click)="removeEmail($event, index)"
                    [matTooltip]="'Remove recipient' | dvt">
                    <mat-icon>close</mat-icon>
                  </button>

                  <mat-error *ngIf="
                                            item.formControl.hasError('email') && !item.formControl.hasError('required')
                                        ">
                    {{ 'Please enter a valid email address' | dvt }}
                  </mat-error>

                  <mat-error *ngIf="item.formControl.hasError('required')">
                    {{ 'Email is required' | dvt }}
                  </mat-error>
                </mat-form-field>
                <div>
                  <button type="button" (click)="addEmail($event)" mat-icon-button color="primary"
                    [matTooltip]="'Add a recipient' | dvt">
                    <mat-icon>add_circle</mat-icon>
                  </button>
                  {{ 'Add a recipient' | dvt }}
                </div>
                <div class="send-row row-width">
                  <button [disabled]="hasErrors()" style="margin-top: 5px" type="submit" mat-raised-button
                    color="primary">
                    {{ 'Send' | dvt | uppercase }}
                  </button>
                </div>
              </div>
            </form>
          </div>

          <mat-divider style="color: #1f2d3d; opacity: 0.3"></mat-divider>

          <!-- Share by URL -->
          <div>
            <p style="font-weight: 500">{{ 'Share by link' | dvt }}:</p>
            <mat-form-field style="width: 345px; margin: 0px; padding: 0px" appearance="outline">
              <input matInput [readonly]="true" [value]="link" />
              <mat-icon matSuffix *ngIf="hasCopied" color="accent">done</mat-icon>
            </mat-form-field>

            <button mat-icon-button [matTooltip]="'Copy Link' | dvt" (click)="copyLink()" style="border-radius: 0%">
              <mat-icon>file_copy</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</dv-dialog-content>
