import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DvAuthService } from '@dv/toolbar-msal';
import { REDIRECT_URL_KEY } from 'app/app.types';

@Component({
  selector: 'dv-login-prompt',
  templateUrl: './login-prompt.component.html',
  styleUrls: ['./login-prompt.component.scss'],
})
export class LoginPromptComponent {
  constructor(private router: Router, private dvAuthService: DvAuthService) {}

  login(): void {
    this.setRedirectPath();
    this.dvAuthService.signIn();
  }

  signUp(): void {
    this.setRedirectPath();
    this.dvAuthService.register();
  }

  setRedirectPath(): void {
    window.localStorage.setItem(
      REDIRECT_URL_KEY,
      this.router.url.split('/').filter((s) => s.length > 0).length > 2
        ? this.router.url
            .split('/')
            .filter((s) => s.length > 0)
            .slice(-2)
            .join('/')
        : this.router.url
    );
  }
}
