<dv-dialog-content
  [title]="'Upload done' | dvt"
  [loading]="loading"
  [closeBtn]="!loading"
  (onClose)="close()"
>
  <div id="wrapper">
    <div *ngIf="!hideImportStatus">
      <div *ngFor="let group of importStatus" class="status-message">
        <mat-icon [color]="group.success ? 'accent' : 'warn'" [inline]="true">
          check_circle
        </mat-icon>
        <p class="group-message-header">
          {{ group.message.split(':')[0]
          }}<span *ngIf="group.message.split(':')[1]">:</span>
        </p>
        <p class="group-message-content">{{ group.message.split(':')[1] }}</p>
      </div>
      <mat-divider class="flex"></mat-divider>
    </div>

    <div
      class="missing-skifte"
      *ngIf="missingSkiften && missingSkiften.count > 0"
    >
      <p class="error">
        {{
          '_missing_parcels_information'
            | dvt
              : '_There are no parcels matching the data in the uploaded file. Do you want to create parcels with this data?'
        }}
      </p>

      <div class="create-options" *ngIf="years.length > 1">
        <div class="option">
          <span class="label"> {{ 'year' | dvt }}: </span>

          <mat-form-field class="flex">
            <mat-select [disabled]="savingParcels" [(ngModel)]="selectedYear">
              <mat-option *ngFor="let year of years" [value]="year">
                {{ year }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="option" *ngIf="farms?.length > 1">
          <span class="label"> {{ 'brukningsenhet' | dvt }}: </span>

          <mat-form-field class="flex">
            <mat-select [disabled]="savingParcels" [(ngModel)]="selectedFarmId">
              <mat-option *ngFor="let farm of farms" [value]="farm.id">
                {{ farm.namn }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="parcel-no">
        {{ 'create number of parcels' | dvt }}: {{ missingSkiften.count }}
      </div>

      <dvm-button
        class="create-parcel-btn"
        [disabled]="savingParcels"
        (click)="importSkiften()"
      >
        {{ 'create parcels' | dvt }}
      </dvm-button>

      <div class="secondrow-action-buttons">
        <dvm-button [disabled]="savingParcels" delete (click)="clear()">
          {{ "don't create parcels" | dvt }}
        </dvm-button>
        <dvm-button [disabled]="savingParcels" secondary (click)="close()">
          {{ 'create parcels later' | dvt }}
        </dvm-button>
      </div>
    </div>
    <div *ngIf="savingParcels" class="status">
      {{ 'Adding parcels' | dvt }}
    </div>
  </div>
</dv-dialog-content>
