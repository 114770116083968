import { KeyValue } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';
import * as turf from '@turf/turf';
import { ImportModel } from 'app/models/import.model';
import { forkJoin, fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';
import * as shp from 'shpjs';
@Component({
  selector: 'dv-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.scss'],
})
export class PrescriptionComponent implements AfterViewInit {
  @Input() importModel: ImportModel = null;
  @Output() importChange = new EventEmitter<ImportModel>();

  selectProp: string[] = ['giva', 'value', 'prescription', 'rate'];

  props: string[] = [];
  prescriptionFiles = {
    shp: null,
    dbf: null,
    shx: null,
    cpg: null,
    fc: null,
    zip: null,
  };
  prescription = {
    min: 0,
    max: 0,
    avg: 0,
    tot: 0,
    area: 0,
  };

  hasFile = false;
  nutritionMetadata: KeyValue<string, string>;
  valueColumnMetadata: KeyValue<string, string>;

  constructor(
    private cd: ChangeDetectorRef,
    private translateService: DvToolbarTranslateService,
    private snackBar: MatSnackBar
  ) {}

  ngAfterViewInit(): void {
    this.nutritionMetadata = this.importModel.metadata.find(
      (data) => data.key === 'nutrition'
    );
    this.valueColumnMetadata = this.importModel.metadata.find(
      (data) => data.key === 'valueColumn'
    );
  }

  files(files: File[]): void {
    for (const file of files) {
      switch (file.name.toLowerCase().substring(file.name.lastIndexOf('.'))) {
        case '.dbf':
          this.prescriptionFiles.dbf = file;
          this.parseShp();
          break;
        case '.shp':
          this.prescriptionFiles.shp = file;
          this.parseShp();
          break;
        case '.shx':
          this.prescriptionFiles.shx = file;
          this.parseShp();
          break;
        case '.cpg':
          this.prescriptionFiles.cpg = file;
          this.parseShp();
          break;
        case '.zip':
          this.prescriptionFiles.zip = file;
          this.parseZip();
          break;
        default:
          this.snackBar.open(
            this.translateService.t('Unknown filetype'),
            null,
            { duration: 3000 }
          );
      }
    }
    this.importModel.files = [];
    for (const file of files) {
      this.importModel.files.push(file);
    }
  }

  private parseZip(): void {
    if (!this.prescriptionFiles.zip) {
      return;
    }

    const zipReader = new FileReader();

    fromEvent(zipReader, 'load').subscribe(() => {
      this.prescriptionFiles.fc = shp.parseZip(zipReader.result as ArrayBuffer);
      this.readFc();
      this.calc();
    });

    zipReader.readAsArrayBuffer(this.prescriptionFiles.zip);
  }

  private parseShp(): void {
    // Don't parse shape if required files are missing
    if (
      this.prescriptionFiles.shp === null ||
      this.prescriptionFiles.dbf === null
    ) {
      return;
    }

    const shpReader = new FileReader();
    const dbfReader = new FileReader();

    forkJoin([
      fromEvent(shpReader, 'load').pipe(first()),
      fromEvent(dbfReader, 'load').pipe(first()),
    ]).subscribe(() => {
      this.prescriptionFiles.fc = shp.combine([
        shp.parseShp(shpReader.result),
        shp.parseDbf(dbfReader.result),
      ]);

      this.readFc();
    });

    shpReader.readAsArrayBuffer(this.prescriptionFiles.shp);
    dbfReader.readAsArrayBuffer(this.prescriptionFiles.dbf);
  }

  private readFc(): void {
    if (!this.prescriptionFiles.fc) {
      return;
    }

    if (!this.prescriptionFiles.fc.features) {
      this.snackBar.open(
        this.translateService.t('Upload one prescription file at a time'),
        null,
        {
          duration: 3000,
        }
      );
      return;
    }

    if (this.prescriptionFiles.fc.features.length > 0) {
      this.props = Object.keys(
        this.prescriptionFiles.fc.features[0].properties
      );
    } else {
      this.props = [];
    }

    this.importModel.addMetadata(
      'valueColumn',
      this.props.find((p) => this.selectProp.includes(p.toLowerCase()))
    );

    this.calc();

    this.hasFile = true;

    this.cd.markForCheck();
  }

  calc(): void {
    if (!this.prescriptionFiles.fc) {
      return;
    }

    let min = Number.MAX_SAFE_INTEGER;
    let max = 0;
    let area = 0;
    let tot = 0;

    this.prescriptionFiles.fc.features.forEach((f: turf.Feature) => {
      const valueColumn = this.importModel.metadata.find(
        (data) => data.key === 'valueColumn'
      );

      tot += (f.properties[valueColumn.value] * turf.area(f)) / 10000;
      area += turf.area(f) / 10000;

      if (min > f.properties[valueColumn.value]) {
        min = f.properties[valueColumn.value];
      } else if (max < f.properties[valueColumn.value]) {
        max = f.properties[valueColumn.value];
      }
    });

    let nutrition = Number(this.nutritionMetadata?.value);
    nutrition = isNaN(nutrition) ? 100 : nutrition;

    this.prescription.avg = (tot / area) * (nutrition / 100);
    this.prescription.tot = tot * (nutrition / 100);
    this.prescription.max = max * (nutrition / 100);
    this.prescription.min = min * (nutrition / 100);
    this.prescription.area = area;
  }

  done(): void {
    this.nutritionMetadata.value = String(this.nutritionMetadata.value);
    this.importChange.emit(this.importModel);
  }

  newFile(): void {
    this.hasFile = false;
    this.prescriptionFiles = {
      shp: null,
      dbf: null,
      shx: null,
      cpg: null,
      fc: null,
      zip: null,
    };
  }
}
