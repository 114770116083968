import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PoiService } from 'app/components/poi/poi.service';
import { FieldInterpolationService } from 'app/services/field-interpolation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { INTERPOLATION_TYPE } from './field.types';

@Component({
  selector: 'dv-base-fields',
  templateUrl: './base-fields.component.html',
  styleUrls: ['./base-fields.component.scss'],
})
export class BaseFieldsComponent implements OnDestroy, OnInit {
  @Input() selectedFieldIds: number[] = [];
  @Input() clientId: number;

  selectedType: INTERPOLATION_TYPE;
  private _unsub$ = new Subject<void>();

  get showTable(): boolean {
    return this.selectedType === INTERPOLATION_TYPE.TABLE;
  }

  get showPoi(): boolean {
    return this.selectedType === INTERPOLATION_TYPE.POI;
  }

  get showInterpolation(): boolean {
    return this.selectedType === INTERPOLATION_TYPE.INTERPOLATION;
  }

  get showCropSat(): boolean {
    return this.selectedType === INTERPOLATION_TYPE.CROP_SAT;
  }

  get showClayContent(): boolean {
    return this.selectedType === INTERPOLATION_TYPE.CLAY_CONTENT;
  }

  constructor(
    private interpolateSrv: FieldInterpolationService,
    private poiService: PoiService
  ) {
    this.listenToInterpolationType();
    this.listenToPoiState();
  }

  ngOnInit(): void {
    this.interpolateSrv.resetInterpolationType();
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  close(): void {
    this.interpolateSrv.resetInterpolationType();
  }

  private listenToInterpolationType(): void {
    this.interpolateSrv
      .getInterpolationType()
      .pipe(takeUntil(this._unsub$))
      .subscribe((type) => {
        switch (type) {
          case null:
            this.selectedType = INTERPOLATION_TYPE.TABLE;
            break;
          case INTERPOLATION_TYPE.CROP_SAT:
            this.selectedType = type;
            break;
          case INTERPOLATION_TYPE.CLAY_CONTENT:
            this.selectedType = type;
            break;
          default:
            this.selectedType = INTERPOLATION_TYPE.INTERPOLATION;
        }
      });
  }

  private listenToPoiState(): void {
    this.poiService
      .getState()
      .pipe(takeUntil(this._unsub$))
      .subscribe((state) => {
        if (state) {
          this.selectedType = INTERPOLATION_TYPE.POI;
        } else {
          this.selectedType = INTERPOLATION_TYPE.TABLE;
        }
      });
  }
}
