<div *ngIf="!hasFile">
    <dv-fileupload (files)="files($event)" style="margin-bottom: 20px;"></dv-fileupload>
    <div class="prescription-info">
        {{'_prescription_info'|dvt:'_Select a prescription file to upload'}}
    </div>
</div>
<div class="prescription" *ngIf="hasFile">
    <h3>{{'_prescription_title'|dvt:'_Settings for your prescription file'}}</h3>

    <div class="compact-form row">
        <div>{{'_prescription_nutrition'|dvt:'_Nutrition for yout file'}}</div>

        <mat-form-field appearance="outline" style="width: 85px;">
            <input type="number" matInput placeholder="{{'nutrition'|dvt}}" autocomplete="off"
                [(ngModel)]="nutritionMetadata.value" (change)="calc()">
            <span matSuffix>%</span>
        </mat-form-field>
    </div>
    <div class="row">
        <div>{{'_prescription_prop'|dvt:'_Field'}}</div>
        <mat-form-field appearance="outline" style="width: 125px; font-size: 12px; margin-bottom: 0px;">
            <mat-select [(ngModel)]="valueColumnMetadata.value" (selectionChange)="calc()">
                <mat-option *ngFor="let prop of props" [value]="prop">
                    {{prop}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="prescription-sum">
        <div class="column">
            <table>
                <tr>
                    <td>
                        <img src="assets/images/icon-fertilizer.svg" />
                    </td>
                    <td>
                        <div class="prescription-value"><span>{{'_prescription_tot'|dvt:'_TOT'}}</span>
                            {{prescription.tot|number:'1.0-0'}} kg</div>
                    </td>
                </tr>
                <tr>
                    <td><img src="assets/images/icon-Fields.svg" /></td>
                    <td>
                        <div class="prescription-value"><span>{{'_prescription_area'|dvt:'_Area'}}</span>
                            {{prescription.area|number:'1.0-2'}} ha</div>
                    </td>
                </tr>

            </table>


        </div>
        <div class="column">
            <table>
                <tr>
                    <td>
                        <mat-icon>vertical_align_center</mat-icon>
                    </td>
                    <td>
                        <div class="prescription-value"><span>{{'_prescription_avg'|dvt:'_AVG'}}</span>
                            {{prescription.avg|number:'1.0-2'}} kg</div>
                    </td>
                </tr>
                <tr>
                    <td><img src="assets/images/icon-minus.svg" /></td>
                    <td>
                        <div class="prescription-value"><span> {{'_prescription_min'|dvt:'_MIN'}}</span>
                            {{prescription.min|number:'1.0-2'}} kg</div>
                    </td>
                </tr>
                <tr>
                    <td><img src="assets/images/icon-plus.svg" /></td>
                    <td>
                        <div class="prescription-value">
                            <span>{{'_prescription_max'|dvt:'_MAX'}}</span> {{prescription.max|number:'1.0-2'}}
                            kg</div>
                    </td>
                </tr>
            </table>


        </div>
    </div>

    <div class="prescription-button">
        <button mat-raised-button (click)="newFile()">{{'_prescription_newfile'|dvt:'_New file'}}</button>
        <span></span>
        <button mat-raised-button color="accent" [disabled]="prescriptionFiles.fc==null"
            (click)="done()">{{'_prescription_next'|dvt:'_Next'}}</button>
    </div>
</div>