import { Component, OnInit } from '@angular/core';
import { MapService } from '../map.service';

@Component({
  selector: 'dv-coordinate',
  templateUrl: './coordinate.component.html',
  styleUrls: ['./coordinate.component.css']
})
export class CoordinateComponent implements OnInit {

  pos: { lat: number, lng: number } = { lat: 0, lng: 0 };

  constructor(private mapService: MapService) { }

  ngOnInit() {
    this.mapService.mainMap().subscribe(mainMap => {
      if (mainMap.map) {
        mainMap.map.addListener('mousemove', (ev: google.maps.MouseEvent) => {
          this.pos.lat = ev.latLng.lat();
          this.pos.lng = ev.latLng.lng();
        });
      }
    });
  }

}
