<div *ngIf="!hasSelectedType">
  <h3>{{ 'What type of file do you want to upload' | dvt }}?</h3>
  <div class="import-btns-wrapper">
    <div class="import-btns">
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('yara')"
      >
        <span class="import-btn-content">
          <img class="import-btn-icon" src="assets/images/logo-yara.svg" />
          {{ 'Yara' | dvt }}
        </span>
      </button>
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('isaria')"
        *ngIf="showAgrometius"
      >
        <span class="import-btn-content">
          <img
            class="import-btn-icon"
            src="https://cdn.datavaxt.se/icons/LogMASTER/icons/logos/Isaria.png"
          />
          {{ 'Fritzmeier Isaria' | dvt }}
        </span>
      </button>
      <button
        class="import-btn"
        mat-raised-button
        (click)="setManufacture('augmenta')"
        *ngIf="showAgrometius"
      >
        <span class="import-btn-content">
          <img
            class="import-btn-icon"
            src="https://cdn.datavaxt.se/icons/logos/partners/augmenta/AugmentaLogo.svg"
          />
          {{ 'Augmenta' | dvt }}
        </span>
      </button>
    </div>
  </div>
</div>
<div *ngIf="hasSelectedType" class="file-upload">
  <div *ngIf="uploadIsaria || uploadAugmenta">
    <div *ngIf="!hasFile">
      <dv-fileupload
        class="bottom-margin"
        (files)="files($event)"
        #fileUpload
        [disableShpHelper]="true"
      ></dv-fileupload>
      <div *ngIf="uploadIsaria" class="fritzmeier-info">
        {{ '_fritzmeier_info' | dvt: '_Select Fritzmeier files to upload' }}
      </div>
      <div *ngIf="uploadAugmenta" class="augmenta-info">
        {{ '_augmenta_info' | dvt: '_Select augmenta files to upload' }}
      </div>
    </div>

    <div *ngIf="needAdditionalFiles" class="bottom-margin">
      <span *ngIf="logdataFiles.shp === null">{{
        '_markk_saknarpunkter'
          | dvt
            : '_GPS punkter saknas, ladda upp en .shp fil för att matcha punkter'
      }}</span>
      <span *ngIf="logdataFiles.shp !== null">{{
        '_markk_saknarpunkter_not_shp'
          | dvt: '_Analysis file is missing. Upload the remaining file'
      }}</span>
      <mat-chip-listbox>
        <mat-chip-option
          selected
          [disableRipple]="true"
          color="{{ logdataFiles.shp === null ? 'warn' : '' }}"
        >
          <mat-icon *ngIf="logdataFiles.shp !== null">done</mat-icon> *.shp
          {{ 'fil' | dvtl }}
        </mat-chip-option>
        <mat-chip-option
          selected
          [disableRipple]="true"
          color="{{ logdataFiles.dbf === null ? 'warn' : '' }}"
        >
          <mat-icon *ngIf="logdataFiles.dbf !== null">done</mat-icon> *.dbf
          {{ 'fil' | dvtl }}
        </mat-chip-option>
        <mat-chip-option
          selected
          [disableRipple]="true"
          color="{{ logdataFiles.shx === null ? 'warn' : '' }}"
        >
          <mat-icon *ngIf="logdataFiles.txt !== null">done</mat-icon> *.shx
          {{ 'fil' | dvtl }}
        </mat-chip-option>
      </mat-chip-listbox>
    </div>

    <div class="col">
      <button
        class="bottom-margin"
        color="accent"
        (click)="done()"
        [disabled]="!gotFiles || needAdditionalFiles"
        mat-raised-button
      >
        {{ 'Next' | dvt }}
      </button>
    </div>
  </div>
  <dv-fileupload
    *ngIf="!uploadIsaria && !uploadAugmenta"
    singleFileOnly="true"
    (files)="yaraFileSelected($event)"
    [fileTypes]="fileTypesMetadata.value"
  ></dv-fileupload>
</div>
