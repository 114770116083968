import { Component } from '@angular/core';
import { BaseGeoDataContent } from '../base-geo-data-content/base-geo-data-content';
import { FunctionNestedDefinition } from '../../../../step-settings.types';
import { GeoDataHeaderComponent } from 'app/views/prescription-wizard-page/components/step-settings/components/file-section/components/geo-data-header/geo-data-header.component';
import { NestedPreviewListComponent } from 'app/views/prescription-wizard-page/components/step-settings/components/file-section/components/nested-geo-data-content/components/nested-preview-list/nested-preview-list.component';
import { DvTranslatePipe } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-nested-geo-data-content',
  templateUrl: 'nested-geo-data-content.component.html',
  styleUrls: ['../base-geo-data-content/base-geo-data-content.scss'],
  standalone: true,
  imports: [
    DvTranslatePipe,
    GeoDataHeaderComponent,
    NestedPreviewListComponent,
  ],
})
export class NestedGeoDataContentComponent extends BaseGeoDataContent<FunctionNestedDefinition> {}
