<div
  *ngIf="
    !viewInited || filesError || loadingFiles || (geodataLength$ | async) == 0
  "
  class="no-files"
>
  <p *ngIf="viewInited && !loadingFiles && filesError">{{ filesError }}</p>
  <p *ngIf="!loadingFiles && !filesError && (geodataLength$ | async) == 0">
    {{ 'You have no files' | dvt }}
  </p>
  <mat-progress-spinner
    *ngIf="loadingFiles || !viewInited"
    mode="indeterminate"
  ></mat-progress-spinner>
</div>
<mat-table
  *ngIf="
    viewInited && !loadingFiles && !filesError && (geodataLength$ | async) > 0
  "
  [dataSource]="geodataDataSource"
  class="mat-elevation-z8 file-table"
  multiTemplateDataRows
  matSort
  (matSortChange)="onSortChange($event)"
>
  <ng-container [matColumnDef]="COLUMN_KEYS.CHECKBOX">
    <mat-header-cell class="no-pointer" *matHeaderCellDef>
      <mat-checkbox
        (change)="selectAllFiles($event.checked)"
        (click)="$event.stopPropagation()"
      ></mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      <mat-checkbox
        (change)="checkedChange(file.fileId)"
        [checked]="selectedFiles.includes(file.fileId)"
        (click)="$event.stopPropagation()"
      ></mat-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_KEYS.NAME">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'Name' | dvt }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.NAME"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell class="file-name" *matCellDef="let file">
      <div class="file-name-text" [matTooltip]="file.name">{{ file.name }}</div>
      <dv-file-status [status]="file.status"></dv-file-status>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_KEYS.TYPE">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'Type' | dvt }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.TYPE"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      {{ '_data_filetype_' + file.fileType | dvt : '_' + file.fileType }}
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_KEYS.AREA">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ '__metadata_' + metaTag.totalArea | dvt : '_' + metaTag.totalArea }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.AREA"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      {{ getMetaData(file.fileId, metaTag.totalArea) }}
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_KEYS.CLIENT">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ '__metadata_' + metaTag.customer | dvt : '_' + metaTag.customer }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.CLIENT"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      <div class="value-ellipse">
        {{ getMetaData(file.fileId, metaTag.customer) }}
      </div>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_KEYS.FARM">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ '__metadata_' + metaTag.farm | dvt : '_' + metaTag.farm }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.FARM"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      <div class="value-ellipse">
        {{ getMetaData(file.fileId, metaTag.farm) }}
      </div>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_KEYS.FIELD">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ '__metadata_' + metaTag.field | dvt : '_' + metaTag.field }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.FIELD"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      <div class="value-ellipse">
        {{ getMetaData(file.fileId, metaTag.field) }}
      </div>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_KEYS.DATE">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ '__metadata_' + metaTag.created | dvt : '_' + metaTag.created }}
      <dv-sort-arrows
        class="header-sort-arrows"
        [id]="COLUMN_KEYS.DATE"
        [currentSort]="currentSort"
      ></dv-sort-arrows>
    </mat-header-cell>
    <mat-cell *matCellDef="let file">
      <span
        *ngIf="getMetaData(file.fileId, metaTag.created) !== '-'; else nodate"
      >
        {{ getMetaData(file.fileId, metaTag.created) | date : 'short' }}
      </span>
      <ng-template #nodate>-</ng-template>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_KEYS.MORE">
    <mat-header-cell class="no-pointer" *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let file">
      <img
        src="assets/images/expand-more.svg"
        class="expand-icon"
        [ngClass]="{ 'expand-icon-active': expandedRow === file.fileId }"
      />
      <button
        (click)="$event.stopPropagation()"
        mat-icon-button
        [matMenuTriggerFor]="fileContextMenu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #fileContextMenu="matMenu" xPosition="before">
        <button
          (click)="fileSrv.openGeodataMetadata(file.fileId)"
          mat-menu-item
        >
          <mat-icon>edit</mat-icon>
          {{ 'Edit' | dvt }}
        </button>
        <button mat-menu-item [matMenuTriggerFor]="fileFormatMenu">
          <mat-icon>download</mat-icon>
          {{ 'Download' | dvt }}
        </button>
        <mat-menu #fileFormatMenu="matMenu">
          <button
            (click)="
              fileSrv.downloadFileByFormat(
                file.fileId,
                file.name,
                FILE_FORMAT.SHAPE
              )
            "
            mat-menu-item
          >
            SHP
          </button>
          <button
            (click)="
              fileSrv.downloadFileByFormat(
                file.fileId,
                file.name,
                FILE_FORMAT.CSV
              )
            "
            mat-menu-item
          >
            CSV
          </button>
          <button
            (click)="
              fileSrv.downloadFileByFormat(
                file.fileId,
                file.name,
                FILE_FORMAT.GEOJSON
              )
            "
            mat-menu-item
          >
            GeoJSON
          </button>
        </mat-menu>
        <button (click)="fileSrv.reimportGeoData(file.fileId)" mat-menu-item>
          <mat-icon>refresh</mat-icon>
          {{ 'Reimport' | dvt }}
        </button>
        <button
          (click)="fileSrv.requestDeleteGeodata(file.fileId)"
          mat-menu-item
        >
          <mat-icon>delete</mat-icon>
          {{ 'Delete' | dvt }}
        </button>
        <button (click)="fileSrv.openGeodataDetail(file.fileId)" mat-menu-item>
          <mat-icon>description</mat-icon>
          {{ 'View details' | dvt }}
        </button>
        <button
          (click)="showFileDetail(file.rawFileId)"
          mat-menu-item
          *ngIf="file.rawFileId"
        >
          <mat-icon>source</mat-icon>
          {{ 'View source file' | dvt }}
        </button>
      </mat-menu>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="details">
    <mat-cell
      *matCellDef="let file; let i = dataIndex"
      [@expandableRow]="file.fileId === expandedRow ? 'expanded' : 'collapsed'"
    >
      <dv-geodata-list-detail
        [file]="file"
        (showFileDetail)="showFileDetail(file.rawFileId)"
      ></dv-geodata-list-detail>
    </mat-cell>
  </ng-container>

  <mat-row
    class="table-header-row"
    *matHeaderRowDef="displayedColumns"
  ></mat-row>
  <mat-row
    class="file-row"
    *matRowDef="let row; let i = dataIndex; columns: displayedColumns"
    [ngClass]="{ 'file-row-expanded': expandedRow === row.fileId }"
    (click)="toggleRow(row.fileId)"
  ></mat-row>
  <mat-row
    class="expandable-row"
    *matRowDef="let row; columns: ['details']"
    [ngClass]="{ 'expandable-row-expanded': expandedRow === row.fileId }"
  ></mat-row>
</mat-table>
<div class="bottom-action-area">
  <div class="file-options">
    <button mat-stroked-button [disabled]="!selectedFiles.length">
      {{ 'Share' | dvt }}
      {{ selectedFiles.length ? ' (' + selectedFiles.length + ')' : '' }}
      <img class="bulk-button-icon" src="assets/images/expand-more.svg" />
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="openShareFilesDialog()"
      [disabled]="!selectedFiles.length"
    >
      {{ 'Apply' | dvt }}
    </button>
  </div>
  <mat-paginator
    #geodataPaginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [aria-label]="'Number of datasets per page' | dvt"
  ></mat-paginator>
</div>
