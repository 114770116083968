<dv-fileupload
  *ngIf="!gotFiles"
  (files)="files($event)"
  [disableShpHelper]="true"
  style="margin-bottom: 20px"
></dv-fileupload>

<div *ngIf="needAdditionalFiles" style="margin-bottom: 20px">
  <span *ngIf="soilsamplingFiles.shp === null">{{
    '_markk_saknarpunkter'
      | dvt: '_GPS punkter saknas, ladda upp en .shp fil för att matcha punkter'
  }}</span>
  <span *ngIf="soilsamplingFiles.shp !== null">{{
    '_markk_saknarpunkter_not_shp'
      | dvt: '_Analysis file is missing. Upload the remaining file'
  }}</span>
  <mat-chip-listbox>
    <mat-chip-option
      selected
      [disableRipple]="true"
      color="{{ soilsamplingFiles.shp === null ? 'warn' : '' }}"
    >
      <mat-icon *ngIf="soilsamplingFiles.shp !== null">done</mat-icon> *.shp
      {{ 'fil' | dvtl }}
    </mat-chip-option>
    <mat-chip-option
      selected
      [disableRipple]="true"
      color="{{ soilsamplingFiles.dbf === null ? 'warn' : '' }}"
    >
      <mat-icon *ngIf="soilsamplingFiles.dbf !== null">done</mat-icon> *.dbf
      {{ 'fil' | dvtl }}
    </mat-chip-option>
    <mat-chip-option
      selected
      [disableRipple]="true"
      color="{{ soilsamplingFiles.txt === null ? 'warn' : '' }}"
    >
      <mat-icon *ngIf="soilsamplingFiles.txt !== null">done</mat-icon> *.csv /
      *.txt {{ 'fil' | dvtl }}
    </mat-chip-option>
  </mat-chip-listbox>
  <div
    *ngIf="mappingLayers?.length > 0 && soilsamplingFiles.shp === null"
    class="coordinate-layer-picker"
  >
    <p>
      {{
        '_markk_soilsampl_use_file'
          | dvt: '_OR use coordinates stored from mobile app.'
      }}
    </p>
    <mat-form-field appearance="outline">
      <mat-select
        [(ngModel)]="selectedMappingLayer"
        (selectionChange)="fetchCoordLayer()"
      >
        <mat-option *ngFor="let l of mappingLayers" [value]="l">
          {{ l.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div *ngIf="gotFiles">
  <h3>
    {{
      '_soilsample_prop_picker_title'
        | dvt: '_Pick matching attributes for following information'
    }}
  </h3>
  <div class="prop-row">
    <div class="prop-col">
      <h4>{{ 'Textfile' | dvt }}</h4>
      <div class="prop-row">
        <span>{{ 'Provnummer' | dvt }}</span>
        <mat-form-field appearance="outline">
          <mat-select [(ngModel)]="selTxtProp" (selectionChange)="checkMatch()">
            <mat-option *ngFor="let tp of txtProps" [value]="tp">
              {{ tp }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="prop-col">
      <h4>{{ 'Mapfile' | dvt }}</h4>
      <div class="prop-row">
        <span>{{ 'Provnummer' | dvt }}</span>
        <mat-form-field appearance="outline">
          <mat-select [(ngModel)]="selShpProp" (selectionChange)="checkMatch()">
            <mat-option *ngFor="let sp of shpProps" [value]="sp">
              {{ sp }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="prop-col">
    <span style="margin-bottom: 10px" class="clickable" (click)="reset()">{{
      'Upload new file' | dvt
    }}</span>
    <div *ngIf="!selectedMappingLayer?.newFilestack">
      <mat-checkbox
        [(ngModel)]="deleteCoordinateLayerAfterImport"
      ></mat-checkbox>
      {{ 'Remove used coordinate layer' | dvt }}
    </div>
    <button
      style="margin-bottom: 20px"
      color="accent"
      (click)="combine()"
      [disabled]="!selTxtProp || !selShpProp || !precheckMatch"
      mat-raised-button
    >
      {{ 'Next' | dvt }}
    </button>
  </div>
</div>

<div *ngIf="rows && rows.length > 0 && !precheckMatch">
  {{ 'Found {0} rows in textfile' | dvt: rows.length }}
</div>
<div *ngIf="precheckMatch">
  {{ 'Found {0} matching rows' | dvt: matchCount }}
</div>
<div
  *ngIf="
    soilsamplingFiles.fc &&
    soilsamplingFiles.fc.features.length > 0 &&
    !precheckMatch
  "
>
  {{ 'Found {0} rows in mapfile' | dvt: soilsamplingFiles.fc.features.length }}
</div>
<div style="color: red" *ngIf="noShpRows">
  {{ 'Shapefile do not contain any rows' | dvt }}
</div>

<div style="color: red" *ngIf="gotFiles && !precheckMatch">
  {{ 'No matching rows found' | dvt }}
</div>
