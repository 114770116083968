import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';
import { DialogService } from 'app/components/dialog/dialog.service';
import { GeoDataFileMetaTags } from 'app/models/geodata.model';
import { GeoDataFileModel, GeoDataMetaData } from 'app/models/models';
import { ClientService } from 'app/services/client.service';
import { DataFileService } from 'app/services/data-file.service';

@Component({
  selector: 'dv-geodata-list-detail',
  templateUrl: './geodata-list-detail.component.html',
  styleUrls: ['./geodata-list-detail.component.scss'],
})
export class GeoDataListDetailComponent {
  @Output() showFileDetail = new EventEmitter<number>();
  @Input() set file(value: GeoDataFileModel) {
    this.geodata = value;
    this.reservedData = [];
    this.customData = [];
    this.geodata.metadata.forEach((d) => {
      switch (d.key) {
        case GeoDataFileMetaTags.customer:
          this.reservedData.push({
            key: this.translateSrv.t(
              '__metadata_' + GeoDataFileMetaTags.customer,
              '_Customer'
            ),
            value: d.value,
          });
          break;
        case GeoDataFileMetaTags.farm:
          this.reservedData.push({
            key: this.translateSrv.t(
              '__metadata_' + GeoDataFileMetaTags.farm,
              '_Farm'
            ),
            value: d.value,
          });
          break;
        case GeoDataFileMetaTags.field:
          this.reservedData.push({
            key: this.translateSrv.t(
              '__metadata_' + GeoDataFileMetaTags.field,
              '_Field'
            ),
            value: d.value,
          });
          break;
        case GeoDataFileMetaTags.created:
          this.reservedData.push({
            key: this.translateSrv.t(
              '__metadata_' + GeoDataFileMetaTags.created,
              '_Created'
            ),
            value: d.value,
          });
          break;
        case GeoDataFileMetaTags.totalArea:
          this.reservedData.push({
            key: this.translateSrv.t(
              '__metadata_' + GeoDataFileMetaTags.totalArea,
              '_Total area'
            ),
            value: d.value + ' m2',
          });
          break;
        case GeoDataFileMetaTags.totalYield:
          this.reservedData.push({
            key: this.translateSrv.t(
              '__metadata_' + GeoDataFileMetaTags.totalYield,
              '_Total yield'
            ),
            value: d.value + 'kg',
          });
          break;
        default:
          this.customData.push(d);
      }
    });
  }

  get metadata(): GeoDataFileModel {
    return this.geodata;
  }

  geodata: GeoDataFileModel;
  reservedData: GeoDataMetaData;
  customData: GeoDataMetaData;

  constructor(
    private translateSrv: DvToolbarTranslateService,
    public fileSrv: DataFileService,
    private dialogSrv: DialogService,
    private clientSrv: ClientService,
    private snackBar: MatSnackBar
  ) {}

  deleteGeodata(geoFileId: number): void {
    this.dialogSrv
      .confirm(
        this.translateSrv.t(
          '_confirm_delete_geodatafile',
          '_Are you sure you want to hide this data in CropMAP?'
        )
      )
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.clientSrv.deleteGeoDataFiles(geoFileId).subscribe(
            () => {
              this.snackBar.open(
                this.translateSrv.t('Data deleted'),
                this.translateSrv.t('Ok'),
                {
                  duration: 3000,
                }
              );
              this.fileSrv.deleteFromGeodata(geoFileId);
            },
            () => {
              this.snackBar.open(
                this.translateSrv.t('Data could not be deleted'),
                this.translateSrv.t('Ok'),
                {
                  duration: 3000,
                }
              );
            }
          );
        }
      });
  }

  showFilesetDetail(id: number): void {
    this.showFileDetail.emit(id);
  }
}
