/* eslint-disable @angular-eslint/no-output-on-prefix */
/// <reference types="@types/googlemaps" />
import {
  AfterViewInit,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import * as turf from '@turf/turf';
import { AllGeoJSON, Feature } from '@turf/turf';
import { DeviationModel } from 'app/models/interpolation.model';
import { MapStateService } from 'app/services/map-state.service';
import * as GeoJSON from 'geojson';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, take, toArray } from 'rxjs/operators';
import {
  Extent,
  LAYER_EDITABLE,
  LAYER_NAME,
  LAYER_NAME_BLOCK,
  LAYER_NAME_FILEDATA,
  LAYER_NAME_MARKERING,
  LAYER_NAME_SKIFTEN,
  LAYER_SELECTED,
  LAYER_ZINDEX,
  MapService,
} from './map.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-map',
  templateUrl: 'map.component.html',
  styleUrls: ['map.component.scss'],
})
export class MapComponent implements OnDestroy {
  private _zPoint = 100000000000;
  private _zArc = 1000000000;
  private _zPoly = 10000000;

  private _zSkifte = 100000;
  private _zBlock = 1;

  private layers: string[] = [];
  readonly DEFAULT_LOADING_TEXT = 'Loading information...';

  private drawFeature$: ReplaySubject<google.maps.Data.Feature> =
    new ReplaySubject<google.maps.Data.Feature>(1);

  // rcm = rightClickMenu
  rcmShowCreateField = false;

  /*This is events and propertys that handels tools*/
  /**
   * A emitter that will fire evry time a tool is active or null if no tool is set to active
   */
  @Output() toolActive = new EventEmitter<any>();
  drawPoly: google.maps.Polygon;
  /**
   * Sets a given tool to active or null if no tool is to be active
   * @param tool
   */
  @Input() setToolActive(tool: any): void {
    this.toolActive.emit(tool);
  }

  loading = false;
  loadingText = this.DEFAULT_LOADING_TEXT;

  /**
   * Emits a event when the map is loaded, before this it is not safe to work with the map
   */
  mapLoaded = new ReplaySubject<MapComponent>(1);
  /**
   * The google map, this is the undelaying map object provided from google
   */
  map: google.maps.Map = null;
  /**
   * True if multiselect is to be used, default is false
   * Alter this will only affect the default click event, it's up the every click event handler to honor this value
   */
  @Input() multiSelect: boolean;
  /**
   * EventEmitter that emitt a value evry time a feature is selected
   */
  @Output() onSelected = new EventEmitter<google.maps.Data.Feature>();
  /**
   * EventEmitter that emitt a value evry time a feature is deselected
   */
  @Output() onDeSelected = new EventEmitter<google.maps.Data.Feature>();

  @Output() onEditStart = new EventEmitter<google.maps.Data.Feature>();

  @Output() onEditStop = new EventEmitter<google.maps.Data.Feature>();

  /**
   * EventEmitter that emitt a value evry time a feature collection is added or removed, emits the collection with layers
   */
  @Output() onLayerChanged = new EventEmitter<string[]>();

  /**
   * Set to true if this map component shall be registred as main map, this property will only be read in the ngAfterViewInit face
   * Changes to this property will not change any state after ngAfterViewInit has fired
   * This map compnent can lose its main map status if it is overriden later
   */
  @Input() isMainMap: boolean;

  /**
   * The name of the map, if this is a main map set that property to true
   */
  @Input() mapName: string = null;

  /**
   * Set to true to disable selecting of features
   */
  @Input() disableSelect: boolean;

  @Input() fullScreen = true;

  @ViewChild('leftMenu', { static: true }) leftMenu: MatSidenav;
  @Input()
  set leftMenuOpen(open: boolean) {
    if (open) {
      this.leftMenu.open();
    } else {
      this.leftMenu.close();
    }
  }

  private listeners: any[] = [];
  private isDrawing = false;
  deviations$ = new BehaviorSubject<DeviationModel[]>([]);
  private updateDeviationsTimer: ReturnType<typeof setTimeout>;
  private infoWindow;

  constructor(
    private zone: NgZone,
    private mapService: MapService,
    private translateService: DvToolbarTranslateService,
    private mapStateSrv: MapStateService
  ) {}

  mapInit(el: ElementRef): void {
    if (this.map !== null) {
      return;
    }

    let center = { lng: 12.726764, lat: 58.299456 };
    if (window.localStorage.getItem('center')) {
      center = JSON.parse(window.localStorage.getItem('center'));
    }

    let zoom = 7;
    if (window.localStorage.getItem('zoom')) {
      zoom = parseInt(window.localStorage.getItem('zoom'));
    }

    let mapType = google.maps.MapTypeId.SATELLITE;
    if (window.localStorage.getItem('maptype')) {
      mapType = <google.maps.MapTypeId>window.localStorage.getItem('maptype');
    }

    const opts: google.maps.MapOptions = {
      center: center,
      zoom: zoom,
      mapTypeId: mapType,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: this.fullScreen,
      zoomControl: false,
    };

    this.map = new google.maps.Map(el.nativeElement, opts);

    this.map.data.addListener('click', (event: google.maps.Data.MouseEvent) => {
      if (!this.drawPoly) {
        this.zone.run(() => {
          this.mapService.clickEvent(
            event.feature.getProperty(LAYER_NAME),
            event,
            this
          );
        });
      }
    });

    this.map.addListener('zoom_changed', () => {
      window.localStorage.setItem('zoom', this.map.getZoom().toString());
    });

    this.map.addListener('center_changed', () => {
      window.localStorage.setItem(
        'center',
        JSON.stringify(this.map.getCenter())
      );
    });

    this.map.data.setStyle((feature: google.maps.Data.Feature) => {
      return this.mapService.styles(
        feature.getProperty(LAYER_NAME),
        feature,
        this
      );
    });

    if (this.isMainMap) {
      this.mapService.registerMainMap(this);
    } else if (this.mapName) {
      this.mapService.registerMap(this, this.mapName);
    }

    this.mapLoaded.next(this);

    this.map.getDiv().dispatchEvent(new Event('resize'));
  }
  ngOnDestroy(): void {
    if (this.isMainMap) {
      this.mapService.registerMainMap(null);
    }
  }

  setDraggable(val: boolean): void {
    this.map.setOptions({ draggable: val });
  }

  /**
   * Adds a geoJson to the map, the geojson must have a name and optinal a settings class
   * @param featureColl
   */
  addGeoJson(
    featureColl:
      | GeoJSON.FeatureCollection<GeoJSON.GeometryObject>
      | turf.helpers.FeatureCollection
      | GeoJSON.FeatureCollection,
    layerName: string
  ): google.maps.Data.Feature[] {
    //fix for features without ID's
    featureColl.features.map((f, index) => (f.id = f.id ? f.id : index + 1));

    const features = this.map.data.addGeoJson(featureColl);
    features.forEach((f) => {
      f.setProperty(LAYER_NAME, layerName);
      f.setProperty(LAYER_ZINDEX, this.zIndex(f));
      if (layerName === LAYER_NAME_FILEDATA) {
        f.setProperty('fillOpacity', 0.4);
      }
    });

    try {
      if (
        (layerName === LAYER_NAME_SKIFTEN ||
          layerName === LAYER_NAME_FILEDATA ||
          LAYER_NAME_MARKERING) &&
        featureColl?.features?.length &&
        [...featureColl.features].filter(
          (f) => f.properties._layerName === layerName
        ).length > 0
      ) {
        const fc = <GeoJSON.FeatureCollection>{
          type: 'FeatureCollection',
          features: [...featureColl.features].filter(
            (f) =>
              f.properties._layerName === layerName &&
              f.properties.LAYER_SELECTED === true
          ),
        };
        if (layerName === LAYER_NAME_SKIFTEN) {
          this.mapStateSrv.addSelectedFeatures(fc);
        } else if (layerName === LAYER_NAME_MARKERING) {
          this.mapStateSrv.setSelectedMarkings(fc);
        }
      }
    } catch (err) {
      console.log('error', err);
    }

    if (layerName === LAYER_NAME_MARKERING) {
      this.mapService.registerStyle(
        LAYER_NAME_MARKERING,
        (feature: google.maps.Data.Feature) => {
          let color = '#F44336';
          if (feature.getProperty('utford')) {
            color = '#4CAF50';
          }

          return {
            fillColor: color,
            strokeColor: color,
            strokeWeight: 2,
            fillOpacity: 0.8,
            icon: {
              url:
                feature.getProperty('imgeRef')?.length > 0
                  ? 'https://dvwebphoto.blob.core.windows.net/' +
                    feature.getProperty('klientId') +
                    '/' +
                    feature.getProperty('imgeRef') +
                    '/circle.png'
                  : 'https://api.datavaxt.se' +
                    '/map/dot/' +
                    color.substring(1, color.length),
            },
            zIndex: this.zIndex(feature),
          };
        }
      );
    }

    if (!this.layers.includes(layerName)) {
      this.layers.push(layerName);
      this.onLayerChanged.emit(this.layers);
    }

    return features;
  }

  /***
   * Selects a given feature by its id, will fire select and deselect events
   */
  selectedFeature(id: number): boolean {
    const f = this.map.data.getFeatureById(id);
    if (f) {
      this.mapService.selectFeature(f, this);
    }
    return f ? true : false;
  }

  selectAllFeatures(): void {
    this.map.data.toGeoJson((f) => {
      this.mapStateSrv.setSelectedFeatures({
        type: 'FeatureCollection',
        features: f['features'],
      });
    });
    this.map.data.forEach((f) => {
      f.setProperty(LAYER_SELECTED, true);
    });
  }

  /**
   * Removes a given layer, if the layer is selected the onDeSelected event will be fired
   * @param layerName
   */
  removeLayer(layerName: string): void {
    if (this.map) {
      this.map.data.forEach((f) => {
        if (f.getProperty(LAYER_NAME) === layerName) {
          if (f.getProperty(LAYER_SELECTED)) {
            this.onDeSelected.next(f);
          }
          this.map.data.remove(f);
        }
      });

      if (this.layers.splice(this.layers.indexOf(layerName))) {
        this.onLayerChanged.emit(this.layers);
      }
    }
  }

  /**
   * Clears the map from all features and layers, will not fire deselect
   * @param except dont remove layers with the name in except
   */
  clearMap(except?: string[]): void {
    this.map.data.forEach((f) => {
      if (!except || !except.includes(f.getProperty(LAYER_NAME))) {
        this.map.data.remove(f);
      }
    });
  }

  /**
   * Centers the map from a given lat and lng
   * @param lat
   * @param lng
   */
  setCenter(lat: number, lng: number): void {
    this.map.panTo(new google.maps.LatLng(lat, lng));
  }

  setZoom(zoom: number): void {
    this.map.setZoom(zoom);
  }

  setType(mapType: google.maps.MapTypeId): void {
    window.localStorage.setItem('maptype', mapType.toString());

    const opts: google.maps.MapOptions = {
      mapTypeId: mapType,
    };
    this.map.setOptions(opts);
  }
  zoomIn(): void {
    if (this.map.getZoom() === 19) {
      this.setType(google.maps.MapTypeId.ROADMAP);
    }

    this.map.setZoom(this.map.getZoom() + 1);
  }

  zoomOut(): void {
    this.map.setZoom(this.map.getZoom() - 1);
  }

  /**
   * Centers a feature collection with in the viewport
   * @param features
   */
  fitFeature(
    features: google.maps.Data.Feature[],
    onlyIfOutside = false
  ): void {
    const bounds = this.getFeatureBounds(features);
    if (!bounds.isEmpty()) {
      if (
        !onlyIfOutside ||
        !bounds.contains(this.map.getCenter()) ||
        this.map.getZoom() < 10
      ) {
        this.map.fitBounds(bounds);
      }
    }
  }

  /**
   * Pans to a feature collection with in the viewport, if h
   * @param features
   */
  panToFeature(features: google.maps.Data.Feature[]): void {
    const bounds = this.getFeatureBounds(features);
    if (!bounds.isEmpty()) {
      this.map.panToBounds(bounds);
    }
  }

  /**
   * Gets a lat lang bounds ie extent for a given collection of features
   * @param featureColl
   */
  getFeatureBounds(
    features: google.maps.Data.Feature[]
  ): google.maps.LatLngBounds {
    const bounds = new google.maps.LatLngBounds();

    features.forEach(function (f: google.maps.Data.Feature) {
      f.getGeometry().forEachLatLng((latLng) => bounds.extend(latLng));
    });
    return bounds;
  }

  /**
   * Gets a collection with selected features for a given layer, or all selected features if no name was provided
   * @param layerName
   */
  getSelectedFeatures(layerName?: string): google.maps.Data.Feature[] {
    const coll: google.maps.Data.Feature[] = [];
    this.map.data.forEach((feature) => {
      if (
        (layerName === undefined ||
          layerName === feature.getProperty(LAYER_NAME)) &&
        feature.getProperty(LAYER_SELECTED)
      ) {
        coll.push(feature);
      }
    });
    return coll;
  }

  /**
   * Gets a colection with selected features for a given layer name
   * @param layerName
   */
  getFeatures(layerName: string): google.maps.Data.Feature[] {
    const coll: google.maps.Data.Feature[] = [];
    this.map.data.forEach((feature) => {
      if (layerName === feature.getProperty(LAYER_NAME)) {
        coll.push(feature);
      }
    });
    return coll;
  }

  /**
   * Deselects all features for a given layer, or all features if no name was provided, will call onDeSelected for each selected layer
   * @param layerName
   */
  deSelectedFeatures(layerName?: string): void {
    this.map.data.forEach((feature) => {
      if (
        (layerName === undefined ||
          layerName === feature.getProperty(LAYER_NAME)) &&
        feature.getProperty(LAYER_SELECTED)
      ) {
        feature.setProperty(LAYER_SELECTED, false);
        this.onDeSelected.next(feature);
      }
    });
  }

  /**
   * Marks a given feature for edit
   * @param feature
   */
  edit(feature: google.maps.Data.Feature): void {
    this.map.data.forEach((f) => {
      if (feature) {
        if (f === feature) {
          f.setProperty(LAYER_EDITABLE, true);
        } else {
          f.setProperty(LAYER_EDITABLE, false);
        }
      } else {
        f.setProperty(LAYER_EDITABLE, undefined);
      }
    });
  }

  /**
   * Gets a Tatuk friendly extent for a given bounds or the visible map if no bounds was provided
   * @param bounds
   */
  getExtent(bounds?: google.maps.LatLngBounds): Extent {
    bounds = bounds || this.map.getBounds();

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    return {
      minX: sw.lat(),
      minY: sw.lng(),
      maxX: ne.lat(),
      maxY: ne.lng(),
    };
  }

  /**
   * Gets a z index, this index is increasing on every fetch
   * The index is guaranteed to be larger than any skifte on the map
   */
  zIndex(f?: google.maps.Data.Feature): number {
    if (f && f.getProperty(LAYER_NAME) === LAYER_NAME_SKIFTEN) {
      return this.zIndexSkifte();
    } else if (f && f.getProperty(LAYER_NAME) === LAYER_NAME_BLOCK) {
      return ++this._zBlock;
    }

    let type = '';
    if (f) {
      type = f.getGeometry().getType();
    }

    switch (type) {
      case 'Point':
      case 'MultiPoint':
        return ++this._zPoint;
      case 'LineString':
      case 'MultiLineString':
      case 'LinearRing':
        return ++this._zArc;
      case 'Polygon':
      case 'MultiPolygon':
      case 'GeometryCollection':
      default:
        return ++this._zPoly;
    }
  }

  /**
   * A z index for skifte, this will guarantee that the skifte allways is the lowest polygon
   */
  zIndexSkifte(): number {
    return ++this._zSkifte;
  }
  /**
   * Returens a observable that will resolve in FeatureCollection when all features is converted
   * This is preferred whay to convert a google data features to a GeoJson FeatureCollection
   * @param features A array of features to be turned to geoJson
   * @param keepInternal If properties shall be copied over
   */
  getGeoJson(
    features: google.maps.Data.Feature[],
    keepInternal?: boolean
  ): Observable<GeoJSON.FeatureCollection<GeoJSON.GeometryObject>> {
    //Create a subject that we will use to parse the data  features to GeoJson Features
    const sub = new ReplaySubject<GeoJSON.Feature<GeoJSON.GeometryObject>>(
      features.length
    );

    features.map((feature) => {
      feature.toGeoJson((f: any) => {
        //we remove all properties that starts with _ this is internall properties that we dont wont to pas along
        if (!keepInternal) {
          for (const key in f.properties) {
            if (key.startsWith('_')) {
              delete f.properties[key];
            }
          }
        }

        sub.next(f);
      });
    });

    const res = sub.pipe(
      take(features.length),
      toArray(),
      map((values: GeoJSON.Feature<GeoJSON.GeometryObject>[]) => {
        return <GeoJSON.FeatureCollection<GeoJSON.GeometryObject>>{
          features: values,
          type: 'FeatureCollection',
          id: 0,
        };
      })
    );

    //we need to close the stream before toArray kick in, with take we will do this
    return res;
  }

  startDrawFeature(): Observable<google.maps.Data.Feature> {
    this.drawFeature$.next(null);
    this.drawPoly = new google.maps.Polygon();
    this.drawPoly.setMap(this.map);
    this.drawPoly.setEditable(true);
    this.drawPoly.setOptions({ clickable: false });

    this.map.setOptions({
      draggableCursor: 'crosshair',
    });

    //clicks outside polygon
    google.maps.event.addListener(this.map, 'mouseup', (ev) => {
      // don't add a vertex if right click or alt+click
      if (!(ev.ub && (ev.ub.altKey || ev.ub.button === 2))) {
        this.drawPoly.getPath().push(ev.latLng);
      }
      if (this.drawPoly.getPath().getLength() > 2) {
        this.emitFeature();
      }
    });

    //changes vertexes to polygon
    google.maps.event.addListener(this.drawPoly, 'mouseup', () => {
      if (this.drawPoly.getPath().getLength() > 2) {
        this.emitFeature();
      }
    });

    //deletes vertexes from polygon
    google.maps.event.addListener(this.drawPoly, 'dblclick', (ev) => {
      this.drawPoly.getPath().removeAt(ev.vertex);
      this.emitFeature();
    });

    return this.drawFeature$.asObservable();
  }

  hide(feature: google.maps.Data.Feature): void {
    if (feature) {
      this.map.data.forEach((f) => {
        if (f === feature || f.getId() === feature.getId()) {
          f.setProperty('strokeWeight', 0.00001);
          f.setProperty('fillOpacity', 0.00001);
        }
      });
    }
  }

  show(feature: google.maps.Data.Feature): void {
    if (feature) {
      this.map.data.forEach((f) => {
        if (f === feature || f.getId() === feature.getId()) {
          f.setProperty('strokeWeight', 2);
          f.setProperty('fillOpacity', 1.0);
        }
      });
    }
  }

  private emitFeature(): void {
    const tPoly = this.getTurfPolygon(this.drawPoly);
    tPoly.geometry;
    const fc = turf.featureCollection([tPoly]);
    const addedFeatures = this.addGeoJson(fc, '_temp');
    addedFeatures[0].setProperty(
      'areal',
      turf.area(<AllGeoJSON>tPoly.geometry)
    );
    this.removeLayer('_temp');
    this.drawFeature$.next(addedFeatures[0]);
  }

  stopDrawFeature(): void {
    if (this.drawPoly) {
      google.maps.event.clearListeners(this.drawPoly, 'mouseup');
      google.maps.event.clearListeners(this.drawPoly, 'dblclick');
      this.drawPoly.setMap(null);
      this.drawPoly = null;
    }

    this.map.setOptions({
      draggableCursor: '',
    });

    google.maps.event.clearListeners(this.map, 'mouseup');
  }

  private getTurfPolygon(googlePoly: google.maps.Polygon): Feature {
    const rings = [];
    googlePoly.getPaths().forEach((ring) => {
      const ringLatLng = ring.getArray().map((latLng) => {
        return [latLng.lng(), latLng.lat()];
      });
      ringLatLng.push(ringLatLng[0]);

      rings.push(ringLatLng);
    });

    if (rings.length > 0 && rings[0].length > 3) {
      return turf.polygon(rings);
    }
    return null;
  }

  setDrawingMode(draw: boolean, currentDeviation: DeviationModel): void {
    this.map.setOptions({ draggable: !draw });
    if (draw) {
      this.listeners.push(
        this.map.data.addListener(
          'click',
          (event: google.maps.Data.MouseEvent) => {
            this.updateColor(event.feature, currentDeviation);
          }
        )
      );

      this.listeners.push(
        this.map.data.addListener(
          'mousedown',
          (event: google.maps.Data.MouseEvent) => {
            this.updateColor(event.feature, currentDeviation);
            this.isDrawing = true;
          }
        )
      );

      this.listeners.push(
        google.maps.event.addListener(this.map, 'mousedown', (ev) => {
          if (!this.infoWindow) {
            this.infoWindow = new Popup(
              ev.latLng,
              this.translateService.t(
                'deselect the drawing mode to move around the map'
              )
            );
            this.infoWindow.setMap(this.map);
            setTimeout(() => {
              this.infoWindow.delete();
              setTimeout(() => {
                this.infoWindow = null;
              }, 500);
            }, 1000);
          }
        })
      );

      this.listeners.push(
        this.map.data.addListener('mouseup', () => {
          this.isDrawing = false;
        })
      );

      this.listeners.push(
        this.map.data.addListener(
          'mouseover',
          (event: google.maps.Data.MouseEvent) => {
            if (this.isDrawing) {
              this.updateColor(event.feature, currentDeviation);
            }
          }
        )
      );
    } else {
      this.listeners.forEach((listener) => {
        google.maps.event.removeListener(listener);
      });

      if (currentDeviation?.features.length > 0) {
        this.saveDeviation(currentDeviation);
      }
    }
  }

  clearDeviations(): void {
    this.deviations$.next([]);
  }

  private saveDeviation(deviation: DeviationModel): void {
    const value = this.deviations$.getValue();
    if (value.find((d) => d.id === deviation.id)) {
      value.find((d) => d.id === deviation.id).features = deviation.features;
      this.deviations$.next(value);
    } else {
      this.deviations$.next([...this.deviations$.getValue(), deviation]);
    }
  }

  deleteDeviation(deviation: DeviationModel): void {
    this.listeners.forEach((listener) => {
      google.maps.event.removeListener(listener);
    });
    clearTimeout(this.updateDeviationsTimer);
    this.deviations$.next(
      this.deviations$.getValue().filter((d) => d.id !== deviation.id)
    );
    if (!this.deviations$.value || this.deviations$.value.length === 0) {
      this.setDrawingMode(false, null);
    }
  }

  private updateColor(feature, deviation: DeviationModel): void {
    if (feature.getProperty('activeDeviation')) {
      return;
    } else {
      feature.setProperty('activeDeviation', true);
      if (!feature.getProperty('oldColor')) {
        feature.setProperty('oldColor', feature.getProperty('color'));
      }

      if (!feature.getProperty('oldRate')) {
        feature.setProperty('oldRate', feature.getProperty('rate'));
      }

      feature.setProperty('color', deviation.color);
      feature.setProperty('rate', deviation.deviationRate);

      deviation.features.push(feature);
    }
    clearTimeout(this.updateDeviationsTimer);
    this.updateDeviationsTimer = setTimeout(() => {
      this.saveDeviation(deviation);
    }, 3000);
  }

  getDeviations(): Observable<DeviationModel[]> {
    return this.deviations$.asObservable();
  }

  setDeviations(deviations: DeviationModel[]): void {
    this.deviations$.next(deviations);
  }

  setLoadStatus(loading: boolean): void {
    this.loading = loading;
  }
}

@Directive({ selector: '[dvMapInit]' })
export class MapInitDirective implements AfterViewInit {
  constructor(private ref: ElementRef) {}

  @Output('dvMapInit')
  loaded = new EventEmitter<ElementRef>();

  ngAfterViewInit(): void {
    window.setTimeout(() => {
      this.loaded.emit(this.ref);
    }, 0);
  }
}

class Popup extends google.maps.OverlayView {
  position: google.maps.LatLng;
  containerDiv: HTMLDivElement;
  fadeEffect;

  constructor(position: google.maps.LatLng, text: string) {
    super();
    this.position = position;

    this.containerDiv = document.createElement('div');
    this.containerDiv.classList.add('popup-drag-locked');
    this.containerDiv.innerHTML = text;

    // Optionally stop clicks, etc., from bubbling up to the map.
    Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
  }

  /** Called when the popup is added to the map. */
  onAdd(): void {
    this.getPanes()?.floatPane.appendChild(this.containerDiv);
  }

  delete(): void {
    if (this.containerDiv.parentElement) {
      this.fadeEffect = setInterval(() => {
        if (!this.containerDiv.style.opacity) {
          this.containerDiv.style.opacity = '1';
        }
        if (parseFloat(this.containerDiv.style.opacity) > 0) {
          this.containerDiv.style.opacity = (
            parseFloat(this.containerDiv.style.opacity) - 0.1
          ).toString();
        } else {
          clearInterval(this.fadeEffect);
          this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
      }, 50);
    }
  }

  draw(): void {
    const divPosition = this.getProjection().fromLatLngToDivPixel(
      this.position
    );

    // Hide the popup when it is far out of view.
    const display =
      Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
        ? 'block'
        : 'none';

    if (display === 'block') {
      this.containerDiv.style.left = divPosition.x + 'px';
      this.containerDiv.style.top = divPosition.y + 'px';
    }

    if (this.containerDiv.style.display !== display) {
      this.containerDiv.style.display = display;
    }
  }
}
