<div class="bucket-container">
  <div class="titles">
    <span>Index</span>
    <span>{{ unit }}</span>
  </div>
  <div class="buckets">
    <div class="bucket" *ngFor="let bucket of prescription.buckets">
      <div class="index">
        <span class="dv-text-color">{{ bucket.breakpoint }}</span>
      </div>
      <div>
        <mat-form-field class="input-value" appearance="outline">
          <input
            [(ngModel)]="bucket.prescription"
            (ngModelChange)="modelChanged($event)"
            [disabled]="disabled"
            matInput
          />
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
