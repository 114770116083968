<div class="content">
  <div
    class="step"
    *ngFor="let state of stepState; let i = index"
    [ngClass]="{
      'step-active': selectedIndex === i,
      'step-completed': selectedIndex > i,
      'step-inactive': selectedIndex < i,
      'step-clickable': i === 0 || stepState[i - 1].completed
    }"
    (click)="onSelectStep(i)"
  >
    <div class="step-circle">
      <ng-container *ngIf="i >= selectedIndex; else completed">
        {{ i + 1 }}
      </ng-container>
      <ng-template #completed>
        <mat-icon class="step-circle-icon">done</mat-icon>
      </ng-template>
    </div>
    <div class="step-text">{{ state.label }}</div>
  </div>
</div>
