import { PrescriptionExportData } from '../prescription-export.types';

export interface MachineProvider {
  providerCode: string;
  name: string;
  isConnected: boolean;
}

export interface Machine {
  id: MachineId;
  name: string;
  provider: Provider;
}

export interface MachineId {
  id: string;
}

export interface Provider {
  code: string;
  name: string;
}

export interface PrescriptionRequest {
  machineId: MachineId;
  fileName: string;
  geoJson: string;
  sourceMetadata: PrescriptionRequestMetaData;
}

export interface PrescriptionRequestMetaData {
  epsg: number;
  gridSize: number;
  unit: string;
}

export interface MachineConnectionData extends PrescriptionExportData {
  machineId: MachineId;
}

export enum MACHINE_CONNECTION_STEP {
  INIT,
  SELECT_PROVIDER,
  SIGN_IN,
  MACHINE_LIST,
  SUCCESS,
}

export enum INIT_STATUS {
  INIT = 'Initiates',
  FETCHING_PROVIDERS = 'Fetching providers',
  PROVIDER_AUTH = 'Authenticating to providers',
  FETCHING_MACHINES = 'Fetching machines',
}
