import {
  Component,
  Input,
  NgZone,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import * as turf from '@turf/turf';
import { MapToolComponent } from '../map-tool';
import { SiteService } from 'app/services/site.service';
import { MapService } from '../../map.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-map-split-area',
  templateUrl: 'map-split-area.component.html',
  styleUrls: ['map-split-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapSplitAreaComponent
  extends MapToolComponent
  implements OnDestroy
{
  @Input() splitMode = '';

  message = '';
  splitDistance: string = this.translateService.t('Split by distance');
  splitArea: string = this.translateService.t('Split by area');
  selectedArea = 0;
  /***
   * Set this variable is chosen split method is distance from the side
   */
  targetDistance: number = null;
  /**
   * Set this variable is chosen split method is user specified area
   */
  targetArea: number = null;

  private mapClickEvent: google.maps.MapsEventListener = null;

  constructor(
    siteService: SiteService,
    public translateService: DvToolbarTranslateService,
    mapService: MapService,
    cd: ChangeDetectorRef,
    zone: NgZone
  ) {
    super(siteService, mapService, cd, zone, translateService);
  }

  protected onMapInit(): void {
    this.message = this.translateService.t('Click aside the parcel to split');
  }

  /**
   * Called when the tool sould be actived
   */
  onActive(): void {
    this.mapClickEvent = this.dvMap.map.addListener('click', (event) => {
      this.mapService
        .topology({
          featureA: this.selectedFeature,
          featureB: null,
          pointA: { lng: event.latLng.lng(), lat: event.latLng.lat() },
          pointB: null,
          dist: this.targetDistance,
          area: this.targetArea * this.siteService.getAreaFactor(),
          operator: this.targetArea > 0 ? 'SplitArea' : 'SplitDist',
        })
        .subscribe((f) => {
          this.setParts(f);
          this.cd.markForCheck();
        });
    });

    this.selectedArea = this.siteService.calculateArea(
      turf.area(this.selectedFeature)
    );

    this.activated.next();
  }

  /**
   * Called when the tool is deactivated ie not active
   */
  onDeActivated(): void {
    this.cancel.next();
    if (this.mapClickEvent) {
      this.mapClickEvent.remove();
    }
  }

  ngOnDestroy(): void {
    this.clean();
    if (this.mapClickEvent) {
      this.mapClickEvent.remove();
    }
  }
}
