import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dv-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.css']
})
export class PromptComponent implements OnInit {
  value: string;
  constructor(private dialogRef: MatDialogRef<PromptComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
  }

  save() {
    this.dialogRef.close(this.value);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
