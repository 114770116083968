<mat-dialog-content>
  <button mat-dialog-close mat-mini-fab color="primary">
    <mat-icon>close</mat-icon>
  </button>
  <h2 mat-dialog-title>
    {{ 'File status' | dvt }}
  </h2>
  <mat-list>
    <mat-list-item class="empty" *ngIf="log && log.length === 0">
      {{ "You don't have any uploaded files" | dvt }}
    </mat-list-item>
    <mat-list-item class="list-row" *ngFor="let entry of log">
      <img
        mat-list-icon
        *ngIf="entry.success"
        src="assets/images/icon-success.svg"
      />
      <img
        mat-list-icon
        *ngIf="entry.warning"
        src="assets/images/icon-warning.svg"
      />
      <img
        mat-list-icon
        *ngIf="entry.error"
        src="assets/images/icon-error.svg"
      />
      <h4 matLine>{{ entry.message }}</h4>
      <p matLine>{{ entry.timestamp | date: 'short' }}</p>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
