import { Component, OnInit } from '@angular/core';
import { BrukningsenhetModel } from 'app/models/api.models';
import { ClientService } from 'app/services/client.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'dv-bruk-enh-dialog',
    templateUrl: './bruk-enh-dialog.component.html',
    styleUrls: ['./bruk-enh-dialog.component.scss'],
})
export class BrukEnhDialogComponent implements OnInit {
    public brukEnheter: BrukningsenhetModel[] = [];
    public loading = false;
    public activeEnh: BrukningsenhetModel;
    constructor(private clientSrv: ClientService) {}
    public error: string = null;
    public deleteError: boolean = false;

    ngOnInit(): void {
        this.getBrukEnh();
    }

    getBrukEnh() {
        this.clientSrv
            .getBrukningsenheter()
            .pipe(first())
            .subscribe((brukenheter) => {
                this.brukEnheter = brukenheter;
            });
    }

    openFarm(farm) {
        this.activeEnh = Object.assign({}, farm);
    }

    cancel() {
        this.activeEnh = null;
        this.error = null;
        this.deleteError = false;
    }

    delete(farm) {
        this.deleteError = false;
        this.clientSrv
            .deleteBrukningsenhet(farm)
            .pipe(first())
            .subscribe(
                (res) => {
                    this.brukEnheter = this.brukEnheter.filter((bruk) => bruk.id !== farm.id);
                    this.activeEnh = null;
                },
                (err) => {
                    this.deleteError = true;
                }
            );
    }
    save(activeEnh) {
        this.error = null;
        this.loading = true;
        this.clientSrv
            .updateBrukningsenhet(activeEnh)
            .pipe(first())
            .subscribe(
                (res) => {
                    this.brukEnheter.find((bruk) => bruk.id === activeEnh.id).namn = activeEnh.namn;
                    this.loading = false;
                    this.activeEnh = null;
                },
                (err) => {
                    this.error = err.error;
                }
            );
    }

    addFarm() {
        this.activeEnh = { id: -1, namn: '' };
    }
    add() {
        this.loading = true;
        this.clientSrv
            .createBrukningsenhet(this.activeEnh)
            .pipe(first())
            .subscribe(
                (res) => {
                    this.brukEnheter.push(res);
                    this.activeEnh = null;
                    this.loading = false;
                },
                (err) => {
                    this.error = err.error;
                }
            );
    }
}
