<div class="empty-view" *ngIf="!loading && matDataSource?.data?.length === 0">
    <p class="title">{{ 'No fields registered' | dvt }}</p>
    <p class="content">
        {{ 'Create fields by chosing an option below' | dvt }}
    </p>
    <div class="options">
        <button (click)="toggleAddField()" mat-stroked-button>
            {{ 'Create field' | dvt }}
        </button>
    </div>
</div>
<div class="loading-area" *ngIf="loading || !viewInited">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<ng-container *ngIf="matDataSource?.data?.length > 0">
    <mat-table
        *ngIf="!loading && viewInited"
        matSort
        (matSortChange)="sortData($event)"
        [dataSource]="matDataSource"
        class="mat-elevation-z8"
    >
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <mat-header-cell mat-header-cell *matHeaderCellDef style="padding: 0.5rem"></mat-header-cell>
            <mat-cell mat-cell *matCellDef="let row" style="padding: 0.5rem">
                <mat-checkbox
                    color="primary"
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selectionChanged(row) : null"
                    [checked]="selection.isSelected(row)"
                >
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header="name">
                {{ 'name' | dvt }}
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element; let i = index">
                <span matTooltipClass="tooltip-parcel" [matTooltip]="element.namn ? element.namn : '-'" *ngIf="expandedRow !== i">{{
                    element.namn && element.namn.length > 0 ? element.namn : '-'
                }}</span>
                <mat-form-field appearance="outline" *ngIf="expandedRow === i"
                    ><input matInput [(ngModel)]="editingField.namn"
                /></mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="crop">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header="crop">
                {{ 'Crop' | dvt }}
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element; let i = index">
                <div class="groda" *ngIf="expandedRow !== i">
                    <div
                        class="color-circle"
                        *ngIf="element.huvudgroda"
                        [ngStyle]="{ 'background-color': element.huvudgroda.farg }"
                    ></div>
                    <!--
                                --><span [ngStyle]="{ 'text-align': element.huvudgroda ? 'left' : 'center' }">{{
                        element.huvudgroda ? element.huvudgroda.benamning : '-'
                    }}</span>
                </div>
                <mat-form-field appearance="outline" *ngIf="expandedRow === i"
                    ><mat-select #cropSelect disableOptionCentering disableOptionCentering [compareWith]="compareCrops" [(value)]="editingField.huvudgroda">
                        <mat-option><button (click)="addCrop()" class="add-crop-edit-button" style="line-height: 1em"
                            ><span style="display: inline-flex; align-items: center"
                                >- {{ 'Add crop' | dvt }} -</span>
                            </button
                        ></mat-option>
                        <mat-option *ngFor="let groda of tabellUtsaden" style="line-height: 1em" [value]="groda"
                            ><span style="display: inline-flex; align-items: center"
                                ><span
                                    style="
                                        display: inline-block;
                                        width: 0.75rem;
                                        height: 0.75rem;
                                        border-radius: 999px;
                                        margin-right: 5px;
                                    "
                                    [ngStyle]="{ 'background-color': groda.farg }"
                                    >&nbsp;</span
                                >{{ groda.groda }} {{ groda.sort }}</span
                            ></mat-option
                        >
                    </mat-select></mat-form-field
                >
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="areal">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header="areal">
                {{ 'Areal' | dvt }} {{ siteService.getAreaUnit() | async }}
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element; let i = index"
                ><span *ngIf="expandedRow !== i">{{ element.areal.toFixed(2) }} {{ siteService.getAreaUnit() | async }}</span>
                <mat-form-field *ngIf="expandedRow === i" appearance="outline"
                    ><input matInput disabled [value]="element.areal.toFixed(2)"
                /></mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="farm">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header="farm">
                {{ 'Farm' | dvt }}
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element; let i = index">
                <span *ngIf="expandedRow !== i || !brukningsenhter || brukningsenhter.length === 0">{{
                    element.brukEnhNamn
                }}</span>
                <mat-form-field appearance="outline" *ngIf="expandedRow === i && brukningsenhter?.length > 0">
                    <mat-select
                        disableOptionCentering
                        (selectionChange)="setBrukenhet($event)"
                        [value]="editingField.bruk"
                        ><mat-option [value]="bruken" *ngFor="let bruken of brukningsenhter">{{
                            bruken.namn
                        }}</mat-option></mat-select
                    >
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="forfrukt">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header="forfrukt">
                {{ 'Prefruit' | dvt }}
            </mat-header-cell>
            <mat-cell
                mat-cell
                *matCellDef="let element; let i = index"
                [ngStyle]="{ 'text-align': element.forfrukt ? 'left' : 'center' }"
            >
                <span>{{ element.forfrukt ? element.forfrukt : '-' }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastmodified">
            <mat-header-cell mat-sort-header="lastmodified" mat-header-cell *matHeaderCellDef>{{
                'Last modified' | dvt
            }}</mat-header-cell>
            <mat-cell [class.disabled]="expandedRow === i" mat-cell *matCellDef="let element; let i = index">{{
                getDateString(element.andradTid)
            }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
            <mat-header-cell class="icon" mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell class="edit-cell" mat-cell *matCellDef="let row; let i = index"
                ><div [matTooltip]="'Use CropPlan to edit your fields' | dvt" [matTooltipDisabled]="editFields">
                    <button
                        class="edit-field"
                        [class.disabled]="!editFields"
                        [class.active]="i === expandedRow"
                        [disabled]="!editFields"
                        (click)="setEdit(row, i)"
                        mat-icon-button
                    >
                        <img src="assets/images/edit.svg" *ngIf="expandedRow !== i" class="edit-icon" /><img
                            src="assets/images/edit_white.svg"
                            *ngIf="expandedRow === i"
                            class="edit-icon"
                        />
                    </button></div
            ></mat-cell>
        </ng-container>

        <mat-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-row>

        <mat-row
            [class.edit]="i === expandedRow"
            mat-row
            [id]="'row-' + i"
            *matRowDef="let row; columns: displayedColumns; let i = index"
            #tableRow
        ></mat-row>
    </mat-table>
</ng-container>

<mat-paginator [class.hidden]="matDataSource?.data?.length <= 15 || !viewInited" [pageSize]="15"></mat-paginator>
