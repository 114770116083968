<div class="pane-header">
  <div class="title">4. {{ 'Review and adjust' | dvt }}</div>
</div>
<div class="pane-content">
  <dv-prescription-saved-notice
    *ngIf="isPrescriptionSaved"
  ></dv-prescription-saved-notice>
  <dv-step-adjust-no-data
    *ngIf="addedAdjustments === 0; else adjustmentContainer"
  ></dv-step-adjust-no-data>
  <ng-template #adjustmentContainer>
    <dvm-text h4>{{ 'Adjustments' | dvt }}</dvm-text>
    <div class="adjustments">
      <div *ngIf="currentAdjustments?.minRate?.added">
        <div class="adjustments-item">
          <dvm-text>
            {{ '_prescription_adjustment_minRate' | dvt : '_minimum rate' }}
          </dvm-text>
          <div class="adjustments-item-action">
            <dvm-input
              class="adjustments-item-input"
              [type]="text"
              [placeholder]="0"
              [value]="currentAdjustments.minRate.value"
              (input)="
                onValueChange($event.target.value, ADJUSTMENT_TYPE.minRate)
              "
            >
              <span unit>{{ selectedSeedUnit }}</span>
            </dvm-input>
            <dvm-button
              ghost
              class="close"
              (click)="remove(ADJUSTMENT_TYPE.minRate)"
            >
              <mat-icon>close</mat-icon>
            </dvm-button>
          </div>
        </div>
        <div class="adjustments-item-flag">
          <dvm-checkbox
            (change)="
              onValueChange($event.target.checked, ADJUSTMENT_TYPE.cutoff)
            "
            [checked]="currentAdjustments.cutoff.value"
          >
            {{ '_prescription_adjustment_cutoff' | dvt : '_cutoff rate' }}
          </dvm-checkbox>
        </div>
        <mat-divider></mat-divider>
      </div>

      <div *ngIf="currentAdjustments?.maxRate?.added">
        <div class="adjustments-item">
          <dvm-text>
            {{ '_prescription_adjustment_maxRate' | dvt : '_maximum rate' }}
          </dvm-text>
          <div class="adjustments-item-action">
            <dvm-input
              class="adjustments-item-input"
              [type]="text"
              [placeholder]="0"
              [value]="currentAdjustments.maxRate.value"
              (input)="
                onValueChange($event.target.value, ADJUSTMENT_TYPE.maxRate)
              "
            >
              <span unit>{{ selectedSeedUnit }}</span>
            </dvm-input>
            <dvm-button
              ghost
              class="close"
              (click)="remove(ADJUSTMENT_TYPE.maxRate)"
            >
              <mat-icon>close</mat-icon>
            </dvm-button>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>

      <div *ngIf="currentAdjustments?.averageRate?.added">
        <div class="adjustments-item">
          <dvm-text>
            {{ '_prescription_adjustment_averageRate' | dvt : '_average rate' }}
          </dvm-text>
          <div class="adjustments-item-action">
            <dvm-input
              class="adjustments-item-input"
              [type]="text"
              [placeholder]="0"
              [value]="currentAdjustments.averageRate.value"
              (input)="
                onValueChange($event.target.value, ADJUSTMENT_TYPE.averageRate)
              "
            >
              <span unit>{{ selectedSeedUnit }}</span>
            </dvm-input>
            <dvm-button
              ghost
              class="close"
              (click)="remove(ADJUSTMENT_TYPE.averageRate)"
            >
              <mat-icon>close</mat-icon>
            </dvm-button>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>

      <div *ngIf="currentAdjustments?.flatAdjustment?.added">
        <div class="adjustments-item">
          <dvm-text>
            {{
              '_prescription_adjustment_flatAdjustment'
                | dvt : '_flat adjustment'
            }}
          </dvm-text>
          <div class="adjustments-item-action">
            <dvm-input
              class="adjustments-item-input"
              [type]="text"
              [placeholder]="0"
              [value]="currentAdjustments.flatAdjustment.value"
              (input)="
                onValueChange(
                  $event.target.value,
                  ADJUSTMENT_TYPE.flatAdjustment
                )
              "
            >
              <span unit>{{ selectedSeedUnit }}</span>
            </dvm-input>
            <dvm-button
              ghost
              class="close"
              (click)="remove(ADJUSTMENT_TYPE.flatAdjustment)"
            >
              <mat-icon>close</mat-icon>
            </dvm-button>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>

      <div *ngIf="currentAdjustments?.availableProduct?.added">
        <div class="adjustments-item">
          <dvm-text>
            {{
              '_prescription_adjustment_availableProduct' | dvt : '_total rate'
            }}
          </dvm-text>
          <div class="adjustments-item-action">
            <dvm-input
              class="adjustments-item-input"
              [type]="text"
              [placeholder]="0"
              [value]="currentAdjustments.availableProduct.value"
              (input)="
                onValueChange(
                  $event.target.value,
                  ADJUSTMENT_TYPE.availableProduct
                )
              "
            >
              <span unit>{{ seedTotalUnit }}</span>
            </dvm-input>
            <dvm-button
              ghost
              class="close"
              (click)="remove(ADJUSTMENT_TYPE.availableProduct)"
            >
              <mat-icon>close</mat-icon>
            </dvm-button>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>

      <div *ngIf="currentAdjustments?.rateOverride?.added">
        <div class="adjustments-item">
          <dvm-text>
            {{
              '_prescription_adjustment_rateOverride' | dvt : '_draw manually'
            }}
          </dvm-text>
          <div class="adjustments-item-action">
            <dvm-button
              class="adjustments-item-button"
              secondary
              (click)="showManualDrawPane = true"
              [disabled]="loading"
            >
              <ng-container *ngIf="hasDeviations$ | async; else addDeviations">
                <mat-icon>edit</mat-icon>
                {{ 'Edit' | dvt }}
              </ng-container>
              <ng-template #addDeviations>
                <mat-icon>add</mat-icon>
                {{ 'Start drawing' | dvt }}
              </ng-template>
            </dvm-button>
            <dvm-button
              ghost
              class="close"
              (click)="remove(ADJUSTMENT_TYPE.rateOverride)"
            >
              <mat-icon>close</mat-icon>
            </dvm-button>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>

    <div *ngIf="currentAdjustments?.subGeodataFile?.added">
      <div class="adjustments-item">
        <dvm-text>
          {{
            '_prescription_adjustment_subPrescription'
              | dvt : '_subtract prescription'
          }}
        </dvm-text>
        <div class="adjustments-item-action">
          <dvm-select
            class="adjustments-item-input"
            [size]="'regular'"
            [options]="geodataList"
            [value]="currentAdjustments.subGeodataFile.value"
            (selectionChange)="
              onValueChange($event.key, ADJUSTMENT_TYPE.subGeodataFile)
            "
          ></dvm-select>

          <dvm-button
            ghost
            class="close"
            (click)="remove(ADJUSTMENT_TYPE.subGeodataFile)"
          >
            <mat-icon>close</mat-icon>
          </dvm-button>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
  </ng-template>

  <dvm-button secondary full-width (click)="openAdjustments()">
    <mat-icon>add</mat-icon>
    {{ 'Add adjustments' | dvt }}
  </dvm-button>
  <dv-grid-select-info-card></dv-grid-select-info-card>
</div>
<dv-grid-cell-detail-pane
  [feature]="selectedFeature"
  (closePane)="onDetailPaneClose()"
></dv-grid-cell-detail-pane>
<dv-manual-draw-pane
  [dvMap]="dvMap"
  [showManualDrawPane]="showManualDrawPane"
  (hideManualDrawPane)="onHideManualDrawPane($event)"
></dv-manual-draw-pane>
