import { Component } from '@angular/core';
import { BaseGeoDataContent } from '../base-geo-data-content/base-geo-data-content';
import { FunctionGeoDataDefinition } from '../../../../step-settings.types';
import { GeoDataHeaderComponent } from 'app/views/prescription-wizard-page/components/step-settings/components/file-section/components/geo-data-header/geo-data-header.component';
import { FilePreviewListComponent } from 'app/views/prescription-wizard-page/components/step-settings/components/file-section/components/file-preview-list/file-preview-list.component';
import { DvTranslatePipe } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-geo-data-content',
  templateUrl: 'geo-data-content.component.html',
  styleUrls: ['../base-geo-data-content/base-geo-data-content.scss'],
  standalone: true,
  imports: [DvTranslatePipe, FilePreviewListComponent, GeoDataHeaderComponent],
})
export class GeoDataContentComponent extends BaseGeoDataContent<FunctionGeoDataDefinition> {}
