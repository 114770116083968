import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { GeoDataFileModel, RawFileSetModel } from 'app/models/models';
import { DataFileService } from 'app/services/data-file.service';
import { UntypedFormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { META_DATA } from './fileset-list-detail.types';
import { MatPaginator } from '@angular/material/paginator';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { DataFilterService } from 'app/services/data-filter.service';
import { KeyValue, Location } from '@angular/common';
import { GeoDataFileMetaTags } from 'app/models/geodata.model';
import { AvailableFilterValues } from 'app/data-page/filter-section/filter-section.types';
import { filterGrowAnimation } from '../../filter-grow.animation';
import { MatSort, Sort } from '@angular/material/sort';
import { COLUMN_KEYS } from '../../data-table.types';
import { ClientService } from 'app/services/client.service';
import { DialogService } from 'app/components/dialog/dialog.service';
import { DataSharePayload, ShareService } from 'app/services/share.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeodataListShareDialogComponent } from '../../geodata/geodata-list/geodata-list-share-dialog/geodata-list-share-dialog.component';
import { ShareDialogResult } from '../../geodata/geodata-list/geodata-list-share-dialog/geodata-list-share-dialog.types';
import { FileStatusLogComponent } from 'app/components/file-status-log/file-status-log.component';
import { MatDialog } from '@angular/material/dialog';
import {
  FileImportStatusLogEntry,
  FileStatus,
} from 'app/models/filestatus.model';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-file-set-list-detail',
  templateUrl: './fileset-list-detail.component.html',
  styleUrls: ['./fileset-list-detail.component.scss', '../../data-table.scss'],
  animations: [filterGrowAnimation],
})
export class FilesetListDetailComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  filter = false;
  freeSearchCtrl = new UntypedFormControl();
  numberOfActiveFilters: 0;
  dataSource = new MatTableDataSource<GeoDataFileModel>([]);
  filesetDataSource = new MatTableDataSource<RawFileSetModel>([]);
  metaData = META_DATA;
  selectedFiles: number[] = [];
  filterValues = { ...this.filterService.CLEARED_FILTER };
  metaTag = GeoDataFileMetaTags;
  availableFilterValues: AvailableFilterValues = {
    customers: [],
    farms: [],
    fileTypes: [],
  };
  currentSort: Sort;
  statusLog: FileImportStatusLogEntry[];
  COLUMN_KEYS = COLUMN_KEYS;
  fileStatus = FileStatus;
  statusLogLoaded = false;

  displayedColumns: string[] = [
    COLUMN_KEYS.CHECKBOX,
    COLUMN_KEYS.NAME,
    COLUMN_KEYS.TYPE,
    COLUMN_KEYS.CUSTOMER,
    COLUMN_KEYS.FARM,
    COLUMN_KEYS.FIELD,
    COLUMN_KEYS.CREATED,
    COLUMN_KEYS.MORE,
  ];

  private unsub$ = new Subject<void>();
  private searchInputSubscription: Subscription;
  private sortingDataAccessor = (
    item: GeoDataFileModel,
    property: string
  ): string => {
    switch (property) {
      case COLUMN_KEYS.TYPE:
        return this.getFileType(item);
      case COLUMN_KEYS.CUSTOMER:
        return this.getMetaData(item, this.metaData.CUSTOMER);
      case COLUMN_KEYS.FARM:
        return this.getMetaData(item, this.metaData.FARM);
      case COLUMN_KEYS.FIELD:
        return this.getMetaData(item, this.metaData.FIELD);
      case COLUMN_KEYS.CREATED:
        return this.getMetaData(item, this.metaData.CREATED);
      default:
        return item[property];
    }
  };

  @Input() fileDetails: RawFileSetModel;
  @Output() hideFileDetails = new EventEmitter<undefined>();
  @ViewChild('filePaginator') paginator: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  get currentImportStatus(): FileStatus {
    const latestEntry = this.statusLog
      ? this.statusLog[this.statusLog.length - 1]
      : null;
    return latestEntry ? latestEntry.status : null;
  }

  constructor(
    private translateService: DvToolbarTranslateService,
    private dialogService: DialogService,
    private shareService: ShareService,
    public fileService: DataFileService,
    private filterService: DataFilterService,
    private location: Location,
    private clientService: ClientService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileDetails?.currentValue) {
      this.dataSource.data = this.fileDetails.dataFiles;
      if (this.paginator) {
        this.paginator._intl.itemsPerPageLabel =
          this.translateService.t('Files per page');
      }
      this.dataSource.paginator = this.paginator;
      this.listenToSearchInput();
      this.setAvailableFilterValues();
      this.getImportStatusLog();
      setTimeout(() => this.onFilterCleared());
    } else {
      this.dataSource = new MatTableDataSource<GeoDataFileModel>([]);

      if (this.searchInputSubscription) {
        this.searchInputSubscription.unsubscribe();
      }
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  private getImportStatusLog(): void {
    this.clientService
      .getFileImportStatus(this.fileDetails.fileId)
      .pipe(takeUntil(this.unsub$))
      .subscribe((statusLog) => {
        this.statusLogLoaded = true;
        statusLog.forEach((entry) => {
          if (entry.status === FileStatus.Error) {
            entry.error = true;
          } else if (entry.status === FileStatus.Failed) {
            entry.warning = true;
          } else {
            entry.success = true;
          }
        });

        this.statusLog = statusLog;
      });
  }

  private setAvailableFilterValues(): void {
    const customers = this.getAvailableFilterValuesOfType(
      'customer',
      new Set<string>()
    );
    const fileTypes = this.getAvailableFilterValuesOfType(
      'fileType',
      new Set<string>()
    );
    const farms = this.getAvailableFilterValuesOfType(
      'farm',
      new Set<string>()
    );

    this.availableFilterValues.customers = Array.from(customers);
    this.availableFilterValues.farms = Array.from(farms);
    this.availableFilterValues.fileTypes = Array.from(fileTypes);
  }

  private getAvailableFilterValuesOfType(
    type: string,
    set: Set<string>
  ): Set<string> {
    this.fileDetails.dataFiles.forEach((file) => {
      const keyValue = file.metadata.find(
        (data) => data.key === this.metaTag[type]
      );
      if (type === 'fileType') {
        const value = file.fileType;

        if (value) {
          set.add(value);
        }
      }

      if (keyValue) {
        set.add(keyValue.value);
      }
    });

    return set;
  }

  private listenToSearchInput(): void {
    this.searchInputSubscription = this.freeSearchCtrl.valueChanges
      .pipe(debounceTime(200))
      .subscribe((searchString: string) => {
        if (searchString) {
          this.dataSource.data = this.fileDetails.dataFiles.filter((file) => {
            const keys = Object.values(this.metaData);
            const matches = keys.find((key) =>
              file.metadata
                .find((data) => data.key === key)
                ?.value.includes(searchString)
            );

            return (
              file.name.includes(searchString) ||
              this.getFileType(file).includes(searchString) ||
              matches
            );
          });
          this.filterValues.searchString = searchString;
          this.setActiveFilterNumber();
        } else {
          this.dataSource.data = this.fileDetails.dataFiles;
          this.filterValues.searchString = null;
          this.setActiveFilterNumber();
        }
      });
  }

  private setActiveFilterNumber(): void {
    this.numberOfActiveFilters = Object.keys(this.filterValues).reduce(
      (prev, curr) => {
        this.filterValues[curr] === null ? prev : prev++;

        return prev;
      },
      0
    );
  }

  onBackClick(): void {
    this.clientService
      .clientId()
      .subscribe((clientId) => this.location.go(`client/${clientId}/data/`));
    this.hideFileDetails.emit();
    this.filter = false;
    this.statusLog = null;
  }

  getFileType(file: GeoDataFileModel): string {
    const type = this.translateService.t(
      `_data_filetype_${file.fileType}`,
      `_${file.fileType}`
    );

    return type ?? '-';
  }

  getMetaData(file: GeoDataFileModel, property: META_DATA): string {
    const value = file.metadata.find((data) => data.key === property)?.value;

    return value ?? '-';
  }

  checkedChange(fileId: number): void {
    if (!this.selectedFiles.includes(fileId)) {
      this.selectedFiles.push(fileId);
    } else {
      this.selectedFiles = this.selectedFiles.filter((id) => id !== fileId);
    }
  }

  selectAllFiles(checked: boolean): void {
    if (checked) {
      this.dataSource.data.forEach((file) =>
        this.selectedFiles.push(file.fileId)
      );
    } else {
      this.selectedFiles = [];
    }
  }

  setFilter({ key, value }: KeyValue<string, string>): void {
    this.filterValues[key] = value;

    this.setActiveFilterNumber();

    if (this.numberOfActiveFilters) {
      this.applyFilters();
    } else {
      this.dataSource.data = this.fileDetails.dataFiles;
    }
  }

  private applyFilters(): void {
    const activeFilters = this.getActiveFilters();

    this.dataSource.data = this.fileDetails.dataFiles.filter((file) => {
      return activeFilters.every(({ key, value }) => {
        const metaKey = this.metaTag[key];
        const created = file.metadata.find(
          (data) => data.key === this.metaTag.created
        )?.value;

        if (key === 'to' || key === 'from') {
          return this.applyDateFilter(created);
        }

        return (
          file[key] === value ||
          file.metadata.find((data) => data.key === metaKey)?.value === value
        );
      });
    });
  }

  private applyDateFilter(created?: string): boolean {
    const { to, from } = this.filterValues;

    if (!to && !from) {
      return true;
    } else if (!created) {
      return false;
    } else {
      let valid = true;
      const createdDate = new Date(created);
      createdDate.setHours(0, 0, 0, 0);

      if (to) {
        to.setHours(0, 0, 0, 0);
        valid = to > createdDate;
      }

      if (from && valid) {
        from.setHours(0, 0, 0, 0);
        valid = from < createdDate;
      }

      return valid;
    }
  }

  private getActiveFilters(): KeyValue<string, string>[] {
    return Object.keys(this.filterValues).reduce((prev, key) => {
      const value = this.filterValues[key];
      if (value !== null && key !== 'searchString') {
        prev.push({ key, value });
      }

      return prev;
    }, []);
  }

  onFilterCleared(): void {
    this.setActiveFilterNumber();
    this.filterValues = { ...this.filterService.CLEARED_FILTER };

    this.dataSource.data = this.fileDetails.dataFiles;
  }

  onSortChange(sort: Sort): void {
    this.currentSort = sort;
  }

  openShareFileDialog(): void {
    const ref = this.dialogService.open(GeodataListShareDialogComponent, {
      selectedFiles: this.dataSource.data.filter((file) =>
        this.selectedFiles.includes(file.fileId)
      ),
    });

    ref.afterClosed().subscribe((result: ShareDialogResult) => {
      if (result?.data) {
        const payload: DataSharePayload = {
          message: result.data.message,
          recipients: result.data.emails,
          urls: result.data.links,
        };

        this.shareService.shareGeoDataLinks(payload).subscribe(
          () => this.showSnackBar('Success'),
          () => this.showSnackBar('Something went wrong, please try again!')
        );
      }
    });
  }

  private showSnackBar(text: string): void {
    this.snackBar.open(this.translateService.t(text), null, {
      duration: 3500,
    });
  }

  onFileStatusClick(): void {
    this.dialog.open(FileStatusLogComponent, {
      data: {
        log: this.statusLog,
      },
    });
  }
}
