import { Component, Input } from '@angular/core';
import { PoiKategoriModel } from 'app/models/api.models';
import { DialogService } from 'app/components/dialog/dialog.service';
import { ClientService } from 'app/services/client.service';
import { filter, flatMap } from 'rxjs/operators';
import { PoiService } from '../poi.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-poi-categorys',
  templateUrl: './poi-categorys.component.html',
  styleUrls: ['./poi-categorys.component.scss'],
})
export class PoiCategorysComponent {
  @Input() categorys: PoiKategoriModel[] = [];

  newCatName: string;

  constructor(
    private poiSrv: PoiService,
    private clientService: ClientService,
    private dialogService: DialogService,
    private translate: DvToolbarTranslateService
  ) {}

  save(cat: PoiKategoriModel): void {
    this.clientService.savePoiCategory(cat).subscribe(() => {
      // EMPTY CALLBACK
    });
  }

  deleteCat(cat: PoiKategoriModel): void {
    this.dialogService
      .confirm(this.translate.t('Remove category?'))
      .afterClosed()
      .pipe(
        filter((res: boolean) => res),
        flatMap(() => this.clientService.deletePoiCategory(cat))
      )
      .subscribe(
        () => {
          // EMPTY CALLBACK
        },
        (err) => {
          console.log('err', err);
          this.dialogService.message(
            this.translate.t('Can´t delete a category that is in use')
          );
        }
      );
  }

  addNew(namn: string): void {
    this.clientService.addPoiCategory(namn).subscribe(() => {
      this.newCatName = '';
    });
  }

  close(): void {
    if (this.categorys.some((c) => c['edit'])) {
      this.dialogService
        .confirm(
          this.translate.t(
            '_unsaved_poi_cat',
            '_You have unsaved categorys. Save these before close?'
          )
        )
        .afterClosed()
        .subscribe((res) => {
          if (res) {
            this.categorys.forEach((c) => {
              if (c['edit']) {
                this.save(c);
              }
            });
          }

          this.poiSrv.setState(null);
        });
    } else {
      this.poiSrv.setState(null);
    }
  }
}
