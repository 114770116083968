<div class="row">
  <button
    [disabled]="!loaded || nodata"
    mat-stroked-button
    [matMenuTriggerFor]="menuType"
    class="button button-type"
  >
    <div class="row space-between">
      <span *ngIf="!currentGroup">{{ 'Choose type' | dvt }}</span>
      <div *ngIf="currentGroup" class="row">
        <img class="type-icon" [src]="currentGroup.icon" />
        <span>{{ currentGroup.displayName }}</span>
      </div>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
  </button>
  <mat-menu #menuType="matMenu">
    <div
      *ngFor="let group of groups"
      mat-menu-item
      (click)="selectGroup(group)"
    >
      <div class="row">
        <img class="type-icon" [src]="group.icon" />
        <span>{{ group.displayName }}</span>
      </div>
    </div>
  </mat-menu>

  <button
    [disabled]="!currentGroup || !loaded"
    mat-stroked-button
    [matMenuTriggerFor]="menuYear"
    class="button button-year"
  >
    <div class="row space-between">
      <span *ngIf="!currentYear">{{ 'Choose year' | dvt }}</span>
      <div *ngIf="currentYear" class="row">
        <span>{{ currentYear }}</span>
      </div>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
  </button>
  <mat-menu #menuYear="matMenu">
    <div *ngFor="let year of years" mat-menu-item (click)="selectYear(year)">
      <div class="row">
        <span>{{ year }}</span>
      </div>
    </div>
  </mat-menu>

  <button
    [disabled]="!currentYear || !loaded"
    mat-stroked-button
    [matMenuTriggerFor]="menuProp"
    class="button button-field"
  >
    <div class="row space-between">
      <span *ngIf="!currentField && currentGroup?.name != CROP_SAT_NAME">
        {{ '_split_choose_field' | dvt: '_Choose field' }}
      </span>
      <span *ngIf="!currentField && currentGroup?.name == CROP_SAT_NAME">
        {{ '_split_choose_date' | dvt: '_Choose date' }}
      </span>
      <div *ngIf="currentField" class="row">
        <span>{{ currentField.displayName }}</span>
      </div>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
  </button>
  <mat-menu #menuProp="matMenu">
    <div
      *ngFor="let field of fields"
      mat-menu-item
      (click)="selectField(field, true)"
    >
      <div class="row">
        <span>{{ field.displayName }}</span>
      </div>
    </div>
    <div *ngIf="currentField && files.length > 0" class="advanced">
      <div
        class="row space-between hand"
        (click)="$event.stopPropagation(); advExpanded = !advExpanded"
      >
        <span>{{ 'Advanced settings' | dvt }}</span>
        <mat-icon *ngIf="!advExpanded">expand_more</mat-icon>
        <mat-icon *ngIf="advExpanded">expand_less</mat-icon>
      </div>

      <div *ngIf="advExpanded" class="column">
        <span class="dv-icon-color small-text">
          {{ 'Pick file to use' | dvt }}:
        </span>
        <mat-checkbox
          *ngFor="let file of selectableFiles"
          (change)="checkFile(file)"
          (click)="$event.stopPropagation()"
          [checked]="file.checked"
          [matTooltip]="file.fileName | trimSystemfolder"
        >
          {{ file.created | date: 'short' }} -
          {{ file.fileName | trimSystemfolder }}
        </mat-checkbox>
      </div>
    </div>
  </mat-menu>
  <div class="row loading-section">
    <mat-spinner *ngIf="!loaded || working" [diameter]="25"></mat-spinner>
    <span *ngIf="!loaded" class="dv-text-color status-text">
      {{ 'Loading fileinfo' | dvt }}...
    </span>
    <div *ngIf="nodata" class="row">
      <mat-icon class="dv-yellow">warning</mat-icon>
      <span class="dv-text-color status-text">{{
        'No data found for selected fields' | dvt
      }}</span>
    </div>
    <span *ngIf="working" class="dv-text-color status-text">
      {{ 'Loading interpolation' | dvt }}...
    </span>
    <div *ngIf="error" class="row">
      <mat-icon color="warn">error</mat-icon>
      <span class="dv-text-color status-text">{{ error }}</span>
    </div>
  </div>
</div>
<div class="row">
  <div class="data-point-slides" *ngIf="showDataPointSlides">
    <mat-slide-toggle
      [(ngModel)]="pointMode"
      (change)="toggleInterpolationPoints($event.checked)"
      [disabled]="working || selectedFileIds.length !== 1"
      [matTooltipDisabled]="selectedFileIds.length === 1 || loaded"
      matTooltip="{{
        '_interpolationpoints_not_available'
          | dvt: '_Interpolationpoints are not available for multiple fields'
      }}"
      >{{ 'Show interpolationpoints' | dvt }}
    </mat-slide-toggle>
    <mat-slide-toggle
      [(ngModel)]="labelMode"
      (change)="togglePointLabels($event.checked)"
      [disabled]="
        working ||
        selectedFileIds.length !== 1 ||
        currentGroup?.name !== FILE_TYPE.SoilSampling
      "
      [matTooltipDisabled]="selectedFileIds.length === 1 || loaded"
      matTooltip="{{
        '_datapoints_not_available' | dvt: '_Datapoints are not available'
      }}"
      >{{ 'Show labels' | dvt }}
    </mat-slide-toggle>
  </div>
</div>
