<div class="checkbox-list" *ngIf="multiSelect; else radio">
  <dvm-checkbox
    class="checkbox"
    *ngFor="let wrappedFile of wrappedFiles; let i = index"
    [checked]="wrappedFile.checked"
    [disabled]="disabled"
    (click)="onChange(wrappedFile, wrappedFile.file.fileId)"
  >
    {{ wrappedFile.createdDate | date: 'short' }} -
    {{ wrappedFile.file.name ?? '' }}
  </dvm-checkbox>
</div>
<ng-template #radio>
  <dvm-radio-button-group
    [value]="singleSelectFileId"
    [disabled]="disabled"
    (change)="onSingleSelectChange($event)"
  >
    <dvm-radio-button
      *ngFor="let wrappedFile of wrappedFiles; let i = index"
      size="regular"
      [checked]="wrappedFile.checked"
      [value]="wrappedFile.file.fileId"
    >
      {{ wrappedFile.createdDate | date: 'short' }} -
      {{ wrappedFile.file.name ?? '' }}
    </dvm-radio-button>
  </dvm-radio-button-group>
</ng-template>
