import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DvUserService } from '@dv/toolbar-msal';
import { ClientService } from 'app/services/client.service';
import { SiteService } from 'app/services/site.service';
import { forkJoin } from 'rxjs';
import { first, take } from 'rxjs/operators';

@Component({
  selector: 'dv-insufficient-rights-dialog',
  templateUrl: './insufficient-rights-dialog.component.html',
  styleUrls: ['./insufficient-rights-dialog.component.scss'],
})
export class InsufficientRightsDialogComponent {
  functionName = '';
  loading = false;
  upgradeError = false;
  contactSupport = false;
  price = '';
  isAgrometius: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<InsufficientRightsDialogComponent>,
    private clientSrv: ClientService,
    private siteSrv: SiteService,
    private dvUserService: DvUserService
  ) {
    this.functionName = data?.functionName;

    try {
      forkJoin({
        client: this.clientSrv.client().pipe(take(1)),
        isAgrometius: this.clientSrv.isAgrometsius().pipe(take(1)),
        user: this.dvUserService.user$.pipe(take(1)),
      }).subscribe(({ client, isAgrometius, user }) => {
        this.isAgrometius = isAgrometius;
        const country = client.land;

        if (country) {
          if (country === 'se' || country === 'no') {
            this.price = '5000 kr';
          } else if (isAgrometius) {
            this.price = '350 €';
          } else {
            this.price = '500 €';
          }
        } else {
          const countryCode = user.languageCode;
          if (
            countryCode === 'sv' ||
            countryCode === 'nn' ||
            countryCode === 'nb' ||
            countryCode === 'no'
          ) {
            this.price = '5000 kr';
          } else if (
            countryCode === 'nl' ||
            countryCode === 'be' ||
            countryCode === 'de'
          ) {
            this.price = '350 €';
          } else {
            this.price = '500 €';
          }
        }
      });
    } catch (error) {
      console.log('country error', error);
      this.price = '500 €';
    }
  }

  save(): void {
    this.loading = true;
    this.clientSrv
      .upgradeToCropMapAdvanced()
      .pipe(first())
      .subscribe(
        (success) => {
          if (success) {
            this.siteSrv.setHasCropMapAdvanced(true);
            this.dialogRef.close();
          } else {
            this.contactSupport = true;
          }
          this.loading = false;
        },
        (err) => {
          if (err.status === 400) {
            this.contactSupport = true;
          } else {
            this.upgradeError = true;
          }
          this.loading = false;
        }
      );
  }
}
