import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { POI_STATES } from './poi.types';

@Injectable({
  providedIn: 'root',
})
export class PoiService {
  poiFeature$ = new BehaviorSubject<google.maps.Data.Feature>(null);
  state$ = new BehaviorSubject<POI_STATES>(null);
  drawState$ = new BehaviorSubject<string>(null);
  prevState: POI_STATES = null;

  setPoiFeature(poi: google.maps.Data.Feature, state: POI_STATES = null): void {
    this.poiFeature$.next(poi);
    this.state$.next(state);
  }

  getPoiFeature(): Observable<google.maps.Data.Feature> {
    return this.poiFeature$.asObservable();
  }

  getState(): Observable<string> {
    return this.state$.asObservable();
  }

  setPrevState(): void {
    this.state$.next(this.prevState);
  }

  setState(state: POI_STATES): void {
    this.prevState = this.state$.value;
    this.state$.next(state);
  }

  setStartDraw(type: string): void {
    this.drawState$.next(type);
  }
}
