import { MatPaginatorIntl } from '@angular/material/paginator';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

export class MatPaginatorIntlCustom extends MatPaginatorIntl {
  translateService: DvToolbarTranslateService;
  constructor() {
    super();
  }
  injectTranslateService(translate: DvToolbarTranslateService): void {
    this.translateService = translate;
    this.translateLabels();
  }

  translateLabels(): void {
    this.itemsPerPageLabel = this.translateService.t('Items per page');
    this.nextPageLabel = this.translateService.t('Next page');
    this.previousPageLabel = this.translateService.t('Previous page');
    this.lastPageLabel = this.translateService.t('Last page');
    this.firstPageLabel = this.translateService.t('First page');
  }
}
