import { Pipe, PipeTransform } from '@angular/core';
import { SiteService } from 'app/services/site.service';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'perAreaUnit', pure: false })
export class PerAreaUnit implements PipeTransform {
  constructor(
    private siteService: SiteService,
    private decimalPipe: DecimalPipe
  ) {}

  transform(areaInHa: number, unit?: string): string {
    /**
     * This is needed because:
     * typeof NaN === 'number'
     */
    if (isNaN(areaInHa)) {
      return '';
    }

    const FORMAT = this.siteService.isClientNo() ? '1.0-0' : '1.0-2';

    if (unit !== undefined) {
      return (
        this.decimalPipe.transform(
          this.siteService.calculateAreaFromHa(areaInHa),
          FORMAT
        ) +
        ' ' +
        this.siteService.getUnitByAreaUnitFromSettings(unit)
      );
    } else {
      return (
        this.decimalPipe.transform(
          this.siteService.calculateAreaFromHa(areaInHa),
          FORMAT
        ) +
        ' /' +
        this.siteService.getAreaUnitFromSettings()
      );
    }
  }
}
