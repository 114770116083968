import { Injectable } from "@angular/core";
import { ReplaySubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
export class SearchableSelectService {
    private selectedValue$ = new ReplaySubject<any>(1);
    private closeOverlay$ = new ReplaySubject<boolean>(0);

    getSelectedValue(): Observable<any> {
        return this.selectedValue$.asObservable();
    }

    setSelectedValue(value: any) {
        this.selectedValue$.next(value);
    }

    getCloseOverlay(): Observable<boolean> {
        return this.closeOverlay$.asObservable();
    }

    setCloseOverlay() {
        this.closeOverlay$.next(true);
    }


 }