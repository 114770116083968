import {
  AfterViewInit,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { ImportService } from 'app/components/import-view/import.service';
import { DataFilterService } from 'app/services/data-filter.service';
import { DataFileService } from 'app/services/data-file.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  map,
  takeUntil,
  distinctUntilChanged,
  take,
  takeWhile,
} from 'rxjs/operators';
import { FileSetListComponent } from 'app/components/data/fileset/fileset-list/fileset-list.component';
import { GeoDataListComponent } from 'app/components/data/geodata/geodata-list/geodata-list.component';
import { ClientService } from 'app/services/client.service';
import { RawFileSetModel } from 'app/models/models';
import { AvailableFilterValues } from './filter-section/filter-section.types';
import { filterGrowAnimation } from 'app/components/data/filter-grow.animation';
import { ActivatedRoute, Router } from '@angular/router';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-data-page',
  templateUrl: './data-page.component.html',
  styleUrls: ['./data-page.component.scss'],
  animations: [filterGrowAnimation],
})
export class DataPageComponent implements OnInit, AfterViewInit, OnDestroy {
  filter = false;
  showNoData: Observable<boolean>;
  filesetLength = 0;
  geodataLength = 0;
  filterValues = { ...this.filterService.CLEARED_FILTER };
  numberOfActiveFilters = 0;
  freeSearchCtrl = new UntypedFormControl();
  showFileDetails = false;
  hideScrollbarFlag = false;
  fileDetails?: RawFileSetModel;
  availableFilterValues: AvailableFilterValues;
  private loadedData = false;
  private loadedFiles = false;
  private _unsub$ = new Subject<void>();

  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  @ViewChild(GeoDataListComponent) geodataList: GeoDataListComponent;
  @ViewChild(FileSetListComponent) filesetList: FileSetListComponent;

  get showNoDataSearchResult(): boolean {
    return (
      this.loadedData && !this.geodataLength && this.numberOfActiveFilters !== 0
    );
  }

  get showNoFileSearchResult(): boolean {
    return (
      this.loadedFiles &&
      !this.filesetLength &&
      this.numberOfActiveFilters !== 0
    );
  }

  constructor(
    public translateService: DvToolbarTranslateService,
    private importService: ImportService,
    public fileService: DataFileService,
    public filterService: DataFilterService,
    private clientService: ClientService,
    public fileSrv: DataFileService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.clientService.loadSkifteLayer();
    this.listenToFilterChange();
    this.createShowNoDataObservable();
  }

  ngOnInit(): void {
    this.handleInitialNavigation();

    combineLatest([
      this.filterService.availableCustomers$,
      this.filterService.availableFarms$,
      this.filterService.availableFileTypes$,
    ]).subscribe((values) => {
      this.availableFilterValues = {
        customers: values[0],
        farms: values[1],
        fileTypes: values[2],
      };
    });
  }

  ngAfterViewInit(): void {
    this.freeSearchCtrl.setValue(this.filterValues.searchString);
    //free text search needs a debounce
    this.freeSearchCtrl.valueChanges
      .pipe(debounceTime(200))
      .subscribe((searchString: string) => {
        searchString = searchString?.trim();
        if (!searchString || searchString?.length === 0) {
          this.filterService.setFilter('searchString', null);
        } else {
          this.filterService.setFilter(
            'searchString',
            searchString.toLocaleLowerCase()
          );
        }
      });

    this.fileService.loadingGeodata$
      .pipe(takeUntil(this._unsub$))
      .subscribe((value: boolean) => (this.loadedData = !value));

    this.fileService.loadingFileset$
      .pipe(takeUntil(this._unsub$))
      .subscribe((value: boolean) => (this.loadedFiles = !value));
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  @HostBinding('class') get hideScrollbar(): string {
    return this.hideScrollbarFlag ? 'hide-scrollbar' : '';
  }

  // If the URL contains a valid file id on component init,
  // show file details.
  private handleInitialNavigation(): void {
    combineLatest([this.fileSrv.fileset$, this.route.paramMap])
      .pipe(takeWhile(([fileset]) => !fileset, true))
      .subscribe(([fileset, params]) => {
        const id = params.get('id');

        if (id && fileset) {
          const file = fileset.find((set) => set.fileId === Number(id));

          if (file) {
            this.onShowFileDetails(file);
          }
        }
      });
  }

  private listenToFilterChange(): void {
    this.filterService.filterChange$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .subscribe(() => {
        this.filterValues = { ...this.filterService.activeFilters };
        let filterCount = 0;

        for (const key in this.filterValues) {
          filterCount += this.filterValues[key] === null ? 0 : 1;
        }

        this.numberOfActiveFilters = filterCount;
      });
  }

  private createShowNoDataObservable(): void {
    this.showNoData = combineLatest([
      this.fileService.loadingGeodata$,
      this.fileService.loadingFileset$,
    ]).pipe(
      map((values) => {
        if (values.some((value) => value ?? true)) {
          return false;
        }
        return (
          !this.fileService.fileset$.value?.length &&
          !this.fileService.geodata$.value?.length
        );
      }),
      distinctUntilChanged()
    );
  }

  importFiles(): void {
    this.importService.open();
  }

  setFilter(type: string, value: string): void {
    this.filterService.setFilter(type, value);
  }

  updateFilesetLength(newLength: number): void {
    this.filesetLength = newLength;
  }

  updateGeodataLength(newLength: number): void {
    this.geodataLength = newLength;
  }

  onShowFileDetails(fileDetails: RawFileSetModel): void {
    this.clientService
      .clientId()
      .pipe(take(1))
      .subscribe(async (clientId) =>
        this.router.navigate([
          `client/${clientId}/data/fileset`,
          fileDetails.fileId,
        ])
      );
    this.fileDetails = fileDetails;
    this.showFileDetails = true;
    setTimeout(() => (this.hideScrollbarFlag = true), 300);
  }

  onHideFileDetails(): void {
    this.fileDetails = null;
    this.showFileDetails = false;
    this.hideScrollbarFlag = false;
  }

  onFilterCleared(): void {
    this.filterService.clearFilters();
  }
}
