import { EventEmitter } from "@angular/core";

export class TextOverlay extends google.maps.OverlayView {
    private el: HTMLDivElement;
    private moveHandler: google.maps.MapsEventListener;
    protected centerPoint: google.maps.LatLng;
    private line: google.maps.Polyline;
    protected visible: boolean = true;
    public labelPosChanged = new EventEmitter<TextOverlay>();

    constructor(public text: string, protected pos: google.maps.LatLng, private map: google.maps.Map) {
        super();
        this.setMap(map);
    }

    onAdd() {
        if (this.visible) {
            this.el = document.createElement('div');
            this.el.innerHTML = this.text;
            this.el.className = 'google-maps-overlay text';

            google.maps.event.addDomListener(this.el, 'mouseleave', () => {
                    google.maps.event.trigger(this.el, 'mouseup');
                }
            );

            google.maps.event.addDomListener(this.el, 'mousedown', (mousedownEvent : any) => {
                this.el.style.cursor = 'move';
                this.map.set('draggable', false);
                let mousePos = { x: mousedownEvent.clientX, y: mousedownEvent.clientY };

                this.moveHandler = google.maps.event.addDomListener(this.el, 'mousemove', (moveEvent : any) => {
                    let pos = this.getProjection().fromLatLngToDivPixel(this.pos)
                    let x = pos.x + (moveEvent.clientX - mousePos.x);
                    let y = pos.y + (moveEvent.clientY - mousePos.y);
                    this.pos = this.getProjection().fromDivPixelToLatLng(new google.maps.Point(x, y));
                    mousePos = { x: moveEvent.clientX, y: moveEvent.clientY };
                    this.set('labelPosX', this.pos.lng());
                    this.set('labelPosY', this.pos.lat());
                    this.labelPosChanged.next(this);
                    this.draw();
                });
            })

            google.maps.event.addDomListener(this.el, 'mouseup', (mouseupEvent) => {
                if (this.map)
                    this.map.set('draggable', true);
                this.el.style.cursor = 'default';
                google.maps.event.removeListener(this.moveHandler);
            });

            // We add an overlay to a map via one of the map's panes.

            this.initLine();
            this.updateElPos();
            var panes = this.getPanes();
            panes.floatPane.appendChild(this.el);
        }
    }

    private initLine() {

        this.line = new google.maps.Polyline({
            path: [this.centerPoint, this.centerPoint],
            clickable: false,
            strokeOpacity: 0,
            zIndex: 99999999,
            icons: [{
                icon: {
                    path: 'M 0,-1 0,1',
                    strokeOpacity: 1,
                    scale: 2,
                    strokeColor: "#fff"
                },
                offset: '0',
                repeat: '15px'
            }],
            map: this.map
        });

        this.line.setVisible(false);
    }

    draw() {
        this.updateElPos();
        if(this.el)
            this.el.innerHTML = this.text;
    }

    onRemove() {
        let panes = this.getPanes();
        this.setMap(null);
        if (this.line)
            this.line.setMap(null);

        if (panes && panes.floatPane && this.el) {
            if(panes.floatPane.hasChildNodes())
                panes.floatPane.removeChild(this.el);
        }

            
    }

    private updateElPos() {
        let overlayProjection = this.getProjection();
        let position = overlayProjection.fromLatLngToDivPixel(this.pos);
        if(this.el) {
            this.el.style.left = position.x + 'px';
            this.el.style.top = position.y + 'px';
        }
        
        let distance;
        if(google.maps.geometry)
            distance = google.maps.geometry.spherical.computeDistanceBetween(this.centerPoint, this.pos);

        if (this.line && distance && distance > 10) {
            this.line.setPath([this.centerPoint, this.pos])
            this.line.setVisible(!(localStorage.getItem('hideLabelLine') && localStorage.getItem('hideLabelLine') === 'true'));
        }
        else if (this.line && distance && distance < 10)
            this.line.setVisible(false);

    }
}