import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrukningsenhetModel } from 'app/models/api.models';
import { MappingWizardService } from 'app/components/mapping-view/mapping-wizard/mapping-wizard.service';
import { ImportStatusGroup } from 'app/models/importStatusGroup.model';
import { MissingSkifteModel } from 'app/models/missingSkifteModel';
import { ClientService } from 'app/services/client.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dv-import-done',
  templateUrl: './import-done.component.html',
  styleUrls: ['./import-done.component.scss'],
})
export class ImportDoneComponent implements OnDestroy {
  private _unsub$: Subject<void> = new Subject<void>();
  missingSkiften: MissingSkifteModel = { year: 0, count: 0 };
  farms: BrukningsenhetModel[] = [];
  private selectedFarmId = 0;
  private selectedYear: number = new Date().getFullYear();
  years: number[] = [];
  loading = true;
  savingParcels = false;
  importStatus: ImportStatusGroup[];
  hideImportStatus = false;

  constructor(
    private clientService: ClientService,
    private dialogRef: MatDialogRef<ImportDoneComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ImportDoneInputModel,

    private wizSrv: MappingWizardService
  ) {
    this.importStatus = data.importStatus;

    const currentYear = new Date().getFullYear();

    this.clientService
      .client()
      .pipe(takeUntil(this._unsub$))
      .subscribe((klient) => {
        if (klient.tillgangligaAr && klient.tillgangligaAr.length > 0) {
          this.years = klient.tillgangligaAr;
        } else {
          this.years = [currentYear];
        }
      });

    if (data && data.hideImportStatus) {
      this.hideImportStatus = data.hideImportStatus;
    }

    if (data && data.selectedYear) {
      this.selectedYear = data.selectedYear;
    } else if (this.years.includes(currentYear)) {
      this.selectedYear = currentYear;
    } else {
      this.selectedYear = Math.max(...this.years);
    }
    this.clientService
      .getBrukningsenheter()
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        this.farms = res;
        this.selectedFarmId = this.farms[0].id;
      });

    this.clientService
      .missingSkifteCount(this.selectedYear)
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        (res) => {
          this.missingSkiften = res;
          this.loading = false;
        },
        (error) => {
          this.dialogRef.close();
          console.log('error', error);
        }
      );
  }

  update(): void {
    this.clientService
      .missingSkifteCount(this.selectedYear)
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        (res) => (this.missingSkiften = res),
        (error) => {
          console.log('error', error);
          this.dialogRef.close();
        }
      );

    this.clientService
      .importStatus(true)
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => (this.importStatus = res));
  }

  close(): void {
    this.dialogRef.close();

    this.importStatus.forEach((group) => {
      this.clientService.importStatusReaded(group.blobRef).subscribe();
    });
  }

  clear(): void {
    this.dialogRef.close();

    this.importStatus.forEach((group) => {
      this.clientService.importStatusReaded(group.blobRef).subscribe();
    });

    if (this.missingSkiften.count > 0) {
      this.clientService.missingSkifteClear().subscribe();
    }
  }

  importSkiften(): void {
    this.savingParcels = true;
    this.loading = true;
    this.clientService
      .missingSkifteImport(this.selectedFarmId, this.selectedYear)
      .pipe(takeUntil(this._unsub$))
      .subscribe(() => {
        this.clientService.loadSkifteLayer();
        //force reload of client so we get new years
        //we only wont to reload the client, so we dont need to store the result
        this.clientService.reloadYear();
        //this.wizSrv.setActiveIndex(0);
        this.loading = false;
        this.savingParcels = false;
        this.close();
      });
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }
}

export interface ImportDoneInputModel {
  importStatus: ImportStatusGroup[];
  selectedYear: number;
  hideImportStatus: boolean;
}
