import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';
import { MapComponent } from 'app/components/map/map.component';
import {
  LAYER_NAME,
  LAYER_NAME_FILEDATA,
  MapService,
} from 'app/components/map/map.service';
import { GeoDataFileModel } from 'app/models/models';
import { ClientService } from 'app/services/client.service';
import { MapStateService } from 'app/services/map-state.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dv-geodata-rawdata-preview',
  templateUrl: './geodata-file-preview.component.html',
  styleUrls: ['./geodata-file-preview.component.scss'],
})
export class GeodataRawdataPreviewComponent implements OnInit, OnDestroy {
  loadingData = false;
  selectedProperties?: Record<string, string | number>;
  propKeys: string[] = [];
  pointLength: number;
  private dvMap: MapComponent;
  private unsub$: Subject<void> = new Subject<void>();
  private selectedFeature = new BehaviorSubject<google.maps.Data.Feature>(null);

  @Input() geodata: GeoDataFileModel;

  constructor(
    private mapService: MapService,
    private clientService: ClientService,
    private snackBar: MatSnackBar,
    private mapStateService: MapStateService,
    private translateService: DvToolbarTranslateService
  ) {}

  ngOnInit(): void {
    this.mapService
      .mainMap()
      .pipe(takeUntil(this.unsub$))
      .subscribe((dvMap) => this.onMapResult(dvMap));
  }

  ngOnDestroy(): void {
    this.mapStateService.clearSelectedFeatures();
    this.unsub$.next();
    this.unsub$.complete();
  }

  private onMapResult(dvMap: MapComponent): void {
    this.dvMap = dvMap;

    if (dvMap.map.getZoom() <= 13) {
      dvMap.map.setZoom(14);
    }

    dvMap.multiSelect = false;
    this.loadData();

    dvMap.onSelected
      .pipe(takeUntil(this.unsub$))
      .subscribe((f) => this.onMapSelected(f));

    dvMap.onDeSelected
      .pipe(takeUntil(this.unsub$))
      .subscribe((f) => this.onMapDeselected(f));
  }

  private onMapSelected(f: google.maps.Data.Feature): void {
    const prevFeature = this.selectedFeature.value;

    if (prevFeature !== null) {
      this.mapStateService.unsetSelectedFeature(prevFeature);
    }

    if (f.getProperty(LAYER_NAME) === LAYER_NAME_FILEDATA) {
      this.selectedFeature.next(f);
      this.selectedProperties = this.getProperties(f);
      this.mapStateService.setSelectedFeature(f);
      this.propKeys = Object.keys(this.selectedProperties);
    }
  }

  private onMapDeselected(f: google.maps.Data.Feature): void {
    this.mapStateService.unsetSelectedFeature(f);
    this.selectedFeature.next(null);
    this.selectedProperties = null;
    this.propKeys = [];
  }

  private loadData(): void {
    this.loadingData = true;

    if (!this.geodata?.fileId) {
      return;
    }

    this.clientService.getGeoDataJson(this.geodata.fileId).subscribe(
      (geoData) => this.onGeoDataResult(geoData),
      () => this.onGeoDataError()
    );
  }

  private onGeoDataResult(geoData: GeoJSON.FeatureCollection): void {
    this.pointLength = geoData?.features?.length;
    this.dvMap.removeLayer(LAYER_NAME_FILEDATA);

    geoData.features.forEach((f) => {
      f.id = this.getUniqueId();
    });

    if (geoData?.features?.length > 0) {
      this.dvMap.fitFeature(
        this.dvMap.addGeoJson(geoData, LAYER_NAME_FILEDATA),
        true
      );
    } else {
      this.snackBar.open(this.translateService.t('Empty Geodata file'), null, {
        duration: 3500,
      });
    }

    this.loadingData = false;
  }

  private onGeoDataError(): void {
    const message = this.translateService.t('Failed to open Geodata file');
    const action = this.translateService.t('retry');

    this.snackBar
      .open(message, action, {
        duration: 3500,
      })
      .afterDismissed()
      .subscribe((action) => {
        if (action.dismissedByAction) {
          this.loadData();
        }
      });

    this.loadingData = false;
  }

  private getUniqueId(): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < 7; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  private getProperties(
    f: google.maps.Data.Feature
  ): Record<string, string | number> {
    const props: Record<string, string | number> = {};

    try {
      f.forEachProperty((value: string | number, name: string) => {
        if (!name.startsWith('_')) {
          props[name] = value;
        }
      });
    } catch (err) {
      return;
    }

    return props;
  }

  isNumber(val): boolean {
    return !isNaN(val);
  }
}
