import * as turf from '@turf/turf';
import { flatMap, first, map } from 'rxjs/operators';
import {
  Input,
  Output,
  ChangeDetectorRef,
  NgZone,
  EventEmitter,
  OnDestroy,
  Component,
} from '@angular/core';
import { SiteService } from 'app/services/site.service';
import { MapComponent } from '../map.component';
import {
  MapService,
  LAYER_NAME_SKIFTEN,
  LAYER_ZINDEX,
  COLORS,
} from '../map.service';
import { AllGeoJSON } from '@turf/turf';
import { FeatureTextOverlay } from '../overlay/FeatureTextOverlay';
import { PartOption } from './PartOption';
import { Subscription } from 'rxjs';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

/**
 *
 * Base tool class to use when working with skiften
 * The working flow is like this
 * IsActive tells if the user is to work with the given tool, and the tool shall be available if selectedFeature ha a value
 * To enter a active state the function toggleActive is called,
 *  And then onActive or onDeActivated is called
 *  Any cleaning shall be done in the method clean
 *
 * With the tool is it recomended to use the Component MapSkiftePartComponent with a selector of dv-map-field-part
 * This component consumes the parts array with is poplualted by calling setParts,
 *
 * Befor any work can be done in the map the method onMapInit must be called, then the map is ready to start work
 *
 */

@Component({
  template: '',
})
export abstract class MapToolComponent implements OnDestroy {
  protected readonly LAYER_NAME = 'clipped';

  /**
   * Holds the state if the tool is active or not
   */
  @Input()
  isActive = false;

  /**
   * The GeoJson feature that represent the feature that was selected
   */
  @Input()
  selectedFeature: GeoJSON.Feature<GeoJSON.Polygon> = null;

  /**
   *  The orginal feature that was selected from the map
   */
  orginalFeature: google.maps.Data.Feature = null;

  /**
   * The selected skifte id
   */
  @Input()
  skifteId: number = null;

  /**
   * Parts is the array used to define the skifte to be
   */
  parts: PartOption[] = [];

  /**
   * Event fired when the users presses save
   */
  @Output()
  save = new EventEmitter<PartOption[]>();

  /**
   * The event fired when the users cancels
   */
  @Output()
  cancel = new EventEmitter<void>();

  /**
   * Event fired when the tool is activated
   */
  @Output()
  activated = new EventEmitter<void>();

  dvMap: MapComponent;
  map$: Subscription;
  mapName = 'editField';

  constructor(
    protected siteService: SiteService,
    protected mapService: MapService,
    protected cd: ChangeDetectorRef,
    protected zone: NgZone,
    protected translateService: DvToolbarTranslateService
  ) {
    this.map$ = this.mapService
      .map(this.mapName)
      .pipe(first())
      .subscribe((_dvMap) => {
        this.dvMap = _dvMap;
        _dvMap.disableSelect = true;
        _dvMap.leftMenuOpen = true;
        _dvMap.onSelected
          .pipe(
            first(),
            map((f) => {
              if (!_dvMap.multiSelect) {
                this.clean();
              }
              this.orginalFeature = f;
              return f;
            }),
            flatMap((f) => _dvMap.getGeoJson([f], true))
          )
          .subscribe(
            (fc: GeoJSON.FeatureCollection<GeoJSON.GeometryObject>) => {
              this.selectedFeature = <GeoJSON.Feature<GeoJSON.Polygon>>(
                fc.features[0]
              );
              if (this.selectedFeature.properties['skifteId']) {
                this.skifteId = parseInt(
                  this.selectedFeature.properties['skifteId']
                );
              }

              this.cd.markForCheck();
            }
          );

        _dvMap.onDeSelected.subscribe((f) => {
          if (f.getProperty('LAYER_NAME') === LAYER_NAME_SKIFTEN) {
            this.parts.forEach((p) => p.featureText.onRemove());
            this.selectedFeature = null;
            this.onDeActivated();
            this.clean();
            this.cd.markForCheck();
          }
        });

        if (this.isActive) {
          _dvMap.edit(this.orginalFeature);
          this.onActive();
        }
        this.onMapInit();
      });
  }

  /**
   * Internal method for when the map is ready
   */
  /**
   * Called when the map is init and ready to be worked with
   */
  protected abstract onMapInit(): void;
  /**
   * Called when the tool sould be actived
   */
  protected abstract onActive(): void;
  /**
   * Called when the tool is deactivated ie not active
   */
  protected abstract onDeActivated(): void;

  /**
   * Toggle the state of the tool
   */
  toggleActive(): void {
    this.isActive = !this.isActive;

    if (this.isActive) {
      this.dvMap.edit(this.orginalFeature);
      this.onActive();
    } else {
      this.dvMap.edit(null);
      this.clean();
      this.onDeActivated();
    }
  }

  /**
   * Sets the parts array from a given feature array
   * @param fc A array with features that is to be added to the part array
   */
  protected setParts(
    features: GeoJSON.Feature<GeoJSON.GeometryObject>[]
  ): void {
    this.dvMap.removeLayer(this.LAYER_NAME);

    features.forEach((f, i) => {
      f.id = f.id === -1 ? i : f.id;
    });

    const fc = turf.featureCollection(features);

    const addedFeatures = this.dvMap.addGeoJson(fc, this.LAYER_NAME);

    //if we have a orginalFeature if we adding a new feature we dont have one
    //this can be from drawing or when we are connecting from a block
    let zIndex: number;
    if (this.orginalFeature) {
      zIndex = this.orginalFeature.getProperty(LAYER_ZINDEX) + 1000;
    } else {
      zIndex = this.dvMap.zIndex();
    }

    this.parts.forEach((p) => p.featureText.onRemove());
    this.parts = [];

    for (let i = 0; i < features.length; i++) {
      addedFeatures[i].setProperty('color', COLORS[i % COLORS.length]);
      addedFeatures[i].setProperty(LAYER_ZINDEX, zIndex);
      addedFeatures[i].setProperty('labelX', 0);
      addedFeatures[i].setProperty('labelY', 0);
      if (addedFeatures[i].getProperty('areal') === undefined) {
        this.siteService.getAreaUnit().subscribe((areaUnit) => {
          addedFeatures[i].setProperty(
            'areal',
            Math.round(
              this.siteService.calculateArea(
                turf.area(<AllGeoJSON>features[i].geometry)
              ) * 100
            ) /
              100 +
              ' ' +
              areaUnit
          );
        });
      }

      this.parts[i] = {
        areal:
          Math.round(
            this.siteService.calculateArea(
              turf.area(<AllGeoJSON>features[i].geometry)
            ) * 100
          ) / 100,
        feature: fc.features[i],
        featureText: new FeatureTextOverlay(
          addedFeatures[i],
          'areal',
          this.dvMap
        ),
        action: this.skifteId ? (i === 0 ? 'update' : 'new') : 'new',
        color: COLORS[i % COLORS.length],
      };
    }
  }
  /**
   * Cleans up the map, not the same as destroy this can be called many times
   */
  protected clean(): void {
    this.parts.forEach((p) => {
      if (p.featureText) {
        p.featureText.onRemove();
      }
    });
    this.parts = [];
    this.dvMap.removeLayer(this.LAYER_NAME);
  }

  onSave(parts: PartOption[]): void {
    this.clean();
    this.save.next(parts);
  }

  ngOnDestroy(): void {
    if (this.map$) {
      this.map$.unsubscribe();
    }
  }
}
