<dv-sliding-pane [showPane]="!!feature">
  <div class="content">
    <dvm-text class="title" h2>{{ 'Selected data point' | dvt }}</dvm-text>
    <div class="property-section">
      <div class="property-section-item" *ngFor="let property of properties">
        <dvm-text class="property-section-item-text" h3>
          {{ property.key }}
        </dvm-text>
        <dvm-text
          class="property-section-item-text"
          body-large
          *ngIf="property.key !== 'areal'"
        >
          {{ property.value | number : '0.2-2' }}
        </dvm-text>
        <dvm-text
          class="property-section-item-text"
          body-large
          *ngIf="property.key === 'areal'"
        >
          {{ property.value | numberDecimal }}
        </dvm-text>
      </div>
    </div>
  </div>
  <dvm-button
    class="close-button"
    size="large"
    secondary
    (click)="closePane.emit()"
  >
    {{ 'Close' | dvt }}
  </dvm-button>
</dv-sliding-pane>
