import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrescriptionModel } from 'app/models/models';
import { PrescriptionService } from 'app/services/prescription.service';

@Component({
  selector: 'dv-prescription-buckets',
  templateUrl: './prescription-buckets.component.html',
  styleUrls: ['./prescription-buckets.component.scss'],
})
export class PrescriptionBucketsComponent {
  @Input() prescription: PrescriptionModel;
  @Input() disabled: boolean;
  @Input() unit: string;
  @Output() prescriptionChange = new EventEmitter<PrescriptionModel>();

  constructor(public prescSrv: PrescriptionService) {}

  private modelChanged(event: any): void {
    this.prescriptionChange.next(this.prescription);
  }
}
