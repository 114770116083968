import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DvTranslatePipe } from '@dv/toolbar-msal';
import {
  DvmInputModule,
  DvmTextModule,
  DvmToggleModule,
  DvmTooltipDirective,
} from '@dvm/components';

@Component({
  selector: 'dv-geo-data-header',
  templateUrl: 'geo-data-header.component.html',
  styleUrls: ['geo-data-header.component.scss'],
  standalone: true,
  imports: [
    DvmInputModule,
    DvmTextModule,
    DvmToggleModule,
    DvmTooltipDirective,
    DvTranslatePipe,
    NgClass,
    NgIf,
    FormsModule,
  ],
})
export class GeoDataHeaderComponent {
  @Input() title: string;
  @Input() showFixedValueToggle = false;
  @Input() min: number;
  @Input() max: number;
  @Output() useFixedValueChange = new EventEmitter<boolean>();
  @Output() fixedValueChange = new EventEmitter<number>();

  fixedValue = 0;
  expanded = false;

  onToggleChange(checked: boolean): void {
    this.useFixedValueChange.emit(checked);

    if (checked) {
      this.fixedValueChange.emit(
        isNaN(this.fixedValue) ? null : Number(this.fixedValue)
      );
    }
  }

  onInputChange(value?: number): void {
    this.fixedValueChange.emit(isNaN(value) ? null : value);
  }
}
