export interface GeoDataInterpolationStatusMsg {
  clientId: number;
  geoDataId: number;
  status: FileStatus;
  timeStamp: Date;
  message: string;
}

export interface FileSetImportStatusMsg {
  clientId: number;
  fileId: number;
  status: FileStatus;
  timeStamp: Date;
  message: string;
}

export enum FileStatus {
  Saved = 'Saved',
  InQueue = 'InQueue',
  InProcess = 'InProcess',
  Succeeded = 'Succeeded',
  Failed = 'Failed',
  Error = 'Error',
  Outdated = 'Outdated',
}

export interface FileImportStatusLogEntry {
  fileId: number;
  message: string;
  status: FileStatus;
  timestamp: string;
  success?: boolean;
  error?: boolean;
  warning?: boolean;
}
