import { Component, Input, OnInit } from '@angular/core';
import { PrescriptionWizardService } from '../../prescription-wizard.service';
import { PRESCRIPTION_PROPERTY_TYPE } from '../step-settings/step-settings.types';

@Component({
  selector: 'dv-settings-map-bar',
  templateUrl: 'settings-map-bar.component.html',
  styleUrls: ['settings-map-bar.component.scss'],
})
export class SettingsMapBarComponent implements OnInit {
  @Input() items: string[] = [];
  @Input() disabled: boolean;
  PRESCRIPTION_PROPERTY_TYPE = PRESCRIPTION_PROPERTY_TYPE;
  selectedIndex = 0;

  constructor(private prescriptionWizardService: PrescriptionWizardService) {}

  ngOnInit(): void {
    this.prescriptionWizardService.selectedGeoDataTypeIndex$.subscribe(
      (index) => (this.selectedIndex = index)
    );
  }

  selectItem(index: number): void {
    if (!this.disabled) {
      this.prescriptionWizardService.setGeoDataTypeIndex(index);
    }
  }
}
