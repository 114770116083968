<div mat-dialog-content style="width: 300px">
  <h2>{{ 'Crops' | dvt }}</h2>
  <span>{{ 'Select crops to add' | dvt }}</span>
  <mat-form-field appearance="outline" [floatLabel]="'always'">
    <input
      [placeholder]="'Search' | dvt"
      [(ngModel)]="searchValue"
      (keyup)="prodUtsadeSearchChanged()"
      matInput
    />
  </mat-form-field>
  <div class="wrapper">
    <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
    <mat-selection-list
      (selectionChange)="selectionChanged($event)"
      dense
      #crops
    >
      <mat-list-option
        color="primary"
        [checkboxPosition]="'before'"
        *ngFor="let crop of filteredUtsaden | async"
        [value]="crop.id"
        [selected]="isSelected(crop.id)"
      >
        {{ crop.groda }} {{ crop.sort }}
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-raised-button
    [disabled]="!selectedCrops.length"
    color="accent"
    (click)="save()"
  >
    {{ 'Save' | dvt }}
  </button>
  <button mat-raised-button (click)="cancel()">{{ 'Cancel' | dvt }}</button>
</div>
