import { Component, OnDestroy, ChangeDetectorRef, NgZone } from '@angular/core';
import * as turf from '@turf/turf';
import { MapToolComponent } from '../map-tool';
import { SiteService } from 'app/services/site.service';
import { MapService } from '../../map.service';
import { ClosestLatLng } from 'app/components/map/tools/vandteg/vandteg.types';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-map-vandteg',
  templateUrl: './vandteg.component.html',
  styleUrls: ['vandteg.component.scss'],
})
export class VandtegComponent extends MapToolComponent implements OnDestroy {
  message: string;
  enable: boolean;
  lengthLine: number;
  _targetDistance = 24;
  set targetDistance(value: number) {
    this._targetDistance = value;
    if (this._targetDistance > 0) {
      this.splitFeature();
    }
  }
  get targetDistance(): number {
    return this._targetDistance;
  }
  splitLine = new google.maps.Polyline({
    clickable: false,
    draggable: false,
    editable: false,
    strokeColor: '#1976d2',
    strokeWeight: 4,
    zIndex: 100000,
  });
  markers: google.maps.Marker[] = [];

  private currRing: google.maps.LatLng[] = null;
  private mapClienEv: google.maps.MapsEventListener = null;

  constructor(
    siteService: SiteService,
    mapService: MapService,
    cd: ChangeDetectorRef,
    public translateService: DvToolbarTranslateService,
    zone: NgZone
  ) {
    super(siteService, mapService, cd, zone, translateService);
  }

  protected onMapInit(): void {
    this.message = this.translateService.t('Rita en delningslinje i kartan');
  }

  /**
   * Called when the tool sould be actived
   */
  onActive(): void {
    this.mapClienEv = this.dvMap.map.addListener(
      'click',
      (ev: google.maps.MouseEvent) => {
        if (this.currRing === null) {
          this.currRing = this.getClosetsRing(
            (<google.maps.Data.Polygon>(
              this.orginalFeature.getGeometry()
            )).getArray(),
            ev.latLng
          );
        }

        const closets = this.getClosest(this.currRing, ev.latLng);

        if (!closets.latLang) {
          return;
        }

        const marker = new google.maps.Marker({
          map: this.dvMap.map,
          position: closets.latLang,
          clickable: true,
        });
        this.markers.push(marker);
        google.maps.event.addListenerOnce(marker, 'click', () => {
          const indexOfMarker = this.markers.indexOf(marker);

          while (this.markers.length > indexOfMarker) {
            const m = this.markers.pop();
            m.setMap(null);
          }
          if (this.markers.length === 0) {
            this.currRing = null;
          } else {
            this.updateLine(this.currRing);
          }

          this.splitFeature();
        });

        if (this.markers.length > 0) {
          this.splitLine.setMap(this.dvMap.map);
          this.updateLine(this.currRing);
        }

        this.splitFeature();
      }
    );

    this.activated.next();
  }

  /**
   * Called when the tool is deactivated ie not active
   */
  onDeActivated(): void {
    this.clean();
    if (this.mapClienEv) {
      this.mapClienEv.remove();
    }

    this.cancel.next();
  }

  splitFeature(): void {
    if (this.markers.length < 1) {
      this.clean();
    }

    if (this.markers.length < 2) {
      this.dvMap.removeLayer(this.LAYER_NAME);
      return;
    }

    this.mapService
      .topology({
        featureA: this.selectedFeature,
        featureB: turf.lineString(
          this.splitLine
            .getPath()
            .getArray()
            .map((latLng: google.maps.LatLng) => {
              return [latLng.lng(), latLng.lat()];
            })
        ),
        pointA: null,
        pointB: null,
        dist: this.targetDistance,
        area: null,
        operator: 'Headland',
      })
      .subscribe((f) => {
        if (f && f.length > 1) {
          const tmp = f[0];
          f[0] = f[f.length - 1];
          f[f.length - 1] = tmp;
        }
        this.setParts(f);
        this.cd.markForCheck();
      });
  }

  updateLine(arr: google.maps.LatLng[]): void {
    let lastLatLng: google.maps.LatLng = null;
    let path = [];

    this.markers.forEach((marker) => {
      if (lastLatLng === null) {
        lastLatLng = marker.getPosition();
      } else {
        path = path.concat(
          this.getShortestPath(arr, lastLatLng, marker.getPosition())
        );
        lastLatLng = marker.getPosition();
      }
    });

    this.splitLine.setPath(path);

    this.zone.run(() => {
      this.lengthLine = google.maps.geometry.spherical.computeLength(path);
      this.cd.markForCheck();
    });
  }

  /**
   * Gets a object that describes the closets point on a given lineRing
   * @param lineRing The lineRing to seach in
   * @param latLng The point to search by
   */
  getClosest(
    arr: google.maps.LatLng[],
    latLng: google.maps.LatLng
  ): ClosestLatLng {
    let closetsDist = 200;
    let closetsPoint: google.maps.LatLng = null;
    let closetsIndex = -1;
    arr.forEach((p, i) => {
      const dist = google.maps.geometry.spherical.computeDistanceBetween(
        p,
        latLng
      );
      if (dist < closetsDist) {
        closetsDist = dist;
        closetsPoint = p;
        closetsIndex = i;
      }
    });

    return {
      index: closetsIndex,
      latLang: closetsPoint,
      dist: closetsDist,
    };
  }

  getShortestPath(
    arr: google.maps.LatLng[],
    start: google.maps.LatLng,
    end: google.maps.LatLng
  ): google.maps.LatLng[] {
    const closetsStart = this.getClosest(arr, start);
    const closetsEnd = this.getClosest(arr, end);

    const arrForward: google.maps.LatLng[] = [];
    const arrBackward: google.maps.LatLng[] = [];

    let i = closetsStart.index;

    //add all points to the forward array, break when we are att the point
    while (i !== closetsEnd.index) {
      arrForward.push(arr[i++]);
      if (i < 0) {
        i = arr.length - 1;
      } else if (i >= arr.length) {
        i = 0;
      }
    }
    arrForward.push(closetsEnd.latLang);

    //add all points to the backward array, break when we are att the point
    i = closetsStart.index;
    while (i !== closetsEnd.index) {
      arrBackward.push(arr[i--]);
      if (i < 0) {
        i = arr.length - 1;
      } else if (i >= arr.length) {
        i = 0;
      }
    }
    arrBackward.push(closetsEnd.latLang);

    //return the shortest array
    if (arrForward.length < arrBackward.length) {
      return arrForward;
    } else {
      return arrBackward;
    }
  }

  getClosetsRing(
    lineRing: google.maps.Data.LinearRing[],
    latLng: google.maps.LatLng
  ): google.maps.LatLng[] {
    let closetsDist = 10000;
    let closetsRing: google.maps.LatLng[] = null;

    lineRing.forEach((ring) => {
      ring.getArray().forEach((p) => {
        const dist = google.maps.geometry.spherical.computeDistanceBetween(
          p,
          latLng
        );
        if (dist < closetsDist) {
          closetsDist = dist;
          closetsRing = ring.getArray();
        }
      });
    });
    return closetsRing;
  }

  protected clean(): void {
    super.clean();
    this.markers.forEach((m) => m.setMap(null));
    this.splitLine.setMap(null);
    this.markers = [];
    this.lengthLine = 0;
  }

  ngOnDestroy(): void {
    this.clean();
    if (this.mapClienEv) {
      this.mapClienEv.remove();
    }
  }
}
