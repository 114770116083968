<div class="wrapper">
  <div class="actions">
    <h2>{{'Parcels' | dvt}}</h2>
    <div class="button-row">
      <button (click)="newField()" mat-raised-button color="accent">
          <mat-icon>add_circle_outline</mat-icon>
          <span>{{'New parcel' | dvt }}</span>
      </button>
      <button (click)="importFields()" mat-button color="primary" class="dv-button-border" *ngIf="missingSkiften && missingSkiften.count>0">
        <span>{{'Import {0} parcel(s)' | dvt:missingSkiften.count }}</span>
    </button>
    </div>
  </div>
    <div class="content">

        <mat-progress-bar [ngClass]="{'hide':!loadingFields}" [mode]="'query'"></mat-progress-bar>
        <table mat-table [dataSource]="dataSource" matSort class="dv-border field-table">
            <ng-container matColumnDef="delete">
                <th class="icon" mat-header-cell *matHeaderCellDef></th>
                <td matTooltip="{{(!edit ? 'Use CropPlan to edit your fields' : '') | dvt}}" (click)="openCropplan()" class="icon" style="width:30px" mat-cell *matCellDef="let element">
                    <button (click)="deleteField(element)" [ngClass]="{'disabled':!edit}" [disabled]="!edit" mat-icon-button>
                        <img src="assets/images/icon-delete.svg">
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="navigate">
                <th class="icon" mat-header-cell *matHeaderCellDef></th>
                <td class="icon" style="width:30px" mat-cell *matCellDef="let element">
                    <button matTooltip="{{'Navigate to field in map' | dvt}}" (click)="navigateToField(element)" mat-icon-button>
                        <img src="assets/images/icon-field.svg">
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="map">
                <th class="icon" mat-header-cell *matHeaderCellDef></th>
                <td matTooltip="{{(!edit ? 'Use CropPlan to edit your fields' : '') | dvt}}" (click)="openCropplan()" class="icon" style="width:30px" mat-cell *matCellDef="let element">
                    <button (click)="openMap(element)" [ngClass]="{'disabled':!edit}" [disabled]="!edit" mat-icon-button>
                        <img src="assets/images/icon-edit.svg">
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef (click)="openCropplan()">
                    <mat-checkbox matTooltip="{{(!edit ? 'Use CropPlan to edit your fields' : '') | dvt}}"
                        [disabled]="!edit" (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" color="primary">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row" (click)="openCropplan()">
                    <mat-checkbox matTooltip="{{(!edit ? 'Use CropPlan to edit your fields' : '') | dvt}}"
                        [disabled]="!edit" (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                        color="primary">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="brukEnhNamn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Farm' | dvt}} </th>
                <td mat-cell style="width: 260px" *matCellDef="let element">
                    <mat-form-field *ngIf="edit" style="width: 210px" class="table-input">
                        <mat-select #farmSelect [(ngModel)]="element.brukEnhId"
                            (selectionChange)="triggerSaveFarm(element)">
                            <div class="btn-row">
                                <button (click)="addFarm(element)" class="menu-btn" mat-button>
                                    <mat-icon  color="accent">add_circle_outline</mat-icon>{{'Add new farm' | dvt}}
                                </button>
                                <button (click)="editFarm()" class="menu-btn" mat-button>
                                    <mat-icon color="primary">edit</mat-icon>{{'Edit farms' | dvt}}
                                </button>
                            </div>
                            <mat-divider style="width: 100%"></mat-divider>
                            <mat-option *ngFor="let b of brukenheter" [value]="b.id">{{b.namn}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span *ngIf="!edit">{{element.brukEnhNamn}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="namn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Name' | dvt}} </th>
                <td style="width:300px" mat-cell *matCellDef="let element">
                    <mat-form-field *ngIf="edit" style="width: 250px" class="table-input">
                        <input [(ngModel)]="element.namn" matInput (keyup)="triggerSave(element)" />
                    </mat-form-field>
                    <span *ngIf="!edit">{{element.namn}} </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="areal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Area' | dvt}} </th>
                <td mat-cell style="width:120px" *matCellDef="let element">
                    <mat-form-field *ngIf="edit" style="width: 70px" class="table-input">
                        <input [(ngModel)]="element.areal" matInput (keyup)="triggerSave(element)" />
                        <span matSuffix>{{siteService.getAreaUnitFromSettings()}}</span>
                    </mat-form-field>
                    <span *ngIf="!edit">{{element.areal | areaUnit}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="huvudgrodaBenamning">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Crop' | dvt}} </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field *ngIf="edit" style="width: 210px" class="table-input">
                        <mat-select #cropSelect [value]="getHuvudgrodaId(element)"
                            (selectionChange)="huvudgrodaChange($event.value, element)">
                            <mat-option>
                                <div class="btn-row">
                                    <button (click)="addCrop(element, true)" class="menu-btn" mat-button>
                                        <mat-icon color="accent">add_circle_outline</mat-icon>{{'Add new crop' | dvt}}
                                    </button>
                                </div>
                            </mat-option>
                            <mat-divider style="width: 100%"></mat-divider>
                            <mat-option *ngFor="let tu of tabellUtsade" [value]="tu.id">{{tu.groda}} {{tu.sort}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span *ngIf="!edit">{{element.huvudgrodaBenamning}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="forfrukt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Preceding crop' | dvt}} </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field *ngIf="edit" style="width: 210px" class="table-input">
                        <mat-select [(ngModel)]="element.forfruktId" (selectionChange)="triggerSaveForfrukt($event.value, element)">
                            <mat-option>
                                <div class="btn-row">
                                    <button (click)="addCrop(element)" class="menu-btn" mat-button>
                                        <mat-icon color="accent">add_circle_outline</mat-icon>{{'Add new crop' | dvt}}
                                    </button>
                                </div>
                            </mat-option>
                            <mat-divider style="width: 100%"></mat-divider>
                            <mat-option *ngFor="let tu of tabellUtsade" [value]="tu.id">{{tu.groda}} {{tu.sort}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span *ngIf="!edit">{{element.forfrukt}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="newfield">
                <th mat-header-cell *matHeaderCellDef>
                    <button (click)="newField()" mat-raised-button color="accent">
                        <mat-icon>add_circle_outline</mat-icon>
                        <span>{{'New parcel' | dvt }}</span>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element"></td>
            </ng-container>

            <ng-container matColumnDef="saving">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell style="width: 40px" *matCellDef="let element">
                    <mat-spinner *ngIf="element.saving" [diameter]="30"></mat-spinner>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"> </tr>
        </table>
        <div *ngIf="dataSource.data.length === 0 && !loadingFields" class="nodata dv-iconcolor">
            <img style="width: 40px; margin-right: 10px;" src="assets/images/icon-Fields.svg" />
            <span>{{'Fieldlist is empty' | dvt}}</span>
        </div>
        <mat-paginator style="position: absolute; bottom: 0px; right: 0; width: 100%;" #paginator
            [pageSizeOptions]="[20, 50, 100, 1000]" showFirstLastButtons></mat-paginator>
    </div>
</div>
