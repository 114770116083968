<div *ngIf="!resizable">
  <div>
    <div [class.inactive]="minimized" class="chart" #histogram></div>
    <div class="graph">
      <div [class.inactive]="minimized" class="chart" #legend></div>
      <div [class.inactive]="loading || !legendReady" class="info">
        <div class="property" *ngIf="legendObj?.showArea">
          <span>{{ 'Areal' | dvt }}</span>
          <p>{{ legendObj?.totalArea | areaUnit: '1.0-2' }}</p>
        </div>
        <div class="property" *ngIf="legendObj?.showMinMax">
          <span>{{ 'Max' | dvt }}</span>
          <p>{{ legendObj?.max | number: '1.0-2' }} {{ unitAreaDependent }}</p>
        </div>
        <div class="property" *ngIf="legendObj?.showMinMax">
          <span>{{ 'Min' | dvt }}</span>
          <p>{{ legendObj?.min | number: '1.0-2' }} {{ unitAreaDependent }}</p>
        </div>
        <div class="property" *ngIf="legendObj?.showAvg">
          <span>{{ 'Average' | dvt }}</span>
          <p>{{ legendObj?.avg | number: '1.0-2' }} {{ unitAreaDependent }}</p>
        </div>
        <div class="property" *ngIf="legendObj?.showSum">
          <span>{{ 'Sum' | dvt }}</span>
          <p>{{ legendObj?.sum | number: '1.0-2' }} {{ legendObj?.unit }}</p>
        </div>
        <div class="property" *ngIf="legendObj?.numberOfPoints > 0">
          <span>{{ 'Data points' | dvt }}</span>
          <p>{{ legendObj?.numberOfPoints | number: '1.0-2' }}</p>
        </div>
      </div>
    </div>
    <div class="loading-chart" *ngIf="loading || !legendReady">
      <img src="/assets/images/animated-bar-chart.svg" />
    </div>
  </div>
</div>
<div
  *ngIf="resizable"
  (click)="toggle()"
  [class.zoomed-in]="!minimized"
  class="over-map"
>
  <mat-icon class="toggle" *ngIf="!minimized">close_fullscreen</mat-icon>
  <mat-icon class="toggle" *ngIf="minimized">open_in_full</mat-icon>
  <div>
    <div [class.inactive]="true" class="chart" #histogram></div>
    <div [class.inactive]="minimized" class="chart" #mediumlegend></div>
  </div>
  <div [class.inactive]="!minimized" class="chart mini" #minilegend></div>
  <div [class.inactive]="resizable" #legend></div>
</div>
