import {
  Component,
  ChangeDetectorRef,
  NgZone,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import * as turf from '@turf/turf';
import { Polygon, Feature, AllGeoJSON } from '@turf/turf';
import { SiteService } from 'app/services/site.service';
import { MapComponent } from '../../map.component';
import {
  MapService,
  LAYER_ZINDEX,
  COLORS,
  LAYER_NAME_SKIFTEN,
} from '../../map.service';
import { PartOption } from '../PartOption';
import { first, takeUntil } from 'rxjs/operators';
import { FeatureTextOverlay } from '../../overlay/FeatureTextOverlay';
import { MappingWizardService } from 'app/components/mapping-view/mapping-wizard/mapping-wizard.service';
import { Subject } from 'rxjs';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-map-draw-direct',
  templateUrl: './draw-direct.component.html',
  styleUrls: ['./draw-direct.component.scss'],
})
export class DrawDirectComponent implements OnChanges, OnDestroy {
  private mapClienEv: google.maps.MapsEventListener;
  private mapMouseupEv: google.maps.MapsEventListener;

  protected readonly LAYER_NAME = 'clipped';

  /**
   * Holds the state if the tool is active or not
   */
  @Input()
  isActive = false;

  /**
   * The GeoJson feature that represent the feature that was selected
   */
  @Input()
  selectedFeature: GeoJSON.Feature<GeoJSON.Polygon> = null;

  /**
   *  The orginal feature that was selected from the map
   */
  orginalFeature: google.maps.Data.Feature = null;

  /**
   * The selected skifte id
   */
  @Input()
  skifteId: number = null;

  /**
   * Parts is the array used to define the skifte to be
   */
  parts: PartOption[] = [];

  /**
   * Event fired when the users presses save
   */
  @Output()
  save = new EventEmitter<PartOption[]>();

  /**
   * The event fired when the users cancels
   */
  @Output()
  cancel = new EventEmitter<void>();

  /**
   * Event fired when the tool is activated
   */
  @Output()
  activated = new EventEmitter<void>();

  dvMap: MapComponent;
  mapName = 'editField';
  currAreal = 0;
  isDrawing = false;
  buttonText: string = this.translate.t('Rita skifte');
  selectedPart = -1;

  private _unsub$: Subject<void> = new Subject<void>();
  history: google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>>[] =
    [];
  historyIndex = 0;

  @Input() set btnText(value: string) {
    this.buttonText = this.translate.t(value);
  }

  private drawMainPoly = new google.maps.Polygon({
    editable: true,
  });

  private currentPolygon = new google.maps.Polygon();
  private polygonParts: google.maps.Polygon[] = [];

  constructor(
    public translate: DvToolbarTranslateService,
    protected mapService: MapService,
    private zone: NgZone,
    private siteService: SiteService,
    private cd: ChangeDetectorRef,
    private wizSrv: MappingWizardService
  ) {
    this.mapService
      .map(this.mapName)
      .pipe(first())
      .subscribe((_dvMap) => {
        this.dvMap = _dvMap;
        if (this.skifteId) {
          this.onActive();
        }
      });
  }

  addPolygon(paths?: any): void {
    const poly = new google.maps.Polygon({
      editable: true,
      fillOpacity: 0.15,
      fillColor: '#ffffff',
      clickable: false,
    });

    poly.addListener('click', (event: google.maps.PolyMouseEvent) => {
      if (poly.getEditable() && event.vertex !== undefined) {
        poly.getPath().removeAt(event.vertex);
      }
    });

    this.showPolygon(poly);
    if (paths) {
      poly.setPath(paths);
    } else {
      poly.setPath([]);
    }
    this.polygonPartsSetEditable(false);
    this.polygonParts.push(poly);
    this.setCurrentPolygon(this.polygonParts.length - 1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !this.isActive &&
      changes.skifteId &&
      changes.skifteId.currentValue !== null
    ) {
      this.skifteId = changes.skifteId.currentValue;
      this.onActive();
    }
  }

  removePolygon(): void {
    this.polygonParts[this.selectedPart].setMap(null);
    this.polygonParts.splice(this.selectedPart, 1);

    this.setCurrentPolygon(-1);
  }

  showPolygon(polygon: google.maps.Polygon): void {
    polygon.setMap(this.dvMap.map);
  }

  polygonPartsSetEditable(value: boolean): void {
    this.polygonParts.forEach((p) => {
      p.setEditable(value);
    });

    this.drawMainPoly.setEditable(value);
  }

  polygonPartChanged(value: number): void {
    this.setCurrentPolygon(value);
  }

  setCurrentPolygon(index: number): void {
    if (index === -1) {
      this.currentPolygon = this.drawMainPoly;
    } else {
      this.currentPolygon = this.polygonParts[index];
    }

    this.polygonPartsSetEditable(false);
    this.currentPolygon.setEditable(true);
    this.selectedPart = index;
  }

  protected onMapInit(): void {
    // EMPTY METHOD
  }

  protected onActive(): void {
    this.isActive = true;
    this.orginalFeature = this.dvMap
      .getFeatures(LAYER_NAME_SKIFTEN)
      .find((f) => f.getProperty('skifteId') === this.skifteId);

    this.drawMainPoly.setOptions({
      zIndex: this.orginalFeature
        ? this.orginalFeature.getProperty(LAYER_ZINDEX) + 1
        : 10000,
      editable: true,
      clickable: false,
    });

    this.history = [];
    this.historyIndex = -1;
    this.addHistory(this.drawMainPoly.getPaths());

    this.startDraw();

    this.currentPolygon = this.drawMainPoly;

    this.mapMouseupEv = this.dvMap.map.addListener('mouseup', () => {
      this.updateAreal();
    });

    this.currentPolygon.addListener('click', (event) => {
      this.addHistory(this.currentPolygon.getPaths());
      if (this.currentPolygon.getEditable() && event.vertex !== undefined) {
        this.currentPolygon.getPath().removeAt(event.vertex);
      }

      this.updateAreal();
    });
    this.currentPolygon.addListener('mouseup', () => {
      this.updateAreal();
    });

    this.mapClienEv = this.dvMap.map.addListener(
      'click',
      (ev: google.maps.MouseEvent) => {
        const path = this.currentPolygon.getPath();
        this.addHistory(this.currentPolygon.getPaths());

        let closestDist = Number.MAX_SAFE_INTEGER;
        let closestIndex = -1;
        path.forEach((p, i) => {
          const dist = google.maps.geometry.spherical.computeDistanceBetween(
            p,
            ev.latLng
          );
          if (dist < closestDist) {
            closestDist = dist;
            closestIndex = i;
          }
        });

        if (closestIndex > -1) {
          const tmpPoly = new google.maps.Polygon();
          const tempPath = new google.maps.MVCArray<google.maps.LatLng>();
          path.forEach((p, i) => {
            tempPath.insertAt(i, p);
          });

          tempPath.insertAt(closestIndex, ev.latLng);
          tmpPoly.setPath(tempPath);
          let poly = this.getTurfPolygon(tmpPoly);

          if (poly) {
            /**
             * kinks kollar om polygonen överlappar sig själv.
             * Gör den det så hoppar vi bak ett steg från närmsta punkt. Om det fortfarande är överlapp
             * hoppar vi fram 1 ett steg från närmsta punkt.
             */
            let res = turf.kinks(poly);
            if (res.features.length > 0) {
              closestIndex -= 1;

              tempPath.insertAt(closestIndex, ev.latLng);
              tmpPoly.setPath(tempPath);
              poly = this.getTurfPolygon(tmpPoly);
              res = turf.kinks(poly);

              if (res.features.length > 0) {
                closestIndex += 2;
              }
            }
          }
        }

        if (closestIndex === -1) {
          closestIndex = 0;
        }
        path.insertAt(closestIndex, ev.latLng);
        this.currentPolygon.setPath(path);

        this.updateAreal();
      }
    );

    this.updateAreal();

    this.activated.next();
  }

  private updateAreal(): void {
    this.zone.run(() => {
      const diff = this.diffPolygons();
      if (diff) {
        this.currAreal = this.siteService.calculateArea(turf.area(diff));
        this.cd.markForCheck();
      }
    });
  }

  protected onDeActivated(): void {
    if (this.mapClienEv) {
      this.mapClienEv.remove();
    }

    if (this.mapMouseupEv) {
      this.mapMouseupEv.remove();
    }

    this.drawMainPoly.setMap(null);
    this.dvMap.show(this.orginalFeature);

    this.cancel.next();
  }

  startDraw(): void {
    this.setParts([]);

    this.isDrawing = true;
    this.drawMainPoly.setMap(this.dvMap.map);

    if (this.orginalFeature) {
      this.dvMap.hide(this.orginalFeature);

      if (this.polygonParts.length > 0) {
        this.showPolygon(this.drawMainPoly);
        this.polygonParts.forEach((p) => {
          this.showPolygon(p);
        });
      } else {
        const orgPolygons = (<google.maps.Data.Polygon>(
          this.orginalFeature.getGeometry()
        ))
          .getArray()
          .map((outer) => {
            return outer.getArray().map((inner) => {
              return inner;
            });
          });

        this.drawMainPoly.setPaths(orgPolygons[0]);

        for (let i = 1; i < orgPolygons.length; i++) {
          this.addPolygon(orgPolygons[i]);
        }
      }

      this.setCurrentPolygon(-1);
    } else {
      this.drawMainPoly.setPath([]);
    }

    this.updateAreal();
  }

  doneDraw(): void {
    this.isDrawing = false;

    this.removePolygonsFromMap();

    const mainPoly = this.diffPolygons();

    this.setParts([mainPoly]);

    this.updateAreal();
    setTimeout(() => {
      this.onSave(this.parts);
    });
  }

  diffPolygons(): turf.helpers.Feature<
    turf.helpers.Polygon,
    {
      [name: string]: any;
    }
  > {
    let mainPoly = this.getTurfPolygon(this.drawMainPoly);
    this.polygonParts.forEach((p) => {
      const hole = this.getTurfPolygon(p);
      if (hole) {
        mainPoly = <Feature<Polygon>>turf.difference(mainPoly, hole);
      }
    });

    return mainPoly;
  }

  getTurfPolygon(googlePoly: google.maps.Polygon): turf.helpers.Feature<
    turf.helpers.Polygon,
    {
      [name: string]: any;
    }
  > | null {
    const rings = [];
    googlePoly.getPaths().forEach((ring) => {
      const ringLatLng = ring.getArray().map((latLng) => {
        return [latLng.lng(), latLng.lat()];
      });
      ringLatLng.push(ringLatLng[0]);

      rings.push(ringLatLng);
    });

    if (rings.length > 0 && rings[0].length > 3) {
      return turf.polygon(rings);
    }
    return null;
  }

  removePolygonsFromMap(): void {
    this.drawMainPoly.setMap(null);
    this.polygonParts.forEach((p) => {
      p.setMap(null);
    });
  }

  setHistory(dir: number): void {
    //the history is not done
    this.historyIndex += dir;
    if (this.historyIndex < 0) {
      this.historyIndex = 0;
    } else if (this.historyIndex >= this.history.length) {
      this.historyIndex = this.history.length - 1;
    }

    this.drawMainPoly.setPaths(this.history[this.historyIndex]);
  }

  addHistory(
    paths: google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>>
  ): void {
    //the history is not done
    this.historyIndex += 1;
    this.history.splice(
      this.historyIndex,
      this.history.length - this.historyIndex
    );

    this.history.push(new google.maps.MVCArray(paths.getArray()));
  }

  /**
   * Sets the parts array from a given feature array
   * @param fc A array with features that is to be added to the part array
   */
  protected setParts(
    features: GeoJSON.Feature<GeoJSON.GeometryObject>[]
  ): void {
    this.dvMap.removeLayer(this.LAYER_NAME);

    features.forEach((f, i) => {
      f.id = f.id === -1 ? i : f.id;
    });

    const fc = turf.featureCollection(features);

    const addedFeatures = this.dvMap.addGeoJson(fc, this.LAYER_NAME);

    //if we have a orginalFeature if we adding a new feature we dont have one
    //this can be from drawing or when we are connecting from a block
    let zIndex: number;
    if (this.orginalFeature) {
      zIndex = this.orginalFeature.getProperty(LAYER_ZINDEX) + 1000;
    } else {
      zIndex = this.dvMap.zIndex();
    }

    this.parts.forEach((p) => p.featureText.onRemove());
    this.parts = [];

    for (let i = 0; i < features.length; i++) {
      addedFeatures[i].setProperty('color', COLORS[i % COLORS.length]);
      addedFeatures[i].setProperty(LAYER_ZINDEX, zIndex);
      addedFeatures[i].setProperty('labelX', 0);
      addedFeatures[i].setProperty('labelY', 0);
      if (addedFeatures[i].getProperty('areal') === undefined) {
        this.siteService
          .getAreaUnit()
          .pipe(takeUntil(this._unsub$))
          .subscribe((areaUnit) => {
            addedFeatures[i].setProperty(
              'areal',
              Math.round(
                this.siteService.calculateArea(
                  turf.area(<AllGeoJSON>features[i].geometry)
                ) * 100
              ) /
                100 +
                ' ' +
                areaUnit
            );
          });
      }

      this.parts[i] = {
        areal:
          Math.round(
            this.siteService.calculateArea(
              turf.area(<AllGeoJSON>features[i].geometry)
            ) * 100
          ) / 100,
        feature: fc.features[i],
        featureText: new FeatureTextOverlay(
          addedFeatures[i],
          'areal',
          this.dvMap
        ),
        action: this.skifteId ? (i === 0 ? 'update' : 'new') : 'new',
        color: COLORS[i % COLORS.length],
      };
    }
  }

  /**
   * Cleans up the map, not the same as destroy this can be called many times
   */
  protected clean(): void {
    this.parts.forEach((p) => {
      if (p.featureText) {
        p.featureText.onRemove();
      }
    });
    this.parts = [];
    this.dvMap.removeLayer(this.LAYER_NAME);
  }

  onSave(parts: PartOption[]): void {
    this.clean();
    this.save.next(parts);
  }

  close(): void {
    this.wizSrv.setEditBordersField(null);
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }
}
