<mat-list-item>
  <button
    mat-button
    [disabled]="selectedFeature === null"
    (click)="toggleActive()"
  >
    <img class="map-split-image" src="assets/images/icon-split.svg" />
    {{ 'Split parcel' | dvt }}
  </button>
</mat-list-item>
<div class="info dv-maps-tool" *ngIf="isActive">
  <div class="content">
    <p *ngIf="parts.length < 2">
      {{ 'Draw a splitline across the parcel' | dvt }}
    </p>
    <dv-map-field-part
      [skifteId]="skifteId"
      [parts]="parts"
      (save)="onSave($event)"
      (cancel)="toggleActive()"
    ></dv-map-field-part>
  </div>
</div>
