import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FunctionModel } from 'app/models/api.models';
import { PrescriptionWizardService } from 'app/views/prescription-wizard-page/prescription-wizard.service';
import { PRESCRIPTION_STEPS } from 'app/views/prescription-wizard-page/prescription-wizard.types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StepSettingsService } from './step-settings.service';
import {
  FunctionGeoDataDefinition,
  FunctionParameterDefinition,
  FunctionParameterWithType,
  GeoDataBarItem,
  PARAMETER_IDS,
  PARAMETER_TYPES,
  PrescriptionSettings,
  PRESCRIPTION_PROPERTY_TYPE,
  FunctionNestedDefinition,
  LINK_ROUTES,
  FunctionGeographicDefinition,
  GeoDataFileType,
  GridSettings,
  INDEX_TYPES,
  FunctionSelectInputDefinition,
  FunctionSelectInputOption,
} from './step-settings.types';
import { PrescriptionWizardInterpolationService } from '../../services/perscription-wizard-interpolation.service';
import { PhParameterIds } from './components/input-section/components/ph-input/ph-input.types';

@Component({
  selector: 'dv-step-settings',
  templateUrl: 'step-settings.component.html',
  styleUrls: ['step-settings.component.scss', '../steps.scss'],
})
export class StepSettingsComponent implements OnChanges, OnInit, OnDestroy {
  @Input() function: FunctionModel;
  @Input() loading: boolean;
  @Output() geoDataTypes = new EventEmitter<GeoDataBarItem[]>();
  @Output() settings = new EventEmitter<PrescriptionSettings>();
  @Output() geoDataIds = new EventEmitter<number[]>();
  @Output() gridSettingsChange = new EventEmitter<GridSettings>();
  @Output() disablePreviewBar = new EventEmitter<boolean>();
  @Output() selectedSeedUnit = new EventEmitter<FunctionSelectInputOption>();
  inputs: FunctionParameterWithType<FunctionParameterDefinition>[];
  geoData: FunctionParameterWithType<
    | FunctionGeoDataDefinition
    | FunctionNestedDefinition
    | FunctionGeographicDefinition
  >[] = [];
  PARAMETER_TYPES = PARAMETER_TYPES;
  LINK_ROUTES = LINK_ROUTES;
  PRESCRIPTION_PROPERTY_TYPE = PRESCRIPTION_PROPERTY_TYPE;
  selectedGeoDataIndex = -1;
  isInputsValid = false;
  isGeoDataValid = false;
  gridSettings: GridSettings;
  filteredGeoData: GeoDataFileType[] = [];
  targetPhParameter: FunctionParameterWithType<FunctionParameterDefinition>;
  private unsub$ = new Subject<void>();
  private phParameterId: PhParameterIds;

  _showGridSettingsPane = false;

  get showGridSettingsPane(): boolean {
    return this._showGridSettingsPane;
  }

  set showGridSettingsPane(value: boolean) {
    this._showGridSettingsPane = value;
    this.disablePreviewBar.emit(value);
  }

  constructor(
    private wizardService: PrescriptionWizardService,
    private stepService: StepSettingsService,
    private interpolationService: PrescriptionWizardInterpolationService
  ) {
    this.resetGridSettings();
  }

  ngOnInit(): void {
    this.listenToSelectedGeoDataType();
    this.listenToSelectedStepIndex();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.function?.currentValue) {
      this.filterFunctionParameters();
    }
  }

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  setInputsValidity(valid: boolean): void {
    this.isInputsValid = valid;
    this.checkIfStepIsDone();
  }

  setGeoDataValidity(valid: boolean): void {
    this.isGeoDataValid = valid;
    this.checkIfStepIsDone();
  }

  onHideSettingsPane(newSettings: GridSettings): void {
    this.showGridSettingsPane = false;

    if (newSettings) {
      this.gridSettings = { ...newSettings };
    }
    this.interpolationService.interpolate(
      this.geoData[this.selectedGeoDataIndex]
    );
  }

  onShowSettingsPane(): void {
    this.showGridSettingsPane = true;
    this.interpolationService.removeInterpolation();
  }

  private listenToSelectedGeoDataType(): void {
    this.wizardService.selectedGeoDataTypeIndex$
      .pipe(takeUntil(this.unsub$))
      .subscribe((index) => {
        this.selectedGeoDataIndex = index;
        const selectedGeoData = this.geoData[this.selectedGeoDataIndex];

        if (
          this.phParameterId === PARAMETER_IDS.TARGET_PH &&
          selectedGeoData?.id === PARAMETER_IDS.TARGET_PH
        ) {
          this.interpolationService.removeInterpolation();
        } else {
          this.interpolationService.interpolate(
            this.geoData[this.selectedGeoDataIndex]
          );
        }
      });
  }

  private listenToSelectedStepIndex(): void {
    this.wizardService.selectedStepIndex$
      .pipe(takeUntil(this.unsub$))
      .subscribe((index) => {
        if (index === PRESCRIPTION_STEPS.SETTINGS) {
          this.handleNavToSettings();
        } else {
          this.handleNavToOther(index);
        }
      });
  }

  private handleNavToSettings(): void {
    if (this.geoData[this.selectedGeoDataIndex]?.fixedValue) {
      this.interpolationService.removeInterpolation();
    } else {
      this.interpolationService.interpolate(
        this.geoData[this.selectedGeoDataIndex]
      );
    }
  }

  private handleNavToOther(index: PRESCRIPTION_STEPS): void {
    if (index === PRESCRIPTION_STEPS.ADJUST) {
      this.emitSettings();
    } else if (index < PRESCRIPTION_STEPS.SETTINGS) {
      this.resetGridSettings();
    }

    this.interpolationService.interpolate(null);
  }

  private resetGridSettings(): void {
    this.showGridSettingsPane = false;
    this.gridSettings = {
      angle: 0,
      cellSize: 20,
    };
  }

  private filterFunctionParameters(): void {
    this.inputs = this.stepService.getInputs(this.function.parameters);

    this.geoData = this.stepService.getGeoData(this.function.parameters);
    this.targetPhParameter = this.function.parameters.find(
      (parameter) => parameter.id === PARAMETER_IDS.TARGET_PH
    );

    this.filteredGeoData = this.geoData.filter(
      (data) => data.parameterType !== PARAMETER_TYPES.TARGET_PH
    ) as GeoDataFileType[];

    this.emitGeoDataTypes();
  }

  private emitGeoDataTypes(): void {
    const types = this.geoData.map((data) => ({
      id: data.id,
      name: data.name,
      type: (data.definition as FunctionGeoDataDefinition)
        .property as PRESCRIPTION_PROPERTY_TYPE,
    }));

    this.geoDataTypes.emit(types);
  }

  onAdjustPhValueChange({ clearInterpolation, parameterId }): void {
    const selectedGeoData = this.geoData[this.selectedGeoDataIndex];
    this.phParameterId = parameterId;

    if (selectedGeoData?.id === PARAMETER_IDS.TARGET_PH) {
      if (!clearInterpolation) {
        this.interpolationService.interpolate(
          this.geoData[this.selectedGeoDataIndex]
        );
      } else {
        this.interpolationService.removeInterpolation();
      }
    }
  }

  private checkIfStepIsDone(): void {
    if (!this.isInputsValid || !this.isGeoDataValid) {
      this.wizardService.selectFunctionParameters(false);
    } else {
      this.wizardService.selectFunctionParameters(true);
    }
  }

  onFileChange(index: number): void {
    this.checkIfStepIsDone();
    if (index === this.selectedGeoDataIndex) {
      this.interpolationService.interpolate(this.geoData[index]);
    }
    if (this.selectedGeoDataIndex === INDEX_TYPES.TARGETPH) {
      //för att interpolate ska veta om mullhalt eller lerhalt geodata har fasta värden behöver dess geodata skickas till interpolate
      if (this.geoData[INDEX_TYPES.HUMUS].useFixedValue) {
        this.interpolationService.interpolate(this.geoData[INDEX_TYPES.HUMUS]);
      } else if (this.geoData[INDEX_TYPES.CLAY].useFixedValue) {
        this.interpolationService.interpolate(this.geoData[INDEX_TYPES.CLAY]);
      } else {
        this.interpolationService.interpolate(
          this.geoData[this.selectedGeoDataIndex]
        );
      }
    }
  }

  onItemChange(index: number): void {
    this.checkIfStepIsDone();
    if (index === this.selectedGeoDataIndex) {
      this.interpolationService.interpolate(this.geoData[index]);
    }
    if (this.selectedGeoDataIndex === INDEX_TYPES.TARGETPH) {
      this.interpolationService.interpolate(
        this.geoData[this.selectedGeoDataIndex]
      );
    }
  }

  emitSettings(): void {
    const settings: PrescriptionSettings = {
      gridSettings: this.gridSettings,
      functionInput: {
        functionId: this.function.id,
        parameters: this.stepService.extractSettings(this.inputs, this.geoData),
      },
      adjustments: null,
    };

    this.emitSelectedSeedUnit();

    this.settings.emit(settings);
  }

  private emitSelectedSeedUnit(): void {
    const seedUnitInput = this.inputs.find(
      (input) => input.id === PARAMETER_IDS.SEED_UNIT
    ) as FunctionParameterWithType<FunctionSelectInputDefinition>;
    const seedUnitOption = seedUnitInput?.definition.options.find(
      (option) => option.id === seedUnitInput.value
    );

    this.selectedSeedUnit.emit(seedUnitOption);
  }
}
