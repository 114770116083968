<div
  class="dv-menu-link border"
  [routerLink]="[APP_PATH.MAP, APP_PATH.MAPPING]"
  routerLinkActive="active"
>
  <img src="assets/images/menu/icon-map.svg" />
  <span class="dv-menu-link-content">{{ 'Field Data' | dvt }}</span>
</div>

<div
  class="dv-menu-link border"
  [class.disabled]="!hasCropMapAdvanced"
  [routerLink]="hasCropMapAdvanced ? APP_PATH.ANALYZE : null"
  (click)="openMissingRightsDialog()"
  [routerLinkActive]="hasCropMapAdvanced ? 'active' : 'inactive'"
>
  <img src="assets/images/menu/icon-analyze.svg" />

  <span class="dv-menu-link-content">{{ 'Analyze' | dvt }}</span>
</div>

<div
  class="dv-menu-link border"
  [routerLink]="APP_PATH.DATA"
  routerLinkActive="active"
>
  <img src="assets/images/menu/icon-files.svg" />
  <span class="dv-menu-link-content">{{ 'Data' | dvt }}</span>
</div>

<div
  *ngIf="!isAgrometsius"
  class="dv-menu-link border"
  [routerLink]="APP_PATH.PRESCRIPTION_WIZARD"
  routerLinkActive="active"
>
  <img
    class="import-btn-icon"
    src="https://cdn.datavaxt.se/icons/CropMAP/CropMap-tilldelning.svg"
  />
  <span class="dv-menu-link-content">{{ 'Prescription' | dvt }}</span>
</div>

<div class="filler"></div>
<div class="dv-menu-link" [matMenuTriggerFor]="settingsMenu">
  <div mat-ripple>
    <mat-icon
      class="with-margin"
      [matBadge]="missingSkiften?.count"
      matBadgeSize="small"
      [matBadgeHidden]="
        missingSkiften == undefined || missingSkiften?.count == 0
      "
      >settings
    </mat-icon>
  </div>
</div>

<mat-menu #settingsMenu="matMenu">
  <button mat-menu-item (click)="about()">
    <mat-icon color="primary">info</mat-icon>
    <span>{{ 'About CropMAP' | dvt }}</span>
  </button>
  <mat-divider class="with-margin"></mat-divider>
  <a
    mat-menu-item
    href="https://export.datavaxt.se"
    target="_blank"
    *ngIf="hasCropMapAdvanced && !isAgrometsius"
  >
    <mat-icon> cloud_download </mat-icon>
    <span>{{ 'Export data' | dvt }}</span>
  </a>
  <button
    mat-menu-item
    (click)="importFields()"
    *ngIf="!(missingSkiften == undefined || missingSkiften?.count == 0)"
  >
    <img [style]="farmStyle" src="\assets\images\icon-field.svg" />
    <span
      [matBadge]="missingSkiften?.count"
      matBadgeSize="small"
      [matBadgeHidden]="
        missingSkiften == undefined || missingSkiften?.count == 0
      "
    >
      {{ 'Import parcel(s)' | dvt }}
    </span>
  </button>
  <button mat-menu-item (click)="brukEnh()">
    <img [style]="farmStyle" src="\assets\images\menu\icon-start.svg" />
    <span>{{ 'Farms' | dvt }}</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon> exit_to_app </mat-icon>
    <span>{{ 'Sign out' | dvt }}</span>
  </button>
</mat-menu>
