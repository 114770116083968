<mat-list-item>
  <button mat-button (click)="toggleActive()">
    <img class="merge-image" src="assets/images/icon-merge.svg" />
    {{ 'Merge' | dvt }}
  </button>
</mat-list-item>
<div class="info dv-maps-tool" *ngIf="isActive">
  <div class="content">
    <p>
      {{ 'Original area' | dvt }} {{ currArealOld | areaUnit: '1.0-2' }} <br />
      {{ 'New area' | dvt }} {{ currAreal | areaUnit: '1.0-2' }}
    </p>
    <div class="merge-warning-icon-wrapper" *ngIf="warn">
      <mat-icon class="merge-warning-icon">warning</mat-icon>
      {{ 'Merge contains two parts as they are not connected' | dvt }}
    </div>

    <div *ngIf="skifteToRemove" class="merge-remove-field-text">
      {{ 'These field will be removed' | dvt }}
      <strong>{{ skifteToRemove.namn }}</strong>
      <strong>{{ skifteToRemove.areal | number: '1.0-2' }}</strong>
      {{ siteService.getAreaUnit() | async }}
    </div>

    <dv-map-field-part
      [skifteId]="skifteId"
      [parts]="parts"
      (save)="onSave($event)"
      (cancel)="toggleActive()"
    ></dv-map-field-part>
  </div>
</div>
