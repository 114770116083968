import { Component, ChangeDetectorRef, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import * as turf from '@turf/turf';
import { first, map } from 'rxjs/operators';
import { MapToolComponent } from '../map-tool';
import { SiteService } from 'app/services/site.service';
import { MapService, METERS, LAYER_ZINDEX } from '../../map.service';
import { PartOption } from '../PartOption';
import { ClientService } from 'app/services/client.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-map-merge',
  templateUrl: 'merge.component.html',
  styleUrls: ['merge.component.scss'],
})
export class MergeComponent extends MapToolComponent {
  private onSelectedSub: Subscription;
  private featureToMerge: GeoJSON.Feature<any> = null;
  message = '';
  currAreal = 0;
  currArealOld = 0;
  skifteToRemove = null;
  warn = false;

  constructor(
    siteService: SiteService,
    mapService: MapService,
    cd: ChangeDetectorRef,
    zone: NgZone,
    translate: DvToolbarTranslateService,
    private clientService: ClientService
  ) {
    super(siteService, mapService, cd, zone, translate);
  }

  protected onMapInit(): void {
    this.onSelectedSub = this.dvMap.onSelected.subscribe(
      (f: google.maps.Data.Feature) => {
        if (this.isActive) {
          this.dvMap
            .getGeoJson([f])
            .subscribe((fc) => this.merge(fc.features[0]));
        }
      }
    );
  }

  private setLayerToMerge(): void {
    if (!this.featureToMerge && this.selectedFeature) {
      this.featureToMerge = turf.feature(this.selectedFeature.geometry);
      this.currArealOld = this.siteService.calculateArea(
        turf.area(this.featureToMerge)
      );
    }
    this.setParts([this.featureToMerge]);
  }

  private merge(feature: GeoJSON.Feature<any>): void {
    let f: GeoJSON.Feature<any> = turf.union(this.featureToMerge, feature);
    if (!f) {
      return;
    }

    //if we get a MultiPolygon try to make the parts 1 meter bigger and se if we still gets a MultiPolygon
    //if we do just warn
    if (f.geometry.type === 'MultiPolygon') {
      //we make the parts 1 meter bigger make a union and then shrink the buffer with 1 meter
      const newF = turf.buffer(
        turf.union(
          turf.buffer(this.featureToMerge, 1, METERS),
          turf.buffer(feature, 1, METERS)
        ),
        -1,
        METERS
      );

      if (newF && newF.geometry.type === 'Polygon') {
        f = newF;
      }

      this.warn = f.geometry.type === 'MultiPolygon';
    } else {
      this.warn = false;
    }

    f.properties[LAYER_ZINDEX] = 5000;
    this.currAreal = this.siteService.calculateArea(turf.area(f));

    f.properties['areal'] = Math.round(this.currAreal * 100) / 100;
    this.setParts([f]);

    this.skifteToRemove = feature.properties;
    this.cd.markForCheck();
  }

  protected onActive(): void {
    this.dvMap.disableSelect = false;
    this.clientService
      .getSkifteLayer()
      .pipe(
        first(),
        map((fc: GeoJSON.FeatureCollection<GeoJSON.GeometryObject>) => {
          fc.features = fc.features.filter((f) => {
            return f.properties['skifteId'] !== this.skifteId;
          });
          return fc;
        })
      )
      .subscribe((skiften) => {
        this.dvMap.addGeoJson(skiften, '_skiftenTemp');
      });
    this.setLayerToMerge();
    this.activated.next();
  }

  protected onDeActivated(): void {
    this.dvMap.disableSelect = true;
    this.dvMap.removeLayer('_skiftenTemp');
    this.featureToMerge = null;
    this.cancel.next();
  }

  onSave(parts: PartOption[]): void {
    this.clean();
    this.clientService
      .deleteSkifte(this.skifteToRemove['skifteId'])
      .subscribe(() => {
        // Empty callback
      });

    this.save.next(parts);
  }
}
