<button mat-fab class="close" color="primary" (click)="close()">
  <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title>{{ 'Upload file' | dvt }}</h1>
<div mat-dialog-content>
  <mat-horizontal-stepper
    #stepper
    [linear]="true"
    (selectionChange)="stepperChanged($event)"
  >
    <mat-step
      [label]="''"
      [completed]="importModel.type !== 'unknown'"
      [editable]="stepperIndex < 2"
    >
      <h3>{{ 'What type of file do you want to upload' | dvt }}?</h3>
      <div class="import-btns-wrapper">
        <div class="import-btns">
          <button
            class="import-btn"
            mat-raised-button
            (click)="setType('soilsampling')"
          >
            <span class="import-btn-content">
              <img
                class="import-btn-icon"
                src="assets/images/icon-soilsampling.svg"
              />
              {{ 'Soilsampling' | dvt }}
            </span>
          </button>
          <button
            class="import-btn"
            mat-raised-button
            (click)="setType('nsensor')"
          >
            <span class="import-btn-content">
              <img
                class="import-btn-icon"
                src="assets/images/icon-logdata.svg"
              />
              {{ 'Logdata' | dvt }}
            </span>
          </button>
          <button
            class="import-btn"
            mat-raised-button
            (click)="setType('prescription')"
          >
            <span class="import-btn-content">
              <img
                class="import-btn-icon"
                src="assets/images/icon-prescription.svg"
              />
              {{ 'Prescription' | dvt }}
            </span>
          </button>
          <button
            class="import-btn"
            mat-raised-button
            (click)="setType('yield')"
          >
            <span class="import-btn-content">
              <img class="import-btn-icon" src="assets/images/icon-yield.svg" />
              {{ 'Yield' | dvt }}
            </span>
          </button>
        </div>
        <div class="import-btns">
          <button
            class="import-btn"
            mat-raised-button
            (click)="setType('veris')"
            *ngIf="showVeris"
          >
            <span class="import-btn-content">
              <img class="import-btn-icon" src="assets/images/icon-veris.svg" />
              {{ 'Veris' | dvt }}
            </span>
          </button>
          <button
            class="import-btn"
            mat-raised-button
            (click)="setType('sensordata')"
          >
            <span class="import-btn-content">
              <img
                class="import-btn-icon"
                src="https://cdn.datavaxt.se/icons/CropMAP/Cropmap-sensordata.svg"
              />
              {{ 'Sensordata' | dvt }}
            </span>
          </button>
          <button
            class="import-btn"
            mat-raised-button
            (click)="setType('common')"
          >
            <span class="import-btn-content">
              <img
                class="import-btn-icon"
                src="assets/images/icon-Mapfile.svg"
              />
              {{ 'Common' | dvt }}
            </span>
          </button>
          <button
            class="import-btn"
            mat-raised-button
            (click)="setType('fieldboundary')"
          >
            <span class="import-btn-content">
              <img
                class="import-btn-icon"
                src="assets/images/icon-Fields.svg"
              />
              {{ 'Field boundarys' | dvt }}
            </span>
          </button>
          <button
            *ngIf="showBetaFunction$ | async"
            class="import-btn"
            mat-raised-button
            (click)="setType('asapplied')"
          >
            <span class="import-btn-content">
              <img class="import-btn-icon" src="assets/images/icon-point.svg" />
              {{ 'As applied' | dvt }}
            </span>
          </button>
        </div>
      </div>
    </mat-step>
    <mat-step label="" [editable]="stepperIndex < 2">
      <dv-yield
        *ngIf="importModel.type === 'yield'"
        (importChange)="stepTwoChanged($event)"
        [importModel]="importModel"
      ></dv-yield>
      <dv-soilsampling
        *ngIf="importModel.type === 'soilsampling'"
        (importChange)="stepTwoChanged($event)"
        [importModel]="importModel"
      ></dv-soilsampling>
      <dv-veris
        *ngIf="importModel.type === 'veris'"
        (importChange)="stepTwoChanged($event)"
        [importModel]="importModel"
      ></dv-veris>
      <dv-prescription
        *ngIf="importModel.type === 'prescription'"
        (importChange)="stepTwoChanged($event)"
        [importModel]="importModel"
      ></dv-prescription>
      <dv-logdata
        *ngIf="importModel.type === 'nsensor'"
        (importChange)="stepTwoChanged($event)"
        [importModel]="importModel"
      ></dv-logdata>
      <dv-common
        *ngIf="importModel.type === 'common'"
        (importChange)="stepTwoChanged($event)"
        [importModel]="importModel"
      ></dv-common>
      <dv-field-boundary
        *ngIf="importModel.type === 'fieldboundary'"
        (fieldsDone)="fieldsDone($event)"
      ></dv-field-boundary>
      <dv-sensordata
        *ngIf="importModel.type === 'sensordata'"
        (importChange)="stepTwoChanged($event)"
        [importModel]="importModel"
      ></dv-sensordata>
      <dv-asapplied
        *ngIf="importModel.type === 'asapplied'"
        (importChange)="stepTwoChanged($event)"
        [importModel]="importModel"
      ></dv-asapplied>
    </mat-step>
    <mat-step *ngIf="importModel.type !== 'fieldboundary'">
      <dv-metadata-prompt (save)="onMetadataSave($event)"></dv-metadata-prompt>
    </mat-step>
    <mat-step label="">
      <div *ngIf="fileUploadProgress < 100" class="fileupload-files">
        <span class="error" *ngIf="error">{{ error }}</span>
        <div
          *ngIf="
            (importModel.files !== null && importModel.files.length > 0) ||
            (fields && fields.length > 0)
          "
        >
          <mat-progress-bar
            [mode]="fileUploadMode"
            [value]="fileUploadProgress"
          ></mat-progress-bar>
          {{ 'Uploading your files' | dvt }}
        </div>
        <div class="help-text">
          {{ uploadFileHelpText }}
        </div>
      </div>
      <div *ngIf="fileUploadProgress >= 100" class="fileupload-done">
        <div>{{ fileImportStatus }}</div>
        <div class="fileupload-done-button-wrapper">
          <button mat-raised-button color="accent" (click)="close()">
            {{ '_fileupload_done' | dvt : '_Done' }}
          </button>
        </div>
        <p *ngIf="!error">
          {{
            '_fileupload_done_close_info'
              | dvt
                : '_You can close this dialog. The upload will continue anyway.'
          }}
        </p>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
