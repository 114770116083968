import { FunctionModel } from 'app/models/api.models';
import {
  GridSettings,
  PrescriptionAdjustments,
  PrescriptionSettings,
} from './components/step-settings/step-settings.types';
import { PrescriptionBucketModel } from 'app/models/models';

export interface SkifteSelection {
  source: SKIFTE_SELECTION_SOURCE;
  id: number;
  name: string;
}

export enum SKIFTE_SELECTION_SOURCE {
  TABLE,
  MAP,
}

export enum SKIFTE_TABLE_COLUMNS {
  RADIO_BUTTON = 'radio-button',
  FIELD = 'field',
  CROP = 'crop',
  UNIT = 'unit',
}

export enum PRESCRIPTION_STEPS {
  FIELD,
  METHOD,
  SETTINGS,
  ADJUST,
}

export interface StepState {
  completed: boolean;
  label: string;
}

export interface SkifteTableChange {
  id: number;
  namn: string;
}

export type SelectedValue =
  | SkifteSelection
  | FunctionModel
  | PrescriptionSettings;

export interface AdjustmentEvent {
  adjustments: PrescriptionAdjustments;
  gridSettings: GridSettings;
}

export interface AdjustedPrescriptionMetadata {
  legendBuckets: PrescriptionBucketModel[];
  total: number;
  avg: number;
}
