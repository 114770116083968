import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PoiKategoriModel, PoiModel } from 'app/models/api.models';
import { DialogService } from 'app/components/dialog/dialog.service';
import { ClientService } from 'app/services/client.service';
import { first } from 'rxjs/operators';
import { PoiService } from '../poi.service';
import { POI_STATES } from '../poi.types';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-poi-add',
  templateUrl: './poi-add.component.html',
  styleUrls: ['./poi-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoiAddComponent implements OnInit, OnChanges {
  @Input() feature: google.maps.Data.Feature;
  @Output() save = new EventEmitter<PoiModel>(null);
  @Output() cancel = new EventEmitter<void>(null);
  categorys: PoiKategoriModel[] = null;
  poi: PoiModel = null;
  kategori: string;
  currentImage: string = null;
  utfordText: string;
  loading = false;
  imageDeleted = false;

  constructor(
    private clientService: ClientService,
    private cd: ChangeDetectorRef,
    private poiSrv: PoiService,
    private translate: DvToolbarTranslateService,
    private datePipe: DatePipe,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.clientService.getPoisCategorys().subscribe((res) => {
      this.categorys = res;
      if (!this.poi) {
        this.addFeature();
      } else {
        this.poi.kategoriId = this.categorys.find(
          (c) => c.namn === this.poi.kategori
        ).id;
        this.cd.markForCheck();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.feature) {
      this.feature = changes.feature.currentValue;
      if (this.poi) {
        this.feature.toGeoJson((f: GeoJSON.Feature) => {
          this.poi.feature = f;
        });
      }
    }
  }

  addFeature(): void {
    const catId = this.categorys?.find(
      (cat) => cat.namn === this.feature?.getProperty('kategori')
    )
      ? this.categorys.find(
          (cat) => cat.namn === this.feature.getProperty('kategori')
        ).id
      : -1;

    this.feature.toGeoJson((f: GeoJSON.Feature) => {
      this.poi = <any>{
        image: null,
        kategoriId:
          catId > 0
            ? catId
            : this.categorys.length > 0
            ? this.categorys[0].id
            : -1,
        anteckning: this.feature.getProperty('anteckning'),
        datum: this.feature.getProperty('datum'),
        feature: f,
        id: this.feature.getProperty('id')
          ? this.feature.getProperty('id')
          : -1,
        kategori: this.feature?.getProperty('kategori')
          ? this.feature.getProperty('kategori')
          : this.categorys.length > 0
          ? this.categorys[0].namn
          : null,
        utford:
          this.feature?.getProperty('utford') &&
          this.feature?.getProperty('utford').length > 0
            ? this.feature.getProperty('utford')
            : null,
      };

      this.currentImage =
        this.feature.getProperty('imgeRef') &&
        this.feature.getProperty('klientId')
          ? this.getImageLink(
              this.feature.getProperty('imgeRef'),
              this.feature.getProperty('klientId')
            )
          : null;

      this.utfordText = this.poi.utford
        ? this.datePipe.transform(this.poi.utford, 'shortDate')
        : this.translate.t('Not done');

      this.cd.markForCheck();
    });

    this.feature.getProperty('utford')
      ? this.datePipe.transform(this.feature.getProperty('utford'), 'shortDate')
      : this.translate.t('Not done');
  }

  savePoi(): void {
    this.loading = true;
    this.clientService
      .savePoi(this.poi, this.imageDeleted)
      .subscribe(
        (res) => {
          this.save.emit(res);
          this.poiSrv.setState(null);
          this.poiSrv.setPoiFeature(null);
        },
        () =>
          this.dialogService.message(
            this.translate.t('Something went wrong. Please try again')
          )
      )
      .add(() => {
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  saveImg(event): void {
    this.clientService.savePoiImg(event.target.files[0]).subscribe((res) => {
      this.imageDeleted = false;
      this.poi.image = res;
      this.cd.detectChanges();
    });
  }

  cancelAdd(): void {
    this.cancel.emit();
  }

  editCateogrys(): void {
    this.poiSrv.setState(POI_STATES.CATEGORY);
  }

  getImageLink(imgeRef: string, klientId: number): string {
    return `https://dvwebphoto.blob.core.windows.net/${klientId}/${imgeRef}/large.jpg`;
  }

  toggleDone(event: MatSlideToggleChange): void {
    this.poi.utford = event.checked ? new Date() : null;
    this.utfordText = this.poi.utford
      ? this.datePipe.transform(this.poi.utford, 'shortDate')
      : this.translate.t('Not done');
  }

  delete(): void {
    this.dialogService
      .confirm(
        this.translate.t('Are you sure you want to remove this marking?')
      )
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.clientService
            .removePoi(this.poi.id)
            .pipe(first())
            .subscribe(() => {
              this.cancel.emit();
            });
        }
      });
  }

  deleteImg(): void {
    this.poi.image = null;
    this.imageDeleted = true;
    this.currentImage = null;
  }
}
