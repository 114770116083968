import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientService } from 'app/services/client.service';
import { Subject, of, combineLatest, Observable } from 'rxjs';
import {
  ProduktUtsadeModel,
  ProduktModel,
  TabellUtsadeModel,
} from 'app/models/api.models';
import { MatSelectionListChange } from '@angular/material/list';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-add-crop',
  templateUrl: './add-crop.component.html',
  styleUrls: ['./add-crop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCropComponent implements OnInit {
  prodUtsade: ProduktUtsadeModel[] = [];
  searchValue: string;
  loading = true;
  filteredUtsaden: Subject<ProduktUtsadeModel[]> = new Subject<
    ProduktUtsadeModel[]
  >();
  selectedCrops: number[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private clientService: ClientService,
    private dialogRef: MatDialogRef<AddCropComponent>,
    private snackBar: MatSnackBar,
    private translate: DvToolbarTranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { data: TabellUtsadeModel[] }
  ) {}

  ngOnInit(): void {
    this.clientService.getProdUts(true).subscribe((uts) => {
      this.prodUtsade = uts.filter(
        (crop) => !this.data.data.find((u) => u.prodId === crop.id)
      );
      this.filteredUtsaden.next(this.prodUtsade);
      this.loading = false;
      this.cd.markForCheck();
    });
  }

  save(): void {
    const arr: Observable<TabellUtsadeModel>[] = [];
    this.selectedCrops.forEach((c) => {
      const prod = this.prodUtsade.find((p) => p.id === c);
      const model = <ProduktModel>{};
      model.id = prod.id;
      model.grupp = prod.groda;
      model.benamning = prod.sort;
      arr.push(this.clientService.saveTabellUtsade(model));
    });

    combineLatest(arr)
      .pipe(
        catchError((err) => {
          this.snackBar.open(
            this.translate.t('An error occured: {0}', err),
            null,
            { duration: 3000 }
          );
          return of(null);
        })
      )
      .subscribe((res: TabellUtsadeModel[]) => {
        if (res.length > 0) {
          this.dialogRef.close(res[0]);
        } else {
          this.dialogRef.close(null);
        }
      });
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  selectionChanged(event: MatSelectionListChange): void {
    const index = this.selectedCrops.indexOf(event.options[0].value);
    if (index > -1) {
      this.selectedCrops.splice(index, 1);
    } else {
      this.selectedCrops.push(event.options[0].value);
    }
  }

  isSelected(cropId: number): boolean {
    return this.selectedCrops.includes(cropId);
  }

  prodUtsadeSearchChanged(): void {
    this.filteredUtsaden.next(
      this.searchValue
        ? this._filterProdUtsade(this.searchValue)
        : this.prodUtsade
    );
  }

  private _filterProdUtsade(value: string): ProduktUtsadeModel[] {
    const filterValue = value.toLowerCase();
    return this.prodUtsade.filter(
      (tu) =>
        (tu.groda && tu.groda.toLowerCase().includes(filterValue)) ||
        (tu.sort && tu.sort.toLowerCase().includes(filterValue))
    );
  }
}
