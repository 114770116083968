import { Injectable } from '@angular/core';
import { FunctionParameter } from 'app/models/api.models';
import {
  FunctionGeoDataDefinition,
  FunctionGeographicDefinition,
  FunctionInputParameters,
  FunctionNestedDefinition,
  FunctionNumberInputDefinition,
  FunctionParameterDefinition,
  FunctionParameterWithType,
  FunctionSelectInputDefinition,
  NestedValue,
  PARAMETER_IDS,
  PARAMETER_TYPES,
} from './step-settings.types';

@Injectable({
  providedIn: 'root',
})
export class StepSettingsService {
  getInputs(parameters: FunctionParameter[]): FunctionParameter[] {
    const inputs = parameters.filter(
      (parameter) =>
        parameter.parameterType !== PARAMETER_TYPES.GEO_DATA &&
        parameter.parameterType !== PARAMETER_TYPES.TARGET_PH &&
        parameter.parameterType !== PARAMETER_TYPES.NESTED
    );

    this.prepareInputs(inputs);
    this.addInputTypeToConstants(inputs);

    return inputs;
  }

  getGeoData(
    parameters: FunctionParameter[]
  ): FunctionParameterWithType<
    FunctionGeoDataDefinition | FunctionNestedDefinition
  >[] {
    const geoData = parameters.filter(
      (parameter) =>
        parameter.parameterType === PARAMETER_TYPES.GEO_DATA ||
        parameter.parameterType === PARAMETER_TYPES.TARGET_PH ||
        parameter.parameterType === PARAMETER_TYPES.NESTED
    ) as FunctionParameterWithType<
      FunctionGeoDataDefinition | FunctionNestedDefinition
    >[];

    geoData.forEach(
      (data) => (data.asIndex = data.id === PARAMETER_IDS.VARIED_YIELD)
    );

    this.prepareGeoData(geoData);

    return geoData;
  }

  private prepareInputs(
    inputs: FunctionParameterWithType<FunctionParameterDefinition>[]
  ): void {
    inputs.forEach((input) => {
      if (input.parameterType === PARAMETER_TYPES.SELECT) {
        this.prepareSelectInput(
          input as FunctionParameterWithType<FunctionSelectInputDefinition>,
          inputs
        );
      } else if (input.parameterType === PARAMETER_TYPES.OPTION) {
        input.value = false;
      }
    });
  }

  private prepareSelectInput(
    selectInput: FunctionParameterWithType<FunctionSelectInputDefinition>,
    inputs: FunctionParameterWithType<FunctionParameterDefinition>[]
  ): void {
    selectInput.value = selectInput.definition.options.find(
      (option) => option.id === selectInput.definition.selected
    )?.id;

    if (selectInput.id === PARAMETER_IDS.CROP_SELECTED) {
      const defaultExpectedYield = selectInput.definition.options.find(
        (option) => option.id === selectInput.definition.selected
      )?.value;

      inputs.find(
        (element) => element.id === PARAMETER_IDS.EXPECTED_YIELD
      ).value = defaultExpectedYield;
    }
  }

  private prepareGeoData(
    geoData: FunctionParameterWithType<
      FunctionGeoDataDefinition | FunctionNestedDefinition
    >[]
  ): void {
    geoData.forEach((data) => {
      if (data.parameterType === PARAMETER_TYPES.GEO_DATA) {
        const geoData =
          data as FunctionParameterWithType<FunctionGeoDataDefinition>;
        geoData.value = [...geoData.definition.selectedFiles];
      }

      if (data.parameterType === PARAMETER_TYPES.NESTED) {
        const nestedData =
          data as FunctionParameterWithType<FunctionNestedDefinition>;
        const nestedParameter = nestedData.definition.nestedParameter[0];

        nestedData.value = {
          length: nestedParameter.definition.files.length,
          [nestedParameter.id]: [...nestedParameter.definition.selectedFiles],
        };
      }
    });
  }

  private addInputTypeToConstants(
    inputs: FunctionParameterWithType<FunctionParameterDefinition>[]
  ): void {
    inputs.forEach((input) => {
      if (input.parameterType !== PARAMETER_TYPES.CONSTANT) {
        return;
      }

      const { maxLimit, minLimit, value } =
        input.definition as FunctionNumberInputDefinition;
      input.type =
        maxLimit !== undefined || minLimit !== undefined || value !== undefined
          ? 'number'
          : 'text';
      if (input.type === 'number') {
        const defaultValue =
          input as FunctionParameterWithType<FunctionNumberInputDefinition>;
        defaultValue.value = defaultValue.definition.value;
        if (!defaultValue.definition.value) {
          input.value = '';
        }
      }
    });
  }

  extractSettings(
    inputs: FunctionParameterWithType<FunctionParameterDefinition>[],
    geoData: FunctionParameterWithType<
      | FunctionGeoDataDefinition
      | FunctionNestedDefinition
      | FunctionGeographicDefinition
    >[]
  ): FunctionInputParameters {
    const inputSettings = this.extractInputSettings(inputs);
    const geoDataSettings = this.extractGeoDataSettings(geoData);

    return {
      ...inputSettings,
      ...geoDataSettings,
    };
  }

  private extractInputSettings(
    inputs: FunctionParameterWithType<FunctionParameterDefinition>[]
  ): FunctionInputParameters {
    return inputs.reduce((prev, curr) => {
      if (curr.parameterType === PARAMETER_TYPES.OPTION) {
        prev[curr.id] = curr.value;
      } else {
        prev[curr.id] = Number(curr.value ?? 0);
      }
      return prev;
    }, {});
  }

  private extractGeoDataSettings(
    geoData: FunctionParameterWithType<
      | FunctionGeoDataDefinition
      | FunctionNestedDefinition
      | FunctionGeographicDefinition
    >[]
  ): FunctionInputParameters {
    return geoData.reduce((prev, curr) => {
      if (curr.parameterType === PARAMETER_TYPES.NESTED) {
        const nestedParameter =
          curr as FunctionParameterWithType<FunctionNestedDefinition>;
        prev[curr.id] = this.getNestedValue(nestedParameter);
      } else {
        const geodataParameter =
          curr as FunctionParameterWithType<FunctionGeoDataDefinition>;
        prev[curr.id] = this.getGoeDataValue(geodataParameter);
      }

      return prev;
    }, {});
  }

  private getNestedValue(
    parameter: FunctionParameterWithType<FunctionNestedDefinition>
  ): FunctionInputParameters | number {
    const selectedKey = Object.keys(parameter.value).find(
      (key) => key !== 'length'
    );
    const value = Array.isArray(parameter.value[selectedKey])
      ? [...parameter.value[selectedKey]]
      : parameter.value[selectedKey];

    return parameter.useFixedValue
      ? parameter.fixedValue
      : {
          [selectedKey]: value,
        };
  }

  private getGoeDataValue(
    geodataParameter: FunctionParameterWithType<FunctionGeoDataDefinition>
  ): string | number | number[] | boolean | NestedValue {
    if (geodataParameter.useFixedValue) {
      return geodataParameter.fixedValue ?? 0;
    } else {
      return geodataParameter.value ?? 0;
    }
  }
}
