import { PrescriptionModel } from 'app/models/api.models';

export enum EXPORT_KEY {
  CONNECTED_FARM = 'ConnectedFarm',
  TRIMBLE = 'Trimble',
  JOHN_DEERE = 'John Deere',
  KVERNELAND = 'Kverneland',
  CNH = 'CNH',
  YARA = 'Yara',
  AGCO = 'Agco',
  MULLER = 'Muller',
  SHAPE = 'Shape',
  JPG = 'Jpg',
  TEXT = 'Text',
  MACHINE_CONNECTION = 'MachineConnection',
}

export interface ExportOption {
  key: EXPORT_KEY;
  showOption: boolean;
  imgSrc: string;
  text: string;
}

export interface PrescriptionExportData {
  exportOnly: boolean;
  fileName: string;
  functionPrescription: boolean;
  nutritionalContent: number;
  prescription: PrescriptionModel;
  unit: string;
}
