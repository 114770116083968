import { NgModule } from '@angular/core';
import { AreaUnit } from './area-unit.pipe';
import { PerAreaUnit } from './per-area-unit.pipe';
import { TrimSystemfolder } from './trim-system-folder.pipe';
import { DecimalPipe } from '@angular/common';
import { NumberDecimalPipe } from '../number-decimal/number-decimal.pipe';

@NgModule({
  declarations: [AreaUnit, NumberDecimalPipe, PerAreaUnit, TrimSystemfolder],
  exports: [AreaUnit, NumberDecimalPipe, PerAreaUnit, TrimSystemfolder],
  providers: [AreaUnit, DecimalPipe, PerAreaUnit, TrimSystemfolder],
})
export class PipeModule {}
