<mat-list-item *ngIf="!isActive">
  <button
    mat-button
    [disabled]="selectedFeature === null"
    (click)="toggleActive()"
  >
    <img class="vandteg-image" src="assets/images/icon-headland.svg" />{{
      'Headland' | dvt
    }}
  </button>
</mat-list-item>
<div class="info dv-maps-tool" *ngIf="isActive">
  <div class="content">
    <div>
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'Avstånd' | dvt }}"
          type="number"
          [(ngModel)]="targetDistance"
          name="areal"
          autocomplete="off"
        />
        <span matSuffix>m</span>
      </mat-form-field>
    </div>
    <p>{{ 'Lenght of headland' | dvt }} {{ lengthLine | number: '1.0-0' }} m</p>
    <div
      *ngIf="
        parts.length < 2 && (targetDistance !== null || targetArea !== null)
      "
    >
      {{ 'Pick startingpoint of headland' | dvt }}
    </div>
    <div *ngIf="targetDistance === null && parts.length < 2">
      {{ 'Provide width of headland in meters' | dvt }}
    </div>

    <dv-map-field-part
      [skifteId]="skifteId"
      [parts]="parts"
      (save)="onSave($event)"
      (cancel)="toggleActive()"
    ></dv-map-field-part>
  </div>
</div>
