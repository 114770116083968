import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';
import {
  LAYER_NAME_BLOCK,
  LAYER_NAME_INTERPOLATION,
  LAYER_NAME_SKIFTEN,
  LAYER_NAME_VALUEPOINT,
  LAYER_SELECTED,
  MapService,
} from 'app/components/map/map.service';
import { MappingWizardService } from 'app/components/mapping-view/mapping-wizard/mapping-wizard.service';
import { ClientService } from 'app/services/client.service';
import { MapStateService } from 'app/services/map-state.service';
import { FeatureCollection, Geometry } from 'geojson';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, flatMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dv-field-page',
  templateUrl: './field-page.component.html',
  styleUrls: ['./field-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldPageComponent implements OnInit, OnDestroy {
  map$: Subscription;
  skiften$: Subscription;
  mapBounds$: google.maps.MapsEventListener;
  mapBoundsSub = new Subject();
  feature: google.maps.Data.Feature = null;
  shp = false;
  draw = false;
  validZoom = false;
  selectedFeature: google.maps.Data.Feature;
  tempFeature: google.maps.Data.Feature = new google.maps.Data.Feature();
  drawPoly: google.maps.Polygon;
  activeMap;
  blocks: FeatureCollection<Geometry>;
  private _unsub$: Subject<void> = new Subject<void>();
  private skiften: google.maps.Data.Feature[];
  @Output() fieldSaved = new EventEmitter<void>();
  @Output() cancel = new EventEmitter();

  constructor(
    private mapService: MapService,
    private clientService: ClientService,
    private snackBar: MatSnackBar,
    private translSrv: DvToolbarTranslateService,
    private wizSrv: MappingWizardService,
    private mapStateService: MapStateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.map$ = this.mapService
      .mainMap()
      .pipe(takeUntil(this._unsub$))
      .subscribe((dvMap) => {
        this.activeMap = dvMap;
        if (dvMap.map.getZoom() <= 13) {
          dvMap.map.setZoom(14);
        }

        this.mapBounds$ = google.maps.event.addListener(
          dvMap.map,
          'bounds_changed',
          (ev) => {
            // We add the event to a sub so we can use a debounceTime
            this.mapBoundsSub.next(ev);
            this.validZoom = dvMap.map.getZoom() > 13;
            this.changeDetectorRef.detectChanges();
          }
        );

        this.mapBoundsSub
          .pipe(
            filter(() => dvMap.map.getZoom() > 13 && !this.draw),
            debounceTime(500),
            flatMap(() => {
              dvMap.loading = true;
              return this.clientService.getBlocks(dvMap.getExtent());
            })
          )
          .pipe(takeUntil(this._unsub$))
          .subscribe(
            (blocks) => {
              this.blocks = blocks;
              blocks.features.forEach((f) => {
                f.id = f.properties['blockKey'];
                if (f.id === this.selectedFeature?.getId()) {
                  f.properties[LAYER_SELECTED] = true;
                } else if (f.properties[LAYER_SELECTED]) {
                  f.properties[LAYER_SELECTED] = false;
                }
              });
              if (!this.draw) {
                dvMap.addGeoJson(blocks, LAYER_NAME_BLOCK);
              }
              dvMap.loading = false;
              this.changeDetectorRef.detectChanges();
            },
            (err) => {
              dvMap.loading = false;
              this.snackBar.open(
                this.translSrv.t(
                  '_err_failed to fetch blocks',
                  'Failed to fetch blocks. You can switch to drawing parcel by hand.'
                ),
                this.translSrv.t('Ok'),
                { duration: 4000 }
              );
              console.log('failed to fetch blocks', err);
            }
          );

        this.skiften$ = this.clientService
          .clientAr()
          .pipe(flatMap(() => this.clientService.getSkifteLayer()))
          .pipe(takeUntil(this._unsub$))
          .subscribe((res) => {
            dvMap.removeLayer(LAYER_NAME_SKIFTEN);
            dvMap.removeLayer(LAYER_NAME_INTERPOLATION);
            dvMap.removeLayer(LAYER_NAME_VALUEPOINT);
            this.skiften = dvMap.addGeoJson(res, LAYER_NAME_SKIFTEN);
          });

        dvMap.onSelected
          .pipe(takeUntil(this._unsub$))
          .subscribe((f: google.maps.Data.Feature) => {
            if (this.selectedFeature) {
              if (this.selectedFeature['_layerName'] === 'block') {
                const ft = this.blocks.features.find(
                  (fb) =>
                    fb.properties['blockKey'] === this.selectedFeature.getId()
                );
                if (ft) {
                  ft.properties[LAYER_SELECTED] = false;
                }
              } else {
                this.mapStateService.clearSelectedFeatures();
              }
              if (f.getProperty('_layerName') === 'block') {
                const block = this.blocks.features.find(
                  (b) => b.properties['blockKey'] === f.getId()
                );
                if (block) {
                  block.properties[LAYER_SELECTED] = true;
                }
              } else {
                this.mapStateService.setSelectedFeature(f);
              }
              dvMap.addGeoJson(this.blocks, LAYER_NAME_BLOCK);
            }
            this.selectedFeature = f;
            this.changeDetectorRef.detectChanges();
          });

        dvMap.onDeSelected.pipe(takeUntil(this._unsub$)).subscribe(() => {
          this.clearSelection();
          this.changeDetectorRef.detectChanges();
        });
        //trigger block fetch when initied
        this.mapBoundsSub.next(null);
      });
    this.wizSrv.getEditSkifte().subscribe((editSkifte) => {
      if (editSkifte) {
        this.selectedFeature = this.skiften.find(
          (skifte) => skifte['j'].skifteId === editSkifte.id
        );
        this.mapStateService.setSelectedFeature(this.selectedFeature);
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  onFieldSaved(): void {
    this.clearSelection();
    this.fieldSaved.emit();
    this.changeDetectorRef.detectChanges();
  }

  clearSelection(): void {
    this.feature = null;
    this.selectedFeature = null;
  }

  onCancel(): void {
    this.clearSelection();
    this.mapService.deselectAllFeatures(this.activeMap);
  }

  getPointArray(f: any): any[] {
    const geometry = [];

    f.getGeometry()
      .getArray()
      .forEach((featureParts: { getArray: () => any[] }) => {
        let firstPoint = null;

        const parts = [];
        featureParts.getArray().forEach((point) => {
          if (firstPoint === null) {
            firstPoint = { lat: point.lat(), lng: point.lng() };
          }
          parts.push({ lat: point.lat(), lng: point.lng() });
        });
        parts.push(firstPoint);
        geometry.push(parts);
      });
    return geometry;
  }

  ngOnDestroy(): void {
    if (this.activeMap) {
      this.activeMap.removeLayer(LAYER_NAME_BLOCK);
    }
    this.activeMap.loading = false;
    this.activeMap.stopDrawFeature();
    google.maps.event.removeListener(this.mapBounds$);
    this.mapBounds$;
    this._unsub$.next();
    this._unsub$.complete();
  }

  startEdit(event: boolean): void {
    if (event) {
      if (this.selectedFeature) {
        this.tempFeature = Object.assign(
          this.tempFeature,
          this.selectedFeature
        );
        this.map$ = this.mapService
          .mainMap()
          .pipe(takeUntil(this._unsub$))
          .subscribe((map) => {
            map.map.data.overrideStyle(this.selectedFeature, {
              editable: true,
            });

            // Hack to trigger changedectection in underlaying component
            map.map.data.addListener('setgeometry', () => {
              const t = this.selectedFeature;
              this.selectedFeature = new google.maps.Data.Feature();
              window.setTimeout(() => {
                this.selectedFeature = t;
              }, 1);
            });
          });
      }
    } else {
      this.mapService
        .mainMap()
        .pipe(takeUntil(this._unsub$))
        .subscribe((map) => {
          map.map.data.overrideStyle(this.selectedFeature, { editable: false });
        });

      if (this.tempFeature && this.tempFeature.getGeometry()) {
        this.selectedFeature.setGeometry(this.tempFeature.getGeometry());
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  startDraw(event: boolean): void {
    this.draw = event;
    this.map$ = this.mapService
      .mainMap()
      .pipe(takeUntil(this._unsub$))
      .subscribe((map) => {
        if (this.draw) {
          map.removeLayer(LAYER_NAME_BLOCK);
          map
            .startDrawFeature()
            .pipe(takeUntil(this._unsub$))
            .subscribe((res) => {
              this.selectedFeature = res;
              this.changeDetectorRef.detectChanges();
            });
        } else {
          map.stopDrawFeature();
          this.selectedFeature = null;
          google.maps.event.trigger(map.map, 'bounds_changed');
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  closeBox(): void {
    this.cancel.emit(true);
  }
}
