<mat-button-toggle-group
  *ngIf="showModeButton"
  (change)="change($event)"
  #group="matButtonToggleGroup"
>
  <mat-button-toggle
    [checked]="splitMode === 2"
    value="2"
    aria-label="Text align left"
  >
    <img src="assets/images/icon-split-two.svg" />
  </mat-button-toggle>
  <mat-button-toggle
    [checked]="splitMode === 4"
    value="4"
    aria-label="Text align center"
  >
    <img src="assets/images/icon-split-four.svg" />
  </mat-button-toggle>
</mat-button-toggle-group>
