import { SiteService } from 'app/services/site.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'areaUnit', pure: false })
export class AreaUnit implements PipeTransform {
  constructor(
    private siteService: SiteService,
    private decimalPipe: DecimalPipe
  ) {}

  transform(areaInHa: number): string {
    /**
     * This is needed because:
     * typeof NaN === 'number'
     */
    if (isNaN(areaInHa)) {
      return '';
    }

    const FORMAT = this.siteService.isClientNo() ? '1.0-0' : '1.0-2';

    return `${this.decimalPipe.transform(
      this.siteService.calculateAreaFromHa(areaInHa),
      FORMAT
    )} ${this.siteService.getAreaUnitFromSettings()}`;
  }
}
