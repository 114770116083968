import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DialogService } from 'app/components/dialog/dialog.service';
import { MapComponent } from 'app/components/map/map.component';
import {
  LAYER_NAME_INTERPOLATION,
  MapService,
} from 'app/components/map/map.service';
import { FileStatus } from 'app/models/filestatus.model';
import { GeoDataFileMetaTags } from 'app/models/geodata.model';
import {
  InterpolationCollection,
  MappingLegendModel,
} from 'app/models/interpolation.model';
import {
  GeoDataFileModel,
  PrescriptionBucketModel,
  PropertyInfoModel,
  SkifteHashModel,
} from 'app/models/models';
import { ClientService } from 'app/services/client.service';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  CalibratingYieldModel,
  PROPERTY_TYPE,
} from './geodata-interpolation-types';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';
import { PrescriptionService } from 'app/services/prescription.service';

@Component({
  selector: 'dv-geodata-interpolation',
  templateUrl: './geodata-interpolation.component.html',
  styleUrls: ['./geodata-interpolation.component.scss'],
})
export class GeodataInterpolationComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  private _unsub$: Subject<void> = new Subject<void>();

  loadingInterpolation = true;
  legend: MappingLegendModel = null;
  pointMode = false;
  error: string = null;
  initiated = false;
  showCalculateYield = false;
  yieldUpdated = false;
  endpointCall = false;

  PROPERTY_TYPE = PROPERTY_TYPE;

  map$: Subscription;
  dvMap: MapComponent;

  availableProperties: PropertyInfoModel[];
  selectedProperty$: BehaviorSubject<PropertyInfoModel> =
    new BehaviorSubject<PropertyInfoModel>(null);

  skiften: SkifteHashModel[];
  pointSpreadBoundary: GeoJSON.FeatureCollection;
  interpolatedProperty: string;
  interpolation$: BehaviorSubject<InterpolationCollection> =
    new BehaviorSubject<InterpolationCollection>(null);
  interpolationType: string;
  calculateYield: CalibratingYieldModel;
  metaDataTotalYield: number;

  @Input() geodata: GeoDataFileModel;

  constructor(
    private mapService: MapService,
    private clientService: ClientService,
    private translateService: DvToolbarTranslateService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialogService: DialogService,
    private prescriptionService: PrescriptionService
  ) {}

  ngOnInit(): void {
    this.mapService
      .mainMap()
      .pipe(takeUntil(this._unsub$))
      .subscribe((dvMap) => this.onMapResult(dvMap));

    if (
      this.geodata.status === FileStatus.Error ||
      this.geodata.status === FileStatus.Failed
    ) {
      this.error = this.translateService.t(
        '_interpolation_geodata_statusNOK',
        "_This geodata failed processing, we can't show anything in this view"
      );
    } else if (this.geodata.status !== FileStatus.Succeeded) {
      this.error = this.translateService.t(
        '_interpolation_geodata_statusProcessing',
        '_This geodata is not fully processed, some data may still be missing from this view'
      );
    }

    this.loadingInterpolation = true;
    combineLatest([
      this.clientService.getGeoDataProperties(this.geodata.fileId),
      this.clientService.getGeoDataPointSpreadBoundary(this.geodata.fileId),
    ]).subscribe(
      ([properties, boundary]) => {
        this.availableProperties = properties;
        this.pointSpreadBoundary = boundary;
        this.initiated = true;
        if (properties && properties.length > 0) {
          this.selectedProperty$.next(properties[0]);
        } else {
          this.selectedProperty$.next(null);
        }
      },
      () => {
        this.error = this.translateService.t(
          '_interpolation_geodata_boundaryfail',
          '_Failed to fetch information on this geodata, interpolation cannot be calculated.'
        );
      }
    );

    this.selectedProperty$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .subscribe((property) => {
        if (property) {
          this.interpolate();
        } else {
          this.interpolation$.next(null);
        }
      });
  }

  ngAfterViewInit(): void {
    this.interpolationType = this.geodata?.fileType ?? '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.geodata) {
      this.setMetaDataTotalYield();
    }
  }

  ngOnDestroy(): void {
    this._unsub$.next();
    this._unsub$.complete();
  }

  private interpolate(): void {
    this.error = null;
    this.loadingInterpolation = true;
    if (this.initiated) {
      this.clientService
        .getGeoDataInterpolation(
          this.geodata.fileType,
          this.selectedProperty$.value.field,
          this.pointSpreadBoundary,
          [this.geodata.fileId]
        )
        .pipe(takeUntil(this._unsub$))
        .subscribe((interpolation) => {
          this.interpolation$.next(interpolation);
          this.loadingInterpolation = false;
          if (this.selectedProperty$.value.field === PROPERTY_TYPE.Yield) {
            this.compareTotalYields(interpolation);
          }
        });
    }
  }

  private setMetaDataTotalYield(): void {
    this.metaDataTotalYield =
      Number(
        this.geodata.metadata.find(
          (data) => data.key === GeoDataFileMetaTags.totalYield
        )?.value
      ) ?? null;
  }

  private compareTotalYields(interpolation: InterpolationCollection): void {
    const legendTotalYield = interpolation?.interpolations?.legend?.sum;

    if (
      Number.isNaN(legendTotalYield) ||
      Number.isNaN(this.metaDataTotalYield)
    ) {
      this.showCalculateYield = false;
    }
    this.showCalculateYield = legendTotalYield !== this.metaDataTotalYield;
  }

  setActiveProperty(event: MatSelectChange): void {
    this.selectedProperty$.next(event.value);
    if (this.selectedProperty$.value.field !== PROPERTY_TYPE.Yield) {
      this.showCalculateYield = false;
    }
  }

  setOpacity(value: number): void {
    this.mapService.sliderOpacity = value;
    if (this.dvMap) {
      this.mapService.sliderOpacityLayers.forEach((layerName) => {
        this.dvMap.getFeatures(layerName).forEach((f) => {
          f.setProperty('fillOpacity', value);
        });
      });
    }
  }

  export(): void {
    const { features, legend } = this.interpolation$.getValue().interpolations;
    const prescription = {
      average: legend.avg,
      total: legend.sum,
      bucketCount: legend.buckets.length,
      gridSize: 30,
      gridRotation: 0,
      gridStartPoint: null,
      buckets: legend.buckets as unknown as PrescriptionBucketModel[],
      fc: {
        type: 'FeatureCollection',
        features: features,
      } as GeoJSON.FeatureCollection,
      functionId: 0,
      maxRate: legend.max,
      minRate: legend.min,
      yield: 0,
      cutoffPoint: null,
      content: 100,
    };
    this.prescriptionService.openExportDialog(
      prescription,
      prescription.content,
      true,
      undefined,
      legend.unit
    );
  }

  private onMapResult(dvMap: MapComponent): void {
    this.dvMap = dvMap;

    this.interpolation$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .subscribe(
        (interpolation) => {
          if (interpolation?.interpolations?.features?.length > 0) {
            this.dvMap.fitFeature(
              this.dvMap.addGeoJson(
                interpolation.interpolations,
                LAYER_NAME_INTERPOLATION
              )
            );
            this.legend = interpolation.interpolations.legend;
            this.loadingInterpolation = false;
          } else {
            this.legend = null;
            this.loadingInterpolation = false;
            this.dvMap.removeLayer(LAYER_NAME_INTERPOLATION);
          }
        },
        () => {
          this.error = this.translateService.t(
            '_interpolation_geodata_interpolation_failed',
            '_Failed to interpolate this data'
          );
        }
      );

    this.selectedProperty$
      .asObservable()
      .pipe(takeUntil(this._unsub$))
      .subscribe(() => {
        this.dvMap.removeLayer(LAYER_NAME_INTERPOLATION);
      });

    dvMap.multiSelect = false;
  }

  recalibrateYield(): void {
    const totalYield = this.geodata.metadata.find(
      (data) => data.key === 'Total Yield'
    )?.value;
    const tempTotalYield = Number(totalYield);

    if (!this.legend.sum || !this.geodata.fileId || !totalYield) {
      this.showSnackBar(
        'You are missing one or several values for this function'
      );
    }

    if (!isNaN(tempTotalYield)) {
      this.calculateYield = {
        calibratedYield: this.legend.sum,
        actualYield: tempTotalYield,
      };
    }

    this.clientService.clientId().subscribe((clientId) => {
      this.router.navigateByUrl(
        `client/${clientId}/data/${this.geodata.fileId}`
      );
    });

    this.clientService
      .recalibrateYield(this.geodata.fileId, this.calculateYield)
      .subscribe(
        () =>
          this.dialogService.note(
            (this.endpointCall = true),
            this.translateService.t('Recalibrating data on selected file'),
            this.translateService.t(
              '_Calibration_main_text',
              '_The information on the selected file is recalibrated, and all data points are adjusted to match the new value.'
            ),
            this.translateService.t(
              '_Calibrate_prompt',
              '_As soon as the recalculation is complete, you can go in and look at your file again'
            )
          ),
        () =>
          this.dialogService.note(
            (this.endpointCall = false),
            this.translateService.t('Something went wrong'),
            this.translateService.t(
              '_Calibration_error',
              '_An error occurred and the calibration could not be performed on the selected file'
            )
          )
      );
  }

  private showSnackBar(text: string): void {
    this.snackBar.open(this.translateService.t(text), null, {
      duration: 3500,
    });
  }
}
