<mat-list-item>
  <button mat-button (click)="toggleActive()">
    <img class="map-connect-norge-image" src="assets/images/icon-connect.svg" />
    {{ 'Pick NIBIO-Field' | dvt }}
  </button>
</mat-list-item>
<div class="dv-maps-tool" *ngIf="isActive">
  <div class="content">
    <div class="map-connect-norge-nibio">
      <span class="dv-lcenter-text map-connect-norge-nibio-span">{{
        'Show NIBIO-Fields' | dvt
      }}</span>
    </div>
    <div class="dv-background dv-border map-connect-norge-background">
      <dv-searchable-select
        class="map-connect-norge-searchable-select"
        #kommunCtrl
        [placeholderText]="placeholderText"
        (valueChanged)="kommun = $event"
        [data]="kommuner"
      ></dv-searchable-select>
      <mat-form-field class="map-connect-norge-farm-input">
        <input
          matInput
          placeholder="{{ 'Farmnumber' | dvt }}"
          type="number"
          [(ngModel)]="gard"
        />
      </mat-form-field>
      <mat-form-field class="map-connect-norge-brukare-input">
        <input
          matInput
          placeholder="{{ 'Bruksnummer' | dvt }}"
          type="number"
          [(ngModel)]="brukare"
        />
      </mat-form-field>
    </div>
    <div class="map-connect-norge-fc-button-wrapper">
      <button
        class="map-connect-norge-fc-button"
        mat-stroked-button
        disabled="{{ !kommun || !gard || !brukare }}"
        color="primary"
        (click)="getFc()"
      >
        {{ 'Fetch parcels' | dvt }}
      </button>
    </div>
    <mat-spinner
      class="map-connect-norge-spinner"
      *ngIf="loading"
      [diameter]="50"
    ></mat-spinner>
    <p *ngIf="parts.length == 0 && hasLoaded">
      {{ 'Select parcel to connect to field' | dvt }}
    </p>

    <dv-map-field-part
      [skifteId]="skifteId"
      fxLayout="column"
      [parts]="parts"
      (save)="onSave($event)"
      (cancel)="toggleActive()"
    ></dv-map-field-part>
  </div>
</div>
