import { Component, Input } from "@angular/core";
import { Sort } from "@angular/material/sort";

@Component({
    selector: 'dv-sort-arrows',
    templateUrl: 'sort-arrows.component.html',
    styleUrls: ['sort-arrows.component.scss'],
})
export class SortArrows {
    @Input() id: string;
    @Input() set currentSort(sort: Sort) {
        this.onSortChange(sort);
    };
    activeUp: boolean = false;
    activeDown: boolean = false;

    onSortChange(sort: Sort): void {
        if (sort && sort.active === this.id) {
            this.activeUp = sort.direction === 'asc';
            this.activeDown = sort.direction === 'desc';
        } else {
            this.activeUp = false;
            this.activeDown = false;
        }
    }
}
