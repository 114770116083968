import { Injectable } from '@angular/core';
import { Layer } from 'app/models/api.models';
import { ReplaySubject, Subject } from 'rxjs';
import { FeatureDataService } from '../map/feature-data/feature-data.service';
import { MapService } from '../map/map.service';

@Injectable({
  providedIn: 'root'
})
export class FieldViewService {
  private visible$ = new Subject<boolean>();
  private source$ = new ReplaySubject<TableSource>(1);

  constructor(private mapService: MapService, private featureDataService: FeatureDataService) {
    this.visible$.subscribe(v => this.featureDataService.disabled = v);
  }

  public onVisible() {
    return this.visible$.asObservable();
  }
  public onSource() {
    return this.source$.asObservable();
  }

  public setVisible(show: boolean) {
    this.visible$.next(show);
  }


  public showLayer(layer: Layer) {
    this.visible$.next(true);
    window.setTimeout(() => {
      this.mapService.mainMap().subscribe(mainMap => {
        let source: TableSource = {
          cols: [],
          data: [],
          name: layer.name,
          key: layer.id
        };
        let firstRun = true;

        mainMap.getFeatures(layer.id.toString()).forEach(feature => {
          let data = {};
          feature.forEachProperty((value, name) => {
            if (name.indexOf('_') !== 0) {
              if (firstRun)
                source.cols.push(name);

              data[name] = value;
              data['_id'] = feature.getId();
            }
          });
          source.data.push(data);
          firstRun = false;

        });
        this.source$.next(source);
      });
    }, 10);
  }
}
export interface TableSource {
  data: { [key: string]: any }[];
  cols: string[];
  name: string;
  key: number;
}
