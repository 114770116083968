import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  BrukningsenhetModel,
  SkifteDetielsModel,
  TabellUtsadeModel,
} from 'app/models/api.models';
import * as turf from '@turf/turf';
import { AllGeoJSON, area } from '@turf/turf';
import { AddCropComponent } from 'app/field-list-page/add-crop/add-crop.component';
import { ITabellUtsadeModel } from 'app/models/itabellutsade.model';
import { ClientService } from 'app/services/client.service';
import { SiteService } from 'app/services/site.service';
import { Subject } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';
import { DialogService } from '../dialog/dialog.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-field-tool',
  templateUrl: './field-tool.component.html',
  styleUrls: ['./field-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldToolComponent implements OnInit, OnDestroy, OnChanges {
  private _unsub$ = new Subject<void>();
  tabellUtsaden: ITabellUtsadeModel[] = [];
  utsaden: ITabellUtsadeModel[] = [];
  selUtsade: ITabellUtsadeModel = null;
  skifteName = '';
  svg: string = null;
  shp = false;
  draw = false;
  hasOdling = false;
  brukningsenhter: BrukningsenhetModel[] = [];
  selBruk: BrukningsenhetModel;
  featureType: 'block' | 'skifte' = 'block';
  selectedSkifte: SkifteDetielsModel;
  selectedSkifteId = 0;
  isEditing = false;
  drawField = false;
  featureAreal = null;
  loading = false;

  private addedTabellUtsade: TabellUtsadeModel;

  tabellUtsadenSearchValue: string;
  utsadenSearchValue: string;
  filteredUtsaden = new Subject<ITabellUtsadeModel[]>();

  @Input() validZoom = false;
  @Input() standAlone = false;
  @Input() drawMode = false;
  @Input() navigateBackOnClose = true;
  @Input() feature: google.maps.Data.Feature = null;

  @Output() changeFeature: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() drawFeature: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() fieldSaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() toolClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() toolCancel: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('cropSelect') cropSelect;

  constructor(
    private location: Location,
    private siteService: SiteService,
    private snackBar: MatSnackBar,
    private clientService: ClientService,
    private translate: DvToolbarTranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.clientService
      .getBrukningsenheter()
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        this.brukningsenhter = res;
        this.selBruk = res[0];
      });

    this.clientService
      .getProdUts()
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        this.utsaden = res;
        this.filteredUtsaden.next(this.utsaden);
      });

    this.clientService.tabellUtsade$
      .pipe(takeUntil(this._unsub$))
      .subscribe((res) => {
        this.tabellUtsaden = res;
        if (this.addedTabellUtsade) {
          this.selUtsade = res.find(
            (t) =>
              t.groda === this.addedTabellUtsade.groda &&
              t.sort === this.addedTabellUtsade.sort
          );
          this.addedTabellUtsade = null;
          this.changeDetectorRef.detectChanges();
        }
      });

    this.siteService
      .hasOdling()
      .pipe(takeUntil(this._unsub$))
      .subscribe((hasOdling) => {
        this.hasOdling = hasOdling;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.feature) {
      if (this.feature && this.feature.getGeometry()) {
        this.featureAreal = null;
        this.feature.toGeoJson(
          (f) => (this.featureAreal = turf.area(<AllGeoJSON>f))
        );
        this.featureType =
          this.feature.getProperty('skifteId') > 0 ? 'skifte' : 'block';
        this.clientService
          .getSvg(this.getPointArray(this.feature))
          .pipe(takeUntil(this._unsub$))
          .subscribe((res) => (this.svg = res));

        if (this.featureType === 'skifte') {
          if (
            this.selectedSkifteId !==
            Number(this.feature.getProperty('skifteId'))
          ) {
            this.skifteName = this.feature.getProperty('namn');
            this.selUtsade = null;

            this.clientService
              .getSkifte(this.feature.getProperty('skifteId'))
              .pipe(takeUntil(this._unsub$))
              .subscribe((skifte) => {
                this.selectedSkifte = skifte;
                this.selectedSkifteId = skifte.id;
                this.selUtsade = this.tabellUtsaden.find(
                  (uts) =>
                    this.selectedSkifte.huvudgroda &&
                    uts.id === this.selectedSkifte.huvudgroda.utsadeId
                );
              });
          }
        }
      }
    }
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.isEditing = false;
    this._unsub$.next();
    this._unsub$.complete();
  }

  clearSelection(): void {
    this.feature = null;
    this.changeDetectorRef.detectChanges();
  }

  getPointArray(f: any): any[] {
    const geometry = [];

    f.getGeometry()
      .getArray()
      .forEach((featureParts) => {
        let firstPoint = null;

        const parts = [];
        featureParts.getArray().forEach((point) => {
          if (firstPoint === null) {
            firstPoint = { lat: point.lat(), lng: point.lng() };
          }
          parts.push({ lat: point.lat(), lng: point.lng() });
        });
        parts.push(firstPoint);
        geometry.push(parts);
      });
    return geometry;
  }

  saveSkifte(): void {
    // Update color if main-crop is changed
    if (
      this.selUtsade &&
      (!this.selectedSkifte.huvudgroda ||
        this.selectedSkifte.huvudgroda.utsadeId !== this.selUtsade.id)
    ) {
      this.clientService
        .changeHuvudgroda(this.selectedSkifte, this.selUtsade.id)
        .pipe(takeUntil(this._unsub$))
        .subscribe((res) => {
          this.feature.setProperty('color', res.farg);
        });
    }

    this.selectedSkifte.namn = this.skifteName;

    this.feature.toGeoJson(
      (feature: GeoJSON.Feature<GeoJSON.GeometryObject>) => {
        if (feature) {
          this.selectedSkifte.feature = feature;
        }

        this.clientService
          .saveSkifte(this.selectedSkifte)
          .pipe(takeUntil(this._unsub$))
          .subscribe((res: SkifteDetielsModel) => {
            this.snackBar.open(this.translate.t('Field updated'), null, {
              duration: 3000,
            });

            this.clientService.loadSkifteLayer();
            this.feature.setProperty('namn', res.namn);
            this.clearInput();
            this.endEdit();
          });
      }
    );

    this.fieldSaved.emit();
    this.changeDetectorRef.detectChanges();
  }

  addSkifte(): void {
    this.loading = true;
    if (this.selUtsade === null) {
      this.selUtsade = {
        groda: '',
        sort: '',
        id: 0,
      };
    }

    this.feature.toGeoJson((geoJson: GeoJSON.Feature) => {
      this.clientService
        .addSkifte([
          {
            areal: this.siteService.calculateArea(
              area(<turf.AllGeoJSON>geoJson)
            ),
            brukId: this.selBruk.id,
            feature: geoJson,
            groda: this.selUtsade.groda,
            namn: this.skifteName,
            sort: this.hasOdling ? this.selUtsade.sort : '',
            wkt: null,
            espg: null,
          },
        ])
        .pipe(first())
        .subscribe(() => {
          this.snackBar.open(this.translate.t('Field added'), null, {
            duration: 3000,
          });
          this.clientService.reloadYear();
          this.clearInput();
          this.drawField = false;
          this.drawFeature.emit(this.drawField);
          this.fieldSaved.emit();
          this.loading = false;
        });
    });
    this.changeDetectorRef.detectChanges();
  }

  clearInput(): void {
    this.skifteName = '';
    this.selUtsade = null;
    this.svg = null;
    this.feature = null;
    this.featureType = null;
    this.selectedSkifteId = 0;
  }

  cancel(): void {
    this.clearInput();
    this.drawField = false;
    this.toolCancel.emit(true);
    this.changeDetectorRef.detectChanges();
  }

  startEdit(): void {
    this.isEditing = true;
    this.changeFeature.emit(true);
    this.changeDetectorRef.detectChanges();
  }

  endEdit(): void {
    this.changeFeature.emit(false);
    this.isEditing = false;
    this.changeDetectorRef.detectChanges();
  }

  drawFieldChange(): void {
    this.drawMode = this.drawField;
    this.drawFeature.emit(this.drawField);
    this.clearInput();
    this.changeDetectorRef.detectChanges();
  }

  goBack(): void {
    if (this.navigateBackOnClose) {
      this.location.back();
    } else {
      this.toolClose.next(true);
    }
    this.changeDetectorRef.detectChanges();
  }

  closeBox(): void {
    this.toolClose.emit(true);
  }

  addCrop(): void {
    this.cropSelect.close();
    this.dialog
      .open(AddCropComponent, { data: this.tabellUtsaden })
      .afterClosed()
      .pipe(
        tap((tabellUtsade: TabellUtsadeModel) => {
          if (tabellUtsade) {
            this.loadSeeds();
          }
        })
      )
      .subscribe((tabellUtsade: TabellUtsadeModel) => {
        if (tabellUtsade) {
          this.addedTabellUtsade = tabellUtsade;
          this.snackBar.open(this.translate.t('Crop(s) added'), null, {
            duration: 3000,
          });
        }
      });
  }

  private loadSeeds(): void {
    this.clientService
      .loadTabellUtsade()
      .pipe(takeUntil(this._unsub$))
      .subscribe();
  }

  compareCrops(c1: TabellUtsadeModel, c2: TabellUtsadeModel): boolean {
    return c1 && c2 ? c1.groda === c2.groda && c1.sort === c2.sort : c1 === c2;
  }
}
