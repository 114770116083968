import { SelectionModel } from '@angular/cdk/collections';
import { DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  BrukningsenhetModel,
  SkifteDetielsModel,
  TabellUtsadeModel,
  UtsadeModel,
} from 'app/models/api.models';
import { DialogService } from 'app/components/dialog/dialog.service';
import { ImportService } from 'app/components/import-view/import.service';
import { MissingSkifteModel } from 'app/models/missingSkifteModel';
import { ClientService } from 'app/services/client.service';
import { MapStateService } from 'app/services/map-state.service';
import { SiteService } from 'app/services/site.service';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, first, flatMap, takeUntil, tap } from 'rxjs/operators';
import { AddCropComponent } from './add-crop/add-crop.component';
import { EditFarmsComponent } from './edit-farms/edit-farms.component';
import { EditFieldMapComponent } from './edit-field-map/edit-field-map.component';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-field-list-page',
  templateUrl: './field-list-page.component.html',
  styleUrls: ['./field-list-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldListPageComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  snackConfigError: MatSnackBarConfig = {
    panelClass: ['style-error'],
  };

  brukenheter: BrukningsenhetModel[] = [];
  displayedColumns: string[] = [
    'delete',
    'navigate',
    'map',
    'select',
    'brukEnhNamn',
    'namn',
    'areal',
    'huvudgrodaBenamning',
    'forfrukt',
    'saving',
  ];
  dataSource: MatTableDataSource<SkifteDetielsModel> =
    new MatTableDataSource<SkifteDetielsModel>();
  selection = new SelectionModel<SkifteDetielsModel>(true, []);
  tabellUtsade: TabellUtsadeModel[] = [];
  year: number;
  missingSkiften: MissingSkifteModel;
  modelChanged: Subject<SkifteDetielsModel> = new Subject<SkifteDetielsModel>();
  edit = false;
  @ViewChild('farmSelect') farmSelect: MatSelect;
  @ViewChild('cropSelect') cropSelect: MatSelect;
  @ViewChild('paginator') paginator: MatPaginator;
  loadingFields: boolean;
  fieldSub: Subscription;
  cropSub: Subscription;
  brukSub: Subscription;
  private unsub$ = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private clientService: ClientService,
    private snackBar: MatSnackBar,
    private translate: DvToolbarTranslateService,
    private dialogService: DialogService,
    private router: Router,
    private dialog: MatDialog,
    public siteService: SiteService,
    private numberPipe: DecimalPipe,
    private importService: ImportService,
    private mapStateService: MapStateService
  ) {}

  ngOnInit(): void {
    this.loadFarms();

    this.siteService.hasOdling().subscribe((hasOdling) => {
      this.edit = !hasOdling;
      this.cd.markForCheck();
    });

    this.modelChanged
      .pipe(
        debounceTime(1000),
        flatMap((skifte) => {
          skifte['saving'] = true;
          this.cd.markForCheck();
          return this.clientService.saveSkifte(skifte);
        })
      )
      .subscribe((res) => {
        if (res) {
          this.snackBar.open(this.translate.t('Field updated'), null, {
            duration: 3000,
          });
        }
        this.stopSaving();
      });
    this.selection.changed.subscribe((res) => {
      if (res.source.selected.length > 1) {
        this.snackBar
          .open(
            this.translate.t('Do you want to delete selected fields?'),
            this.translate.t('Delete'),
            this.snackConfigError
          )
          .onAction()
          .subscribe(() => {
            this.deleteSelected();
          });
      } else {
        this.snackBar.dismiss();
      }
    });

    this.clientService.clientAr().subscribe((year) => {
      if (this.year && this.year !== year) {
        this.year = year;
        this.init();
      } else {
        this.year = year;
      }
    });
  }

  ngAfterViewInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    if (this.fieldSub) {
      this.fieldSub.unsubscribe();
    }
    if (this.cropSub) {
      this.cropSub.unsubscribe();
    }
    if (this.brukSub) {
      this.brukSub.unsubscribe();
    }

    this.unsub$.next();
    this.unsub$.complete();
  }

  private init(): void {
    this.loadCrops();
    this.fillDatatable();
    this.missingSkiften = undefined;
    this.loadMissingSkifte();
    this.cd.markForCheck();
  }

  private loadCrops(): void {
    this.cropSub = this.clientService.tabellUtsade$.subscribe((tUsade) => {
      this.tabellUtsade = tUsade;

      this.cd.markForCheck();
    });
  }

  private loadFarms(): void {
    this.brukSub = this.clientService
      .getBrukningsenheter()
      .subscribe((bruk) => {
        this.brukenheter = bruk;
        this.cd.markForCheck();
      });
  }

  private loadMissingSkifte(): void {
    this.clientService
      .missingSkifteCount(this.year)
      .pipe(takeUntil(this.unsub$))
      .subscribe((skifteCount) => {
        this.missingSkiften = skifteCount;
      });
  }

  private loadFields(): void {
    let numberFormat = '1.2-2';
    if (this.siteService.isClientNo()) {
      numberFormat = '1.0-0';
    }

    this.loadingFields = true;
    this.cd.markForCheck();
    this.fieldSub = this.clientService.getSkiften().subscribe((skiften) => {
      skiften.forEach((s) => {
        if (!isNaN(s.areal)) {
          s.areal = Number(
            this.numberPipe.transform(s.areal, numberFormat).replace(',', '.')
          );
        }
      });

      this.dataSource.data = skiften;

      this.cd.markForCheck();
    });
  }

  private fillDatatable(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loadFields();
    let counter = 0;
    const intervall = setInterval(() => {
      if (this.paginator.getNumberOfPages() > 0) {
        this.loadingFields = false;
        this.cd.markForCheck();
        clearInterval(intervall);
      }
      counter++;
      if (counter > 15 && this.dataSource.data.length === 0) {
        this.loadingFields = false;
        this.cd.markForCheck();
        clearInterval(intervall);
      }
    }, 500);
  }

  private stopSaving(): void {
    this.dataSource.data.forEach((s) => (s['saving'] = false));
    this.cd.markForCheck();
  }

  getHuvudgrodaId(skifte: SkifteDetielsModel): number {
    if (skifte.huvudgroda) {
      return skifte.huvudgroda.utsadeId;
    }

    return 0;
  }

  huvudgrodaChange(utsadeId: number, skifte: SkifteDetielsModel): void {
    if (
      utsadeId &&
      (!skifte.huvudgroda || skifte.huvudgroda.utsadeId !== utsadeId)
    ) {
      skifte['saving'] = true;
      this.clientService.changeHuvudgroda(skifte, utsadeId).subscribe((res) => {
        if (res) {
          this.snackBar.open(this.translate.t('Field updated'), null, {
            duration: 3000,
          });
        }
        this.stopSaving();
      });
    }
  }

  triggerSave(skifte: SkifteDetielsModel): void {
    this.modelChanged.next(skifte);
  }

  triggerSaveFarm(skifte: SkifteDetielsModel): void {
    const res = this.dataSource.data.filter(
      (s) => s.brukEnhId === skifte.brukEnhId && s.skifteNr === skifte.skifteNr
    );

    if (res.length > 1) {
      let maxFieldNumber = 0;
      const fieldNumberList = this.dataSource.data
        .filter((s) => s.brukEnhId === skifte.brukEnhId)
        .map((s) => s.skifteNr);
      if (fieldNumberList) {
        maxFieldNumber = Math.max(...fieldNumberList);
      }
      skifte.skifteNr = maxFieldNumber + 1;
    }

    this.modelChanged.next(skifte);
  }

  triggerSaveForfrukt(utsadeId: number, skifte: SkifteDetielsModel): void {
    if (utsadeId) {
      this.modelChanged.next(skifte);
    } else {
      skifte.forfruktId = 0;
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  deleteSelected(): void {
    this.dialogService
      .confirm(this.translate.t('Delete selected fields?'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          const arr: Observable<void>[] = [];
          this.selection.selected.forEach((s) => {
            arr.push(this.clientService.deleteSkifte(s.id, true));
          });

          forkJoin(arr).subscribe(() => {
            this.snackBar.open(
              this.translate.t('Selected field(s) deleted'),
              null,
              { duration: 3000 }
            );
            this.loadFields();
          });
        }
      });
  }

  deleteField(skifte: SkifteDetielsModel): void {
    this.dialogService
      .confirm(this.translate.t('Delete selected field?'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.clientService.deleteSkifte(skifte.id, true).subscribe(() => {
            this.snackBar.open(
              this.translate.t('Selected field deleted'),
              null,
              { duration: 3000 }
            );
            this.loadFields();
          });
        }
      });
  }

  newField(): void {
    this.clientService.clientId().subscribe((clientId) => {
      this.router.navigateByUrl('client/' + clientId + '/field');
    });
  }

  importFields(): void {
    this.importService
      .tryOpenImportDone(this.year, true)
      .afterClosed()
      .subscribe(() => this.init());
  }

  addFarm(skifte: SkifteDetielsModel): void {
    this.farmSelect.close();
    this.dialogService
      .prompt('Create farm', 'Farm name')
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.clientService
            .createBrukningsenhet({ id: -1, namn: res })
            .subscribe((createdFarm) => {
              this.clientService
                .getBrukningsenheter()
                .pipe(first())
                .subscribe((bruk) => {
                  this.brukenheter = bruk;
                  skifte.brukEnhId = createdFarm.id;
                  this.cd.markForCheck();
                  this.snackBar.open(
                    this.translate.t('New farm created'),
                    null,
                    { duration: 3000 }
                  );
                  this.triggerSaveFarm(skifte);
                });
            });
        }
      });
  }

  editFarm(): void {
    this.farmSelect.close();
    this.dialog
      .open(EditFarmsComponent, { data: { brukenheter: this.brukenheter } })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.loadFarms();
          this.snackBar.open(this.translate.t('Farm(s) updated'), null, {
            duration: 3000,
          });
        }
      });
  }

  addCrop(skifte: SkifteDetielsModel, isHuvudgroda = false): void {
    this.cropSelect.close();
    this.dialog
      .open(AddCropComponent, { data: this.tabellUtsade })
      .afterClosed()
      .pipe(
        tap((tabellUtsade: TabellUtsadeModel) => {
          if (tabellUtsade) {
            this.loadSeeds();
          }
        })
      )
      .subscribe((tabellUtsade: TabellUtsadeModel) => {
        if (tabellUtsade) {
          this.clientService.tabellUtsade$.pipe(first()).subscribe(() => {
            if (isHuvudgroda) {
              this.huvudgrodaChange(tabellUtsade.id, skifte);
              if (!skifte.huvudgroda) {
                skifte.huvudgroda = <UtsadeModel>{};
              }

              skifte.huvudgroda.utsadeId = tabellUtsade.id;
            } else {
              skifte.forfruktId = tabellUtsade.id;
              this.triggerSave(skifte);
            }

            this.cd.markForCheck();
          });
          this.snackBar.open(this.translate.t('Crop(s) added'), null, {
            duration: 3000,
          });
        }
      });
  }

  private loadSeeds(): void {
    this.clientService
      .loadTabellUtsade()
      .pipe(takeUntil(this.unsub$))
      .subscribe();
  }

  openMap(skifte: SkifteDetielsModel): void {
    const obj = Object.assign({}, skifte);
    obj['allSkifte'] = this.dataSource.data;
    obj['brukningsenheter'] = this.brukenheter;
    this.dialog
      .open(EditFieldMapComponent, {
        data: obj,
        width: '90%',
        height: '90%',
        maxWidth: '100vw',
        maxHeight: '100vh',
      })
      .afterClosed()
      .subscribe(() => {
        this.loadFields();
      });
  }

  openCropplan(): void {
    if (!this.edit) {
      this.snackBar
        .open(this.translate.t('Open CropPLAN?'), this.translate.t('Open'), {
          duration: 5000,
        })
        .onAction()
        .subscribe(() => {
          this.clientService.clientId().subscribe((clientId) => {
            window.open(
              'https://odling.datavaxt.se/client/' + clientId,
              '_blank'
            );
          });
        });
    }
  }

  navigateToField(skifte: SkifteDetielsModel): void {
    this.mapStateService.clearSelectedFeatures();
    this.mapStateService.setSelectedGeoFeature(skifte.feature);
    if (skifte.skifteCenter) {
      window.localStorage.setItem(
        'center',
        JSON.stringify(skifte.skifteCenter)
      );
    }

    this.clientService.clientId().subscribe((clientId) => {
      this.router.navigateByUrl('client/' + clientId + '/map/mapping');
    });
  }
}
